import { isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import { idGrupoCondicao } from 'types/enums'

import {
  convertAgeRangeValueToFaixaEtariaInput,
  convertFaixaEtariaAcompanhamentoInput,
} from '../converter/converter-faixaEtariaAcompanhamento'
import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'

export function convertModelToInput(
  model: AcompanhamentoCondicaoSaudeFormModel
): AcompanhamentoCondicaoSaudeQueryInput {
  return {
    ciaps: model?.problemasCondicoes?.filter((item) => isCiap(item)).map((item) => item.codigo),
    cid10: model?.problemasCondicoes?.filter((item) => !isCiap(item)).map((item) => item.codigo),
    grupoCondicao: model?.grupoCondicao?.map((item) => idGrupoCondicao[item.grupoCondicao]),
    somenteProblemasCondicoesAtivas: model?.ativoListaProblema ? model.ativoListaProblema : false,
    unidadeResponsavelId: model?.unidadeResponsavelId,
    equipeResponsavelId: model.isCoordenador ? model?.equipeResponsavel?.id : model?.equipeResponsavelId,
    sexo: model?.sexo,
    identidadeGenero: model?.identidadeGenero,
    faixaEtaria: convertFaixaEtariaAcompanhamentoInput(
      model?.tipoFaixaEtaria,
      convertAgeRangeValueToFaixaEtariaInput(model?.faixaEtariaRange)
    ),
    periodoUltimoAtendimento: model?.periodoUltimoAtendimento,
    microarea: model?.microarea,
  }
}
