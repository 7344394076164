import { FaixaEtariaEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, required } from 'util/validation'

import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'

export const validate = createValidator<AcompanhamentoCondicaoSaudeFormModel>(
  {
    equipeResponsavel: required,
  },
  (values: AcompanhamentoCondicaoSaudeFormModel, errors: ErrorObject<AcompanhamentoCondicaoSaudeFormModel>) => {
    if (FaixaEtariaEnum.OUTRA === values?.tipoFaixaEtaria) {
      errors.faixaEtariaRange = required(values.faixaEtariaRange)
    }

    return errors
  }
)
