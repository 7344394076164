import { Alert, VFlow } from 'bold-ui'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { useFlags } from 'config/useFlagsContext'
import { FormApi } from 'final-form'
import { useAcessoHasCbosDisponiveisVinculacaoEquipeQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { emptyArray } from 'util/array'
import { MetaPath } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { CompartilhamentoCuidadoTable } from '../compartilhamento-cuidado/compartilhamento-cuidado-table/CompartilhamentoCuidadoTable'
import { AdicionarCuidadoCompartilhadoForm } from './cuidado-compartilhado-form/AdicionarCuidadoCompartilhadoForm'
import { EditarCuidadoCompartilhadoModalForm } from './cuidado-compartilhado-form/EditarCuidadoCompartilhadoModalForm'
import { CuidadoCompartilhadoTable } from './cuidado-compartilhado-table/CuidadoCompartilhadoTable'
import { CuidadoCompartilhadoPlanoModel, EDITAR_CUIDADO_COMPARTILHADO_URL } from './model-cuidadocompartilhado'

interface CuidadoCompartilhadoViewProps {
  name: MetaPath<CuidadoCompartilhadoPlanoModel[]>
  isAtendimentoOdonto?: boolean
}

export const CuidadoCompartilhadoView = (props: CuidadoCompartilhadoViewProps) => {
  const { name, isAtendimentoOdonto = false } = props

  const history = useHistory()
  const match = useRouteMatch()
  const { analytics } = useFirebase()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()
  const { getServerTimeNow } = useServerTime()
  const {
    data: { acessoHasCbosDisponiveisVinculacaoEquipe },
  } = useAcessoHasCbosDisponiveisVinculacaoEquipeQuery({
    skip: PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && !isAtendimentoOdonto,
  })

  const accordionProps = useAccordionControl({})

  const {
    id: atendimentoId,
    prontuario: { id: prontuarioId },
    atendimentoProfissional: { id: atendimentoProfissionalId, lotacao, iniciadoEm },
  } = useAtendimentoContext()

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value = emptyArray },
  } = useEditableListField({ name })

  const onSubmit = (values: CuidadoCompartilhadoPlanoModel, form: FormApi<CuidadoCompartilhadoPlanoModel>) => {
    handleSubmit({ ...values, lastUpdate: getServerTimeNow().valueOf() }, form)
  }

  const onRowChanged = (values: CuidadoCompartilhadoPlanoModel) => {
    handleRowChanged({ ...values, lastUpdate: getServerTimeNow().valueOf() })
  }

  const handleEdit = (id: ID) => {
    history.push(`${match.url}${EDITAR_CUIDADO_COMPARTILHADO_URL}/${id}`)
  }

  const handleDelete = (id: ID) => {
    removeItem(value.find(({ _id: valueId }) => valueId === id))
    analytics.logEvent('CC_excluido')
  }

  return (
    <VFlow>
      {(PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && !isAtendimentoOdonto) || acessoHasCbosDisponiveisVinculacaoEquipe ? (
        <AdicionarCuidadoCompartilhadoForm
          onSubmit={onSubmit}
          lotacaoAtual={lotacao}
          isAtendimentoOdonto={isAtendimentoOdonto}
        />
      ) : (
        <Alert type='info'>
          Não é possível iniciar um compartilhamento de cuidado, pois não existem equipes NASF/eMulti vinculadas a
          equipe da sua lotação.
        </Alert>
      )}

      {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && !isAtendimentoOdonto ? (
        <CompartilhamentoCuidadoTable
          prontuarioId={prontuarioId}
          atendimentoId={atendimentoId}
          atendimentoProfissionalId={atendimentoProfissionalId}
          compartilhamentosAtendimentoAtual={value}
          iniciadoEm={iniciadoEm}
          lotacaoAtual={lotacao}
          onEdit={handleEdit}
          onDelete={handleDelete}
          {...accordionProps}
        />
      ) : (
        <CuidadoCompartilhadoTable
          prontuarioId={prontuarioId}
          cuidadosAtendimentoAtual={value}
          lotacaoAtual={lotacao}
          dataInicioAtendimento={iniciadoEm.getTime()}
          onEdit={handleEdit}
          onDelete={handleDelete}
          {...accordionProps}
        />
      )}
      <Route path={`${match.url}${EDITAR_CUIDADO_COMPARTILHADO_URL}/:id`}>
        <EditarCuidadoCompartilhadoModalForm
          compartilhamentosCuidado={value}
          onSubmit={onRowChanged}
          isAtendimentoOdonto={isAtendimentoOdonto}
        />
      </Route>
    </VFlow>
  )
}
