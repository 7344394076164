import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { isEmpty } from 'util/validation/Util'

import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'

const meta = metaPath<AcompanhamentoCondicaoSaudeFormModel>()

export const calculator = createDecorator({
  field: [meta.grupoCondicao.absolutePath(), meta.problemasCondicoes.absolutePath()],
  updates: {
    [meta.ativoListaProblema.absolutePath()]: (
      value: [],
      allValues: AcompanhamentoCondicaoSaudeFormModel,
      prevValues: AcompanhamentoCondicaoSaudeFormModel
    ) => {
      if (isEmpty(allValues.grupoCondicao) && isEmpty(allValues.problemasCondicoes)) return false

      if (isObjectDeepEmpty(prevValues)) return allValues.ativoListaProblema

      return (
        (isEmpty(prevValues.grupoCondicao) &&
          isEmpty(prevValues.problemasCondicoes) &&
          (!isEmpty(allValues.grupoCondicao) || !isEmpty(allValues.problemasCondicoes))) ||
        allValues.ativoListaProblema
      )
    },
  },
})
