import { AcompanhamentoSaudeRelatorio } from 'api/AcompanhamentoSaudeRelatorio'
import { AxiosPromise } from 'axios'
import { EquipeSelectModel, LocalDateRange } from 'components/form'
import { CiapCidSelectModel } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'

import { AcompanhamentoFilterModel } from '../models/model-acompanhamentoFilter'

export interface AcompanhamentoCondicaoSaudeFormModel extends AcompanhamentoFilterModel {
  problemasCondicoes?: CiapCidSelectModel[]
  grupoCondicao?: GrupoCondicaoSelectModel[]
  ativoListaProblema?: boolean
  unidadeResponsavelId?: ID
  equipeResponsavelId?: ID
  equipeResponsavel?: EquipeSelectModel
  isCoordenador?: boolean
  // controle dos grupos rápidos
  isDiabetesCheck?: boolean
  isHipertensaoCheck?: boolean
  isObesidadeCheck?: boolean
  isGravidezCheck?: boolean
  periodoUltimoAtendimento?: LocalDateRange
  microarea?: string
  foraarea?: boolean
  naoinformada?: boolean
}

export enum TipoRelatorioOperacionalEnum {
  CRIANCA = 'CRIANCA',
  GESTANTE = 'GESTANTE',
  RISCO_CARDIOVASCULAR = 'RISCO_CARDIOVASCULAR',
}

export const tipoRelatorioOperacionalAnalyticsLabel: Record<TipoRelatorioOperacionalEnum, string> = {
  [TipoRelatorioOperacionalEnum.CRIANCA]: 'relatorio_ope_acomp_cond_saude_with_crianca',
  [TipoRelatorioOperacionalEnum.GESTANTE]: 'relatorio_ope_acomp_cond_saude_with_gestante_puerpera',
  [TipoRelatorioOperacionalEnum.RISCO_CARDIOVASCULAR]: 'relatorio_ope_acomp_cond_saude_with_risco_cardiovascular',
}

export const tipoRelatorioOperacionalToApiCallRecord: Record<
  TipoRelatorioOperacionalEnum,
  { api: (filter: AcompanhamentoCondicaoSaudeQueryInput) => AxiosPromise; fileName: string }
> = {
  [TipoRelatorioOperacionalEnum.CRIANCA]: {
    api: (filter) => new AcompanhamentoSaudeRelatorio().downloadRelatorioOperacionalCrianca(filter),
    fileName: 'relatorio-operacional-de-criancas-menores-de-5-anos',
  },
  [TipoRelatorioOperacionalEnum.GESTANTE]: {
    api: (filter) => new AcompanhamentoSaudeRelatorio().downloadRelatorioOperacionalGestante(filter),
    fileName: 'relatorio-operacional-de-gestante-puerpera',
  },
  [TipoRelatorioOperacionalEnum.RISCO_CARDIOVASCULAR]: {
    api: (filter) => new AcompanhamentoSaudeRelatorio().downloadRelatorioOperacionalRiscoCardiovascular(filter),
    fileName: 'relatorio-operacional-de-risco-cardiovascular',
  },
}
