import * as Types from 'graphql/types.generated'

import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactHooks from 'graphql/hooks'

export const TipoEquipeFragmentDoc = gql`
  fragment TipoEquipe on Equipe {
    tipoEquipe {
      id
      descricao
      nome
      sigla
    }
  }
`
export const CboFragmentDoc = gql`
  fragment Cbo on LotacaoOuEstagio {
    ... on Lotacao {
      cbo {
        id
        nome
        cbo2002
      }
    }
    ... on Estagio {
      cbo {
        id
        nome
        cbo2002
      }
    }
  }
`
export const AcessoAdministradorMunicipalFragmentDoc = gql`
  fragment AcessoAdministradorMunicipal on AdministradorMunicipal {
    municipio {
      id
      nome
    }
  }
`
export const AcessoGestorEstadualFragmentDoc = gql`
  fragment AcessoGestorEstadual on GestorEstadual {
    uf {
      id
      nome
    }
  }
`
export const AcessoGestorMunicipalFragmentDoc = gql`
  fragment AcessoGestorMunicipal on GestorMunicipal {
    municipio {
      id
      nome
    }
  }
`
export const AcessoLotacaoFragmentDoc = gql`
  fragment AcessoLotacao on Lotacao {
    ...Cbo
    equipe {
      id
      nome
      ine
    }
    unidadeSaude {
      id
      nome
    }
  }
  ${CboFragmentDoc}
`
export const AcessoEstagioFragmentDoc = gql`
  fragment AcessoEstagio on Estagio {
    lotacaoSupervisora {
      id
      profissional {
        id
        nome
      }
    }
    ...Cbo
    equipe {
      id
      nome
      ine
    }
    unidadeSaude {
      id
      nome
    }
  }
  ${CboFragmentDoc}
`
export const SessaoDataFragmentDoc = gql`
  fragment SessaoData on Sessao {
    id
    timeout
    expDateAccessTokenGovBr
    recursos
    sessionProvider
    acesso {
      id
      tipo
      moduloInicial
      municipio {
        id
        nome
        uf {
          id
          nome
          sigla
        }
      }
      perfis {
        id
        nome
      }
      ... on AdministradorMunicipal {
        autorizado
        habilitado
      }
      ... on Lotacao {
        unidadeSaude {
          id
          nome
          cnes
          tipo {
            id
            codigoMs
          }
          subtipo {
            id
            codigoMs
          }
          tipoEstabelecimento
          isEstabelecimentoAtencaoPrimaria
        }
        equipe {
          id
          nome
          ine
          ...TipoEquipe
        }
        ...Cbo
      }
      ... on Estagio {
        unidadeSaude {
          id
          nome
          cnes
          tipo {
            id
            codigoMs
          }
          subtipo {
            id
            codigoMs
          }
          tipoEstabelecimento
        }
        equipe {
          id
          nome
          ine
          ...TipoEquipe
        }
        ...Cbo
        lotacaoSupervisora {
          id
          __typename
          profissional {
            id
            nome
            numeroConselhoClasse
            conselhoClasse {
              id
              sigla
            }
            ufEmissoraConselhoClasse {
              id
              nome
              sigla
            }
          }
          municipio {
            id
            nome
            uf {
              id
              sigla
            }
          }
          unidadeSaude {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
      }
    }
    profissional {
      id
      cpf
      cns
      nome
      conselhoClasse {
        id
        sigla
        descricao
      }
      numeroConselhoClasse
      ufEmissoraConselhoClasse {
        id
        nome
        sigla
      }
      usuario {
        id
        aceitouTermosUso
        aceitouTermoTeleinterconsulta
        forcarTrocaSenha
        visualizouNovidades
        mostrarPesquisaSatisfacao
        notificaNovidadesVersao
        hashId
      }
      acessos {
        id
        tipo
        ... on AdministradorMunicipal {
          ...AcessoAdministradorMunicipal
        }
        ... on GestorEstadual {
          ...AcessoGestorEstadual
        }
        ... on GestorMunicipal {
          ...AcessoGestorMunicipal
        }
        ... on Lotacao {
          ...AcessoLotacao
        }
        ... on Estagio {
          ...AcessoEstagio
        }
      }
    }
  }
  ${TipoEquipeFragmentDoc}
  ${CboFragmentDoc}
  ${AcessoAdministradorMunicipalFragmentDoc}
  ${AcessoGestorEstadualFragmentDoc}
  ${AcessoGestorMunicipalFragmentDoc}
  ${AcessoLotacaoFragmentDoc}
  ${AcessoEstagioFragmentDoc}
`
export const LotacaoAgendaSelectFragmentDoc = gql`
  fragment LotacaoAgendaSelect on Lotacao {
    id
    ativo
    importada
    hasConfiguracaoAgenda
    hasConfiguracaoAgendaOnline
    hasAgendamentoFuturo
    profissional {
      id
      nome
      cpf
      email
    }
    cbo {
      id
      nome
      cbo2002
    }
    equipe {
      id
      ine
      nome
      tipoEquipe {
        id
        descricao
      }
    }
  }
`
export const CiapFragmentDoc = gql`
  fragment Ciap on Ciap {
    id
    codigo
    descricao
  }
`
export const Cid10FragmentDoc = gql`
  fragment Cid10 on Cid10 {
    id
    codigo
    nome
  }
`
export const ProblemaCondicaoFragmentDoc = gql`
  fragment ProblemaCondicao on Problema {
    id
    situacao
    ciap {
      ...Ciap
    }
    cid10 {
      ...Cid10
    }
    descricaoOutro
    ultimaEvolucao {
      id
      dataInicio
      dataFim
      situacao
      observacao
      atualizadoEm
    }
    evolucaoAvaliacaoCiapCid {
      id
    }
  }
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
`
export const AlergiaFragmentDoc = gql`
  fragment Alergia on Alergia {
    id
    criticidade
    tipoReacao
    grauCerteza
    categoriaSubstanciaEspecificaLegado
    substanciaEspecificaLegado
  }
`
export const CidadaoBasicoFragmentDoc = gql`
  fragment CidadaoBasico on CidadaoBasico {
    id
    nome
    nomeSocial
    cpf
    cns
    nomeMae
    dataNascimento
    presenteListaAtendimento
  }
`
export const EncaminhamentoExternoEspecializadoFragmentDoc = gql`
  fragment EncaminhamentoExternoEspecializado on EncaminhamentoExternoEspecializado {
    id
    motivoEncaminhamento
    classificacaoRiscoEncaminhamento
    complemento
    observacoes
    especialidadeSisreg {
      id
      descricao
    }
    hipoteseDiagnosticoCid10 {
      id
      codigo
      nome
    }
    hipoteseDiagnosticoCiap {
      id
      codigo
      descricao
    }
    atendimentoProfissional {
      id
      iniciadoEm
      lotacao {
        id
        profissional {
          id
        }
      }
    }
  }
`
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    number
    size
    totalPages
    totalElements
    sort
    first
    last
    numberOfElements
  }
`
export const ProcedimentoFragmentDoc = gql`
  fragment Procedimento on Procedimento {
    id
    descricao
    codigo
    procedimentoReferencia {
      id
      descricao
      codigo
    }
  }
`
export const ResultadoExameFragmentDoc = gql`
  fragment ResultadoExame on ResultadoExame {
    id
    exame {
      ...Procedimento
    }
    solicitacao {
      id
      data
      atendimentoProfissional {
        id
        lotacao {
          id
          cbo {
            id
            nome
          }
          unidadeSaude {
            id
            nome
          }
          profissional {
            id
            nome
          }
        }
      }
    }
    especifico {
      id
      exameEspecificoEnum
      valor
      igDias
      igSemanas
      dpp
      resultadoExamePuericulturaEnum
    }
    dataResultado
    dataRealizacao
    dataSolicitacao
    conferido
    observacao
    resultado
    atendimentoProfissional {
      id
      lotacao {
        id
        cbo {
          id
          nome
        }
        unidadeSaude {
          id
          nome
        }
        profissional {
          id
          nome
        }
      }
    }
  }
  ${ProcedimentoFragmentDoc}
`
export const SolicitacaoExameFragmentDoc = gql`
  fragment SolicitacaoExame on SolicitacaoExame {
    id
    data
    tipoExame
    justificativa
    observacoes
    exames {
      id
      conferido
      observacao
      solicitacao {
        id
        data
      }
      exame {
        ...Procedimento
      }
    }
    cid10 {
      ...Cid10
      ciapsRelacionadas {
        id
      }
    }
    atendimentoProfissional {
      id
      lotacao {
        id
        cbo {
          id
          nome
        }
        unidadeSaude {
          id
          nome
        }
        profissional {
          id
          nome
        }
      }
    }
  }
  ${ProcedimentoFragmentDoc}
  ${Cid10FragmentDoc}
`
export const SubstanciaEspecificaAlergiaFragmentDoc = gql`
  fragment SubstanciaEspecificaAlergia on SubstanciaEspecificaAlergia {
    id
    coSubstanciaEspecifica
    categoria
    nome
  }
`
export const AgendamentoFragmentDoc = gql`
  fragment Agendamento on Agendado {
    id
    horarioInicial
    horarioFinal
    dataCriacao
    origem
    observacao
    situacao
    situacaoSincronizacao
    isForaUbs
    isAtencaoDomiciliar
    tipoAgendamento
    cidadao {
      id
      nome
      nomeSocial
    }
    localAtendimento {
      id
      localAtendimentoExibicao
    }
  }
`
export const LotacaoAssociadaAgendamentoFragmentDoc = gql`
  fragment LotacaoAssociadaAgendamento on Lotacao {
    id
    ativo
    profissional {
      id
      nome
    }
    cbo {
      id
      nome
      cbo2002
    }
    equipe {
      id
      nome
      ine
      tipoEquipe {
        id
        descricao
      }
    }
  }
`
export const EstagioAssociadoAgendamentoFragmentDoc = gql`
  fragment EstagioAssociadoAgendamento on Estagio {
    id
    ativo
    profissional {
      id
      nome
    }
    cbo {
      id
      nome
      cbo2002
    }
    equipe {
      id
      nome
      ine
      tipoEquipe {
        id
        descricao
      }
    }
  }
`
export const ResponsavelAtendimentoObservacaoFragmentDoc = gql`
  fragment ResponsavelAtendimentoObservacao on Lotacao {
    id
    profissional {
      id
      nome
      cpf
    }
  }
`
export const ExamePreNatalFragmentDoc = gql`
  fragment ExamePreNatal on ResultadoExame {
    id
    dataRealizacao
    especifico {
      id
      igSemanas
      igDias
      dpp
    }
  }
`
export const CuidadoCompartilhadoDwFragmentDoc = gql`
  fragment CuidadoCompartilhadoDw on Historico {
    ciapNome
    ciapCodigo
    cidNome
    cidCodigo
    classificacaoPrioridade
    reclassificacaoPrioridade
    conduta
    cnsSolicitante
    nomeSolicitante
    cboSolicitante
    ineSolicitante
    siglaEquipeSolicitante
    nomeUbsSolicitante
    cnsExecutante
    nomeExecutante
    cboExecutante
    ineExecutante
    siglaEquipeExecutante
    nomeUbsExecutante
  }
`
export const HistoricoAntecedentesPecFragmentFragmentDoc = gql`
  fragment HistoricoAntecedentesPecFragment on AtendimentoProfissional {
    id
    antecedentes {
      id
      observacoes
      puericultura {
        tipoGravidez
        tipoParto
        idadeGestacionalDias
        idadeGestacionalSemanas
        apgarUm
        apgarCinco
        apgarDez
        peso
        comprimento
        perimetroCefalico
      }
      informacoesObstetricas {
        gestaPrevias
        abortos
        partoMenosDeUmAno
        partos
        partosVaginais
        partosCesareas
        partosDomiciliares
        nascidosVivos
        natimortos
        recemNascidoAbaixo
        recemNascidoAcima
        filhosVivos
        obitoAntesPrimeiraSemana
        obitoAposPrimeiraSemana
      }
    }
    cirurgiasInternacoes {
      id
      descricao
      observacoes
      dataCirurgiaInternacao
    }
  }
`
export const HistoricoAvaliacaoPecFragmentFragmentDoc = gql`
  fragment HistoricoAvaliacaoPecFragment on AtendimentoProfissional {
    id
    evolucaoAvaliacao {
      id
      descricao
      ciapsCids {
        id
        ciap {
          ...Ciap
        }
        cid10 {
          ...Cid10
        }
        nota
        isCiapCidNaListaDeProblemas
      }
    }
    evolucaoProblema {
      id
      problema {
        id
        cid10 {
          ...Cid10
        }
        ciap {
          ...Ciap
        }
        descricaoOutro
        situacao
      }
      dataInicio
      dataFim
      situacao
      observacao
      atualizadoEm
      possuiCid
      possuiCiap
    }
    alergiaEvolucao {
      id
      alergia {
        id
        substanciaEspecifica {
          id
          categoria
          nome
          sinonimos
          sigla
        }
        substanciaEspecificaLegado
        categoriaSubstanciaEspecificaLegado
      }
      criticidade
      dataInicio
      observacao
      tipoReacao
      grauCerteza
      manifestacoes {
        id
        nome
        sinonimo
      }
      manifestacoesLegado
      possuiSubstanciaEspecificaCbara
    }
  }
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
`
export const HistoricoDesfechoPecFragmentFragmentDoc = gql`
  fragment HistoricoDesfechoPecFragment on AtendimentoProfissional {
    id
    racionalidadeSaude
    condutas
    procedimentosAtendimento {
      id
      procedimento {
        id
        codigo
        descricao
      }
    }
    notificacoesCasoSuspeito {
      id
      notificacao {
        id
        nomeNotificacao
      }
    }
  }
`
export const HistoricoDesfechoOdontoPecFragmentFragmentDoc = gql`
  fragment HistoricoDesfechoOdontoPecFragment on AtendimentoProfissional {
    id
    ...HistoricoDesfechoPecFragment
    atendimentoProfissionalOdonto {
      id
      cdsTipoFornecimOdonto
      tipoConduta
    }
  }
  ${HistoricoDesfechoPecFragmentFragmentDoc}
`
export const HistoricoFinalizadorObservacaoFragmentFragmentDoc = gql`
  fragment HistoricoFinalizadorObservacaoFragment on AtendimentoProfissional {
    atendimentoProfissionalObservacao {
      id
      atendimentoObservacao {
        id
        atendimentoProfissionalFinalizador {
          id
          lotacao {
            id
            profissional {
              id
              nome
            }
            unidadeSaude {
              id
              nome
              cnes
              tipoEstabelecimento
            }
            ...AcessoLotacao
          }
        }
      }
    }
  }
  ${AcessoLotacaoFragmentDoc}
`
export const HistoricoInfoAdministrativasPecFragmentFragmentDoc = gql`
  fragment HistoricoInfoAdministrativasPecFragment on AtendimentoProfissional {
    id
    estagio {
      id
      profissional {
        id
        nome
      }
      cbo {
        id
        nome
      }
      equipe {
        id
        nome
        ine
      }
    }
    atendimento {
      id
      localAtendimento {
        id
        localAtendimentoExibicao
      }
      isRegistroTardio
      dataCriacaoRegistro
    }
    lotacao {
      id
      profissional {
        id
        nome
      }
      unidadeSaude {
        id
        nome
        cnes
        tipoEstabelecimento
      }
      ...AcessoLotacao
    }
    lotacaoAtendimentoCompartilhado {
      id
      profissional {
        id
        nome
      }
      unidadeSaude {
        id
        nome
        cnes
      }
      ...AcessoLotacao
    }
    tipoParticipacaoCidadao
    tipoParticipacaoProfissionalConvidado
    ...HistoricoFinalizadorObservacaoFragment
  }
  ${AcessoLotacaoFragmentDoc}
  ${HistoricoFinalizadorObservacaoFragmentFragmentDoc}
`
export const MedicoesFragmentFragmentDoc = gql`
  fragment MedicoesFragment on Medicao {
    id
    valorPeso
    valorAltura
    valorFrequenciaCardiaca
    valorPressaoArterial
    valorPerimetroCefalico
    valorFrequenciaRespiratoria
    valorTemperatura
    valorSaturacaoO2
    valorGlicemia
    tipoGlicemia
    perimetroPanturrilha
    valorCircunferenciaAbdominal
  }
`
export const HistoricoObjetivoPecFragmentFragmentDoc = gql`
  fragment HistoricoObjetivoPecFragment on AtendimentoProfissional {
    id
    evolucaoObjetivo {
      id
      descricao
    }
    medicoes {
      ...MedicoesFragment
      valorImc
      dum
      valorVacinacaoEmDia
    }
    marcadorConsumoAlimentar {
      id
      categoria
      respostas {
        pergunta
        opcoesSelecionadas
      }
    }
    examesResultados {
      id
      exame {
        ...Procedimento
      }
      dataSolicitacao
      dataRealizacao
      dataResultado
      resultado
      especifico {
        id
        exameEspecificoEnum
        valor
        igDias
        igSemanas
        dpp
        resultadoExamePuericulturaEnum
      }
    }
  }
  ${MedicoesFragmentFragmentDoc}
  ${ProcedimentoFragmentDoc}
`
export const HistoricoPlanoCuidadoFragmentFragmentDoc = gql`
  fragment HistoricoPlanoCuidadoFragment on AtendimentoProfissional {
    id
    planosDeCuidado {
      id
      descricao
      dataDescricao
    }
  }
`
export const CboHistoricoCuidadoCompartilhadoHeaderFragmentDoc = gql`
  fragment CboHistoricoCuidadoCompartilhadoHeader on Cbo {
    id
    nome
  }
`
export const UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragmentDoc = gql`
  fragment UnidadeSaudeHistoricoCuidadoCompartilhadoHeader on UnidadeSaude {
    id
    nome
    cnes
  }
`
export const LotacaoHistoricoCuidadoCompartilhadoHeaderFragmentDoc = gql`
  fragment LotacaoHistoricoCuidadoCompartilhadoHeader on Lotacao {
    id
    profissional {
      id
      cns
      nome
    }
    cbo {
      ...CboHistoricoCuidadoCompartilhadoHeader
    }
    equipe {
      id
      ine
      nome
    }
    unidadeSaude {
      ...UnidadeSaudeHistoricoCuidadoCompartilhadoHeader
    }
  }
  ${CboHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
  ${UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
`
export const HistoricoPlanoPecCuidadoCompartilhadoFragmentDoc = gql`
  fragment HistoricoPlanoPecCuidadoCompartilhado on CuidadoCompartilhado {
    id
    discussao
    ciap {
      ...Ciap
    }
    cid10 {
      ...Cid10
    }
    cidadaoAceitaAtendTic
    primeiraEvolucao {
      classificacaoPrioridade
      lotacaoExecutante {
        ...LotacaoHistoricoCuidadoCompartilhadoHeader
      }
      cboExecutante {
        ...CboHistoricoCuidadoCompartilhadoHeader
      }
      unidadeSaudeExecutante {
        ...UnidadeSaudeHistoricoCuidadoCompartilhadoHeader
      }
    }
  }
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
  ${LotacaoHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
  ${CboHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
  ${UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
`
export const HistoricoPlanoPecGuiaEncaminhamentoFragmentDoc = gql`
  fragment HistoricoPlanoPecGuiaEncaminhamento on GuiaEncaminhamento {
    id
    discussao
    classificacaoPrioridade
    ciap {
      ...Ciap
    }
    cid10 {
      ...Cid10
    }
    cbo {
      id
      nome
    }
  }
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
`
export const HistoricoPlanoPecFragmentFragmentDoc = gql`
  fragment HistoricoPlanoPecFragment on AtendimentoProfissional {
    id
    evolucaoPlano {
      id
      descricao
      intervencoesProcedimentos {
        id
        ciap {
          ...Ciap
        }
        procedimento {
          id
          descricao
          codigo
        }
        nota
      }
    }
    quantidadeAtestados
    quantidadeOrientacoes
    encaminhamentos
    encaminhamentosEspecializados {
      id
      observacoes
      motivoEncaminhamento
      hipoteseDiagnosticoCid10 {
        id
        codigo
        nome
      }
      hipoteseDiagnosticoCiap {
        id
        codigo
        descricao
      }
      complemento
      classificacaoRiscoEncaminhamento
      especialidadeSisreg {
        id
        descricao
      }
    }
    requisicoesExames {
      id
      justificativa
      observacoes
      cid10 {
        id
        codigo
        nome
      }
      exames {
        id
        observacao
        exame {
          ...Procedimento
        }
      }
    }
    receitaMedicamento {
      id
      medicamento {
        id
        principioAtivo
        concentracao
        formaFarmaceutica {
          id
          nome
        }
      }
      aplicacaoMedicamento {
        id
        nome
      }
      quantidadeReceitada
      recomendacoes
      posologia
      dtInicioTratamento
      dtFimTratamento
      medidaTempoTratamento
    }
    cuidadosCompartilhados {
      ...HistoricoPlanoPecCuidadoCompartilhado
    }
    guiasEncaminhamento {
      ...HistoricoPlanoPecGuiaEncaminhamento
    }
  }
  ${CiapFragmentDoc}
  ${ProcedimentoFragmentDoc}
  ${HistoricoPlanoPecCuidadoCompartilhadoFragmentDoc}
  ${HistoricoPlanoPecGuiaEncaminhamentoFragmentDoc}
`
export const HistoricoRegistrosAnterioresVacinacaoFragmentFragmentDoc = gql`
  fragment HistoricoRegistrosAnterioresVacinacaoFragment on AtendimentoProfissional {
    id
    vacinacao {
      id
      registrosVacinacao {
        id
        imunobiologico {
          id
          nome
        }
        doseImunobiologico {
          id
          sigla
        }
        dataAplicacao
        tipoRegistroVacinacao {
          id
          identificador
        }
        loteImunobiologico {
          id
          lote
          fabricante {
            id
            nome
          }
        }
        isRegistroAnterior
        isAplicadoExterior
        observacoes
        estrategiaVacinacao {
          id
          nome
        }
      }
    }
  }
`
export const HistoricoSubjetivoPecFragmentFragmentDoc = gql`
  fragment HistoricoSubjetivoPecFragment on AtendimentoProfissional {
    id
    evolucaoSubjetivo {
      id
      descricao
      ciaps {
        id
        ciap {
          ...Ciap
        }
        nota
      }
    }
  }
  ${CiapFragmentDoc}
`
export const HistoricoCuidadoCompartilhadoEvolucaoHeaderFragmentDoc = gql`
  fragment HistoricoCuidadoCompartilhadoEvolucaoHeader on CuidadoCompartilhadoEvolucao {
    id
    conduta
    classificacaoPrioridade
    reclassificacaoPrioridade
    lotacaoExecutante {
      ...LotacaoHistoricoCuidadoCompartilhadoHeader
    }
    cboExecutante {
      ...CboHistoricoCuidadoCompartilhadoHeader
    }
    unidadeSaudeExecutante {
      ...UnidadeSaudeHistoricoCuidadoCompartilhadoHeader
    }
  }
  ${LotacaoHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
  ${CboHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
  ${UnidadeSaudeHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
`
export const HistoricoCuidadoCompartilhadoHeaderFragmentDoc = gql`
  fragment HistoricoCuidadoCompartilhadoHeader on CuidadoCompartilhado {
    id
    cid10 {
      ...Cid10
    }
    ciap {
      ...Ciap
    }
    lotacaoSolicitante {
      ...LotacaoHistoricoCuidadoCompartilhadoHeader
    }
  }
  ${Cid10FragmentDoc}
  ${CiapFragmentDoc}
  ${LotacaoHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
`
export const GrupoMedicoesCardFragmentDoc = gql`
  fragment GrupoMedicoesCard on GrupoMedicoesTipo {
    tipo
    medicoes {
      dataMedicao
      valor
      observacao
    }
  }
`
export const LotacaoSugestaoAgendamentoFragmentDoc = gql`
  fragment LotacaoSugestaoAgendamento on Lotacao {
    id
    profissional {
      id
      nome
    }
    cbo {
      id
      nome
      cbo2002
    }
    equipe {
      id
      ine
      nome
    }
  }
`
export const CompartilhamentoCuidadoLotacaoFragmentDoc = gql`
  fragment CompartilhamentoCuidadoLotacao on Lotacao {
    id
    profissional {
      id
      nome
      cpf
      cns
    }
  }
`
export const ResponsavelAtendimentoCommonFragmentDoc = gql`
  fragment ResponsavelAtendimentoCommon on LotacaoOuEstagio {
    ... on Lotacao {
      unidadeSaude {
        id
        nome
        cnes
      }
      equipe {
        id
        ine
        nome
        isAD
        tipoEquipe {
          id
          nome
          sigla
        }
      }
    }
    ... on Estagio {
      unidadeSaude {
        id
        nome
        cnes
      }
      equipe {
        id
        ine
        nome
        isAD
        tipoEquipe {
          id
          nome
          sigla
        }
      }
    }
  }
`
export const ResponsavelAtendimentoLotacaoFragmentDoc = gql`
  fragment ResponsavelAtendimentoLotacao on Lotacao {
    cbo {
      id
      nome
      cbo2002
    }
    ...ResponsavelAtendimentoCommon
  }
  ${ResponsavelAtendimentoCommonFragmentDoc}
`
export const ResponsavelAtendimentoEstagioFragmentDoc = gql`
  fragment ResponsavelAtendimentoEstagio on Estagio {
    cbo {
      id
      nome
      cbo2002
    }
    ...ResponsavelAtendimentoCommon
  }
  ${ResponsavelAtendimentoCommonFragmentDoc}
`
export const CuidadoCompartilhadoEvolucaoGarantiaAcessoFragmentFragmentDoc = gql`
  fragment CuidadoCompartilhadoEvolucaoGarantiaAcessoFragment on CuidadoCompartilhadoEvolucao {
    id
    cuidadoCompartilhado {
      id
      lotacaoSolicitante {
        id
        equipe {
          id
          nome
          area
        }
        profissional {
          id
          nome
          email
        }
        cbo {
          id
          nome
        }
      }
      classificacaoPrioridadeAtual
    }
    lotacaoExecutante {
      id
      equipe {
        id
        nome
        area
      }
      profissional {
        id
        nome
        email
      }
      cbo {
        id
        nome
      }
    }
    condutaAgendamento: enviarGarantiaAcesso
  }
`
export const ProfissionalDetailFragmentDoc = gql`
  fragment ProfissionalDetail on Profissional {
    cns
    conselhoClasse {
      id
      descricao
      sigla
    }
    cpf
    dataNascimento
    email
    endereco {
      bairro
      cep
      complemento
      logradouro
      numero
      pontoReferencia
      municipio {
        id
        ibge
        nome
        uf {
          id
          nome
          sigla
        }
      }
      semNumero
      tipoLogradouro {
        id
        nome
        numeroDne
      }
      uf {
        id
        nome
        sigla
      }
    }
    id
    nome
    numeroConselhoClasse
    sexo
    telefone
    ufEmissoraConselhoClasse {
      id
      nome
      sigla
    }
  }
`
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      ...SessaoData
    }
  }
  ${SessaoDataFragmentDoc}
`
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<Types.LogoutMutation, Types.LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.LogoutMutation, Types.LogoutMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.LogoutMutation, Types.LogoutMutationVariables>(LogoutDocument, baseOptions)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = ApolloReactCommon.MutationResult<Types.LogoutMutation>
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.LogoutMutation,
  Types.LogoutMutationVariables
>
export const SessaoDocument = gql`
  query Sessao {
    sessao {
      ...SessaoData
    }
  }
  ${SessaoDataFragmentDoc}
`

/**
 * __useSessaoQuery__
 *
 * To run a query within a React component, call `useSessaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessaoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.SessaoQuery, Types.SessaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.SessaoQuery, Types.SessaoQueryVariables>(SessaoDocument, baseOptions)
}
export function useSessaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SessaoQuery, Types.SessaoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.SessaoQuery, Types.SessaoQueryVariables>(SessaoDocument, baseOptions)
}
export type SessaoQueryHookResult = ReturnType<typeof useSessaoQuery>
export type SessaoLazyQueryHookResult = ReturnType<typeof useSessaoLazyQuery>
export type SessaoQueryResult = ApolloReactCommon.QueryResult<Types.SessaoQuery, Types.SessaoQueryVariables>
export const EsusViewMountTargetDocument = gql`
  query EsusViewMountTarget {
    info {
      oldPecConnected
    }
  }
`

/**
 * __useEsusViewMountTargetQuery__
 *
 * To run a query within a React component, call `useEsusViewMountTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsusViewMountTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsusViewMountTargetQuery({
 *   variables: {
 *   },
 * });
 */
export function useEsusViewMountTargetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EsusViewMountTargetQuery,
    Types.EsusViewMountTargetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EsusViewMountTargetQuery, Types.EsusViewMountTargetQueryVariables>(
    EsusViewMountTargetDocument,
    baseOptions
  )
}
export function useEsusViewMountTargetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EsusViewMountTargetQuery,
    Types.EsusViewMountTargetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EsusViewMountTargetQuery, Types.EsusViewMountTargetQueryVariables>(
    EsusViewMountTargetDocument,
    baseOptions
  )
}
export type EsusViewMountTargetQueryHookResult = ReturnType<typeof useEsusViewMountTargetQuery>
export type EsusViewMountTargetLazyQueryHookResult = ReturnType<typeof useEsusViewMountTargetLazyQuery>
export type EsusViewMountTargetQueryResult = ApolloReactCommon.QueryResult<
  Types.EsusViewMountTargetQuery,
  Types.EsusViewMountTargetQueryVariables
>
export const EnderecoDocument = gql`
  query Endereco($cep: String!) {
    endereco(cep: $cep) {
      municipio {
        id
        nome
      }
      uf {
        id
        nome
        sigla
      }
      logradouro
      tipoLogradouro {
        id
        nome
        numeroDne
      }
      bairro {
        id
        nome
      }
    }
  }
`

/**
 * __useEnderecoQuery__
 *
 * To run a query within a React component, call `useEnderecoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnderecoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnderecoQuery({
 *   variables: {
 *      cep: // value for 'cep'
 *   },
 * });
 */
export function useEnderecoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EnderecoQuery, Types.EnderecoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EnderecoQuery, Types.EnderecoQueryVariables>(EnderecoDocument, baseOptions)
}
export function useEnderecoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EnderecoQuery, Types.EnderecoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EnderecoQuery, Types.EnderecoQueryVariables>(EnderecoDocument, baseOptions)
}
export type EnderecoQueryHookResult = ReturnType<typeof useEnderecoQuery>
export type EnderecoLazyQueryHookResult = ReturnType<typeof useEnderecoLazyQuery>
export type EnderecoQueryResult = ApolloReactCommon.QueryResult<Types.EnderecoQuery, Types.EnderecoQueryVariables>
export const AlergiaSelectFieldDocument = gql`
  query AlergiaSelectField($filtro: AlergiasQueryInput!) {
    alergias(input: $filtro) {
      content {
        ...Alergia
        substanciaEspecifica {
          ...SubstanciaEspecificaAlergia
          sigla
        }
        ultimaEvolucao {
          id
          dataInicio
          manifestacoesLegado
          possuiSubstanciaEspecificaCbara
          manifestacoes {
            id
            nome
            sinonimo
          }
        }
      }
    }
  }
  ${AlergiaFragmentDoc}
  ${SubstanciaEspecificaAlergiaFragmentDoc}
`

/**
 * __useAlergiaSelectFieldQuery__
 *
 * To run a query within a React component, call `useAlergiaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlergiaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlergiaSelectFieldQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useAlergiaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AlergiaSelectFieldQuery, Types.AlergiaSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AlergiaSelectFieldQuery, Types.AlergiaSelectFieldQueryVariables>(
    AlergiaSelectFieldDocument,
    baseOptions
  )
}
export function useAlergiaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AlergiaSelectFieldQuery,
    Types.AlergiaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AlergiaSelectFieldQuery, Types.AlergiaSelectFieldQueryVariables>(
    AlergiaSelectFieldDocument,
    baseOptions
  )
}
export type AlergiaSelectFieldQueryHookResult = ReturnType<typeof useAlergiaSelectFieldQuery>
export type AlergiaSelectFieldLazyQueryHookResult = ReturnType<typeof useAlergiaSelectFieldLazyQuery>
export type AlergiaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.AlergiaSelectFieldQuery,
  Types.AlergiaSelectFieldQueryVariables
>
export const BairroSelectFieldDocument = gql`
  query BairroSelectField($input: BairroInput!) {
    bairros(input: $input) {
      content {
        id
        nome
        municipio {
          id
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useBairroSelectFieldQuery__
 *
 * To run a query within a React component, call `useBairroSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useBairroSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBairroSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBairroSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BairroSelectFieldQuery, Types.BairroSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BairroSelectFieldQuery, Types.BairroSelectFieldQueryVariables>(
    BairroSelectFieldDocument,
    baseOptions
  )
}
export function useBairroSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BairroSelectFieldQuery,
    Types.BairroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BairroSelectFieldQuery, Types.BairroSelectFieldQueryVariables>(
    BairroSelectFieldDocument,
    baseOptions
  )
}
export type BairroSelectFieldQueryHookResult = ReturnType<typeof useBairroSelectFieldQuery>
export type BairroSelectFieldLazyQueryHookResult = ReturnType<typeof useBairroSelectFieldLazyQuery>
export type BairroSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.BairroSelectFieldQuery,
  Types.BairroSelectFieldQueryVariables
>
export const AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldDocument = gql`
  query AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectField {
    atendimentoCompartilhadoRelatorioGerencialAtendimento
  }
`

/**
 * __useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >(AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldDocument, baseOptions)
}
export function useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >(AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldDocument, baseOptions)
}
export type AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryHookResult = ReturnType<
  typeof useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery
>
export type AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useAtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldLazyQuery
>
export type AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQuery,
  Types.AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldQueryVariables
>
export const CategoriaProfissionalRelatorioGerencialSelectFieldDocument = gql`
  query CategoriaProfissionalRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    categoriasProfissionalRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useCategoriaProfissionalRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useCategoriaProfissionalRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriaProfissionalRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriaProfissionalRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useCategoriaProfissionalRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQuery,
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQuery,
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables
  >(CategoriaProfissionalRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useCategoriaProfissionalRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQuery,
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQuery,
    Types.CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables
  >(CategoriaProfissionalRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type CategoriaProfissionalRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useCategoriaProfissionalRelatorioGerencialSelectFieldQuery
>
export type CategoriaProfissionalRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCategoriaProfissionalRelatorioGerencialSelectFieldLazyQuery
>
export type CategoriaProfissionalRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CategoriaProfissionalRelatorioGerencialSelectFieldQuery,
  Types.CategoriaProfissionalRelatorioGerencialSelectFieldQueryVariables
>
export const CboRelatorioGerencialSelectFieldDocument = gql`
  query CboRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum, $input: String) {
    cbosRelatorioGerencial(tipoModulo: $tipoModulo, input: $input)
  }
`

/**
 * __useCboRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useCboRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCboRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CboRelatorioGerencialSelectFieldQuery,
    Types.CboRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CboRelatorioGerencialSelectFieldQuery,
    Types.CboRelatorioGerencialSelectFieldQueryVariables
  >(CboRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useCboRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CboRelatorioGerencialSelectFieldQuery,
    Types.CboRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CboRelatorioGerencialSelectFieldQuery,
    Types.CboRelatorioGerencialSelectFieldQueryVariables
  >(CboRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type CboRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useCboRelatorioGerencialSelectFieldQuery
>
export type CboRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCboRelatorioGerencialSelectFieldLazyQuery
>
export type CboRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CboRelatorioGerencialSelectFieldQuery,
  Types.CboRelatorioGerencialSelectFieldQueryVariables
>
export const CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument = gql`
  query CondutaRelatorioGerencialCuidadoCompartilhadoSelectField {
    condutasRelatorioGerencialCuidadoCompartilhado
  }
`

/**
 * __useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery
>
export type CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery
>
export type CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  Types.CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
>
export const DiaDaSemanaRelatorioGerencialSelectFieldDocument = gql`
  query DiaDaSemanaRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    diasDaSemanaRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useDiaDaSemanaRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useDiaDaSemanaRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiaDaSemanaRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiaDaSemanaRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useDiaDaSemanaRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQuery,
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQuery,
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQueryVariables
  >(DiaDaSemanaRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useDiaDaSemanaRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQuery,
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQuery,
    Types.DiaDaSemanaRelatorioGerencialSelectFieldQueryVariables
  >(DiaDaSemanaRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type DiaDaSemanaRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useDiaDaSemanaRelatorioGerencialSelectFieldQuery
>
export type DiaDaSemanaRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useDiaDaSemanaRelatorioGerencialSelectFieldLazyQuery
>
export type DiaDaSemanaRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.DiaDaSemanaRelatorioGerencialSelectFieldQuery,
  Types.DiaDaSemanaRelatorioGerencialSelectFieldQueryVariables
>
export const DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldDocument = gql`
  query DoseImunobiologicoRelatorioGerencialVacinacaoSelectField($input: String) {
    dosesImunobiologicosRelatorioGerencialVacinacao(input: $input)
  }
`

/**
 * __useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export function useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export type DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryHookResult = ReturnType<
  typeof useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery
>
export type DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useDoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldLazyQuery
>
export type DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
  Types.DoseImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
>
export const EquipeRelatorioGerencialSelectFieldDocument = gql`
  query EquipeRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum, $input: String) {
    equipesRelatorioGerencial(tipoModulo: $tipoModulo, input: $input)
  }
`

/**
 * __useEquipeRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useEquipeRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EquipeRelatorioGerencialSelectFieldQuery,
    Types.EquipeRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EquipeRelatorioGerencialSelectFieldQuery,
    Types.EquipeRelatorioGerencialSelectFieldQueryVariables
  >(EquipeRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useEquipeRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeRelatorioGerencialSelectFieldQuery,
    Types.EquipeRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EquipeRelatorioGerencialSelectFieldQuery,
    Types.EquipeRelatorioGerencialSelectFieldQueryVariables
  >(EquipeRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type EquipeRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useEquipeRelatorioGerencialSelectFieldQuery
>
export type EquipeRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useEquipeRelatorioGerencialSelectFieldLazyQuery
>
export type EquipeRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeRelatorioGerencialSelectFieldQuery,
  Types.EquipeRelatorioGerencialSelectFieldQueryVariables
>
export const EstrategiaRelatorioGerencialVacinacaoSelectFieldDocument = gql`
  query EstrategiaRelatorioGerencialVacinacaoSelectField {
    estrategiasRelatorioGerencialVacinacao
  }
`

/**
 * __useEstrategiaRelatorioGerencialVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useEstrategiaRelatorioGerencialVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstrategiaRelatorioGerencialVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstrategiaRelatorioGerencialVacinacaoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstrategiaRelatorioGerencialVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(EstrategiaRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export function useEstrategiaRelatorioGerencialVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(EstrategiaRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export type EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryHookResult = ReturnType<
  typeof useEstrategiaRelatorioGerencialVacinacaoSelectFieldQuery
>
export type EstrategiaRelatorioGerencialVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useEstrategiaRelatorioGerencialVacinacaoSelectFieldLazyQuery
>
export type EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQuery,
  Types.EstrategiaRelatorioGerencialVacinacaoSelectFieldQueryVariables
>
export const FaixaEtariaRelatorioGerencialSelectFieldDocument = gql`
  query FaixaEtariaRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    faixasEtariaRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useFaixaEtariaRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useFaixaEtariaRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaixaEtariaRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaixaEtariaRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useFaixaEtariaRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.FaixaEtariaRelatorioGerencialSelectFieldQuery,
    Types.FaixaEtariaRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.FaixaEtariaRelatorioGerencialSelectFieldQuery,
    Types.FaixaEtariaRelatorioGerencialSelectFieldQueryVariables
  >(FaixaEtariaRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useFaixaEtariaRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.FaixaEtariaRelatorioGerencialSelectFieldQuery,
    Types.FaixaEtariaRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.FaixaEtariaRelatorioGerencialSelectFieldQuery,
    Types.FaixaEtariaRelatorioGerencialSelectFieldQueryVariables
  >(FaixaEtariaRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type FaixaEtariaRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useFaixaEtariaRelatorioGerencialSelectFieldQuery
>
export type FaixaEtariaRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useFaixaEtariaRelatorioGerencialSelectFieldLazyQuery
>
export type FaixaEtariaRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.FaixaEtariaRelatorioGerencialSelectFieldQuery,
  Types.FaixaEtariaRelatorioGerencialSelectFieldQueryVariables
>
export const GestanteRelatorioGerencialVacinacaoSelectFieldDocument = gql`
  query GestanteRelatorioGerencialVacinacaoSelectField {
    gestanteRelatorioGerencialVacinacao
  }
`

/**
 * __useGestanteRelatorioGerencialVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useGestanteRelatorioGerencialVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGestanteRelatorioGerencialVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGestanteRelatorioGerencialVacinacaoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useGestanteRelatorioGerencialVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(GestanteRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export function useGestanteRelatorioGerencialVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.GestanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(GestanteRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export type GestanteRelatorioGerencialVacinacaoSelectFieldQueryHookResult = ReturnType<
  typeof useGestanteRelatorioGerencialVacinacaoSelectFieldQuery
>
export type GestanteRelatorioGerencialVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useGestanteRelatorioGerencialVacinacaoSelectFieldLazyQuery
>
export type GestanteRelatorioGerencialVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.GestanteRelatorioGerencialVacinacaoSelectFieldQuery,
  Types.GestanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
>
export const HorarioRelatorioGerencialSelectFieldDocument = gql`
  query HorarioRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    horariosRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useHorarioRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useHorarioRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useHorarioRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHorarioRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useHorarioRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HorarioRelatorioGerencialSelectFieldQuery,
    Types.HorarioRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HorarioRelatorioGerencialSelectFieldQuery,
    Types.HorarioRelatorioGerencialSelectFieldQueryVariables
  >(HorarioRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useHorarioRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HorarioRelatorioGerencialSelectFieldQuery,
    Types.HorarioRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HorarioRelatorioGerencialSelectFieldQuery,
    Types.HorarioRelatorioGerencialSelectFieldQueryVariables
  >(HorarioRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type HorarioRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useHorarioRelatorioGerencialSelectFieldQuery
>
export type HorarioRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useHorarioRelatorioGerencialSelectFieldLazyQuery
>
export type HorarioRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.HorarioRelatorioGerencialSelectFieldQuery,
  Types.HorarioRelatorioGerencialSelectFieldQueryVariables
>
export const ImunobiologicoRelatorioGerencialVacinacaoSelectFieldDocument = gql`
  query ImunobiologicoRelatorioGerencialVacinacaoSelectField($input: String) {
    imunobiologicosRelatorioGerencialVacinacao(input: $input)
  }
`

/**
 * __useImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(ImunobiologicoRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export function useImunobiologicoRelatorioGerencialVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(ImunobiologicoRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export type ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryHookResult = ReturnType<
  typeof useImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery
>
export type ImunobiologicoRelatorioGerencialVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useImunobiologicoRelatorioGerencialVacinacaoSelectFieldLazyQuery
>
export type ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQuery,
  Types.ImunobiologicoRelatorioGerencialVacinacaoSelectFieldQueryVariables
>
export const MesRelatorioGerencialSelectFieldDocument = gql`
  query MesRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    mesesRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useMesRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useMesRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMesRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMesRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useMesRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MesRelatorioGerencialSelectFieldQuery,
    Types.MesRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.MesRelatorioGerencialSelectFieldQuery,
    Types.MesRelatorioGerencialSelectFieldQueryVariables
  >(MesRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useMesRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MesRelatorioGerencialSelectFieldQuery,
    Types.MesRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.MesRelatorioGerencialSelectFieldQuery,
    Types.MesRelatorioGerencialSelectFieldQueryVariables
  >(MesRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type MesRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useMesRelatorioGerencialSelectFieldQuery
>
export type MesRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useMesRelatorioGerencialSelectFieldLazyQuery
>
export type MesRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.MesRelatorioGerencialSelectFieldQuery,
  Types.MesRelatorioGerencialSelectFieldQueryVariables
>
export const MunicipioRelatorioGerencialSelectFieldDocument = gql`
  query MunicipioRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum, $input: String) {
    municipiosRelatorioGerencial(tipoModulo: $tipoModulo, input: $input)
  }
`

/**
 * __useMunicipioRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useMunicipioRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMunicipioRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMunicipioRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMunicipioRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MunicipioRelatorioGerencialSelectFieldQuery,
    Types.MunicipioRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.MunicipioRelatorioGerencialSelectFieldQuery,
    Types.MunicipioRelatorioGerencialSelectFieldQueryVariables
  >(MunicipioRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useMunicipioRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MunicipioRelatorioGerencialSelectFieldQuery,
    Types.MunicipioRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.MunicipioRelatorioGerencialSelectFieldQuery,
    Types.MunicipioRelatorioGerencialSelectFieldQueryVariables
  >(MunicipioRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type MunicipioRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useMunicipioRelatorioGerencialSelectFieldQuery
>
export type MunicipioRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useMunicipioRelatorioGerencialSelectFieldLazyQuery
>
export type MunicipioRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.MunicipioRelatorioGerencialSelectFieldQuery,
  Types.MunicipioRelatorioGerencialSelectFieldQueryVariables
>
export const PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument = gql`
  query PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectField {
    primeirasRespostasRelatorioGerencialCuidadoCompartilhado
  }
`

/**
 * __usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery
>
export type PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof usePrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery
>
export type PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  Types.PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
>
export const PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument = gql`
  query PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectField {
    prioridadesRelatorioGerencialCuidadoCompartilhado
  }
`

/**
 * __usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery
>
export type PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof usePrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery
>
export type PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  Types.PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
>
export const ProfissionalRelatorioGerencialSelectFieldDocument = gql`
  query ProfissionalRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum, $input: String) {
    profissionaisRelatorioGerencial(tipoModulo: $tipoModulo, input: $input)
  }
`

/**
 * __useProfissionalRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useProfissionalRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfissionalRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionalRelatorioGerencialSelectFieldQuery,
    Types.ProfissionalRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProfissionalRelatorioGerencialSelectFieldQuery,
    Types.ProfissionalRelatorioGerencialSelectFieldQueryVariables
  >(ProfissionalRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useProfissionalRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalRelatorioGerencialSelectFieldQuery,
    Types.ProfissionalRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProfissionalRelatorioGerencialSelectFieldQuery,
    Types.ProfissionalRelatorioGerencialSelectFieldQueryVariables
  >(ProfissionalRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type ProfissionalRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useProfissionalRelatorioGerencialSelectFieldQuery
>
export type ProfissionalRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useProfissionalRelatorioGerencialSelectFieldLazyQuery
>
export type ProfissionalRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalRelatorioGerencialSelectFieldQuery,
  Types.ProfissionalRelatorioGerencialSelectFieldQueryVariables
>
export const PuerperaRelatorioGerencialVacinacaoSelectFieldDocument = gql`
  query PuerperaRelatorioGerencialVacinacaoSelectField {
    puerperaRelatorioGerencialVacinacao
  }
`

/**
 * __usePuerperaRelatorioGerencialVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `usePuerperaRelatorioGerencialVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePuerperaRelatorioGerencialVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePuerperaRelatorioGerencialVacinacaoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function usePuerperaRelatorioGerencialVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(PuerperaRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export function usePuerperaRelatorioGerencialVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(PuerperaRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export type PuerperaRelatorioGerencialVacinacaoSelectFieldQueryHookResult = ReturnType<
  typeof usePuerperaRelatorioGerencialVacinacaoSelectFieldQuery
>
export type PuerperaRelatorioGerencialVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof usePuerperaRelatorioGerencialVacinacaoSelectFieldLazyQuery
>
export type PuerperaRelatorioGerencialVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQuery,
  Types.PuerperaRelatorioGerencialVacinacaoSelectFieldQueryVariables
>
export const RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument = gql`
  query RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectField {
    realizadoPorRelatorioGerencialCuidadoCompartilhado
  }
`

/**
 * __useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery
>
export type RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useRealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery
>
export type RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  Types.RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
>
export const SexoRelatorioGerencialSelectFieldDocument = gql`
  query SexoRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    sexosRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useSexoRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useSexoRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSexoRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSexoRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useSexoRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SexoRelatorioGerencialSelectFieldQuery,
    Types.SexoRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.SexoRelatorioGerencialSelectFieldQuery,
    Types.SexoRelatorioGerencialSelectFieldQueryVariables
  >(SexoRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useSexoRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SexoRelatorioGerencialSelectFieldQuery,
    Types.SexoRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.SexoRelatorioGerencialSelectFieldQuery,
    Types.SexoRelatorioGerencialSelectFieldQueryVariables
  >(SexoRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type SexoRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useSexoRelatorioGerencialSelectFieldQuery
>
export type SexoRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useSexoRelatorioGerencialSelectFieldLazyQuery
>
export type SexoRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.SexoRelatorioGerencialSelectFieldQuery,
  Types.SexoRelatorioGerencialSelectFieldQueryVariables
>
export const TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument = gql`
  query TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectField {
    temposDeEsperaRelatorioGerencialCuidadoCompartilhado
  }
`

/**
 * __useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery
>
export type TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery
>
export type TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  Types.TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
>
export const TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument = gql`
  query TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectField {
    temposDeRespostaRelatorioGerencialCuidadoCompartilhado
  }
`

/**
 * __useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
    Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
  >(TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery
>
export type TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldLazyQuery
>
export type TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQuery,
  Types.TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldQueryVariables
>
export const TempoEntreAgendamentoEConsultaSelectFieldDocument = gql`
  query TempoEntreAgendamentoEConsultaSelectField {
    tempoEntreAgendamentoEConsultaRelatorioGerencialAbsenteismo
  }
`

/**
 * __useTempoEntreAgendamentoEConsultaSelectFieldQuery__
 *
 * To run a query within a React component, call `useTempoEntreAgendamentoEConsultaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTempoEntreAgendamentoEConsultaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTempoEntreAgendamentoEConsultaSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTempoEntreAgendamentoEConsultaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TempoEntreAgendamentoEConsultaSelectFieldQuery,
    Types.TempoEntreAgendamentoEConsultaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TempoEntreAgendamentoEConsultaSelectFieldQuery,
    Types.TempoEntreAgendamentoEConsultaSelectFieldQueryVariables
  >(TempoEntreAgendamentoEConsultaSelectFieldDocument, baseOptions)
}
export function useTempoEntreAgendamentoEConsultaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TempoEntreAgendamentoEConsultaSelectFieldQuery,
    Types.TempoEntreAgendamentoEConsultaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TempoEntreAgendamentoEConsultaSelectFieldQuery,
    Types.TempoEntreAgendamentoEConsultaSelectFieldQueryVariables
  >(TempoEntreAgendamentoEConsultaSelectFieldDocument, baseOptions)
}
export type TempoEntreAgendamentoEConsultaSelectFieldQueryHookResult = ReturnType<
  typeof useTempoEntreAgendamentoEConsultaSelectFieldQuery
>
export type TempoEntreAgendamentoEConsultaSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTempoEntreAgendamentoEConsultaSelectFieldLazyQuery
>
export type TempoEntreAgendamentoEConsultaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TempoEntreAgendamentoEConsultaSelectFieldQuery,
  Types.TempoEntreAgendamentoEConsultaSelectFieldQueryVariables
>
export const TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldDocument = gql`
  query TipoDeAtendimentoRelatorioGerencialAtendimentoSelectField {
    tiposDeAtendimentoRelatorioGerencialAtendimento
  }
`

/**
 * __useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >(TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldDocument, baseOptions)
}
export function useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >(TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldDocument, baseOptions)
}
export type TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryHookResult = ReturnType<
  typeof useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery
>
export type TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldLazyQuery
>
export type TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQuery,
  Types.TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldQueryVariables
>
export const TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldDocument = gql`
  query TipoDeConsultaRelatorioGerencialAtendimentoSelectField {
    tiposDeConsultaRelatorioGerencialAtendimento
  }
`

/**
 * __useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >(TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldDocument, baseOptions)
}
export function useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery,
    Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables
  >(TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldDocument, baseOptions)
}
export type TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryHookResult = ReturnType<
  typeof useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery
>
export type TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTipoDeConsultaRelatorioGerencialAtendimentoSelectFieldLazyQuery
>
export type TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQuery,
  Types.TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldQueryVariables
>
export const TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldDocument = gql`
  query TipoDeFaltaRelatorioGerencialAbsenteismoSelectField {
    tiposDeFaltaRelatorioGerencialAbsenteismo
  }
`

/**
 * __useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery,
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery,
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables
  >(TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldDocument, baseOptions)
}
export function useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery,
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery,
    Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables
  >(TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldDocument, baseOptions)
}
export type TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryHookResult = ReturnType<
  typeof useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery
>
export type TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldLazyQuery
>
export type TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQuery,
  Types.TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldQueryVariables
>
export const TipoProducaoRelatorioGerencialSelectFieldDocument = gql`
  query TipoProducaoRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    tiposProducaoRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useTipoProducaoRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoProducaoRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoProducaoRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoProducaoRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useTipoProducaoRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoProducaoRelatorioGerencialSelectFieldQuery,
    Types.TipoProducaoRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TipoProducaoRelatorioGerencialSelectFieldQuery,
    Types.TipoProducaoRelatorioGerencialSelectFieldQueryVariables
  >(TipoProducaoRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useTipoProducaoRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoProducaoRelatorioGerencialSelectFieldQuery,
    Types.TipoProducaoRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TipoProducaoRelatorioGerencialSelectFieldQuery,
    Types.TipoProducaoRelatorioGerencialSelectFieldQueryVariables
  >(TipoProducaoRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type TipoProducaoRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useTipoProducaoRelatorioGerencialSelectFieldQuery
>
export type TipoProducaoRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTipoProducaoRelatorioGerencialSelectFieldLazyQuery
>
export type TipoProducaoRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoProducaoRelatorioGerencialSelectFieldQuery,
  Types.TipoProducaoRelatorioGerencialSelectFieldQueryVariables
>
export const TurnoRelatorioGerencialSelectFieldDocument = gql`
  query TurnoRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum) {
    turnosRelatorioGerencial(tipoModulo: $tipoModulo)
  }
`

/**
 * __useTurnoRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useTurnoRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTurnoRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTurnoRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useTurnoRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TurnoRelatorioGerencialSelectFieldQuery,
    Types.TurnoRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TurnoRelatorioGerencialSelectFieldQuery,
    Types.TurnoRelatorioGerencialSelectFieldQueryVariables
  >(TurnoRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useTurnoRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TurnoRelatorioGerencialSelectFieldQuery,
    Types.TurnoRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TurnoRelatorioGerencialSelectFieldQuery,
    Types.TurnoRelatorioGerencialSelectFieldQueryVariables
  >(TurnoRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type TurnoRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useTurnoRelatorioGerencialSelectFieldQuery
>
export type TurnoRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTurnoRelatorioGerencialSelectFieldLazyQuery
>
export type TurnoRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TurnoRelatorioGerencialSelectFieldQuery,
  Types.TurnoRelatorioGerencialSelectFieldQueryVariables
>
export const UnidadeSaudeRelatorioGerencialSelectFieldDocument = gql`
  query UnidadeSaudeRelatorioGerencialSelectField($tipoModulo: TipoModuloEnum, $input: String) {
    unidadesSaudeRelatorioGerencial(tipoModulo: $tipoModulo, input: $input)
  }
`

/**
 * __useUnidadeSaudeRelatorioGerencialSelectFieldQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeRelatorioGerencialSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeRelatorioGerencialSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeRelatorioGerencialSelectFieldQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadeSaudeRelatorioGerencialSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQuery,
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQuery,
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables
  >(UnidadeSaudeRelatorioGerencialSelectFieldDocument, baseOptions)
}
export function useUnidadeSaudeRelatorioGerencialSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQuery,
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQuery,
    Types.UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables
  >(UnidadeSaudeRelatorioGerencialSelectFieldDocument, baseOptions)
}
export type UnidadeSaudeRelatorioGerencialSelectFieldQueryHookResult = ReturnType<
  typeof useUnidadeSaudeRelatorioGerencialSelectFieldQuery
>
export type UnidadeSaudeRelatorioGerencialSelectFieldLazyQueryHookResult = ReturnType<
  typeof useUnidadeSaudeRelatorioGerencialSelectFieldLazyQuery
>
export type UnidadeSaudeRelatorioGerencialSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeRelatorioGerencialSelectFieldQuery,
  Types.UnidadeSaudeRelatorioGerencialSelectFieldQueryVariables
>
export const ViajanteRelatorioGerencialVacinacaoSelectFieldDocument = gql`
  query ViajanteRelatorioGerencialVacinacaoSelectField {
    viajanteRelatorioGerencialVacinacao
  }
`

/**
 * __useViajanteRelatorioGerencialVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useViajanteRelatorioGerencialVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useViajanteRelatorioGerencialVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViajanteRelatorioGerencialVacinacaoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useViajanteRelatorioGerencialVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(ViajanteRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export function useViajanteRelatorioGerencialVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQuery,
    Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
  >(ViajanteRelatorioGerencialVacinacaoSelectFieldDocument, baseOptions)
}
export type ViajanteRelatorioGerencialVacinacaoSelectFieldQueryHookResult = ReturnType<
  typeof useViajanteRelatorioGerencialVacinacaoSelectFieldQuery
>
export type ViajanteRelatorioGerencialVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useViajanteRelatorioGerencialVacinacaoSelectFieldLazyQuery
>
export type ViajanteRelatorioGerencialVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQuery,
  Types.ViajanteRelatorioGerencialVacinacaoSelectFieldQueryVariables
>
export const CboCuidadoCompartilhadoSelectFieldDocument = gql`
  query CboCuidadoCompartilhadoSelectField($input: CbosCuidadoCompartilhadoQueryInput!) {
    cbosVinculacoesServico(input: $input) {
      content {
        cbo {
          id
          nome
          cbo2002
        }
        isVinculado
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCboCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useCboCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCboCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CboCuidadoCompartilhadoSelectFieldQuery,
    Types.CboCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CboCuidadoCompartilhadoSelectFieldQuery,
    Types.CboCuidadoCompartilhadoSelectFieldQueryVariables
  >(CboCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useCboCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CboCuidadoCompartilhadoSelectFieldQuery,
    Types.CboCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CboCuidadoCompartilhadoSelectFieldQuery,
    Types.CboCuidadoCompartilhadoSelectFieldQueryVariables
  >(CboCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type CboCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useCboCuidadoCompartilhadoSelectFieldQuery
>
export type CboCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCboCuidadoCompartilhadoSelectFieldLazyQuery
>
export type CboCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CboCuidadoCompartilhadoSelectFieldQuery,
  Types.CboCuidadoCompartilhadoSelectFieldQueryVariables
>
export const CboSelectFieldDocument = gql`
  query CboSelectField($input: CbosQueryInput!) {
    cbos(input: $input) {
      content {
        cbo {
          id
          nome
          cbo2002
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCboSelectFieldQuery__
 *
 * To run a query within a React component, call `useCboSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCboSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>(
    CboSelectFieldDocument,
    baseOptions
  )
}
export function useCboSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>(
    CboSelectFieldDocument,
    baseOptions
  )
}
export type CboSelectFieldQueryHookResult = ReturnType<typeof useCboSelectFieldQuery>
export type CboSelectFieldLazyQueryHookResult = ReturnType<typeof useCboSelectFieldLazyQuery>
export type CboSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CboSelectFieldQuery,
  Types.CboSelectFieldQueryVariables
>
export const CiapCid10SelectFieldDocument = gql`
  query CiapCid10SelectField($inputCiap: CiapQueryInput!, $inputCid10: Cid10QueryInput!) {
    ciaps(input: $inputCiap) {
      content {
        id
        codigo
        descricao
      }
      pageInfo {
        ...PageInfo
      }
    }
    cids(input: $inputCid10) {
      content {
        id
        codigo
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCiapCid10SelectFieldQuery__
 *
 * To run a query within a React component, call `useCiapCid10SelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCiapCid10SelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCiapCid10SelectFieldQuery({
 *   variables: {
 *      inputCiap: // value for 'inputCiap'
 *      inputCid10: // value for 'inputCid10'
 *   },
 * });
 */
export function useCiapCid10SelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CiapCid10SelectFieldQuery,
    Types.CiapCid10SelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CiapCid10SelectFieldQuery, Types.CiapCid10SelectFieldQueryVariables>(
    CiapCid10SelectFieldDocument,
    baseOptions
  )
}
export function useCiapCid10SelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CiapCid10SelectFieldQuery,
    Types.CiapCid10SelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CiapCid10SelectFieldQuery, Types.CiapCid10SelectFieldQueryVariables>(
    CiapCid10SelectFieldDocument,
    baseOptions
  )
}
export type CiapCid10SelectFieldQueryHookResult = ReturnType<typeof useCiapCid10SelectFieldQuery>
export type CiapCid10SelectFieldLazyQueryHookResult = ReturnType<typeof useCiapCid10SelectFieldLazyQuery>
export type CiapCid10SelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CiapCid10SelectFieldQuery,
  Types.CiapCid10SelectFieldQueryVariables
>
export const AntecedenteCiapSelectFieldDocument = gql`
  query AntecedenteCiapSelectField($input: CiapQueryInput!) {
    ciaps(input: $input) {
      content {
        id
        codigo
        descricao
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useAntecedenteCiapSelectFieldQuery__
 *
 * To run a query within a React component, call `useAntecedenteCiapSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useAntecedenteCiapSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAntecedenteCiapSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAntecedenteCiapSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AntecedenteCiapSelectFieldQuery,
    Types.AntecedenteCiapSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AntecedenteCiapSelectFieldQuery,
    Types.AntecedenteCiapSelectFieldQueryVariables
  >(AntecedenteCiapSelectFieldDocument, baseOptions)
}
export function useAntecedenteCiapSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AntecedenteCiapSelectFieldQuery,
    Types.AntecedenteCiapSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AntecedenteCiapSelectFieldQuery,
    Types.AntecedenteCiapSelectFieldQueryVariables
  >(AntecedenteCiapSelectFieldDocument, baseOptions)
}
export type AntecedenteCiapSelectFieldQueryHookResult = ReturnType<typeof useAntecedenteCiapSelectFieldQuery>
export type AntecedenteCiapSelectFieldLazyQueryHookResult = ReturnType<typeof useAntecedenteCiapSelectFieldLazyQuery>
export type AntecedenteCiapSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.AntecedenteCiapSelectFieldQuery,
  Types.AntecedenteCiapSelectFieldQueryVariables
>
export const CiapSelectFieldDocument = gql`
  query CiapSelectField($input: CiapQueryInput!, $fetchCID10Encaminhamento: Boolean!) {
    ciaps(input: $input) {
      content {
        id
        codigo
        descricao
        inclui
        exclui
        cid10Encaminhamento @include(if: $fetchCID10Encaminhamento) {
          id
          codigo
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCiapSelectFieldQuery__
 *
 * To run a query within a React component, call `useCiapSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCiapSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCiapSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *      fetchCID10Encaminhamento: // value for 'fetchCID10Encaminhamento'
 *   },
 * });
 */
export function useCiapSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CiapSelectFieldQuery, Types.CiapSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CiapSelectFieldQuery, Types.CiapSelectFieldQueryVariables>(
    CiapSelectFieldDocument,
    baseOptions
  )
}
export function useCiapSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CiapSelectFieldQuery, Types.CiapSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CiapSelectFieldQuery, Types.CiapSelectFieldQueryVariables>(
    CiapSelectFieldDocument,
    baseOptions
  )
}
export type CiapSelectFieldQueryHookResult = ReturnType<typeof useCiapSelectFieldQuery>
export type CiapSelectFieldLazyQueryHookResult = ReturnType<typeof useCiapSelectFieldLazyQuery>
export type CiapSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CiapSelectFieldQuery,
  Types.CiapSelectFieldQueryVariables
>
export const CidSelectFieldDocument = gql`
  query CidSelectField($input: Cid10QueryInput!, $fetchCiapsRelacionadas: Boolean!) {
    cids(input: $input) {
      content {
        id
        codigo
        nome
        ciapsRelacionadas @include(if: $fetchCiapsRelacionadas) {
          id
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCidSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *      fetchCiapsRelacionadas: // value for 'fetchCiapsRelacionadas'
 *   },
 * });
 */
export function useCidSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidSelectFieldQuery, Types.CidSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidSelectFieldQuery, Types.CidSelectFieldQueryVariables>(
    CidSelectFieldDocument,
    baseOptions
  )
}
export function useCidSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidSelectFieldQuery, Types.CidSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidSelectFieldQuery, Types.CidSelectFieldQueryVariables>(
    CidSelectFieldDocument,
    baseOptions
  )
}
export type CidSelectFieldQueryHookResult = ReturnType<typeof useCidSelectFieldQuery>
export type CidSelectFieldLazyQueryHookResult = ReturnType<typeof useCidSelectFieldLazyQuery>
export type CidSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidSelectFieldQuery,
  Types.CidSelectFieldQueryVariables
>
export const CidadaoAtendimentoPorCnsSelectFieldDocument = gql`
  query CidadaoAtendimentoPorCnsSelectField($input: CidadaosComboQueryInput!) {
    cidadaosComboByCns(input: $input) {
      ...CidadaoBasico
    }
  }
  ${CidadaoBasicoFragmentDoc}
`

/**
 * __useCidadaoAtendimentoPorCnsSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoAtendimentoPorCnsSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAtendimentoPorCnsSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoAtendimentoPorCnsSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoAtendimentoPorCnsSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoAtendimentoPorCnsSelectFieldQuery,
    Types.CidadaoAtendimentoPorCnsSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoAtendimentoPorCnsSelectFieldQuery,
    Types.CidadaoAtendimentoPorCnsSelectFieldQueryVariables
  >(CidadaoAtendimentoPorCnsSelectFieldDocument, baseOptions)
}
export function useCidadaoAtendimentoPorCnsSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoAtendimentoPorCnsSelectFieldQuery,
    Types.CidadaoAtendimentoPorCnsSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoAtendimentoPorCnsSelectFieldQuery,
    Types.CidadaoAtendimentoPorCnsSelectFieldQueryVariables
  >(CidadaoAtendimentoPorCnsSelectFieldDocument, baseOptions)
}
export type CidadaoAtendimentoPorCnsSelectFieldQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorCnsSelectFieldQuery
>
export type CidadaoAtendimentoPorCnsSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorCnsSelectFieldLazyQuery
>
export type CidadaoAtendimentoPorCnsSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoAtendimentoPorCnsSelectFieldQuery,
  Types.CidadaoAtendimentoPorCnsSelectFieldQueryVariables
>
export const CidadaoAtendimentoPorCpfSelectFieldDocument = gql`
  query CidadaoAtendimentoPorCpfSelectField($input: CidadaosComboQueryInput!) {
    cidadaosComboByCpf(input: $input) {
      ...CidadaoBasico
    }
  }
  ${CidadaoBasicoFragmentDoc}
`

/**
 * __useCidadaoAtendimentoPorCpfSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoAtendimentoPorCpfSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAtendimentoPorCpfSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoAtendimentoPorCpfSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoAtendimentoPorCpfSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoAtendimentoPorCpfSelectFieldQuery,
    Types.CidadaoAtendimentoPorCpfSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoAtendimentoPorCpfSelectFieldQuery,
    Types.CidadaoAtendimentoPorCpfSelectFieldQueryVariables
  >(CidadaoAtendimentoPorCpfSelectFieldDocument, baseOptions)
}
export function useCidadaoAtendimentoPorCpfSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoAtendimentoPorCpfSelectFieldQuery,
    Types.CidadaoAtendimentoPorCpfSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoAtendimentoPorCpfSelectFieldQuery,
    Types.CidadaoAtendimentoPorCpfSelectFieldQueryVariables
  >(CidadaoAtendimentoPorCpfSelectFieldDocument, baseOptions)
}
export type CidadaoAtendimentoPorCpfSelectFieldQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorCpfSelectFieldQuery
>
export type CidadaoAtendimentoPorCpfSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorCpfSelectFieldLazyQuery
>
export type CidadaoAtendimentoPorCpfSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoAtendimentoPorCpfSelectFieldQuery,
  Types.CidadaoAtendimentoPorCpfSelectFieldQueryVariables
>
export const CidadaoAtendimentoPorDataNascimentoSelectFieldDocument = gql`
  query CidadaoAtendimentoPorDataNascimentoSelectField($input: CidadaosComboQueryInput!) {
    cidadaosComboByDataNascimento(input: $input) {
      ...CidadaoBasico
    }
  }
  ${CidadaoBasicoFragmentDoc}
`

/**
 * __useCidadaoAtendimentoPorDataNascimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoAtendimentoPorDataNascimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAtendimentoPorDataNascimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoAtendimentoPorDataNascimentoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoAtendimentoPorDataNascimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQuery,
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQuery,
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables
  >(CidadaoAtendimentoPorDataNascimentoSelectFieldDocument, baseOptions)
}
export function useCidadaoAtendimentoPorDataNascimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQuery,
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQuery,
    Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables
  >(CidadaoAtendimentoPorDataNascimentoSelectFieldDocument, baseOptions)
}
export type CidadaoAtendimentoPorDataNascimentoSelectFieldQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorDataNascimentoSelectFieldQuery
>
export type CidadaoAtendimentoPorDataNascimentoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorDataNascimentoSelectFieldLazyQuery
>
export type CidadaoAtendimentoPorDataNascimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQuery,
  Types.CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables
>
export const CidadaoAtendimentoPorNomeSelectFieldDocument = gql`
  query CidadaoAtendimentoPorNomeSelectField($input: CidadaosComboQueryInput!) {
    cidadaosComboByNome(input: $input) {
      ...CidadaoBasico
    }
  }
  ${CidadaoBasicoFragmentDoc}
`

/**
 * __useCidadaoAtendimentoPorNomeSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoAtendimentoPorNomeSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAtendimentoPorNomeSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoAtendimentoPorNomeSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoAtendimentoPorNomeSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoAtendimentoPorNomeSelectFieldQuery,
    Types.CidadaoAtendimentoPorNomeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoAtendimentoPorNomeSelectFieldQuery,
    Types.CidadaoAtendimentoPorNomeSelectFieldQueryVariables
  >(CidadaoAtendimentoPorNomeSelectFieldDocument, baseOptions)
}
export function useCidadaoAtendimentoPorNomeSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoAtendimentoPorNomeSelectFieldQuery,
    Types.CidadaoAtendimentoPorNomeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoAtendimentoPorNomeSelectFieldQuery,
    Types.CidadaoAtendimentoPorNomeSelectFieldQueryVariables
  >(CidadaoAtendimentoPorNomeSelectFieldDocument, baseOptions)
}
export type CidadaoAtendimentoPorNomeSelectFieldQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorNomeSelectFieldQuery
>
export type CidadaoAtendimentoPorNomeSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoPorNomeSelectFieldLazyQuery
>
export type CidadaoAtendimentoPorNomeSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoAtendimentoPorNomeSelectFieldQuery,
  Types.CidadaoAtendimentoPorNomeSelectFieldQueryVariables
>
export const CidadaoAtendimentoSelectFieldDocument = gql`
  query CidadaoAtendimentoSelectField(
    $input: CidadaosComboQueryInput!
    $emAtencaoDomiciliar: Boolean!
    $equipeResponsavelId: ID
    $retrieveContato: Boolean!
  ) {
    cidadaosCombo(input: $input, atencaoDomiciliar: $emAtencaoDomiciliar, equipeResponsavelId: $equipeResponsavelId) {
      ...CidadaoBasico
      contato @include(if: $retrieveContato) {
        id
        telefoneCelular
        email
      }
    }
  }
  ${CidadaoBasicoFragmentDoc}
`

/**
 * __useCidadaoAtendimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoAtendimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAtendimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoAtendimentoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *      emAtencaoDomiciliar: // value for 'emAtencaoDomiciliar'
 *      equipeResponsavelId: // value for 'equipeResponsavelId'
 *      retrieveContato: // value for 'retrieveContato'
 *   },
 * });
 */
export function useCidadaoAtendimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoAtendimentoSelectFieldQuery,
    Types.CidadaoAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoAtendimentoSelectFieldQuery,
    Types.CidadaoAtendimentoSelectFieldQueryVariables
  >(CidadaoAtendimentoSelectFieldDocument, baseOptions)
}
export function useCidadaoAtendimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoAtendimentoSelectFieldQuery,
    Types.CidadaoAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoAtendimentoSelectFieldQuery,
    Types.CidadaoAtendimentoSelectFieldQueryVariables
  >(CidadaoAtendimentoSelectFieldDocument, baseOptions)
}
export type CidadaoAtendimentoSelectFieldQueryHookResult = ReturnType<typeof useCidadaoAtendimentoSelectFieldQuery>
export type CidadaoAtendimentoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCidadaoAtendimentoSelectFieldLazyQuery
>
export type CidadaoAtendimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoAtendimentoSelectFieldQuery,
  Types.CidadaoAtendimentoSelectFieldQueryVariables
>
export const CidadaoSelectFieldDocument = gql`
  query CidadaoSelectField($input: CidadaosQueryInput!) {
    cidadaos(input: $input) {
      content {
        id
        nome
        nomeSocial
        cpf
        cns
        nomeMae
        dataNascimento
        sexo
        identidadeGeneroDbEnum
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCidadaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoSelectFieldQuery, Types.CidadaoSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoSelectFieldQuery, Types.CidadaoSelectFieldQueryVariables>(
    CidadaoSelectFieldDocument,
    baseOptions
  )
}
export function useCidadaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoSelectFieldQuery,
    Types.CidadaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoSelectFieldQuery, Types.CidadaoSelectFieldQueryVariables>(
    CidadaoSelectFieldDocument,
    baseOptions
  )
}
export type CidadaoSelectFieldQueryHookResult = ReturnType<typeof useCidadaoSelectFieldQuery>
export type CidadaoSelectFieldLazyQueryHookResult = ReturnType<typeof useCidadaoSelectFieldLazyQuery>
export type CidadaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoSelectFieldQuery,
  Types.CidadaoSelectFieldQueryVariables
>
export const ConselhoClasseSelectDocument = gql`
  query ConselhoClasseSelect($input: ConselhoClasseQueryInput!) {
    conselhosClasse(input: $input) {
      content {
        id
        descricao
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useConselhoClasseSelectQuery__
 *
 * To run a query within a React component, call `useConselhoClasseSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useConselhoClasseSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConselhoClasseSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConselhoClasseSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConselhoClasseSelectQuery,
    Types.ConselhoClasseSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConselhoClasseSelectQuery, Types.ConselhoClasseSelectQueryVariables>(
    ConselhoClasseSelectDocument,
    baseOptions
  )
}
export function useConselhoClasseSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConselhoClasseSelectQuery,
    Types.ConselhoClasseSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConselhoClasseSelectQuery, Types.ConselhoClasseSelectQueryVariables>(
    ConselhoClasseSelectDocument,
    baseOptions
  )
}
export type ConselhoClasseSelectQueryHookResult = ReturnType<typeof useConselhoClasseSelectQuery>
export type ConselhoClasseSelectLazyQueryHookResult = ReturnType<typeof useConselhoClasseSelectLazyQuery>
export type ConselhoClasseSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.ConselhoClasseSelectQuery,
  Types.ConselhoClasseSelectQueryVariables
>
export const DoseImunobiologicoVacinacaoSelectFieldDocument = gql`
  query DoseImunobiologicoVacinacaoSelectField($input: DoseImunobiologicoVacinacaoQueryInput!) {
    doseImunobiologicoVacinacao(input: $input) {
      content {
        id
        sigla
        nome
        nomeApresentacaoDose
        ordem
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useDoseImunobiologicoVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useDoseImunobiologicoVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoseImunobiologicoVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoseImunobiologicoVacinacaoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoseImunobiologicoVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DoseImunobiologicoVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DoseImunobiologicoVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoVacinacaoSelectFieldQueryVariables
  >(DoseImunobiologicoVacinacaoSelectFieldDocument, baseOptions)
}
export function useDoseImunobiologicoVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DoseImunobiologicoVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DoseImunobiologicoVacinacaoSelectFieldQuery,
    Types.DoseImunobiologicoVacinacaoSelectFieldQueryVariables
  >(DoseImunobiologicoVacinacaoSelectFieldDocument, baseOptions)
}
export type DoseImunobiologicoVacinacaoSelectFieldQueryHookResult = ReturnType<
  typeof useDoseImunobiologicoVacinacaoSelectFieldQuery
>
export type DoseImunobiologicoVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useDoseImunobiologicoVacinacaoSelectFieldLazyQuery
>
export type DoseImunobiologicoVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.DoseImunobiologicoVacinacaoSelectFieldQuery,
  Types.DoseImunobiologicoVacinacaoSelectFieldQueryVariables
>
export const EquipeAdSelectFieldDocument = gql`
  query EquipeAdSelectField($input: EquipesAdQueryInput!) {
    equipesAD(input: $input) {
      content {
        id
        nome
        ine
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipeAdSelectFieldQuery__
 *
 * To run a query within a React component, call `useEquipeAdSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeAdSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeAdSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeAdSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EquipeAdSelectFieldQuery,
    Types.EquipeAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EquipeAdSelectFieldQuery, Types.EquipeAdSelectFieldQueryVariables>(
    EquipeAdSelectFieldDocument,
    baseOptions
  )
}
export function useEquipeAdSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeAdSelectFieldQuery,
    Types.EquipeAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipeAdSelectFieldQuery, Types.EquipeAdSelectFieldQueryVariables>(
    EquipeAdSelectFieldDocument,
    baseOptions
  )
}
export type EquipeAdSelectFieldQueryHookResult = ReturnType<typeof useEquipeAdSelectFieldQuery>
export type EquipeAdSelectFieldLazyQueryHookResult = ReturnType<typeof useEquipeAdSelectFieldLazyQuery>
export type EquipeAdSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeAdSelectFieldQuery,
  Types.EquipeAdSelectFieldQueryVariables
>
export const EquipeSelectFieldDocument = gql`
  query EquipeSelectField($input: EquipesQueryInput!) {
    equipes(input: $input) {
      content {
        id
        nome
        ine
        ativo
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipeSelectFieldQuery__
 *
 * To run a query within a React component, call `useEquipeSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipeSelectFieldQuery, Types.EquipeSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EquipeSelectFieldQuery, Types.EquipeSelectFieldQueryVariables>(
    EquipeSelectFieldDocument,
    baseOptions
  )
}
export function useEquipeSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeSelectFieldQuery,
    Types.EquipeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipeSelectFieldQuery, Types.EquipeSelectFieldQueryVariables>(
    EquipeSelectFieldDocument,
    baseOptions
  )
}
export type EquipeSelectFieldQueryHookResult = ReturnType<typeof useEquipeSelectFieldQuery>
export type EquipeSelectFieldLazyQueryHookResult = ReturnType<typeof useEquipeSelectFieldLazyQuery>
export type EquipeSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeSelectFieldQuery,
  Types.EquipeSelectFieldQueryVariables
>
export const EspecialidadeSisregSelectFieldDocument = gql`
  query EspecialidadeSisregSelectField($input: EspecialidadeSisregQueryInput!) {
    especialidadesSisreg(input: $input) {
      content {
        id
        descricao
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEspecialidadeSisregSelectFieldQuery__
 *
 * To run a query within a React component, call `useEspecialidadeSisregSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEspecialidadeSisregSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEspecialidadeSisregSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEspecialidadeSisregSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EspecialidadeSisregSelectFieldQuery,
    Types.EspecialidadeSisregSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EspecialidadeSisregSelectFieldQuery,
    Types.EspecialidadeSisregSelectFieldQueryVariables
  >(EspecialidadeSisregSelectFieldDocument, baseOptions)
}
export function useEspecialidadeSisregSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EspecialidadeSisregSelectFieldQuery,
    Types.EspecialidadeSisregSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EspecialidadeSisregSelectFieldQuery,
    Types.EspecialidadeSisregSelectFieldQueryVariables
  >(EspecialidadeSisregSelectFieldDocument, baseOptions)
}
export type EspecialidadeSisregSelectFieldQueryHookResult = ReturnType<typeof useEspecialidadeSisregSelectFieldQuery>
export type EspecialidadeSisregSelectFieldLazyQueryHookResult = ReturnType<
  typeof useEspecialidadeSisregSelectFieldLazyQuery
>
export type EspecialidadeSisregSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EspecialidadeSisregSelectFieldQuery,
  Types.EspecialidadeSisregSelectFieldQueryVariables
>
export const EstrategiaVacinacaoSelectFieldDocument = gql`
  query EstrategiaVacinacaoSelectField($input: EstrategiasVacinacaoQueryInput!) {
    estrategiasVacinacao(input: $input) {
      id
      codigo
      nome
      estrategiaVacinacaoDbEnum
    }
  }
`

/**
 * __useEstrategiaVacinacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useEstrategiaVacinacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstrategiaVacinacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstrategiaVacinacaoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstrategiaVacinacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EstrategiaVacinacaoSelectFieldQuery,
    Types.EstrategiaVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EstrategiaVacinacaoSelectFieldQuery,
    Types.EstrategiaVacinacaoSelectFieldQueryVariables
  >(EstrategiaVacinacaoSelectFieldDocument, baseOptions)
}
export function useEstrategiaVacinacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EstrategiaVacinacaoSelectFieldQuery,
    Types.EstrategiaVacinacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EstrategiaVacinacaoSelectFieldQuery,
    Types.EstrategiaVacinacaoSelectFieldQueryVariables
  >(EstrategiaVacinacaoSelectFieldDocument, baseOptions)
}
export type EstrategiaVacinacaoSelectFieldQueryHookResult = ReturnType<typeof useEstrategiaVacinacaoSelectFieldQuery>
export type EstrategiaVacinacaoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useEstrategiaVacinacaoSelectFieldLazyQuery
>
export type EstrategiaVacinacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EstrategiaVacinacaoSelectFieldQuery,
  Types.EstrategiaVacinacaoSelectFieldQueryVariables
>
export const EtniaSelectFieldDocument = gql`
  query EtniaSelectField($input: EtniaQueryInput!) {
    etnias(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEtniaSelectFieldQuery__
 *
 * To run a query within a React component, call `useEtniaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEtniaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEtniaSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEtniaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>(
    EtniaSelectFieldDocument,
    baseOptions
  )
}
export function useEtniaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>(
    EtniaSelectFieldDocument,
    baseOptions
  )
}
export type EtniaSelectFieldQueryHookResult = ReturnType<typeof useEtniaSelectFieldQuery>
export type EtniaSelectFieldLazyQueryHookResult = ReturnType<typeof useEtniaSelectFieldLazyQuery>
export type EtniaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EtniaSelectFieldQuery,
  Types.EtniaSelectFieldQueryVariables
>
export const FichaNotificacaoCasoSuspeitoSelectFieldDocument = gql`
  query FichaNotificacaoCasoSuspeitoSelectField($input: FichaNotificacaoQueryInput!) {
    fichasNotificacao(input: $input) {
      content {
        id
        nomeNotificacao
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useFichaNotificacaoCasoSuspeitoSelectFieldQuery__
 *
 * To run a query within a React component, call `useFichaNotificacaoCasoSuspeitoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useFichaNotificacaoCasoSuspeitoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFichaNotificacaoCasoSuspeitoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFichaNotificacaoCasoSuspeitoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQuery,
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQuery,
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables
  >(FichaNotificacaoCasoSuspeitoSelectFieldDocument, baseOptions)
}
export function useFichaNotificacaoCasoSuspeitoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQuery,
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQuery,
    Types.FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables
  >(FichaNotificacaoCasoSuspeitoSelectFieldDocument, baseOptions)
}
export type FichaNotificacaoCasoSuspeitoSelectFieldQueryHookResult = ReturnType<
  typeof useFichaNotificacaoCasoSuspeitoSelectFieldQuery
>
export type FichaNotificacaoCasoSuspeitoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useFichaNotificacaoCasoSuspeitoSelectFieldLazyQuery
>
export type FichaNotificacaoCasoSuspeitoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.FichaNotificacaoCasoSuspeitoSelectFieldQuery,
  Types.FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables
>
export const FormaFarmaceuticaSelectFieldDocument = gql`
  query FormaFarmaceuticaSelectField($input: FormaFarmaceuticaQueryInput!) {
    formasFarmaceuticas(input: $input) {
      content {
        id
        nome
      }
    }
  }
`

/**
 * __useFormaFarmaceuticaSelectFieldQuery__
 *
 * To run a query within a React component, call `useFormaFarmaceuticaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormaFarmaceuticaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormaFarmaceuticaSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormaFarmaceuticaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.FormaFarmaceuticaSelectFieldQuery,
    Types.FormaFarmaceuticaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.FormaFarmaceuticaSelectFieldQuery,
    Types.FormaFarmaceuticaSelectFieldQueryVariables
  >(FormaFarmaceuticaSelectFieldDocument, baseOptions)
}
export function useFormaFarmaceuticaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.FormaFarmaceuticaSelectFieldQuery,
    Types.FormaFarmaceuticaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.FormaFarmaceuticaSelectFieldQuery,
    Types.FormaFarmaceuticaSelectFieldQueryVariables
  >(FormaFarmaceuticaSelectFieldDocument, baseOptions)
}
export type FormaFarmaceuticaSelectFieldQueryHookResult = ReturnType<typeof useFormaFarmaceuticaSelectFieldQuery>
export type FormaFarmaceuticaSelectFieldLazyQueryHookResult = ReturnType<
  typeof useFormaFarmaceuticaSelectFieldLazyQuery
>
export type FormaFarmaceuticaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.FormaFarmaceuticaSelectFieldQuery,
  Types.FormaFarmaceuticaSelectFieldQueryVariables
>
export const GrupoAtendimentoSelectFieldDocument = gql`
  query GrupoAtendimentoSelectField($inputQuery: String, $onlyAtivos: Boolean) {
    gruposAtendimento(inputQuery: $inputQuery, onlyAtivos: $onlyAtivos) {
      id
      nome
    }
  }
`

/**
 * __useGrupoAtendimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useGrupoAtendimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoAtendimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoAtendimentoSelectFieldQuery({
 *   variables: {
 *      inputQuery: // value for 'inputQuery'
 *      onlyAtivos: // value for 'onlyAtivos'
 *   },
 * });
 */
export function useGrupoAtendimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GrupoAtendimentoSelectFieldQuery,
    Types.GrupoAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.GrupoAtendimentoSelectFieldQuery,
    Types.GrupoAtendimentoSelectFieldQueryVariables
  >(GrupoAtendimentoSelectFieldDocument, baseOptions)
}
export function useGrupoAtendimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GrupoAtendimentoSelectFieldQuery,
    Types.GrupoAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.GrupoAtendimentoSelectFieldQuery,
    Types.GrupoAtendimentoSelectFieldQueryVariables
  >(GrupoAtendimentoSelectFieldDocument, baseOptions)
}
export type GrupoAtendimentoSelectFieldQueryHookResult = ReturnType<typeof useGrupoAtendimentoSelectFieldQuery>
export type GrupoAtendimentoSelectFieldLazyQueryHookResult = ReturnType<typeof useGrupoAtendimentoSelectFieldLazyQuery>
export type GrupoAtendimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoAtendimentoSelectFieldQuery,
  Types.GrupoAtendimentoSelectFieldQueryVariables
>
export const GrupoCondicaoSelectFieldDocument = gql`
  query GrupoCondicaoSelectField($inputGrupo: GrupoCondicaoQueryInput!) {
    grupos(input: $inputGrupo) {
      content {
        id
        grupoCondicao
        ciaps {
          id
          codigo
          descricao
        }
        cids {
          id
          codigo
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useGrupoCondicaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useGrupoCondicaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoCondicaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoCondicaoSelectFieldQuery({
 *   variables: {
 *      inputGrupo: // value for 'inputGrupo'
 *   },
 * });
 */
export function useGrupoCondicaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GrupoCondicaoSelectFieldQuery,
    Types.GrupoCondicaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.GrupoCondicaoSelectFieldQuery, Types.GrupoCondicaoSelectFieldQueryVariables>(
    GrupoCondicaoSelectFieldDocument,
    baseOptions
  )
}
export function useGrupoCondicaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GrupoCondicaoSelectFieldQuery,
    Types.GrupoCondicaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.GrupoCondicaoSelectFieldQuery,
    Types.GrupoCondicaoSelectFieldQueryVariables
  >(GrupoCondicaoSelectFieldDocument, baseOptions)
}
export type GrupoCondicaoSelectFieldQueryHookResult = ReturnType<typeof useGrupoCondicaoSelectFieldQuery>
export type GrupoCondicaoSelectFieldLazyQueryHookResult = ReturnType<typeof useGrupoCondicaoSelectFieldLazyQuery>
export type GrupoCondicaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoCondicaoSelectFieldQuery,
  Types.GrupoCondicaoSelectFieldQueryVariables
>
export const HorarioAgendaSelectFieldDocument = gql`
  query HorarioAgendaSelectField($input: HorariosAgendaQueryInput!) {
    horariosAgenda(input: $input) {
      horario
      duracao
      isOcupado
      isReservadoAgendaOnline
    }
  }
`

/**
 * __useHorarioAgendaSelectFieldQuery__
 *
 * To run a query within a React component, call `useHorarioAgendaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useHorarioAgendaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHorarioAgendaSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHorarioAgendaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HorarioAgendaSelectFieldQuery,
    Types.HorarioAgendaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HorarioAgendaSelectFieldQuery, Types.HorarioAgendaSelectFieldQueryVariables>(
    HorarioAgendaSelectFieldDocument,
    baseOptions
  )
}
export function useHorarioAgendaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HorarioAgendaSelectFieldQuery,
    Types.HorarioAgendaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HorarioAgendaSelectFieldQuery,
    Types.HorarioAgendaSelectFieldQueryVariables
  >(HorarioAgendaSelectFieldDocument, baseOptions)
}
export type HorarioAgendaSelectFieldQueryHookResult = ReturnType<typeof useHorarioAgendaSelectFieldQuery>
export type HorarioAgendaSelectFieldLazyQueryHookResult = ReturnType<typeof useHorarioAgendaSelectFieldLazyQuery>
export type HorarioAgendaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.HorarioAgendaSelectFieldQuery,
  Types.HorarioAgendaSelectFieldQueryVariables
>
export const LocalAplicacaoSelectFieldDocument = gql`
  query LocalAplicacaoSelectField($input: LocalAplicacaoQueryInput!) {
    locaisAplicacao(input: $input) {
      id
      nome
    }
  }
`

/**
 * __useLocalAplicacaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useLocalAplicacaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalAplicacaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalAplicacaoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocalAplicacaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LocalAplicacaoSelectFieldQuery,
    Types.LocalAplicacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LocalAplicacaoSelectFieldQuery, Types.LocalAplicacaoSelectFieldQueryVariables>(
    LocalAplicacaoSelectFieldDocument,
    baseOptions
  )
}
export function useLocalAplicacaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LocalAplicacaoSelectFieldQuery,
    Types.LocalAplicacaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LocalAplicacaoSelectFieldQuery,
    Types.LocalAplicacaoSelectFieldQueryVariables
  >(LocalAplicacaoSelectFieldDocument, baseOptions)
}
export type LocalAplicacaoSelectFieldQueryHookResult = ReturnType<typeof useLocalAplicacaoSelectFieldQuery>
export type LocalAplicacaoSelectFieldLazyQueryHookResult = ReturnType<typeof useLocalAplicacaoSelectFieldLazyQuery>
export type LocalAplicacaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.LocalAplicacaoSelectFieldQuery,
  Types.LocalAplicacaoSelectFieldQueryVariables
>
export const LocalAtendimentoSelectFieldDocument = gql`
  query LocalAtendimentoSelectField($filtro: LocalAtendimentoQueryInput!) {
    localAtendimento(input: $filtro) {
      content {
        id
        localAtendimentoExibicao
      }
    }
  }
`

/**
 * __useLocalAtendimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useLocalAtendimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalAtendimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalAtendimentoSelectFieldQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useLocalAtendimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LocalAtendimentoSelectFieldQuery,
    Types.LocalAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.LocalAtendimentoSelectFieldQuery,
    Types.LocalAtendimentoSelectFieldQueryVariables
  >(LocalAtendimentoSelectFieldDocument, baseOptions)
}
export function useLocalAtendimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LocalAtendimentoSelectFieldQuery,
    Types.LocalAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LocalAtendimentoSelectFieldQuery,
    Types.LocalAtendimentoSelectFieldQueryVariables
  >(LocalAtendimentoSelectFieldDocument, baseOptions)
}
export type LocalAtendimentoSelectFieldQueryHookResult = ReturnType<typeof useLocalAtendimentoSelectFieldQuery>
export type LocalAtendimentoSelectFieldLazyQueryHookResult = ReturnType<typeof useLocalAtendimentoSelectFieldLazyQuery>
export type LocalAtendimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.LocalAtendimentoSelectFieldQuery,
  Types.LocalAtendimentoSelectFieldQueryVariables
>
export const LogradouroSelectFieldDocument = gql`
  query LogradouroSelectField($input: LogradouroInput!) {
    logradouros(input: $input) {
      content {
        id
        nome
        nomeExibicao
        tituloPatente {
          id
          nome
        }
        cep
        bairro {
          id
          nome
          municipio {
            id
            nome
            uf {
              id
              sigla
            }
          }
        }
        tipoLogradouro {
          id
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLogradouroSelectFieldQuery__
 *
 * To run a query within a React component, call `useLogradouroSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogradouroSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogradouroSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogradouroSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LogradouroSelectFieldQuery,
    Types.LogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LogradouroSelectFieldQuery, Types.LogradouroSelectFieldQueryVariables>(
    LogradouroSelectFieldDocument,
    baseOptions
  )
}
export function useLogradouroSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LogradouroSelectFieldQuery,
    Types.LogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.LogradouroSelectFieldQuery, Types.LogradouroSelectFieldQueryVariables>(
    LogradouroSelectFieldDocument,
    baseOptions
  )
}
export type LogradouroSelectFieldQueryHookResult = ReturnType<typeof useLogradouroSelectFieldQuery>
export type LogradouroSelectFieldLazyQueryHookResult = ReturnType<typeof useLogradouroSelectFieldLazyQuery>
export type LogradouroSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.LogradouroSelectFieldQuery,
  Types.LogradouroSelectFieldQueryVariables
>
export const LotacaoAgendaSelectDocument = gql`
  query LotacaoAgendaSelect($id: ID!) {
    lotacao(id: $id) {
      ...LotacaoAgendaSelect
    }
  }
  ${LotacaoAgendaSelectFragmentDoc}
`

/**
 * __useLotacaoAgendaSelectQuery__
 *
 * To run a query within a React component, call `useLotacaoAgendaSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoAgendaSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoAgendaSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLotacaoAgendaSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LotacaoAgendaSelectQuery,
    Types.LotacaoAgendaSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LotacaoAgendaSelectQuery, Types.LotacaoAgendaSelectQueryVariables>(
    LotacaoAgendaSelectDocument,
    baseOptions
  )
}
export function useLotacaoAgendaSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LotacaoAgendaSelectQuery,
    Types.LotacaoAgendaSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.LotacaoAgendaSelectQuery, Types.LotacaoAgendaSelectQueryVariables>(
    LotacaoAgendaSelectDocument,
    baseOptions
  )
}
export type LotacaoAgendaSelectQueryHookResult = ReturnType<typeof useLotacaoAgendaSelectQuery>
export type LotacaoAgendaSelectLazyQueryHookResult = ReturnType<typeof useLotacaoAgendaSelectLazyQuery>
export type LotacaoAgendaSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoAgendaSelectQuery,
  Types.LotacaoAgendaSelectQueryVariables
>
export const LotacoesAgendaSelectDocument = gql`
  query LotacoesAgendaSelect($input: LotacoesAgendaInput!) {
    lotacoesAgenda(input: $input) {
      content {
        ...LotacaoAgendaSelect
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${LotacaoAgendaSelectFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useLotacoesAgendaSelectQuery__
 *
 * To run a query within a React component, call `useLotacoesAgendaSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacoesAgendaSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacoesAgendaSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLotacoesAgendaSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LotacoesAgendaSelectQuery,
    Types.LotacoesAgendaSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LotacoesAgendaSelectQuery, Types.LotacoesAgendaSelectQueryVariables>(
    LotacoesAgendaSelectDocument,
    baseOptions
  )
}
export function useLotacoesAgendaSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LotacoesAgendaSelectQuery,
    Types.LotacoesAgendaSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.LotacoesAgendaSelectQuery, Types.LotacoesAgendaSelectQueryVariables>(
    LotacoesAgendaSelectDocument,
    baseOptions
  )
}
export type LotacoesAgendaSelectQueryHookResult = ReturnType<typeof useLotacoesAgendaSelectQuery>
export type LotacoesAgendaSelectLazyQueryHookResult = ReturnType<typeof useLotacoesAgendaSelectLazyQuery>
export type LotacoesAgendaSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacoesAgendaSelectQuery,
  Types.LotacoesAgendaSelectQueryVariables
>
export const LotacaoAndEstagioSelectFieldDocument = gql`
  query LotacaoAndEstagioSelectField($input: LotacoesAndEstagiosInput!) {
    lotacoesAndEstagios(input: $input) {
      id
      ... on Lotacao {
        profissional {
          id
          nome
          cpf
          email
        }
        unidadeSaude {
          id
          nome
          cnes
        }
        cbo {
          id
          nome
          cbo2002
        }
        equipe {
          id
          ine
          nome
          isAD
        }
      }
      ... on Estagio {
        profissional {
          id
          nome
          cpf
        }
        unidadeSaude {
          id
          nome
          cnes
        }
        cbo {
          id
          nome
          cbo2002
        }
        equipe {
          id
          ine
          nome
        }
        lotacaoSupervisora {
          id
          profissional {
            id
            nome
            cpf
          }
        }
      }
    }
  }
`

/**
 * __useLotacaoAndEstagioSelectFieldQuery__
 *
 * To run a query within a React component, call `useLotacaoAndEstagioSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoAndEstagioSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoAndEstagioSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLotacaoAndEstagioSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LotacaoAndEstagioSelectFieldQuery,
    Types.LotacaoAndEstagioSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.LotacaoAndEstagioSelectFieldQuery,
    Types.LotacaoAndEstagioSelectFieldQueryVariables
  >(LotacaoAndEstagioSelectFieldDocument, baseOptions)
}
export function useLotacaoAndEstagioSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LotacaoAndEstagioSelectFieldQuery,
    Types.LotacaoAndEstagioSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LotacaoAndEstagioSelectFieldQuery,
    Types.LotacaoAndEstagioSelectFieldQueryVariables
  >(LotacaoAndEstagioSelectFieldDocument, baseOptions)
}
export type LotacaoAndEstagioSelectFieldQueryHookResult = ReturnType<typeof useLotacaoAndEstagioSelectFieldQuery>
export type LotacaoAndEstagioSelectFieldLazyQueryHookResult = ReturnType<
  typeof useLotacaoAndEstagioSelectFieldLazyQuery
>
export type LotacaoAndEstagioSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoAndEstagioSelectFieldQuery,
  Types.LotacaoAndEstagioSelectFieldQueryVariables
>
export const LoteImunobiologicoSelectFieldDocument = gql`
  query LoteImunobiologicoSelectField($input: LoteImunobiologicoQueryInput!) {
    lotesImunobiologico(input: $input) {
      content {
        id
        lote
        fabricante {
          id
          nome
        }
        validade
      }
    }
  }
`

/**
 * __useLoteImunobiologicoSelectFieldQuery__
 *
 * To run a query within a React component, call `useLoteImunobiologicoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteImunobiologicoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteImunobiologicoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoteImunobiologicoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LoteImunobiologicoSelectFieldQuery,
    Types.LoteImunobiologicoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.LoteImunobiologicoSelectFieldQuery,
    Types.LoteImunobiologicoSelectFieldQueryVariables
  >(LoteImunobiologicoSelectFieldDocument, baseOptions)
}
export function useLoteImunobiologicoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LoteImunobiologicoSelectFieldQuery,
    Types.LoteImunobiologicoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LoteImunobiologicoSelectFieldQuery,
    Types.LoteImunobiologicoSelectFieldQueryVariables
  >(LoteImunobiologicoSelectFieldDocument, baseOptions)
}
export type LoteImunobiologicoSelectFieldQueryHookResult = ReturnType<typeof useLoteImunobiologicoSelectFieldQuery>
export type LoteImunobiologicoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useLoteImunobiologicoSelectFieldLazyQuery
>
export type LoteImunobiologicoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.LoteImunobiologicoSelectFieldQuery,
  Types.LoteImunobiologicoSelectFieldQueryVariables
>
export const ManifestacaoAlergiaSelectFieldDocument = gql`
  query ManifestacaoAlergiaSelectField($filtro: ManifestacoesAlergiaQueryInput!) {
    manifestacoesAlergia(input: $filtro) {
      content {
        id
        nome
        sinonimo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useManifestacaoAlergiaSelectFieldQuery__
 *
 * To run a query within a React component, call `useManifestacaoAlergiaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useManifestacaoAlergiaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManifestacaoAlergiaSelectFieldQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useManifestacaoAlergiaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ManifestacaoAlergiaSelectFieldQuery,
    Types.ManifestacaoAlergiaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ManifestacaoAlergiaSelectFieldQuery,
    Types.ManifestacaoAlergiaSelectFieldQueryVariables
  >(ManifestacaoAlergiaSelectFieldDocument, baseOptions)
}
export function useManifestacaoAlergiaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ManifestacaoAlergiaSelectFieldQuery,
    Types.ManifestacaoAlergiaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ManifestacaoAlergiaSelectFieldQuery,
    Types.ManifestacaoAlergiaSelectFieldQueryVariables
  >(ManifestacaoAlergiaSelectFieldDocument, baseOptions)
}
export type ManifestacaoAlergiaSelectFieldQueryHookResult = ReturnType<typeof useManifestacaoAlergiaSelectFieldQuery>
export type ManifestacaoAlergiaSelectFieldLazyQueryHookResult = ReturnType<
  typeof useManifestacaoAlergiaSelectFieldLazyQuery
>
export type ManifestacaoAlergiaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ManifestacaoAlergiaSelectFieldQuery,
  Types.ManifestacaoAlergiaSelectFieldQueryVariables
>
export const MedicamentoCatmatSelectFieldDocument = gql`
  query MedicamentoCatmatSelectField($input: MedicamentoCatmatQueryInput!) {
    medicamentosCatmat(input: $input) {
      content {
        id
        volume
        medicamento {
          id
          principioAtivo
          unidadeFornecimento
          concentracao
          formaFarmaceutica {
            id
            nome
          }
        }
        codigoCatMat
        codigoRename
        unidadeFornecimento {
          id
          nome
          nomePlural
        }
        principioAtivo {
          id
          nome
          listaMaterial {
            id
            tipo
            tipoReceita
          }
        }
        unidadeMedidaDose {
          id
          nome
          nomePlural
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMedicamentoCatmatSelectFieldQuery__
 *
 * To run a query within a React component, call `useMedicamentoCatmatSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicamentoCatmatSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicamentoCatmatSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicamentoCatmatSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MedicamentoCatmatSelectFieldQuery,
    Types.MedicamentoCatmatSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.MedicamentoCatmatSelectFieldQuery,
    Types.MedicamentoCatmatSelectFieldQueryVariables
  >(MedicamentoCatmatSelectFieldDocument, baseOptions)
}
export function useMedicamentoCatmatSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MedicamentoCatmatSelectFieldQuery,
    Types.MedicamentoCatmatSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.MedicamentoCatmatSelectFieldQuery,
    Types.MedicamentoCatmatSelectFieldQueryVariables
  >(MedicamentoCatmatSelectFieldDocument, baseOptions)
}
export type MedicamentoCatmatSelectFieldQueryHookResult = ReturnType<typeof useMedicamentoCatmatSelectFieldQuery>
export type MedicamentoCatmatSelectFieldLazyQueryHookResult = ReturnType<
  typeof useMedicamentoCatmatSelectFieldLazyQuery
>
export type MedicamentoCatmatSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.MedicamentoCatmatSelectFieldQuery,
  Types.MedicamentoCatmatSelectFieldQueryVariables
>
export const MedicamentosDocument = gql`
  query Medicamentos($input: MedicamentoQueryInput!) {
    medicamentos(input: $input) {
      content {
        id
        principioAtivo
        concentracao
        unidadeFornecimento
        formaFarmaceutica {
          id
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMedicamentosQuery__
 *
 * To run a query within a React component, call `useMedicamentosQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicamentosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicamentosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicamentosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MedicamentosQuery, Types.MedicamentosQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.MedicamentosQuery, Types.MedicamentosQueryVariables>(
    MedicamentosDocument,
    baseOptions
  )
}
export function useMedicamentosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MedicamentosQuery, Types.MedicamentosQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.MedicamentosQuery, Types.MedicamentosQueryVariables>(
    MedicamentosDocument,
    baseOptions
  )
}
export type MedicamentosQueryHookResult = ReturnType<typeof useMedicamentosQuery>
export type MedicamentosLazyQueryHookResult = ReturnType<typeof useMedicamentosLazyQuery>
export type MedicamentosQueryResult = ApolloReactCommon.QueryResult<
  Types.MedicamentosQuery,
  Types.MedicamentosQueryVariables
>
export const MicroareaImovelSelectFieldDocument = gql`
  query MicroareaImovelSelectField($input: TerritorioEquipeQueryInput!) {
    microareasImoveis(input: $input)
  }
`

/**
 * __useMicroareaImovelSelectFieldQuery__
 *
 * To run a query within a React component, call `useMicroareaImovelSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicroareaImovelSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicroareaImovelSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMicroareaImovelSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MicroareaImovelSelectFieldQuery,
    Types.MicroareaImovelSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.MicroareaImovelSelectFieldQuery,
    Types.MicroareaImovelSelectFieldQueryVariables
  >(MicroareaImovelSelectFieldDocument, baseOptions)
}
export function useMicroareaImovelSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MicroareaImovelSelectFieldQuery,
    Types.MicroareaImovelSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.MicroareaImovelSelectFieldQuery,
    Types.MicroareaImovelSelectFieldQueryVariables
  >(MicroareaImovelSelectFieldDocument, baseOptions)
}
export type MicroareaImovelSelectFieldQueryHookResult = ReturnType<typeof useMicroareaImovelSelectFieldQuery>
export type MicroareaImovelSelectFieldLazyQueryHookResult = ReturnType<typeof useMicroareaImovelSelectFieldLazyQuery>
export type MicroareaImovelSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.MicroareaImovelSelectFieldQuery,
  Types.MicroareaImovelSelectFieldQueryVariables
>
export const MicroareasSelectFieldDocument = gql`
  query MicroareasSelectField($equipeResponsavelId: ID) {
    microareas(equipeResponsavelId: $equipeResponsavelId)
  }
`

/**
 * __useMicroareasSelectFieldQuery__
 *
 * To run a query within a React component, call `useMicroareasSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicroareasSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicroareasSelectFieldQuery({
 *   variables: {
 *      equipeResponsavelId: // value for 'equipeResponsavelId'
 *   },
 * });
 */
export function useMicroareasSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MicroareasSelectFieldQuery,
    Types.MicroareasSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.MicroareasSelectFieldQuery, Types.MicroareasSelectFieldQueryVariables>(
    MicroareasSelectFieldDocument,
    baseOptions
  )
}
export function useMicroareasSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MicroareasSelectFieldQuery,
    Types.MicroareasSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.MicroareasSelectFieldQuery, Types.MicroareasSelectFieldQueryVariables>(
    MicroareasSelectFieldDocument,
    baseOptions
  )
}
export type MicroareasSelectFieldQueryHookResult = ReturnType<typeof useMicroareasSelectFieldQuery>
export type MicroareasSelectFieldLazyQueryHookResult = ReturnType<typeof useMicroareasSelectFieldLazyQuery>
export type MicroareasSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.MicroareasSelectFieldQuery,
  Types.MicroareasSelectFieldQueryVariables
>
export const MunicipioSelectFieldDocument = gql`
  query MunicipioSelectField($input: MunicipiosQueryInput!) {
    municipios(input: $input) {
      content {
        id
        nome
        uf {
          id
          nome
          sigla
        }
        ibge
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMunicipioSelectFieldQuery__
 *
 * To run a query within a React component, call `useMunicipioSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMunicipioSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMunicipioSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMunicipioSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MunicipioSelectFieldQuery,
    Types.MunicipioSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.MunicipioSelectFieldQuery, Types.MunicipioSelectFieldQueryVariables>(
    MunicipioSelectFieldDocument,
    baseOptions
  )
}
export function useMunicipioSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MunicipioSelectFieldQuery,
    Types.MunicipioSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.MunicipioSelectFieldQuery, Types.MunicipioSelectFieldQueryVariables>(
    MunicipioSelectFieldDocument,
    baseOptions
  )
}
export type MunicipioSelectFieldQueryHookResult = ReturnType<typeof useMunicipioSelectFieldQuery>
export type MunicipioSelectFieldLazyQueryHookResult = ReturnType<typeof useMunicipioSelectFieldLazyQuery>
export type MunicipioSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.MunicipioSelectFieldQuery,
  Types.MunicipioSelectFieldQueryVariables
>
export const PaisSelectFieldDocument = gql`
  query PaisSelectField($input: PaisQueryInput!) {
    paises(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePaisSelectFieldQuery__
 *
 * To run a query within a React component, call `usePaisSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaisSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaisSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaisSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>(
    PaisSelectFieldDocument,
    baseOptions
  )
}
export function usePaisSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>(
    PaisSelectFieldDocument,
    baseOptions
  )
}
export type PaisSelectFieldQueryHookResult = ReturnType<typeof usePaisSelectFieldQuery>
export type PaisSelectFieldLazyQueryHookResult = ReturnType<typeof usePaisSelectFieldLazyQuery>
export type PaisSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.PaisSelectFieldQuery,
  Types.PaisSelectFieldQueryVariables
>
export const PerfilSelectFieldDocument = gql`
  query PerfilSelectField($input: PerfisQueryInput!) {
    perfis(input: $input) {
      content {
        id
        nome
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePerfilSelectFieldQuery__
 *
 * To run a query within a React component, call `usePerfilSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerfilSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerfilSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerfilSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PerfilSelectFieldQuery, Types.PerfilSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PerfilSelectFieldQuery, Types.PerfilSelectFieldQueryVariables>(
    PerfilSelectFieldDocument,
    baseOptions
  )
}
export function usePerfilSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PerfilSelectFieldQuery,
    Types.PerfilSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.PerfilSelectFieldQuery, Types.PerfilSelectFieldQueryVariables>(
    PerfilSelectFieldDocument,
    baseOptions
  )
}
export type PerfilSelectFieldQueryHookResult = ReturnType<typeof usePerfilSelectFieldQuery>
export type PerfilSelectFieldLazyQueryHookResult = ReturnType<typeof usePerfilSelectFieldLazyQuery>
export type PerfilSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.PerfilSelectFieldQuery,
  Types.PerfilSelectFieldQueryVariables
>
export const ProblemaCondicaoSelectFieldDocument = gql`
  query ProblemaCondicaoSelectField($filtro: ProblemaQueryInput!) {
    problemas(input: $filtro) {
      content {
        ...ProblemaCondicao
      }
    }
  }
  ${ProblemaCondicaoFragmentDoc}
`

/**
 * __useProblemaCondicaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useProblemaCondicaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemaCondicaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemaCondicaoSelectFieldQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProblemaCondicaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProblemaCondicaoSelectFieldQuery,
    Types.ProblemaCondicaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProblemaCondicaoSelectFieldQuery,
    Types.ProblemaCondicaoSelectFieldQueryVariables
  >(ProblemaCondicaoSelectFieldDocument, baseOptions)
}
export function useProblemaCondicaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProblemaCondicaoSelectFieldQuery,
    Types.ProblemaCondicaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProblemaCondicaoSelectFieldQuery,
    Types.ProblemaCondicaoSelectFieldQueryVariables
  >(ProblemaCondicaoSelectFieldDocument, baseOptions)
}
export type ProblemaCondicaoSelectFieldQueryHookResult = ReturnType<typeof useProblemaCondicaoSelectFieldQuery>
export type ProblemaCondicaoSelectFieldLazyQueryHookResult = ReturnType<typeof useProblemaCondicaoSelectFieldLazyQuery>
export type ProblemaCondicaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ProblemaCondicaoSelectFieldQuery,
  Types.ProblemaCondicaoSelectFieldQueryVariables
>
export const ProcedimentoOdontoSelectFieldDocument = gql`
  query ProcedimentoOdontoSelectField($input: ProcedimentoOdontoQueryInput!) {
    procedimentosEvolucaoOdonto(input: $input) {
      content {
        ...Procedimento
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProcedimentoFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useProcedimentoOdontoSelectFieldQuery__
 *
 * To run a query within a React component, call `useProcedimentoOdontoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcedimentoOdontoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcedimentoOdontoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcedimentoOdontoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProcedimentoOdontoSelectFieldQuery,
    Types.ProcedimentoOdontoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProcedimentoOdontoSelectFieldQuery,
    Types.ProcedimentoOdontoSelectFieldQueryVariables
  >(ProcedimentoOdontoSelectFieldDocument, baseOptions)
}
export function useProcedimentoOdontoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProcedimentoOdontoSelectFieldQuery,
    Types.ProcedimentoOdontoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProcedimentoOdontoSelectFieldQuery,
    Types.ProcedimentoOdontoSelectFieldQueryVariables
  >(ProcedimentoOdontoSelectFieldDocument, baseOptions)
}
export type ProcedimentoOdontoSelectFieldQueryHookResult = ReturnType<typeof useProcedimentoOdontoSelectFieldQuery>
export type ProcedimentoOdontoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useProcedimentoOdontoSelectFieldLazyQuery
>
export type ProcedimentoOdontoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ProcedimentoOdontoSelectFieldQuery,
  Types.ProcedimentoOdontoSelectFieldQueryVariables
>
export const ProcedimentoSelectFieldDocument = gql`
  query ProcedimentoSelectField($input: ProcedimentoQueryInput!) {
    procedimentos(input: $input) {
      content {
        ...Procedimento
        idadeMinima
        idadeMaxima
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProcedimentoFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useProcedimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useProcedimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcedimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcedimentoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcedimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProcedimentoSelectFieldQuery,
    Types.ProcedimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProcedimentoSelectFieldQuery, Types.ProcedimentoSelectFieldQueryVariables>(
    ProcedimentoSelectFieldDocument,
    baseOptions
  )
}
export function useProcedimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProcedimentoSelectFieldQuery,
    Types.ProcedimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProcedimentoSelectFieldQuery, Types.ProcedimentoSelectFieldQueryVariables>(
    ProcedimentoSelectFieldDocument,
    baseOptions
  )
}
export type ProcedimentoSelectFieldQueryHookResult = ReturnType<typeof useProcedimentoSelectFieldQuery>
export type ProcedimentoSelectFieldLazyQueryHookResult = ReturnType<typeof useProcedimentoSelectFieldLazyQuery>
export type ProcedimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ProcedimentoSelectFieldQuery,
  Types.ProcedimentoSelectFieldQueryVariables
>
export const ProfissionaisRelatorioInconsistenciaDocument = gql`
  query ProfissionaisRelatorioInconsistencia($filtro: ProfissionaisRelatorioInconsistenciaQueryInput!) {
    profissionaisRelatorioInconsistencia(filtro: $filtro) {
      content {
        responsavel
        cpfResponsavel
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useProfissionaisRelatorioInconsistenciaQuery__
 *
 * To run a query within a React component, call `useProfissionaisRelatorioInconsistenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionaisRelatorioInconsistenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionaisRelatorioInconsistenciaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProfissionaisRelatorioInconsistenciaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >(ProfissionaisRelatorioInconsistenciaDocument, baseOptions)
}
export function useProfissionaisRelatorioInconsistenciaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >(ProfissionaisRelatorioInconsistenciaDocument, baseOptions)
}
export type ProfissionaisRelatorioInconsistenciaQueryHookResult = ReturnType<
  typeof useProfissionaisRelatorioInconsistenciaQuery
>
export type ProfissionaisRelatorioInconsistenciaLazyQueryHookResult = ReturnType<
  typeof useProfissionaisRelatorioInconsistenciaLazyQuery
>
export type ProfissionaisRelatorioInconsistenciaQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionaisRelatorioInconsistenciaQuery,
  Types.ProfissionaisRelatorioInconsistenciaQueryVariables
>
export const ProfissionalAdSelectFieldDocument = gql`
  query ProfissionalAdSelectField($inputEquipe: EquipesAdQueryInput!, $inputProf: ProfissionaisAdQueryInput!) {
    profissionaisAD(equipeFilter: $inputEquipe, profissionalFilter: $inputProf) {
      content {
        id
        nome
        cpf
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useProfissionalAdSelectFieldQuery__
 *
 * To run a query within a React component, call `useProfissionalAdSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalAdSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalAdSelectFieldQuery({
 *   variables: {
 *      inputEquipe: // value for 'inputEquipe'
 *      inputProf: // value for 'inputProf'
 *   },
 * });
 */
export function useProfissionalAdSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionalAdSelectFieldQuery,
    Types.ProfissionalAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalAdSelectFieldQuery, Types.ProfissionalAdSelectFieldQueryVariables>(
    ProfissionalAdSelectFieldDocument,
    baseOptions
  )
}
export function useProfissionalAdSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalAdSelectFieldQuery,
    Types.ProfissionalAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProfissionalAdSelectFieldQuery,
    Types.ProfissionalAdSelectFieldQueryVariables
  >(ProfissionalAdSelectFieldDocument, baseOptions)
}
export type ProfissionalAdSelectFieldQueryHookResult = ReturnType<typeof useProfissionalAdSelectFieldQuery>
export type ProfissionalAdSelectFieldLazyQueryHookResult = ReturnType<typeof useProfissionalAdSelectFieldLazyQuery>
export type ProfissionalAdSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalAdSelectFieldQuery,
  Types.ProfissionalAdSelectFieldQueryVariables
>
export const ProfissionalSelectDocument = gql`
  query ProfissionalSelect($input: ProfissionaisQueryInput!) {
    profissionais(input: $input) {
      content {
        id
        nome
        cpf
        cns
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useProfissionalSelectQuery__
 *
 * To run a query within a React component, call `useProfissionalSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfissionalSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalSelectQuery, Types.ProfissionalSelectQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalSelectQuery, Types.ProfissionalSelectQueryVariables>(
    ProfissionalSelectDocument,
    baseOptions
  )
}
export function useProfissionalSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalSelectQuery,
    Types.ProfissionalSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalSelectQuery, Types.ProfissionalSelectQueryVariables>(
    ProfissionalSelectDocument,
    baseOptions
  )
}
export type ProfissionalSelectQueryHookResult = ReturnType<typeof useProfissionalSelectQuery>
export type ProfissionalSelectLazyQueryHookResult = ReturnType<typeof useProfissionalSelectLazyQuery>
export type ProfissionalSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalSelectQuery,
  Types.ProfissionalSelectQueryVariables
>
export const SubstanciaAlergiaSelectFieldDocument = gql`
  query SubstanciaAlergiaSelectField($filtro: SubstanciasCbaraAlergiaQueryInput!) {
    substanciasCbaraAlergia(input: $filtro) {
      content {
        id
        categoria
        nome
        sinonimos
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useSubstanciaAlergiaSelectFieldQuery__
 *
 * To run a query within a React component, call `useSubstanciaAlergiaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstanciaAlergiaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstanciaAlergiaSelectFieldQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useSubstanciaAlergiaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SubstanciaAlergiaSelectFieldQuery,
    Types.SubstanciaAlergiaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.SubstanciaAlergiaSelectFieldQuery,
    Types.SubstanciaAlergiaSelectFieldQueryVariables
  >(SubstanciaAlergiaSelectFieldDocument, baseOptions)
}
export function useSubstanciaAlergiaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SubstanciaAlergiaSelectFieldQuery,
    Types.SubstanciaAlergiaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.SubstanciaAlergiaSelectFieldQuery,
    Types.SubstanciaAlergiaSelectFieldQueryVariables
  >(SubstanciaAlergiaSelectFieldDocument, baseOptions)
}
export type SubstanciaAlergiaSelectFieldQueryHookResult = ReturnType<typeof useSubstanciaAlergiaSelectFieldQuery>
export type SubstanciaAlergiaSelectFieldLazyQueryHookResult = ReturnType<
  typeof useSubstanciaAlergiaSelectFieldLazyQuery
>
export type SubstanciaAlergiaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.SubstanciaAlergiaSelectFieldQuery,
  Types.SubstanciaAlergiaSelectFieldQueryVariables
>
export const SubtipoAtendimentoSelectFieldDocument = gql`
  query SubtipoAtendimentoSelectField($input: SubtipoAtendimentoHistoricoClinicoQueryInput!) {
    subtipoAtendimentoHistorico(input: $input)
  }
`

/**
 * __useSubtipoAtendimentoSelectFieldQuery__
 *
 * To run a query within a React component, call `useSubtipoAtendimentoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubtipoAtendimentoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubtipoAtendimentoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubtipoAtendimentoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SubtipoAtendimentoSelectFieldQuery,
    Types.SubtipoAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.SubtipoAtendimentoSelectFieldQuery,
    Types.SubtipoAtendimentoSelectFieldQueryVariables
  >(SubtipoAtendimentoSelectFieldDocument, baseOptions)
}
export function useSubtipoAtendimentoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SubtipoAtendimentoSelectFieldQuery,
    Types.SubtipoAtendimentoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.SubtipoAtendimentoSelectFieldQuery,
    Types.SubtipoAtendimentoSelectFieldQueryVariables
  >(SubtipoAtendimentoSelectFieldDocument, baseOptions)
}
export type SubtipoAtendimentoSelectFieldQueryHookResult = ReturnType<typeof useSubtipoAtendimentoSelectFieldQuery>
export type SubtipoAtendimentoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useSubtipoAtendimentoSelectFieldLazyQuery
>
export type SubtipoAtendimentoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.SubtipoAtendimentoSelectFieldQuery,
  Types.SubtipoAtendimentoSelectFieldQueryVariables
>
export const TipoEventoSelectFieldDocument = gql`
  query TipoEventoSelectField {
    auditoriaTipoEvento {
      id
      nome
    }
  }
`

/**
 * __useTipoEventoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoEventoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoEventoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoEventoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTipoEventoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoEventoSelectFieldQuery,
    Types.TipoEventoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.TipoEventoSelectFieldQuery, Types.TipoEventoSelectFieldQueryVariables>(
    TipoEventoSelectFieldDocument,
    baseOptions
  )
}
export function useTipoEventoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoEventoSelectFieldQuery,
    Types.TipoEventoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.TipoEventoSelectFieldQuery, Types.TipoEventoSelectFieldQueryVariables>(
    TipoEventoSelectFieldDocument,
    baseOptions
  )
}
export type TipoEventoSelectFieldQueryHookResult = ReturnType<typeof useTipoEventoSelectFieldQuery>
export type TipoEventoSelectFieldLazyQueryHookResult = ReturnType<typeof useTipoEventoSelectFieldLazyQuery>
export type TipoEventoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoEventoSelectFieldQuery,
  Types.TipoEventoSelectFieldQueryVariables
>
export const TipoLogradouroSelectFieldDocument = gql`
  query TipoLogradouroSelectField($input: TipoLogradouroInput!) {
    tiposLogradouro(input: $input) {
      content {
        id
        nome
        numeroDne
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTipoLogradouroSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoLogradouroSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoLogradouroSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoLogradouroSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipoLogradouroSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoLogradouroSelectFieldQuery,
    Types.TipoLogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.TipoLogradouroSelectFieldQuery, Types.TipoLogradouroSelectFieldQueryVariables>(
    TipoLogradouroSelectFieldDocument,
    baseOptions
  )
}
export function useTipoLogradouroSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoLogradouroSelectFieldQuery,
    Types.TipoLogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TipoLogradouroSelectFieldQuery,
    Types.TipoLogradouroSelectFieldQueryVariables
  >(TipoLogradouroSelectFieldDocument, baseOptions)
}
export type TipoLogradouroSelectFieldQueryHookResult = ReturnType<typeof useTipoLogradouroSelectFieldQuery>
export type TipoLogradouroSelectFieldLazyQueryHookResult = ReturnType<typeof useTipoLogradouroSelectFieldLazyQuery>
export type TipoLogradouroSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoLogradouroSelectFieldQuery,
  Types.TipoLogradouroSelectFieldQueryVariables
>
export const TipoRegistroAfetadoSelectFieldDocument = gql`
  query TipoRegistroAfetadoSelectField {
    auditoriaTipoRegistroAfetado {
      id
      nome
    }
  }
`

/**
 * __useTipoRegistroAfetadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoRegistroAfetadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoRegistroAfetadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoRegistroAfetadoSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTipoRegistroAfetadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoRegistroAfetadoSelectFieldQuery,
    Types.TipoRegistroAfetadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TipoRegistroAfetadoSelectFieldQuery,
    Types.TipoRegistroAfetadoSelectFieldQueryVariables
  >(TipoRegistroAfetadoSelectFieldDocument, baseOptions)
}
export function useTipoRegistroAfetadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoRegistroAfetadoSelectFieldQuery,
    Types.TipoRegistroAfetadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TipoRegistroAfetadoSelectFieldQuery,
    Types.TipoRegistroAfetadoSelectFieldQueryVariables
  >(TipoRegistroAfetadoSelectFieldDocument, baseOptions)
}
export type TipoRegistroAfetadoSelectFieldQueryHookResult = ReturnType<typeof useTipoRegistroAfetadoSelectFieldQuery>
export type TipoRegistroAfetadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTipoRegistroAfetadoSelectFieldLazyQuery
>
export type TipoRegistroAfetadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoRegistroAfetadoSelectFieldQuery,
  Types.TipoRegistroAfetadoSelectFieldQueryVariables
>
export const TipoRegistroSelectFieldDocument = gql`
  query TipoRegistroSelectField($input: TipoRegistroHistoricoClinicoQueryInput!) {
    tipoRegistroHistorico(input: $input)
  }
`

/**
 * __useTipoRegistroSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoRegistroSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoRegistroSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoRegistroSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipoRegistroSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoRegistroSelectFieldQuery,
    Types.TipoRegistroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.TipoRegistroSelectFieldQuery, Types.TipoRegistroSelectFieldQueryVariables>(
    TipoRegistroSelectFieldDocument,
    baseOptions
  )
}
export function useTipoRegistroSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoRegistroSelectFieldQuery,
    Types.TipoRegistroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.TipoRegistroSelectFieldQuery, Types.TipoRegistroSelectFieldQueryVariables>(
    TipoRegistroSelectFieldDocument,
    baseOptions
  )
}
export type TipoRegistroSelectFieldQueryHookResult = ReturnType<typeof useTipoRegistroSelectFieldQuery>
export type TipoRegistroSelectFieldLazyQueryHookResult = ReturnType<typeof useTipoRegistroSelectFieldLazyQuery>
export type TipoRegistroSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoRegistroSelectFieldQuery,
  Types.TipoRegistroSelectFieldQueryVariables
>
export const TipoServicoSelectFieldDocument = gql`
  query TipoServicoSelectField($input: TipoServicoQueryInput!) {
    tiposServico(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTipoServicoSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoServicoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoServicoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoServicoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipoServicoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoServicoSelectFieldQuery,
    Types.TipoServicoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.TipoServicoSelectFieldQuery, Types.TipoServicoSelectFieldQueryVariables>(
    TipoServicoSelectFieldDocument,
    baseOptions
  )
}
export function useTipoServicoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoServicoSelectFieldQuery,
    Types.TipoServicoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.TipoServicoSelectFieldQuery, Types.TipoServicoSelectFieldQueryVariables>(
    TipoServicoSelectFieldDocument,
    baseOptions
  )
}
export type TipoServicoSelectFieldQueryHookResult = ReturnType<typeof useTipoServicoSelectFieldQuery>
export type TipoServicoSelectFieldLazyQueryHookResult = ReturnType<typeof useTipoServicoSelectFieldLazyQuery>
export type TipoServicoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoServicoSelectFieldQuery,
  Types.TipoServicoSelectFieldQueryVariables
>
export const TiposOrigemDadoTransporteSelectFieldDocument = gql`
  query TiposOrigemDadoTransporteSelectField {
    tiposOrigemDadoTransporte {
      id
      descricao
    }
  }
`

/**
 * __useTiposOrigemDadoTransporteSelectFieldQuery__
 *
 * To run a query within a React component, call `useTiposOrigemDadoTransporteSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTiposOrigemDadoTransporteSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTiposOrigemDadoTransporteSelectFieldQuery({
 *   variables: {
 *   },
 * });
 */
export function useTiposOrigemDadoTransporteSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TiposOrigemDadoTransporteSelectFieldQuery,
    Types.TiposOrigemDadoTransporteSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TiposOrigemDadoTransporteSelectFieldQuery,
    Types.TiposOrigemDadoTransporteSelectFieldQueryVariables
  >(TiposOrigemDadoTransporteSelectFieldDocument, baseOptions)
}
export function useTiposOrigemDadoTransporteSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TiposOrigemDadoTransporteSelectFieldQuery,
    Types.TiposOrigemDadoTransporteSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TiposOrigemDadoTransporteSelectFieldQuery,
    Types.TiposOrigemDadoTransporteSelectFieldQueryVariables
  >(TiposOrigemDadoTransporteSelectFieldDocument, baseOptions)
}
export type TiposOrigemDadoTransporteSelectFieldQueryHookResult = ReturnType<
  typeof useTiposOrigemDadoTransporteSelectFieldQuery
>
export type TiposOrigemDadoTransporteSelectFieldLazyQueryHookResult = ReturnType<
  typeof useTiposOrigemDadoTransporteSelectFieldLazyQuery
>
export type TiposOrigemDadoTransporteSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TiposOrigemDadoTransporteSelectFieldQuery,
  Types.TiposOrigemDadoTransporteSelectFieldQueryVariables
>
export const UnidadeMedidaSelectFieldDocument = gql`
  query UnidadeMedidaSelectField($input: UnidadeMedidaQueryInput!) {
    unidadesMedida(input: $input) {
      content {
        id
        nome
        nomePlural
      }
    }
  }
`

/**
 * __useUnidadeMedidaSelectFieldQuery__
 *
 * To run a query within a React component, call `useUnidadeMedidaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeMedidaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeMedidaSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadeMedidaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadeMedidaSelectFieldQuery,
    Types.UnidadeMedidaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UnidadeMedidaSelectFieldQuery, Types.UnidadeMedidaSelectFieldQueryVariables>(
    UnidadeMedidaSelectFieldDocument,
    baseOptions
  )
}
export function useUnidadeMedidaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeMedidaSelectFieldQuery,
    Types.UnidadeMedidaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnidadeMedidaSelectFieldQuery,
    Types.UnidadeMedidaSelectFieldQueryVariables
  >(UnidadeMedidaSelectFieldDocument, baseOptions)
}
export type UnidadeMedidaSelectFieldQueryHookResult = ReturnType<typeof useUnidadeMedidaSelectFieldQuery>
export type UnidadeMedidaSelectFieldLazyQueryHookResult = ReturnType<typeof useUnidadeMedidaSelectFieldLazyQuery>
export type UnidadeMedidaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeMedidaSelectFieldQuery,
  Types.UnidadeMedidaSelectFieldQueryVariables
>
export const UnidadesSaudeSelectFieldDocument = gql`
  query UnidadesSaudeSelectField($input: UnidadesSaudeQueryInput!) {
    unidadesSaude(input: $input) {
      content {
        id
        cnes
        nome
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUnidadesSaudeSelectFieldQuery__
 *
 * To run a query within a React component, call `useUnidadesSaudeSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadesSaudeSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadesSaudeSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadesSaudeSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadesSaudeSelectFieldQuery,
    Types.UnidadesSaudeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UnidadesSaudeSelectFieldQuery, Types.UnidadesSaudeSelectFieldQueryVariables>(
    UnidadesSaudeSelectFieldDocument,
    baseOptions
  )
}
export function useUnidadesSaudeSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadesSaudeSelectFieldQuery,
    Types.UnidadesSaudeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnidadesSaudeSelectFieldQuery,
    Types.UnidadesSaudeSelectFieldQueryVariables
  >(UnidadesSaudeSelectFieldDocument, baseOptions)
}
export type UnidadesSaudeSelectFieldQueryHookResult = ReturnType<typeof useUnidadesSaudeSelectFieldQuery>
export type UnidadesSaudeSelectFieldLazyQueryHookResult = ReturnType<typeof useUnidadesSaudeSelectFieldLazyQuery>
export type UnidadesSaudeSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadesSaudeSelectFieldQuery,
  Types.UnidadesSaudeSelectFieldQueryVariables
>
export const ViaAdministracaoSelectFieldDocument = gql`
  query ViaAdministracaoSelectField($input: AplicacaoMedicamentoQueryInput!) {
    aplicacoesMedicamento(input: $input) {
      content {
        id
        nome
      }
    }
  }
`

/**
 * __useViaAdministracaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useViaAdministracaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useViaAdministracaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViaAdministracaoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViaAdministracaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ViaAdministracaoSelectFieldQuery,
    Types.ViaAdministracaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ViaAdministracaoSelectFieldQuery,
    Types.ViaAdministracaoSelectFieldQueryVariables
  >(ViaAdministracaoSelectFieldDocument, baseOptions)
}
export function useViaAdministracaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ViaAdministracaoSelectFieldQuery,
    Types.ViaAdministracaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ViaAdministracaoSelectFieldQuery,
    Types.ViaAdministracaoSelectFieldQueryVariables
  >(ViaAdministracaoSelectFieldDocument, baseOptions)
}
export type ViaAdministracaoSelectFieldQueryHookResult = ReturnType<typeof useViaAdministracaoSelectFieldQuery>
export type ViaAdministracaoSelectFieldLazyQueryHookResult = ReturnType<typeof useViaAdministracaoSelectFieldLazyQuery>
export type ViaAdministracaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ViaAdministracaoSelectFieldQuery,
  Types.ViaAdministracaoSelectFieldQueryVariables
>
export const ViaAdministracaoVacinaSelectFieldDocument = gql`
  query ViaAdministracaoVacinaSelectField($inputQuery: String) {
    viasAdministracaoVacina(inputQuery: $inputQuery) {
      id
      nome
    }
  }
`

/**
 * __useViaAdministracaoVacinaSelectFieldQuery__
 *
 * To run a query within a React component, call `useViaAdministracaoVacinaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useViaAdministracaoVacinaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViaAdministracaoVacinaSelectFieldQuery({
 *   variables: {
 *      inputQuery: // value for 'inputQuery'
 *   },
 * });
 */
export function useViaAdministracaoVacinaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ViaAdministracaoVacinaSelectFieldQuery,
    Types.ViaAdministracaoVacinaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ViaAdministracaoVacinaSelectFieldQuery,
    Types.ViaAdministracaoVacinaSelectFieldQueryVariables
  >(ViaAdministracaoVacinaSelectFieldDocument, baseOptions)
}
export function useViaAdministracaoVacinaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ViaAdministracaoVacinaSelectFieldQuery,
    Types.ViaAdministracaoVacinaSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ViaAdministracaoVacinaSelectFieldQuery,
    Types.ViaAdministracaoVacinaSelectFieldQueryVariables
  >(ViaAdministracaoVacinaSelectFieldDocument, baseOptions)
}
export type ViaAdministracaoVacinaSelectFieldQueryHookResult = ReturnType<
  typeof useViaAdministracaoVacinaSelectFieldQuery
>
export type ViaAdministracaoVacinaSelectFieldLazyQueryHookResult = ReturnType<
  typeof useViaAdministracaoVacinaSelectFieldLazyQuery
>
export type ViaAdministracaoVacinaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ViaAdministracaoVacinaSelectFieldQuery,
  Types.ViaAdministracaoVacinaSelectFieldQueryVariables
>
export const InstalacaoHeaderDocument = gql`
  query InstalacaoHeader {
    info {
      treinamento
      versao
    }
  }
`

/**
 * __useInstalacaoHeaderQuery__
 *
 * To run a query within a React component, call `useInstalacaoHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstalacaoHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstalacaoHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstalacaoHeaderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>(
    InstalacaoHeaderDocument,
    baseOptions
  )
}
export function useInstalacaoHeaderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>(
    InstalacaoHeaderDocument,
    baseOptions
  )
}
export type InstalacaoHeaderQueryHookResult = ReturnType<typeof useInstalacaoHeaderQuery>
export type InstalacaoHeaderLazyQueryHookResult = ReturnType<typeof useInstalacaoHeaderLazyQuery>
export type InstalacaoHeaderQueryResult = ApolloReactCommon.QueryResult<
  Types.InstalacaoHeaderQuery,
  Types.InstalacaoHeaderQueryVariables
>
export const CompartilharVideochamadaDocument = gql`
  mutation CompartilharVideochamada($input: CompartilharVideochamadaInput!) {
    compartilharVideochamada(input: $input)
  }
`
export type CompartilharVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.CompartilharVideochamadaMutation,
  Types.CompartilharVideochamadaMutationVariables
>

/**
 * __useCompartilharVideochamadaMutation__
 *
 * To run a mutation, you first call `useCompartilharVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompartilharVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compartilharVideochamadaMutation, { data, loading, error }] = useCompartilharVideochamadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompartilharVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CompartilharVideochamadaMutation,
    Types.CompartilharVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.CompartilharVideochamadaMutation,
    Types.CompartilharVideochamadaMutationVariables
  >(CompartilharVideochamadaDocument, baseOptions)
}
export type CompartilharVideochamadaMutationHookResult = ReturnType<typeof useCompartilharVideochamadaMutation>
export type CompartilharVideochamadaMutationResult = ApolloReactCommon.MutationResult<
  Types.CompartilharVideochamadaMutation
>
export type CompartilharVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CompartilharVideochamadaMutation,
  Types.CompartilharVideochamadaMutationVariables
>
export const FlagsDocument = gql`
  query Flags {
    info {
      flags {
        UNIFICACAO_BASE_ENABLED: unificacaoBase
        LISTA_REGISTRO_TARDIO_NOVA_ENABLED: listaRegistroTardioNova
        UNIFICACAO_BASE_ENABLED: unificacaoBase
        LISTA_REGISTRO_TARDIO_NOVA_ENABLED: listaRegistroTardioNova
        TERRITORIO_ENABLED: territorio
        VISUALIZACAO_TERRITORIO_ENABLED: visualizacaoTerritorio
        BUSCA_ATIVA_VACINACAO_ENABLED: buscaAtivaVacinacao
        API_DADOS_DW_ENABLED: apiDadosDW
        ENVIO_RAC_ENABLED: envioRac
        ENVIO_RPM_ENABLED: envioRpm
        ENVIO_VACINA_ENABLED: envioVacina
        ENVIO_RNDS_ENABLED: envioRnds
        CUIDADO_COMPARTILHADO_ENABLED: cuidadoCompartilhado
        HISTORICO_CUIDADO_COMPARTILHADO_ENABLED: historicoCuidadoCompartilhado
        PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED: pilotoCuidadoCompartilhado1
        PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED: pilotoCuidadoCompartilhado2
        COOKIE_ENABLED: cookie
        NPS_ENABLED: nps
        PILOTO_ATESTADO_DIGITAL_ENABLED: pilotoAtestadoDigital
        PILOTO_VIDEOCHAMADA_2_ENABLED: pilotoVideochamada2
        PILOTO_VIDEOCHAMADA_3_ENABLED: pilotoVideochamada3
        PRESCRICAO_CONTROLADA: prescricaoControlada
        IMPRESSAO_ESCUTA_INICIAL_ENABLED: impressaoEscutaInicial
        PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED: pilotoRecebimentoOnlineExterno
        GARANTIA_ACESSO_ENABLED: garantiaAcesso
        PILOTO_VINCULACAO_SERVICO_1_ENABLED: pilotoVinculacaoServico1
        PRESCRICAO_TURNO: prescricaoTurno
        ATUALIZACAO_SIGTAP_ENABLED: atualizacaoSigtap
        ACOMPANHAMENTO_CONDICAO_SAUDE_ENABLED: acompanhamentoCondicaoSaude
      }
    }
  }
`

/**
 * __useFlagsQuery__
 *
 * To run a query within a React component, call `useFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.FlagsQuery, Types.FlagsQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.FlagsQuery, Types.FlagsQueryVariables>(FlagsDocument, baseOptions)
}
export function useFlagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.FlagsQuery, Types.FlagsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.FlagsQuery, Types.FlagsQueryVariables>(FlagsDocument, baseOptions)
}
export type FlagsQueryHookResult = ReturnType<typeof useFlagsQuery>
export type FlagsLazyQueryHookResult = ReturnType<typeof useFlagsLazyQuery>
export type FlagsQueryResult = ApolloReactCommon.QueryResult<Types.FlagsQuery, Types.FlagsQueryVariables>
export const AsyncProcessingStatusDocument = gql`
  query AsyncProcessingStatus($asyncProcessingName: AsyncProcessingNameEnum!) {
    asyncProcessingStatus(asyncProcessingName: $asyncProcessingName) {
      isStarted
      isFinished
      errorMessage
    }
  }
`

/**
 * __useAsyncProcessingStatusQuery__
 *
 * To run a query within a React component, call `useAsyncProcessingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAsyncProcessingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAsyncProcessingStatusQuery({
 *   variables: {
 *      asyncProcessingName: // value for 'asyncProcessingName'
 *   },
 * });
 */
export function useAsyncProcessingStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AsyncProcessingStatusQuery,
    Types.AsyncProcessingStatusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AsyncProcessingStatusQuery, Types.AsyncProcessingStatusQueryVariables>(
    AsyncProcessingStatusDocument,
    baseOptions
  )
}
export function useAsyncProcessingStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AsyncProcessingStatusQuery,
    Types.AsyncProcessingStatusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AsyncProcessingStatusQuery, Types.AsyncProcessingStatusQueryVariables>(
    AsyncProcessingStatusDocument,
    baseOptions
  )
}
export type AsyncProcessingStatusQueryHookResult = ReturnType<typeof useAsyncProcessingStatusQuery>
export type AsyncProcessingStatusLazyQueryHookResult = ReturnType<typeof useAsyncProcessingStatusLazyQuery>
export type AsyncProcessingStatusQueryResult = ApolloReactCommon.QueryResult<
  Types.AsyncProcessingStatusQuery,
  Types.AsyncProcessingStatusQueryVariables
>
export const RemoverProcessoAssincronoDocument = gql`
  mutation RemoverProcessoAssincrono($asyncProcessingName: AsyncProcessingNameEnum!) {
    removerProcessoAssincrono(asyncProcessingName: $asyncProcessingName)
  }
`
export type RemoverProcessoAssincronoMutationFn = ApolloReactCommon.MutationFunction<
  Types.RemoverProcessoAssincronoMutation,
  Types.RemoverProcessoAssincronoMutationVariables
>

/**
 * __useRemoverProcessoAssincronoMutation__
 *
 * To run a mutation, you first call `useRemoverProcessoAssincronoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverProcessoAssincronoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerProcessoAssincronoMutation, { data, loading, error }] = useRemoverProcessoAssincronoMutation({
 *   variables: {
 *      asyncProcessingName: // value for 'asyncProcessingName'
 *   },
 * });
 */
export function useRemoverProcessoAssincronoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RemoverProcessoAssincronoMutation,
    Types.RemoverProcessoAssincronoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RemoverProcessoAssincronoMutation,
    Types.RemoverProcessoAssincronoMutationVariables
  >(RemoverProcessoAssincronoDocument, baseOptions)
}
export type RemoverProcessoAssincronoMutationHookResult = ReturnType<typeof useRemoverProcessoAssincronoMutation>
export type RemoverProcessoAssincronoMutationResult = ApolloReactCommon.MutationResult<
  Types.RemoverProcessoAssincronoMutation
>
export type RemoverProcessoAssincronoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RemoverProcessoAssincronoMutation,
  Types.RemoverProcessoAssincronoMutationVariables
>
export const RemoverTodosProcessosAssincronosDocument = gql`
  mutation RemoverTodosProcessosAssincronos {
    removerTodosProcessosAssincronos
  }
`
export type RemoverTodosProcessosAssincronosMutationFn = ApolloReactCommon.MutationFunction<
  Types.RemoverTodosProcessosAssincronosMutation,
  Types.RemoverTodosProcessosAssincronosMutationVariables
>

/**
 * __useRemoverTodosProcessosAssincronosMutation__
 *
 * To run a mutation, you first call `useRemoverTodosProcessosAssincronosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverTodosProcessosAssincronosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerTodosProcessosAssincronosMutation, { data, loading, error }] = useRemoverTodosProcessosAssincronosMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoverTodosProcessosAssincronosMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RemoverTodosProcessosAssincronosMutation,
    Types.RemoverTodosProcessosAssincronosMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RemoverTodosProcessosAssincronosMutation,
    Types.RemoverTodosProcessosAssincronosMutationVariables
  >(RemoverTodosProcessosAssincronosDocument, baseOptions)
}
export type RemoverTodosProcessosAssincronosMutationHookResult = ReturnType<
  typeof useRemoverTodosProcessosAssincronosMutation
>
export type RemoverTodosProcessosAssincronosMutationResult = ApolloReactCommon.MutationResult<
  Types.RemoverTodosProcessosAssincronosMutation
>
export type RemoverTodosProcessosAssincronosMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RemoverTodosProcessosAssincronosMutation,
  Types.RemoverTodosProcessosAssincronosMutationVariables
>
export const FirebaseDocument = gql`
  query Firebase {
    info {
      firebaseEnabled
    }
  }
`

/**
 * __useFirebaseQuery__
 *
 * To run a query within a React component, call `useFirebaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirebaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirebaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useFirebaseQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.FirebaseQuery, Types.FirebaseQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.FirebaseQuery, Types.FirebaseQueryVariables>(FirebaseDocument, baseOptions)
}
export function useFirebaseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.FirebaseQuery, Types.FirebaseQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.FirebaseQuery, Types.FirebaseQueryVariables>(FirebaseDocument, baseOptions)
}
export type FirebaseQueryHookResult = ReturnType<typeof useFirebaseQuery>
export type FirebaseLazyQueryHookResult = ReturnType<typeof useFirebaseLazyQuery>
export type FirebaseQueryResult = ApolloReactCommon.QueryResult<Types.FirebaseQuery, Types.FirebaseQueryVariables>
export const DefinirModuloInicialDocument = gql`
  mutation DefinirModuloInicial($input: SelecionarModuloInicialInput!) {
    definirModuloInicial(input: $input) {
      id
      moduloInicial
    }
  }
`
export type DefinirModuloInicialMutationFn = ApolloReactCommon.MutationFunction<
  Types.DefinirModuloInicialMutation,
  Types.DefinirModuloInicialMutationVariables
>

/**
 * __useDefinirModuloInicialMutation__
 *
 * To run a mutation, you first call `useDefinirModuloInicialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDefinirModuloInicialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [definirModuloInicialMutation, { data, loading, error }] = useDefinirModuloInicialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDefinirModuloInicialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DefinirModuloInicialMutation,
    Types.DefinirModuloInicialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.DefinirModuloInicialMutation, Types.DefinirModuloInicialMutationVariables>(
    DefinirModuloInicialDocument,
    baseOptions
  )
}
export type DefinirModuloInicialMutationHookResult = ReturnType<typeof useDefinirModuloInicialMutation>
export type DefinirModuloInicialMutationResult = ApolloReactCommon.MutationResult<Types.DefinirModuloInicialMutation>
export type DefinirModuloInicialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DefinirModuloInicialMutation,
  Types.DefinirModuloInicialMutationVariables
>
export const InitialViewDocument = gql`
  query InitialView {
    info {
      ativado
    }
  }
`

/**
 * __useInitialViewQuery__
 *
 * To run a query within a React component, call `useInitialViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitialViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.InitialViewQuery, Types.InitialViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.InitialViewQuery, Types.InitialViewQueryVariables>(
    InitialViewDocument,
    baseOptions
  )
}
export function useInitialViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.InitialViewQuery, Types.InitialViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.InitialViewQuery, Types.InitialViewQueryVariables>(
    InitialViewDocument,
    baseOptions
  )
}
export type InitialViewQueryHookResult = ReturnType<typeof useInitialViewQuery>
export type InitialViewLazyQueryHookResult = ReturnType<typeof useInitialViewLazyQuery>
export type InitialViewQueryResult = ApolloReactCommon.QueryResult<
  Types.InitialViewQuery,
  Types.InitialViewQueryVariables
>
export const AcompanhamentoCidadaosVinculadosProcessamentoDocument = gql`
  query AcompanhamentoCidadaosVinculadosProcessamento {
    processamentoStatus
  }
`

/**
 * __useAcompanhamentoCidadaosVinculadosProcessamentoQuery__
 *
 * To run a query within a React component, call `useAcompanhamentoCidadaosVinculadosProcessamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcompanhamentoCidadaosVinculadosProcessamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcompanhamentoCidadaosVinculadosProcessamentoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcompanhamentoCidadaosVinculadosProcessamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQuery,
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQuery,
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQueryVariables
  >(AcompanhamentoCidadaosVinculadosProcessamentoDocument, baseOptions)
}
export function useAcompanhamentoCidadaosVinculadosProcessamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQuery,
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQuery,
    Types.AcompanhamentoCidadaosVinculadosProcessamentoQueryVariables
  >(AcompanhamentoCidadaosVinculadosProcessamentoDocument, baseOptions)
}
export type AcompanhamentoCidadaosVinculadosProcessamentoQueryHookResult = ReturnType<
  typeof useAcompanhamentoCidadaosVinculadosProcessamentoQuery
>
export type AcompanhamentoCidadaosVinculadosProcessamentoLazyQueryHookResult = ReturnType<
  typeof useAcompanhamentoCidadaosVinculadosProcessamentoLazyQuery
>
export type AcompanhamentoCidadaosVinculadosProcessamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.AcompanhamentoCidadaosVinculadosProcessamentoQuery,
  Types.AcompanhamentoCidadaosVinculadosProcessamentoQueryVariables
>
export const AcompanhamentoCidadaosVinculadosDocument = gql`
  query AcompanhamentoCidadaosVinculados($input: AcompanhamentoCidadaosVinculadosFilterInput!) {
    acompanhamentoCidadaosVinculados(input: $input) {
      content {
        id
        nomeCidadao
        dataNascimentoCidadao
        sexoIdentidadeGeneroCidadao
        cpfCidadao
        cnsCidadao
        telefoneCelular
        telefoneContato
        dataUltimaAtualizacaoCidadao
        telefoneResidencial
        idade
        endereco
        equipe
        microarea
        origem
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useAcompanhamentoCidadaosVinculadosQuery__
 *
 * To run a query within a React component, call `useAcompanhamentoCidadaosVinculadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcompanhamentoCidadaosVinculadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcompanhamentoCidadaosVinculadosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcompanhamentoCidadaosVinculadosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcompanhamentoCidadaosVinculadosQuery,
    Types.AcompanhamentoCidadaosVinculadosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AcompanhamentoCidadaosVinculadosQuery,
    Types.AcompanhamentoCidadaosVinculadosQueryVariables
  >(AcompanhamentoCidadaosVinculadosDocument, baseOptions)
}
export function useAcompanhamentoCidadaosVinculadosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcompanhamentoCidadaosVinculadosQuery,
    Types.AcompanhamentoCidadaosVinculadosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AcompanhamentoCidadaosVinculadosQuery,
    Types.AcompanhamentoCidadaosVinculadosQueryVariables
  >(AcompanhamentoCidadaosVinculadosDocument, baseOptions)
}
export type AcompanhamentoCidadaosVinculadosQueryHookResult = ReturnType<
  typeof useAcompanhamentoCidadaosVinculadosQuery
>
export type AcompanhamentoCidadaosVinculadosLazyQueryHookResult = ReturnType<
  typeof useAcompanhamentoCidadaosVinculadosLazyQuery
>
export type AcompanhamentoCidadaosVinculadosQueryResult = ApolloReactCommon.QueryResult<
  Types.AcompanhamentoCidadaosVinculadosQuery,
  Types.AcompanhamentoCidadaosVinculadosQueryVariables
>
export const BuscaCidadaoListagemDocument = gql`
  query BuscaCidadaoListagem($input: AcompanhamentoCondicaoSaudeQueryInput!) {
    acompanhamentoCondicaoSaude(input: $input) {
      content {
        id
        dataNascimento
        nome
        nomeSocial
        cpf
        telefoneResidencial
        telefoneCelular
        telefoneContato
        dataUltimoAtendimento
        endereco {
          logradouro
          numero
          complemento
          bairro
          municipio {
            id
            nome
          }
          tipoLogradouro {
            id
            nome
          }
          uf {
            id
            sigla
          }
          cep
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useBuscaCidadaoListagemQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoListagemQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoListagemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoListagemQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuscaCidadaoListagemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaCidadaoListagemQuery,
    Types.BuscaCidadaoListagemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BuscaCidadaoListagemQuery, Types.BuscaCidadaoListagemQueryVariables>(
    BuscaCidadaoListagemDocument,
    baseOptions
  )
}
export function useBuscaCidadaoListagemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoListagemQuery,
    Types.BuscaCidadaoListagemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaCidadaoListagemQuery, Types.BuscaCidadaoListagemQueryVariables>(
    BuscaCidadaoListagemDocument,
    baseOptions
  )
}
export type BuscaCidadaoListagemQueryHookResult = ReturnType<typeof useBuscaCidadaoListagemQuery>
export type BuscaCidadaoListagemLazyQueryHookResult = ReturnType<typeof useBuscaCidadaoListagemLazyQuery>
export type BuscaCidadaoListagemQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoListagemQuery,
  Types.BuscaCidadaoListagemQueryVariables
>
export const SalvarApoioEmapEmadDocument = gql`
  mutation SalvarApoioEmapEmad($input: ConfigAtendimentoDomInput!) {
    salvarConfigAtendimentoDomTipoApoio(input: $input) {
      id
    }
  }
`
export type SalvarApoioEmapEmadMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarApoioEmapEmadMutation,
  Types.SalvarApoioEmapEmadMutationVariables
>

/**
 * __useSalvarApoioEmapEmadMutation__
 *
 * To run a mutation, you first call `useSalvarApoioEmapEmadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarApoioEmapEmadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarApoioEmapEmadMutation, { data, loading, error }] = useSalvarApoioEmapEmadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarApoioEmapEmadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarApoioEmapEmadMutation,
    Types.SalvarApoioEmapEmadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarApoioEmapEmadMutation, Types.SalvarApoioEmapEmadMutationVariables>(
    SalvarApoioEmapEmadDocument,
    baseOptions
  )
}
export type SalvarApoioEmapEmadMutationHookResult = ReturnType<typeof useSalvarApoioEmapEmadMutation>
export type SalvarApoioEmapEmadMutationResult = ApolloReactCommon.MutationResult<Types.SalvarApoioEmapEmadMutation>
export type SalvarApoioEmapEmadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarApoioEmapEmadMutation,
  Types.SalvarApoioEmapEmadMutationVariables
>
export const SalvarPodeAtenderEmadDocument = gql`
  mutation SalvarPodeAtenderEmad($input: ConfigAtendimentoDomInput!) {
    salvarConfigAtendimentoDomTipoPodeAtender(input: $input) {
      id
    }
  }
`
export type SalvarPodeAtenderEmadMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarPodeAtenderEmadMutation,
  Types.SalvarPodeAtenderEmadMutationVariables
>

/**
 * __useSalvarPodeAtenderEmadMutation__
 *
 * To run a mutation, you first call `useSalvarPodeAtenderEmadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarPodeAtenderEmadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarPodeAtenderEmadMutation, { data, loading, error }] = useSalvarPodeAtenderEmadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarPodeAtenderEmadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarPodeAtenderEmadMutation,
    Types.SalvarPodeAtenderEmadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarPodeAtenderEmadMutation,
    Types.SalvarPodeAtenderEmadMutationVariables
  >(SalvarPodeAtenderEmadDocument, baseOptions)
}
export type SalvarPodeAtenderEmadMutationHookResult = ReturnType<typeof useSalvarPodeAtenderEmadMutation>
export type SalvarPodeAtenderEmadMutationResult = ApolloReactCommon.MutationResult<Types.SalvarPodeAtenderEmadMutation>
export type SalvarPodeAtenderEmadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarPodeAtenderEmadMutation,
  Types.SalvarPodeAtenderEmadMutationVariables
>
export const ConfiguracoesAtencaoDomDocument = gql`
  query ConfiguracoesAtencaoDom($input: ConfiguracoesAtendimentosDomInput) {
    configuracoesAtencaoDom {
      duracao
      emadsAtendemEmads(input: $input) {
        content {
          id
          identificadorNacionalEquipePai {
            id
            nome
            ine
          }
          identificadorNacionalEquipeFilho {
            id
            nome
            ine
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      emapsApoiamEmads(input: $input) {
        content {
          id
          identificadorNacionalEquipePai {
            id
            nome
            ine
          }
          identificadorNacionalEquipeFilho {
            id
            nome
            ine
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useConfiguracoesAtencaoDomQuery__
 *
 * To run a query within a React component, call `useConfiguracoesAtencaoDomQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracoesAtencaoDomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracoesAtencaoDomQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfiguracoesAtencaoDomQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracoesAtencaoDomQuery,
    Types.ConfiguracoesAtencaoDomQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracoesAtencaoDomQuery, Types.ConfiguracoesAtencaoDomQueryVariables>(
    ConfiguracoesAtencaoDomDocument,
    baseOptions
  )
}
export function useConfiguracoesAtencaoDomLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracoesAtencaoDomQuery,
    Types.ConfiguracoesAtencaoDomQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracoesAtencaoDomQuery, Types.ConfiguracoesAtencaoDomQueryVariables>(
    ConfiguracoesAtencaoDomDocument,
    baseOptions
  )
}
export type ConfiguracoesAtencaoDomQueryHookResult = ReturnType<typeof useConfiguracoesAtencaoDomQuery>
export type ConfiguracoesAtencaoDomLazyQueryHookResult = ReturnType<typeof useConfiguracoesAtencaoDomLazyQuery>
export type ConfiguracoesAtencaoDomQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracoesAtencaoDomQuery,
  Types.ConfiguracoesAtencaoDomQueryVariables
>
export const EquipesAtencaoDomDocument = gql`
  query EquipesAtencaoDom($input: EquipeADInput) {
    equipesAtencaoDom(input: $input) {
      content {
        id
        nome
        ine
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipesAtencaoDomQuery__
 *
 * To run a query within a React component, call `useEquipesAtencaoDomQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipesAtencaoDomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipesAtencaoDomQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipesAtencaoDomQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipesAtencaoDomQuery, Types.EquipesAtencaoDomQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EquipesAtencaoDomQuery, Types.EquipesAtencaoDomQueryVariables>(
    EquipesAtencaoDomDocument,
    baseOptions
  )
}
export function useEquipesAtencaoDomLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipesAtencaoDomQuery,
    Types.EquipesAtencaoDomQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipesAtencaoDomQuery, Types.EquipesAtencaoDomQueryVariables>(
    EquipesAtencaoDomDocument,
    baseOptions
  )
}
export type EquipesAtencaoDomQueryHookResult = ReturnType<typeof useEquipesAtencaoDomQuery>
export type EquipesAtencaoDomLazyQueryHookResult = ReturnType<typeof useEquipesAtencaoDomLazyQuery>
export type EquipesAtencaoDomQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipesAtencaoDomQuery,
  Types.EquipesAtencaoDomQueryVariables
>
export const ExcluirConfigAtendimentoDomDocument = gql`
  mutation ExcluirConfigAtendimentoDom($idConfig: ID!) {
    excluirConfigAtendimentoDom(idConfig: $idConfig)
  }
`
export type ExcluirConfigAtendimentoDomMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirConfigAtendimentoDomMutation,
  Types.ExcluirConfigAtendimentoDomMutationVariables
>

/**
 * __useExcluirConfigAtendimentoDomMutation__
 *
 * To run a mutation, you first call `useExcluirConfigAtendimentoDomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirConfigAtendimentoDomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirConfigAtendimentoDomMutation, { data, loading, error }] = useExcluirConfigAtendimentoDomMutation({
 *   variables: {
 *      idConfig: // value for 'idConfig'
 *   },
 * });
 */
export function useExcluirConfigAtendimentoDomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirConfigAtendimentoDomMutation,
    Types.ExcluirConfigAtendimentoDomMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirConfigAtendimentoDomMutation,
    Types.ExcluirConfigAtendimentoDomMutationVariables
  >(ExcluirConfigAtendimentoDomDocument, baseOptions)
}
export type ExcluirConfigAtendimentoDomMutationHookResult = ReturnType<typeof useExcluirConfigAtendimentoDomMutation>
export type ExcluirConfigAtendimentoDomMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirConfigAtendimentoDomMutation
>
export type ExcluirConfigAtendimentoDomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirConfigAtendimentoDomMutation,
  Types.ExcluirConfigAtendimentoDomMutationVariables
>
export const SalvarDuracaoAgendamentoAdDocument = gql`
  mutation SalvarDuracaoAgendamentoAD($input: DuracaoAgendamentoAtencaoDomInput!) {
    salvarDuracaoAgendamentoAtencaoDom(input: $input) {
      duracaoAgendamento
    }
  }
`
export type SalvarDuracaoAgendamentoAdMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarDuracaoAgendamentoAdMutation,
  Types.SalvarDuracaoAgendamentoAdMutationVariables
>

/**
 * __useSalvarDuracaoAgendamentoAdMutation__
 *
 * To run a mutation, you first call `useSalvarDuracaoAgendamentoAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarDuracaoAgendamentoAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarDuracaoAgendamentoAdMutation, { data, loading, error }] = useSalvarDuracaoAgendamentoAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarDuracaoAgendamentoAdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarDuracaoAgendamentoAdMutation,
    Types.SalvarDuracaoAgendamentoAdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarDuracaoAgendamentoAdMutation,
    Types.SalvarDuracaoAgendamentoAdMutationVariables
  >(SalvarDuracaoAgendamentoAdDocument, baseOptions)
}
export type SalvarDuracaoAgendamentoAdMutationHookResult = ReturnType<typeof useSalvarDuracaoAgendamentoAdMutation>
export type SalvarDuracaoAgendamentoAdMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarDuracaoAgendamentoAdMutation
>
export type SalvarDuracaoAgendamentoAdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarDuracaoAgendamentoAdMutation,
  Types.SalvarDuracaoAgendamentoAdMutationVariables
>
export const CidadaoAdLoadDocument = gql`
  query CidadaoADLoad($input: ID!) {
    cidadaoAD(input: $input) {
      id
      cidadao {
        id
        nome
        dataNascimento
        nomeMae
        nomeSocial
        nomeResponsavel
        dtNascimentoResponsavel
        cpfCnsResponsavel
        naoPossuiCuidador
        cpfCnsCuidador
        nomeCuidador
        dtNascimentoCuidador
        tipoCuidador
        sexo
        identidadeGeneroDbEnum
        dataObito
      }
      cidadaoAdEmObito {
        id
        cidadao {
          id
          nome
          nomeSocial
          dataNascimento
          dataObito
        }
      }
      cidadaosAdEmPosObito {
        id
        cidadao {
          id
          nome
          nomeSocial
          cpf
          cns
        }
      }
      modalidade
      procedencia
      procedenciaEspecificacao
      cid10Principal {
        id
        nome
        codigo
      }
      cid10Secundario1 {
        id
        nome
        codigo
      }
      cid10Secundario2 {
        id
        nome
        codigo
      }
      condutaDesfecho
      dataAdmissao
      dataSaida
      dataObito
      dataAgendado
      dataUltimaVisita
      numeroDocumentoObito
      actions {
        permissaoEditarAD {
          enabled
          hint
        }
      }
    }
  }
`

/**
 * __useCidadaoAdLoadQuery__
 *
 * To run a query within a React component, call `useCidadaoAdLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAdLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoAdLoadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoAdLoadQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoAdLoadQuery, Types.CidadaoAdLoadQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoAdLoadQuery, Types.CidadaoAdLoadQueryVariables>(
    CidadaoAdLoadDocument,
    baseOptions
  )
}
export function useCidadaoAdLoadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidadaoAdLoadQuery, Types.CidadaoAdLoadQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoAdLoadQuery, Types.CidadaoAdLoadQueryVariables>(
    CidadaoAdLoadDocument,
    baseOptions
  )
}
export type CidadaoAdLoadQueryHookResult = ReturnType<typeof useCidadaoAdLoadQuery>
export type CidadaoAdLoadLazyQueryHookResult = ReturnType<typeof useCidadaoAdLoadLazyQuery>
export type CidadaoAdLoadQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoAdLoadQuery,
  Types.CidadaoAdLoadQueryVariables
>
export const CidadaoAdSaveDocument = gql`
  mutation CidadaoADSave($input: AtencaoDomiciliarCidadaoInput!) {
    salvarCidadaoAtencaoDomiciliar(input: $input) {
      id
    }
  }
`
export type CidadaoAdSaveMutationFn = ApolloReactCommon.MutationFunction<
  Types.CidadaoAdSaveMutation,
  Types.CidadaoAdSaveMutationVariables
>

/**
 * __useCidadaoAdSaveMutation__
 *
 * To run a mutation, you first call `useCidadaoAdSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAdSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cidadaoAdSaveMutation, { data, loading, error }] = useCidadaoAdSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoAdSaveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CidadaoAdSaveMutation, Types.CidadaoAdSaveMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.CidadaoAdSaveMutation, Types.CidadaoAdSaveMutationVariables>(
    CidadaoAdSaveDocument,
    baseOptions
  )
}
export type CidadaoAdSaveMutationHookResult = ReturnType<typeof useCidadaoAdSaveMutation>
export type CidadaoAdSaveMutationResult = ApolloReactCommon.MutationResult<Types.CidadaoAdSaveMutation>
export type CidadaoAdSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CidadaoAdSaveMutation,
  Types.CidadaoAdSaveMutationVariables
>
export const EquipeCidadaoAdListaDocument = gql`
  query EquipeCidadaoADLista($input: CidadaosAdQueryInput!) {
    cidadaosAD(input: $input) {
      content {
        id
        cidadao {
          id
        }
        equipe {
          id
          nome
          ine
        }
      }
    }
  }
`

/**
 * __useEquipeCidadaoAdListaQuery__
 *
 * To run a query within a React component, call `useEquipeCidadaoAdListaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeCidadaoAdListaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeCidadaoAdListaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeCidadaoAdListaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EquipeCidadaoAdListaQuery,
    Types.EquipeCidadaoAdListaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EquipeCidadaoAdListaQuery, Types.EquipeCidadaoAdListaQueryVariables>(
    EquipeCidadaoAdListaDocument,
    baseOptions
  )
}
export function useEquipeCidadaoAdListaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeCidadaoAdListaQuery,
    Types.EquipeCidadaoAdListaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipeCidadaoAdListaQuery, Types.EquipeCidadaoAdListaQueryVariables>(
    EquipeCidadaoAdListaDocument,
    baseOptions
  )
}
export type EquipeCidadaoAdListaQueryHookResult = ReturnType<typeof useEquipeCidadaoAdListaQuery>
export type EquipeCidadaoAdListaLazyQueryHookResult = ReturnType<typeof useEquipeCidadaoAdListaLazyQuery>
export type EquipeCidadaoAdListaQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeCidadaoAdListaQuery,
  Types.EquipeCidadaoAdListaQueryVariables
>
export const CidadaoAdExcluirDocument = gql`
  mutation CidadaoADExcluir($id: ID!) {
    excluirCidadaoAtendimentoDomiciliar(id: $id)
  }
`
export type CidadaoAdExcluirMutationFn = ApolloReactCommon.MutationFunction<
  Types.CidadaoAdExcluirMutation,
  Types.CidadaoAdExcluirMutationVariables
>

/**
 * __useCidadaoAdExcluirMutation__
 *
 * To run a mutation, you first call `useCidadaoAdExcluirMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAdExcluirMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cidadaoAdExcluirMutation, { data, loading, error }] = useCidadaoAdExcluirMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCidadaoAdExcluirMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CidadaoAdExcluirMutation,
    Types.CidadaoAdExcluirMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.CidadaoAdExcluirMutation, Types.CidadaoAdExcluirMutationVariables>(
    CidadaoAdExcluirDocument,
    baseOptions
  )
}
export type CidadaoAdExcluirMutationHookResult = ReturnType<typeof useCidadaoAdExcluirMutation>
export type CidadaoAdExcluirMutationResult = ApolloReactCommon.MutationResult<Types.CidadaoAdExcluirMutation>
export type CidadaoAdExcluirMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CidadaoAdExcluirMutation,
  Types.CidadaoAdExcluirMutationVariables
>
export const ListaAdTableDocument = gql`
  query ListaADTable($input: CidadaosAdQueryInput!) {
    cidadaosAD(input: $input) {
      content {
        id
        cidadao {
          id
          nome
          cpf
          cns
          nomeSocial
          prontuario {
            id
          }
        }
        equipe {
          id
          nome
        }
        modalidade
        condutaDesfecho
        dataAgendado
        dataUltimaVisita
        actions {
          permissaoEditarAD {
            enabled
            hint
          }
          adEncerrado {
            enabled
            hint
          }
          permissaoExcluirAD {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useListaAdTableQuery__
 *
 * To run a query within a React component, call `useListaAdTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useListaAdTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListaAdTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListaAdTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ListaAdTableQuery, Types.ListaAdTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ListaAdTableQuery, Types.ListaAdTableQueryVariables>(
    ListaAdTableDocument,
    baseOptions
  )
}
export function useListaAdTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ListaAdTableQuery, Types.ListaAdTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ListaAdTableQuery, Types.ListaAdTableQueryVariables>(
    ListaAdTableDocument,
    baseOptions
  )
}
export type ListaAdTableQueryHookResult = ReturnType<typeof useListaAdTableQuery>
export type ListaAdTableLazyQueryHookResult = ReturnType<typeof useListaAdTableLazyQuery>
export type ListaAdTableQueryResult = ApolloReactCommon.QueryResult<
  Types.ListaAdTableQuery,
  Types.ListaAdTableQueryVariables
>
export const CidadaoAdSelectFieldDocument = gql`
  query CidadaoADSelectField($input: CidadaosAdQueryInput!) {
    cidadaosAD(input: $input) {
      content {
        id
        cidadao {
          id
          nome
          cpf
          cns
          nomeSocial
          dataNascimento
          nomeMae
          dataObito
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCidadaoAdSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoAdSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoAdSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoAdSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoAdSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoAdSelectFieldQuery,
    Types.CidadaoAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CidadaoAdSelectFieldQuery, Types.CidadaoAdSelectFieldQueryVariables>(
    CidadaoAdSelectFieldDocument,
    baseOptions
  )
}
export function useCidadaoAdSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoAdSelectFieldQuery,
    Types.CidadaoAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoAdSelectFieldQuery, Types.CidadaoAdSelectFieldQueryVariables>(
    CidadaoAdSelectFieldDocument,
    baseOptions
  )
}
export type CidadaoAdSelectFieldQueryHookResult = ReturnType<typeof useCidadaoAdSelectFieldQuery>
export type CidadaoAdSelectFieldLazyQueryHookResult = ReturnType<typeof useCidadaoAdSelectFieldLazyQuery>
export type CidadaoAdSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoAdSelectFieldQuery,
  Types.CidadaoAdSelectFieldQueryVariables
>
export const CidadaoListaAdDocument = gql`
  query CidadaoListaAD($id: ID!) {
    cidadao(id: $id) {
      id
      nome
      nomeSocial
      cpf
      cns
      nomeMae
      dataNascimento
      sexo
      identidadeGeneroDbEnum
    }
  }
`

/**
 * __useCidadaoListaAdQuery__
 *
 * To run a query within a React component, call `useCidadaoListaAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoListaAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoListaAdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCidadaoListaAdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoListaAdQuery, Types.CidadaoListaAdQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoListaAdQuery, Types.CidadaoListaAdQueryVariables>(
    CidadaoListaAdDocument,
    baseOptions
  )
}
export function useCidadaoListaAdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidadaoListaAdQuery, Types.CidadaoListaAdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoListaAdQuery, Types.CidadaoListaAdQueryVariables>(
    CidadaoListaAdDocument,
    baseOptions
  )
}
export type CidadaoListaAdQueryHookResult = ReturnType<typeof useCidadaoListaAdQuery>
export type CidadaoListaAdLazyQueryHookResult = ReturnType<typeof useCidadaoListaAdLazyQuery>
export type CidadaoListaAdQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoListaAdQuery,
  Types.CidadaoListaAdQueryVariables
>
export const CidadaoListaAdSelectFieldDocument = gql`
  query CidadaoListaADSelectField($input: CidadaosComboQueryInput!, $emAtencaoDomiciliar: Boolean!) {
    cidadaosCombo(input: $input, atencaoDomiciliar: $emAtencaoDomiciliar) {
      id
      nome
      nomeSocial
      cpf
      cns
      nomeMae
      dataNascimento
      sexo
      identidadeGeneroDbEnum
      equipeAtendimentoAd {
        id
        nome
        ine
      }
    }
  }
`

/**
 * __useCidadaoListaAdSelectFieldQuery__
 *
 * To run a query within a React component, call `useCidadaoListaAdSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoListaAdSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoListaAdSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *      emAtencaoDomiciliar: // value for 'emAtencaoDomiciliar'
 *   },
 * });
 */
export function useCidadaoListaAdSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoListaAdSelectFieldQuery,
    Types.CidadaoListaAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CidadaoListaAdSelectFieldQuery, Types.CidadaoListaAdSelectFieldQueryVariables>(
    CidadaoListaAdSelectFieldDocument,
    baseOptions
  )
}
export function useCidadaoListaAdSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoListaAdSelectFieldQuery,
    Types.CidadaoListaAdSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoListaAdSelectFieldQuery,
    Types.CidadaoListaAdSelectFieldQueryVariables
  >(CidadaoListaAdSelectFieldDocument, baseOptions)
}
export type CidadaoListaAdSelectFieldQueryHookResult = ReturnType<typeof useCidadaoListaAdSelectFieldQuery>
export type CidadaoListaAdSelectFieldLazyQueryHookResult = ReturnType<typeof useCidadaoListaAdSelectFieldLazyQuery>
export type CidadaoListaAdSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoListaAdSelectFieldQuery,
  Types.CidadaoListaAdSelectFieldQueryVariables
>
export const AgendamentosDiaLotacaoDocument = gql`
  query AgendamentosDiaLotacao($input: AgendadosQueryInput!) {
    agendados(input: $input) {
      content {
        ...Agendamento
        tipoAgendamento
        cidadao {
          id
          nome
          nomeSocial
          contato {
            id
            telefoneResidencial
            telefoneCelular
            telefoneContato
          }
        }
        atendimento {
          id
          statusAtendimento
          possuiAtendimentosProfissionais
        }
        isAtencaoDomiciliar
        motivoReserva
        outroMotivoReserva
        prontuarioId
        profissionalAnfitriao {
          id
          lotacaoId
          email
        }
        profissionalParticipante {
          id
          email
          nome
        }
        videochamada {
          id
          uuid
        }
      }
    }
  }
  ${AgendamentoFragmentDoc}
`

/**
 * __useAgendamentosDiaLotacaoQuery__
 *
 * To run a query within a React component, call `useAgendamentosDiaLotacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendamentosDiaLotacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendamentosDiaLotacaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgendamentosDiaLotacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AgendamentosDiaLotacaoQuery,
    Types.AgendamentosDiaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AgendamentosDiaLotacaoQuery, Types.AgendamentosDiaLotacaoQueryVariables>(
    AgendamentosDiaLotacaoDocument,
    baseOptions
  )
}
export function useAgendamentosDiaLotacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AgendamentosDiaLotacaoQuery,
    Types.AgendamentosDiaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AgendamentosDiaLotacaoQuery, Types.AgendamentosDiaLotacaoQueryVariables>(
    AgendamentosDiaLotacaoDocument,
    baseOptions
  )
}
export type AgendamentosDiaLotacaoQueryHookResult = ReturnType<typeof useAgendamentosDiaLotacaoQuery>
export type AgendamentosDiaLotacaoLazyQueryHookResult = ReturnType<typeof useAgendamentosDiaLotacaoLazyQuery>
export type AgendamentosDiaLotacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendamentosDiaLotacaoQuery,
  Types.AgendamentosDiaLotacaoQueryVariables
>
export const ConfiguracaoHorariosAgendaLotacaoDocument = gql`
  query ConfiguracaoHorariosAgendaLotacao($lotacaoId: ID!, $isAtencaoDomiciliar: Boolean!, $isAgendaAtiva: Boolean!) {
    lotacao(id: $lotacaoId) {
      id
      configuracaoAgenda {
        duracaoAgendamento(isAtencaoDomiciliar: $isAtencaoDomiciliar)
        configuracaoHorarioAgenda @include(if: $isAgendaAtiva) {
          configuracaoDiasSemana {
            diaSemana
            periodos {
              horarioInicial
              horarioFinal
            }
          }
        }
      }
      gradeConfiguracaoAgendaOnline @include(if: $isAgendaAtiva) {
        configuracoesSemana {
          diaSemana
          configuracoes {
            horario
            ativo
          }
        }
        diasLiberacaoReserva
      }
    }
    conexao @include(if: $isAgendaAtiva) {
      agendaOnline {
        ativa
      }
    }
  }
`

/**
 * __useConfiguracaoHorariosAgendaLotacaoQuery__
 *
 * To run a query within a React component, call `useConfiguracaoHorariosAgendaLotacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoHorariosAgendaLotacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoHorariosAgendaLotacaoQuery({
 *   variables: {
 *      lotacaoId: // value for 'lotacaoId'
 *      isAtencaoDomiciliar: // value for 'isAtencaoDomiciliar'
 *      isAgendaAtiva: // value for 'isAgendaAtiva'
 *   },
 * });
 */
export function useConfiguracaoHorariosAgendaLotacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoHorariosAgendaLotacaoQuery,
    Types.ConfiguracaoHorariosAgendaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ConfiguracaoHorariosAgendaLotacaoQuery,
    Types.ConfiguracaoHorariosAgendaLotacaoQueryVariables
  >(ConfiguracaoHorariosAgendaLotacaoDocument, baseOptions)
}
export function useConfiguracaoHorariosAgendaLotacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoHorariosAgendaLotacaoQuery,
    Types.ConfiguracaoHorariosAgendaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoHorariosAgendaLotacaoQuery,
    Types.ConfiguracaoHorariosAgendaLotacaoQueryVariables
  >(ConfiguracaoHorariosAgendaLotacaoDocument, baseOptions)
}
export type ConfiguracaoHorariosAgendaLotacaoQueryHookResult = ReturnType<
  typeof useConfiguracaoHorariosAgendaLotacaoQuery
>
export type ConfiguracaoHorariosAgendaLotacaoLazyQueryHookResult = ReturnType<
  typeof useConfiguracaoHorariosAgendaLotacaoLazyQuery
>
export type ConfiguracaoHorariosAgendaLotacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoHorariosAgendaLotacaoQuery,
  Types.ConfiguracaoHorariosAgendaLotacaoQueryVariables
>
export const FechamentoAgendaDiaDocument = gql`
  query FechamentoAgendaDia($input: FechamentoAgendaDiaQueryInput!) {
    fechamentoDia(input: $input) {
      id
      dataInicial
      dataFinal
      motivo
      especifique
    }
  }
`

/**
 * __useFechamentoAgendaDiaQuery__
 *
 * To run a query within a React component, call `useFechamentoAgendaDiaQuery` and pass it any options that fit your needs.
 * When your component renders, `useFechamentoAgendaDiaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFechamentoAgendaDiaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFechamentoAgendaDiaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.FechamentoAgendaDiaQuery,
    Types.FechamentoAgendaDiaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.FechamentoAgendaDiaQuery, Types.FechamentoAgendaDiaQueryVariables>(
    FechamentoAgendaDiaDocument,
    baseOptions
  )
}
export function useFechamentoAgendaDiaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.FechamentoAgendaDiaQuery,
    Types.FechamentoAgendaDiaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.FechamentoAgendaDiaQuery, Types.FechamentoAgendaDiaQueryVariables>(
    FechamentoAgendaDiaDocument,
    baseOptions
  )
}
export type FechamentoAgendaDiaQueryHookResult = ReturnType<typeof useFechamentoAgendaDiaQuery>
export type FechamentoAgendaDiaLazyQueryHookResult = ReturnType<typeof useFechamentoAgendaDiaLazyQuery>
export type FechamentoAgendaDiaQueryResult = ApolloReactCommon.QueryResult<
  Types.FechamentoAgendaDiaQuery,
  Types.FechamentoAgendaDiaQueryVariables
>
export const AgendaAuthorizationDocument = gql`
  query AgendaAuthorization($equipeId: ID!, $isAd: Boolean!) {
    equipe(id: $equipeId) {
      id
      emapsApoio @include(if: $isAd) {
        id
      }
      equipesVinculadas {
        id
      }
    }
  }
`

/**
 * __useAgendaAuthorizationQuery__
 *
 * To run a query within a React component, call `useAgendaAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendaAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendaAuthorizationQuery({
 *   variables: {
 *      equipeId: // value for 'equipeId'
 *      isAd: // value for 'isAd'
 *   },
 * });
 */
export function useAgendaAuthorizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AgendaAuthorizationQuery,
    Types.AgendaAuthorizationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AgendaAuthorizationQuery, Types.AgendaAuthorizationQueryVariables>(
    AgendaAuthorizationDocument,
    baseOptions
  )
}
export function useAgendaAuthorizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AgendaAuthorizationQuery,
    Types.AgendaAuthorizationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AgendaAuthorizationQuery, Types.AgendaAuthorizationQueryVariables>(
    AgendaAuthorizationDocument,
    baseOptions
  )
}
export type AgendaAuthorizationQueryHookResult = ReturnType<typeof useAgendaAuthorizationQuery>
export type AgendaAuthorizationLazyQueryHookResult = ReturnType<typeof useAgendaAuthorizationLazyQuery>
export type AgendaAuthorizationQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendaAuthorizationQuery,
  Types.AgendaAuthorizationQueryVariables
>
export const AgendamentosConflitantesDocument = gql`
  query AgendamentosConflitantes($input: AgendamentoConflitanteInput!) {
    nomesOutrosProfissionaisAgendamentosConflitantes(input: $input) {
      nomesProfissionais
      horarioAgendamentoConflitante
    }
    possuiAgendamentoMesmoProfissionalDataFutura(input: $input) {
      profissionalId
      dataFutura
    }
  }
`

/**
 * __useAgendamentosConflitantesQuery__
 *
 * To run a query within a React component, call `useAgendamentosConflitantesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendamentosConflitantesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendamentosConflitantesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgendamentosConflitantesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AgendamentosConflitantesQuery,
    Types.AgendamentosConflitantesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AgendamentosConflitantesQuery, Types.AgendamentosConflitantesQueryVariables>(
    AgendamentosConflitantesDocument,
    baseOptions
  )
}
export function useAgendamentosConflitantesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AgendamentosConflitantesQuery,
    Types.AgendamentosConflitantesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AgendamentosConflitantesQuery,
    Types.AgendamentosConflitantesQueryVariables
  >(AgendamentosConflitantesDocument, baseOptions)
}
export type AgendamentosConflitantesQueryHookResult = ReturnType<typeof useAgendamentosConflitantesQuery>
export type AgendamentosConflitantesLazyQueryHookResult = ReturnType<typeof useAgendamentosConflitantesLazyQuery>
export type AgendamentosConflitantesQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendamentosConflitantesQuery,
  Types.AgendamentosConflitantesQueryVariables
>
export const CidadaoCadastroAgendamentoDocument = gql`
  query CidadaoCadastroAgendamento($id: ID!) {
    cidadao(id: $id) {
      id
      nome
      nomeSocial
      cpf
      cns
      nomeMae
      dataNascimento
      sexo
      identidadeGeneroDbEnum
      telefoneCelular
      email
    }
  }
`

/**
 * __useCidadaoCadastroAgendamentoQuery__
 *
 * To run a query within a React component, call `useCidadaoCadastroAgendamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoCadastroAgendamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoCadastroAgendamentoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCidadaoCadastroAgendamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoCadastroAgendamentoQuery,
    Types.CidadaoCadastroAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoCadastroAgendamentoQuery,
    Types.CidadaoCadastroAgendamentoQueryVariables
  >(CidadaoCadastroAgendamentoDocument, baseOptions)
}
export function useCidadaoCadastroAgendamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoCadastroAgendamentoQuery,
    Types.CidadaoCadastroAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoCadastroAgendamentoQuery,
    Types.CidadaoCadastroAgendamentoQueryVariables
  >(CidadaoCadastroAgendamentoDocument, baseOptions)
}
export type CidadaoCadastroAgendamentoQueryHookResult = ReturnType<typeof useCidadaoCadastroAgendamentoQuery>
export type CidadaoCadastroAgendamentoLazyQueryHookResult = ReturnType<typeof useCidadaoCadastroAgendamentoLazyQuery>
export type CidadaoCadastroAgendamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoCadastroAgendamentoQuery,
  Types.CidadaoCadastroAgendamentoQueryVariables
>
export const EditarAgendadoDocument = gql`
  query EditarAgendado($id: ID!) {
    agendado(id: $id) {
      id
      horarioInicial
      horarioFinal
      lotacaoAgendada {
        ...LotacaoAssociadaAgendamento
      }
      motivoReserva
      outroMotivoReserva
      observacao
      localAtendimento {
        id
        localAtendimentoExibicao
      }
      cidadao {
        id
        nome
        nomeSocial
        contato {
          id
          email
          telefoneCelular
        }
      }
      agendamentoParticipante {
        id
        lotacaoAgendada {
          ...LotacaoAgendaSelect
        }
      }
      videochamada {
        id
        uuid
      }
    }
  }
  ${LotacaoAssociadaAgendamentoFragmentDoc}
  ${LotacaoAgendaSelectFragmentDoc}
`

/**
 * __useEditarAgendadoQuery__
 *
 * To run a query within a React component, call `useEditarAgendadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditarAgendadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditarAgendadoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditarAgendadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EditarAgendadoQuery, Types.EditarAgendadoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EditarAgendadoQuery, Types.EditarAgendadoQueryVariables>(
    EditarAgendadoDocument,
    baseOptions
  )
}
export function useEditarAgendadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EditarAgendadoQuery, Types.EditarAgendadoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EditarAgendadoQuery, Types.EditarAgendadoQueryVariables>(
    EditarAgendadoDocument,
    baseOptions
  )
}
export type EditarAgendadoQueryHookResult = ReturnType<typeof useEditarAgendadoQuery>
export type EditarAgendadoLazyQueryHookResult = ReturnType<typeof useEditarAgendadoLazyQuery>
export type EditarAgendadoQueryResult = ApolloReactCommon.QueryResult<
  Types.EditarAgendadoQuery,
  Types.EditarAgendadoQueryVariables
>
export const EditarAgendamentoConsultaCompartilhadaDocument = gql`
  mutation EditarAgendamentoConsultaCompartilhada($input: EditarAgendamentoConsultaCompartilhadaInput!) {
    editarAgendamentoConsultaCompartilhada(input: $input)
  }
`
export type EditarAgendamentoConsultaCompartilhadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.EditarAgendamentoConsultaCompartilhadaMutation,
  Types.EditarAgendamentoConsultaCompartilhadaMutationVariables
>

/**
 * __useEditarAgendamentoConsultaCompartilhadaMutation__
 *
 * To run a mutation, you first call `useEditarAgendamentoConsultaCompartilhadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarAgendamentoConsultaCompartilhadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarAgendamentoConsultaCompartilhadaMutation, { data, loading, error }] = useEditarAgendamentoConsultaCompartilhadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditarAgendamentoConsultaCompartilhadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EditarAgendamentoConsultaCompartilhadaMutation,
    Types.EditarAgendamentoConsultaCompartilhadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.EditarAgendamentoConsultaCompartilhadaMutation,
    Types.EditarAgendamentoConsultaCompartilhadaMutationVariables
  >(EditarAgendamentoConsultaCompartilhadaDocument, baseOptions)
}
export type EditarAgendamentoConsultaCompartilhadaMutationHookResult = ReturnType<
  typeof useEditarAgendamentoConsultaCompartilhadaMutation
>
export type EditarAgendamentoConsultaCompartilhadaMutationResult = ApolloReactCommon.MutationResult<
  Types.EditarAgendamentoConsultaCompartilhadaMutation
>
export type EditarAgendamentoConsultaCompartilhadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EditarAgendamentoConsultaCompartilhadaMutation,
  Types.EditarAgendamentoConsultaCompartilhadaMutationVariables
>
export const EditarAgendamentoConsultaDocument = gql`
  mutation EditarAgendamentoConsulta($input: EditarAgendamentoConsultaInput!) {
    editarAgendamentoConsulta(input: $input)
  }
`
export type EditarAgendamentoConsultaMutationFn = ApolloReactCommon.MutationFunction<
  Types.EditarAgendamentoConsultaMutation,
  Types.EditarAgendamentoConsultaMutationVariables
>

/**
 * __useEditarAgendamentoConsultaMutation__
 *
 * To run a mutation, you first call `useEditarAgendamentoConsultaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarAgendamentoConsultaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarAgendamentoConsultaMutation, { data, loading, error }] = useEditarAgendamentoConsultaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditarAgendamentoConsultaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EditarAgendamentoConsultaMutation,
    Types.EditarAgendamentoConsultaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.EditarAgendamentoConsultaMutation,
    Types.EditarAgendamentoConsultaMutationVariables
  >(EditarAgendamentoConsultaDocument, baseOptions)
}
export type EditarAgendamentoConsultaMutationHookResult = ReturnType<typeof useEditarAgendamentoConsultaMutation>
export type EditarAgendamentoConsultaMutationResult = ApolloReactCommon.MutationResult<
  Types.EditarAgendamentoConsultaMutation
>
export type EditarAgendamentoConsultaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EditarAgendamentoConsultaMutation,
  Types.EditarAgendamentoConsultaMutationVariables
>
export const EditarAgendamentoReservaDocument = gql`
  mutation EditarAgendamentoReserva($input: EditarAgendamentoReservaInput!) {
    editarAgendamentoReserva(input: $input)
  }
`
export type EditarAgendamentoReservaMutationFn = ApolloReactCommon.MutationFunction<
  Types.EditarAgendamentoReservaMutation,
  Types.EditarAgendamentoReservaMutationVariables
>

/**
 * __useEditarAgendamentoReservaMutation__
 *
 * To run a mutation, you first call `useEditarAgendamentoReservaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarAgendamentoReservaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarAgendamentoReservaMutation, { data, loading, error }] = useEditarAgendamentoReservaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditarAgendamentoReservaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EditarAgendamentoReservaMutation,
    Types.EditarAgendamentoReservaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.EditarAgendamentoReservaMutation,
    Types.EditarAgendamentoReservaMutationVariables
  >(EditarAgendamentoReservaDocument, baseOptions)
}
export type EditarAgendamentoReservaMutationHookResult = ReturnType<typeof useEditarAgendamentoReservaMutation>
export type EditarAgendamentoReservaMutationResult = ApolloReactCommon.MutationResult<
  Types.EditarAgendamentoReservaMutation
>
export type EditarAgendamentoReservaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EditarAgendamentoReservaMutation,
  Types.EditarAgendamentoReservaMutationVariables
>
export const SalvarAgendamentoConsultaCompartilhadaDocument = gql`
  mutation SalvarAgendamentoConsultaCompartilhada($input: AgendamentoConsultaCompartilhadaInput!) {
    salvarAgendamentoConsultaCompartilhada(input: $input)
  }
`
export type SalvarAgendamentoConsultaCompartilhadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAgendamentoConsultaCompartilhadaMutation,
  Types.SalvarAgendamentoConsultaCompartilhadaMutationVariables
>

/**
 * __useSalvarAgendamentoConsultaCompartilhadaMutation__
 *
 * To run a mutation, you first call `useSalvarAgendamentoConsultaCompartilhadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAgendamentoConsultaCompartilhadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAgendamentoConsultaCompartilhadaMutation, { data, loading, error }] = useSalvarAgendamentoConsultaCompartilhadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAgendamentoConsultaCompartilhadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAgendamentoConsultaCompartilhadaMutation,
    Types.SalvarAgendamentoConsultaCompartilhadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarAgendamentoConsultaCompartilhadaMutation,
    Types.SalvarAgendamentoConsultaCompartilhadaMutationVariables
  >(SalvarAgendamentoConsultaCompartilhadaDocument, baseOptions)
}
export type SalvarAgendamentoConsultaCompartilhadaMutationHookResult = ReturnType<
  typeof useSalvarAgendamentoConsultaCompartilhadaMutation
>
export type SalvarAgendamentoConsultaCompartilhadaMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarAgendamentoConsultaCompartilhadaMutation
>
export type SalvarAgendamentoConsultaCompartilhadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAgendamentoConsultaCompartilhadaMutation,
  Types.SalvarAgendamentoConsultaCompartilhadaMutationVariables
>
export const SalvarAgendamentoConsultaDocument = gql`
  mutation SalvarAgendamentoConsulta($input: AgendamentoConsultaInput!) {
    salvarAgendamentoConsulta(input: $input)
  }
`
export type SalvarAgendamentoConsultaMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAgendamentoConsultaMutation,
  Types.SalvarAgendamentoConsultaMutationVariables
>

/**
 * __useSalvarAgendamentoConsultaMutation__
 *
 * To run a mutation, you first call `useSalvarAgendamentoConsultaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAgendamentoConsultaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAgendamentoConsultaMutation, { data, loading, error }] = useSalvarAgendamentoConsultaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAgendamentoConsultaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAgendamentoConsultaMutation,
    Types.SalvarAgendamentoConsultaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarAgendamentoConsultaMutation,
    Types.SalvarAgendamentoConsultaMutationVariables
  >(SalvarAgendamentoConsultaDocument, baseOptions)
}
export type SalvarAgendamentoConsultaMutationHookResult = ReturnType<typeof useSalvarAgendamentoConsultaMutation>
export type SalvarAgendamentoConsultaMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarAgendamentoConsultaMutation
>
export type SalvarAgendamentoConsultaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAgendamentoConsultaMutation,
  Types.SalvarAgendamentoConsultaMutationVariables
>
export const SalvarAgendamentoReservaDocument = gql`
  mutation SalvarAgendamentoReserva($input: AgendamentoReservaInput!) {
    salvarAgendamentoReserva(input: $input)
  }
`
export type SalvarAgendamentoReservaMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAgendamentoReservaMutation,
  Types.SalvarAgendamentoReservaMutationVariables
>

/**
 * __useSalvarAgendamentoReservaMutation__
 *
 * To run a mutation, you first call `useSalvarAgendamentoReservaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAgendamentoReservaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAgendamentoReservaMutation, { data, loading, error }] = useSalvarAgendamentoReservaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAgendamentoReservaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAgendamentoReservaMutation,
    Types.SalvarAgendamentoReservaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarAgendamentoReservaMutation,
    Types.SalvarAgendamentoReservaMutationVariables
  >(SalvarAgendamentoReservaDocument, baseOptions)
}
export type SalvarAgendamentoReservaMutationHookResult = ReturnType<typeof useSalvarAgendamentoReservaMutation>
export type SalvarAgendamentoReservaMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarAgendamentoReservaMutation
>
export type SalvarAgendamentoReservaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAgendamentoReservaMutation,
  Types.SalvarAgendamentoReservaMutationVariables
>
export const AgendamentosCidadaoPagedDocument = gql`
  query AgendamentosCidadaoPaged($input: AgendadosQueryInput!) {
    agendados(input: $input) {
      content {
        ...Agendamento
        lotacaoAgendada {
          ...LotacaoAssociadaAgendamento
          unidadeSaude {
            id
            nome
          }
        }
        responsavelCriador {
          ... on Lotacao {
            ...LotacaoAssociadaAgendamento
          }
          ... on Estagio {
            ...EstagioAssociadoAgendamento
          }
        }
        justificativaCancelamento {
          id
          justificativa
          data
          responsavel {
            ... on Lotacao {
              ...LotacaoAssociadaAgendamento
            }
            ... on Estagio {
              ...EstagioAssociadoAgendamento
            }
          }
        }
        profissionalAnfitriao {
          id
          nome
          cbo {
            id
            nome
            cbo2002
          }
          equipe {
            id
            ine
            nome
          }
        }
        profissionalParticipante {
          id
          nome
          lotacao {
            id
            cbo {
              id
              cbo2002
              nome
            }
            equipe {
              id
              ine
              nome
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    conexao {
      agendaOnline {
        ativa
      }
    }
  }
  ${AgendamentoFragmentDoc}
  ${LotacaoAssociadaAgendamentoFragmentDoc}
  ${EstagioAssociadoAgendamentoFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useAgendamentosCidadaoPagedQuery__
 *
 * To run a query within a React component, call `useAgendamentosCidadaoPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendamentosCidadaoPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendamentosCidadaoPagedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgendamentosCidadaoPagedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AgendamentosCidadaoPagedQuery,
    Types.AgendamentosCidadaoPagedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AgendamentosCidadaoPagedQuery, Types.AgendamentosCidadaoPagedQueryVariables>(
    AgendamentosCidadaoPagedDocument,
    baseOptions
  )
}
export function useAgendamentosCidadaoPagedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AgendamentosCidadaoPagedQuery,
    Types.AgendamentosCidadaoPagedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AgendamentosCidadaoPagedQuery,
    Types.AgendamentosCidadaoPagedQueryVariables
  >(AgendamentosCidadaoPagedDocument, baseOptions)
}
export type AgendamentosCidadaoPagedQueryHookResult = ReturnType<typeof useAgendamentosCidadaoPagedQuery>
export type AgendamentosCidadaoPagedLazyQueryHookResult = ReturnType<typeof useAgendamentosCidadaoPagedLazyQuery>
export type AgendamentosCidadaoPagedQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendamentosCidadaoPagedQuery,
  Types.AgendamentosCidadaoPagedQueryVariables
>
export const LotacaoConfiguracaoAgendaDocument = gql`
  query LotacaoConfiguracaoAgenda($id: ID!) {
    hasConfiguracaoAgenda(idLotacao: $id)
  }
`

/**
 * __useLotacaoConfiguracaoAgendaQuery__
 *
 * To run a query within a React component, call `useLotacaoConfiguracaoAgendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoConfiguracaoAgendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoConfiguracaoAgendaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLotacaoConfiguracaoAgendaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LotacaoConfiguracaoAgendaQuery,
    Types.LotacaoConfiguracaoAgendaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LotacaoConfiguracaoAgendaQuery, Types.LotacaoConfiguracaoAgendaQueryVariables>(
    LotacaoConfiguracaoAgendaDocument,
    baseOptions
  )
}
export function useLotacaoConfiguracaoAgendaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LotacaoConfiguracaoAgendaQuery,
    Types.LotacaoConfiguracaoAgendaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LotacaoConfiguracaoAgendaQuery,
    Types.LotacaoConfiguracaoAgendaQueryVariables
  >(LotacaoConfiguracaoAgendaDocument, baseOptions)
}
export type LotacaoConfiguracaoAgendaQueryHookResult = ReturnType<typeof useLotacaoConfiguracaoAgendaQuery>
export type LotacaoConfiguracaoAgendaLazyQueryHookResult = ReturnType<typeof useLotacaoConfiguracaoAgendaLazyQuery>
export type LotacaoConfiguracaoAgendaQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoConfiguracaoAgendaQuery,
  Types.LotacaoConfiguracaoAgendaQueryVariables
>
export const CancelarAgendamentoDocument = gql`
  mutation CancelarAgendamento($input: CancelarAgendamentoInput!) {
    cancelarAgendamento(input: $input)
  }
`
export type CancelarAgendamentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.CancelarAgendamentoMutation,
  Types.CancelarAgendamentoMutationVariables
>

/**
 * __useCancelarAgendamentoMutation__
 *
 * To run a mutation, you first call `useCancelarAgendamentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelarAgendamentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelarAgendamentoMutation, { data, loading, error }] = useCancelarAgendamentoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelarAgendamentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CancelarAgendamentoMutation,
    Types.CancelarAgendamentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.CancelarAgendamentoMutation, Types.CancelarAgendamentoMutationVariables>(
    CancelarAgendamentoDocument,
    baseOptions
  )
}
export type CancelarAgendamentoMutationHookResult = ReturnType<typeof useCancelarAgendamentoMutation>
export type CancelarAgendamentoMutationResult = ApolloReactCommon.MutationResult<Types.CancelarAgendamentoMutation>
export type CancelarAgendamentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CancelarAgendamentoMutation,
  Types.CancelarAgendamentoMutationVariables
>
export const DataCriacaoAgendadoDocument = gql`
  query DataCriacaoAgendado($id: ID!) {
    agendado(id: $id) {
      id
      dataCriacao
    }
  }
`

/**
 * __useDataCriacaoAgendadoQuery__
 *
 * To run a query within a React component, call `useDataCriacaoAgendadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataCriacaoAgendadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataCriacaoAgendadoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataCriacaoAgendadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DataCriacaoAgendadoQuery,
    Types.DataCriacaoAgendadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.DataCriacaoAgendadoQuery, Types.DataCriacaoAgendadoQueryVariables>(
    DataCriacaoAgendadoDocument,
    baseOptions
  )
}
export function useDataCriacaoAgendadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DataCriacaoAgendadoQuery,
    Types.DataCriacaoAgendadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.DataCriacaoAgendadoQuery, Types.DataCriacaoAgendadoQueryVariables>(
    DataCriacaoAgendadoDocument,
    baseOptions
  )
}
export type DataCriacaoAgendadoQueryHookResult = ReturnType<typeof useDataCriacaoAgendadoQuery>
export type DataCriacaoAgendadoLazyQueryHookResult = ReturnType<typeof useDataCriacaoAgendadoLazyQuery>
export type DataCriacaoAgendadoQueryResult = ApolloReactCommon.QueryResult<
  Types.DataCriacaoAgendadoQuery,
  Types.DataCriacaoAgendadoQueryVariables
>
export const InformarFaltaCidadaoDocument = gql`
  mutation InformarFaltaCidadao($agendamentoId: ID!) {
    informarFaltaCidadao(agendamentoId: $agendamentoId)
  }
`
export type InformarFaltaCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.InformarFaltaCidadaoMutation,
  Types.InformarFaltaCidadaoMutationVariables
>

/**
 * __useInformarFaltaCidadaoMutation__
 *
 * To run a mutation, you first call `useInformarFaltaCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInformarFaltaCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [informarFaltaCidadaoMutation, { data, loading, error }] = useInformarFaltaCidadaoMutation({
 *   variables: {
 *      agendamentoId: // value for 'agendamentoId'
 *   },
 * });
 */
export function useInformarFaltaCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InformarFaltaCidadaoMutation,
    Types.InformarFaltaCidadaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.InformarFaltaCidadaoMutation, Types.InformarFaltaCidadaoMutationVariables>(
    InformarFaltaCidadaoDocument,
    baseOptions
  )
}
export type InformarFaltaCidadaoMutationHookResult = ReturnType<typeof useInformarFaltaCidadaoMutation>
export type InformarFaltaCidadaoMutationResult = ApolloReactCommon.MutationResult<Types.InformarFaltaCidadaoMutation>
export type InformarFaltaCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InformarFaltaCidadaoMutation,
  Types.InformarFaltaCidadaoMutationVariables
>
export const InserirListaAtendimentoDocument = gql`
  mutation InserirListaAtendimento($input: AtendimentoInput!) {
    salvarAtendimento(input: $input) {
      id
    }
  }
`
export type InserirListaAtendimentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.InserirListaAtendimentoMutation,
  Types.InserirListaAtendimentoMutationVariables
>

/**
 * __useInserirListaAtendimentoMutation__
 *
 * To run a mutation, you first call `useInserirListaAtendimentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInserirListaAtendimentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inserirListaAtendimentoMutation, { data, loading, error }] = useInserirListaAtendimentoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInserirListaAtendimentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InserirListaAtendimentoMutation,
    Types.InserirListaAtendimentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.InserirListaAtendimentoMutation,
    Types.InserirListaAtendimentoMutationVariables
  >(InserirListaAtendimentoDocument, baseOptions)
}
export type InserirListaAtendimentoMutationHookResult = ReturnType<typeof useInserirListaAtendimentoMutation>
export type InserirListaAtendimentoMutationResult = ApolloReactCommon.MutationResult<
  Types.InserirListaAtendimentoMutation
>
export type InserirListaAtendimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InserirListaAtendimentoMutation,
  Types.InserirListaAtendimentoMutationVariables
>
export const RemoverFaltaCidadaoMutationDocument = gql`
  mutation RemoverFaltaCidadaoMutation($agendamentoId: ID!) {
    removerFaltaCidadao(agendamentoId: $agendamentoId)
  }
`
export type RemoverFaltaCidadaoMutationMutationFn = ApolloReactCommon.MutationFunction<
  Types.RemoverFaltaCidadaoMutationMutation,
  Types.RemoverFaltaCidadaoMutationMutationVariables
>

/**
 * __useRemoverFaltaCidadaoMutationMutation__
 *
 * To run a mutation, you first call `useRemoverFaltaCidadaoMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverFaltaCidadaoMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerFaltaCidadaoMutationMutation, { data, loading, error }] = useRemoverFaltaCidadaoMutationMutation({
 *   variables: {
 *      agendamentoId: // value for 'agendamentoId'
 *   },
 * });
 */
export function useRemoverFaltaCidadaoMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RemoverFaltaCidadaoMutationMutation,
    Types.RemoverFaltaCidadaoMutationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RemoverFaltaCidadaoMutationMutation,
    Types.RemoverFaltaCidadaoMutationMutationVariables
  >(RemoverFaltaCidadaoMutationDocument, baseOptions)
}
export type RemoverFaltaCidadaoMutationMutationHookResult = ReturnType<typeof useRemoverFaltaCidadaoMutationMutation>
export type RemoverFaltaCidadaoMutationMutationResult = ApolloReactCommon.MutationResult<
  Types.RemoverFaltaCidadaoMutationMutation
>
export type RemoverFaltaCidadaoMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RemoverFaltaCidadaoMutationMutation,
  Types.RemoverFaltaCidadaoMutationMutationVariables
>
export const RemoverListaAtendimentoDocument = gql`
  mutation RemoverListaAtendimento($atendimentoId: ID!) {
    excluirAtendimento(atendimento: $atendimentoId)
  }
`
export type RemoverListaAtendimentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.RemoverListaAtendimentoMutation,
  Types.RemoverListaAtendimentoMutationVariables
>

/**
 * __useRemoverListaAtendimentoMutation__
 *
 * To run a mutation, you first call `useRemoverListaAtendimentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverListaAtendimentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerListaAtendimentoMutation, { data, loading, error }] = useRemoverListaAtendimentoMutation({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useRemoverListaAtendimentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RemoverListaAtendimentoMutation,
    Types.RemoverListaAtendimentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RemoverListaAtendimentoMutation,
    Types.RemoverListaAtendimentoMutationVariables
  >(RemoverListaAtendimentoDocument, baseOptions)
}
export type RemoverListaAtendimentoMutationHookResult = ReturnType<typeof useRemoverListaAtendimentoMutation>
export type RemoverListaAtendimentoMutationResult = ApolloReactCommon.MutationResult<
  Types.RemoverListaAtendimentoMutation
>
export type RemoverListaAtendimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RemoverListaAtendimentoMutation,
  Types.RemoverListaAtendimentoMutationVariables
>
export const MesAgendaDocument = gql`
  query MesAgenda($input: MesAgendaQueryInput!) {
    mesAgenda(input: $input) {
      id
      dias {
        data
        status
      }
    }
  }
`

/**
 * __useMesAgendaQuery__
 *
 * To run a query within a React component, call `useMesAgendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMesAgendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMesAgendaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMesAgendaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MesAgendaQuery, Types.MesAgendaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.MesAgendaQuery, Types.MesAgendaQueryVariables>(MesAgendaDocument, baseOptions)
}
export function useMesAgendaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MesAgendaQuery, Types.MesAgendaQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.MesAgendaQuery, Types.MesAgendaQueryVariables>(
    MesAgendaDocument,
    baseOptions
  )
}
export type MesAgendaQueryHookResult = ReturnType<typeof useMesAgendaQuery>
export type MesAgendaLazyQueryHookResult = ReturnType<typeof useMesAgendaLazyQuery>
export type MesAgendaQueryResult = ApolloReactCommon.QueryResult<Types.MesAgendaQuery, Types.MesAgendaQueryVariables>
export const LotacaoAgendaActionsDocument = gql`
  query LotacaoAgendaActions($id: ID!) {
    lotacao(id: $id) {
      id
      actions {
        permissaoConfigurarAgenda {
          enabled
          hint
        }
        permissaoConfigurarAgendaOnline {
          enabled
          hint
        }
        permissaoFecharAgenda {
          enabled
          hint
        }
      }
    }
  }
`

/**
 * __useLotacaoAgendaActionsQuery__
 *
 * To run a query within a React component, call `useLotacaoAgendaActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoAgendaActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoAgendaActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLotacaoAgendaActionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LotacaoAgendaActionsQuery,
    Types.LotacaoAgendaActionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LotacaoAgendaActionsQuery, Types.LotacaoAgendaActionsQueryVariables>(
    LotacaoAgendaActionsDocument,
    baseOptions
  )
}
export function useLotacaoAgendaActionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LotacaoAgendaActionsQuery,
    Types.LotacaoAgendaActionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.LotacaoAgendaActionsQuery, Types.LotacaoAgendaActionsQueryVariables>(
    LotacaoAgendaActionsDocument,
    baseOptions
  )
}
export type LotacaoAgendaActionsQueryHookResult = ReturnType<typeof useLotacaoAgendaActionsQuery>
export type LotacaoAgendaActionsLazyQueryHookResult = ReturnType<typeof useLotacaoAgendaActionsLazyQuery>
export type LotacaoAgendaActionsQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoAgendaActionsQuery,
  Types.LotacaoAgendaActionsQueryVariables
>
export const DetalhesAgendamentoDocument = gql`
  query DetalhesAgendamento($id: ID!) {
    agendado(id: $id) {
      id
      agendamentoPrincipal {
        id
        cidadao {
          id
          nome
          nomeSocial
          cpf
          cns
          dataNascimento
          contato {
            id
            telefoneResidencial
            telefoneCelular
            telefoneContato
          }
        }
      }
      cidadao {
        id
        nome
        nomeSocial
        cpf
        cns
        dataNascimento
        contato {
          id
          telefoneResidencial
          telefoneCelular
          telefoneContato
        }
      }
      profissionalParticipante {
        id
        nome
        email
        lotacao {
          id
          equipe {
            id
            ine
            nome
          }
          cbo {
            id
            nome
          }
        }
      }
      profissionalAnfitriao {
        id
        nome
        email
        equipe {
          id
          ine
          nome
        }
        cbo {
          id
          nome
        }
      }
      videochamada {
        id
        uuid
      }
      dataCriacao
      responsavelCriador {
        ... on Lotacao {
          ...LotacaoAssociadaAgendamento
        }
        ... on Estagio {
          ...EstagioAssociadoAgendamento
        }
      }
      lotacaoAgendada {
        ...LotacaoAssociadaAgendamento
      }
    }
  }
  ${LotacaoAssociadaAgendamentoFragmentDoc}
  ${EstagioAssociadoAgendamentoFragmentDoc}
`

/**
 * __useDetalhesAgendamentoQuery__
 *
 * To run a query within a React component, call `useDetalhesAgendamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetalhesAgendamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetalhesAgendamentoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetalhesAgendamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DetalhesAgendamentoQuery,
    Types.DetalhesAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.DetalhesAgendamentoQuery, Types.DetalhesAgendamentoQueryVariables>(
    DetalhesAgendamentoDocument,
    baseOptions
  )
}
export function useDetalhesAgendamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DetalhesAgendamentoQuery,
    Types.DetalhesAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.DetalhesAgendamentoQuery, Types.DetalhesAgendamentoQueryVariables>(
    DetalhesAgendamentoDocument,
    baseOptions
  )
}
export type DetalhesAgendamentoQueryHookResult = ReturnType<typeof useDetalhesAgendamentoQuery>
export type DetalhesAgendamentoLazyQueryHookResult = ReturnType<typeof useDetalhesAgendamentoLazyQuery>
export type DetalhesAgendamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.DetalhesAgendamentoQuery,
  Types.DetalhesAgendamentoQueryVariables
>
export const AlterarSenhaDocument = gql`
  mutation AlterarSenha($input: AlterarSenhaAtualInput!) {
    alterarSenhaAtual(input: $input) {
      id
      forcarTrocaSenha
    }
  }
`
export type AlterarSenhaMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarSenhaMutation,
  Types.AlterarSenhaMutationVariables
>

/**
 * __useAlterarSenhaMutation__
 *
 * To run a mutation, you first call `useAlterarSenhaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarSenhaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarSenhaMutation, { data, loading, error }] = useAlterarSenhaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarSenhaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlterarSenhaMutation, Types.AlterarSenhaMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlterarSenhaMutation, Types.AlterarSenhaMutationVariables>(
    AlterarSenhaDocument,
    baseOptions
  )
}
export type AlterarSenhaMutationHookResult = ReturnType<typeof useAlterarSenhaMutation>
export type AlterarSenhaMutationResult = ApolloReactCommon.MutationResult<Types.AlterarSenhaMutation>
export type AlterarSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarSenhaMutation,
  Types.AlterarSenhaMutationVariables
>
export const UltimoArquivoProntoEmProcessamentoDocument = gql`
  query UltimoArquivoProntoEmProcessamento($input: ArquivoQueryInput!) {
    ultimoArquivoProntoEmProcessamento(input: $input) {
      id
      dataInicioGerado
      status
      identificador
      formato
    }
  }
`

/**
 * __useUltimoArquivoProntoEmProcessamentoQuery__
 *
 * To run a query within a React component, call `useUltimoArquivoProntoEmProcessamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUltimoArquivoProntoEmProcessamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUltimoArquivoProntoEmProcessamentoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUltimoArquivoProntoEmProcessamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UltimoArquivoProntoEmProcessamentoQuery,
    Types.UltimoArquivoProntoEmProcessamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UltimoArquivoProntoEmProcessamentoQuery,
    Types.UltimoArquivoProntoEmProcessamentoQueryVariables
  >(UltimoArquivoProntoEmProcessamentoDocument, baseOptions)
}
export function useUltimoArquivoProntoEmProcessamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UltimoArquivoProntoEmProcessamentoQuery,
    Types.UltimoArquivoProntoEmProcessamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UltimoArquivoProntoEmProcessamentoQuery,
    Types.UltimoArquivoProntoEmProcessamentoQueryVariables
  >(UltimoArquivoProntoEmProcessamentoDocument, baseOptions)
}
export type UltimoArquivoProntoEmProcessamentoQueryHookResult = ReturnType<
  typeof useUltimoArquivoProntoEmProcessamentoQuery
>
export type UltimoArquivoProntoEmProcessamentoLazyQueryHookResult = ReturnType<
  typeof useUltimoArquivoProntoEmProcessamentoLazyQuery
>
export type UltimoArquivoProntoEmProcessamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.UltimoArquivoProntoEmProcessamentoQuery,
  Types.UltimoArquivoProntoEmProcessamentoQueryVariables
>
export const UltimosCincoArquivosDocument = gql`
  query UltimosCincoArquivos($input: ArquivoIdentificadorEnum!) {
    ultimosCincoArquivos(arquivoIdentificador: $input) {
      id
      dataInicioGerado
      status
      identificador
      formato
    }
  }
`

/**
 * __useUltimosCincoArquivosQuery__
 *
 * To run a query within a React component, call `useUltimosCincoArquivosQuery` and pass it any options that fit your needs.
 * When your component renders, `useUltimosCincoArquivosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUltimosCincoArquivosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUltimosCincoArquivosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UltimosCincoArquivosQuery,
    Types.UltimosCincoArquivosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UltimosCincoArquivosQuery, Types.UltimosCincoArquivosQueryVariables>(
    UltimosCincoArquivosDocument,
    baseOptions
  )
}
export function useUltimosCincoArquivosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UltimosCincoArquivosQuery,
    Types.UltimosCincoArquivosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UltimosCincoArquivosQuery, Types.UltimosCincoArquivosQueryVariables>(
    UltimosCincoArquivosDocument,
    baseOptions
  )
}
export type UltimosCincoArquivosQueryHookResult = ReturnType<typeof useUltimosCincoArquivosQuery>
export type UltimosCincoArquivosLazyQueryHookResult = ReturnType<typeof useUltimosCincoArquivosLazyQuery>
export type UltimosCincoArquivosQueryResult = ApolloReactCommon.QueryResult<
  Types.UltimosCincoArquivosQuery,
  Types.UltimosCincoArquivosQueryVariables
>
export const AgendadoAtendimentoFormDocument = gql`
  query AgendadoAtendimentoForm($id: ID!) {
    agendado(id: $id) {
      id
      lotacaoAgendada {
        id
        cbo {
          id
          cbo2002
        }
        profissional {
          id
          nome
        }
        equipe {
          id
          nome
          ine
          tipoEquipe {
            id
            nome
            sigla
          }
        }
      }
    }
  }
`

/**
 * __useAgendadoAtendimentoFormQuery__
 *
 * To run a query within a React component, call `useAgendadoAtendimentoFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendadoAtendimentoFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendadoAtendimentoFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgendadoAtendimentoFormQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AgendadoAtendimentoFormQuery,
    Types.AgendadoAtendimentoFormQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AgendadoAtendimentoFormQuery, Types.AgendadoAtendimentoFormQueryVariables>(
    AgendadoAtendimentoFormDocument,
    baseOptions
  )
}
export function useAgendadoAtendimentoFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AgendadoAtendimentoFormQuery,
    Types.AgendadoAtendimentoFormQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AgendadoAtendimentoFormQuery, Types.AgendadoAtendimentoFormQueryVariables>(
    AgendadoAtendimentoFormDocument,
    baseOptions
  )
}
export type AgendadoAtendimentoFormQueryHookResult = ReturnType<typeof useAgendadoAtendimentoFormQuery>
export type AgendadoAtendimentoFormLazyQueryHookResult = ReturnType<typeof useAgendadoAtendimentoFormLazyQuery>
export type AgendadoAtendimentoFormQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendadoAtendimentoFormQuery,
  Types.AgendadoAtendimentoFormQueryVariables
>
export const AgendamentosDiaDocument = gql`
  query AgendamentosDia($input: AgendadosQueryInput!) {
    agendados(input: $input) {
      content {
        id
        horarioInicial
        lotacaoAgendada {
          id
          profissional {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
        profissionalParticipante {
          id
          nome
          lotacao {
            id
            cbo {
              id
              nome
            }
          }
        }
      }
    }
  }
`

/**
 * __useAgendamentosDiaQuery__
 *
 * To run a query within a React component, call `useAgendamentosDiaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendamentosDiaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendamentosDiaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgendamentosDiaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AgendamentosDiaQuery, Types.AgendamentosDiaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AgendamentosDiaQuery, Types.AgendamentosDiaQueryVariables>(
    AgendamentosDiaDocument,
    baseOptions
  )
}
export function useAgendamentosDiaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AgendamentosDiaQuery, Types.AgendamentosDiaQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AgendamentosDiaQuery, Types.AgendamentosDiaQueryVariables>(
    AgendamentosDiaDocument,
    baseOptions
  )
}
export type AgendamentosDiaQueryHookResult = ReturnType<typeof useAgendamentosDiaQuery>
export type AgendamentosDiaLazyQueryHookResult = ReturnType<typeof useAgendamentosDiaLazyQuery>
export type AgendamentosDiaQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendamentosDiaQuery,
  Types.AgendamentosDiaQueryVariables
>
export const AtendimentoIndividualViewDocument = gql`
  query AtendimentoIndividualView($atendimentoId: ID!) {
    atendimento(id: $atendimentoId) {
      id
      iniciadoEm
      isRegistroTardio
      statusRevisaoAtendimento
      agendado {
        id
        lotacaoAgendada {
          id
        }
        profissionalParticipante {
          id
          lotacao {
            id
            cbo {
              id
              nome
              cbo2002
            }
            unidadeSaude {
              id
              nome
              cnes
            }
            profissional {
              id
              nome
              cpf
            }
          }
        }
        profissionalAnfitriao {
          id
          lotacaoId
        }
        videochamada {
          id
        }
        profissionalAnfitriao {
          id
          lotacaoId
          nome
        }
      }
      tiposServico {
        id
      }
      cidadao {
        id
        cpf
        cns
        nome
        nomeSocial
        dataNascimento
        sexo
        nomeMae
        area
        microArea
        desejaInformarIdentidadeGenero
        identidadeGeneroDbEnum
        stCompartilhaProntuario
        ultimaDum {
          dum
          registradoEm
        }
        contato {
          id
          email
        }
      }
      atendimentoProfissional {
        id
        tipo
        tipoAtendimento
        iniciadoEm
        finalizadoEm
        estagio {
          id
        }
        rascunho
        lotacao {
          id
          profissional {
            id
            nome
            cns
            cpf
            numeroConselhoClasse
            conselhoClasse {
              id
              sigla
              descricao
            }
            ufEmissoraConselhoClasse {
              id
              nome
              sigla
            }
          }
          cbo {
            id
            nome
            cbo2002
          }
          unidadeSaude {
            id
            nome
            cnes
          }
          municipio {
            id
            nome
            uf {
              id
              nome
              sigla
            }
          }
        }
        atendimentoProfissionalObservacao {
          id
          atendimentoObservacao {
            id
            responsaveis {
              id
              responsavel {
                ...ResponsavelAtendimentoObservacao
              }
              atendeuComoResponsavel
            }
            ultimoResponsavelObservacao {
              ...ResponsavelAtendimentoObservacao
            }
          }
        }
      }
      prontuario {
        id
        gestacoes {
          id
          inicio
          fim
        }
        preNatalAtivo {
          id
          gravidezPlanejada
          ultimaDum
          tipoGravidez {
            id
            descricao
            tipoGravidez
          }
          altoRisco
          problema {
            id
            ultimaEvolucao {
              id
              dataInicio
            }
          }
        }
        puerpera
      }
    }
  }
  ${ResponsavelAtendimentoObservacaoFragmentDoc}
`

/**
 * __useAtendimentoIndividualViewQuery__
 *
 * To run a query within a React component, call `useAtendimentoIndividualViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoIndividualViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoIndividualViewQuery({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useAtendimentoIndividualViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtendimentoIndividualViewQuery,
    Types.AtendimentoIndividualViewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AtendimentoIndividualViewQuery, Types.AtendimentoIndividualViewQueryVariables>(
    AtendimentoIndividualViewDocument,
    baseOptions
  )
}
export function useAtendimentoIndividualViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentoIndividualViewQuery,
    Types.AtendimentoIndividualViewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AtendimentoIndividualViewQuery,
    Types.AtendimentoIndividualViewQueryVariables
  >(AtendimentoIndividualViewDocument, baseOptions)
}
export type AtendimentoIndividualViewQueryHookResult = ReturnType<typeof useAtendimentoIndividualViewQuery>
export type AtendimentoIndividualViewLazyQueryHookResult = ReturnType<typeof useAtendimentoIndividualViewLazyQuery>
export type AtendimentoIndividualViewQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoIndividualViewQuery,
  Types.AtendimentoIndividualViewQueryVariables
>
export const CiapCidAtendimentoDocument = gql`
  query CiapCidAtendimento($inputCiap: CiapQueryInput!, $inputCid10: Cid10QueryInput!) {
    ciaps(input: $inputCiap) {
      content {
        id
        codigo
        descricao
        inclui
        exclui
      }
    }
    cids(input: $inputCid10) {
      content {
        id
        codigo
        nome
      }
    }
  }
`

/**
 * __useCiapCidAtendimentoQuery__
 *
 * To run a query within a React component, call `useCiapCidAtendimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCiapCidAtendimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCiapCidAtendimentoQuery({
 *   variables: {
 *      inputCiap: // value for 'inputCiap'
 *      inputCid10: // value for 'inputCid10'
 *   },
 * });
 */
export function useCiapCidAtendimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CiapCidAtendimentoQuery, Types.CiapCidAtendimentoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CiapCidAtendimentoQuery, Types.CiapCidAtendimentoQueryVariables>(
    CiapCidAtendimentoDocument,
    baseOptions
  )
}
export function useCiapCidAtendimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CiapCidAtendimentoQuery,
    Types.CiapCidAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CiapCidAtendimentoQuery, Types.CiapCidAtendimentoQueryVariables>(
    CiapCidAtendimentoDocument,
    baseOptions
  )
}
export type CiapCidAtendimentoQueryHookResult = ReturnType<typeof useCiapCidAtendimentoQuery>
export type CiapCidAtendimentoLazyQueryHookResult = ReturnType<typeof useCiapCidAtendimentoLazyQuery>
export type CiapCidAtendimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.CiapCidAtendimentoQuery,
  Types.CiapCidAtendimentoQueryVariables
>
export const ProcedimentosAutomaticosDocument = gql`
  query ProcedimentosAutomaticos {
    procedimentosAutomaticos {
      ...Procedimento
      idadeMinima
      idadeMaxima
      sexo
    }
    registroAvaliacaoPuericultura {
      ciap {
        ...Ciap
      }
      cid10 {
        ...Cid10
      }
    }
  }
  ${ProcedimentoFragmentDoc}
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
`

/**
 * __useProcedimentosAutomaticosQuery__
 *
 * To run a query within a React component, call `useProcedimentosAutomaticosQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcedimentosAutomaticosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcedimentosAutomaticosQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcedimentosAutomaticosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProcedimentosAutomaticosQuery,
    Types.ProcedimentosAutomaticosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProcedimentosAutomaticosQuery, Types.ProcedimentosAutomaticosQueryVariables>(
    ProcedimentosAutomaticosDocument,
    baseOptions
  )
}
export function useProcedimentosAutomaticosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProcedimentosAutomaticosQuery,
    Types.ProcedimentosAutomaticosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProcedimentosAutomaticosQuery,
    Types.ProcedimentosAutomaticosQueryVariables
  >(ProcedimentosAutomaticosDocument, baseOptions)
}
export type ProcedimentosAutomaticosQueryHookResult = ReturnType<typeof useProcedimentosAutomaticosQuery>
export type ProcedimentosAutomaticosLazyQueryHookResult = ReturnType<typeof useProcedimentosAutomaticosLazyQuery>
export type ProcedimentosAutomaticosQueryResult = ApolloReactCommon.QueryResult<
  Types.ProcedimentosAutomaticosQuery,
  Types.ProcedimentosAutomaticosQueryVariables
>
export const SalvarAtendimentoIndividualDocument = gql`
  mutation SalvarAtendimentoIndividual($input: AtendimentoIndividualInput!) {
    salvarAtendimentoIndividual(input: $input) {
      id
    }
  }
`
export type SalvarAtendimentoIndividualMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAtendimentoIndividualMutation,
  Types.SalvarAtendimentoIndividualMutationVariables
>

/**
 * __useSalvarAtendimentoIndividualMutation__
 *
 * To run a mutation, you first call `useSalvarAtendimentoIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAtendimentoIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAtendimentoIndividualMutation, { data, loading, error }] = useSalvarAtendimentoIndividualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAtendimentoIndividualMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAtendimentoIndividualMutation,
    Types.SalvarAtendimentoIndividualMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarAtendimentoIndividualMutation,
    Types.SalvarAtendimentoIndividualMutationVariables
  >(SalvarAtendimentoIndividualDocument, baseOptions)
}
export type SalvarAtendimentoIndividualMutationHookResult = ReturnType<typeof useSalvarAtendimentoIndividualMutation>
export type SalvarAtendimentoIndividualMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarAtendimentoIndividualMutation
>
export type SalvarAtendimentoIndividualMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAtendimentoIndividualMutation,
  Types.SalvarAtendimentoIndividualMutationVariables
>
export const SalvarAtendimentoIndividualParaAprovacaoDocument = gql`
  mutation SalvarAtendimentoIndividualParaAprovacao($input: AtendimentoIndividualAprovacaoInput!) {
    salvarAtendimentoIndividualParaAprovacao(input: $input)
  }
`
export type SalvarAtendimentoIndividualParaAprovacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAtendimentoIndividualParaAprovacaoMutation,
  Types.SalvarAtendimentoIndividualParaAprovacaoMutationVariables
>

/**
 * __useSalvarAtendimentoIndividualParaAprovacaoMutation__
 *
 * To run a mutation, you first call `useSalvarAtendimentoIndividualParaAprovacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAtendimentoIndividualParaAprovacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAtendimentoIndividualParaAprovacaoMutation, { data, loading, error }] = useSalvarAtendimentoIndividualParaAprovacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAtendimentoIndividualParaAprovacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAtendimentoIndividualParaAprovacaoMutation,
    Types.SalvarAtendimentoIndividualParaAprovacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarAtendimentoIndividualParaAprovacaoMutation,
    Types.SalvarAtendimentoIndividualParaAprovacaoMutationVariables
  >(SalvarAtendimentoIndividualParaAprovacaoDocument, baseOptions)
}
export type SalvarAtendimentoIndividualParaAprovacaoMutationHookResult = ReturnType<
  typeof useSalvarAtendimentoIndividualParaAprovacaoMutation
>
export type SalvarAtendimentoIndividualParaAprovacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarAtendimentoIndividualParaAprovacaoMutation
>
export type SalvarAtendimentoIndividualParaAprovacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAtendimentoIndividualParaAprovacaoMutation,
  Types.SalvarAtendimentoIndividualParaAprovacaoMutationVariables
>
export const AtendimentoObservacaoViewDocument = gql`
  query AtendimentoObservacaoView($atendimentoId: ID!) {
    atendimento(id: $atendimentoId) {
      id
      atendimentoProfissional {
        id
        atestados {
          id
          descricao
          tipo
          assinadoDigitalmente
        }
        encaminhamentos
        encaminhamentosEspecializados {
          ...EncaminhamentoExternoEspecializado
        }
        orientacoes {
          id
          descricao
        }
        requisicoesExames {
          ...SolicitacaoExame
        }
        examesResultados {
          ...ResultadoExame
        }
        receitaMedicamento {
          id
          quantidadeReceitada
          recomendacoes
          qtDose
          unidadeMedidaDose {
            id
            nome
            nomePlural
          }
          frequenciaDose
          posologia
          tipoFrequenciaDose
          qtPeriodoFrequencia
          medidaTempoFrequencia
          qtDuracaoTratamento
          medidaTempoTratamento
          usoContinuo
          registroManual
          doseUnica
          tipoReceita
          tratamentoInterrompido
          justificativaInterrupcao
          dtInterrupcao
          codigoPrescricaoDigital
          aplicacaoMedicamento {
            id
            nome
          }
          medicamento {
            id
            concentracao
            principioAtivo
            principioAtivoFiltro
            unidadeFornecimento
            formaFarmaceutica {
              id
              nome
              ativo
            }
            medicamentoCatmat {
              id
              ativo
              volume
              codigoCatMat
              codigoRename
              unidadeFornecimento {
                id
                nome
                nomePlural
              }
              principioAtivo {
                id
                nome
                listaMaterial {
                  id
                  tipo
                  tipoReceita
                }
              }
            }
          }
        }
        evolucaoProblema {
          id
          situacao
          observacao
          possuiCid
          possuiCiap
          dataInicio
          dataFim
          atualizadoEm
          problema {
            ...ProblemaCondicao
          }
        }
        evolucaoSubjetivo {
          id
          descricao
          ciaps {
            id
            ciap {
              ...Ciap
            }
            nota
          }
        }
        evolucaoAvaliacao {
          id
          descricao
          ciapsCids {
            id
            nota
            isCiapCidNaListaDeProblemas
            ciap {
              ...Ciap
            }
            cid10 {
              ...Cid10
            }
          }
        }
        evolucaoObjetivo {
          id
          descricao
        }
        evolucaoPlano {
          id
          descricao
          intervencoesProcedimentos {
            id
            nota
            ciap {
              ...Ciap
            }
            procedimento {
              ...Procedimento
            }
          }
        }
        tipoAtendimento
        condutas
        racionalidadeSaude
        notificacoesCasoSuspeito {
          id
          notificacao {
            id
            nomeNotificacao
          }
        }
        lotacaoAtendimentoCompartilhado {
          id
          profissional {
            id
            nome
            cpf
          }
          unidadeSaude {
            id
            nome
            cnes
          }
          cbo {
            id
            nome
            cbo2002
          }
          equipe {
            id
            ine
            nome
            isAD
          }
        }
        procedimentosAtendimento {
          id
          procedimento {
            id
            descricao
            codigo
          }
          automatico
        }
        marcadorConsumoAlimentar {
          id
          categoria
          respostas {
            pergunta
            opcoesSelecionadas
          }
        }
      }
    }
  }
  ${EncaminhamentoExternoEspecializadoFragmentDoc}
  ${SolicitacaoExameFragmentDoc}
  ${ResultadoExameFragmentDoc}
  ${ProblemaCondicaoFragmentDoc}
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
  ${ProcedimentoFragmentDoc}
`

/**
 * __useAtendimentoObservacaoViewQuery__
 *
 * To run a query within a React component, call `useAtendimentoObservacaoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoObservacaoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoObservacaoViewQuery({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useAtendimentoObservacaoViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtendimentoObservacaoViewQuery,
    Types.AtendimentoObservacaoViewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AtendimentoObservacaoViewQuery, Types.AtendimentoObservacaoViewQueryVariables>(
    AtendimentoObservacaoViewDocument,
    baseOptions
  )
}
export function useAtendimentoObservacaoViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentoObservacaoViewQuery,
    Types.AtendimentoObservacaoViewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AtendimentoObservacaoViewQuery,
    Types.AtendimentoObservacaoViewQueryVariables
  >(AtendimentoObservacaoViewDocument, baseOptions)
}
export type AtendimentoObservacaoViewQueryHookResult = ReturnType<typeof useAtendimentoObservacaoViewQuery>
export type AtendimentoObservacaoViewLazyQueryHookResult = ReturnType<typeof useAtendimentoObservacaoViewLazyQuery>
export type AtendimentoObservacaoViewQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoObservacaoViewQuery,
  Types.AtendimentoObservacaoViewQueryVariables
>
export const SalvarAtendimentoVacinacaoParaAprovacaoDocument = gql`
  mutation SalvarAtendimentoVacinacaoParaAprovacao($input: AtendimentoVacinacaoAprovacaoInput!) {
    salvarAtendimentoVacinacaoParaAprovacao(input: $input)
  }
`
export type SalvarAtendimentoVacinacaoParaAprovacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAtendimentoVacinacaoParaAprovacaoMutation,
  Types.SalvarAtendimentoVacinacaoParaAprovacaoMutationVariables
>

/**
 * __useSalvarAtendimentoVacinacaoParaAprovacaoMutation__
 *
 * To run a mutation, you first call `useSalvarAtendimentoVacinacaoParaAprovacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAtendimentoVacinacaoParaAprovacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAtendimentoVacinacaoParaAprovacaoMutation, { data, loading, error }] = useSalvarAtendimentoVacinacaoParaAprovacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAtendimentoVacinacaoParaAprovacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAtendimentoVacinacaoParaAprovacaoMutation,
    Types.SalvarAtendimentoVacinacaoParaAprovacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarAtendimentoVacinacaoParaAprovacaoMutation,
    Types.SalvarAtendimentoVacinacaoParaAprovacaoMutationVariables
  >(SalvarAtendimentoVacinacaoParaAprovacaoDocument, baseOptions)
}
export type SalvarAtendimentoVacinacaoParaAprovacaoMutationHookResult = ReturnType<
  typeof useSalvarAtendimentoVacinacaoParaAprovacaoMutation
>
export type SalvarAtendimentoVacinacaoParaAprovacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarAtendimentoVacinacaoParaAprovacaoMutation
>
export type SalvarAtendimentoVacinacaoParaAprovacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAtendimentoVacinacaoParaAprovacaoMutation,
  Types.SalvarAtendimentoVacinacaoParaAprovacaoMutationVariables
>
export const AtendimentoVacinacaoViewDocument = gql`
  query AtendimentoVacinacaoView($atendimentoId: ID!) {
    atendimento(id: $atendimentoId) {
      id
      iniciadoEm
      statusAtendimento
      statusRevisaoAtendimento
      atendimentoProfissional {
        id
        tipo
        iniciadoEm
        rascunho
        estagio {
          id
        }
        lotacao {
          id
          profissional {
            id
            cpf
            cns
            nome
          }
          cbo {
            id
            nome
            cbo2002
          }
          unidadeSaude {
            id
            nome
            cnes
          }
          municipio {
            id
            nome
            uf {
              id
              nome
              sigla
            }
          }
        }
      }
      agendado {
        id
        lotacaoAgendada {
          id
        }
      }
      isRegistroTardio
      cidadao {
        id
        cpf
        cns
        nome
        nomeSocial
        dataNascimento
        sexo
        nomeMae
        area
        microArea
        identidadeGeneroDbEnum
        stCompartilhaProntuario
      }
      atendimentoProfissional {
        id
        tipo
      }
      tiposServico {
        id
      }
      prontuario {
        id
        gestacoes {
          id
          inicio
          fim
        }
        preNatalAtivo {
          id
          ultimaDum
        }
        puerpera
      }
    }
  }
`

/**
 * __useAtendimentoVacinacaoViewQuery__
 *
 * To run a query within a React component, call `useAtendimentoVacinacaoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoVacinacaoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoVacinacaoViewQuery({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useAtendimentoVacinacaoViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtendimentoVacinacaoViewQuery,
    Types.AtendimentoVacinacaoViewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AtendimentoVacinacaoViewQuery, Types.AtendimentoVacinacaoViewQueryVariables>(
    AtendimentoVacinacaoViewDocument,
    baseOptions
  )
}
export function useAtendimentoVacinacaoViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentoVacinacaoViewQuery,
    Types.AtendimentoVacinacaoViewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AtendimentoVacinacaoViewQuery,
    Types.AtendimentoVacinacaoViewQueryVariables
  >(AtendimentoVacinacaoViewDocument, baseOptions)
}
export type AtendimentoVacinacaoViewQueryHookResult = ReturnType<typeof useAtendimentoVacinacaoViewQuery>
export type AtendimentoVacinacaoViewLazyQueryHookResult = ReturnType<typeof useAtendimentoVacinacaoViewLazyQuery>
export type AtendimentoVacinacaoViewQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoVacinacaoViewQuery,
  Types.AtendimentoVacinacaoViewQueryVariables
>
export const EsquemaCalendarioVacinalDocument = gql`
  query EsquemaCalendarioVacinal($input: EsquemaCalendarioVacinalQueryInput!) {
    esquemaCalendarioVacinal(input: $input) {
      id
      descricaoImunobiologico
      idadeRecomendada
      mostrarNoCalendarioDoGrupo
      imunobiologico {
        id
        sigla
        nome
      }
      dose {
        id
        nomeApresentacaoDose
        sigla
        nome
        ordem
      }
      faixaEtaria {
        id
        diasInicio
        diasFim
        descricao
      }
      ordemImuno
    }
  }
`

/**
 * __useEsquemaCalendarioVacinalQuery__
 *
 * To run a query within a React component, call `useEsquemaCalendarioVacinalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsquemaCalendarioVacinalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsquemaCalendarioVacinalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEsquemaCalendarioVacinalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EsquemaCalendarioVacinalQuery,
    Types.EsquemaCalendarioVacinalQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EsquemaCalendarioVacinalQuery, Types.EsquemaCalendarioVacinalQueryVariables>(
    EsquemaCalendarioVacinalDocument,
    baseOptions
  )
}
export function useEsquemaCalendarioVacinalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EsquemaCalendarioVacinalQuery,
    Types.EsquemaCalendarioVacinalQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EsquemaCalendarioVacinalQuery,
    Types.EsquemaCalendarioVacinalQueryVariables
  >(EsquemaCalendarioVacinalDocument, baseOptions)
}
export type EsquemaCalendarioVacinalQueryHookResult = ReturnType<typeof useEsquemaCalendarioVacinalQuery>
export type EsquemaCalendarioVacinalLazyQueryHookResult = ReturnType<typeof useEsquemaCalendarioVacinalLazyQuery>
export type EsquemaCalendarioVacinalQueryResult = ApolloReactCommon.QueryResult<
  Types.EsquemaCalendarioVacinalQuery,
  Types.EsquemaCalendarioVacinalQueryVariables
>
export const FaixaEtariaVacinacaoDocument = gql`
  query FaixaEtariaVacinacao($input: FaixaEtariaVacinacaoQueryInput!) {
    faixaEtariaVacinacao(input: $input) {
      id
      diasInicio
      diasFim
      descricao
    }
  }
`

/**
 * __useFaixaEtariaVacinacaoQuery__
 *
 * To run a query within a React component, call `useFaixaEtariaVacinacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaixaEtariaVacinacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaixaEtariaVacinacaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaixaEtariaVacinacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.FaixaEtariaVacinacaoQuery,
    Types.FaixaEtariaVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.FaixaEtariaVacinacaoQuery, Types.FaixaEtariaVacinacaoQueryVariables>(
    FaixaEtariaVacinacaoDocument,
    baseOptions
  )
}
export function useFaixaEtariaVacinacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.FaixaEtariaVacinacaoQuery,
    Types.FaixaEtariaVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.FaixaEtariaVacinacaoQuery, Types.FaixaEtariaVacinacaoQueryVariables>(
    FaixaEtariaVacinacaoDocument,
    baseOptions
  )
}
export type FaixaEtariaVacinacaoQueryHookResult = ReturnType<typeof useFaixaEtariaVacinacaoQuery>
export type FaixaEtariaVacinacaoLazyQueryHookResult = ReturnType<typeof useFaixaEtariaVacinacaoLazyQuery>
export type FaixaEtariaVacinacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.FaixaEtariaVacinacaoQuery,
  Types.FaixaEtariaVacinacaoQueryVariables
>
export const HasVacinaAtrasadaDocument = gql`
  query HasVacinaAtrasada($input: VacinacaoUltimaVacinaInput!) {
    hasVacinaAtrasada(input: $input)
  }
`

/**
 * __useHasVacinaAtrasadaQuery__
 *
 * To run a query within a React component, call `useHasVacinaAtrasadaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasVacinaAtrasadaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasVacinaAtrasadaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHasVacinaAtrasadaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.HasVacinaAtrasadaQuery, Types.HasVacinaAtrasadaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.HasVacinaAtrasadaQuery, Types.HasVacinaAtrasadaQueryVariables>(
    HasVacinaAtrasadaDocument,
    baseOptions
  )
}
export function useHasVacinaAtrasadaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HasVacinaAtrasadaQuery,
    Types.HasVacinaAtrasadaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HasVacinaAtrasadaQuery, Types.HasVacinaAtrasadaQueryVariables>(
    HasVacinaAtrasadaDocument,
    baseOptions
  )
}
export type HasVacinaAtrasadaQueryHookResult = ReturnType<typeof useHasVacinaAtrasadaQuery>
export type HasVacinaAtrasadaLazyQueryHookResult = ReturnType<typeof useHasVacinaAtrasadaLazyQuery>
export type HasVacinaAtrasadaQueryResult = ApolloReactCommon.QueryResult<
  Types.HasVacinaAtrasadaQuery,
  Types.HasVacinaAtrasadaQueryVariables
>
export const ProximaDoseAprazamentoDocument = gql`
  query ProximaDoseAprazamento($input: ProximaDoseAprazamentoQueryInput!) {
    proximaDoseAprazamentoAutomatico(input: $input) {
      diasAprazamento
      proximaDoseId
      proximaDoseSigla
      proximaDoseNome
    }
  }
`

/**
 * __useProximaDoseAprazamentoQuery__
 *
 * To run a query within a React component, call `useProximaDoseAprazamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProximaDoseAprazamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProximaDoseAprazamentoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProximaDoseAprazamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProximaDoseAprazamentoQuery,
    Types.ProximaDoseAprazamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProximaDoseAprazamentoQuery, Types.ProximaDoseAprazamentoQueryVariables>(
    ProximaDoseAprazamentoDocument,
    baseOptions
  )
}
export function useProximaDoseAprazamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProximaDoseAprazamentoQuery,
    Types.ProximaDoseAprazamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProximaDoseAprazamentoQuery, Types.ProximaDoseAprazamentoQueryVariables>(
    ProximaDoseAprazamentoDocument,
    baseOptions
  )
}
export type ProximaDoseAprazamentoQueryHookResult = ReturnType<typeof useProximaDoseAprazamentoQuery>
export type ProximaDoseAprazamentoLazyQueryHookResult = ReturnType<typeof useProximaDoseAprazamentoLazyQuery>
export type ProximaDoseAprazamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.ProximaDoseAprazamentoQuery,
  Types.ProximaDoseAprazamentoQueryVariables
>
export const RegistrosAplicadosVacinacaoDocument = gql`
  query RegistrosAplicadosVacinacao($input: RegistrosAplicadosVacinacaoInput!) {
    registrosAplicadosVacinacao(input: $input) {
      imunobiologicoId
      imunobiologicoSigla
      imunobiologicoNome
      doseId
      doseNomeApresentacao
      doseNome
      doseSigla
      dataAprazamento
      dataAplicacao
      quantidadeAplicacoes
      dataRegistroAprazamento
      estrategiaId
      isRegistroAnterior
    }
  }
`

/**
 * __useRegistrosAplicadosVacinacaoQuery__
 *
 * To run a query within a React component, call `useRegistrosAplicadosVacinacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrosAplicadosVacinacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrosAplicadosVacinacaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrosAplicadosVacinacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.RegistrosAplicadosVacinacaoQuery,
    Types.RegistrosAplicadosVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.RegistrosAplicadosVacinacaoQuery,
    Types.RegistrosAplicadosVacinacaoQueryVariables
  >(RegistrosAplicadosVacinacaoDocument, baseOptions)
}
export function useRegistrosAplicadosVacinacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.RegistrosAplicadosVacinacaoQuery,
    Types.RegistrosAplicadosVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.RegistrosAplicadosVacinacaoQuery,
    Types.RegistrosAplicadosVacinacaoQueryVariables
  >(RegistrosAplicadosVacinacaoDocument, baseOptions)
}
export type RegistrosAplicadosVacinacaoQueryHookResult = ReturnType<typeof useRegistrosAplicadosVacinacaoQuery>
export type RegistrosAplicadosVacinacaoLazyQueryHookResult = ReturnType<typeof useRegistrosAplicadosVacinacaoLazyQuery>
export type RegistrosAplicadosVacinacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.RegistrosAplicadosVacinacaoQuery,
  Types.RegistrosAplicadosVacinacaoQueryVariables
>
export const SalvarAtendimentoVacinacaoDocument = gql`
  mutation SalvarAtendimentoVacinacao($input: AtendimentoVacinacaoInput!) {
    salvarAtendimentoVacinacao(input: $input) {
      id
      atendimentoProfissional {
        id
      }
    }
  }
`
export type SalvarAtendimentoVacinacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAtendimentoVacinacaoMutation,
  Types.SalvarAtendimentoVacinacaoMutationVariables
>

/**
 * __useSalvarAtendimentoVacinacaoMutation__
 *
 * To run a mutation, you first call `useSalvarAtendimentoVacinacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAtendimentoVacinacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAtendimentoVacinacaoMutation, { data, loading, error }] = useSalvarAtendimentoVacinacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAtendimentoVacinacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAtendimentoVacinacaoMutation,
    Types.SalvarAtendimentoVacinacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarAtendimentoVacinacaoMutation,
    Types.SalvarAtendimentoVacinacaoMutationVariables
  >(SalvarAtendimentoVacinacaoDocument, baseOptions)
}
export type SalvarAtendimentoVacinacaoMutationHookResult = ReturnType<typeof useSalvarAtendimentoVacinacaoMutation>
export type SalvarAtendimentoVacinacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarAtendimentoVacinacaoMutation
>
export type SalvarAtendimentoVacinacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAtendimentoVacinacaoMutation,
  Types.SalvarAtendimentoVacinacaoMutationVariables
>
export const UltimaVacinaAprazadaDocument = gql`
  query UltimaVacinaAprazada($input: VacinacaoUltimaVacinaInput!) {
    ultimaVacinaAprazada(input: $input) {
      imunobiologicoNome
      dataAprazamento
      doseNomeApresentacao
    }
  }
`

/**
 * __useUltimaVacinaAprazadaQuery__
 *
 * To run a query within a React component, call `useUltimaVacinaAprazadaQuery` and pass it any options that fit your needs.
 * When your component renders, `useUltimaVacinaAprazadaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUltimaVacinaAprazadaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUltimaVacinaAprazadaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UltimaVacinaAprazadaQuery,
    Types.UltimaVacinaAprazadaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UltimaVacinaAprazadaQuery, Types.UltimaVacinaAprazadaQueryVariables>(
    UltimaVacinaAprazadaDocument,
    baseOptions
  )
}
export function useUltimaVacinaAprazadaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UltimaVacinaAprazadaQuery,
    Types.UltimaVacinaAprazadaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UltimaVacinaAprazadaQuery, Types.UltimaVacinaAprazadaQueryVariables>(
    UltimaVacinaAprazadaDocument,
    baseOptions
  )
}
export type UltimaVacinaAprazadaQueryHookResult = ReturnType<typeof useUltimaVacinaAprazadaQuery>
export type UltimaVacinaAprazadaLazyQueryHookResult = ReturnType<typeof useUltimaVacinaAprazadaLazyQuery>
export type UltimaVacinaAprazadaQueryResult = ApolloReactCommon.QueryResult<
  Types.UltimaVacinaAprazadaQuery,
  Types.UltimaVacinaAprazadaQueryVariables
>
export const UltimasTresVacinasAplicadasDocument = gql`
  query UltimasTresVacinasAplicadas($input: VacinacaoUltimaVacinaInput!) {
    ultimasTresVacinasAplicadas(input: $input) {
      imunobiologicoNome
      dataAplicacao
      doseNomeApresentacao
    }
  }
`

/**
 * __useUltimasTresVacinasAplicadasQuery__
 *
 * To run a query within a React component, call `useUltimasTresVacinasAplicadasQuery` and pass it any options that fit your needs.
 * When your component renders, `useUltimasTresVacinasAplicadasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUltimasTresVacinasAplicadasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUltimasTresVacinasAplicadasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UltimasTresVacinasAplicadasQuery,
    Types.UltimasTresVacinasAplicadasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UltimasTresVacinasAplicadasQuery,
    Types.UltimasTresVacinasAplicadasQueryVariables
  >(UltimasTresVacinasAplicadasDocument, baseOptions)
}
export function useUltimasTresVacinasAplicadasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UltimasTresVacinasAplicadasQuery,
    Types.UltimasTresVacinasAplicadasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UltimasTresVacinasAplicadasQuery,
    Types.UltimasTresVacinasAplicadasQueryVariables
  >(UltimasTresVacinasAplicadasDocument, baseOptions)
}
export type UltimasTresVacinasAplicadasQueryHookResult = ReturnType<typeof useUltimasTresVacinasAplicadasQuery>
export type UltimasTresVacinasAplicadasLazyQueryHookResult = ReturnType<typeof useUltimasTresVacinasAplicadasLazyQuery>
export type UltimasTresVacinasAplicadasQueryResult = ApolloReactCommon.QueryResult<
  Types.UltimasTresVacinasAplicadasQuery,
  Types.UltimasTresVacinasAplicadasQueryVariables
>
export const AcessoConectesusRndsDocument = gql`
  mutation acessoConectesusRnds($input: AcessoConecteSusInput!) {
    acessoConecteSus(input: $input)
  }
`
export type AcessoConectesusRndsMutationFn = ApolloReactCommon.MutationFunction<
  Types.AcessoConectesusRndsMutation,
  Types.AcessoConectesusRndsMutationVariables
>

/**
 * __useAcessoConectesusRndsMutation__
 *
 * To run a mutation, you first call `useAcessoConectesusRndsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcessoConectesusRndsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acessoConectesusRndsMutation, { data, loading, error }] = useAcessoConectesusRndsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcessoConectesusRndsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AcessoConectesusRndsMutation,
    Types.AcessoConectesusRndsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AcessoConectesusRndsMutation, Types.AcessoConectesusRndsMutationVariables>(
    AcessoConectesusRndsDocument,
    baseOptions
  )
}
export type AcessoConectesusRndsMutationHookResult = ReturnType<typeof useAcessoConectesusRndsMutation>
export type AcessoConectesusRndsMutationResult = ApolloReactCommon.MutationResult<Types.AcessoConectesusRndsMutation>
export type AcessoConectesusRndsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AcessoConectesusRndsMutation,
  Types.AcessoConectesusRndsMutationVariables
>
export const AtendimentoViewDocument = gql`
  query AtendimentoView($id: ID!) {
    atendimento(id: $id) {
      id
      statusAtendimento
      isRegistroTardio
      atendimentoProfissional {
        id
        tipo
        lotacao {
          id
        }
        estagio {
          id
        }
      }
    }
  }
`

/**
 * __useAtendimentoViewQuery__
 *
 * To run a query within a React component, call `useAtendimentoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtendimentoViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AtendimentoViewQuery, Types.AtendimentoViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AtendimentoViewQuery, Types.AtendimentoViewQueryVariables>(
    AtendimentoViewDocument,
    baseOptions
  )
}
export function useAtendimentoViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AtendimentoViewQuery, Types.AtendimentoViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AtendimentoViewQuery, Types.AtendimentoViewQueryVariables>(
    AtendimentoViewDocument,
    baseOptions
  )
}
export type AtendimentoViewQueryHookResult = ReturnType<typeof useAtendimentoViewQuery>
export type AtendimentoViewLazyQueryHookResult = ReturnType<typeof useAtendimentoViewLazyQuery>
export type AtendimentoViewQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoViewQuery,
  Types.AtendimentoViewQueryVariables
>
export const CancelarAtendimentoDocument = gql`
  mutation CancelarAtendimento($atendimentoId: ID!) {
    cancelarAtendimento(atendimento: $atendimentoId) {
      id
    }
  }
`
export type CancelarAtendimentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.CancelarAtendimentoMutation,
  Types.CancelarAtendimentoMutationVariables
>

/**
 * __useCancelarAtendimentoMutation__
 *
 * To run a mutation, you first call `useCancelarAtendimentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelarAtendimentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelarAtendimentoMutation, { data, loading, error }] = useCancelarAtendimentoMutation({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useCancelarAtendimentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CancelarAtendimentoMutation,
    Types.CancelarAtendimentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.CancelarAtendimentoMutation, Types.CancelarAtendimentoMutationVariables>(
    CancelarAtendimentoDocument,
    baseOptions
  )
}
export type CancelarAtendimentoMutationHookResult = ReturnType<typeof useCancelarAtendimentoMutation>
export type CancelarAtendimentoMutationResult = ApolloReactCommon.MutationResult<Types.CancelarAtendimentoMutation>
export type CancelarAtendimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CancelarAtendimentoMutation,
  Types.CancelarAtendimentoMutationVariables
>
export const RascunhoAtendimentoDocument = gql`
  query RascunhoAtendimento($id: ID!) {
    atendimento(id: $id) {
      id
      atendimentoProfissional {
        id
        rascunho
      }
    }
  }
`

/**
 * __useRascunhoAtendimentoQuery__
 *
 * To run a query within a React component, call `useRascunhoAtendimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRascunhoAtendimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRascunhoAtendimentoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRascunhoAtendimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.RascunhoAtendimentoQuery,
    Types.RascunhoAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.RascunhoAtendimentoQuery, Types.RascunhoAtendimentoQueryVariables>(
    RascunhoAtendimentoDocument,
    baseOptions
  )
}
export function useRascunhoAtendimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.RascunhoAtendimentoQuery,
    Types.RascunhoAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.RascunhoAtendimentoQuery, Types.RascunhoAtendimentoQueryVariables>(
    RascunhoAtendimentoDocument,
    baseOptions
  )
}
export type RascunhoAtendimentoQueryHookResult = ReturnType<typeof useRascunhoAtendimentoQuery>
export type RascunhoAtendimentoLazyQueryHookResult = ReturnType<typeof useRascunhoAtendimentoLazyQuery>
export type RascunhoAtendimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.RascunhoAtendimentoQuery,
  Types.RascunhoAtendimentoQueryVariables
>
export const RegistrosDoseVacinacaoDocument = gql`
  query RegistrosDoseVacinacao($input: RegistrosDoseVacinacaoInput!) {
    registrosDoseVacinacao(input: $input) {
      lote
      viaAdministracao
      localAplicacao
      doseNome
      doseSigla
      estrategia
      estrategiaId
      dataAplicacao
      dataAprazamento
      dataRegistro
      dataValidadeLote
      profissional
      grupoAtendimento
      descricao
      fabricante
      cbo
      isGestante
      isPuerpera
      isViajante
      isComunicanteHanseniase
      unidadeSaude
      origemDados
      isRegistroAnterior
      isAplicadoExterior
      isAplicacaoEquivalente
    }
  }
`

/**
 * __useRegistrosDoseVacinacaoQuery__
 *
 * To run a query within a React component, call `useRegistrosDoseVacinacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrosDoseVacinacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrosDoseVacinacaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrosDoseVacinacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.RegistrosDoseVacinacaoQuery,
    Types.RegistrosDoseVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.RegistrosDoseVacinacaoQuery, Types.RegistrosDoseVacinacaoQueryVariables>(
    RegistrosDoseVacinacaoDocument,
    baseOptions
  )
}
export function useRegistrosDoseVacinacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.RegistrosDoseVacinacaoQuery,
    Types.RegistrosDoseVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.RegistrosDoseVacinacaoQuery, Types.RegistrosDoseVacinacaoQueryVariables>(
    RegistrosDoseVacinacaoDocument,
    baseOptions
  )
}
export type RegistrosDoseVacinacaoQueryHookResult = ReturnType<typeof useRegistrosDoseVacinacaoQuery>
export type RegistrosDoseVacinacaoLazyQueryHookResult = ReturnType<typeof useRegistrosDoseVacinacaoLazyQuery>
export type RegistrosDoseVacinacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.RegistrosDoseVacinacaoQuery,
  Types.RegistrosDoseVacinacaoQueryVariables
>
export const AlergiasModalDocument = gql`
  query AlergiasModal($filtro: AlergiasQueryInput!) {
    alergias(input: $filtro) {
      content {
        ...Alergia
        substanciaEspecifica {
          ...SubstanciaEspecificaAlergia
          sigla
        }
        ultimaEvolucao {
          id
          dataInicio
          manifestacoesLegado
          possuiSubstanciaEspecificaCbara
          atualizadoEm
          manifestacoes {
            id
            nome
            sinonimo
          }
        }
        evolucoes {
          id
          tipoReacao
          criticidade
          grauCerteza
          dataInicio
          manifestacoes {
            id
            nome
          }
          manifestacoesLegado
          atualizadoEm
          observacao
          atendimentoProfissional {
            id
            lotacao {
              id
              cbo {
                id
                nome
              }
              profissional {
                id
                nome
              }
            }
          }
          possuiSubstanciaEspecificaCbara
        }
      }
    }
  }
  ${AlergiaFragmentDoc}
  ${SubstanciaEspecificaAlergiaFragmentDoc}
`

/**
 * __useAlergiasModalQuery__
 *
 * To run a query within a React component, call `useAlergiasModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlergiasModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlergiasModalQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useAlergiasModalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AlergiasModalQuery, Types.AlergiasModalQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AlergiasModalQuery, Types.AlergiasModalQueryVariables>(
    AlergiasModalDocument,
    baseOptions
  )
}
export function useAlergiasModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AlergiasModalQuery, Types.AlergiasModalQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AlergiasModalQuery, Types.AlergiasModalQueryVariables>(
    AlergiasModalDocument,
    baseOptions
  )
}
export type AlergiasModalQueryHookResult = ReturnType<typeof useAlergiasModalQuery>
export type AlergiasModalLazyQueryHookResult = ReturnType<typeof useAlergiasModalLazyQuery>
export type AlergiasModalQueryResult = ApolloReactCommon.QueryResult<
  Types.AlergiasModalQuery,
  Types.AlergiasModalQueryVariables
>
export const LembretesModalDocument = gql`
  query LembretesModal($lembretesQueryInput: LembretesQueryInput!) {
    lembretes(input: $lembretesQueryInput) {
      id
      historicoLembrete {
        id
        visibilidadeLembrete
        ativo
        descricao
        data
        lotacao {
          id
          profissional {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
      }
      profissionalCriador {
        id
      }
    }
  }
`

/**
 * __useLembretesModalQuery__
 *
 * To run a query within a React component, call `useLembretesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useLembretesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLembretesModalQuery({
 *   variables: {
 *      lembretesQueryInput: // value for 'lembretesQueryInput'
 *   },
 * });
 */
export function useLembretesModalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LembretesModalQuery, Types.LembretesModalQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LembretesModalQuery, Types.LembretesModalQueryVariables>(
    LembretesModalDocument,
    baseOptions
  )
}
export function useLembretesModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LembretesModalQuery, Types.LembretesModalQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LembretesModalQuery, Types.LembretesModalQueryVariables>(
    LembretesModalDocument,
    baseOptions
  )
}
export type LembretesModalQueryHookResult = ReturnType<typeof useLembretesModalQuery>
export type LembretesModalLazyQueryHookResult = ReturnType<typeof useLembretesModalLazyQuery>
export type LembretesModalQueryResult = ApolloReactCommon.QueryResult<
  Types.LembretesModalQuery,
  Types.LembretesModalQueryVariables
>
export const ExisteProblemaResolvidoDocument = gql`
  query ExisteProblemaResolvido($prontuarioId: ID!) {
    existeProblemaResolvido(input: $prontuarioId)
  }
`

/**
 * __useExisteProblemaResolvidoQuery__
 *
 * To run a query within a React component, call `useExisteProblemaResolvidoQuery` and pass it any options that fit your needs.
 * When your component renders, `useExisteProblemaResolvidoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExisteProblemaResolvidoQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useExisteProblemaResolvidoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ExisteProblemaResolvidoQuery,
    Types.ExisteProblemaResolvidoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ExisteProblemaResolvidoQuery, Types.ExisteProblemaResolvidoQueryVariables>(
    ExisteProblemaResolvidoDocument,
    baseOptions
  )
}
export function useExisteProblemaResolvidoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ExisteProblemaResolvidoQuery,
    Types.ExisteProblemaResolvidoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ExisteProblemaResolvidoQuery, Types.ExisteProblemaResolvidoQueryVariables>(
    ExisteProblemaResolvidoDocument,
    baseOptions
  )
}
export type ExisteProblemaResolvidoQueryHookResult = ReturnType<typeof useExisteProblemaResolvidoQuery>
export type ExisteProblemaResolvidoLazyQueryHookResult = ReturnType<typeof useExisteProblemaResolvidoLazyQuery>
export type ExisteProblemaResolvidoQueryResult = ApolloReactCommon.QueryResult<
  Types.ExisteProblemaResolvidoQuery,
  Types.ExisteProblemaResolvidoQueryVariables
>
export const ProblemasModalDocument = gql`
  query ProblemasModal($filtro: ProblemaQueryInput!) {
    problemas(input: $filtro) {
      content {
        id
        situacao
        ciap {
          ...Ciap
        }
        cid10 {
          ...Cid10
        }
        descricaoOutro
        ultimaEvolucao {
          id
          dataInicio
          dataFim
          atualizadoEm
          situacao
          observacao
        }
        evolucaoAvaliacaoCiapCid {
          id
        }
        evolucoes {
          id
          dataInicio
          dataFim
          situacao
          observacao
          atualizadoEm
          possuiCid
          possuiCiap
          atendimentoProfissional {
            id
            lotacao {
              id
              cbo {
                id
                nome
              }
              profissional {
                id
                nome
              }
            }
          }
        }
      }
    }
  }
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
`

/**
 * __useProblemasModalQuery__
 *
 * To run a query within a React component, call `useProblemasModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemasModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemasModalQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProblemasModalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProblemasModalQuery, Types.ProblemasModalQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProblemasModalQuery, Types.ProblemasModalQueryVariables>(
    ProblemasModalDocument,
    baseOptions
  )
}
export function useProblemasModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProblemasModalQuery, Types.ProblemasModalQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ProblemasModalQuery, Types.ProblemasModalQueryVariables>(
    ProblemasModalDocument,
    baseOptions
  )
}
export type ProblemasModalQueryHookResult = ReturnType<typeof useProblemasModalQuery>
export type ProblemasModalLazyQueryHookResult = ReturnType<typeof useProblemasModalLazyQuery>
export type ProblemasModalQueryResult = ApolloReactCommon.QueryResult<
  Types.ProblemasModalQuery,
  Types.ProblemasModalQueryVariables
>
export const ProblemasModalSimplificadoDocument = gql`
  query ProblemasModalSimplificado($filtro: ProblemaQueryInput!) {
    problemas(input: $filtro) {
      content {
        ...ProblemaCondicao
      }
    }
  }
  ${ProblemaCondicaoFragmentDoc}
`

/**
 * __useProblemasModalSimplificadoQuery__
 *
 * To run a query within a React component, call `useProblemasModalSimplificadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemasModalSimplificadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemasModalSimplificadoQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProblemasModalSimplificadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProblemasModalSimplificadoQuery,
    Types.ProblemasModalSimplificadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProblemasModalSimplificadoQuery,
    Types.ProblemasModalSimplificadoQueryVariables
  >(ProblemasModalSimplificadoDocument, baseOptions)
}
export function useProblemasModalSimplificadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProblemasModalSimplificadoQuery,
    Types.ProblemasModalSimplificadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProblemasModalSimplificadoQuery,
    Types.ProblemasModalSimplificadoQueryVariables
  >(ProblemasModalSimplificadoDocument, baseOptions)
}
export type ProblemasModalSimplificadoQueryHookResult = ReturnType<typeof useProblemasModalSimplificadoQuery>
export type ProblemasModalSimplificadoLazyQueryHookResult = ReturnType<typeof useProblemasModalSimplificadoLazyQuery>
export type ProblemasModalSimplificadoQueryResult = ApolloReactCommon.QueryResult<
  Types.ProblemasModalSimplificadoQuery,
  Types.ProblemasModalSimplificadoQueryVariables
>
export const MedicamentoUsoModalDocument = gql`
  query MedicamentoUsoModal($input: ListaMedicamentoAtivoConcluidoQueryInput!) {
    listaMedicamento(input: $input) {
      content {
        id
        medicamento {
          id
          principioAtivo
          concentracao
        }
        usoContinuo
        posologia
        dtInicioTratamento
        dtFimTratamento
        medidaTempoTratamento
        tipoReceita
        recomendacoes
        tratamentoInterrompido
        justificativaInterrupcao
        dtInterrupcao
        atendProf {
          id
          versaoAnterior
          lotacao {
            id
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMedicamentoUsoModalQuery__
 *
 * To run a query within a React component, call `useMedicamentoUsoModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicamentoUsoModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicamentoUsoModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicamentoUsoModalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MedicamentoUsoModalQuery,
    Types.MedicamentoUsoModalQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.MedicamentoUsoModalQuery, Types.MedicamentoUsoModalQueryVariables>(
    MedicamentoUsoModalDocument,
    baseOptions
  )
}
export function useMedicamentoUsoModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MedicamentoUsoModalQuery,
    Types.MedicamentoUsoModalQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.MedicamentoUsoModalQuery, Types.MedicamentoUsoModalQueryVariables>(
    MedicamentoUsoModalDocument,
    baseOptions
  )
}
export type MedicamentoUsoModalQueryHookResult = ReturnType<typeof useMedicamentoUsoModalQuery>
export type MedicamentoUsoModalLazyQueryHookResult = ReturnType<typeof useMedicamentoUsoModalLazyQuery>
export type MedicamentoUsoModalQueryResult = ApolloReactCommon.QueryResult<
  Types.MedicamentoUsoModalQuery,
  Types.MedicamentoUsoModalQueryVariables
>
export const AcompanhamentoPreNatalDocument = gql`
  query acompanhamentoPreNatal($input: ID!) {
    atendimentosProfUltimaGestacao(prontuarioId: $input) {
      id
      iniciadoEm
      atendimentoProfissionalPreNatal {
        id
        movimentacaoFetal
        tipoEdema
      }
      medicoes {
        id
        dataMedicao
        valorBatimentoCardiacoFetal
        dum
        valorAlturaUterina
        medicaoAnterior
      }
      examesResultados {
        ...ExamePreNatal
      }
    }
  }
  ${ExamePreNatalFragmentDoc}
`

/**
 * __useAcompanhamentoPreNatalQuery__
 *
 * To run a query within a React component, call `useAcompanhamentoPreNatalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcompanhamentoPreNatalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcompanhamentoPreNatalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcompanhamentoPreNatalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcompanhamentoPreNatalQuery,
    Types.AcompanhamentoPreNatalQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AcompanhamentoPreNatalQuery, Types.AcompanhamentoPreNatalQueryVariables>(
    AcompanhamentoPreNatalDocument,
    baseOptions
  )
}
export function useAcompanhamentoPreNatalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcompanhamentoPreNatalQuery,
    Types.AcompanhamentoPreNatalQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AcompanhamentoPreNatalQuery, Types.AcompanhamentoPreNatalQueryVariables>(
    AcompanhamentoPreNatalDocument,
    baseOptions
  )
}
export type AcompanhamentoPreNatalQueryHookResult = ReturnType<typeof useAcompanhamentoPreNatalQuery>
export type AcompanhamentoPreNatalLazyQueryHookResult = ReturnType<typeof useAcompanhamentoPreNatalLazyQuery>
export type AcompanhamentoPreNatalQueryResult = ApolloReactCommon.QueryResult<
  Types.AcompanhamentoPreNatalQuery,
  Types.AcompanhamentoPreNatalQueryVariables
>
export const MedicoesAtendimentosAnterioresDocument = gql`
  query MedicoesAtendimentosAnteriores($prontuarioId: ID!) {
    medicoes(input: { prontuarioId: $prontuarioId }) {
      id
      dataMedicao
      dum
      valorPeso
      valorAltura
      valorFrequenciaCardiaca
      valorPressaoArterial
      valorPerimetroCefalico
      valorFrequenciaRespiratoria
      valorTemperatura
      valorSaturacaoO2
      valorGlicemia
      tipoGlicemia
      atendimentoProfissional {
        id
        iniciadoEm
        lotacao {
          id
          profissional {
            id
            nome
          }
        }
      }
      valorImc
      perimetroPanturrilha
      valorCircunferenciaAbdominal
      medicaoAnterior
      valorAlturaUterina
    }
    medicoesNoNascimento: antecedente(prontuarioId: $prontuarioId) {
      id
      peso
      altura
      perimetroCefalico
      dataUltimaAtualizacao: dataUltimaAtualizacaoPuericultura
    }
  }
`

/**
 * __useMedicoesAtendimentosAnterioresQuery__
 *
 * To run a query within a React component, call `useMedicoesAtendimentosAnterioresQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicoesAtendimentosAnterioresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicoesAtendimentosAnterioresQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useMedicoesAtendimentosAnterioresQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MedicoesAtendimentosAnterioresQuery,
    Types.MedicoesAtendimentosAnterioresQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.MedicoesAtendimentosAnterioresQuery,
    Types.MedicoesAtendimentosAnterioresQueryVariables
  >(MedicoesAtendimentosAnterioresDocument, baseOptions)
}
export function useMedicoesAtendimentosAnterioresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MedicoesAtendimentosAnterioresQuery,
    Types.MedicoesAtendimentosAnterioresQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.MedicoesAtendimentosAnterioresQuery,
    Types.MedicoesAtendimentosAnterioresQueryVariables
  >(MedicoesAtendimentosAnterioresDocument, baseOptions)
}
export type MedicoesAtendimentosAnterioresQueryHookResult = ReturnType<typeof useMedicoesAtendimentosAnterioresQuery>
export type MedicoesAtendimentosAnterioresLazyQueryHookResult = ReturnType<
  typeof useMedicoesAtendimentosAnterioresLazyQuery
>
export type MedicoesAtendimentosAnterioresQueryResult = ApolloReactCommon.QueryResult<
  Types.MedicoesAtendimentosAnterioresQuery,
  Types.MedicoesAtendimentosAnterioresQueryVariables
>
export const HistoricoResultadosExamesDocument = gql`
  query HistoricoResultadosExames($input: ResultadosExameQueryInput!) {
    resultadosExame(input: $input) {
      content {
        id
        exame {
          ...Procedimento
        }
        resultado
        solicitacao {
          id
          data
          atendimentoProfissional {
            id
            lotacao {
              id
              cbo {
                id
                nome
              }
              profissional {
                id
                nome
              }
            }
          }
        }
        dataRealizacao
        dataResultado
        dataSolicitacao
        conferido
        especifico {
          id
          exameEspecificoEnum
          valor
          igDias
          igSemanas
          dpp
          resultadoExamePuericulturaEnum
        }
        atendimentoProfissional {
          id
          iniciadoEm
          lotacao {
            id
            cbo {
              id
              nome
            }
            profissional {
              id
              nome
            }
          }
        }
      }
    }
  }
  ${ProcedimentoFragmentDoc}
`

/**
 * __useHistoricoResultadosExamesQuery__
 *
 * To run a query within a React component, call `useHistoricoResultadosExamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoResultadosExamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoResultadosExamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoResultadosExamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoResultadosExamesQuery,
    Types.HistoricoResultadosExamesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoResultadosExamesQuery, Types.HistoricoResultadosExamesQueryVariables>(
    HistoricoResultadosExamesDocument,
    baseOptions
  )
}
export function useHistoricoResultadosExamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoResultadosExamesQuery,
    Types.HistoricoResultadosExamesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoResultadosExamesQuery,
    Types.HistoricoResultadosExamesQueryVariables
  >(HistoricoResultadosExamesDocument, baseOptions)
}
export type HistoricoResultadosExamesQueryHookResult = ReturnType<typeof useHistoricoResultadosExamesQuery>
export type HistoricoResultadosExamesLazyQueryHookResult = ReturnType<typeof useHistoricoResultadosExamesLazyQuery>
export type HistoricoResultadosExamesQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoResultadosExamesQuery,
  Types.HistoricoResultadosExamesQueryVariables
>
export const DadosEscutaPreAtendimentoDocument = gql`
  query DadosEscutaPreAtendimento($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      finalizadoEm
      classificacaoRisco
      evolucaoSubjetivo {
        id
        descricao
        ciaps {
          id
          ciap {
            ...Ciap
          }
          nota
        }
      }
      procedimentosAtendimento {
        id
        procedimento {
          id
          descricao
          codigo
          tipoProcedimento
        }
      }
      lotacao {
        id
        cbo {
          id
          nome
        }
        profissional {
          id
          nome
        }
        equipe {
          id
          nome
        }
      }
    }
  }
  ${CiapFragmentDoc}
`

/**
 * __useDadosEscutaPreAtendimentoQuery__
 *
 * To run a query within a React component, call `useDadosEscutaPreAtendimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDadosEscutaPreAtendimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDadosEscutaPreAtendimentoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDadosEscutaPreAtendimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DadosEscutaPreAtendimentoQuery,
    Types.DadosEscutaPreAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.DadosEscutaPreAtendimentoQuery, Types.DadosEscutaPreAtendimentoQueryVariables>(
    DadosEscutaPreAtendimentoDocument,
    baseOptions
  )
}
export function useDadosEscutaPreAtendimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DadosEscutaPreAtendimentoQuery,
    Types.DadosEscutaPreAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DadosEscutaPreAtendimentoQuery,
    Types.DadosEscutaPreAtendimentoQueryVariables
  >(DadosEscutaPreAtendimentoDocument, baseOptions)
}
export type DadosEscutaPreAtendimentoQueryHookResult = ReturnType<typeof useDadosEscutaPreAtendimentoQuery>
export type DadosEscutaPreAtendimentoLazyQueryHookResult = ReturnType<typeof useDadosEscutaPreAtendimentoLazyQuery>
export type DadosEscutaPreAtendimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.DadosEscutaPreAtendimentoQuery,
  Types.DadosEscutaPreAtendimentoQueryVariables
>
export const EscutaPreAtendimentoMaisRecenteDocument = gql`
  query EscutaPreAtendimentoMaisRecente($input: EscutaOrPreAtendimentoOfAtendimentoInput!) {
    escutaOrPreAtendimentoOfAtendimento(input: $input)
  }
`

/**
 * __useEscutaPreAtendimentoMaisRecenteQuery__
 *
 * To run a query within a React component, call `useEscutaPreAtendimentoMaisRecenteQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscutaPreAtendimentoMaisRecenteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscutaPreAtendimentoMaisRecenteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEscutaPreAtendimentoMaisRecenteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EscutaPreAtendimentoMaisRecenteQuery,
    Types.EscutaPreAtendimentoMaisRecenteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EscutaPreAtendimentoMaisRecenteQuery,
    Types.EscutaPreAtendimentoMaisRecenteQueryVariables
  >(EscutaPreAtendimentoMaisRecenteDocument, baseOptions)
}
export function useEscutaPreAtendimentoMaisRecenteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EscutaPreAtendimentoMaisRecenteQuery,
    Types.EscutaPreAtendimentoMaisRecenteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EscutaPreAtendimentoMaisRecenteQuery,
    Types.EscutaPreAtendimentoMaisRecenteQueryVariables
  >(EscutaPreAtendimentoMaisRecenteDocument, baseOptions)
}
export type EscutaPreAtendimentoMaisRecenteQueryHookResult = ReturnType<typeof useEscutaPreAtendimentoMaisRecenteQuery>
export type EscutaPreAtendimentoMaisRecenteLazyQueryHookResult = ReturnType<
  typeof useEscutaPreAtendimentoMaisRecenteLazyQuery
>
export type EscutaPreAtendimentoMaisRecenteQueryResult = ApolloReactCommon.QueryResult<
  Types.EscutaPreAtendimentoMaisRecenteQuery,
  Types.EscutaPreAtendimentoMaisRecenteQueryVariables
>
export const DadosUltimosContatosDocument = gql`
  query DadosUltimosContatos($input: HistoricoQueryInput!) {
    historico(input: $input) {
      content {
        codigoUnicoRegistro
        origemAtendimento
        dataAtendimento
        turno
        tipoAtendProf
        tipoApresentacao
        isCancelado
        coSubtipoAtendimento
        classificacaoRisco
        idadeGestacional {
          dias
          semanas
        }
        dadosClinicos
        condicoesAvaliadas
        examesRealizadosZika
        tipoConsultaOdonto
        isAtendRecente
        idAtendRecente
        cpfCnsCidadao
        hasObservacao
        hasCuidadoCompartilhado
        cuidadoCompartilhado {
          ...HistoricoCuidadoCompartilhadoHeader
        }
        cuidadoCompartilhadoEvolucao {
          ...HistoricoCuidadoCompartilhadoEvolucaoHeader
        }
        ...CuidadoCompartilhadoDw
      }
    }
  }
  ${HistoricoCuidadoCompartilhadoHeaderFragmentDoc}
  ${HistoricoCuidadoCompartilhadoEvolucaoHeaderFragmentDoc}
  ${CuidadoCompartilhadoDwFragmentDoc}
`

/**
 * __useDadosUltimosContatosQuery__
 *
 * To run a query within a React component, call `useDadosUltimosContatosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDadosUltimosContatosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDadosUltimosContatosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDadosUltimosContatosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DadosUltimosContatosQuery,
    Types.DadosUltimosContatosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.DadosUltimosContatosQuery, Types.DadosUltimosContatosQueryVariables>(
    DadosUltimosContatosDocument,
    baseOptions
  )
}
export function useDadosUltimosContatosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DadosUltimosContatosQuery,
    Types.DadosUltimosContatosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.DadosUltimosContatosQuery, Types.DadosUltimosContatosQueryVariables>(
    DadosUltimosContatosDocument,
    baseOptions
  )
}
export type DadosUltimosContatosQueryHookResult = ReturnType<typeof useDadosUltimosContatosQuery>
export type DadosUltimosContatosLazyQueryHookResult = ReturnType<typeof useDadosUltimosContatosLazyQuery>
export type DadosUltimosContatosQueryResult = ApolloReactCommon.QueryResult<
  Types.DadosUltimosContatosQuery,
  Types.DadosUltimosContatosQueryVariables
>
export const VideochamadaAtendimentoDocument = gql`
  query VideochamadaAtendimento($atendimentoId: ID!) {
    atendimento(id: $atendimentoId) {
      id
      agendado {
        id
        lotacaoAgendada {
          id
        }
        profissionalParticipante {
          id
          nome
          lotacao {
            id
            equipe {
              id
              nome
              ine
            }
            cbo {
              id
              nome
            }
          }
          email
        }
        videochamada {
          id
          uuid
        }
      }
    }
  }
`

/**
 * __useVideochamadaAtendimentoQuery__
 *
 * To run a query within a React component, call `useVideochamadaAtendimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideochamadaAtendimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideochamadaAtendimentoQuery({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useVideochamadaAtendimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VideochamadaAtendimentoQuery,
    Types.VideochamadaAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.VideochamadaAtendimentoQuery, Types.VideochamadaAtendimentoQueryVariables>(
    VideochamadaAtendimentoDocument,
    baseOptions
  )
}
export function useVideochamadaAtendimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VideochamadaAtendimentoQuery,
    Types.VideochamadaAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.VideochamadaAtendimentoQuery, Types.VideochamadaAtendimentoQueryVariables>(
    VideochamadaAtendimentoDocument,
    baseOptions
  )
}
export type VideochamadaAtendimentoQueryHookResult = ReturnType<typeof useVideochamadaAtendimentoQuery>
export type VideochamadaAtendimentoLazyQueryHookResult = ReturnType<typeof useVideochamadaAtendimentoLazyQuery>
export type VideochamadaAtendimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.VideochamadaAtendimentoQuery,
  Types.VideochamadaAtendimentoQueryVariables
>
export const AtendimentoHeaderCidadaoDocument = gql`
  query AtendimentoHeaderCidadao($cidadaoId: ID!) {
    cidadao(id: $cidadaoId) {
      id
      cpf
      cns
      nome
      nomeSocial
      dataNascimento
      sexo
      nomeMae
      identidadeGeneroDbEnum
      ativo
      faleceu
      unificado
      unificacaoBase
    }
  }
`

/**
 * __useAtendimentoHeaderCidadaoQuery__
 *
 * To run a query within a React component, call `useAtendimentoHeaderCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoHeaderCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoHeaderCidadaoQuery({
 *   variables: {
 *      cidadaoId: // value for 'cidadaoId'
 *   },
 * });
 */
export function useAtendimentoHeaderCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtendimentoHeaderCidadaoQuery,
    Types.AtendimentoHeaderCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AtendimentoHeaderCidadaoQuery, Types.AtendimentoHeaderCidadaoQueryVariables>(
    AtendimentoHeaderCidadaoDocument,
    baseOptions
  )
}
export function useAtendimentoHeaderCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentoHeaderCidadaoQuery,
    Types.AtendimentoHeaderCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AtendimentoHeaderCidadaoQuery,
    Types.AtendimentoHeaderCidadaoQueryVariables
  >(AtendimentoHeaderCidadaoDocument, baseOptions)
}
export type AtendimentoHeaderCidadaoQueryHookResult = ReturnType<typeof useAtendimentoHeaderCidadaoQuery>
export type AtendimentoHeaderCidadaoLazyQueryHookResult = ReturnType<typeof useAtendimentoHeaderCidadaoLazyQuery>
export type AtendimentoHeaderCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoHeaderCidadaoQuery,
  Types.AtendimentoHeaderCidadaoQueryVariables
>
export const ConfiguracaoRndsDocument = gql`
  query configuracaoRnds {
    conexao {
      internet {
        habilitado
      }
    }
    configuracaoCertificadoHabilitado {
      id
      rndsHabilitado
    }
  }
`

/**
 * __useConfiguracaoRndsQuery__
 *
 * To run a query within a React component, call `useConfiguracaoRndsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoRndsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoRndsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoRndsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ConfiguracaoRndsQuery, Types.ConfiguracaoRndsQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoRndsQuery, Types.ConfiguracaoRndsQueryVariables>(
    ConfiguracaoRndsDocument,
    baseOptions
  )
}
export function useConfiguracaoRndsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ConfiguracaoRndsQuery, Types.ConfiguracaoRndsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracaoRndsQuery, Types.ConfiguracaoRndsQueryVariables>(
    ConfiguracaoRndsDocument,
    baseOptions
  )
}
export type ConfiguracaoRndsQueryHookResult = ReturnType<typeof useConfiguracaoRndsQuery>
export type ConfiguracaoRndsLazyQueryHookResult = ReturnType<typeof useConfiguracaoRndsLazyQuery>
export type ConfiguracaoRndsQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoRndsQuery,
  Types.ConfiguracaoRndsQueryVariables
>
export const BuscaListagemHistoricoClinicoDocument = gql`
  query BuscaListagemHistoricoClinico($input: HistoricoQueryInput!) {
    historico(input: $input) {
      content {
        idAtendRecente
        idAtendProcessado
        codigoUnicoRegistro
        dataAtendimento
        turno
        tipoApresentacao
        tipoAtendProf
        isCancelado
        coSubtipoAtendimento
        origemAtendimento
        cpfCnsCidadao
        classificacaoRisco
        idadeGestacional {
          dias
          semanas
        }
        isAtendRecente
        condicoesAvaliadas
        examesRealizadosZika
        profissional {
          id
          nome
        }
        cbo {
          id
          nome
          cbo2002
        }
        estagiario {
          id
          nome
        }
        cboEstagio {
          id
          nome
          cbo2002
        }
        unidadeSaude {
          id
          nome
          cnes
        }
        equipe {
          id
          ine
          nome
        }
        cnsProfissional
        hasPrescricaoMedicamento
        hasOrientacao
        hasAtestado
        hasLembrete
        hasAlergia
        hasSolicitacaoExame
        hasResultadoExame
        hasEncaminhamento
        hasEncaminhamentoEspecializado
        hasProcedimentoClinico
        hasMarcadorConsumoAlimentar
        hasCuidadoCompartilhado
        dadosClinicos
        condicoesVacinacao {
          idAtend
          isViajante
          isGestante
          isPuerpera
          isComunicanteHanseniase
        }
        tipoConsultaOdonto
        fichasConcatenadas {
          uuidProcedimento
          uuidZika
        }
        hasObservacao
        isAtendObsFinalizado
        inicioAtendimentoObservacao
        fimAtendimentoObservacao
        cuidadoCompartilhadoEvolucao {
          ...HistoricoCuidadoCompartilhadoEvolucaoHeader
        }
        cuidadoCompartilhado {
          ...HistoricoCuidadoCompartilhadoHeader
        }
        nomeFinalizadorObservacao
        cnsFinalizadorObservacao
        cboFinalizadorObservacao
        ubsFinalizadorObservacao
        ineFinalizadorObservacao
        ...CuidadoCompartilhadoDw
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${HistoricoCuidadoCompartilhadoEvolucaoHeaderFragmentDoc}
  ${HistoricoCuidadoCompartilhadoHeaderFragmentDoc}
  ${CuidadoCompartilhadoDwFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useBuscaListagemHistoricoClinicoQuery__
 *
 * To run a query within a React component, call `useBuscaListagemHistoricoClinicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaListagemHistoricoClinicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaListagemHistoricoClinicoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuscaListagemHistoricoClinicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaListagemHistoricoClinicoQuery,
    Types.BuscaListagemHistoricoClinicoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.BuscaListagemHistoricoClinicoQuery,
    Types.BuscaListagemHistoricoClinicoQueryVariables
  >(BuscaListagemHistoricoClinicoDocument, baseOptions)
}
export function useBuscaListagemHistoricoClinicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaListagemHistoricoClinicoQuery,
    Types.BuscaListagemHistoricoClinicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BuscaListagemHistoricoClinicoQuery,
    Types.BuscaListagemHistoricoClinicoQueryVariables
  >(BuscaListagemHistoricoClinicoDocument, baseOptions)
}
export type BuscaListagemHistoricoClinicoQueryHookResult = ReturnType<typeof useBuscaListagemHistoricoClinicoQuery>
export type BuscaListagemHistoricoClinicoLazyQueryHookResult = ReturnType<
  typeof useBuscaListagemHistoricoClinicoLazyQuery
>
export type BuscaListagemHistoricoClinicoQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaListagemHistoricoClinicoQuery,
  Types.BuscaListagemHistoricoClinicoQueryVariables
>
export const HistoricoAtendimentoDomiciliarAdDocument = gql`
  query HistoricoAtendimentoDomiciliarAD($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      evolucaoObjetivo {
        id
        descricao
      }
      ...HistoricoSubjetivoPecFragment
      medicoes {
        id
        valorPeso
        valorAltura
        valorFrequenciaCardiaca
        valorPressaoArterial
        valorPerimetroCefalico
        valorFrequenciaRespiratoria
        valorTemperatura
        valorSaturacaoO2
        valorGlicemia
        tipoGlicemia
        valorImc
        perimetroPanturrilha
        valorCircunferenciaAbdominal
        dum
        valorVacinacaoEmDia
        medicaoAnterior
        dataMedicao
      }
      evolucaoAvaliacao {
        id
        descricao
        ciapsCids {
          id
          ciap {
            ...Ciap
          }
          cid10 {
            ...Cid10
          }
          nota
          isCiapCidNaListaDeProblemas
        }
      }
      evolucaoPlano {
        id
        descricao
        intervencoesProcedimentos {
          id
          ciap {
            ...Ciap
          }
          procedimento {
            id
            descricao
            codigo
          }
          nota
        }
      }
      atendimentoProfissionalAD {
        id
        origem
        modalidade
        cid10Principal {
          id
          codigo
          nome
        }
        cid10Secundario1 {
          id
          codigo
          nome
        }
        cid10Secundario2 {
          id
          codigo
          nome
        }
        condicoesAvaliadasAD {
          id
          questao {
            id
            descricao
          }
          situacaoAtiva
        }
        conduta
      }
      cidadaoAD {
        id
        dataObito
        numeroDocumentoObito
      }
      procedimentosAtendimento {
        id
        procedimento {
          id
          descricao
          codigo
        }
        cid10Principal {
          id
          codigo
          nome
        }
      }
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoAtendimentoDomiciliarAdQuery__
 *
 * To run a query within a React component, call `useHistoricoAtendimentoDomiciliarAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAtendimentoDomiciliarAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAtendimentoDomiciliarAdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAtendimentoDomiciliarAdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAtendimentoDomiciliarAdQuery,
    Types.HistoricoAtendimentoDomiciliarAdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAtendimentoDomiciliarAdQuery,
    Types.HistoricoAtendimentoDomiciliarAdQueryVariables
  >(HistoricoAtendimentoDomiciliarAdDocument, baseOptions)
}
export function useHistoricoAtendimentoDomiciliarAdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAtendimentoDomiciliarAdQuery,
    Types.HistoricoAtendimentoDomiciliarAdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAtendimentoDomiciliarAdQuery,
    Types.HistoricoAtendimentoDomiciliarAdQueryVariables
  >(HistoricoAtendimentoDomiciliarAdDocument, baseOptions)
}
export type HistoricoAtendimentoDomiciliarAdQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoDomiciliarAdQuery
>
export type HistoricoAtendimentoDomiciliarAdLazyQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoDomiciliarAdLazyQuery
>
export type HistoricoAtendimentoDomiciliarAdQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAtendimentoDomiciliarAdQuery,
  Types.HistoricoAtendimentoDomiciliarAdQueryVariables
>
export const HistoricoAtendimentoDomiciliarDwDocument = gql`
  query HistoricoAtendimentoDomiciliarDw($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoDomiciliar(input: $input) {
      id
      fatosAtendDomCondicaoAval {
        id
        dimensaoCiap2 {
          id
          codigoCiap
          nomeCiap
        }
        dimensaoCid10 {
          id
          codigoCid10
          nomeCid10
        }
      }
      dimensaoProfissional1 {
        id
        nome
      }
      dimensaoProfissional2 {
        id
        nome
      }
      dimensaoCbo1 {
        id
        nome
      }
      dimensaoCbo2 {
        id
        nome
      }
      dimensaoUnidadeSaude1 {
        id
        nome
        cnes
      }
      dimensaoUnidadeSaude2 {
        id
        nome
        cnes
      }
      dimensaoEquipe1 {
        id
        nome
        ine
      }
      dimensaoEquipe2 {
        id
        nome
        ine
      }
      dimensaoLocalAtendimento {
        id
        descricao
      }
      dimensaoModalidadeAD {
        id
        descricao
      }
      dimensaoCondutaAD {
        id
        conduta
      }
      condicoesAvaliadas
      procedimentos {
        id
        dimensaoProcedimento {
          id
          codigoProcedimento
          descricaoProcedimento
        }
      }
    }
  }
`

/**
 * __useHistoricoAtendimentoDomiciliarDwQuery__
 *
 * To run a query within a React component, call `useHistoricoAtendimentoDomiciliarDwQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAtendimentoDomiciliarDwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAtendimentoDomiciliarDwQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAtendimentoDomiciliarDwQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAtendimentoDomiciliarDwQuery,
    Types.HistoricoAtendimentoDomiciliarDwQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAtendimentoDomiciliarDwQuery,
    Types.HistoricoAtendimentoDomiciliarDwQueryVariables
  >(HistoricoAtendimentoDomiciliarDwDocument, baseOptions)
}
export function useHistoricoAtendimentoDomiciliarDwLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAtendimentoDomiciliarDwQuery,
    Types.HistoricoAtendimentoDomiciliarDwQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAtendimentoDomiciliarDwQuery,
    Types.HistoricoAtendimentoDomiciliarDwQueryVariables
  >(HistoricoAtendimentoDomiciliarDwDocument, baseOptions)
}
export type HistoricoAtendimentoDomiciliarDwQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoDomiciliarDwQuery
>
export type HistoricoAtendimentoDomiciliarDwLazyQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoDomiciliarDwLazyQuery
>
export type HistoricoAtendimentoDomiciliarDwQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAtendimentoDomiciliarDwQuery,
  Types.HistoricoAtendimentoDomiciliarDwQueryVariables
>
export const HistoricoConsultaPecDocument = gql`
  query HistoricoConsultaPec($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      ...HistoricoSubjetivoPecFragment
      ...HistoricoObjetivoPecFragment
      medicoes {
        id
        medicaoAnterior
        dataMedicao
      }
      ...HistoricoPlanoCuidadoFragment
      ...HistoricoAvaliacaoPecFragment
      ...HistoricoPlanoPecFragment
      ...HistoricoAntecedentesPecFragment
      ...HistoricoRegistrosAnterioresVacinacaoFragment
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${HistoricoObjetivoPecFragmentFragmentDoc}
  ${HistoricoPlanoCuidadoFragmentFragmentDoc}
  ${HistoricoAvaliacaoPecFragmentFragmentDoc}
  ${HistoricoPlanoPecFragmentFragmentDoc}
  ${HistoricoAntecedentesPecFragmentFragmentDoc}
  ${HistoricoRegistrosAnterioresVacinacaoFragmentFragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoConsultaPecQuery__
 *
 * To run a query within a React component, call `useHistoricoConsultaPecQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoConsultaPecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoConsultaPecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoConsultaPecQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoConsultaPecQuery,
    Types.HistoricoConsultaPecQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoConsultaPecQuery, Types.HistoricoConsultaPecQueryVariables>(
    HistoricoConsultaPecDocument,
    baseOptions
  )
}
export function useHistoricoConsultaPecLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoConsultaPecQuery,
    Types.HistoricoConsultaPecQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoConsultaPecQuery, Types.HistoricoConsultaPecQueryVariables>(
    HistoricoConsultaPecDocument,
    baseOptions
  )
}
export type HistoricoConsultaPecQueryHookResult = ReturnType<typeof useHistoricoConsultaPecQuery>
export type HistoricoConsultaPecLazyQueryHookResult = ReturnType<typeof useHistoricoConsultaPecLazyQuery>
export type HistoricoConsultaPecQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoConsultaPecQuery,
  Types.HistoricoConsultaPecQueryVariables
>
export const HistoricoAtendimentoIndividualDwDocument = gql`
  query HistoricoAtendimentoIndividualDw($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoIndividualDw(input: $input) {
      id
      stFicouEmObservacao
      stVacinacaoEmDia
      nuPeso
      nuAltura
      nuImc
      nuPerimetroCefalico
      dum
      stGravidezPlanejada
      nuIdadeGestacional
      nuGestasPrevias
      nuPartos
      dimensaoAleitamento {
        id
        descricao
      }
      dimensaoModalidadeAd {
        id
        descricao
      }
      dimensaoRacionalidadeSaude {
        id
        descricao
      }
      cidsCiapsAvaliados
      examesSolicitados {
        id
        codigoProcedimento
        descricaoProcedimento
      }
      examesAvaliados {
        id
        codigoProcedimento
        descricaoProcedimento
        dimensaoProcedimentoReferencia {
          id
          codigoProcedimento
          descricaoProcedimento
        }
      }
      resultadosExamesEspecificos {
        id
        dataSolicitacao
        dataRealizacao
        dataResultado
        dataResultadoData
        nuResultadoValor
        nuResultadoDia
        nuResultadoSemana
        dimensaoProcedimento {
          id
          codigoProcedimento
          descricaoProcedimento
          dimensaoProcedimentoReferencia {
            id
            codigoProcedimento
            descricaoProcedimento
          }
        }
        resultadoPuericultura
      }
      nasfPolo
      stNasf
      condutaDesfecho
      encaminhamentos
      dimensaoProfissional1 {
        id
        nome
      }
      dimensaoProfissional2 {
        id
        nome
      }
      dimensaoCbo1 {
        id
        nome
      }
      dimensaoCbo2 {
        id
        nome
      }
      dimensaoUnidadeSaude1 {
        id
        nome
        cnes
      }
      dimensaoUnidadeSaude2 {
        id
        nome
        cnes
      }
      dimensaoEquipe1 {
        id
        nome
        ine
      }
      dimensaoEquipe2 {
        id
        nome
        ine
      }
      dimensaoLocalAtendimento {
        id
        descricao
      }
      dimProfFinalizadorObs {
        id
        nome
      }
      dimCboFinalizadorObs {
        id
        nome
      }
      dimUbsFinalizadorObs {
        id
        nome
        cnes
      }
      dimEquipeFinalizadorObs {
        id
        nome
        ine
      }
      dimTipoParticipacaoCidadao {
        id
        descricao
      }
      dimTipoParticipacaoProfConvidado {
        id
        descricao
      }
      medicamentos {
        id
        unidadeMedidaDuracao
        unidadeFrequenciaDose
        tipoFrequencia
        duracao
        quantidade
        dataInicioTratamento
        dose
        doseUnica
        usoContinuo
        doseFrequencia
        frequenciaDose
        dimensaoCatmat {
          id
          principioAtivo
          concentracao
          dimensaoFormaFarmaceutica {
            id
            identificador
            nome
            valido
          }
        }
        dimensaoViaAdministracao {
          id
          nome
        }
      }
    }
  }
`

/**
 * __useHistoricoAtendimentoIndividualDwQuery__
 *
 * To run a query within a React component, call `useHistoricoAtendimentoIndividualDwQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAtendimentoIndividualDwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAtendimentoIndividualDwQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAtendimentoIndividualDwQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAtendimentoIndividualDwQuery,
    Types.HistoricoAtendimentoIndividualDwQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAtendimentoIndividualDwQuery,
    Types.HistoricoAtendimentoIndividualDwQueryVariables
  >(HistoricoAtendimentoIndividualDwDocument, baseOptions)
}
export function useHistoricoAtendimentoIndividualDwLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAtendimentoIndividualDwQuery,
    Types.HistoricoAtendimentoIndividualDwQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAtendimentoIndividualDwQuery,
    Types.HistoricoAtendimentoIndividualDwQueryVariables
  >(HistoricoAtendimentoIndividualDwDocument, baseOptions)
}
export type HistoricoAtendimentoIndividualDwQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoIndividualDwQuery
>
export type HistoricoAtendimentoIndividualDwLazyQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoIndividualDwLazyQuery
>
export type HistoricoAtendimentoIndividualDwQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAtendimentoIndividualDwQuery,
  Types.HistoricoAtendimentoIndividualDwQueryVariables
>
export const HistoricoConcatenadaProcedimentoDocument = gql`
  query HistoricoConcatenadaProcedimento($input: HistoricoDetailQueryInput!) {
    historicoProcedimentoDw(input: $input) {
      id
      procedimentos {
        id
        codigoProcedimento
        descricaoProcedimento
      }
    }
  }
`

/**
 * __useHistoricoConcatenadaProcedimentoQuery__
 *
 * To run a query within a React component, call `useHistoricoConcatenadaProcedimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoConcatenadaProcedimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoConcatenadaProcedimentoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoConcatenadaProcedimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoConcatenadaProcedimentoQuery,
    Types.HistoricoConcatenadaProcedimentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoConcatenadaProcedimentoQuery,
    Types.HistoricoConcatenadaProcedimentoQueryVariables
  >(HistoricoConcatenadaProcedimentoDocument, baseOptions)
}
export function useHistoricoConcatenadaProcedimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoConcatenadaProcedimentoQuery,
    Types.HistoricoConcatenadaProcedimentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoConcatenadaProcedimentoQuery,
    Types.HistoricoConcatenadaProcedimentoQueryVariables
  >(HistoricoConcatenadaProcedimentoDocument, baseOptions)
}
export type HistoricoConcatenadaProcedimentoQueryHookResult = ReturnType<
  typeof useHistoricoConcatenadaProcedimentoQuery
>
export type HistoricoConcatenadaProcedimentoLazyQueryHookResult = ReturnType<
  typeof useHistoricoConcatenadaProcedimentoLazyQuery
>
export type HistoricoConcatenadaProcedimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoConcatenadaProcedimentoQuery,
  Types.HistoricoConcatenadaProcedimentoQueryVariables
>
export const HistoricoConcatenadaZikaDocument = gql`
  query HistoricoConcatenadaZika($input: HistoricoDetailQueryInput!) {
    historicoZikaDetail(input: $input) {
      examesDeImagem {
        id
        nome
        resultado
        dataRealizacao
      }
      outrosExames {
        id
        nome
        resultado
        dataRealizacao
      }
    }
  }
`

/**
 * __useHistoricoConcatenadaZikaQuery__
 *
 * To run a query within a React component, call `useHistoricoConcatenadaZikaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoConcatenadaZikaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoConcatenadaZikaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoConcatenadaZikaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoConcatenadaZikaQuery,
    Types.HistoricoConcatenadaZikaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoConcatenadaZikaQuery, Types.HistoricoConcatenadaZikaQueryVariables>(
    HistoricoConcatenadaZikaDocument,
    baseOptions
  )
}
export function useHistoricoConcatenadaZikaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoConcatenadaZikaQuery,
    Types.HistoricoConcatenadaZikaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoConcatenadaZikaQuery,
    Types.HistoricoConcatenadaZikaQueryVariables
  >(HistoricoConcatenadaZikaDocument, baseOptions)
}
export type HistoricoConcatenadaZikaQueryHookResult = ReturnType<typeof useHistoricoConcatenadaZikaQuery>
export type HistoricoConcatenadaZikaLazyQueryHookResult = ReturnType<typeof useHistoricoConcatenadaZikaLazyQuery>
export type HistoricoConcatenadaZikaQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoConcatenadaZikaQuery,
  Types.HistoricoConcatenadaZikaQueryVariables
>
export const HistoricoPreNatalPecDocument = gql`
  query HistoricoPreNatalPec($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      ...HistoricoSubjetivoPecFragment
      ...HistoricoObjetivoPecFragment
      medicoes {
        id
        valorAlturaUterina
        valorBatimentoCardiacoFetal
        medicaoAnterior
        dataMedicao
      }
      ...HistoricoPlanoCuidadoFragment
      atendimentoProfissionalPreNatal {
        id
        gravidezPlanejada
        movimentacaoFetal
        tipoEdema
      }
      ...HistoricoAvaliacaoPecFragment
      ...HistoricoPlanoPecFragment
      ...HistoricoAntecedentesPecFragment
      ...HistoricoRegistrosAnterioresVacinacaoFragment
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${HistoricoObjetivoPecFragmentFragmentDoc}
  ${HistoricoPlanoCuidadoFragmentFragmentDoc}
  ${HistoricoAvaliacaoPecFragmentFragmentDoc}
  ${HistoricoPlanoPecFragmentFragmentDoc}
  ${HistoricoAntecedentesPecFragmentFragmentDoc}
  ${HistoricoRegistrosAnterioresVacinacaoFragmentFragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoPreNatalPecQuery__
 *
 * To run a query within a React component, call `useHistoricoPreNatalPecQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoPreNatalPecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoPreNatalPecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoPreNatalPecQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoPreNatalPecQuery,
    Types.HistoricoPreNatalPecQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoPreNatalPecQuery, Types.HistoricoPreNatalPecQueryVariables>(
    HistoricoPreNatalPecDocument,
    baseOptions
  )
}
export function useHistoricoPreNatalPecLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoPreNatalPecQuery,
    Types.HistoricoPreNatalPecQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoPreNatalPecQuery, Types.HistoricoPreNatalPecQueryVariables>(
    HistoricoPreNatalPecDocument,
    baseOptions
  )
}
export type HistoricoPreNatalPecQueryHookResult = ReturnType<typeof useHistoricoPreNatalPecQuery>
export type HistoricoPreNatalPecLazyQueryHookResult = ReturnType<typeof useHistoricoPreNatalPecLazyQuery>
export type HistoricoPreNatalPecQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoPreNatalPecQuery,
  Types.HistoricoPreNatalPecQueryVariables
>
export const HistoricoPuericulturaPecDocument = gql`
  query HistoricoPuericulturaPec($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      ...HistoricoSubjetivoPecFragment
      ...HistoricoObjetivoPecFragment
      medicoes {
        id
        valorAlturaUterina
        valorBatimentoCardiacoFetal
        medicaoAnterior
        dataMedicao
      }
      ...HistoricoPlanoCuidadoFragment
      atendimentoProfissionalPuericultura {
        id
        aleitamentoMaterno
        neuroAlteracaoFenotipica {
          id
          statusAvaliacao
          alteracaoFenotipicaDetalhe {
            id
            descricao
          }
        }
        neuroFatorRisco {
          id
          statusAvaliacao
          fatorRiscoDetalhe {
            id
            descricao
          }
        }
        neuroMarco {
          id
          statusAvaliacao
          marcoDetalhe {
            id
            titulo
            faixaEtaria {
              id
              descricao
            }
          }
          anosIdadeRegistro
          mesesIdadeRegistro
        }
      }
      ...HistoricoAvaliacaoPecFragment
      ...HistoricoPlanoPecFragment
      ...HistoricoAntecedentesPecFragment
      ...HistoricoRegistrosAnterioresVacinacaoFragment
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${HistoricoObjetivoPecFragmentFragmentDoc}
  ${HistoricoPlanoCuidadoFragmentFragmentDoc}
  ${HistoricoAvaliacaoPecFragmentFragmentDoc}
  ${HistoricoPlanoPecFragmentFragmentDoc}
  ${HistoricoAntecedentesPecFragmentFragmentDoc}
  ${HistoricoRegistrosAnterioresVacinacaoFragmentFragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoPuericulturaPecQuery__
 *
 * To run a query within a React component, call `useHistoricoPuericulturaPecQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoPuericulturaPecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoPuericulturaPecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoPuericulturaPecQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoPuericulturaPecQuery,
    Types.HistoricoPuericulturaPecQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoPuericulturaPecQuery, Types.HistoricoPuericulturaPecQueryVariables>(
    HistoricoPuericulturaPecDocument,
    baseOptions
  )
}
export function useHistoricoPuericulturaPecLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoPuericulturaPecQuery,
    Types.HistoricoPuericulturaPecQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoPuericulturaPecQuery,
    Types.HistoricoPuericulturaPecQueryVariables
  >(HistoricoPuericulturaPecDocument, baseOptions)
}
export type HistoricoPuericulturaPecQueryHookResult = ReturnType<typeof useHistoricoPuericulturaPecQuery>
export type HistoricoPuericulturaPecLazyQueryHookResult = ReturnType<typeof useHistoricoPuericulturaPecLazyQuery>
export type HistoricoPuericulturaPecQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoPuericulturaPecQuery,
  Types.HistoricoPuericulturaPecQueryVariables
>
export const HistoricoPuerperioPecDocument = gql`
  query HistoricoPuerperioPec($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      ...HistoricoSubjetivoPecFragment
      ...HistoricoObjetivoPecFragment
      medicoes {
        id
        medicaoAnterior
        dataMedicao
      }
      ...HistoricoPlanoCuidadoFragment
      ...HistoricoAvaliacaoPecFragment
      ...HistoricoPlanoPecFragment
      ...HistoricoAntecedentesPecFragment
      ...HistoricoRegistrosAnterioresVacinacaoFragment
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${HistoricoObjetivoPecFragmentFragmentDoc}
  ${HistoricoPlanoCuidadoFragmentFragmentDoc}
  ${HistoricoAvaliacaoPecFragmentFragmentDoc}
  ${HistoricoPlanoPecFragmentFragmentDoc}
  ${HistoricoAntecedentesPecFragmentFragmentDoc}
  ${HistoricoRegistrosAnterioresVacinacaoFragmentFragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoPuerperioPecQuery__
 *
 * To run a query within a React component, call `useHistoricoPuerperioPecQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoPuerperioPecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoPuerperioPecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoPuerperioPecQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoPuerperioPecQuery,
    Types.HistoricoPuerperioPecQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoPuerperioPecQuery, Types.HistoricoPuerperioPecQueryVariables>(
    HistoricoPuerperioPecDocument,
    baseOptions
  )
}
export function useHistoricoPuerperioPecLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoPuerperioPecQuery,
    Types.HistoricoPuerperioPecQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoPuerperioPecQuery, Types.HistoricoPuerperioPecQueryVariables>(
    HistoricoPuerperioPecDocument,
    baseOptions
  )
}
export type HistoricoPuerperioPecQueryHookResult = ReturnType<typeof useHistoricoPuerperioPecQuery>
export type HistoricoPuerperioPecLazyQueryHookResult = ReturnType<typeof useHistoricoPuerperioPecLazyQuery>
export type HistoricoPuerperioPecQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoPuerperioPecQuery,
  Types.HistoricoPuerperioPecQueryVariables
>
export const HistoricoAtendimentoOdontologicoDwDocument = gql`
  query HistoricoAtendimentoOdontologicoDw($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoOdontoDw(input: $input) {
      id
      isPacienteNecessidadesEspeciais
      isGestante
      cidsCiapsAvaliados
      vigilanciaSaudeBucal
      fornecimento
      conduta
      encaminhamento
      procedimentos {
        id
        dimensaoProcedimento {
          id
          codigoProcedimento
          descricaoProcedimento
        }
        quantidadeProcedimentos
      }
      dimensaoProfissional1 {
        id
        nome
      }
      dimensaoProfissional2 {
        id
        nome
      }
      dimensaoCbo1 {
        id
        nome
      }
      dimensaoCbo2 {
        id
        nome
      }
      dimensaoUnidadeSaude1 {
        id
        nome
        cnes
      }
      dimensaoUnidadeSaude2 {
        id
        nome
        cnes
      }
      dimensaoEquipe1 {
        id
        nome
        ine
      }
      dimensaoEquipe2 {
        id
        nome
        ine
      }
      dimensaoLocalAtendimento {
        id
        descricao
      }
      dimTipoParticipacaoCidadao {
        id
        descricao
      }
      dimTipoParticipacaoProfConvidado {
        id
        descricao
      }
      medicamentos {
        id
        unidadeMedidaDuracao
        unidadeFrequenciaDose
        tipoFrequencia
        duracao
        quantidade
        dataInicioTratamento
        dose
        doseUnica
        usoContinuo
        doseFrequencia
        frequenciaDose
        dimensaoCatmat {
          id
          principioAtivo
          concentracao
          dimensaoFormaFarmaceutica {
            id
            identificador
            nome
            valido
          }
        }
        dimensaoViaAdministracao {
          id
          nome
        }
      }
    }
  }
`

/**
 * __useHistoricoAtendimentoOdontologicoDwQuery__
 *
 * To run a query within a React component, call `useHistoricoAtendimentoOdontologicoDwQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAtendimentoOdontologicoDwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAtendimentoOdontologicoDwQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAtendimentoOdontologicoDwQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAtendimentoOdontologicoDwQuery,
    Types.HistoricoAtendimentoOdontologicoDwQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAtendimentoOdontologicoDwQuery,
    Types.HistoricoAtendimentoOdontologicoDwQueryVariables
  >(HistoricoAtendimentoOdontologicoDwDocument, baseOptions)
}
export function useHistoricoAtendimentoOdontologicoDwLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAtendimentoOdontologicoDwQuery,
    Types.HistoricoAtendimentoOdontologicoDwQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAtendimentoOdontologicoDwQuery,
    Types.HistoricoAtendimentoOdontologicoDwQueryVariables
  >(HistoricoAtendimentoOdontologicoDwDocument, baseOptions)
}
export type HistoricoAtendimentoOdontologicoDwQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoOdontologicoDwQuery
>
export type HistoricoAtendimentoOdontologicoDwLazyQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoOdontologicoDwLazyQuery
>
export type HistoricoAtendimentoOdontologicoDwQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAtendimentoOdontologicoDwQuery,
  Types.HistoricoAtendimentoOdontologicoDwQueryVariables
>
export const HistoricoAtendimentoOdontoPecDocument = gql`
  query HistoricoAtendimentoOdontoPec($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      evolucaoObjetivo {
        id
        descricao
        necessidadesEspeciais
      }
      ...HistoricoSubjetivoPecFragment
      ...HistoricoObjetivoPecFragment
      medicoes {
        id
        medicaoAnterior
        dataMedicao
      }
      ...HistoricoAvaliacaoPecFragment
      evolucaoAvaliacao {
        id
        necessidadeDeProtese
        tiposVigilanciaBucal
      }
      ...HistoricoPlanoPecFragment
      atendimentoProfissionalOdonto {
        id
        odontograma {
          id
          proteseTotalInferior
          proteseTotalSuperior
          contencao
          aparelho
        }
        evolucoes {
          id
          procedimentos {
            id
            descricao
            codigo
          }
          partesBucais {
            id
            tipoParteBucal
            descricao
            parteBucalEnum
          }
          descricaoParteBucal
          observacao
        }
        periogramaSimplificado {
          id
          avaliacaoSextante1
          avaliacaoSextante2
          avaliacaoSextante3
          avaliacaoSextante4
          avaliacaoSextante5
          avaliacaoSextante6
          observacao
        }
        periogramaCompleto {
          id
          dente {
            id
            parteBucalEnum
          }
          excluido
          profundidadeVestibular
          recessaoVestibular
          profundidadeInterna
          recessaoInterna
          placaDVestibular
          placaVVestibular
          placaMVestibular
          sangramentoDVestibular
          sangramentoVVestibular
          sangramentoMVestibular
          placaDInterna
          placaVInterna
          placaMInterna
          sangramentoDInterna
          sangramentoVInterna
          sangramentoMInterna
          mobilidade
          lesaoDeFurca
        }
        observacaoRPC
        tecidosMoles {
          id
          descricaoSintomatologia
          parteBucal {
            id
            descricao
          }
          cid10 {
            ...Cid10
          }
          caracteristicasLesao {
            id
            caracteristicaLesao
          }
          evolucaoProcedimentoOdonto {
            id
            procedimentos {
              id
              descricao
              codigo
            }
            observacao
          }
        }
      }
      ...HistoricoAntecedentesPecFragment
      ...HistoricoRegistrosAnterioresVacinacaoFragment
      ...HistoricoDesfechoOdontoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${HistoricoObjetivoPecFragmentFragmentDoc}
  ${HistoricoAvaliacaoPecFragmentFragmentDoc}
  ${HistoricoPlanoPecFragmentFragmentDoc}
  ${Cid10FragmentDoc}
  ${HistoricoAntecedentesPecFragmentFragmentDoc}
  ${HistoricoRegistrosAnterioresVacinacaoFragmentFragmentDoc}
  ${HistoricoDesfechoOdontoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoAtendimentoOdontoPecQuery__
 *
 * To run a query within a React component, call `useHistoricoAtendimentoOdontoPecQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAtendimentoOdontoPecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAtendimentoOdontoPecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAtendimentoOdontoPecQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAtendimentoOdontoPecQuery,
    Types.HistoricoAtendimentoOdontoPecQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAtendimentoOdontoPecQuery,
    Types.HistoricoAtendimentoOdontoPecQueryVariables
  >(HistoricoAtendimentoOdontoPecDocument, baseOptions)
}
export function useHistoricoAtendimentoOdontoPecLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAtendimentoOdontoPecQuery,
    Types.HistoricoAtendimentoOdontoPecQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAtendimentoOdontoPecQuery,
    Types.HistoricoAtendimentoOdontoPecQueryVariables
  >(HistoricoAtendimentoOdontoPecDocument, baseOptions)
}
export type HistoricoAtendimentoOdontoPecQueryHookResult = ReturnType<typeof useHistoricoAtendimentoOdontoPecQuery>
export type HistoricoAtendimentoOdontoPecLazyQueryHookResult = ReturnType<
  typeof useHistoricoAtendimentoOdontoPecLazyQuery
>
export type HistoricoAtendimentoOdontoPecQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAtendimentoOdontoPecQuery,
  Types.HistoricoAtendimentoOdontoPecQueryVariables
>
export const HistoricoAtividadeColetivaDocument = gql`
  query HistoricoAtividadeColetiva($input: HistoricoDetailQueryInput!) {
    historicoAtividadeColetiva(input: $input) {
      id
      nuUuidFicha
      stAvaliacaoAlterada
      nuPesoParticipante
      nuAlturaParticipante
      nuImcParticipante
      pnctParticipante
      fatoAtividadeColetiva {
        id
        dimensaoProcedimento {
          id
          descricaoProcedimento
          codigoProcedimento
        }
        dimensaoProfissional {
          id
          cns
          nome
        }
        dimensaoCbo {
          id
          nome
        }
        dimensaoUnidadeSaude {
          id
          nome
          cnes
        }
        dimensaoEquipe {
          id
          nome
          ine
        }
        dimensaoTipoAtividade {
          id
          descricaoTipoAtividade
        }
        dimensaoInep {
          id
          nuIdentificador
          noEstabelecimento
        }
        nuAvaliacoesAlteradas
        stPseEducacao
        stPseSaude
        temasSaude
        praticasSaude
        publicoAlvo
      }
    }
  }
`

/**
 * __useHistoricoAtividadeColetivaQuery__
 *
 * To run a query within a React component, call `useHistoricoAtividadeColetivaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAtividadeColetivaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAtividadeColetivaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAtividadeColetivaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAtividadeColetivaQuery,
    Types.HistoricoAtividadeColetivaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAtividadeColetivaQuery,
    Types.HistoricoAtividadeColetivaQueryVariables
  >(HistoricoAtividadeColetivaDocument, baseOptions)
}
export function useHistoricoAtividadeColetivaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAtividadeColetivaQuery,
    Types.HistoricoAtividadeColetivaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAtividadeColetivaQuery,
    Types.HistoricoAtividadeColetivaQueryVariables
  >(HistoricoAtividadeColetivaDocument, baseOptions)
}
export type HistoricoAtividadeColetivaQueryHookResult = ReturnType<typeof useHistoricoAtividadeColetivaQuery>
export type HistoricoAtividadeColetivaLazyQueryHookResult = ReturnType<typeof useHistoricoAtividadeColetivaLazyQuery>
export type HistoricoAtividadeColetivaQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAtividadeColetivaQuery,
  Types.HistoricoAtividadeColetivaQueryVariables
>
export const HistoricoAvaliacaoElegibilidadeAdDocument = gql`
  query HistoricoAvaliacaoElegibilidadeAD($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      atendimentoProfissionalAD {
        id
        origem
        modalidade
        elegivel
        conclusao
        cid10Principal {
          id
          codigo
          nome
        }
        cid10Secundario1 {
          id
          codigo
          nome
        }
        cid10Secundario2 {
          id
          codigo
          nome
        }
        condicoesAvaliadasElegibilidade {
          id
          situacaoAtiva
          descricao
        }
        conduta
      }
      cidadaoAD {
        id
        dataObito
        numeroDocumentoObito
      }
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoAvaliacaoElegibilidadeAdQuery__
 *
 * To run a query within a React component, call `useHistoricoAvaliacaoElegibilidadeAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAvaliacaoElegibilidadeAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAvaliacaoElegibilidadeAdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAvaliacaoElegibilidadeAdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAvaliacaoElegibilidadeAdQuery,
    Types.HistoricoAvaliacaoElegibilidadeAdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAvaliacaoElegibilidadeAdQuery,
    Types.HistoricoAvaliacaoElegibilidadeAdQueryVariables
  >(HistoricoAvaliacaoElegibilidadeAdDocument, baseOptions)
}
export function useHistoricoAvaliacaoElegibilidadeAdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAvaliacaoElegibilidadeAdQuery,
    Types.HistoricoAvaliacaoElegibilidadeAdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAvaliacaoElegibilidadeAdQuery,
    Types.HistoricoAvaliacaoElegibilidadeAdQueryVariables
  >(HistoricoAvaliacaoElegibilidadeAdDocument, baseOptions)
}
export type HistoricoAvaliacaoElegibilidadeAdQueryHookResult = ReturnType<
  typeof useHistoricoAvaliacaoElegibilidadeAdQuery
>
export type HistoricoAvaliacaoElegibilidadeAdLazyQueryHookResult = ReturnType<
  typeof useHistoricoAvaliacaoElegibilidadeAdLazyQuery
>
export type HistoricoAvaliacaoElegibilidadeAdQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAvaliacaoElegibilidadeAdQuery,
  Types.HistoricoAvaliacaoElegibilidadeAdQueryVariables
>
export const HistoricoAvaliacaoElegibilidadeDwDocument = gql`
  query HistoricoAvaliacaoElegibilidadeDw($input: HistoricoDetailQueryInput!) {
    historicoElegibilidadeDetail(input: $input) {
      id
      nuUuidFicha
      dimensaoCid10Principal {
        id
        codigoCid10
        nomeCid10
      }
      dimensaoCid10SecundarioUm {
        id
        codigoCid10
        nomeCid10
      }
      dimensaoCid10SecundarioDois {
        id
        codigoCid10
        nomeCid10
      }
      procedenciaOrigem
      dimensaoProfissional1 {
        id
        cns
        nome
      }
      dimensaoCbo1 {
        id
        cbo
        nome
      }
      dimensaoUnidadeSaude1 {
        id
        nome
        cnes
      }
      dimensaoEquipe1 {
        id
        nome
        ine
      }
      dimensaoProfissional2 {
        id
        cns
        nome
      }
      dimensaoCbo2 {
        id
        cbo
        nome
      }
      dimensaoUnidadeSaude2 {
        id
        nome
        cnes
      }
      dimensaoEquipe2 {
        id
        nome
        ine
      }
      dimensaoCuidador {
        id
        descricao
      }
      dimensaoModalidadeAD {
        id
        descricao
      }
      condicoesAvaliadas
      conclusao
      isElegivel
      cpfCuidador
      cnsCuidador
    }
  }
`

/**
 * __useHistoricoAvaliacaoElegibilidadeDwQuery__
 *
 * To run a query within a React component, call `useHistoricoAvaliacaoElegibilidadeDwQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAvaliacaoElegibilidadeDwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoAvaliacaoElegibilidadeDwQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoAvaliacaoElegibilidadeDwQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoAvaliacaoElegibilidadeDwQuery,
    Types.HistoricoAvaliacaoElegibilidadeDwQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoAvaliacaoElegibilidadeDwQuery,
    Types.HistoricoAvaliacaoElegibilidadeDwQueryVariables
  >(HistoricoAvaliacaoElegibilidadeDwDocument, baseOptions)
}
export function useHistoricoAvaliacaoElegibilidadeDwLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoAvaliacaoElegibilidadeDwQuery,
    Types.HistoricoAvaliacaoElegibilidadeDwQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoAvaliacaoElegibilidadeDwQuery,
    Types.HistoricoAvaliacaoElegibilidadeDwQueryVariables
  >(HistoricoAvaliacaoElegibilidadeDwDocument, baseOptions)
}
export type HistoricoAvaliacaoElegibilidadeDwQueryHookResult = ReturnType<
  typeof useHistoricoAvaliacaoElegibilidadeDwQuery
>
export type HistoricoAvaliacaoElegibilidadeDwLazyQueryHookResult = ReturnType<
  typeof useHistoricoAvaliacaoElegibilidadeDwLazyQuery
>
export type HistoricoAvaliacaoElegibilidadeDwQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoAvaliacaoElegibilidadeDwQuery,
  Types.HistoricoAvaliacaoElegibilidadeDwQueryVariables
>
export const HistoricoCuidadoCompartilhadoDwDetalhesDocument = gql`
  query HistoricoCuidadoCompartilhadoDwDetalhes($input: HistoricoDetailQueryInput!) {
    historicoCuidadoCompartilhadoDw(input: $input) {
      id
      dataCriacaoCuidado
      isExecutante
      dimProfissionalSolicitante {
        id
        nome
      }
      dimCboSolicitante {
        id
        nome
      }
      dimUnidadeSaudeSolicitante {
        id
        nome
        cnes
      }
      dimEquipeSolicitante {
        id
        nome
        ine
      }
      dimProfissionalExecutante {
        id
        nome
      }
      dimCboExecutante {
        id
        nome
      }
      dimUnidadeSaudeExecutante {
        id
        nome
        cnes
      }
      dimEquipeExecutante {
        id
        nome
        ine
      }
      dimensaoCondutaCuidado {
        id
        nuIdentificador
      }
      dimensaoCid10 {
        id
        codigoCid10
        nomeCid10
      }
      dimensaoCiap {
        id
        codigoCiap
        nomeCiap
      }
      dimensaoPrioridadeCuidado {
        id
        descricao
      }
      dimReclassificacaoPrioridade {
        id
        descricao
      }
    }
  }
`

/**
 * __useHistoricoCuidadoCompartilhadoDwDetalhesQuery__
 *
 * To run a query within a React component, call `useHistoricoCuidadoCompartilhadoDwDetalhesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoCuidadoCompartilhadoDwDetalhesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoCuidadoCompartilhadoDwDetalhesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoCuidadoCompartilhadoDwDetalhesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQueryVariables
  >(HistoricoCuidadoCompartilhadoDwDetalhesDocument, baseOptions)
}
export function useHistoricoCuidadoCompartilhadoDwDetalhesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoDwDetalhesQueryVariables
  >(HistoricoCuidadoCompartilhadoDwDetalhesDocument, baseOptions)
}
export type HistoricoCuidadoCompartilhadoDwDetalhesQueryHookResult = ReturnType<
  typeof useHistoricoCuidadoCompartilhadoDwDetalhesQuery
>
export type HistoricoCuidadoCompartilhadoDwDetalhesLazyQueryHookResult = ReturnType<
  typeof useHistoricoCuidadoCompartilhadoDwDetalhesLazyQuery
>
export type HistoricoCuidadoCompartilhadoDwDetalhesQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoCuidadoCompartilhadoDwDetalhesQuery,
  Types.HistoricoCuidadoCompartilhadoDwDetalhesQueryVariables
>
export const HistoricoCuidadoCompartilhadoEvolucaoDetalhesDocument = gql`
  query HistoricoCuidadoCompartilhadoEvolucaoDetalhes($id: ID!) {
    cuidadoCompartilhadoEvolucao(id: $id) {
      id
      conduta
      dataEvolucao
      descricao
      classificacaoPrioridade
      reclassificacaoPrioridade
      enviarGarantiaAcesso
      lotacao {
        ...LotacaoHistoricoCuidadoCompartilhadoHeader
      }
      cuidadoCompartilhado {
        id
        iniciadoEm
        discussao
        atendimentoProfissional {
          id
          atendimento {
            id
            localAtendimento {
              id
              localAtendimentoExibicao
            }
            unidadeSaude {
              id
              nome
              cnes
            }
          }
        }
      }
      agenda {
        id
        horarioInicial
        observacao
        situacao
      }
    }
  }
  ${LotacaoHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
`

/**
 * __useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery__
 *
 * To run a query within a React component, call `useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryVariables
  >(HistoricoCuidadoCompartilhadoEvolucaoDetalhesDocument, baseOptions)
}
export function useHistoricoCuidadoCompartilhadoEvolucaoDetalhesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
    Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryVariables
  >(HistoricoCuidadoCompartilhadoEvolucaoDetalhesDocument, baseOptions)
}
export type HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryHookResult = ReturnType<
  typeof useHistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery
>
export type HistoricoCuidadoCompartilhadoEvolucaoDetalhesLazyQueryHookResult = ReturnType<
  typeof useHistoricoCuidadoCompartilhadoEvolucaoDetalhesLazyQuery
>
export type HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
  Types.HistoricoCuidadoCompartilhadoEvolucaoDetalhesQueryVariables
>
export const HistoricoCuidadoCompartilhadoLotacaoAfetadaDocument = gql`
  query HistoricoCuidadoCompartilhadoLotacaoAfetada($id: ID!) {
    cuidadoCompartilhadoEvolucao(id: $id) {
      id
      lotacaoAfetada {
        ...LotacaoHistoricoCuidadoCompartilhadoHeader
      }
    }
  }
  ${LotacaoHistoricoCuidadoCompartilhadoHeaderFragmentDoc}
`

/**
 * __useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery__
 *
 * To run a query within a React component, call `useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQuery,
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQuery,
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryVariables
  >(HistoricoCuidadoCompartilhadoLotacaoAfetadaDocument, baseOptions)
}
export function useHistoricoCuidadoCompartilhadoLotacaoAfetadaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQuery,
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQuery,
    Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryVariables
  >(HistoricoCuidadoCompartilhadoLotacaoAfetadaDocument, baseOptions)
}
export type HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryHookResult = ReturnType<
  typeof useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery
>
export type HistoricoCuidadoCompartilhadoLotacaoAfetadaLazyQueryHookResult = ReturnType<
  typeof useHistoricoCuidadoCompartilhadoLotacaoAfetadaLazyQuery
>
export type HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQuery,
  Types.HistoricoCuidadoCompartilhadoLotacaoAfetadaQueryVariables
>
export const HistoricoAuditImpressaoEscutaDocument = gql`
  mutation historicoAuditImpressaoEscuta($atendProfId: ID!) {
    auditImpressaoEscutaInicial(atendProfId: $atendProfId)
  }
`
export type HistoricoAuditImpressaoEscutaMutationFn = ApolloReactCommon.MutationFunction<
  Types.HistoricoAuditImpressaoEscutaMutation,
  Types.HistoricoAuditImpressaoEscutaMutationVariables
>

/**
 * __useHistoricoAuditImpressaoEscutaMutation__
 *
 * To run a mutation, you first call `useHistoricoAuditImpressaoEscutaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHistoricoAuditImpressaoEscutaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [historicoAuditImpressaoEscutaMutation, { data, loading, error }] = useHistoricoAuditImpressaoEscutaMutation({
 *   variables: {
 *      atendProfId: // value for 'atendProfId'
 *   },
 * });
 */
export function useHistoricoAuditImpressaoEscutaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.HistoricoAuditImpressaoEscutaMutation,
    Types.HistoricoAuditImpressaoEscutaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.HistoricoAuditImpressaoEscutaMutation,
    Types.HistoricoAuditImpressaoEscutaMutationVariables
  >(HistoricoAuditImpressaoEscutaDocument, baseOptions)
}
export type HistoricoAuditImpressaoEscutaMutationHookResult = ReturnType<
  typeof useHistoricoAuditImpressaoEscutaMutation
>
export type HistoricoAuditImpressaoEscutaMutationResult = ApolloReactCommon.MutationResult<
  Types.HistoricoAuditImpressaoEscutaMutation
>
export type HistoricoAuditImpressaoEscutaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.HistoricoAuditImpressaoEscutaMutation,
  Types.HistoricoAuditImpressaoEscutaMutationVariables
>
export const HistoricoEscutaInicialDocument = gql`
  query HistoricoEscutaInicial($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      ...HistoricoSubjetivoPecFragment
      evolucaoSubjetivo {
        acompanhadoEspecialidade
      }
      classificacaoRisco
      medicoes {
        ...MedicoesFragment
        valorImc
      }
      procedimentosAtendimento {
        id
        procedimento {
          id
          descricao
          codigo
          tipoProcedimento
        }
        obrigatorio
        automatico
      }
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${MedicoesFragmentFragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoEscutaInicialQuery__
 *
 * To run a query within a React component, call `useHistoricoEscutaInicialQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoEscutaInicialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoEscutaInicialQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoEscutaInicialQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoEscutaInicialQuery,
    Types.HistoricoEscutaInicialQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoEscutaInicialQuery, Types.HistoricoEscutaInicialQueryVariables>(
    HistoricoEscutaInicialDocument,
    baseOptions
  )
}
export function useHistoricoEscutaInicialLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoEscutaInicialQuery,
    Types.HistoricoEscutaInicialQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoEscutaInicialQuery, Types.HistoricoEscutaInicialQueryVariables>(
    HistoricoEscutaInicialDocument,
    baseOptions
  )
}
export type HistoricoEscutaInicialQueryHookResult = ReturnType<typeof useHistoricoEscutaInicialQuery>
export type HistoricoEscutaInicialLazyQueryHookResult = ReturnType<typeof useHistoricoEscutaInicialLazyQuery>
export type HistoricoEscutaInicialQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoEscutaInicialQuery,
  Types.HistoricoEscutaInicialQueryVariables
>
export const ImpressaoEscutaInicialDocument = gql`
  query ImpressaoEscutaInicial($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      motivoConsulta: evolucaoSubjetivo {
        id
        texto: descricao
        ciaps {
          id
          ciap {
            ...Ciap
          }
        }
      }
      classificacaoRisco
      medicoes {
        ...MedicoesFragment
      }
      procedimentos: procedimentosAtendimento {
        _id: id
        procedimento {
          id
          descricao
          codigo
        }
        automatico
      }
      iniciadoEm
      atendimento {
        id
        isAgendado
        cidadao {
          id
          cns
          cpf
          nome
          nomeSocial
          dataNascimento
          sexo
          identidadeGeneroDbEnum
        }
      }
      lotacao {
        id
        profissional {
          id
          nome
          numeroConselhoClasse
          conselhoClasse {
            id
            sigla
          }
          ufEmissoraConselhoClasse {
            id
            nome
            sigla
          }
        }
        municipio {
          id
          nome
          uf {
            id
            nome
            sigla
          }
        }
        unidadeSaude {
          id
          nome
          cnes
        }
        cbo {
          id
          nome
        }
      }
    }
  }
  ${CiapFragmentDoc}
  ${MedicoesFragmentFragmentDoc}
`

/**
 * __useImpressaoEscutaInicialQuery__
 *
 * To run a query within a React component, call `useImpressaoEscutaInicialQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpressaoEscutaInicialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpressaoEscutaInicialQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImpressaoEscutaInicialQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ImpressaoEscutaInicialQuery,
    Types.ImpressaoEscutaInicialQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ImpressaoEscutaInicialQuery, Types.ImpressaoEscutaInicialQueryVariables>(
    ImpressaoEscutaInicialDocument,
    baseOptions
  )
}
export function useImpressaoEscutaInicialLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ImpressaoEscutaInicialQuery,
    Types.ImpressaoEscutaInicialQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ImpressaoEscutaInicialQuery, Types.ImpressaoEscutaInicialQueryVariables>(
    ImpressaoEscutaInicialDocument,
    baseOptions
  )
}
export type ImpressaoEscutaInicialQueryHookResult = ReturnType<typeof useImpressaoEscutaInicialQuery>
export type ImpressaoEscutaInicialLazyQueryHookResult = ReturnType<typeof useImpressaoEscutaInicialLazyQuery>
export type ImpressaoEscutaInicialQueryResult = ApolloReactCommon.QueryResult<
  Types.ImpressaoEscutaInicialQuery,
  Types.ImpressaoEscutaInicialQueryVariables
>
export const HistoricoMarcadoresConsumoAlimentarDocument = gql`
  query HistoricoMarcadoresConsumoAlimentar($input: HistoricoDetailQueryInput!) {
    historicoConsumoAlimentarDetail(input: $input) {
      id
      subtitle
      questionario
      perguntasRespostas {
        pergunta
        respostas
      }
      perguntasRespostasOntem {
        pergunta
        respostas
      }
      dimensaoProfissional {
        id
        nome
      }
      dimensaoCbo {
        id
        nome
      }
      dimensaoUnidadeSaude {
        id
        nome
        cnes
      }
      dimensaoEquipe {
        id
        nome
        ine
      }
      dimensaoLocalAtendimento {
        id
        descricao
      }
    }
  }
`

/**
 * __useHistoricoMarcadoresConsumoAlimentarQuery__
 *
 * To run a query within a React component, call `useHistoricoMarcadoresConsumoAlimentarQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoMarcadoresConsumoAlimentarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoMarcadoresConsumoAlimentarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoMarcadoresConsumoAlimentarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoMarcadoresConsumoAlimentarQuery,
    Types.HistoricoMarcadoresConsumoAlimentarQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoMarcadoresConsumoAlimentarQuery,
    Types.HistoricoMarcadoresConsumoAlimentarQueryVariables
  >(HistoricoMarcadoresConsumoAlimentarDocument, baseOptions)
}
export function useHistoricoMarcadoresConsumoAlimentarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoMarcadoresConsumoAlimentarQuery,
    Types.HistoricoMarcadoresConsumoAlimentarQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoMarcadoresConsumoAlimentarQuery,
    Types.HistoricoMarcadoresConsumoAlimentarQueryVariables
  >(HistoricoMarcadoresConsumoAlimentarDocument, baseOptions)
}
export type HistoricoMarcadoresConsumoAlimentarQueryHookResult = ReturnType<
  typeof useHistoricoMarcadoresConsumoAlimentarQuery
>
export type HistoricoMarcadoresConsumoAlimentarLazyQueryHookResult = ReturnType<
  typeof useHistoricoMarcadoresConsumoAlimentarLazyQuery
>
export type HistoricoMarcadoresConsumoAlimentarQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoMarcadoresConsumoAlimentarQuery,
  Types.HistoricoMarcadoresConsumoAlimentarQueryVariables
>
export const HistoricoProcedimentoDwDocument = gql`
  query HistoricoProcedimentoDw($input: HistoricoDetailQueryInput!) {
    historicoProcedimentoDw(input: $input) {
      id
      procedimentos {
        id
        codigoProcedimento
        descricaoProcedimento
      }
      dimensaoProfissional {
        id
        nome
      }
      dimensaoCbo {
        id
        nome
      }
      dimensaoUnidadeSaude {
        id
        nome
        cnes
      }
      dimensaoEquipe {
        id
        nome
        ine
      }
      dimensaoLocalAtendimento {
        id
        descricao
      }
    }
  }
`

/**
 * __useHistoricoProcedimentoDwQuery__
 *
 * To run a query within a React component, call `useHistoricoProcedimentoDwQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoProcedimentoDwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoProcedimentoDwQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoProcedimentoDwQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoProcedimentoDwQuery,
    Types.HistoricoProcedimentoDwQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoProcedimentoDwQuery, Types.HistoricoProcedimentoDwQueryVariables>(
    HistoricoProcedimentoDwDocument,
    baseOptions
  )
}
export function useHistoricoProcedimentoDwLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoProcedimentoDwQuery,
    Types.HistoricoProcedimentoDwQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoProcedimentoDwQuery, Types.HistoricoProcedimentoDwQueryVariables>(
    HistoricoProcedimentoDwDocument,
    baseOptions
  )
}
export type HistoricoProcedimentoDwQueryHookResult = ReturnType<typeof useHistoricoProcedimentoDwQuery>
export type HistoricoProcedimentoDwLazyQueryHookResult = ReturnType<typeof useHistoricoProcedimentoDwLazyQuery>
export type HistoricoProcedimentoDwQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoProcedimentoDwQuery,
  Types.HistoricoProcedimentoDwQueryVariables
>
export const HistoricoProcedimentoPecDocument = gql`
  query HistoricoProcedimentoPec($input: HistoricoDetailQueryInput!) {
    historicoAtendimentoPec(input: $input) {
      id
      ...HistoricoSubjetivoPecFragment
      ...HistoricoObjetivoPecFragment
      evolucaoPlano {
        id
        descricao
        intervencoesProcedimentos {
          id
          ciap {
            ...Ciap
          }
          procedimento {
            id
            descricao
            codigo
          }
          nota
        }
      }
      planosDeCuidado {
        id
        descricao
        dataDescricao
      }
      quantidadeOrientacoes
      ...HistoricoAntecedentesPecFragment
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${HistoricoObjetivoPecFragmentFragmentDoc}
  ${CiapFragmentDoc}
  ${HistoricoAntecedentesPecFragmentFragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoProcedimentoPecQuery__
 *
 * To run a query within a React component, call `useHistoricoProcedimentoPecQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoProcedimentoPecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoProcedimentoPecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoProcedimentoPecQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoProcedimentoPecQuery,
    Types.HistoricoProcedimentoPecQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoProcedimentoPecQuery, Types.HistoricoProcedimentoPecQueryVariables>(
    HistoricoProcedimentoPecDocument,
    baseOptions
  )
}
export function useHistoricoProcedimentoPecLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoProcedimentoPecQuery,
    Types.HistoricoProcedimentoPecQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoProcedimentoPecQuery,
    Types.HistoricoProcedimentoPecQueryVariables
  >(HistoricoProcedimentoPecDocument, baseOptions)
}
export type HistoricoProcedimentoPecQueryHookResult = ReturnType<typeof useHistoricoProcedimentoPecQuery>
export type HistoricoProcedimentoPecLazyQueryHookResult = ReturnType<typeof useHistoricoProcedimentoPecLazyQuery>
export type HistoricoProcedimentoPecQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoProcedimentoPecQuery,
  Types.HistoricoProcedimentoPecQueryVariables
>
export const HistoricoVacinacaoDwDocument = gql`
  query HistoricoVacinacaoDw($input: HistoricoDetailQueryInput!) {
    historicoVacinacaoDw(input: $input) {
      id
      gestante
      puerpera
      viajante
      comunicanteHanseniase
      dataRegistro
      fatosVacinacaoVacina {
        id
        nomeFabricante
        nomeLote
        dimensaoImunobiologico {
          id
          nome
        }
        dimensaoEstrategiaVacinacao {
          id
          nome
        }
        dimensaoDoseImunobiologico {
          id
          nome
        }
        isRegistroAnterior
        dataRegistroVacinaAplicada
        dataRegistro
      }
      dimensaoProfissional {
        id
        nome
      }
      dimensaoCbo {
        id
        nome
      }
      dimensaoUnidadeSaude {
        id
        nome
        cnes
      }
      dimensaoEquipe {
        id
        nome
        ine
      }
      dimensaoLocalAtendimento {
        id
        descricao
      }
    }
  }
`

/**
 * __useHistoricoVacinacaoDwQuery__
 *
 * To run a query within a React component, call `useHistoricoVacinacaoDwQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoVacinacaoDwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoVacinacaoDwQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoVacinacaoDwQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoVacinacaoDwQuery,
    Types.HistoricoVacinacaoDwQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoVacinacaoDwQuery, Types.HistoricoVacinacaoDwQueryVariables>(
    HistoricoVacinacaoDwDocument,
    baseOptions
  )
}
export function useHistoricoVacinacaoDwLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoVacinacaoDwQuery,
    Types.HistoricoVacinacaoDwQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoVacinacaoDwQuery, Types.HistoricoVacinacaoDwQueryVariables>(
    HistoricoVacinacaoDwDocument,
    baseOptions
  )
}
export type HistoricoVacinacaoDwQueryHookResult = ReturnType<typeof useHistoricoVacinacaoDwQuery>
export type HistoricoVacinacaoDwLazyQueryHookResult = ReturnType<typeof useHistoricoVacinacaoDwLazyQuery>
export type HistoricoVacinacaoDwQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoVacinacaoDwQuery,
  Types.HistoricoVacinacaoDwQueryVariables
>
export const HistoricoVacinacaoPecDocument = gql`
  query HistoricoVacinacaoPec($input: HistoricoDetailQueryInput!) {
    historicoVacinacaoPec(input: $input) {
      id
      isGestante
      isPuerpera
      isViajante
      isComunicanteHanseniase
      atendimentoProfissional {
        id
        finalizadoEm
        ...HistoricoDesfechoPecFragment
        ...HistoricoInfoAdministrativasPecFragment
      }
      registrosVacinacao {
        id
        isRegistroAnterior
        isAplicadoExterior
        imunobiologico {
          id
          nome
        }
        estrategiaVacinacao {
          id
          nome
        }
        grupoAtendimento {
          id
          nome
        }
        tipoRegistroVacinacao {
          id
          identificador
        }
        dataAplicacao
        doseImunobiologico {
          id
          nome
        }
        loteImunobiologico {
          id
          lote
          fabricante {
            id
            nome
          }
          validade
        }
        viaAdministracao {
          id
          nome
        }
        localAplicacaoVacinacao {
          id
          nome
        }
        observacoes
      }
    }
  }
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useHistoricoVacinacaoPecQuery__
 *
 * To run a query within a React component, call `useHistoricoVacinacaoPecQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoVacinacaoPecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoVacinacaoPecQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoVacinacaoPecQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoVacinacaoPecQuery,
    Types.HistoricoVacinacaoPecQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoVacinacaoPecQuery, Types.HistoricoVacinacaoPecQueryVariables>(
    HistoricoVacinacaoPecDocument,
    baseOptions
  )
}
export function useHistoricoVacinacaoPecLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoVacinacaoPecQuery,
    Types.HistoricoVacinacaoPecQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoVacinacaoPecQuery, Types.HistoricoVacinacaoPecQueryVariables>(
    HistoricoVacinacaoPecDocument,
    baseOptions
  )
}
export type HistoricoVacinacaoPecQueryHookResult = ReturnType<typeof useHistoricoVacinacaoPecQuery>
export type HistoricoVacinacaoPecLazyQueryHookResult = ReturnType<typeof useHistoricoVacinacaoPecLazyQuery>
export type HistoricoVacinacaoPecQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoVacinacaoPecQuery,
  Types.HistoricoVacinacaoPecQueryVariables
>
export const HistoricoVisitaDomiciliarDocument = gql`
  query HistoricoVisitaDomiciliar($input: HistoricoDetailQueryInput!) {
    historicoVisitaDomiciliar(input: $input) {
      id
      nuUuidFicha
      cpfCidadao
      cnsCidadao
      motivoVisita
      buscaAtiva
      acompanhamento
      controleAmbiental
      anotacoes
      dimensaoDesfechoVisita {
        id
        numeroIdentificador
        descricao
      }
      dimensaoTipoGlicemia {
        id
        descricao
      }
      dimensaoProfissional {
        id
        nome
      }
      dimensaoCbo {
        id
        nome
      }
      dimensaoUnidadeSaude {
        id
        nome
        cnes
      }
      dimensaoEquipe {
        id
        nome
        ine
      }
      peso
      altura
      temperatura
      medicaoGlicemia
      medicaoPressaoArterial
      stVisitaCompartilhada
    }
  }
`

/**
 * __useHistoricoVisitaDomiciliarQuery__
 *
 * To run a query within a React component, call `useHistoricoVisitaDomiciliarQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoVisitaDomiciliarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoVisitaDomiciliarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoVisitaDomiciliarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoVisitaDomiciliarQuery,
    Types.HistoricoVisitaDomiciliarQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoVisitaDomiciliarQuery, Types.HistoricoVisitaDomiciliarQueryVariables>(
    HistoricoVisitaDomiciliarDocument,
    baseOptions
  )
}
export function useHistoricoVisitaDomiciliarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoVisitaDomiciliarQuery,
    Types.HistoricoVisitaDomiciliarQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoVisitaDomiciliarQuery,
    Types.HistoricoVisitaDomiciliarQueryVariables
  >(HistoricoVisitaDomiciliarDocument, baseOptions)
}
export type HistoricoVisitaDomiciliarQueryHookResult = ReturnType<typeof useHistoricoVisitaDomiciliarQuery>
export type HistoricoVisitaDomiciliarLazyQueryHookResult = ReturnType<typeof useHistoricoVisitaDomiciliarLazyQuery>
export type HistoricoVisitaDomiciliarQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoVisitaDomiciliarQuery,
  Types.HistoricoVisitaDomiciliarQueryVariables
>
export const HistoricoZikaDetailDocument = gql`
  query HistoricoZikaDetail($input: HistoricoDetailQueryInput!) {
    historicoZikaDetail(input: $input) {
      examesDeImagem {
        id
        nome
        resultado
        dataRealizacao
      }
      outrosExames {
        id
        nome
        resultado
        dataRealizacao
      }
      dimensaoProfissional {
        id
        nome
      }
      dimensaoCbo {
        id
        nome
      }
      dimensaoUnidadeSaude {
        id
        nome
        cnes
      }
      dimensaoEquipe {
        id
        nome
        ine
      }
    }
  }
`

/**
 * __useHistoricoZikaDetailQuery__
 *
 * To run a query within a React component, call `useHistoricoZikaDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoZikaDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoZikaDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoZikaDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoZikaDetailQuery,
    Types.HistoricoZikaDetailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoZikaDetailQuery, Types.HistoricoZikaDetailQueryVariables>(
    HistoricoZikaDetailDocument,
    baseOptions
  )
}
export function useHistoricoZikaDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoZikaDetailQuery,
    Types.HistoricoZikaDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoZikaDetailQuery, Types.HistoricoZikaDetailQueryVariables>(
    HistoricoZikaDetailDocument,
    baseOptions
  )
}
export type HistoricoZikaDetailQueryHookResult = ReturnType<typeof useHistoricoZikaDetailQuery>
export type HistoricoZikaDetailLazyQueryHookResult = ReturnType<typeof useHistoricoZikaDetailLazyQuery>
export type HistoricoZikaDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoZikaDetailQuery,
  Types.HistoricoZikaDetailQueryVariables
>
export const HistoricoRetificaAtendimentoMutationDocument = gql`
  mutation HistoricoRetificaAtendimentoMutation($id: ID!) {
    retificaAtendimento(atendProfId: $id)
  }
`
export type HistoricoRetificaAtendimentoMutationMutationFn = ApolloReactCommon.MutationFunction<
  Types.HistoricoRetificaAtendimentoMutationMutation,
  Types.HistoricoRetificaAtendimentoMutationMutationVariables
>

/**
 * __useHistoricoRetificaAtendimentoMutationMutation__
 *
 * To run a mutation, you first call `useHistoricoRetificaAtendimentoMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHistoricoRetificaAtendimentoMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [historicoRetificaAtendimentoMutationMutation, { data, loading, error }] = useHistoricoRetificaAtendimentoMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHistoricoRetificaAtendimentoMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.HistoricoRetificaAtendimentoMutationMutation,
    Types.HistoricoRetificaAtendimentoMutationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.HistoricoRetificaAtendimentoMutationMutation,
    Types.HistoricoRetificaAtendimentoMutationMutationVariables
  >(HistoricoRetificaAtendimentoMutationDocument, baseOptions)
}
export type HistoricoRetificaAtendimentoMutationMutationHookResult = ReturnType<
  typeof useHistoricoRetificaAtendimentoMutationMutation
>
export type HistoricoRetificaAtendimentoMutationMutationResult = ApolloReactCommon.MutationResult<
  Types.HistoricoRetificaAtendimentoMutationMutation
>
export type HistoricoRetificaAtendimentoMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.HistoricoRetificaAtendimentoMutationMutation,
  Types.HistoricoRetificaAtendimentoMutationMutationVariables
>
export const DisponibilidadeDadosClinicosHistoricoDocument = gql`
  query DisponibilidadeDadosClinicosHistorico {
    disponibilidadeDadosClinicosHistorico {
      statusProcessamento
      isVersaoMinimaOracle
    }
  }
`

/**
 * __useDisponibilidadeDadosClinicosHistoricoQuery__
 *
 * To run a query within a React component, call `useDisponibilidadeDadosClinicosHistoricoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisponibilidadeDadosClinicosHistoricoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisponibilidadeDadosClinicosHistoricoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisponibilidadeDadosClinicosHistoricoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DisponibilidadeDadosClinicosHistoricoQuery,
    Types.DisponibilidadeDadosClinicosHistoricoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DisponibilidadeDadosClinicosHistoricoQuery,
    Types.DisponibilidadeDadosClinicosHistoricoQueryVariables
  >(DisponibilidadeDadosClinicosHistoricoDocument, baseOptions)
}
export function useDisponibilidadeDadosClinicosHistoricoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DisponibilidadeDadosClinicosHistoricoQuery,
    Types.DisponibilidadeDadosClinicosHistoricoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DisponibilidadeDadosClinicosHistoricoQuery,
    Types.DisponibilidadeDadosClinicosHistoricoQueryVariables
  >(DisponibilidadeDadosClinicosHistoricoDocument, baseOptions)
}
export type DisponibilidadeDadosClinicosHistoricoQueryHookResult = ReturnType<
  typeof useDisponibilidadeDadosClinicosHistoricoQuery
>
export type DisponibilidadeDadosClinicosHistoricoLazyQueryHookResult = ReturnType<
  typeof useDisponibilidadeDadosClinicosHistoricoLazyQuery
>
export type DisponibilidadeDadosClinicosHistoricoQueryResult = ApolloReactCommon.QueryResult<
  Types.DisponibilidadeDadosClinicosHistoricoQuery,
  Types.DisponibilidadeDadosClinicosHistoricoQueryVariables
>
export const HasProblemasGestacaoDocument = gql`
  query HasProblemasGestacao($id: Long!) {
    hasProblemasGestacao(id: $id)
  }
`

/**
 * __useHasProblemasGestacaoQuery__
 *
 * To run a query within a React component, call `useHasProblemasGestacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasProblemasGestacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasProblemasGestacaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHasProblemasGestacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HasProblemasGestacaoQuery,
    Types.HasProblemasGestacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HasProblemasGestacaoQuery, Types.HasProblemasGestacaoQueryVariables>(
    HasProblemasGestacaoDocument,
    baseOptions
  )
}
export function useHasProblemasGestacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HasProblemasGestacaoQuery,
    Types.HasProblemasGestacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HasProblemasGestacaoQuery, Types.HasProblemasGestacaoQueryVariables>(
    HasProblemasGestacaoDocument,
    baseOptions
  )
}
export type HasProblemasGestacaoQueryHookResult = ReturnType<typeof useHasProblemasGestacaoQuery>
export type HasProblemasGestacaoLazyQueryHookResult = ReturnType<typeof useHasProblemasGestacaoLazyQuery>
export type HasProblemasGestacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.HasProblemasGestacaoQuery,
  Types.HasProblemasGestacaoQueryVariables
>
export const HasPuericulturaDocument = gql`
  query HasPuericultura($prontuarioId: ID!) {
    hasPuericultura(prontuarioId: $prontuarioId)
  }
`

/**
 * __useHasPuericulturaQuery__
 *
 * To run a query within a React component, call `useHasPuericulturaQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasPuericulturaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasPuericulturaQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useHasPuericulturaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.HasPuericulturaQuery, Types.HasPuericulturaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.HasPuericulturaQuery, Types.HasPuericulturaQueryVariables>(
    HasPuericulturaDocument,
    baseOptions
  )
}
export function useHasPuericulturaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.HasPuericulturaQuery, Types.HasPuericulturaQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.HasPuericulturaQuery, Types.HasPuericulturaQueryVariables>(
    HasPuericulturaDocument,
    baseOptions
  )
}
export type HasPuericulturaQueryHookResult = ReturnType<typeof useHasPuericulturaQuery>
export type HasPuericulturaLazyQueryHookResult = ReturnType<typeof useHasPuericulturaLazyQuery>
export type HasPuericulturaQueryResult = ApolloReactCommon.QueryResult<
  Types.HasPuericulturaQuery,
  Types.HasPuericulturaQueryVariables
>
export const LoadAntecedentesDocument = gql`
  query LoadAntecedentes($id: ID!) {
    antecedente(prontuarioId: $id) {
      id
      observacoes
      dataUltimaAtualizacaoPessoal
      gestacoesPrevias
      qtPartos
      qtAbortos
      qtCesareas
      partosVaginais
      partosDomiciliares
      nascidosVivos
      natimortos
      obitoAntesPrimeiraSemana
      obitoAposPrimeiraSemana
      filhosVivos
      recemNascidosAcima
      recemNascidosAbaixo
      dataUltimoParto
      partoMenosDeUmAno
      dataUltimaAtualizacaoGestacional
      peso
      altura
      perimetroCefalico
      apgarUm
      apgarCinco
      apgarDez
      tipoGravidez
      tipoParto
      idadeGestacionalSemanas
      idadeGestacionalDias
      dataUltimaAtualizacaoPuericultura
      antecedentesFamiliaresCiap {
        idCiap
        codigoCiap
        descricao
        observacao
      }
      cirurgiasInternacoes {
        id
        descricao
        observacoes
        dataCirurgiaInternacao
      }
    }
  }
`

/**
 * __useLoadAntecedentesQuery__
 *
 * To run a query within a React component, call `useLoadAntecedentesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAntecedentesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAntecedentesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadAntecedentesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoadAntecedentesQuery, Types.LoadAntecedentesQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoadAntecedentesQuery, Types.LoadAntecedentesQueryVariables>(
    LoadAntecedentesDocument,
    baseOptions
  )
}
export function useLoadAntecedentesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LoadAntecedentesQuery, Types.LoadAntecedentesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LoadAntecedentesQuery, Types.LoadAntecedentesQueryVariables>(
    LoadAntecedentesDocument,
    baseOptions
  )
}
export type LoadAntecedentesQueryHookResult = ReturnType<typeof useLoadAntecedentesQuery>
export type LoadAntecedentesLazyQueryHookResult = ReturnType<typeof useLoadAntecedentesLazyQuery>
export type LoadAntecedentesQueryResult = ApolloReactCommon.QueryResult<
  Types.LoadAntecedentesQuery,
  Types.LoadAntecedentesQueryVariables
>
export const AcompanhamentoIdosoCardDocument = gql`
  query AcompanhamentoIdosoCard($acompanhamentoIdosoQueryInput: AcompanhamentoIdosoQueryInput!) {
    ultimaAvaliacaoMultidimensional(input: $acompanhamentoIdosoQueryInput)
  }
`

/**
 * __useAcompanhamentoIdosoCardQuery__
 *
 * To run a query within a React component, call `useAcompanhamentoIdosoCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcompanhamentoIdosoCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcompanhamentoIdosoCardQuery({
 *   variables: {
 *      acompanhamentoIdosoQueryInput: // value for 'acompanhamentoIdosoQueryInput'
 *   },
 * });
 */
export function useAcompanhamentoIdosoCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcompanhamentoIdosoCardQuery,
    Types.AcompanhamentoIdosoCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AcompanhamentoIdosoCardQuery, Types.AcompanhamentoIdosoCardQueryVariables>(
    AcompanhamentoIdosoCardDocument,
    baseOptions
  )
}
export function useAcompanhamentoIdosoCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcompanhamentoIdosoCardQuery,
    Types.AcompanhamentoIdosoCardQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AcompanhamentoIdosoCardQuery, Types.AcompanhamentoIdosoCardQueryVariables>(
    AcompanhamentoIdosoCardDocument,
    baseOptions
  )
}
export type AcompanhamentoIdosoCardQueryHookResult = ReturnType<typeof useAcompanhamentoIdosoCardQuery>
export type AcompanhamentoIdosoCardLazyQueryHookResult = ReturnType<typeof useAcompanhamentoIdosoCardLazyQuery>
export type AcompanhamentoIdosoCardQueryResult = ApolloReactCommon.QueryResult<
  Types.AcompanhamentoIdosoCardQuery,
  Types.AcompanhamentoIdosoCardQueryVariables
>
export const AcompanhamentoPuericulturaCardDocument = gql`
  query AcompanhamentoPuericulturaCard($prontuarioId: ID!, $dataReferencia: LocalDate!) {
    ultimoAtendimentoPuericultura(prontuarioId: $prontuarioId) {
      id
      dataInicio
      nomeProfissional
    }
    tipoAleitamentoMaterno(prontuarioId: $prontuarioId) {
      id
      tipo
      dataInicioAtendimento
    }
    preNatalPartoNascimento: antecedente(prontuarioId: $prontuarioId) {
      id
      tipoGravidez
      tipoParto
      idadeGestacionalSemanas
      idadeGestacionalDias
      apgarUm
      apgarCinco
      apgarDez
      peso
      altura
      perimetroCefalico
    }
    alteracoesFenotipicas(prontuarioId: $prontuarioId) {
      id
      descricao
      status
      dataAvaliacao
    }
    fatoresRisco(prontuarioId: $prontuarioId) {
      id
      descricao
      status
      dataAvaliacao
    }
    marcosDesenvolvimento(input: { prontuarioId: $prontuarioId, dataReferencia: $dataReferencia }) {
      id
      descricao
      hint
      status
      dataAvaliacao
      alcancadoComMeses
      alcancadoComAnos
      faixaEtariaEnum
      faixaEtariaFim
      descricaoFaixaEtaria
    }
    medicoesAnteriores: medicoes(input: { prontuarioId: $prontuarioId }) {
      id
      dataMedicao
      valorVacinacaoEmDia
      valorPerimetroCefalico
    }
    antecedente(prontuarioId: $prontuarioId) {
      id
      perimetroCefalico
    }
  }
`

/**
 * __useAcompanhamentoPuericulturaCardQuery__
 *
 * To run a query within a React component, call `useAcompanhamentoPuericulturaCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcompanhamentoPuericulturaCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcompanhamentoPuericulturaCardQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *      dataReferencia: // value for 'dataReferencia'
 *   },
 * });
 */
export function useAcompanhamentoPuericulturaCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcompanhamentoPuericulturaCardQuery,
    Types.AcompanhamentoPuericulturaCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AcompanhamentoPuericulturaCardQuery,
    Types.AcompanhamentoPuericulturaCardQueryVariables
  >(AcompanhamentoPuericulturaCardDocument, baseOptions)
}
export function useAcompanhamentoPuericulturaCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcompanhamentoPuericulturaCardQuery,
    Types.AcompanhamentoPuericulturaCardQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AcompanhamentoPuericulturaCardQuery,
    Types.AcompanhamentoPuericulturaCardQueryVariables
  >(AcompanhamentoPuericulturaCardDocument, baseOptions)
}
export type AcompanhamentoPuericulturaCardQueryHookResult = ReturnType<typeof useAcompanhamentoPuericulturaCardQuery>
export type AcompanhamentoPuericulturaCardLazyQueryHookResult = ReturnType<
  typeof useAcompanhamentoPuericulturaCardLazyQuery
>
export type AcompanhamentoPuericulturaCardQueryResult = ApolloReactCommon.QueryResult<
  Types.AcompanhamentoPuericulturaCardQuery,
  Types.AcompanhamentoPuericulturaCardQueryVariables
>
export const AlergiasCardDocument = gql`
  query AlergiasCard($filtro: AlergiasQueryInput!, $prontuarioId: ID!) {
    alergias(input: $filtro) {
      content {
        id
        categoriaSubstanciaEspecificaLegado
        substanciaEspecificaLegado
        tipoReacao
        substanciaEspecifica {
          ...SubstanciaEspecificaAlergia
        }
        criticidade
        grauCerteza
      }
    }
    existemAlergiasResolvidasRefutadas(prontuarioId: $prontuarioId)
  }
  ${SubstanciaEspecificaAlergiaFragmentDoc}
`

/**
 * __useAlergiasCardQuery__
 *
 * To run a query within a React component, call `useAlergiasCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlergiasCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlergiasCardQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useAlergiasCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AlergiasCardQuery, Types.AlergiasCardQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AlergiasCardQuery, Types.AlergiasCardQueryVariables>(
    AlergiasCardDocument,
    baseOptions
  )
}
export function useAlergiasCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AlergiasCardQuery, Types.AlergiasCardQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AlergiasCardQuery, Types.AlergiasCardQueryVariables>(
    AlergiasCardDocument,
    baseOptions
  )
}
export type AlergiasCardQueryHookResult = ReturnType<typeof useAlergiasCardQuery>
export type AlergiasCardLazyQueryHookResult = ReturnType<typeof useAlergiasCardLazyQuery>
export type AlergiasCardQueryResult = ApolloReactCommon.QueryResult<
  Types.AlergiasCardQuery,
  Types.AlergiasCardQueryVariables
>
export const LembretesCardDocument = gql`
  query LembretesCard($lembretesQueryInput: LembretesQueryInput!) {
    lembretes(input: $lembretesQueryInput) {
      id
      ultimoLembrete {
        id
        descricao
        ativo
        data
        lotacao {
          id
          profissional {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
      }
    }
  }
`

/**
 * __useLembretesCardQuery__
 *
 * To run a query within a React component, call `useLembretesCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLembretesCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLembretesCardQuery({
 *   variables: {
 *      lembretesQueryInput: // value for 'lembretesQueryInput'
 *   },
 * });
 */
export function useLembretesCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LembretesCardQuery, Types.LembretesCardQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LembretesCardQuery, Types.LembretesCardQueryVariables>(
    LembretesCardDocument,
    baseOptions
  )
}
export function useLembretesCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LembretesCardQuery, Types.LembretesCardQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LembretesCardQuery, Types.LembretesCardQueryVariables>(
    LembretesCardDocument,
    baseOptions
  )
}
export type LembretesCardQueryHookResult = ReturnType<typeof useLembretesCardQuery>
export type LembretesCardLazyQueryHookResult = ReturnType<typeof useLembretesCardLazyQuery>
export type LembretesCardQueryResult = ApolloReactCommon.QueryResult<
  Types.LembretesCardQuery,
  Types.LembretesCardQueryVariables
>
export const ProblemasCardDocument = gql`
  query ProblemasCard($filtro: ProblemaQueryInput!) {
    problemas(input: $filtro) {
      content {
        ...ProblemaCondicao
      }
    }
  }
  ${ProblemaCondicaoFragmentDoc}
`

/**
 * __useProblemasCardQuery__
 *
 * To run a query within a React component, call `useProblemasCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemasCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemasCardQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProblemasCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProblemasCardQuery, Types.ProblemasCardQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProblemasCardQuery, Types.ProblemasCardQueryVariables>(
    ProblemasCardDocument,
    baseOptions
  )
}
export function useProblemasCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProblemasCardQuery, Types.ProblemasCardQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ProblemasCardQuery, Types.ProblemasCardQueryVariables>(
    ProblemasCardDocument,
    baseOptions
  )
}
export type ProblemasCardQueryHookResult = ReturnType<typeof useProblemasCardQuery>
export type ProblemasCardLazyQueryHookResult = ReturnType<typeof useProblemasCardLazyQuery>
export type ProblemasCardQueryResult = ApolloReactCommon.QueryResult<
  Types.ProblemasCardQuery,
  Types.ProblemasCardQueryVariables
>
export const MedicamentoUsoBarraLateralDocument = gql`
  query MedicamentoUsoBarraLateral($input: ListaMedicamentoAtivoConcluidoQueryInput!) {
    listaMedicamento(input: $input) {
      content {
        id
        dtInicioTratamento
        dtFimTratamento
        medicamento {
          id
          principioAtivo
          concentracao
        }
        usoContinuo
        posologia
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMedicamentoUsoBarraLateralQuery__
 *
 * To run a query within a React component, call `useMedicamentoUsoBarraLateralQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicamentoUsoBarraLateralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicamentoUsoBarraLateralQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicamentoUsoBarraLateralQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MedicamentoUsoBarraLateralQuery,
    Types.MedicamentoUsoBarraLateralQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.MedicamentoUsoBarraLateralQuery,
    Types.MedicamentoUsoBarraLateralQueryVariables
  >(MedicamentoUsoBarraLateralDocument, baseOptions)
}
export function useMedicamentoUsoBarraLateralLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MedicamentoUsoBarraLateralQuery,
    Types.MedicamentoUsoBarraLateralQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.MedicamentoUsoBarraLateralQuery,
    Types.MedicamentoUsoBarraLateralQueryVariables
  >(MedicamentoUsoBarraLateralDocument, baseOptions)
}
export type MedicamentoUsoBarraLateralQueryHookResult = ReturnType<typeof useMedicamentoUsoBarraLateralQuery>
export type MedicamentoUsoBarraLateralLazyQueryHookResult = ReturnType<typeof useMedicamentoUsoBarraLateralLazyQuery>
export type MedicamentoUsoBarraLateralQueryResult = ApolloReactCommon.QueryResult<
  Types.MedicamentoUsoBarraLateralQuery,
  Types.MedicamentoUsoBarraLateralQueryVariables
>
export const PossuiTratamentoConcluidoDocument = gql`
  query PossuiTratamentoConcluido($input: PossuiTratamentoConcluidoQueryInput!) {
    possuiTratamentoConcluido(input: $input)
  }
`

/**
 * __usePossuiTratamentoConcluidoQuery__
 *
 * To run a query within a React component, call `usePossuiTratamentoConcluidoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossuiTratamentoConcluidoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossuiTratamentoConcluidoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePossuiTratamentoConcluidoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PossuiTratamentoConcluidoQuery,
    Types.PossuiTratamentoConcluidoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.PossuiTratamentoConcluidoQuery, Types.PossuiTratamentoConcluidoQueryVariables>(
    PossuiTratamentoConcluidoDocument,
    baseOptions
  )
}
export function usePossuiTratamentoConcluidoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PossuiTratamentoConcluidoQuery,
    Types.PossuiTratamentoConcluidoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PossuiTratamentoConcluidoQuery,
    Types.PossuiTratamentoConcluidoQueryVariables
  >(PossuiTratamentoConcluidoDocument, baseOptions)
}
export type PossuiTratamentoConcluidoQueryHookResult = ReturnType<typeof usePossuiTratamentoConcluidoQuery>
export type PossuiTratamentoConcluidoLazyQueryHookResult = ReturnType<typeof usePossuiTratamentoConcluidoLazyQuery>
export type PossuiTratamentoConcluidoQueryResult = ApolloReactCommon.QueryResult<
  Types.PossuiTratamentoConcluidoQuery,
  Types.PossuiTratamentoConcluidoQueryVariables
>
export const MedicoesCardDocument = gql`
  query MedicoesCard($prontuarioId: ID!, $fetchMedicoesAnteriores: Boolean!, $dataReferencia: Instant) {
    ultimasMedicoesDoDia(filtro: { prontuarioId: $prontuarioId, data: $dataReferencia }) {
      ...GrupoMedicoesCard
    }
    ultimasMedicoesDiasAnteriores(filtro: { prontuarioId: $prontuarioId, data: $dataReferencia })
      @include(if: $fetchMedicoesAnteriores) {
      ...GrupoMedicoesCard
    }
    medicoesNoNascimento: antecedente(prontuarioId: $prontuarioId) {
      id
      peso
      altura
      perimetroCefalico
    }
  }
  ${GrupoMedicoesCardFragmentDoc}
`

/**
 * __useMedicoesCardQuery__
 *
 * To run a query within a React component, call `useMedicoesCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicoesCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicoesCardQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *      fetchMedicoesAnteriores: // value for 'fetchMedicoesAnteriores'
 *      dataReferencia: // value for 'dataReferencia'
 *   },
 * });
 */
export function useMedicoesCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MedicoesCardQuery, Types.MedicoesCardQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.MedicoesCardQuery, Types.MedicoesCardQueryVariables>(
    MedicoesCardDocument,
    baseOptions
  )
}
export function useMedicoesCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MedicoesCardQuery, Types.MedicoesCardQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.MedicoesCardQuery, Types.MedicoesCardQueryVariables>(
    MedicoesCardDocument,
    baseOptions
  )
}
export type MedicoesCardQueryHookResult = ReturnType<typeof useMedicoesCardQuery>
export type MedicoesCardLazyQueryHookResult = ReturnType<typeof useMedicoesCardLazyQuery>
export type MedicoesCardQueryResult = ApolloReactCommon.QueryResult<
  Types.MedicoesCardQuery,
  Types.MedicoesCardQueryVariables
>
export const AcompanhamentoPreNatalCardDocument = gql`
  query acompanhamentoPreNatalCard($input: ID!) {
    atendimentosProfUltimaGestacao(prontuarioId: $input) {
      id
      iniciadoEm
      atendimentoProfissionalPreNatal {
        id
        preNatal {
          id
          altoRisco
          tipoGravidez {
            id
            descricao
          }
        }
      }
      medicoes {
        id
        dataMedicao
        dum
        medicaoAnterior
      }
      examesResultados {
        ...ExamePreNatal
      }
    }
  }
  ${ExamePreNatalFragmentDoc}
`

/**
 * __useAcompanhamentoPreNatalCardQuery__
 *
 * To run a query within a React component, call `useAcompanhamentoPreNatalCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcompanhamentoPreNatalCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcompanhamentoPreNatalCardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcompanhamentoPreNatalCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcompanhamentoPreNatalCardQuery,
    Types.AcompanhamentoPreNatalCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AcompanhamentoPreNatalCardQuery,
    Types.AcompanhamentoPreNatalCardQueryVariables
  >(AcompanhamentoPreNatalCardDocument, baseOptions)
}
export function useAcompanhamentoPreNatalCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcompanhamentoPreNatalCardQuery,
    Types.AcompanhamentoPreNatalCardQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AcompanhamentoPreNatalCardQuery,
    Types.AcompanhamentoPreNatalCardQueryVariables
  >(AcompanhamentoPreNatalCardDocument, baseOptions)
}
export type AcompanhamentoPreNatalCardQueryHookResult = ReturnType<typeof useAcompanhamentoPreNatalCardQuery>
export type AcompanhamentoPreNatalCardLazyQueryHookResult = ReturnType<typeof useAcompanhamentoPreNatalCardLazyQuery>
export type AcompanhamentoPreNatalCardQueryResult = ApolloReactCommon.QueryResult<
  Types.AcompanhamentoPreNatalCardQuery,
  Types.AcompanhamentoPreNatalCardQueryVariables
>
export const DataUltimaConsultaOdontoGestanteDocument = gql`
  query dataUltimaConsultaOdontoGestante($input: DataUltimaConsultaOdontoGestanteQueryInput!) {
    dataUltimaConsultaOdontoGestante(filter: $input)
  }
`

/**
 * __useDataUltimaConsultaOdontoGestanteQuery__
 *
 * To run a query within a React component, call `useDataUltimaConsultaOdontoGestanteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataUltimaConsultaOdontoGestanteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataUltimaConsultaOdontoGestanteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDataUltimaConsultaOdontoGestanteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DataUltimaConsultaOdontoGestanteQuery,
    Types.DataUltimaConsultaOdontoGestanteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DataUltimaConsultaOdontoGestanteQuery,
    Types.DataUltimaConsultaOdontoGestanteQueryVariables
  >(DataUltimaConsultaOdontoGestanteDocument, baseOptions)
}
export function useDataUltimaConsultaOdontoGestanteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DataUltimaConsultaOdontoGestanteQuery,
    Types.DataUltimaConsultaOdontoGestanteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DataUltimaConsultaOdontoGestanteQuery,
    Types.DataUltimaConsultaOdontoGestanteQueryVariables
  >(DataUltimaConsultaOdontoGestanteDocument, baseOptions)
}
export type DataUltimaConsultaOdontoGestanteQueryHookResult = ReturnType<
  typeof useDataUltimaConsultaOdontoGestanteQuery
>
export type DataUltimaConsultaOdontoGestanteLazyQueryHookResult = ReturnType<
  typeof useDataUltimaConsultaOdontoGestanteLazyQuery
>
export type DataUltimaConsultaOdontoGestanteQueryResult = ApolloReactCommon.QueryResult<
  Types.DataUltimaConsultaOdontoGestanteQuery,
  Types.DataUltimaConsultaOdontoGestanteQueryVariables
>
export const ExamesPreNatalDocument = gql`
  query examesPreNatal($input: ResultadosExameQueryInput!) {
    resultadosExame(input: $input) {
      content {
        ...ExamePreNatal
      }
    }
  }
  ${ExamePreNatalFragmentDoc}
`

/**
 * __useExamesPreNatalQuery__
 *
 * To run a query within a React component, call `useExamesPreNatalQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamesPreNatalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamesPreNatalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamesPreNatalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ExamesPreNatalQuery, Types.ExamesPreNatalQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ExamesPreNatalQuery, Types.ExamesPreNatalQueryVariables>(
    ExamesPreNatalDocument,
    baseOptions
  )
}
export function useExamesPreNatalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ExamesPreNatalQuery, Types.ExamesPreNatalQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ExamesPreNatalQuery, Types.ExamesPreNatalQueryVariables>(
    ExamesPreNatalDocument,
    baseOptions
  )
}
export type ExamesPreNatalQueryHookResult = ReturnType<typeof useExamesPreNatalQuery>
export type ExamesPreNatalLazyQueryHookResult = ReturnType<typeof useExamesPreNatalLazyQuery>
export type ExamesPreNatalQueryResult = ApolloReactCommon.QueryResult<
  Types.ExamesPreNatalQuery,
  Types.ExamesPreNatalQueryVariables
>
export const ResultadosExamesCardDocument = gql`
  query ResultadosExamesCard($input: ResultadosExameQueryInput!) {
    resultadosExame(input: $input) {
      content {
        id
        exame {
          ...Procedimento
        }
        resultado
        dataSolicitacao
        dataRealizacao
        dataResultado
        observacao
        conferido
        especifico {
          id
          exameEspecificoEnum
          valor
          igDias
          igSemanas
          dpp
          resultadoExamePuericulturaEnum
        }
        atendimentoProfissional {
          id
          iniciadoEm
        }
      }
    }
  }
  ${ProcedimentoFragmentDoc}
`

/**
 * __useResultadosExamesCardQuery__
 *
 * To run a query within a React component, call `useResultadosExamesCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useResultadosExamesCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResultadosExamesCardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResultadosExamesCardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ResultadosExamesCardQuery,
    Types.ResultadosExamesCardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ResultadosExamesCardQuery, Types.ResultadosExamesCardQueryVariables>(
    ResultadosExamesCardDocument,
    baseOptions
  )
}
export function useResultadosExamesCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ResultadosExamesCardQuery,
    Types.ResultadosExamesCardQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ResultadosExamesCardQuery, Types.ResultadosExamesCardQueryVariables>(
    ResultadosExamesCardDocument,
    baseOptions
  )
}
export type ResultadosExamesCardQueryHookResult = ReturnType<typeof useResultadosExamesCardQuery>
export type ResultadosExamesCardLazyQueryHookResult = ReturnType<typeof useResultadosExamesCardLazyQuery>
export type ResultadosExamesCardQueryResult = ApolloReactCommon.QueryResult<
  Types.ResultadosExamesCardQuery,
  Types.ResultadosExamesCardQueryVariables
>
export const SugestaoDatasAgendamentoDocument = gql`
  query SugestaoDatasAgendamento($input: SugestoesDatasInput!) {
    sugestoesDatas(input: $input) {
      preNatal
      odontologica
    }
  }
`

/**
 * __useSugestaoDatasAgendamentoQuery__
 *
 * To run a query within a React component, call `useSugestaoDatasAgendamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSugestaoDatasAgendamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSugestaoDatasAgendamentoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSugestaoDatasAgendamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SugestaoDatasAgendamentoQuery,
    Types.SugestaoDatasAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.SugestaoDatasAgendamentoQuery, Types.SugestaoDatasAgendamentoQueryVariables>(
    SugestaoDatasAgendamentoDocument,
    baseOptions
  )
}
export function useSugestaoDatasAgendamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SugestaoDatasAgendamentoQuery,
    Types.SugestaoDatasAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.SugestaoDatasAgendamentoQuery,
    Types.SugestaoDatasAgendamentoQueryVariables
  >(SugestaoDatasAgendamentoDocument, baseOptions)
}
export type SugestaoDatasAgendamentoQueryHookResult = ReturnType<typeof useSugestaoDatasAgendamentoQuery>
export type SugestaoDatasAgendamentoLazyQueryHookResult = ReturnType<typeof useSugestaoDatasAgendamentoLazyQuery>
export type SugestaoDatasAgendamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.SugestaoDatasAgendamentoQuery,
  Types.SugestaoDatasAgendamentoQueryVariables
>
export const SugestaoProfissionaisAgendamentoDocument = gql`
  query SugestaoProfissionaisAgendamento($prontuarioId: ID!) {
    sugestoesProfissionais(prontuarioId: $prontuarioId) {
      preNatal {
        ...LotacaoSugestaoAgendamento
      }
      odontologico {
        ...LotacaoSugestaoAgendamento
      }
    }
  }
  ${LotacaoSugestaoAgendamentoFragmentDoc}
`

/**
 * __useSugestaoProfissionaisAgendamentoQuery__
 *
 * To run a query within a React component, call `useSugestaoProfissionaisAgendamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSugestaoProfissionaisAgendamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSugestaoProfissionaisAgendamentoQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useSugestaoProfissionaisAgendamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SugestaoProfissionaisAgendamentoQuery,
    Types.SugestaoProfissionaisAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.SugestaoProfissionaisAgendamentoQuery,
    Types.SugestaoProfissionaisAgendamentoQueryVariables
  >(SugestaoProfissionaisAgendamentoDocument, baseOptions)
}
export function useSugestaoProfissionaisAgendamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SugestaoProfissionaisAgendamentoQuery,
    Types.SugestaoProfissionaisAgendamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.SugestaoProfissionaisAgendamentoQuery,
    Types.SugestaoProfissionaisAgendamentoQueryVariables
  >(SugestaoProfissionaisAgendamentoDocument, baseOptions)
}
export type SugestaoProfissionaisAgendamentoQueryHookResult = ReturnType<
  typeof useSugestaoProfissionaisAgendamentoQuery
>
export type SugestaoProfissionaisAgendamentoLazyQueryHookResult = ReturnType<
  typeof useSugestaoProfissionaisAgendamentoLazyQuery
>
export type SugestaoProfissionaisAgendamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.SugestaoProfissionaisAgendamentoQuery,
  Types.SugestaoProfissionaisAgendamentoQueryVariables
>
export const PreNatalEmAtendimentoObservacaoDocument = gql`
  query PreNatalEmAtendimentoObservacao($id: ID!) {
    atendimentoIndividual(id: $id) {
      id
      medicoes {
        id
        valorAlturaUterina
        valorBatimentoCardiacoFetal
        medicaoAnterior
      }
      atendimentoProfissionalPreNatal {
        id
        gravidezPlanejada
        movimentacaoFetal
        tipoEdema
        preNatal {
          id
          altoRisco
          tipoGravidez {
            id
            descricao
            tipoGravidez
          }
        }
      }
    }
  }
`

/**
 * __usePreNatalEmAtendimentoObservacaoQuery__
 *
 * To run a query within a React component, call `usePreNatalEmAtendimentoObservacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreNatalEmAtendimentoObservacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreNatalEmAtendimentoObservacaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreNatalEmAtendimentoObservacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PreNatalEmAtendimentoObservacaoQuery,
    Types.PreNatalEmAtendimentoObservacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.PreNatalEmAtendimentoObservacaoQuery,
    Types.PreNatalEmAtendimentoObservacaoQueryVariables
  >(PreNatalEmAtendimentoObservacaoDocument, baseOptions)
}
export function usePreNatalEmAtendimentoObservacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PreNatalEmAtendimentoObservacaoQuery,
    Types.PreNatalEmAtendimentoObservacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PreNatalEmAtendimentoObservacaoQuery,
    Types.PreNatalEmAtendimentoObservacaoQueryVariables
  >(PreNatalEmAtendimentoObservacaoDocument, baseOptions)
}
export type PreNatalEmAtendimentoObservacaoQueryHookResult = ReturnType<typeof usePreNatalEmAtendimentoObservacaoQuery>
export type PreNatalEmAtendimentoObservacaoLazyQueryHookResult = ReturnType<
  typeof usePreNatalEmAtendimentoObservacaoLazyQuery
>
export type PreNatalEmAtendimentoObservacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.PreNatalEmAtendimentoObservacaoQuery,
  Types.PreNatalEmAtendimentoObservacaoQueryVariables
>
export const PuericulturaDocument = gql`
  query Puericultura($prontuarioId: ID!, $dataReferencia: LocalDate!) {
    hasPuericultura(prontuarioId: $prontuarioId)
    alteracoesFenotipicas(prontuarioId: $prontuarioId) {
      id
      descricao
      status
      dataAvaliacao
    }
    fatoresRisco(prontuarioId: $prontuarioId) {
      id
      descricao
      status
      dataAvaliacao
    }
    marcosDesenvolvimento(input: { prontuarioId: $prontuarioId, dataReferencia: $dataReferencia }) {
      id
      descricao
      hint
      status
      dataAvaliacao
      alcancadoComMeses
      alcancadoComAnos
      faixaEtariaEnum
      faixaEtariaFim
      descricaoFaixaEtaria
    }
    medicoesAnteriores: medicoes(input: { prontuarioId: $prontuarioId }) {
      id
      dataMedicao
      valorPerimetroCefalico
    }
    antecedente(prontuarioId: $prontuarioId) {
      id
      perimetroCefalico
    }
  }
`

/**
 * __usePuericulturaQuery__
 *
 * To run a query within a React component, call `usePuericulturaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePuericulturaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePuericulturaQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *      dataReferencia: // value for 'dataReferencia'
 *   },
 * });
 */
export function usePuericulturaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PuericulturaQuery, Types.PuericulturaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PuericulturaQuery, Types.PuericulturaQueryVariables>(
    PuericulturaDocument,
    baseOptions
  )
}
export function usePuericulturaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PuericulturaQuery, Types.PuericulturaQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PuericulturaQuery, Types.PuericulturaQueryVariables>(
    PuericulturaDocument,
    baseOptions
  )
}
export type PuericulturaQueryHookResult = ReturnType<typeof usePuericulturaQuery>
export type PuericulturaLazyQueryHookResult = ReturnType<typeof usePuericulturaLazyQuery>
export type PuericulturaQueryResult = ApolloReactCommon.QueryResult<
  Types.PuericulturaQuery,
  Types.PuericulturaQueryVariables
>
export const SolicitacoesExamesPagedDocument = gql`
  query SolicitacoesExamesPaged($input: SolicitacoesExameQueryInput!) {
    solicitacoesExamePaged(input: $input) {
      content {
        id
        data
        tipoExame
        observacoes
        justificativa
        exames {
          id
          conferido
          observacao
          solicitacao {
            id
            data
          }
          exame {
            ...Procedimento
          }
          atendimentoProfissional {
            id
          }
        }
        cid10 {
          ...Cid10
          sexo
        }
        atendimentoProfissional {
          id
          iniciadoEm
          lotacao {
            id
            cbo {
              id
              nome
            }
            unidadeSaude {
              id
              nome
            }
            profissional {
              id
              nome
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProcedimentoFragmentDoc}
  ${Cid10FragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useSolicitacoesExamesPagedQuery__
 *
 * To run a query within a React component, call `useSolicitacoesExamesPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolicitacoesExamesPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolicitacoesExamesPagedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolicitacoesExamesPagedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SolicitacoesExamesPagedQuery,
    Types.SolicitacoesExamesPagedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.SolicitacoesExamesPagedQuery, Types.SolicitacoesExamesPagedQueryVariables>(
    SolicitacoesExamesPagedDocument,
    baseOptions
  )
}
export function useSolicitacoesExamesPagedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SolicitacoesExamesPagedQuery,
    Types.SolicitacoesExamesPagedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.SolicitacoesExamesPagedQuery, Types.SolicitacoesExamesPagedQueryVariables>(
    SolicitacoesExamesPagedDocument,
    baseOptions
  )
}
export type SolicitacoesExamesPagedQueryHookResult = ReturnType<typeof useSolicitacoesExamesPagedQuery>
export type SolicitacoesExamesPagedLazyQueryHookResult = ReturnType<typeof useSolicitacoesExamesPagedLazyQuery>
export type SolicitacoesExamesPagedQueryResult = ApolloReactCommon.QueryResult<
  Types.SolicitacoesExamesPagedQuery,
  Types.SolicitacoesExamesPagedQueryVariables
>
export const SolicitacoesExameUnpagedDocument = gql`
  query SolicitacoesExameUnpaged($input: SolicitacoesExameQueryInput!) {
    solicitacoesExame(input: $input) {
      content {
        ...SolicitacaoExame
      }
    }
  }
  ${SolicitacaoExameFragmentDoc}
`

/**
 * __useSolicitacoesExameUnpagedQuery__
 *
 * To run a query within a React component, call `useSolicitacoesExameUnpagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolicitacoesExameUnpagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolicitacoesExameUnpagedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolicitacoesExameUnpagedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SolicitacoesExameUnpagedQuery,
    Types.SolicitacoesExameUnpagedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.SolicitacoesExameUnpagedQuery, Types.SolicitacoesExameUnpagedQueryVariables>(
    SolicitacoesExameUnpagedDocument,
    baseOptions
  )
}
export function useSolicitacoesExameUnpagedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SolicitacoesExameUnpagedQuery,
    Types.SolicitacoesExameUnpagedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.SolicitacoesExameUnpagedQuery,
    Types.SolicitacoesExameUnpagedQueryVariables
  >(SolicitacoesExameUnpagedDocument, baseOptions)
}
export type SolicitacoesExameUnpagedQueryHookResult = ReturnType<typeof useSolicitacoesExameUnpagedQuery>
export type SolicitacoesExameUnpagedLazyQueryHookResult = ReturnType<typeof useSolicitacoesExameUnpagedLazyQuery>
export type SolicitacoesExameUnpagedQueryResult = ApolloReactCommon.QueryResult<
  Types.SolicitacoesExameUnpagedQuery,
  Types.SolicitacoesExameUnpagedQueryVariables
>
export const AtestadoModelosLoadDocument = gql`
  query AtestadoModelosLoad {
    atestadoModelos {
      id
      nome
    }
  }
`

/**
 * __useAtestadoModelosLoadQuery__
 *
 * To run a query within a React component, call `useAtestadoModelosLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtestadoModelosLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtestadoModelosLoadQuery({
 *   variables: {
 *   },
 * });
 */
export function useAtestadoModelosLoadQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtestadoModelosLoadQuery,
    Types.AtestadoModelosLoadQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AtestadoModelosLoadQuery, Types.AtestadoModelosLoadQueryVariables>(
    AtestadoModelosLoadDocument,
    baseOptions
  )
}
export function useAtestadoModelosLoadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtestadoModelosLoadQuery,
    Types.AtestadoModelosLoadQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AtestadoModelosLoadQuery, Types.AtestadoModelosLoadQueryVariables>(
    AtestadoModelosLoadDocument,
    baseOptions
  )
}
export type AtestadoModelosLoadQueryHookResult = ReturnType<typeof useAtestadoModelosLoadQuery>
export type AtestadoModelosLoadLazyQueryHookResult = ReturnType<typeof useAtestadoModelosLoadLazyQuery>
export type AtestadoModelosLoadQueryResult = ApolloReactCommon.QueryResult<
  Types.AtestadoModelosLoadQuery,
  Types.AtestadoModelosLoadQueryVariables
>
export const LoadAtestadoByIdDocument = gql`
  query LoadAtestadoById($id: ID!) {
    atestado(id: $id) {
      id
      descricao
      tipo
      atendimentoProfissional {
        id
        lotacao {
          id
          profissional {
            id
            nome
            numeroConselhoClasse
            conselhoClasse {
              id
              sigla
            }
            ufEmissoraConselhoClasse {
              id
              nome
              sigla
            }
          }
          municipio {
            id
            nome
            uf {
              id
              nome
              sigla
            }
          }
          unidadeSaude {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
        iniciadoEm
      }
    }
  }
`

/**
 * __useLoadAtestadoByIdQuery__
 *
 * To run a query within a React component, call `useLoadAtestadoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAtestadoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAtestadoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadAtestadoByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoadAtestadoByIdQuery, Types.LoadAtestadoByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoadAtestadoByIdQuery, Types.LoadAtestadoByIdQueryVariables>(
    LoadAtestadoByIdDocument,
    baseOptions
  )
}
export function useLoadAtestadoByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LoadAtestadoByIdQuery, Types.LoadAtestadoByIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LoadAtestadoByIdQuery, Types.LoadAtestadoByIdQueryVariables>(
    LoadAtestadoByIdDocument,
    baseOptions
  )
}
export type LoadAtestadoByIdQueryHookResult = ReturnType<typeof useLoadAtestadoByIdQuery>
export type LoadAtestadoByIdLazyQueryHookResult = ReturnType<typeof useLoadAtestadoByIdLazyQuery>
export type LoadAtestadoByIdQueryResult = ApolloReactCommon.QueryResult<
  Types.LoadAtestadoByIdQuery,
  Types.LoadAtestadoByIdQueryVariables
>
export const LoadAtestadosDocument = gql`
  query LoadAtestados($input: AtestadoQueryInput!) {
    atestados(input: $input) {
      content {
        id
        atendimentoProfissional {
          id
          iniciadoEm
          lotacao {
            id
            profissional {
              id
              nome
              numeroConselhoClasse
              conselhoClasse {
                id
                sigla
              }
              ufEmissoraConselhoClasse {
                id
                nome
                sigla
              }
            }
            cbo {
              id
              nome
            }
            unidadeSaude {
              id
              nome
            }
            municipio {
              id
              nome
              uf {
                id
                nome
                sigla
              }
            }
          }
        }
        assinadoDigitalmente
      }
    }
  }
`

/**
 * __useLoadAtestadosQuery__
 *
 * To run a query within a React component, call `useLoadAtestadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAtestadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAtestadosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadAtestadosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoadAtestadosQuery, Types.LoadAtestadosQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoadAtestadosQuery, Types.LoadAtestadosQueryVariables>(
    LoadAtestadosDocument,
    baseOptions
  )
}
export function useLoadAtestadosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LoadAtestadosQuery, Types.LoadAtestadosQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LoadAtestadosQuery, Types.LoadAtestadosQueryVariables>(
    LoadAtestadosDocument,
    baseOptions
  )
}
export type LoadAtestadosQueryHookResult = ReturnType<typeof useLoadAtestadosQuery>
export type LoadAtestadosLazyQueryHookResult = ReturnType<typeof useLoadAtestadosLazyQuery>
export type LoadAtestadosQueryResult = ApolloReactCommon.QueryResult<
  Types.LoadAtestadosQuery,
  Types.LoadAtestadosQueryVariables
>
export const AuditaImpressaoAtestadoDocument = gql`
  mutation auditaImpressaoAtestado($prontuarioId: ID!, $atendimentoId: ID, $atestadoId: ID) {
    auditoriaImpressaoAtestado(prontuarioId: $prontuarioId, atendimentoId: $atendimentoId, atestadoId: $atestadoId)
  }
`
export type AuditaImpressaoAtestadoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AuditaImpressaoAtestadoMutation,
  Types.AuditaImpressaoAtestadoMutationVariables
>

/**
 * __useAuditaImpressaoAtestadoMutation__
 *
 * To run a mutation, you first call `useAuditaImpressaoAtestadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuditaImpressaoAtestadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auditaImpressaoAtestadoMutation, { data, loading, error }] = useAuditaImpressaoAtestadoMutation({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *      atendimentoId: // value for 'atendimentoId'
 *      atestadoId: // value for 'atestadoId'
 *   },
 * });
 */
export function useAuditaImpressaoAtestadoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AuditaImpressaoAtestadoMutation,
    Types.AuditaImpressaoAtestadoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AuditaImpressaoAtestadoMutation,
    Types.AuditaImpressaoAtestadoMutationVariables
  >(AuditaImpressaoAtestadoDocument, baseOptions)
}
export type AuditaImpressaoAtestadoMutationHookResult = ReturnType<typeof useAuditaImpressaoAtestadoMutation>
export type AuditaImpressaoAtestadoMutationResult = ApolloReactCommon.MutationResult<
  Types.AuditaImpressaoAtestadoMutation
>
export type AuditaImpressaoAtestadoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AuditaImpressaoAtestadoMutation,
  Types.AuditaImpressaoAtestadoMutationVariables
>
export const AtestadoModeloByIdDocument = gql`
  query AtestadoModeloById($id: ID!) {
    atestadoModelo(id: $id) {
      id
      nome
      descricao
    }
  }
`

/**
 * __useAtestadoModeloByIdQuery__
 *
 * To run a query within a React component, call `useAtestadoModeloByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtestadoModeloByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtestadoModeloByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtestadoModeloByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AtestadoModeloByIdQuery, Types.AtestadoModeloByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AtestadoModeloByIdQuery, Types.AtestadoModeloByIdQueryVariables>(
    AtestadoModeloByIdDocument,
    baseOptions
  )
}
export function useAtestadoModeloByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtestadoModeloByIdQuery,
    Types.AtestadoModeloByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AtestadoModeloByIdQuery, Types.AtestadoModeloByIdQueryVariables>(
    AtestadoModeloByIdDocument,
    baseOptions
  )
}
export type AtestadoModeloByIdQueryHookResult = ReturnType<typeof useAtestadoModeloByIdQuery>
export type AtestadoModeloByIdLazyQueryHookResult = ReturnType<typeof useAtestadoModeloByIdLazyQuery>
export type AtestadoModeloByIdQueryResult = ApolloReactCommon.QueryResult<
  Types.AtestadoModeloByIdQuery,
  Types.AtestadoModeloByIdQueryVariables
>
export const AtestadoModeloDeleteDocument = gql`
  mutation AtestadoModeloDelete($id: ID!) {
    excluirAtestadoModelo(idModelo: $id)
  }
`
export type AtestadoModeloDeleteMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtestadoModeloDeleteMutation,
  Types.AtestadoModeloDeleteMutationVariables
>

/**
 * __useAtestadoModeloDeleteMutation__
 *
 * To run a mutation, you first call `useAtestadoModeloDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtestadoModeloDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atestadoModeloDeleteMutation, { data, loading, error }] = useAtestadoModeloDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtestadoModeloDeleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtestadoModeloDeleteMutation,
    Types.AtestadoModeloDeleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtestadoModeloDeleteMutation, Types.AtestadoModeloDeleteMutationVariables>(
    AtestadoModeloDeleteDocument,
    baseOptions
  )
}
export type AtestadoModeloDeleteMutationHookResult = ReturnType<typeof useAtestadoModeloDeleteMutation>
export type AtestadoModeloDeleteMutationResult = ApolloReactCommon.MutationResult<Types.AtestadoModeloDeleteMutation>
export type AtestadoModeloDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtestadoModeloDeleteMutation,
  Types.AtestadoModeloDeleteMutationVariables
>
export const AtestadoModeloSaveDocument = gql`
  mutation AtestadoModeloSave($input: AtestadoModeloInput!) {
    salvarAtestadoModelo(input: $input) {
      id
    }
  }
`
export type AtestadoModeloSaveMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtestadoModeloSaveMutation,
  Types.AtestadoModeloSaveMutationVariables
>

/**
 * __useAtestadoModeloSaveMutation__
 *
 * To run a mutation, you first call `useAtestadoModeloSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtestadoModeloSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atestadoModeloSaveMutation, { data, loading, error }] = useAtestadoModeloSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtestadoModeloSaveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtestadoModeloSaveMutation,
    Types.AtestadoModeloSaveMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtestadoModeloSaveMutation, Types.AtestadoModeloSaveMutationVariables>(
    AtestadoModeloSaveDocument,
    baseOptions
  )
}
export type AtestadoModeloSaveMutationHookResult = ReturnType<typeof useAtestadoModeloSaveMutation>
export type AtestadoModeloSaveMutationResult = ApolloReactCommon.MutationResult<Types.AtestadoModeloSaveMutation>
export type AtestadoModeloSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtestadoModeloSaveMutation,
  Types.AtestadoModeloSaveMutationVariables
>
export const CompartilhamentoCuidadoPlanoDocument = gql`
  query CompartilhamentoCuidadoPlano($prontuarioId: ID!) {
    compartilhamentosCuidadoPlano(prontuarioId: $prontuarioId) {
      id
      atendimentoProfissional {
        id
      }
      lotacaoSolicitante {
        ...CompartilhamentoCuidadoLotacao
        cbo {
          id
          nome
        }
      }
      lotacaoExecutanteAtual {
        ...CompartilhamentoCuidadoLotacao
      }
      cbo {
        id
        nome
        cbo2002
      }
      unidadeSaude {
        id
        nome
        cnes
      }
      dataInicio
      cid10 {
        ...Cid10
      }
      ciap {
        ...Ciap
      }
      classificacaoPrioridadeAtual
      discussao
      cidadaoAceitaAtendTic
      isEncaminhamento
    }
  }
  ${CompartilhamentoCuidadoLotacaoFragmentDoc}
  ${Cid10FragmentDoc}
  ${CiapFragmentDoc}
`

/**
 * __useCompartilhamentoCuidadoPlanoQuery__
 *
 * To run a query within a React component, call `useCompartilhamentoCuidadoPlanoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompartilhamentoCuidadoPlanoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompartilhamentoCuidadoPlanoQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useCompartilhamentoCuidadoPlanoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CompartilhamentoCuidadoPlanoQuery,
    Types.CompartilhamentoCuidadoPlanoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CompartilhamentoCuidadoPlanoQuery,
    Types.CompartilhamentoCuidadoPlanoQueryVariables
  >(CompartilhamentoCuidadoPlanoDocument, baseOptions)
}
export function useCompartilhamentoCuidadoPlanoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CompartilhamentoCuidadoPlanoQuery,
    Types.CompartilhamentoCuidadoPlanoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CompartilhamentoCuidadoPlanoQuery,
    Types.CompartilhamentoCuidadoPlanoQueryVariables
  >(CompartilhamentoCuidadoPlanoDocument, baseOptions)
}
export type CompartilhamentoCuidadoPlanoQueryHookResult = ReturnType<typeof useCompartilhamentoCuidadoPlanoQuery>
export type CompartilhamentoCuidadoPlanoLazyQueryHookResult = ReturnType<
  typeof useCompartilhamentoCuidadoPlanoLazyQuery
>
export type CompartilhamentoCuidadoPlanoQueryResult = ApolloReactCommon.QueryResult<
  Types.CompartilhamentoCuidadoPlanoQuery,
  Types.CompartilhamentoCuidadoPlanoQueryVariables
>
export const AcessoHasCbosDisponiveisVinculacaoEquipeDocument = gql`
  query acessoHasCbosDisponiveisVinculacaoEquipe {
    acessoHasCbosDisponiveisVinculacaoEquipe
  }
`

/**
 * __useAcessoHasCbosDisponiveisVinculacaoEquipeQuery__
 *
 * To run a query within a React component, call `useAcessoHasCbosDisponiveisVinculacaoEquipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoHasCbosDisponiveisVinculacaoEquipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoHasCbosDisponiveisVinculacaoEquipeQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcessoHasCbosDisponiveisVinculacaoEquipeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQuery,
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQuery,
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQueryVariables
  >(AcessoHasCbosDisponiveisVinculacaoEquipeDocument, baseOptions)
}
export function useAcessoHasCbosDisponiveisVinculacaoEquipeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQuery,
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQuery,
    Types.AcessoHasCbosDisponiveisVinculacaoEquipeQueryVariables
  >(AcessoHasCbosDisponiveisVinculacaoEquipeDocument, baseOptions)
}
export type AcessoHasCbosDisponiveisVinculacaoEquipeQueryHookResult = ReturnType<
  typeof useAcessoHasCbosDisponiveisVinculacaoEquipeQuery
>
export type AcessoHasCbosDisponiveisVinculacaoEquipeLazyQueryHookResult = ReturnType<
  typeof useAcessoHasCbosDisponiveisVinculacaoEquipeLazyQuery
>
export type AcessoHasCbosDisponiveisVinculacaoEquipeQueryResult = ApolloReactCommon.QueryResult<
  Types.AcessoHasCbosDisponiveisVinculacaoEquipeQuery,
  Types.AcessoHasCbosDisponiveisVinculacaoEquipeQueryVariables
>
export const AcessoHasVinculacaoEquipeNasfDocument = gql`
  query acessoHasVinculacaoEquipeNasf {
    acessoHasVinculacaoEquipeNasf
  }
`

/**
 * __useAcessoHasVinculacaoEquipeNasfQuery__
 *
 * To run a query within a React component, call `useAcessoHasVinculacaoEquipeNasfQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoHasVinculacaoEquipeNasfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoHasVinculacaoEquipeNasfQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcessoHasVinculacaoEquipeNasfQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AcessoHasVinculacaoEquipeNasfQuery,
    Types.AcessoHasVinculacaoEquipeNasfQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AcessoHasVinculacaoEquipeNasfQuery,
    Types.AcessoHasVinculacaoEquipeNasfQueryVariables
  >(AcessoHasVinculacaoEquipeNasfDocument, baseOptions)
}
export function useAcessoHasVinculacaoEquipeNasfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AcessoHasVinculacaoEquipeNasfQuery,
    Types.AcessoHasVinculacaoEquipeNasfQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AcessoHasVinculacaoEquipeNasfQuery,
    Types.AcessoHasVinculacaoEquipeNasfQueryVariables
  >(AcessoHasVinculacaoEquipeNasfDocument, baseOptions)
}
export type AcessoHasVinculacaoEquipeNasfQueryHookResult = ReturnType<typeof useAcessoHasVinculacaoEquipeNasfQuery>
export type AcessoHasVinculacaoEquipeNasfLazyQueryHookResult = ReturnType<
  typeof useAcessoHasVinculacaoEquipeNasfLazyQuery
>
export type AcessoHasVinculacaoEquipeNasfQueryResult = ApolloReactCommon.QueryResult<
  Types.AcessoHasVinculacaoEquipeNasfQuery,
  Types.AcessoHasVinculacaoEquipeNasfQueryVariables
>
export const EstabelecimentosCuidadoCompartilhadoSelectFieldDocument = gql`
  query EstabelecimentosCuidadoCompartilhadoSelectField(
    $input: EstabelecimentosCuidadoCompartilhadoByCboIdQueryInput!
  ) {
    estabelecimentosCuidadoCompartilhado(input: $input) {
      content {
        id
        cnes
        nome
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEstabelecimentosCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useEstabelecimentosCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstabelecimentosCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstabelecimentosCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstabelecimentosCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
  >(EstabelecimentosCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useEstabelecimentosCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
    Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
  >(EstabelecimentosCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type EstabelecimentosCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useEstabelecimentosCuidadoCompartilhadoSelectFieldQuery
>
export type EstabelecimentosCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useEstabelecimentosCuidadoCompartilhadoSelectFieldLazyQuery
>
export type EstabelecimentosCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
  Types.EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
>
export const CuidadosCompartilhadoPlanoDocument = gql`
  query CuidadosCompartilhadoPlano($input: CuidadosCompartilhadoQueryInput!) {
    cuidadosCompartilhado(input: $input) {
      content {
        id
        iniciadoEm
        discussao
        cidadaoAceitaAtendTic
        classificacaoPrioridadeAtual
        lotacaoExecutanteAtual {
          id
          profissional {
            id
            nome
          }
        }
        cboExecutanteAtual {
          id
          nome
        }
        lotacaoSolicitante {
          id
          profissional {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
        cid10 {
          ...Cid10
        }
        ciap {
          ...Ciap
        }
      }
    }
  }
  ${Cid10FragmentDoc}
  ${CiapFragmentDoc}
`

/**
 * __useCuidadosCompartilhadoPlanoQuery__
 *
 * To run a query within a React component, call `useCuidadosCompartilhadoPlanoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCuidadosCompartilhadoPlanoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCuidadosCompartilhadoPlanoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCuidadosCompartilhadoPlanoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CuidadosCompartilhadoPlanoQuery,
    Types.CuidadosCompartilhadoPlanoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CuidadosCompartilhadoPlanoQuery,
    Types.CuidadosCompartilhadoPlanoQueryVariables
  >(CuidadosCompartilhadoPlanoDocument, baseOptions)
}
export function useCuidadosCompartilhadoPlanoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CuidadosCompartilhadoPlanoQuery,
    Types.CuidadosCompartilhadoPlanoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CuidadosCompartilhadoPlanoQuery,
    Types.CuidadosCompartilhadoPlanoQueryVariables
  >(CuidadosCompartilhadoPlanoDocument, baseOptions)
}
export type CuidadosCompartilhadoPlanoQueryHookResult = ReturnType<typeof useCuidadosCompartilhadoPlanoQuery>
export type CuidadosCompartilhadoPlanoLazyQueryHookResult = ReturnType<typeof useCuidadosCompartilhadoPlanoLazyQuery>
export type CuidadosCompartilhadoPlanoQueryResult = ApolloReactCommon.QueryResult<
  Types.CuidadosCompartilhadoPlanoQuery,
  Types.CuidadosCompartilhadoPlanoQueryVariables
>
export const AuditGerarDeclaracaoComparecimentoDocument = gql`
  mutation AuditGerarDeclaracaoComparecimento($atendimento: ID!) {
    auditGerarDeclaracaoComparecimento(atendimentoId: $atendimento)
  }
`
export type AuditGerarDeclaracaoComparecimentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AuditGerarDeclaracaoComparecimentoMutation,
  Types.AuditGerarDeclaracaoComparecimentoMutationVariables
>

/**
 * __useAuditGerarDeclaracaoComparecimentoMutation__
 *
 * To run a mutation, you first call `useAuditGerarDeclaracaoComparecimentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuditGerarDeclaracaoComparecimentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auditGerarDeclaracaoComparecimentoMutation, { data, loading, error }] = useAuditGerarDeclaracaoComparecimentoMutation({
 *   variables: {
 *      atendimento: // value for 'atendimento'
 *   },
 * });
 */
export function useAuditGerarDeclaracaoComparecimentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AuditGerarDeclaracaoComparecimentoMutation,
    Types.AuditGerarDeclaracaoComparecimentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AuditGerarDeclaracaoComparecimentoMutation,
    Types.AuditGerarDeclaracaoComparecimentoMutationVariables
  >(AuditGerarDeclaracaoComparecimentoDocument, baseOptions)
}
export type AuditGerarDeclaracaoComparecimentoMutationHookResult = ReturnType<
  typeof useAuditGerarDeclaracaoComparecimentoMutation
>
export type AuditGerarDeclaracaoComparecimentoMutationResult = ApolloReactCommon.MutationResult<
  Types.AuditGerarDeclaracaoComparecimentoMutation
>
export type AuditGerarDeclaracaoComparecimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AuditGerarDeclaracaoComparecimentoMutation,
  Types.AuditGerarDeclaracaoComparecimentoMutationVariables
>
export const AtendimentoProfissionalEncaminhamentoByIdDocument = gql`
  query AtendimentoProfissionalEncaminhamentoById($atendimentoProfissionalId: ID!) {
    atendimentoIndividual(id: $atendimentoProfissionalId) {
      id
      lotacao {
        id
        profissional {
          id
          nome
          cpf
          cns
        }
        cbo {
          id
          nome
          cbo2002
        }
      }
    }
  }
`

/**
 * __useAtendimentoProfissionalEncaminhamentoByIdQuery__
 *
 * To run a query within a React component, call `useAtendimentoProfissionalEncaminhamentoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoProfissionalEncaminhamentoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoProfissionalEncaminhamentoByIdQuery({
 *   variables: {
 *      atendimentoProfissionalId: // value for 'atendimentoProfissionalId'
 *   },
 * });
 */
export function useAtendimentoProfissionalEncaminhamentoByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtendimentoProfissionalEncaminhamentoByIdQuery,
    Types.AtendimentoProfissionalEncaminhamentoByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AtendimentoProfissionalEncaminhamentoByIdQuery,
    Types.AtendimentoProfissionalEncaminhamentoByIdQueryVariables
  >(AtendimentoProfissionalEncaminhamentoByIdDocument, baseOptions)
}
export function useAtendimentoProfissionalEncaminhamentoByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentoProfissionalEncaminhamentoByIdQuery,
    Types.AtendimentoProfissionalEncaminhamentoByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AtendimentoProfissionalEncaminhamentoByIdQuery,
    Types.AtendimentoProfissionalEncaminhamentoByIdQueryVariables
  >(AtendimentoProfissionalEncaminhamentoByIdDocument, baseOptions)
}
export type AtendimentoProfissionalEncaminhamentoByIdQueryHookResult = ReturnType<
  typeof useAtendimentoProfissionalEncaminhamentoByIdQuery
>
export type AtendimentoProfissionalEncaminhamentoByIdLazyQueryHookResult = ReturnType<
  typeof useAtendimentoProfissionalEncaminhamentoByIdLazyQuery
>
export type AtendimentoProfissionalEncaminhamentoByIdQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoProfissionalEncaminhamentoByIdQuery,
  Types.AtendimentoProfissionalEncaminhamentoByIdQueryVariables
>
export const EncaminhamentoEspecializadoByIdDocument = gql`
  query EncaminhamentoEspecializadoById($id: ID!) {
    encaminhamentoEspecializado(id: $id) {
      id
      atendimentoProfissional {
        id
        lotacao {
          id
          profissional {
            id
            nome
            cpf
            cns
          }
          cbo {
            id
            nome
            cbo2002
          }
        }
        iniciadoEm
      }
      especialidadeSisreg {
        id
        descricao
      }
      hipoteseDiagnosticoCiap {
        id
        descricao
        codigo
      }
      hipoteseDiagnosticoCid10 {
        id
        nome
        codigo
      }
      classificacaoRiscoEncaminhamento
      complemento
      motivoEncaminhamento
      observacoes
    }
  }
`

/**
 * __useEncaminhamentoEspecializadoByIdQuery__
 *
 * To run a query within a React component, call `useEncaminhamentoEspecializadoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEncaminhamentoEspecializadoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEncaminhamentoEspecializadoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEncaminhamentoEspecializadoByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EncaminhamentoEspecializadoByIdQuery,
    Types.EncaminhamentoEspecializadoByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EncaminhamentoEspecializadoByIdQuery,
    Types.EncaminhamentoEspecializadoByIdQueryVariables
  >(EncaminhamentoEspecializadoByIdDocument, baseOptions)
}
export function useEncaminhamentoEspecializadoByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EncaminhamentoEspecializadoByIdQuery,
    Types.EncaminhamentoEspecializadoByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EncaminhamentoEspecializadoByIdQuery,
    Types.EncaminhamentoEspecializadoByIdQueryVariables
  >(EncaminhamentoEspecializadoByIdDocument, baseOptions)
}
export type EncaminhamentoEspecializadoByIdQueryHookResult = ReturnType<typeof useEncaminhamentoEspecializadoByIdQuery>
export type EncaminhamentoEspecializadoByIdLazyQueryHookResult = ReturnType<
  typeof useEncaminhamentoEspecializadoByIdLazyQuery
>
export type EncaminhamentoEspecializadoByIdQueryResult = ApolloReactCommon.QueryResult<
  Types.EncaminhamentoEspecializadoByIdQuery,
  Types.EncaminhamentoEspecializadoByIdQueryVariables
>
export const EncaminhamentosExternosEspecializadosDocument = gql`
  query EncaminhamentosExternosEspecializados($prontuarioId: ID!, $editAtendProfId: ID) {
    encaminhamentosEspecializados(input: { prontuarioId: $prontuarioId, editAtendProfId: $editAtendProfId }) {
      content {
        ...EncaminhamentoExternoEspecializado
      }
    }
  }
  ${EncaminhamentoExternoEspecializadoFragmentDoc}
`

/**
 * __useEncaminhamentosExternosEspecializadosQuery__
 *
 * To run a query within a React component, call `useEncaminhamentosExternosEspecializadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useEncaminhamentosExternosEspecializadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEncaminhamentosExternosEspecializadosQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *      editAtendProfId: // value for 'editAtendProfId'
 *   },
 * });
 */
export function useEncaminhamentosExternosEspecializadosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EncaminhamentosExternosEspecializadosQuery,
    Types.EncaminhamentosExternosEspecializadosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EncaminhamentosExternosEspecializadosQuery,
    Types.EncaminhamentosExternosEspecializadosQueryVariables
  >(EncaminhamentosExternosEspecializadosDocument, baseOptions)
}
export function useEncaminhamentosExternosEspecializadosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EncaminhamentosExternosEspecializadosQuery,
    Types.EncaminhamentosExternosEspecializadosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EncaminhamentosExternosEspecializadosQuery,
    Types.EncaminhamentosExternosEspecializadosQueryVariables
  >(EncaminhamentosExternosEspecializadosDocument, baseOptions)
}
export type EncaminhamentosExternosEspecializadosQueryHookResult = ReturnType<
  typeof useEncaminhamentosExternosEspecializadosQuery
>
export type EncaminhamentosExternosEspecializadosLazyQueryHookResult = ReturnType<
  typeof useEncaminhamentosExternosEspecializadosLazyQuery
>
export type EncaminhamentosExternosEspecializadosQueryResult = ApolloReactCommon.QueryResult<
  Types.EncaminhamentosExternosEspecializadosQuery,
  Types.EncaminhamentosExternosEspecializadosQueryVariables
>
export const EncaminhamentosExternosDocument = gql`
  query EncaminhamentosExternos($prontuarioId: ID!, $editAtendProfId: ID) {
    encaminhamentos(input: { prontuarioId: $prontuarioId, editAtendProfId: $editAtendProfId }) {
      atendimentoProfissional {
        id
        iniciadoEm
        lotacao {
          id
          profissional {
            id
          }
        }
      }
      tipo
    }
  }
`

/**
 * __useEncaminhamentosExternosQuery__
 *
 * To run a query within a React component, call `useEncaminhamentosExternosQuery` and pass it any options that fit your needs.
 * When your component renders, `useEncaminhamentosExternosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEncaminhamentosExternosQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *      editAtendProfId: // value for 'editAtendProfId'
 *   },
 * });
 */
export function useEncaminhamentosExternosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EncaminhamentosExternosQuery,
    Types.EncaminhamentosExternosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EncaminhamentosExternosQuery, Types.EncaminhamentosExternosQueryVariables>(
    EncaminhamentosExternosDocument,
    baseOptions
  )
}
export function useEncaminhamentosExternosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EncaminhamentosExternosQuery,
    Types.EncaminhamentosExternosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EncaminhamentosExternosQuery, Types.EncaminhamentosExternosQueryVariables>(
    EncaminhamentosExternosDocument,
    baseOptions
  )
}
export type EncaminhamentosExternosQueryHookResult = ReturnType<typeof useEncaminhamentosExternosQuery>
export type EncaminhamentosExternosLazyQueryHookResult = ReturnType<typeof useEncaminhamentosExternosLazyQuery>
export type EncaminhamentosExternosQueryResult = ApolloReactCommon.QueryResult<
  Types.EncaminhamentosExternosQuery,
  Types.EncaminhamentosExternosQueryVariables
>
export const PartesBucaisDocument = gql`
  query PartesBucais($input: PartesBucaisQueryInput!) {
    partesBucais(input: $input) {
      content {
        id
        descricao
        parteBucalEnum
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePartesBucaisQuery__
 *
 * To run a query within a React component, call `usePartesBucaisQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartesBucaisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartesBucaisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartesBucaisQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PartesBucaisQuery, Types.PartesBucaisQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PartesBucaisQuery, Types.PartesBucaisQueryVariables>(
    PartesBucaisDocument,
    baseOptions
  )
}
export function usePartesBucaisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PartesBucaisQuery, Types.PartesBucaisQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PartesBucaisQuery, Types.PartesBucaisQueryVariables>(
    PartesBucaisDocument,
    baseOptions
  )
}
export type PartesBucaisQueryHookResult = ReturnType<typeof usePartesBucaisQuery>
export type PartesBucaisLazyQueryHookResult = ReturnType<typeof usePartesBucaisLazyQuery>
export type PartesBucaisQueryResult = ApolloReactCommon.QueryResult<
  Types.PartesBucaisQuery,
  Types.PartesBucaisQueryVariables
>
export const OdontogramaProcedimentosDocument = gql`
  query OdontogramaProcedimentos($input: EvolucoesProcedimentoOdontoInput!) {
    evolucoesProcedimentoOdonto(input: $input) {
      content {
        id
        descricaoParteBucal
        observacao
        atendimentoProfissionalOdonto {
          id
        }
        partesBucais {
          id
          tipoParteBucal
          descricao
        }
        procedimentos {
          ...Procedimento
        }
      }
    }
  }
  ${ProcedimentoFragmentDoc}
`

/**
 * __useOdontogramaProcedimentosQuery__
 *
 * To run a query within a React component, call `useOdontogramaProcedimentosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOdontogramaProcedimentosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOdontogramaProcedimentosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOdontogramaProcedimentosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.OdontogramaProcedimentosQuery,
    Types.OdontogramaProcedimentosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.OdontogramaProcedimentosQuery, Types.OdontogramaProcedimentosQueryVariables>(
    OdontogramaProcedimentosDocument,
    baseOptions
  )
}
export function useOdontogramaProcedimentosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.OdontogramaProcedimentosQuery,
    Types.OdontogramaProcedimentosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.OdontogramaProcedimentosQuery,
    Types.OdontogramaProcedimentosQueryVariables
  >(OdontogramaProcedimentosDocument, baseOptions)
}
export type OdontogramaProcedimentosQueryHookResult = ReturnType<typeof useOdontogramaProcedimentosQuery>
export type OdontogramaProcedimentosLazyQueryHookResult = ReturnType<typeof useOdontogramaProcedimentosLazyQuery>
export type OdontogramaProcedimentosQueryResult = ApolloReactCommon.QueryResult<
  Types.OdontogramaProcedimentosQuery,
  Types.OdontogramaProcedimentosQueryVariables
>
export const OdontogramaDocument = gql`
  query Odontograma($id: ID!) {
    odontograma(id: $id) {
      id
      evolucoesDente {
        id
        dente {
          id
          numero
        }
        situacoesFace
        situacoesRaiz
        situacaoCoroaCima
        situacaoCoroaBaixo
        situacaoCoroaEsquerda
        situacaoCoroaDireita
        situacaoCoroaCentro
      }
      proteseTotalInferior
      proteseTotalSuperior
      contencao
      aparelho
    }
  }
`

/**
 * __useOdontogramaQuery__
 *
 * To run a query within a React component, call `useOdontogramaQuery` and pass it any options that fit your needs.
 * When your component renders, `useOdontogramaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOdontogramaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOdontogramaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.OdontogramaQuery, Types.OdontogramaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.OdontogramaQuery, Types.OdontogramaQueryVariables>(
    OdontogramaDocument,
    baseOptions
  )
}
export function useOdontogramaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.OdontogramaQuery, Types.OdontogramaQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.OdontogramaQuery, Types.OdontogramaQueryVariables>(
    OdontogramaDocument,
    baseOptions
  )
}
export type OdontogramaQueryHookResult = ReturnType<typeof useOdontogramaQuery>
export type OdontogramaLazyQueryHookResult = ReturnType<typeof useOdontogramaLazyQuery>
export type OdontogramaQueryResult = ApolloReactCommon.QueryResult<
  Types.OdontogramaQuery,
  Types.OdontogramaQueryVariables
>
export const OdontogramasDocument = gql`
  query Odontogramas($input: OdontogramasQueryInput!) {
    odontogramas(input: $input) {
      content {
        id
        evolucoesDente {
          id
          dente {
            id
            numero
          }
          situacoesRaiz
          situacoesFace
          situacaoCoroaCima
          situacaoCoroaDireita
          situacaoCoroaEsquerda
          situacaoCoroaBaixo
          situacaoCoroaCentro
        }
        proteseTotalSuperior
        proteseTotalInferior
        contencao
        aparelho
      }
    }
  }
`

/**
 * __useOdontogramasQuery__
 *
 * To run a query within a React component, call `useOdontogramasQuery` and pass it any options that fit your needs.
 * When your component renders, `useOdontogramasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOdontogramasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOdontogramasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.OdontogramasQuery, Types.OdontogramasQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.OdontogramasQuery, Types.OdontogramasQueryVariables>(
    OdontogramasDocument,
    baseOptions
  )
}
export function useOdontogramasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.OdontogramasQuery, Types.OdontogramasQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.OdontogramasQuery, Types.OdontogramasQueryVariables>(
    OdontogramasDocument,
    baseOptions
  )
}
export type OdontogramasQueryHookResult = ReturnType<typeof useOdontogramasQuery>
export type OdontogramasLazyQueryHookResult = ReturnType<typeof useOdontogramasLazyQuery>
export type OdontogramasQueryResult = ApolloReactCommon.QueryResult<
  Types.OdontogramasQuery,
  Types.OdontogramasQueryVariables
>
export const PeriodoOdontogramaSelectDocument = gql`
  query PeriodoOdontogramaSelect($input: OdontogramasQueryInput!) {
    odontogramas(input: $input) {
      content {
        id
        atendimentoProfissionalOdonto {
          id
          atendimentoProfissional {
            id
            finalizadoEm
            iniciadoEm
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePeriodoOdontogramaSelectQuery__
 *
 * To run a query within a React component, call `usePeriodoOdontogramaSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodoOdontogramaSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodoOdontogramaSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePeriodoOdontogramaSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PeriodoOdontogramaSelectQuery,
    Types.PeriodoOdontogramaSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.PeriodoOdontogramaSelectQuery, Types.PeriodoOdontogramaSelectQueryVariables>(
    PeriodoOdontogramaSelectDocument,
    baseOptions
  )
}
export function usePeriodoOdontogramaSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PeriodoOdontogramaSelectQuery,
    Types.PeriodoOdontogramaSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PeriodoOdontogramaSelectQuery,
    Types.PeriodoOdontogramaSelectQueryVariables
  >(PeriodoOdontogramaSelectDocument, baseOptions)
}
export type PeriodoOdontogramaSelectQueryHookResult = ReturnType<typeof usePeriodoOdontogramaSelectQuery>
export type PeriodoOdontogramaSelectLazyQueryHookResult = ReturnType<typeof usePeriodoOdontogramaSelectLazyQuery>
export type PeriodoOdontogramaSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.PeriodoOdontogramaSelectQuery,
  Types.PeriodoOdontogramaSelectQueryVariables
>
export const HistoricoEvolucoesOdontoTableDocument = gql`
  query HistoricoEvolucoesOdontoTable($input: EvolucoesProcedimentoOdontoInput!) {
    evolucoesProcedimentoOdonto(input: $input) {
      content {
        id
        atendimentoProfissionalOdonto {
          id
          atendimentoProfissional {
            id
            iniciadoEm
            lotacao {
              id
              profissional {
                id
                nome
              }
              cbo {
                id
                nome
              }
            }
          }
        }
        procedimentos {
          ... on Procedimento {
            id
            codigo
            descricao
          }
        }
        partesBucais {
          id
          tipoParteBucal
          descricao
          parteBucalEnum
        }
        observacao
        descricaoParteBucal
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useHistoricoEvolucoesOdontoTableQuery__
 *
 * To run a query within a React component, call `useHistoricoEvolucoesOdontoTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoEvolucoesOdontoTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoEvolucoesOdontoTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoEvolucoesOdontoTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoEvolucoesOdontoTableQuery,
    Types.HistoricoEvolucoesOdontoTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoEvolucoesOdontoTableQuery,
    Types.HistoricoEvolucoesOdontoTableQueryVariables
  >(HistoricoEvolucoesOdontoTableDocument, baseOptions)
}
export function useHistoricoEvolucoesOdontoTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoEvolucoesOdontoTableQuery,
    Types.HistoricoEvolucoesOdontoTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoEvolucoesOdontoTableQuery,
    Types.HistoricoEvolucoesOdontoTableQueryVariables
  >(HistoricoEvolucoesOdontoTableDocument, baseOptions)
}
export type HistoricoEvolucoesOdontoTableQueryHookResult = ReturnType<typeof useHistoricoEvolucoesOdontoTableQuery>
export type HistoricoEvolucoesOdontoTableLazyQueryHookResult = ReturnType<
  typeof useHistoricoEvolucoesOdontoTableLazyQuery
>
export type HistoricoEvolucoesOdontoTableQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoEvolucoesOdontoTableQuery,
  Types.HistoricoEvolucoesOdontoTableQueryVariables
>
export const PeriogramasCompletoDocument = gql`
  query PeriogramasCompleto($input: PeriogramaCompletoQueryInput!) {
    periogramasCompleto(input: $input) {
      id
      dente {
        id
        parteBucalEnum
      }
      excluido
      profundidadeVestibular
      recessaoVestibular
      profundidadeInterna
      recessaoInterna
      placaDVestibular
      placaVVestibular
      placaMVestibular
      sangramentoDVestibular
      sangramentoVVestibular
      sangramentoMVestibular
      placaDInterna
      placaVInterna
      placaMInterna
      sangramentoDInterna
      sangramentoVInterna
      sangramentoMInterna
      mobilidade
      lesaoDeFurca
      atendimentoProfissionalOdonto {
        id
        observacaoRPC
        atendimentoProfissional {
          id
          iniciadoEm
          lotacao {
            id
            cbo {
              id
              nome
            }
            profissional {
              id
              nome
            }
          }
        }
      }
    }
  }
`

/**
 * __usePeriogramasCompletoQuery__
 *
 * To run a query within a React component, call `usePeriogramasCompletoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriogramasCompletoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriogramasCompletoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePeriogramasCompletoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PeriogramasCompletoQuery,
    Types.PeriogramasCompletoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.PeriogramasCompletoQuery, Types.PeriogramasCompletoQueryVariables>(
    PeriogramasCompletoDocument,
    baseOptions
  )
}
export function usePeriogramasCompletoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PeriogramasCompletoQuery,
    Types.PeriogramasCompletoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.PeriogramasCompletoQuery, Types.PeriogramasCompletoQueryVariables>(
    PeriogramasCompletoDocument,
    baseOptions
  )
}
export type PeriogramasCompletoQueryHookResult = ReturnType<typeof usePeriogramasCompletoQuery>
export type PeriogramasCompletoLazyQueryHookResult = ReturnType<typeof usePeriogramasCompletoLazyQuery>
export type PeriogramasCompletoQueryResult = ApolloReactCommon.QueryResult<
  Types.PeriogramasCompletoQuery,
  Types.PeriogramasCompletoQueryVariables
>
export const HistoricoPeriogramaSimplificadoDocument = gql`
  query HistoricoPeriogramaSimplificado($input: PeriogramaSimplificadoQueryInput!) {
    periogramasSimplificados(input: $input) {
      content {
        id
        avaliacaoSextante1
        avaliacaoSextante2
        avaliacaoSextante3
        avaliacaoSextante4
        avaliacaoSextante5
        avaliacaoSextante5
        avaliacaoSextante6
        observacao
        atendimentoProfissionalOdonto {
          id
          atendimentoProfissional {
            id
            iniciadoEm
            lotacao {
              id
              cbo {
                id
                nome
              }
              profissional {
                id
                nome
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useHistoricoPeriogramaSimplificadoQuery__
 *
 * To run a query within a React component, call `useHistoricoPeriogramaSimplificadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoPeriogramaSimplificadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoPeriogramaSimplificadoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoPeriogramaSimplificadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoPeriogramaSimplificadoQuery,
    Types.HistoricoPeriogramaSimplificadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HistoricoPeriogramaSimplificadoQuery,
    Types.HistoricoPeriogramaSimplificadoQueryVariables
  >(HistoricoPeriogramaSimplificadoDocument, baseOptions)
}
export function useHistoricoPeriogramaSimplificadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoPeriogramaSimplificadoQuery,
    Types.HistoricoPeriogramaSimplificadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoPeriogramaSimplificadoQuery,
    Types.HistoricoPeriogramaSimplificadoQueryVariables
  >(HistoricoPeriogramaSimplificadoDocument, baseOptions)
}
export type HistoricoPeriogramaSimplificadoQueryHookResult = ReturnType<typeof useHistoricoPeriogramaSimplificadoQuery>
export type HistoricoPeriogramaSimplificadoLazyQueryHookResult = ReturnType<
  typeof useHistoricoPeriogramaSimplificadoLazyQuery
>
export type HistoricoPeriogramaSimplificadoQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoPeriogramaSimplificadoQuery,
  Types.HistoricoPeriogramaSimplificadoQueryVariables
>
export const HistoricoTecidosMolesDocument = gql`
  query HistoricoTecidosMoles($input: TecidosMolesQueryInput!) {
    tecidosMoles(input: $input) {
      content {
        id
        parteBucal {
          id
          descricao
        }
        cid10 {
          ...Cid10
        }
        caracteristicasLesao {
          id
          caracteristicaLesao
        }
        evolucaoProcedimentoOdonto {
          id
          procedimentos {
            ... on Procedimento {
              id
              codigo
              descricao
            }
          }
          observacao
        }
        descricaoSintomatologia
        atendimentoProfissionalOdonto {
          id
          atendimentoProfissional {
            id
            iniciadoEm
            lotacao {
              id
              profissional {
                id
                nome
              }
              cbo {
                id
                nome
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Cid10FragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useHistoricoTecidosMolesQuery__
 *
 * To run a query within a React component, call `useHistoricoTecidosMolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoTecidosMolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoTecidosMolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoTecidosMolesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoTecidosMolesQuery,
    Types.HistoricoTecidosMolesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoTecidosMolesQuery, Types.HistoricoTecidosMolesQueryVariables>(
    HistoricoTecidosMolesDocument,
    baseOptions
  )
}
export function useHistoricoTecidosMolesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoTecidosMolesQuery,
    Types.HistoricoTecidosMolesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoTecidosMolesQuery, Types.HistoricoTecidosMolesQueryVariables>(
    HistoricoTecidosMolesDocument,
    baseOptions
  )
}
export type HistoricoTecidosMolesQueryHookResult = ReturnType<typeof useHistoricoTecidosMolesQuery>
export type HistoricoTecidosMolesLazyQueryHookResult = ReturnType<typeof useHistoricoTecidosMolesLazyQuery>
export type HistoricoTecidosMolesQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoTecidosMolesQuery,
  Types.HistoricoTecidosMolesQueryVariables
>
export const GruposExamesDocument = gql`
  query GruposExames($input: GrupoExameQueryInput!) {
    gruposExame(input: $input) {
      content {
        id
        nome
        idadeMinima
        idadeMaxima
        procedimentos {
          id
          descricao
          codigo
          idadeMinima
          idadeMaxima
          sexo
          procedimentoReferencia {
            id
            descricao
            codigo
          }
        }
      }
    }
  }
`

/**
 * __useGruposExamesQuery__
 *
 * To run a query within a React component, call `useGruposExamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGruposExamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGruposExamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGruposExamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GruposExamesQuery, Types.GruposExamesQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.GruposExamesQuery, Types.GruposExamesQueryVariables>(
    GruposExamesDocument,
    baseOptions
  )
}
export function useGruposExamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GruposExamesQuery, Types.GruposExamesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.GruposExamesQuery, Types.GruposExamesQueryVariables>(
    GruposExamesDocument,
    baseOptions
  )
}
export type GruposExamesQueryHookResult = ReturnType<typeof useGruposExamesQuery>
export type GruposExamesLazyQueryHookResult = ReturnType<typeof useGruposExamesLazyQuery>
export type GruposExamesQueryResult = ApolloReactCommon.QueryResult<
  Types.GruposExamesQuery,
  Types.GruposExamesQueryVariables
>
export const HistoricoPlanosDeCuidadoDocument = gql`
  query HistoricoPlanosDeCuidado($atendProfId: ID!) {
    planosDeCuidado(atendProfId: $atendProfId) {
      id
      atendimentoProfissionalObservacao {
        id
        atendimentoProfissional {
          id
          lotacao {
            id
            profissional {
              id
              nome
            }
            cbo {
              id
              nome
            }
          }
        }
        atendimentoObservacao {
          id
          atendimentoProfissionalIniciador {
            id
            iniciadoEm
            lotacao {
              id
              profissional {
                id
                nome
              }
            }
          }
        }
      }
      descricao
      dataDescricao
    }
  }
`

/**
 * __useHistoricoPlanosDeCuidadoQuery__
 *
 * To run a query within a React component, call `useHistoricoPlanosDeCuidadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoPlanosDeCuidadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoPlanosDeCuidadoQuery({
 *   variables: {
 *      atendProfId: // value for 'atendProfId'
 *   },
 * });
 */
export function useHistoricoPlanosDeCuidadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoPlanosDeCuidadoQuery,
    Types.HistoricoPlanosDeCuidadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoPlanosDeCuidadoQuery, Types.HistoricoPlanosDeCuidadoQueryVariables>(
    HistoricoPlanosDeCuidadoDocument,
    baseOptions
  )
}
export function useHistoricoPlanosDeCuidadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoPlanosDeCuidadoQuery,
    Types.HistoricoPlanosDeCuidadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HistoricoPlanosDeCuidadoQuery,
    Types.HistoricoPlanosDeCuidadoQueryVariables
  >(HistoricoPlanosDeCuidadoDocument, baseOptions)
}
export type HistoricoPlanosDeCuidadoQueryHookResult = ReturnType<typeof useHistoricoPlanosDeCuidadoQuery>
export type HistoricoPlanosDeCuidadoLazyQueryHookResult = ReturnType<typeof useHistoricoPlanosDeCuidadoLazyQuery>
export type HistoricoPlanosDeCuidadoQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoPlanosDeCuidadoQuery,
  Types.HistoricoPlanosDeCuidadoQueryVariables
>
export const OrientacoesDocument = gql`
  query Orientacoes($orientacaoQueryInput: OrientacaoQueryInput!) {
    orientacoes(input: $orientacaoQueryInput) {
      id
      descricao
      atendimentoProfissional {
        id
        iniciadoEm
        lotacao {
          id
          profissional {
            id
            nome
            numeroConselhoClasse
            conselhoClasse {
              id
              sigla
            }
            ufEmissoraConselhoClasse {
              id
              nome
              sigla
            }
          }
          municipio {
            id
            nome
            uf {
              id
              sigla
            }
          }
          unidadeSaude {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
      }
    }
  }
`

/**
 * __useOrientacoesQuery__
 *
 * To run a query within a React component, call `useOrientacoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrientacoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrientacoesQuery({
 *   variables: {
 *      orientacaoQueryInput: // value for 'orientacaoQueryInput'
 *   },
 * });
 */
export function useOrientacoesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.OrientacoesQuery, Types.OrientacoesQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.OrientacoesQuery, Types.OrientacoesQueryVariables>(
    OrientacoesDocument,
    baseOptions
  )
}
export function useOrientacoesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.OrientacoesQuery, Types.OrientacoesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.OrientacoesQuery, Types.OrientacoesQueryVariables>(
    OrientacoesDocument,
    baseOptions
  )
}
export type OrientacoesQueryHookResult = ReturnType<typeof useOrientacoesQuery>
export type OrientacoesLazyQueryHookResult = ReturnType<typeof useOrientacoesLazyQuery>
export type OrientacoesQueryResult = ApolloReactCommon.QueryResult<
  Types.OrientacoesQuery,
  Types.OrientacoesQueryVariables
>
export const AuditaImpressaoOrientacaoDocument = gql`
  mutation auditaImpressaoOrientacao($prontuarioId: ID!, $atendimentoId: ID, $orientacaoId: ID) {
    auditaImpressaoOrientacao(prontuarioId: $prontuarioId, atendimentoId: $atendimentoId, orientacaoId: $orientacaoId)
  }
`
export type AuditaImpressaoOrientacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AuditaImpressaoOrientacaoMutation,
  Types.AuditaImpressaoOrientacaoMutationVariables
>

/**
 * __useAuditaImpressaoOrientacaoMutation__
 *
 * To run a mutation, you first call `useAuditaImpressaoOrientacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuditaImpressaoOrientacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auditaImpressaoOrientacaoMutation, { data, loading, error }] = useAuditaImpressaoOrientacaoMutation({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *      atendimentoId: // value for 'atendimentoId'
 *      orientacaoId: // value for 'orientacaoId'
 *   },
 * });
 */
export function useAuditaImpressaoOrientacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AuditaImpressaoOrientacaoMutation,
    Types.AuditaImpressaoOrientacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AuditaImpressaoOrientacaoMutation,
    Types.AuditaImpressaoOrientacaoMutationVariables
  >(AuditaImpressaoOrientacaoDocument, baseOptions)
}
export type AuditaImpressaoOrientacaoMutationHookResult = ReturnType<typeof useAuditaImpressaoOrientacaoMutation>
export type AuditaImpressaoOrientacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.AuditaImpressaoOrientacaoMutation
>
export type AuditaImpressaoOrientacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AuditaImpressaoOrientacaoMutation,
  Types.AuditaImpressaoOrientacaoMutationVariables
>
export const PrincipiosAtivosAlergiasDocument = gql`
  query PrincipiosAtivosAlergias($prontuarioId: ID!) {
    principiosAtivosAlergias(prontuarioId: $prontuarioId) {
      id
    }
  }
`

/**
 * __usePrincipiosAtivosAlergiasQuery__
 *
 * To run a query within a React component, call `usePrincipiosAtivosAlergiasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrincipiosAtivosAlergiasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrincipiosAtivosAlergiasQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function usePrincipiosAtivosAlergiasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PrincipiosAtivosAlergiasQuery,
    Types.PrincipiosAtivosAlergiasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.PrincipiosAtivosAlergiasQuery, Types.PrincipiosAtivosAlergiasQueryVariables>(
    PrincipiosAtivosAlergiasDocument,
    baseOptions
  )
}
export function usePrincipiosAtivosAlergiasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PrincipiosAtivosAlergiasQuery,
    Types.PrincipiosAtivosAlergiasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PrincipiosAtivosAlergiasQuery,
    Types.PrincipiosAtivosAlergiasQueryVariables
  >(PrincipiosAtivosAlergiasDocument, baseOptions)
}
export type PrincipiosAtivosAlergiasQueryHookResult = ReturnType<typeof usePrincipiosAtivosAlergiasQuery>
export type PrincipiosAtivosAlergiasLazyQueryHookResult = ReturnType<typeof usePrincipiosAtivosAlergiasLazyQuery>
export type PrincipiosAtivosAlergiasQueryResult = ApolloReactCommon.QueryResult<
  Types.PrincipiosAtivosAlergiasQuery,
  Types.PrincipiosAtivosAlergiasQueryVariables
>
export const HistoricoPrescricaoDocument = gql`
  query HistoricoPrescricao($input: HistoricoPrescricaoQueryInput!) {
    historicoPrescricao(input: $input) {
      content {
        id
        versao
        versaoAnterior
        finalizadoEm
        lotacao {
          id
          profissional {
            id
            nome
            numeroConselhoClasse
            ufEmissoraConselhoClasse {
              id
              sigla
            }
            conselhoClasse {
              id
              sigla
            }
          }
        }
        receitaMedicamento {
          id
          medicamento {
            id
            principioAtivo
            concentracao
            unidadeFornecimento
            medicamentoCatmat {
              id
              ativo
              volume
              codigoCatMat
              codigoRename
              unidadeFornecimento {
                id
                nome
                nomePlural
              }
              principioAtivo {
                id
                nome
                listaMaterial {
                  id
                  tipo
                  tipoReceita
                }
              }
            }
            formaFarmaceutica {
              id
              nome
            }
          }
          aplicacaoMedicamento {
            id
            nome
          }
          quantidadeReceitada
          recomendacoes
          dose
          qtDose
          qtDoseManha
          qtDoseTarde
          qtDoseNoite
          unidadeMedidaDose {
            id
            nome
            nomePlural
          }
          frequenciaDose
          posologia
          tipoFrequenciaDose
          qtPeriodoFrequencia
          medidaTempoFrequencia
          dtInicioTratamento
          dtFimTratamento
          qtDuracaoTratamento
          medidaTempoTratamento
          usoContinuo
          registroManual
          doseUnica
          tipoReceita
          tratamentoInterrompido
          justificativaInterrupcao
          dtInterrupcao
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useHistoricoPrescricaoQuery__
 *
 * To run a query within a React component, call `useHistoricoPrescricaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricoPrescricaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricoPrescricaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHistoricoPrescricaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HistoricoPrescricaoQuery,
    Types.HistoricoPrescricaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HistoricoPrescricaoQuery, Types.HistoricoPrescricaoQueryVariables>(
    HistoricoPrescricaoDocument,
    baseOptions
  )
}
export function useHistoricoPrescricaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HistoricoPrescricaoQuery,
    Types.HistoricoPrescricaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HistoricoPrescricaoQuery, Types.HistoricoPrescricaoQueryVariables>(
    HistoricoPrescricaoDocument,
    baseOptions
  )
}
export type HistoricoPrescricaoQueryHookResult = ReturnType<typeof useHistoricoPrescricaoQuery>
export type HistoricoPrescricaoLazyQueryHookResult = ReturnType<typeof useHistoricoPrescricaoLazyQuery>
export type HistoricoPrescricaoQueryResult = ApolloReactCommon.QueryResult<
  Types.HistoricoPrescricaoQuery,
  Types.HistoricoPrescricaoQueryVariables
>
export const ConexaoHorusDocument = gql`
  query ConexaoHorus {
    conexao {
      internet {
        habilitado
      }
      horus {
        id
        habilitado
      }
    }
  }
`

/**
 * __useConexaoHorusQuery__
 *
 * To run a query within a React component, call `useConexaoHorusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConexaoHorusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConexaoHorusQuery({
 *   variables: {
 *   },
 * });
 */
export function useConexaoHorusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ConexaoHorusQuery, Types.ConexaoHorusQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ConexaoHorusQuery, Types.ConexaoHorusQueryVariables>(
    ConexaoHorusDocument,
    baseOptions
  )
}
export function useConexaoHorusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ConexaoHorusQuery, Types.ConexaoHorusQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ConexaoHorusQuery, Types.ConexaoHorusQueryVariables>(
    ConexaoHorusDocument,
    baseOptions
  )
}
export type ConexaoHorusQueryHookResult = ReturnType<typeof useConexaoHorusQuery>
export type ConexaoHorusLazyQueryHookResult = ReturnType<typeof useConexaoHorusLazyQuery>
export type ConexaoHorusQueryResult = ApolloReactCommon.QueryResult<
  Types.ConexaoHorusQuery,
  Types.ConexaoHorusQueryVariables
>
export const ConsultaEstoqueHorusDocument = gql`
  query ConsultaEstoqueHorus($filter: ConsultaEstoqueHorusQueryInput!) {
    consultaEstoqueHorus(input: $filter)
  }
`

/**
 * __useConsultaEstoqueHorusQuery__
 *
 * To run a query within a React component, call `useConsultaEstoqueHorusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultaEstoqueHorusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultaEstoqueHorusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useConsultaEstoqueHorusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConsultaEstoqueHorusQuery,
    Types.ConsultaEstoqueHorusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConsultaEstoqueHorusQuery, Types.ConsultaEstoqueHorusQueryVariables>(
    ConsultaEstoqueHorusDocument,
    baseOptions
  )
}
export function useConsultaEstoqueHorusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConsultaEstoqueHorusQuery,
    Types.ConsultaEstoqueHorusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConsultaEstoqueHorusQuery, Types.ConsultaEstoqueHorusQueryVariables>(
    ConsultaEstoqueHorusDocument,
    baseOptions
  )
}
export type ConsultaEstoqueHorusQueryHookResult = ReturnType<typeof useConsultaEstoqueHorusQuery>
export type ConsultaEstoqueHorusLazyQueryHookResult = ReturnType<typeof useConsultaEstoqueHorusLazyQuery>
export type ConsultaEstoqueHorusQueryResult = ApolloReactCommon.QueryResult<
  Types.ConsultaEstoqueHorusQuery,
  Types.ConsultaEstoqueHorusQueryVariables
>
export const PrescricaoDigitalEnabledDocument = gql`
  query PrescricaoDigitalEnabled {
    info {
      isPrescricaoDigitalEnabled
    }
  }
`

/**
 * __usePrescricaoDigitalEnabledQuery__
 *
 * To run a query within a React component, call `usePrescricaoDigitalEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescricaoDigitalEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescricaoDigitalEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrescricaoDigitalEnabledQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PrescricaoDigitalEnabledQuery,
    Types.PrescricaoDigitalEnabledQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.PrescricaoDigitalEnabledQuery, Types.PrescricaoDigitalEnabledQueryVariables>(
    PrescricaoDigitalEnabledDocument,
    baseOptions
  )
}
export function usePrescricaoDigitalEnabledLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PrescricaoDigitalEnabledQuery,
    Types.PrescricaoDigitalEnabledQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PrescricaoDigitalEnabledQuery,
    Types.PrescricaoDigitalEnabledQueryVariables
  >(PrescricaoDigitalEnabledDocument, baseOptions)
}
export type PrescricaoDigitalEnabledQueryHookResult = ReturnType<typeof usePrescricaoDigitalEnabledQuery>
export type PrescricaoDigitalEnabledLazyQueryHookResult = ReturnType<typeof usePrescricaoDigitalEnabledLazyQuery>
export type PrescricaoDigitalEnabledQueryResult = ApolloReactCommon.QueryResult<
  Types.PrescricaoDigitalEnabledQuery,
  Types.PrescricaoDigitalEnabledQueryVariables
>
export const CategoriasProibidasDocument = gql`
  query CategoriasProibidas {
    categoriasProibidas
  }
`

/**
 * __useCategoriasProibidasQuery__
 *
 * To run a query within a React component, call `useCategoriasProibidasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriasProibidasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriasProibidasQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriasProibidasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CategoriasProibidasQuery,
    Types.CategoriasProibidasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CategoriasProibidasQuery, Types.CategoriasProibidasQueryVariables>(
    CategoriasProibidasDocument,
    baseOptions
  )
}
export function useCategoriasProibidasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CategoriasProibidasQuery,
    Types.CategoriasProibidasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CategoriasProibidasQuery, Types.CategoriasProibidasQueryVariables>(
    CategoriasProibidasDocument,
    baseOptions
  )
}
export type CategoriasProibidasQueryHookResult = ReturnType<typeof useCategoriasProibidasQuery>
export type CategoriasProibidasLazyQueryHookResult = ReturnType<typeof useCategoriasProibidasLazyQuery>
export type CategoriasProibidasQueryResult = ApolloReactCommon.QueryResult<
  Types.CategoriasProibidasQuery,
  Types.CategoriasProibidasQueryVariables
>
export const CidadaoEmailUpdateDocument = gql`
  mutation CidadaoEmailUpdate($input: AtualizarEmailCidadaoInput!) {
    atualizarEmailCidadao(input: $input) {
      id
      email
    }
  }
`
export type CidadaoEmailUpdateMutationFn = ApolloReactCommon.MutationFunction<
  Types.CidadaoEmailUpdateMutation,
  Types.CidadaoEmailUpdateMutationVariables
>

/**
 * __useCidadaoEmailUpdateMutation__
 *
 * To run a mutation, you first call `useCidadaoEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCidadaoEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cidadaoEmailUpdateMutation, { data, loading, error }] = useCidadaoEmailUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoEmailUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CidadaoEmailUpdateMutation,
    Types.CidadaoEmailUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.CidadaoEmailUpdateMutation, Types.CidadaoEmailUpdateMutationVariables>(
    CidadaoEmailUpdateDocument,
    baseOptions
  )
}
export type CidadaoEmailUpdateMutationHookResult = ReturnType<typeof useCidadaoEmailUpdateMutation>
export type CidadaoEmailUpdateMutationResult = ApolloReactCommon.MutationResult<Types.CidadaoEmailUpdateMutation>
export type CidadaoEmailUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CidadaoEmailUpdateMutation,
  Types.CidadaoEmailUpdateMutationVariables
>
export const CidadaoInformacoesContatoDocument = gql`
  query CidadaoInformacoesContato($id: ID!) {
    contatoCidadao(id: $id) {
      id
      email
    }
  }
`

/**
 * __useCidadaoInformacoesContatoQuery__
 *
 * To run a query within a React component, call `useCidadaoInformacoesContatoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoInformacoesContatoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoInformacoesContatoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCidadaoInformacoesContatoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoInformacoesContatoQuery,
    Types.CidadaoInformacoesContatoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CidadaoInformacoesContatoQuery, Types.CidadaoInformacoesContatoQueryVariables>(
    CidadaoInformacoesContatoDocument,
    baseOptions
  )
}
export function useCidadaoInformacoesContatoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoInformacoesContatoQuery,
    Types.CidadaoInformacoesContatoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoInformacoesContatoQuery,
    Types.CidadaoInformacoesContatoQueryVariables
  >(CidadaoInformacoesContatoDocument, baseOptions)
}
export type CidadaoInformacoesContatoQueryHookResult = ReturnType<typeof useCidadaoInformacoesContatoQuery>
export type CidadaoInformacoesContatoLazyQueryHookResult = ReturnType<typeof useCidadaoInformacoesContatoLazyQuery>
export type CidadaoInformacoesContatoQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoInformacoesContatoQuery,
  Types.CidadaoInformacoesContatoQueryVariables
>
export const TiposReceitaProibidosDocument = gql`
  query TiposReceitaProibidos {
    tiposReceitaProibidos
  }
`

/**
 * __useTiposReceitaProibidosQuery__
 *
 * To run a query within a React component, call `useTiposReceitaProibidosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTiposReceitaProibidosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTiposReceitaProibidosQuery({
 *   variables: {
 *   },
 * });
 */
export function useTiposReceitaProibidosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TiposReceitaProibidosQuery,
    Types.TiposReceitaProibidosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.TiposReceitaProibidosQuery, Types.TiposReceitaProibidosQueryVariables>(
    TiposReceitaProibidosDocument,
    baseOptions
  )
}
export function useTiposReceitaProibidosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TiposReceitaProibidosQuery,
    Types.TiposReceitaProibidosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.TiposReceitaProibidosQuery, Types.TiposReceitaProibidosQueryVariables>(
    TiposReceitaProibidosDocument,
    baseOptions
  )
}
export type TiposReceitaProibidosQueryHookResult = ReturnType<typeof useTiposReceitaProibidosQuery>
export type TiposReceitaProibidosLazyQueryHookResult = ReturnType<typeof useTiposReceitaProibidosLazyQuery>
export type TiposReceitaProibidosQueryResult = ApolloReactCommon.QueryResult<
  Types.TiposReceitaProibidosQuery,
  Types.TiposReceitaProibidosQueryVariables
>
export const CidPrimeiroAtendimentoPreNatalDocument = gql`
  query CidPrimeiroAtendimentoPreNatal($prontuarioId: ID!) {
    cidPrimeiroAtendimentoPreNatal(prontuarioId: $prontuarioId) {
      id
      codigo
      nome
    }
  }
`

/**
 * __useCidPrimeiroAtendimentoPreNatalQuery__
 *
 * To run a query within a React component, call `useCidPrimeiroAtendimentoPreNatalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidPrimeiroAtendimentoPreNatalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidPrimeiroAtendimentoPreNatalQuery({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useCidPrimeiroAtendimentoPreNatalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidPrimeiroAtendimentoPreNatalQuery,
    Types.CidPrimeiroAtendimentoPreNatalQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidPrimeiroAtendimentoPreNatalQuery,
    Types.CidPrimeiroAtendimentoPreNatalQueryVariables
  >(CidPrimeiroAtendimentoPreNatalDocument, baseOptions)
}
export function useCidPrimeiroAtendimentoPreNatalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidPrimeiroAtendimentoPreNatalQuery,
    Types.CidPrimeiroAtendimentoPreNatalQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidPrimeiroAtendimentoPreNatalQuery,
    Types.CidPrimeiroAtendimentoPreNatalQueryVariables
  >(CidPrimeiroAtendimentoPreNatalDocument, baseOptions)
}
export type CidPrimeiroAtendimentoPreNatalQueryHookResult = ReturnType<typeof useCidPrimeiroAtendimentoPreNatalQuery>
export type CidPrimeiroAtendimentoPreNatalLazyQueryHookResult = ReturnType<
  typeof useCidPrimeiroAtendimentoPreNatalLazyQuery
>
export type CidPrimeiroAtendimentoPreNatalQueryResult = ApolloReactCommon.QueryResult<
  Types.CidPrimeiroAtendimentoPreNatalQuery,
  Types.CidPrimeiroAtendimentoPreNatalQueryVariables
>
export const IdadeGestacionalDocument = gql`
  query IdadeGestacional($input: IdadeGestacionalQueryInput!) {
    idadeGestacional(input: $input) {
      dias
      isEcografica
    }
  }
`

/**
 * __useIdadeGestacionalQuery__
 *
 * To run a query within a React component, call `useIdadeGestacionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdadeGestacionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdadeGestacionalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIdadeGestacionalQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.IdadeGestacionalQuery, Types.IdadeGestacionalQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.IdadeGestacionalQuery, Types.IdadeGestacionalQueryVariables>(
    IdadeGestacionalDocument,
    baseOptions
  )
}
export function useIdadeGestacionalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.IdadeGestacionalQuery, Types.IdadeGestacionalQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.IdadeGestacionalQuery, Types.IdadeGestacionalQueryVariables>(
    IdadeGestacionalDocument,
    baseOptions
  )
}
export type IdadeGestacionalQueryHookResult = ReturnType<typeof useIdadeGestacionalQuery>
export type IdadeGestacionalLazyQueryHookResult = ReturnType<typeof useIdadeGestacionalLazyQuery>
export type IdadeGestacionalQueryResult = ApolloReactCommon.QueryResult<
  Types.IdadeGestacionalQuery,
  Types.IdadeGestacionalQueryVariables
>
export const EscutaInicialSaveDocument = gql`
  mutation EscutaInicialSave($input: EscutaInicialInput!) {
    salvarEscutaInicial(input: $input) {
      id
    }
  }
`
export type EscutaInicialSaveMutationFn = ApolloReactCommon.MutationFunction<
  Types.EscutaInicialSaveMutation,
  Types.EscutaInicialSaveMutationVariables
>

/**
 * __useEscutaInicialSaveMutation__
 *
 * To run a mutation, you first call `useEscutaInicialSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEscutaInicialSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [escutaInicialSaveMutation, { data, loading, error }] = useEscutaInicialSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEscutaInicialSaveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EscutaInicialSaveMutation,
    Types.EscutaInicialSaveMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.EscutaInicialSaveMutation, Types.EscutaInicialSaveMutationVariables>(
    EscutaInicialSaveDocument,
    baseOptions
  )
}
export type EscutaInicialSaveMutationHookResult = ReturnType<typeof useEscutaInicialSaveMutation>
export type EscutaInicialSaveMutationResult = ApolloReactCommon.MutationResult<Types.EscutaInicialSaveMutation>
export type EscutaInicialSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EscutaInicialSaveMutation,
  Types.EscutaInicialSaveMutationVariables
>
export const EscutaInicialViewDocument = gql`
  query EscutaInicialView($atendimentoId: ID!) {
    atendimento(id: $atendimentoId) {
      id
      statusAtendimento
      cidadao {
        id
        cns
        cpf
        nome
        nomeSocial
        dataNascimento
        sexo
        identidadeGeneroDbEnum
      }
      prontuario {
        id
        gestacoes {
          id
          inicio
          fim
        }
        preNatalAtivo {
          id
          ultimaDum
        }
      }
      iniciadoEm
      tiposServico {
        id
      }
      isAgendado
      atendimentoProfissional {
        id
        tipo
        iniciadoEm
        rascunho
        estagio {
          id
        }
        lotacao {
          id
          profissional {
            id
            cpf
            cns
            nome
          }
          cbo {
            id
            nome
            cbo2002
          }
          unidadeSaude {
            id
            nome
            cnes
          }
          municipio {
            id
            nome
            uf {
              id
              nome
              sigla
            }
          }
        }
      }
    }
    procedimentosAutomaticos {
      ...Procedimento
    }
  }
  ${ProcedimentoFragmentDoc}
`

/**
 * __useEscutaInicialViewQuery__
 *
 * To run a query within a React component, call `useEscutaInicialViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscutaInicialViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscutaInicialViewQuery({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useEscutaInicialViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EscutaInicialViewQuery, Types.EscutaInicialViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EscutaInicialViewQuery, Types.EscutaInicialViewQueryVariables>(
    EscutaInicialViewDocument,
    baseOptions
  )
}
export function useEscutaInicialViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EscutaInicialViewQuery,
    Types.EscutaInicialViewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EscutaInicialViewQuery, Types.EscutaInicialViewQueryVariables>(
    EscutaInicialViewDocument,
    baseOptions
  )
}
export type EscutaInicialViewQueryHookResult = ReturnType<typeof useEscutaInicialViewQuery>
export type EscutaInicialViewLazyQueryHookResult = ReturnType<typeof useEscutaInicialViewLazyQuery>
export type EscutaInicialViewQueryResult = ApolloReactCommon.QueryResult<
  Types.EscutaInicialViewQuery,
  Types.EscutaInicialViewQueryVariables
>
export const SalvarEscutaInicialParaAprovacaoDocument = gql`
  mutation SalvarEscutaInicialParaAprovacao($input: EscutaInicialAprovacaoInput!) {
    salvarEscutaInicialParaAprovacao(input: $input)
  }
`
export type SalvarEscutaInicialParaAprovacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarEscutaInicialParaAprovacaoMutation,
  Types.SalvarEscutaInicialParaAprovacaoMutationVariables
>

/**
 * __useSalvarEscutaInicialParaAprovacaoMutation__
 *
 * To run a mutation, you first call `useSalvarEscutaInicialParaAprovacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarEscutaInicialParaAprovacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarEscutaInicialParaAprovacaoMutation, { data, loading, error }] = useSalvarEscutaInicialParaAprovacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarEscutaInicialParaAprovacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarEscutaInicialParaAprovacaoMutation,
    Types.SalvarEscutaInicialParaAprovacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarEscutaInicialParaAprovacaoMutation,
    Types.SalvarEscutaInicialParaAprovacaoMutationVariables
  >(SalvarEscutaInicialParaAprovacaoDocument, baseOptions)
}
export type SalvarEscutaInicialParaAprovacaoMutationHookResult = ReturnType<
  typeof useSalvarEscutaInicialParaAprovacaoMutation
>
export type SalvarEscutaInicialParaAprovacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarEscutaInicialParaAprovacaoMutation
>
export type SalvarEscutaInicialParaAprovacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarEscutaInicialParaAprovacaoMutation,
  Types.SalvarEscutaInicialParaAprovacaoMutationVariables
>
export const EscutaInicialDetailDocument = gql`
  query EscutaInicialDetail($input: HistoricoDetailQueryInput!) {
    escutaInicialDetail(input: $input) {
      id
      ...HistoricoSubjetivoPecFragment
      evolucaoSubjetivo {
        id
        acompanhadoEspecialidade
      }
      classificacaoRisco
      medicoes {
        ...MedicoesFragment
        valorImc
      }
      procedimentosAtendimento {
        id
        procedimento {
          id
          descricao
          codigo
          tipoProcedimento
        }
        obrigatorio
        automatico
      }
      ...HistoricoDesfechoPecFragment
      ...HistoricoInfoAdministrativasPecFragment
    }
  }
  ${HistoricoSubjetivoPecFragmentFragmentDoc}
  ${MedicoesFragmentFragmentDoc}
  ${HistoricoDesfechoPecFragmentFragmentDoc}
  ${HistoricoInfoAdministrativasPecFragmentFragmentDoc}
`

/**
 * __useEscutaInicialDetailQuery__
 *
 * To run a query within a React component, call `useEscutaInicialDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscutaInicialDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscutaInicialDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEscutaInicialDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EscutaInicialDetailQuery,
    Types.EscutaInicialDetailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EscutaInicialDetailQuery, Types.EscutaInicialDetailQueryVariables>(
    EscutaInicialDetailDocument,
    baseOptions
  )
}
export function useEscutaInicialDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EscutaInicialDetailQuery,
    Types.EscutaInicialDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EscutaInicialDetailQuery, Types.EscutaInicialDetailQueryVariables>(
    EscutaInicialDetailDocument,
    baseOptions
  )
}
export type EscutaInicialDetailQueryHookResult = ReturnType<typeof useEscutaInicialDetailQuery>
export type EscutaInicialDetailLazyQueryHookResult = ReturnType<typeof useEscutaInicialDetailLazyQuery>
export type EscutaInicialDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.EscutaInicialDetailQuery,
  Types.EscutaInicialDetailQueryVariables
>
export const EscutaInicialVisualizarDocument = gql`
  query EscutaInicialVisualizar($atendimentoId: ID!) {
    atendimento(id: $atendimentoId) {
      id
      cidadao {
        id
      }
      isAgendado
      escutaInicialAtendProf {
        id
        iniciadoEm
      }
    }
  }
`

/**
 * __useEscutaInicialVisualizarQuery__
 *
 * To run a query within a React component, call `useEscutaInicialVisualizarQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscutaInicialVisualizarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscutaInicialVisualizarQuery({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useEscutaInicialVisualizarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EscutaInicialVisualizarQuery,
    Types.EscutaInicialVisualizarQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EscutaInicialVisualizarQuery, Types.EscutaInicialVisualizarQueryVariables>(
    EscutaInicialVisualizarDocument,
    baseOptions
  )
}
export function useEscutaInicialVisualizarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EscutaInicialVisualizarQuery,
    Types.EscutaInicialVisualizarQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EscutaInicialVisualizarQuery, Types.EscutaInicialVisualizarQueryVariables>(
    EscutaInicialVisualizarDocument,
    baseOptions
  )
}
export type EscutaInicialVisualizarQueryHookResult = ReturnType<typeof useEscutaInicialVisualizarQuery>
export type EscutaInicialVisualizarLazyQueryHookResult = ReturnType<typeof useEscutaInicialVisualizarLazyQuery>
export type EscutaInicialVisualizarQueryResult = ApolloReactCommon.QueryResult<
  Types.EscutaInicialVisualizarQuery,
  Types.EscutaInicialVisualizarQueryVariables
>
export const BuscaCidadaoPropriedadesDocument = gql`
  query BuscaCidadaoPropriedades {
    info {
      buscaCidadaoPorPropriedadesEnabled
    }
  }
`

/**
 * __useBuscaCidadaoPropriedadesQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoPropriedadesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoPropriedadesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoPropriedadesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBuscaCidadaoPropriedadesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaCidadaoPropriedadesQuery,
    Types.BuscaCidadaoPropriedadesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BuscaCidadaoPropriedadesQuery, Types.BuscaCidadaoPropriedadesQueryVariables>(
    BuscaCidadaoPropriedadesDocument,
    baseOptions
  )
}
export function useBuscaCidadaoPropriedadesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoPropriedadesQuery,
    Types.BuscaCidadaoPropriedadesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BuscaCidadaoPropriedadesQuery,
    Types.BuscaCidadaoPropriedadesQueryVariables
  >(BuscaCidadaoPropriedadesDocument, baseOptions)
}
export type BuscaCidadaoPropriedadesQueryHookResult = ReturnType<typeof useBuscaCidadaoPropriedadesQuery>
export type BuscaCidadaoPropriedadesLazyQueryHookResult = ReturnType<typeof useBuscaCidadaoPropriedadesLazyQuery>
export type BuscaCidadaoPropriedadesQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoPropriedadesQuery,
  Types.BuscaCidadaoPropriedadesQueryVariables
>
export const EquipeAtendimentoDocument = gql`
  query EquipeAtendimento($id: ID!) {
    equipe(id: $id) {
      id
      nome
      ine
      tipoEquipe {
        id
        nome
        sigla
      }
    }
  }
`

/**
 * __useEquipeAtendimentoQuery__
 *
 * To run a query within a React component, call `useEquipeAtendimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeAtendimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeAtendimentoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEquipeAtendimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipeAtendimentoQuery, Types.EquipeAtendimentoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EquipeAtendimentoQuery, Types.EquipeAtendimentoQueryVariables>(
    EquipeAtendimentoDocument,
    baseOptions
  )
}
export function useEquipeAtendimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeAtendimentoQuery,
    Types.EquipeAtendimentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipeAtendimentoQuery, Types.EquipeAtendimentoQueryVariables>(
    EquipeAtendimentoDocument,
    baseOptions
  )
}
export type EquipeAtendimentoQueryHookResult = ReturnType<typeof useEquipeAtendimentoQuery>
export type EquipeAtendimentoLazyQueryHookResult = ReturnType<typeof useEquipeAtendimentoLazyQuery>
export type EquipeAtendimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeAtendimentoQuery,
  Types.EquipeAtendimentoQueryVariables
>
export const ExcluirAtendimentoDocument = gql`
  mutation ExcluirAtendimento($atendimento: ID!) {
    excluirAtendimento(atendimento: $atendimento)
  }
`
export type ExcluirAtendimentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirAtendimentoMutation,
  Types.ExcluirAtendimentoMutationVariables
>

/**
 * __useExcluirAtendimentoMutation__
 *
 * To run a mutation, you first call `useExcluirAtendimentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirAtendimentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirAtendimentoMutation, { data, loading, error }] = useExcluirAtendimentoMutation({
 *   variables: {
 *      atendimento: // value for 'atendimento'
 *   },
 * });
 */
export function useExcluirAtendimentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirAtendimentoMutation,
    Types.ExcluirAtendimentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ExcluirAtendimentoMutation, Types.ExcluirAtendimentoMutationVariables>(
    ExcluirAtendimentoDocument,
    baseOptions
  )
}
export type ExcluirAtendimentoMutationHookResult = ReturnType<typeof useExcluirAtendimentoMutation>
export type ExcluirAtendimentoMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirAtendimentoMutation>
export type ExcluirAtendimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirAtendimentoMutation,
  Types.ExcluirAtendimentoMutationVariables
>
export const ListaAtendimentoPollingDocument = gql`
  query ListaAtendimentoPolling($time: Instant!) {
    atendimentoPolling(time: $time)
  }
`

/**
 * __useListaAtendimentoPollingQuery__
 *
 * To run a query within a React component, call `useListaAtendimentoPollingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListaAtendimentoPollingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListaAtendimentoPollingQuery({
 *   variables: {
 *      time: // value for 'time'
 *   },
 * });
 */
export function useListaAtendimentoPollingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ListaAtendimentoPollingQuery,
    Types.ListaAtendimentoPollingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ListaAtendimentoPollingQuery, Types.ListaAtendimentoPollingQueryVariables>(
    ListaAtendimentoPollingDocument,
    baseOptions
  )
}
export function useListaAtendimentoPollingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ListaAtendimentoPollingQuery,
    Types.ListaAtendimentoPollingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ListaAtendimentoPollingQuery, Types.ListaAtendimentoPollingQueryVariables>(
    ListaAtendimentoPollingDocument,
    baseOptions
  )
}
export type ListaAtendimentoPollingQueryHookResult = ReturnType<typeof useListaAtendimentoPollingQuery>
export type ListaAtendimentoPollingLazyQueryHookResult = ReturnType<typeof useListaAtendimentoPollingLazyQuery>
export type ListaAtendimentoPollingQueryResult = ApolloReactCommon.QueryResult<
  Types.ListaAtendimentoPollingQuery,
  Types.ListaAtendimentoPollingQueryVariables
>
export const ListaAtendimentoPollingEnabledDocument = gql`
  query ListaAtendimentoPollingEnabled {
    atendimentoPollingEnabled
  }
`

/**
 * __useListaAtendimentoPollingEnabledQuery__
 *
 * To run a query within a React component, call `useListaAtendimentoPollingEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useListaAtendimentoPollingEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListaAtendimentoPollingEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useListaAtendimentoPollingEnabledQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ListaAtendimentoPollingEnabledQuery,
    Types.ListaAtendimentoPollingEnabledQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ListaAtendimentoPollingEnabledQuery,
    Types.ListaAtendimentoPollingEnabledQueryVariables
  >(ListaAtendimentoPollingEnabledDocument, baseOptions)
}
export function useListaAtendimentoPollingEnabledLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ListaAtendimentoPollingEnabledQuery,
    Types.ListaAtendimentoPollingEnabledQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ListaAtendimentoPollingEnabledQuery,
    Types.ListaAtendimentoPollingEnabledQueryVariables
  >(ListaAtendimentoPollingEnabledDocument, baseOptions)
}
export type ListaAtendimentoPollingEnabledQueryHookResult = ReturnType<typeof useListaAtendimentoPollingEnabledQuery>
export type ListaAtendimentoPollingEnabledLazyQueryHookResult = ReturnType<
  typeof useListaAtendimentoPollingEnabledLazyQuery
>
export type ListaAtendimentoPollingEnabledQueryResult = ApolloReactCommon.QueryResult<
  Types.ListaAtendimentoPollingEnabledQuery,
  Types.ListaAtendimentoPollingEnabledQueryVariables
>
export const ListaAtendimentoDocument = gql`
  query ListaAtendimento($input: AtendimentosQueryInput!) {
    atendimentos(input: $input) {
      content {
        id
        prontuarioId
        cidadao {
          id
          nome
          nomeSocial
          dataNascimento
        }
        iniciadoEm
        encaminhadoEm
        statusAtendimento
        statusRevisaoAtendimento
        agendado {
          id
          horarioInicial
          situacao
          lotacaoAgendada {
            id
            profissional {
              id
              nome
            }
          }
          profissionalParticipante {
            id
            nome
          }
          videochamada {
            id
          }
        }
        classificacaoRisco
        tiposServico {
          id
          nome
        }
        equipe {
          id
          nome
          area
        }
        responsavel {
          ... on Acesso {
            id
            profissional {
              id
              nome
            }
          }
          ... on Lotacao {
            cbo {
              id
              nome
              cbo2002
            }
          }
          ... on Estagio {
            cbo {
              id
              nome
              cbo2002
            }
          }
        }
        ultimaAlteracaoStatusEm
        hasTagNaoFinalizado
        actions {
          atender {
            hint
            enabled
          }
          revisarRetificar {
            hint
            enabled
          }
          escutaInicial {
            hint
            enabled
          }
          vacinacao {
            hint
            enabled
          }
          informarAusencia {
            hint
            enabled
          }
          cancelarAusencia {
            hint
            enabled
          }
          visualizarProntuario {
            hint
            enabled
          }
          visualizarEscutaInicial {
            hint
            enabled
          }
          editar {
            hint
            enabled
          }
          excluir {
            hint
            enabled
          }
          atendimentosDia {
            hint
            enabled
          }
          declaracaoComparecimento {
            hint
            enabled
          }
          cancelarRegistro {
            hint
            enabled
          }
          tornarResponsavelObservacao {
            hint
            enabled
          }
        }
        ultimoResponsavelObservacao {
          ...ResponsavelAtendimentoObservacao
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ResponsavelAtendimentoObservacaoFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useListaAtendimentoQuery__
 *
 * To run a query within a React component, call `useListaAtendimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListaAtendimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListaAtendimentoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListaAtendimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ListaAtendimentoQuery, Types.ListaAtendimentoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ListaAtendimentoQuery, Types.ListaAtendimentoQueryVariables>(
    ListaAtendimentoDocument,
    baseOptions
  )
}
export function useListaAtendimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ListaAtendimentoQuery, Types.ListaAtendimentoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ListaAtendimentoQuery, Types.ListaAtendimentoQueryVariables>(
    ListaAtendimentoDocument,
    baseOptions
  )
}
export type ListaAtendimentoQueryHookResult = ReturnType<typeof useListaAtendimentoQuery>
export type ListaAtendimentoLazyQueryHookResult = ReturnType<typeof useListaAtendimentoLazyQuery>
export type ListaAtendimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.ListaAtendimentoQuery,
  Types.ListaAtendimentoQueryVariables
>
export const PossuiAtendimentosNaoFinalizadosDocument = gql`
  query PossuiAtendimentosNaoFinalizados($input: PossuiAtendimentosNaoFinalizadosInput!) {
    possuiAtendimentosNaoFinalizados(input: $input)
  }
`

/**
 * __usePossuiAtendimentosNaoFinalizadosQuery__
 *
 * To run a query within a React component, call `usePossuiAtendimentosNaoFinalizadosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossuiAtendimentosNaoFinalizadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossuiAtendimentosNaoFinalizadosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePossuiAtendimentosNaoFinalizadosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PossuiAtendimentosNaoFinalizadosQuery,
    Types.PossuiAtendimentosNaoFinalizadosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.PossuiAtendimentosNaoFinalizadosQuery,
    Types.PossuiAtendimentosNaoFinalizadosQueryVariables
  >(PossuiAtendimentosNaoFinalizadosDocument, baseOptions)
}
export function usePossuiAtendimentosNaoFinalizadosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PossuiAtendimentosNaoFinalizadosQuery,
    Types.PossuiAtendimentosNaoFinalizadosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.PossuiAtendimentosNaoFinalizadosQuery,
    Types.PossuiAtendimentosNaoFinalizadosQueryVariables
  >(PossuiAtendimentosNaoFinalizadosDocument, baseOptions)
}
export type PossuiAtendimentosNaoFinalizadosQueryHookResult = ReturnType<
  typeof usePossuiAtendimentosNaoFinalizadosQuery
>
export type PossuiAtendimentosNaoFinalizadosLazyQueryHookResult = ReturnType<
  typeof usePossuiAtendimentosNaoFinalizadosLazyQuery
>
export type PossuiAtendimentosNaoFinalizadosQueryResult = ApolloReactCommon.QueryResult<
  Types.PossuiAtendimentosNaoFinalizadosQuery,
  Types.PossuiAtendimentosNaoFinalizadosQueryVariables
>
export const SalvarAtendimentoDocument = gql`
  mutation SalvarAtendimento($input: AtendimentoInput!) {
    salvarAtendimento(input: $input) {
      id
      cidadao {
        id
        nome
        nomeSocial
      }
    }
  }
`
export type SalvarAtendimentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAtendimentoMutation,
  Types.SalvarAtendimentoMutationVariables
>

/**
 * __useSalvarAtendimentoMutation__
 *
 * To run a mutation, you first call `useSalvarAtendimentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAtendimentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAtendimentoMutation, { data, loading, error }] = useSalvarAtendimentoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAtendimentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarAtendimentoMutation,
    Types.SalvarAtendimentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarAtendimentoMutation, Types.SalvarAtendimentoMutationVariables>(
    SalvarAtendimentoDocument,
    baseOptions
  )
}
export type SalvarAtendimentoMutationHookResult = ReturnType<typeof useSalvarAtendimentoMutation>
export type SalvarAtendimentoMutationResult = ApolloReactCommon.MutationResult<Types.SalvarAtendimentoMutation>
export type SalvarAtendimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAtendimentoMutation,
  Types.SalvarAtendimentoMutationVariables
>
export const SalvarJustificativaAutomaticaVisualizarEscutaInicialDocument = gql`
  mutation SalvarJustificativaAutomaticaVisualizarEscutaInicial($prontuarioId: ID!) {
    salvarJustificativaAutomaticaVisualizarEscutaInicial(prontuarioId: $prontuarioId)
  }
`
export type SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutation,
  Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationVariables
>

/**
 * __useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation__
 *
 * To run a mutation, you first call `useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarJustificativaAutomaticaVisualizarEscutaInicialMutation, { data, loading, error }] = useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation({
 *   variables: {
 *      prontuarioId: // value for 'prontuarioId'
 *   },
 * });
 */
export function useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutation,
    Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutation,
    Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationVariables
  >(SalvarJustificativaAutomaticaVisualizarEscutaInicialDocument, baseOptions)
}
export type SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationHookResult = ReturnType<
  typeof useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation
>
export type SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutation
>
export type SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutation,
  Types.SalvarJustificativaAutomaticaVisualizarEscutaInicialMutationVariables
>
export const TornarLotacaoAtualResponsavelAtendimentoObservacaoDocument = gql`
  mutation TornarLotacaoAtualResponsavelAtendimentoObservacao($atendimentoId: ID!) {
    tornarLotacaoAtualResponsavelAtendimentoObservacao(atendimentoId: $atendimentoId)
  }
`
export type TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutation,
  Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationVariables
>

/**
 * __useTornarLotacaoAtualResponsavelAtendimentoObservacaoMutation__
 *
 * To run a mutation, you first call `useTornarLotacaoAtualResponsavelAtendimentoObservacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTornarLotacaoAtualResponsavelAtendimentoObservacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tornarLotacaoAtualResponsavelAtendimentoObservacaoMutation, { data, loading, error }] = useTornarLotacaoAtualResponsavelAtendimentoObservacaoMutation({
 *   variables: {
 *      atendimentoId: // value for 'atendimentoId'
 *   },
 * });
 */
export function useTornarLotacaoAtualResponsavelAtendimentoObservacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutation,
    Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutation,
    Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationVariables
  >(TornarLotacaoAtualResponsavelAtendimentoObservacaoDocument, baseOptions)
}
export type TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationHookResult = ReturnType<
  typeof useTornarLotacaoAtualResponsavelAtendimentoObservacaoMutation
>
export type TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutation
>
export type TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutation,
  Types.TornarLotacaoAtualResponsavelAtendimentoObservacaoMutationVariables
>
export const AtenderDocument = gql`
  mutation Atender($atendimento: ID!) {
    realizarAtendimentoIndividual(atendimento: $atendimento) {
      id
      statusAtendimento
      cidadao {
        id
      }
      atendimentoProfissional {
        id
      }
    }
  }
`
export type AtenderMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtenderMutation,
  Types.AtenderMutationVariables
>

/**
 * __useAtenderMutation__
 *
 * To run a mutation, you first call `useAtenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atenderMutation, { data, loading, error }] = useAtenderMutation({
 *   variables: {
 *      atendimento: // value for 'atendimento'
 *   },
 * });
 */
export function useAtenderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AtenderMutation, Types.AtenderMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AtenderMutation, Types.AtenderMutationVariables>(
    AtenderDocument,
    baseOptions
  )
}
export type AtenderMutationHookResult = ReturnType<typeof useAtenderMutation>
export type AtenderMutationResult = ApolloReactCommon.MutationResult<Types.AtenderMutation>
export type AtenderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtenderMutation,
  Types.AtenderMutationVariables
>
export const CancelarAusenciaDocument = gql`
  mutation CancelarAusencia($atendimento: ID!) {
    cancelarCidadaoNaoAguardou(atendimento: $atendimento) {
      id
    }
  }
`
export type CancelarAusenciaMutationFn = ApolloReactCommon.MutationFunction<
  Types.CancelarAusenciaMutation,
  Types.CancelarAusenciaMutationVariables
>

/**
 * __useCancelarAusenciaMutation__
 *
 * To run a mutation, you first call `useCancelarAusenciaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelarAusenciaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelarAusenciaMutation, { data, loading, error }] = useCancelarAusenciaMutation({
 *   variables: {
 *      atendimento: // value for 'atendimento'
 *   },
 * });
 */
export function useCancelarAusenciaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CancelarAusenciaMutation,
    Types.CancelarAusenciaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.CancelarAusenciaMutation, Types.CancelarAusenciaMutationVariables>(
    CancelarAusenciaDocument,
    baseOptions
  )
}
export type CancelarAusenciaMutationHookResult = ReturnType<typeof useCancelarAusenciaMutation>
export type CancelarAusenciaMutationResult = ApolloReactCommon.MutationResult<Types.CancelarAusenciaMutation>
export type CancelarAusenciaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CancelarAusenciaMutation,
  Types.CancelarAusenciaMutationVariables
>
export const InformarAusenciaDocument = gql`
  mutation InformarAusencia($atendimento: ID!) {
    informarCidadaoNaoAguardou(atendimento: $atendimento) {
      id
    }
  }
`
export type InformarAusenciaMutationFn = ApolloReactCommon.MutationFunction<
  Types.InformarAusenciaMutation,
  Types.InformarAusenciaMutationVariables
>

/**
 * __useInformarAusenciaMutation__
 *
 * To run a mutation, you first call `useInformarAusenciaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInformarAusenciaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [informarAusenciaMutation, { data, loading, error }] = useInformarAusenciaMutation({
 *   variables: {
 *      atendimento: // value for 'atendimento'
 *   },
 * });
 */
export function useInformarAusenciaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InformarAusenciaMutation,
    Types.InformarAusenciaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.InformarAusenciaMutation, Types.InformarAusenciaMutationVariables>(
    InformarAusenciaDocument,
    baseOptions
  )
}
export type InformarAusenciaMutationHookResult = ReturnType<typeof useInformarAusenciaMutation>
export type InformarAusenciaMutationResult = ApolloReactCommon.MutationResult<Types.InformarAusenciaMutation>
export type InformarAusenciaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InformarAusenciaMutation,
  Types.InformarAusenciaMutationVariables
>
export const RealizarAtendimentoVacinacaoDocument = gql`
  mutation RealizarAtendimentoVacinacao($atendimento: ID!) {
    realizarAtendimentoVacinacao(atendimento: $atendimento) {
      id
      cidadao {
        id
      }
      atendimentoProfissional {
        id
      }
    }
  }
`
export type RealizarAtendimentoVacinacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.RealizarAtendimentoVacinacaoMutation,
  Types.RealizarAtendimentoVacinacaoMutationVariables
>

/**
 * __useRealizarAtendimentoVacinacaoMutation__
 *
 * To run a mutation, you first call `useRealizarAtendimentoVacinacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRealizarAtendimentoVacinacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [realizarAtendimentoVacinacaoMutation, { data, loading, error }] = useRealizarAtendimentoVacinacaoMutation({
 *   variables: {
 *      atendimento: // value for 'atendimento'
 *   },
 * });
 */
export function useRealizarAtendimentoVacinacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RealizarAtendimentoVacinacaoMutation,
    Types.RealizarAtendimentoVacinacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RealizarAtendimentoVacinacaoMutation,
    Types.RealizarAtendimentoVacinacaoMutationVariables
  >(RealizarAtendimentoVacinacaoDocument, baseOptions)
}
export type RealizarAtendimentoVacinacaoMutationHookResult = ReturnType<typeof useRealizarAtendimentoVacinacaoMutation>
export type RealizarAtendimentoVacinacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.RealizarAtendimentoVacinacaoMutation
>
export type RealizarAtendimentoVacinacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RealizarAtendimentoVacinacaoMutation,
  Types.RealizarAtendimentoVacinacaoMutationVariables
>
export const RealizarEscutaInicialDocument = gql`
  mutation RealizarEscutaInicial($atendimento: ID!) {
    realizarEscutaInicial(atendimento: $atendimento) {
      id
      cidadao {
        id
      }
      atendimentoProfissional {
        id
      }
    }
  }
`
export type RealizarEscutaInicialMutationFn = ApolloReactCommon.MutationFunction<
  Types.RealizarEscutaInicialMutation,
  Types.RealizarEscutaInicialMutationVariables
>

/**
 * __useRealizarEscutaInicialMutation__
 *
 * To run a mutation, you first call `useRealizarEscutaInicialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRealizarEscutaInicialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [realizarEscutaInicialMutation, { data, loading, error }] = useRealizarEscutaInicialMutation({
 *   variables: {
 *      atendimento: // value for 'atendimento'
 *   },
 * });
 */
export function useRealizarEscutaInicialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RealizarEscutaInicialMutation,
    Types.RealizarEscutaInicialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RealizarEscutaInicialMutation,
    Types.RealizarEscutaInicialMutationVariables
  >(RealizarEscutaInicialDocument, baseOptions)
}
export type RealizarEscutaInicialMutationHookResult = ReturnType<typeof useRealizarEscutaInicialMutation>
export type RealizarEscutaInicialMutationResult = ApolloReactCommon.MutationResult<Types.RealizarEscutaInicialMutation>
export type RealizarEscutaInicialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RealizarEscutaInicialMutation,
  Types.RealizarEscutaInicialMutationVariables
>
export const AtendimentosDoDiaDocument = gql`
  query AtendimentosDoDia($id: ID!) {
    atendimentosDoDia(id: $id) {
      content {
        id
        iniciadoEm
        finalizadoEm
        lotacao {
          id
          profissional {
            id
            nome
          }
          cbo {
            id
            nome
          }
        }
        tipo
        atendimento {
          id
          iniciadoEm
          cidadao {
            id
            nome
            cpf
            cns
            dataNascimento
            nomeSocial
          }
          dataCriacaoRegistro
        }
      }
    }
  }
`

/**
 * __useAtendimentosDoDiaQuery__
 *
 * To run a query within a React component, call `useAtendimentosDoDiaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentosDoDiaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentosDoDiaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtendimentosDoDiaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AtendimentosDoDiaQuery, Types.AtendimentosDoDiaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AtendimentosDoDiaQuery, Types.AtendimentosDoDiaQueryVariables>(
    AtendimentosDoDiaDocument,
    baseOptions
  )
}
export function useAtendimentosDoDiaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentosDoDiaQuery,
    Types.AtendimentosDoDiaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AtendimentosDoDiaQuery, Types.AtendimentosDoDiaQueryVariables>(
    AtendimentosDoDiaDocument,
    baseOptions
  )
}
export type AtendimentosDoDiaQueryHookResult = ReturnType<typeof useAtendimentosDoDiaQuery>
export type AtendimentosDoDiaLazyQueryHookResult = ReturnType<typeof useAtendimentosDoDiaLazyQuery>
export type AtendimentosDoDiaQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentosDoDiaQuery,
  Types.AtendimentosDoDiaQueryVariables
>
export const CidadaoDeclaracaoComparecimentoDocument = gql`
  query CidadaoDeclaracaoComparecimento($id: ID!) {
    atendimento(id: $id) {
      id
      dataCriacaoRegistro
      iniciadoEm
      isRegistroTardio
      responsavel {
        ... on Acesso {
          id
          profissional {
            id
            nome
          }
        }
      }
      cidadao {
        id
        cpf
        cns
        dataNascimento
        nome
        nomeSocial
      }
      unidadeSaude {
        id
        nome
        endereco {
          municipio {
            id
            nome
            uf {
              id
              nome
              sigla
            }
          }
        }
      }
    }
  }
`

/**
 * __useCidadaoDeclaracaoComparecimentoQuery__
 *
 * To run a query within a React component, call `useCidadaoDeclaracaoComparecimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoDeclaracaoComparecimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoDeclaracaoComparecimentoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCidadaoDeclaracaoComparecimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoDeclaracaoComparecimentoQuery,
    Types.CidadaoDeclaracaoComparecimentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoDeclaracaoComparecimentoQuery,
    Types.CidadaoDeclaracaoComparecimentoQueryVariables
  >(CidadaoDeclaracaoComparecimentoDocument, baseOptions)
}
export function useCidadaoDeclaracaoComparecimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoDeclaracaoComparecimentoQuery,
    Types.CidadaoDeclaracaoComparecimentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoDeclaracaoComparecimentoQuery,
    Types.CidadaoDeclaracaoComparecimentoQueryVariables
  >(CidadaoDeclaracaoComparecimentoDocument, baseOptions)
}
export type CidadaoDeclaracaoComparecimentoQueryHookResult = ReturnType<typeof useCidadaoDeclaracaoComparecimentoQuery>
export type CidadaoDeclaracaoComparecimentoLazyQueryHookResult = ReturnType<
  typeof useCidadaoDeclaracaoComparecimentoLazyQuery
>
export type CidadaoDeclaracaoComparecimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoDeclaracaoComparecimentoQuery,
  Types.CidadaoDeclaracaoComparecimentoQueryVariables
>
export const AtendimentoDocument = gql`
  query Atendimento($id: ID!) {
    atendimento(id: $id) {
      id
      agendado {
        id
      }
      cidadao {
        id
        nome
        nomeSocial
        dataNascimento
        sexo
        racaCor {
          id
          nome
          codigoMS
          codigoCadsus
          racaCorDbEnum
        }
      }
      responsavel {
        ... on Acesso {
          id
          profissional {
            id
            nome
            cpf
          }
        }
        ... on Lotacao {
          ...ResponsavelAtendimentoLotacao
        }
        ... on Estagio {
          ...ResponsavelAtendimentoEstagio
        }
      }
      equipe {
        id
        ine
        nome
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      tiposServico {
        id
        nome
      }
    }
  }
  ${ResponsavelAtendimentoLotacaoFragmentDoc}
  ${ResponsavelAtendimentoEstagioFragmentDoc}
`

/**
 * __useAtendimentoQuery__
 *
 * To run a query within a React component, call `useAtendimentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtendimentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AtendimentoQuery, Types.AtendimentoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AtendimentoQuery, Types.AtendimentoQueryVariables>(
    AtendimentoDocument,
    baseOptions
  )
}
export function useAtendimentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AtendimentoQuery, Types.AtendimentoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AtendimentoQuery, Types.AtendimentoQueryVariables>(
    AtendimentoDocument,
    baseOptions
  )
}
export type AtendimentoQueryHookResult = ReturnType<typeof useAtendimentoQuery>
export type AtendimentoLazyQueryHookResult = ReturnType<typeof useAtendimentoLazyQuery>
export type AtendimentoQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoQuery,
  Types.AtendimentoQueryVariables
>
export const EditarAtendimentoDocument = gql`
  mutation EditarAtendimento($input: AtendimentoEditInput!) {
    editarAtendimento(input: $input) {
      id
      cidadao {
        id
        nome
        nomeSocial
      }
    }
  }
`
export type EditarAtendimentoMutationFn = ApolloReactCommon.MutationFunction<
  Types.EditarAtendimentoMutation,
  Types.EditarAtendimentoMutationVariables
>

/**
 * __useEditarAtendimentoMutation__
 *
 * To run a mutation, you first call `useEditarAtendimentoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarAtendimentoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarAtendimentoMutation, { data, loading, error }] = useEditarAtendimentoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditarAtendimentoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EditarAtendimentoMutation,
    Types.EditarAtendimentoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.EditarAtendimentoMutation, Types.EditarAtendimentoMutationVariables>(
    EditarAtendimentoDocument,
    baseOptions
  )
}
export type EditarAtendimentoMutationHookResult = ReturnType<typeof useEditarAtendimentoMutation>
export type EditarAtendimentoMutationResult = ApolloReactCommon.MutationResult<Types.EditarAtendimentoMutation>
export type EditarAtendimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EditarAtendimentoMutation,
  Types.EditarAtendimentoMutationVariables
>
export const AuditoriaHistoricoTableDocument = gql`
  query AuditoriaHistoricoTable($input: AuditoriaHistoricoQueryInput!) {
    auditoriaHistorico(input: $input) {
      content {
        id
        dataInicio
        dataFim
        dataSolicitacao
        status
        csv
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useAuditoriaHistoricoTableQuery__
 *
 * To run a query within a React component, call `useAuditoriaHistoricoTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditoriaHistoricoTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditoriaHistoricoTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuditoriaHistoricoTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AuditoriaHistoricoTableQuery,
    Types.AuditoriaHistoricoTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AuditoriaHistoricoTableQuery, Types.AuditoriaHistoricoTableQueryVariables>(
    AuditoriaHistoricoTableDocument,
    baseOptions
  )
}
export function useAuditoriaHistoricoTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AuditoriaHistoricoTableQuery,
    Types.AuditoriaHistoricoTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AuditoriaHistoricoTableQuery, Types.AuditoriaHistoricoTableQueryVariables>(
    AuditoriaHistoricoTableDocument,
    baseOptions
  )
}
export type AuditoriaHistoricoTableQueryHookResult = ReturnType<typeof useAuditoriaHistoricoTableQuery>
export type AuditoriaHistoricoTableLazyQueryHookResult = ReturnType<typeof useAuditoriaHistoricoTableLazyQuery>
export type AuditoriaHistoricoTableQueryResult = ApolloReactCommon.QueryResult<
  Types.AuditoriaHistoricoTableQuery,
  Types.AuditoriaHistoricoTableQueryVariables
>
export const ProcessarAuditoriaDocument = gql`
  mutation ProcessarAuditoria($input: AuditoriaInput!) {
    processAuditoria(input: $input)
  }
`
export type ProcessarAuditoriaMutationFn = ApolloReactCommon.MutationFunction<
  Types.ProcessarAuditoriaMutation,
  Types.ProcessarAuditoriaMutationVariables
>

/**
 * __useProcessarAuditoriaMutation__
 *
 * To run a mutation, you first call `useProcessarAuditoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessarAuditoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processarAuditoriaMutation, { data, loading, error }] = useProcessarAuditoriaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessarAuditoriaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ProcessarAuditoriaMutation,
    Types.ProcessarAuditoriaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ProcessarAuditoriaMutation, Types.ProcessarAuditoriaMutationVariables>(
    ProcessarAuditoriaDocument,
    baseOptions
  )
}
export type ProcessarAuditoriaMutationHookResult = ReturnType<typeof useProcessarAuditoriaMutation>
export type ProcessarAuditoriaMutationResult = ApolloReactCommon.MutationResult<Types.ProcessarAuditoriaMutation>
export type ProcessarAuditoriaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ProcessarAuditoriaMutation,
  Types.ProcessarAuditoriaMutationVariables
>
export const AutorizarMunicipioConfigAgendaPadraoDocument = gql`
  query AutorizarMunicipioConfigAgendaPadrao {
    duracaoAtendimentoPadraoMunicipio {
      duracaoAtendimentoPadraoMunicipio
    }
    configuracaoHorariosMunicipio {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`

/**
 * __useAutorizarMunicipioConfigAgendaPadraoQuery__
 *
 * To run a query within a React component, call `useAutorizarMunicipioConfigAgendaPadraoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutorizarMunicipioConfigAgendaPadraoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutorizarMunicipioConfigAgendaPadraoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutorizarMunicipioConfigAgendaPadraoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >(AutorizarMunicipioConfigAgendaPadraoDocument, baseOptions)
}
export function useAutorizarMunicipioConfigAgendaPadraoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >(AutorizarMunicipioConfigAgendaPadraoDocument, baseOptions)
}
export type AutorizarMunicipioConfigAgendaPadraoQueryHookResult = ReturnType<
  typeof useAutorizarMunicipioConfigAgendaPadraoQuery
>
export type AutorizarMunicipioConfigAgendaPadraoLazyQueryHookResult = ReturnType<
  typeof useAutorizarMunicipioConfigAgendaPadraoLazyQuery
>
export type AutorizarMunicipioConfigAgendaPadraoQueryResult = ApolloReactCommon.QueryResult<
  Types.AutorizarMunicipioConfigAgendaPadraoQuery,
  Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
>
export const SalvaAutorizarMunicipioConfiguracaoAgendaPadraoDocument = gql`
  mutation SalvaAutorizarMunicipioConfiguracaoAgendaPadrao(
    $duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput!
    $configuracaoAgendaMunicipalInput: ConfiguracaoAgendaMunicipalInput
  ) {
    alterarDuracaoPadraoAtendimento(duracaoAtendimentoPadraoMunicipioInput: $duracaoAtendimentoPadraoMunicipioInput) {
      duracaoAtendimentoPadraoMunicipio
    }
    salvarConfiguracaoHorariosMunicipio(configuracaoAgendaMunicipalInput: $configuracaoAgendaMunicipalInput) {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
>

/**
 * __useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation__
 *
 * To run a mutation, you first call `useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation, { data, loading, error }] = useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation({
 *   variables: {
 *      duracaoAtendimentoPadraoMunicipioInput: // value for 'duracaoAtendimentoPadraoMunicipioInput'
 *      configuracaoAgendaMunicipalInput: // value for 'configuracaoAgendaMunicipalInput'
 *   },
 * });
 */
export function useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
  >(SalvaAutorizarMunicipioConfiguracaoAgendaPadraoDocument, baseOptions)
}
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationHookResult = ReturnType<
  typeof useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation
>
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation
>
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
>
export const SaveAutorizarMunicipioDocument = gql`
  mutation SaveAutorizarMunicipio($input: AutorizarMunicipioInput!) {
    autorizarMunicipio(input: $input) {
      id
      autorizado
    }
  }
`
export type SaveAutorizarMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SaveAutorizarMunicipioMutation,
  Types.SaveAutorizarMunicipioMutationVariables
>

/**
 * __useSaveAutorizarMunicipioMutation__
 *
 * To run a mutation, you first call `useSaveAutorizarMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAutorizarMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAutorizarMunicipioMutation, { data, loading, error }] = useSaveAutorizarMunicipioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAutorizarMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SaveAutorizarMunicipioMutation,
    Types.SaveAutorizarMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SaveAutorizarMunicipioMutation,
    Types.SaveAutorizarMunicipioMutationVariables
  >(SaveAutorizarMunicipioDocument, baseOptions)
}
export type SaveAutorizarMunicipioMutationHookResult = ReturnType<typeof useSaveAutorizarMunicipioMutation>
export type SaveAutorizarMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.SaveAutorizarMunicipioMutation
>
export type SaveAutorizarMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SaveAutorizarMunicipioMutation,
  Types.SaveAutorizarMunicipioMutationVariables
>
export const AutorizarMunicipioDocument = gql`
  query AutorizarMunicipio($acessoId: ID!) {
    info {
      treinamento
      tipoInstalacao
    }
    acesso(id: $acessoId) {
      __typename
      id
      profissional {
        id
        nome
        cpf
      }
      municipio {
        id
        nome
        uf {
          id
          nome
          sigla
        }
      }
      ... on AdministradorMunicipal {
        autorizado
        chaveMunicipio
      }
    }
  }
`

/**
 * __useAutorizarMunicipioQuery__
 *
 * To run a query within a React component, call `useAutorizarMunicipioQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutorizarMunicipioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutorizarMunicipioQuery({
 *   variables: {
 *      acessoId: // value for 'acessoId'
 *   },
 * });
 */
export function useAutorizarMunicipioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AutorizarMunicipioQuery, Types.AutorizarMunicipioQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AutorizarMunicipioQuery, Types.AutorizarMunicipioQueryVariables>(
    AutorizarMunicipioDocument,
    baseOptions
  )
}
export function useAutorizarMunicipioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AutorizarMunicipioQuery,
    Types.AutorizarMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AutorizarMunicipioQuery, Types.AutorizarMunicipioQueryVariables>(
    AutorizarMunicipioDocument,
    baseOptions
  )
}
export type AutorizarMunicipioQueryHookResult = ReturnType<typeof useAutorizarMunicipioQuery>
export type AutorizarMunicipioLazyQueryHookResult = ReturnType<typeof useAutorizarMunicipioLazyQuery>
export type AutorizarMunicipioQueryResult = ApolloReactCommon.QueryResult<
  Types.AutorizarMunicipioQuery,
  Types.AutorizarMunicipioQueryVariables
>
export const ConcluirConfiguracaoMunicipioDocument = gql`
  mutation ConcluirConfiguracaoMunicipio {
    concluirConfiguracaoMunicipio {
      id
      habilitado
    }
  }
`
export type ConcluirConfiguracaoMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.ConcluirConfiguracaoMunicipioMutation,
  Types.ConcluirConfiguracaoMunicipioMutationVariables
>

/**
 * __useConcluirConfiguracaoMunicipioMutation__
 *
 * To run a mutation, you first call `useConcluirConfiguracaoMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConcluirConfiguracaoMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [concluirConfiguracaoMunicipioMutation, { data, loading, error }] = useConcluirConfiguracaoMunicipioMutation({
 *   variables: {
 *   },
 * });
 */
export function useConcluirConfiguracaoMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ConcluirConfiguracaoMunicipioMutation,
    Types.ConcluirConfiguracaoMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ConcluirConfiguracaoMunicipioMutation,
    Types.ConcluirConfiguracaoMunicipioMutationVariables
  >(ConcluirConfiguracaoMunicipioDocument, baseOptions)
}
export type ConcluirConfiguracaoMunicipioMutationHookResult = ReturnType<
  typeof useConcluirConfiguracaoMunicipioMutation
>
export type ConcluirConfiguracaoMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.ConcluirConfiguracaoMunicipioMutation
>
export type ConcluirConfiguracaoMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ConcluirConfiguracaoMunicipioMutation,
  Types.ConcluirConfiguracaoMunicipioMutationVariables
>
export const BuscaAtivaVacinacaoDocument = gql`
  query BuscaAtivaVacinacao($input: BuscaAtivaVacinacaoInput!) {
    buscaAtivaVacinacao(input: $input) {
      content {
        id
        cidadaoId
        nomeSocialOuNomeCidadao
        cpfCidadao
        cnsCidadao
        dataNascimentoCidadao
        telefoneResidencial
        telefoneCelular
        telefoneContato
        endereco
        vacinasAtrasadas {
          cidadaoId
          nome
          siglaDose
          imunobiologicoId
          doseId
        }
        vacinasNoPrazo {
          cidadaoId
          nome
          siglaDose
          imunobiologicoId
          doseId
        }
        vacinasAplicadas {
          cidadaoId
          nome
          siglaDose
          dataAplicacaoVacina
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useBuscaAtivaVacinacaoQuery__
 *
 * To run a query within a React component, call `useBuscaAtivaVacinacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaAtivaVacinacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaAtivaVacinacaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuscaAtivaVacinacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaAtivaVacinacaoQuery,
    Types.BuscaAtivaVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BuscaAtivaVacinacaoQuery, Types.BuscaAtivaVacinacaoQueryVariables>(
    BuscaAtivaVacinacaoDocument,
    baseOptions
  )
}
export function useBuscaAtivaVacinacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaAtivaVacinacaoQuery,
    Types.BuscaAtivaVacinacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaAtivaVacinacaoQuery, Types.BuscaAtivaVacinacaoQueryVariables>(
    BuscaAtivaVacinacaoDocument,
    baseOptions
  )
}
export type BuscaAtivaVacinacaoQueryHookResult = ReturnType<typeof useBuscaAtivaVacinacaoQuery>
export type BuscaAtivaVacinacaoLazyQueryHookResult = ReturnType<typeof useBuscaAtivaVacinacaoLazyQuery>
export type BuscaAtivaVacinacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaAtivaVacinacaoQuery,
  Types.BuscaAtivaVacinacaoQueryVariables
>
export const AlterarDuracaoCboDocument = gql`
  mutation AlterarDuracaoCbo($cboDuracaoForm: CboDuracaoFormInput!) {
    alterarDuracaoCbo(input: $cboDuracaoForm) {
      cbo {
        id
      }
    }
  }
`
export type AlterarDuracaoCboMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarDuracaoCboMutation,
  Types.AlterarDuracaoCboMutationVariables
>

/**
 * __useAlterarDuracaoCboMutation__
 *
 * To run a mutation, you first call `useAlterarDuracaoCboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarDuracaoCboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarDuracaoCboMutation, { data, loading, error }] = useAlterarDuracaoCboMutation({
 *   variables: {
 *      cboDuracaoForm: // value for 'cboDuracaoForm'
 *   },
 * });
 */
export function useAlterarDuracaoCboMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarDuracaoCboMutation,
    Types.AlterarDuracaoCboMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarDuracaoCboMutation, Types.AlterarDuracaoCboMutationVariables>(
    AlterarDuracaoCboDocument,
    baseOptions
  )
}
export type AlterarDuracaoCboMutationHookResult = ReturnType<typeof useAlterarDuracaoCboMutation>
export type AlterarDuracaoCboMutationResult = ApolloReactCommon.MutationResult<Types.AlterarDuracaoCboMutation>
export type AlterarDuracaoCboMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarDuracaoCboMutation,
  Types.AlterarDuracaoCboMutationVariables
>
export const DisponibilizarCboDocument = gql`
  mutation DisponibilizarCbo($cboDisponibilizarForm: CboDisponibilizarFormInput!) {
    disponibilizarCbo(input: $cboDisponibilizarForm) {
      cbo {
        id
      }
    }
  }
`
export type DisponibilizarCboMutationFn = ApolloReactCommon.MutationFunction<
  Types.DisponibilizarCboMutation,
  Types.DisponibilizarCboMutationVariables
>

/**
 * __useDisponibilizarCboMutation__
 *
 * To run a mutation, you first call `useDisponibilizarCboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisponibilizarCboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disponibilizarCboMutation, { data, loading, error }] = useDisponibilizarCboMutation({
 *   variables: {
 *      cboDisponibilizarForm: // value for 'cboDisponibilizarForm'
 *   },
 * });
 */
export function useDisponibilizarCboMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DisponibilizarCboMutation,
    Types.DisponibilizarCboMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.DisponibilizarCboMutation, Types.DisponibilizarCboMutationVariables>(
    DisponibilizarCboDocument,
    baseOptions
  )
}
export type DisponibilizarCboMutationHookResult = ReturnType<typeof useDisponibilizarCboMutation>
export type DisponibilizarCboMutationResult = ApolloReactCommon.MutationResult<Types.DisponibilizarCboMutation>
export type DisponibilizarCboMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DisponibilizarCboMutation,
  Types.DisponibilizarCboMutationVariables
>
export const CboEditComponentDocument = gql`
  query CboEditComponent {
    conexao {
      agendaOnline {
        ativa
      }
    }
  }
`

/**
 * __useCboEditComponentQuery__
 *
 * To run a query within a React component, call `useCboEditComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboEditComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboEditComponentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCboEditComponentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CboEditComponentQuery, Types.CboEditComponentQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CboEditComponentQuery, Types.CboEditComponentQueryVariables>(
    CboEditComponentDocument,
    baseOptions
  )
}
export function useCboEditComponentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CboEditComponentQuery, Types.CboEditComponentQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CboEditComponentQuery, Types.CboEditComponentQueryVariables>(
    CboEditComponentDocument,
    baseOptions
  )
}
export type CboEditComponentQueryHookResult = ReturnType<typeof useCboEditComponentQuery>
export type CboEditComponentLazyQueryHookResult = ReturnType<typeof useCboEditComponentLazyQuery>
export type CboEditComponentQueryResult = ApolloReactCommon.QueryResult<
  Types.CboEditComponentQuery,
  Types.CboEditComponentQueryVariables
>
export const CboTableDocument = gql`
  query CboTable($input: CbosQueryInput!) {
    cbos(input: $input) {
      content {
        disponivelLotacao
        duracao
        duracaoPadrao
        cbo {
          id
          nome
          cbo2002
        }
        possuiLotacaoComAgendaOnline
        actions {
          inativar {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCboTableQuery__
 *
 * To run a query within a React component, call `useCboTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCboTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CboTableQuery, Types.CboTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CboTableQuery, Types.CboTableQueryVariables>(CboTableDocument, baseOptions)
}
export function useCboTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CboTableQuery, Types.CboTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CboTableQuery, Types.CboTableQueryVariables>(CboTableDocument, baseOptions)
}
export type CboTableQueryHookResult = ReturnType<typeof useCboTableQuery>
export type CboTableLazyQueryHookResult = ReturnType<typeof useCboTableLazyQuery>
export type CboTableQueryResult = ApolloReactCommon.QueryResult<Types.CboTableQuery, Types.CboTableQueryVariables>
export const AtualizarContatoCidadaoDocument = gql`
  mutation AtualizarContatoCidadao($input: AtualizarContatoCidadaoInput!) {
    atualizarContatoCidadao(input: $input) {
      id
    }
  }
`
export type AtualizarContatoCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtualizarContatoCidadaoMutation,
  Types.AtualizarContatoCidadaoMutationVariables
>

/**
 * __useAtualizarContatoCidadaoMutation__
 *
 * To run a mutation, you first call `useAtualizarContatoCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarContatoCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarContatoCidadaoMutation, { data, loading, error }] = useAtualizarContatoCidadaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtualizarContatoCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtualizarContatoCidadaoMutation,
    Types.AtualizarContatoCidadaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AtualizarContatoCidadaoMutation,
    Types.AtualizarContatoCidadaoMutationVariables
  >(AtualizarContatoCidadaoDocument, baseOptions)
}
export type AtualizarContatoCidadaoMutationHookResult = ReturnType<typeof useAtualizarContatoCidadaoMutation>
export type AtualizarContatoCidadaoMutationResult = ApolloReactCommon.MutationResult<
  Types.AtualizarContatoCidadaoMutation
>
export type AtualizarContatoCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtualizarContatoCidadaoMutation,
  Types.AtualizarContatoCidadaoMutationVariables
>
export const BuscaCidadaoCabecalhoDocument = gql`
  query BuscaCidadaoCabecalho($id: ID!) {
    cidadao(id: $id) {
      id
      cpf
      cns
      nome
      nomeSocial
      dataNascimento
      dataAtualizado
      sexo
      nomeMae
      telefoneResidencial
      telefoneCelular
      telefoneContato
      identidadeGeneroDbEnum
      ativo
      faleceu
      possuiAgendamento
      unificado
      prontuario {
        id
      }
      cidadaoVinculacaoEquipe {
        id
        tpCdsOrigem
        unidadeSaude {
          id
          nome
        }
        equipe {
          id
          nome
          ine
        }
      }
    }
  }
`

/**
 * __useBuscaCidadaoCabecalhoQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoCabecalhoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoCabecalhoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoCabecalhoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuscaCidadaoCabecalhoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaCidadaoCabecalhoQuery,
    Types.BuscaCidadaoCabecalhoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BuscaCidadaoCabecalhoQuery, Types.BuscaCidadaoCabecalhoQueryVariables>(
    BuscaCidadaoCabecalhoDocument,
    baseOptions
  )
}
export function useBuscaCidadaoCabecalhoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoCabecalhoQuery,
    Types.BuscaCidadaoCabecalhoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaCidadaoCabecalhoQuery, Types.BuscaCidadaoCabecalhoQueryVariables>(
    BuscaCidadaoCabecalhoDocument,
    baseOptions
  )
}
export type BuscaCidadaoCabecalhoQueryHookResult = ReturnType<typeof useBuscaCidadaoCabecalhoQuery>
export type BuscaCidadaoCabecalhoLazyQueryHookResult = ReturnType<typeof useBuscaCidadaoCabecalhoLazyQuery>
export type BuscaCidadaoCabecalhoQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoCabecalhoQuery,
  Types.BuscaCidadaoCabecalhoQueryVariables
>
export const BuscaCidadaoCadsusPollingDocument = gql`
  query BuscaCidadaoCadsusPolling($uuid: String!) {
    buscaCidadaosCadsusCompletoPolling(uuid: $uuid) {
      usuario
      uuid
      resultCadsus
      cidadaos {
        cpf
        cns
        nome
        nomeSocial
        nomeMae
        nomePai
        racaCor {
          id
          nome
          racaCorDbEnum
        }
        etnia {
          id
          nome
        }
        telefoneContato
        telefoneResidencial
        telefoneCelular
        dataNascimento
        dataObito
        obito
        sexo
        email
        nacionalidade {
          id
          nacionalidadeDbEnum
        }
        municipioNascimento {
          id
          ibge
          nome
          uf {
            id
            nome
            sigla
          }
        }
        paisNascimento {
          id
          nome
        }
        portariaNaturalizacao
        dataEntradaBrasil
        dataNaturalizacao
        endereco {
          bairro
          cep
          complemento
          logradouro
          municipio {
            id
            ibge
            nome
            uf {
              id
              nome
              sigla
            }
          }
          numero
          pontoReferencia
          semNumero
          tipoLogradouro {
            id
            nome
            numeroDne
          }
          uf {
            id
            nome
            sigla
          }
        }
        paisResidencia {
          id
          nome
        }
        municipioResidenciaExterior
        numeroPisPasep
      }
    }
  }
`

/**
 * __useBuscaCidadaoCadsusPollingQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoCadsusPollingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoCadsusPollingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoCadsusPollingQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBuscaCidadaoCadsusPollingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaCidadaoCadsusPollingQuery,
    Types.BuscaCidadaoCadsusPollingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BuscaCidadaoCadsusPollingQuery, Types.BuscaCidadaoCadsusPollingQueryVariables>(
    BuscaCidadaoCadsusPollingDocument,
    baseOptions
  )
}
export function useBuscaCidadaoCadsusPollingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoCadsusPollingQuery,
    Types.BuscaCidadaoCadsusPollingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BuscaCidadaoCadsusPollingQuery,
    Types.BuscaCidadaoCadsusPollingQueryVariables
  >(BuscaCidadaoCadsusPollingDocument, baseOptions)
}
export type BuscaCidadaoCadsusPollingQueryHookResult = ReturnType<typeof useBuscaCidadaoCadsusPollingQuery>
export type BuscaCidadaoCadsusPollingLazyQueryHookResult = ReturnType<typeof useBuscaCidadaoCadsusPollingLazyQuery>
export type BuscaCidadaoCadsusPollingQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoCadsusPollingQuery,
  Types.BuscaCidadaoCadsusPollingQueryVariables
>
export const BuscaDetailCidadaoDocument = gql`
  query BuscaDetailCidadao($id: ID!) {
    cidadao(id: $id) {
      id
      cpf
      cns
      nisPisPasep
      nome
      nomeSocial
      dataNascimento
      dataAtualizado
      dataObito
      numeroDocumentoObito
      sexo
      nomeMae
      nomePai
      telefoneResidencial
      telefoneCelular
      telefoneContato
      email
      area
      microArea
      endereco {
        cep
        uf {
          id
          nome
        }
        municipio {
          id
          nome
        }
        bairro
        tipoLogradouro {
          id
          nome
        }
        logradouro
        numero
        semNumero
        complemento
        pontoReferencia
      }
      localidadeExterior
      prontuario {
        id
      }
      identidadeGeneroDbEnum
      etnia {
        id
        nome
      }
      racaCor {
        id
        nome
        racaCorDbEnum
      }
      area
      microArea
      cbo {
        id
        nome
      }
      escolaridade {
        id
        nome
      }
      ativo
      localidadeNascimento {
        id
        nome
        uf {
          id
          sigla
        }
      }
      faleceu
      possuiAgendamento
      prontuario {
        id
        gestacoes {
          id
          inicio
          fim
        }
        preNatalAtivo {
          id
          ultimaDum
        }
        puerpera
      }
      unificado
      unificacaoBase
      cidadaoVinculacaoEquipe {
        id
        tpCdsOrigem
        utilizarCadastroIndividual
        unidadeSaude {
          id
          nome
        }
        equipe {
          id
          nome
          ine
        }
      }
      tipoSanguineo
      orientacaoSexualDbEnum
      estadoCivil {
        id
        nome
      }
      paisExterior {
        id
        nome
      }
      localidadeExterior
      nacionalidade {
        id
        nacionalidadeDbEnum
      }
      portariaNaturalizacao
      dataNaturalizacao
      paisNascimento {
        id
        nome
      }
      dataEntradaBrasil
      stCompartilhaProntuario
    }
  }
`

/**
 * __useBuscaDetailCidadaoQuery__
 *
 * To run a query within a React component, call `useBuscaDetailCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaDetailCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaDetailCidadaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuscaDetailCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BuscaDetailCidadaoQuery, Types.BuscaDetailCidadaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BuscaDetailCidadaoQuery, Types.BuscaDetailCidadaoQueryVariables>(
    BuscaDetailCidadaoDocument,
    baseOptions
  )
}
export function useBuscaDetailCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaDetailCidadaoQuery,
    Types.BuscaDetailCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaDetailCidadaoQuery, Types.BuscaDetailCidadaoQueryVariables>(
    BuscaDetailCidadaoDocument,
    baseOptions
  )
}
export type BuscaDetailCidadaoQueryHookResult = ReturnType<typeof useBuscaDetailCidadaoQuery>
export type BuscaDetailCidadaoLazyQueryHookResult = ReturnType<typeof useBuscaDetailCidadaoLazyQuery>
export type BuscaDetailCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaDetailCidadaoQuery,
  Types.BuscaDetailCidadaoQueryVariables
>
export const CidadaoEditDocument = gql`
  query CidadaoEdit($input: BuscaCidadaoCadsusInput!) {
    buscaCidadaoCadsusAtualizado(buscaCidadaoCadsusInput: $input)
  }
`

/**
 * __useCidadaoEditQuery__
 *
 * To run a query within a React component, call `useCidadaoEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoEditQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCidadaoEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>(
    CidadaoEditDocument,
    baseOptions
  )
}
export function useCidadaoEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>(
    CidadaoEditDocument,
    baseOptions
  )
}
export type CidadaoEditQueryHookResult = ReturnType<typeof useCidadaoEditQuery>
export type CidadaoEditLazyQueryHookResult = ReturnType<typeof useCidadaoEditLazyQuery>
export type CidadaoEditQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoEditQuery,
  Types.CidadaoEditQueryVariables
>
export const CidadaoListingDocument = gql`
  query CidadaoListing($filtro: CidadaosQueryInput!) {
    cidadaos(input: $filtro) {
      content {
        id
        nome
        nomeSocial
        cpf
        cns
        nomeMae
        dataNascimento
        telefoneCelular
        telefoneResidencial
        sexo
        identidadeGeneroDbEnum
        dataAtualizado
        ativo
        unificado
        unificacaoBase
        prontuario {
          id
        }
        possuiAgendamento
        localidadeNascimento {
          id
          nome
          uf {
            id
            nome
            sigla
          }
        }
        faleceu
        cidadaoVinculacaoEquipe {
          id
          unidadeSaude {
            id
            nome
          }
        }
      }
    }
  }
`

/**
 * __useCidadaoListingQuery__
 *
 * To run a query within a React component, call `useCidadaoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoListingQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCidadaoListingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>(
    CidadaoListingDocument,
    baseOptions
  )
}
export function useCidadaoListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>(
    CidadaoListingDocument,
    baseOptions
  )
}
export type CidadaoListingQueryHookResult = ReturnType<typeof useCidadaoListingQuery>
export type CidadaoListingLazyQueryHookResult = ReturnType<typeof useCidadaoListingLazyQuery>
export type CidadaoListingQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoListingQuery,
  Types.CidadaoListingQueryVariables
>
export const CidadaoDocument = gql`
  query Cidadao($id: ID!) {
    cidadao(id: $id) {
      id
      nome
      nomeSocial
      cpf
      cns
      nomeMae
      dataNascimento
      sexo
      identidadeGeneroDbEnum
      faleceu
    }
  }
`

/**
 * __useCidadaoQuery__
 *
 * To run a query within a React component, call `useCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoQuery, Types.CidadaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoQuery, Types.CidadaoQueryVariables>(CidadaoDocument, baseOptions)
}
export function useCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidadaoQuery, Types.CidadaoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoQuery, Types.CidadaoQueryVariables>(CidadaoDocument, baseOptions)
}
export type CidadaoQueryHookResult = ReturnType<typeof useCidadaoQuery>
export type CidadaoLazyQueryHookResult = ReturnType<typeof useCidadaoLazyQuery>
export type CidadaoQueryResult = ApolloReactCommon.QueryResult<Types.CidadaoQuery, Types.CidadaoQueryVariables>
export const CondicoesAutorreferidasDocument = gql`
  query CondicoesAutorreferidas($cidadaoId: ID!) {
    condicoesAutorreferidas(cidadaoId: $cidadaoId) {
      descricao
    }
  }
`

/**
 * __useCondicoesAutorreferidasQuery__
 *
 * To run a query within a React component, call `useCondicoesAutorreferidasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCondicoesAutorreferidasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCondicoesAutorreferidasQuery({
 *   variables: {
 *      cidadaoId: // value for 'cidadaoId'
 *   },
 * });
 */
export function useCondicoesAutorreferidasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CondicoesAutorreferidasQuery,
    Types.CondicoesAutorreferidasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CondicoesAutorreferidasQuery, Types.CondicoesAutorreferidasQueryVariables>(
    CondicoesAutorreferidasDocument,
    baseOptions
  )
}
export function useCondicoesAutorreferidasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CondicoesAutorreferidasQuery,
    Types.CondicoesAutorreferidasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CondicoesAutorreferidasQuery, Types.CondicoesAutorreferidasQueryVariables>(
    CondicoesAutorreferidasDocument,
    baseOptions
  )
}
export type CondicoesAutorreferidasQueryHookResult = ReturnType<typeof useCondicoesAutorreferidasQuery>
export type CondicoesAutorreferidasLazyQueryHookResult = ReturnType<typeof useCondicoesAutorreferidasLazyQuery>
export type CondicoesAutorreferidasQueryResult = ApolloReactCommon.QueryResult<
  Types.CondicoesAutorreferidasQuery,
  Types.CondicoesAutorreferidasQueryVariables
>
export const ExcluirCidadaoDocument = gql`
  mutation ExcluirCidadao($cidadaoId: ID!) {
    excluirCidadao(cidadaoId: $cidadaoId)
  }
`
export type ExcluirCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirCidadaoMutation,
  Types.ExcluirCidadaoMutationVariables
>

/**
 * __useExcluirCidadaoMutation__
 *
 * To run a mutation, you first call `useExcluirCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirCidadaoMutation, { data, loading, error }] = useExcluirCidadaoMutation({
 *   variables: {
 *      cidadaoId: // value for 'cidadaoId'
 *   },
 * });
 */
export function useExcluirCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirCidadaoMutation,
    Types.ExcluirCidadaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ExcluirCidadaoMutation, Types.ExcluirCidadaoMutationVariables>(
    ExcluirCidadaoDocument,
    baseOptions
  )
}
export type ExcluirCidadaoMutationHookResult = ReturnType<typeof useExcluirCidadaoMutation>
export type ExcluirCidadaoMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirCidadaoMutation>
export type ExcluirCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirCidadaoMutation,
  Types.ExcluirCidadaoMutationVariables
>
export const HasCidadaoAgendamentoFuturoDocument = gql`
  query HasCidadaoAgendamentoFuturo($input: CidadaoPossuiAgendamentoFuturoQueryInput!) {
    hasAgendamentoFuturo(input: $input)
  }
`

/**
 * __useHasCidadaoAgendamentoFuturoQuery__
 *
 * To run a query within a React component, call `useHasCidadaoAgendamentoFuturoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCidadaoAgendamentoFuturoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCidadaoAgendamentoFuturoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHasCidadaoAgendamentoFuturoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >(HasCidadaoAgendamentoFuturoDocument, baseOptions)
}
export function useHasCidadaoAgendamentoFuturoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >(HasCidadaoAgendamentoFuturoDocument, baseOptions)
}
export type HasCidadaoAgendamentoFuturoQueryHookResult = ReturnType<typeof useHasCidadaoAgendamentoFuturoQuery>
export type HasCidadaoAgendamentoFuturoLazyQueryHookResult = ReturnType<typeof useHasCidadaoAgendamentoFuturoLazyQuery>
export type HasCidadaoAgendamentoFuturoQueryResult = ApolloReactCommon.QueryResult<
  Types.HasCidadaoAgendamentoFuturoQuery,
  Types.HasCidadaoAgendamentoFuturoQueryVariables
>
export const ReativarCidadaoDocument = gql`
  mutation ReativarCidadao($cidadaoId: ID!) {
    reativarCidadao(cidadaoId: $cidadaoId) {
      id
    }
  }
`
export type ReativarCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ReativarCidadaoMutation,
  Types.ReativarCidadaoMutationVariables
>

/**
 * __useReativarCidadaoMutation__
 *
 * To run a mutation, you first call `useReativarCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReativarCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reativarCidadaoMutation, { data, loading, error }] = useReativarCidadaoMutation({
 *   variables: {
 *      cidadaoId: // value for 'cidadaoId'
 *   },
 * });
 */
export function useReativarCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ReativarCidadaoMutation,
    Types.ReativarCidadaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ReativarCidadaoMutation, Types.ReativarCidadaoMutationVariables>(
    ReativarCidadaoDocument,
    baseOptions
  )
}
export type ReativarCidadaoMutationHookResult = ReturnType<typeof useReativarCidadaoMutation>
export type ReativarCidadaoMutationResult = ApolloReactCommon.MutationResult<Types.ReativarCidadaoMutation>
export type ReativarCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ReativarCidadaoMutation,
  Types.ReativarCidadaoMutationVariables
>
export const SalvarCidadaoDocument = gql`
  mutation SalvarCidadao($input: CidadaoInput!) {
    salvarCidadao(input: $input) {
      id
      nome
      cns
    }
  }
`
export type SalvarCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarCidadaoMutation,
  Types.SalvarCidadaoMutationVariables
>

/**
 * __useSalvarCidadaoMutation__
 *
 * To run a mutation, you first call `useSalvarCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarCidadaoMutation, { data, loading, error }] = useSalvarCidadaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SalvarCidadaoMutation, Types.SalvarCidadaoMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.SalvarCidadaoMutation, Types.SalvarCidadaoMutationVariables>(
    SalvarCidadaoDocument,
    baseOptions
  )
}
export type SalvarCidadaoMutationHookResult = ReturnType<typeof useSalvarCidadaoMutation>
export type SalvarCidadaoMutationResult = ApolloReactCommon.MutationResult<Types.SalvarCidadaoMutation>
export type SalvarCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarCidadaoMutation,
  Types.SalvarCidadaoMutationVariables
>
export const SincronizaCidadaoCadsusPollingDocument = gql`
  query SincronizaCidadaoCadsusPolling($uuid: String!) {
    sincronizacaoCadsusPolling(uuid: $uuid) {
      result
      errors
      uuid
      camposAlterados {
        campo
        conteudo
      }
      cidadaoCadsus {
        cns
        cpf
      }
      cidadaosDuplicados {
        id
        nome
        cpf
        cns
      }
      cidadaoLocal {
        id
        cpf
        cns
        nisPisPasep
        nome
        nomeSocial
        dataNascimento
        dataObito
        numeroDocumentoObito
        sexo
        desconheceNomeMae
        nomeMae
        desconheceNomePai
        nomePai
        telefoneResidencial
        telefoneCelular
        telefoneContato
        email
        area
        microArea
        statusForaArea
        endereco {
          bairro
          cep
          complemento
          logradouro
          numero
          pontoReferencia
          municipio {
            id
            ibge
            nome
            uf {
              id
              nome
              sigla
            }
          }
          semNumero
          tipoLogradouro {
            id
            nome
            numeroDne
          }
          uf {
            id
            nome
            sigla
          }
        }
        etnia {
          id
          nome
        }
        racaCor {
          id
          nome
          racaCorDbEnum
        }
        cbo {
          id
          nome
          cbo2002
        }
        escolaridade {
          id
          nome
        }
        localidadeNascimento {
          uf {
            id
            nome
            sigla
          }
          id
          nome
        }
        faleceu
        tipoSanguineo
        desejaInformarIdentidadeGenero
        identidadeGeneroDbEnum
        desejaInformarOrientacaoSexual
        orientacaoSexualDbEnum
        estadoCivil {
          id
          nome
        }
        paisExterior {
          id
          nome
        }
        localidadeExterior
        nacionalidade {
          id
          nacionalidadeDbEnum
        }
        portariaNaturalizacao
        dataNaturalizacao
        paisNascimento {
          id
          nome
        }
        dataEntradaBrasil
        stCompartilhaProntuario
        uuidUltimaFicha
        cidadaoVinculacaoEquipe {
          id
          utilizarCadastroIndividual
          unidadeSaude {
            id
            nome
            cnes
          }
          equipe {
            id
            nome
            ine
            tipoEquipe {
              id
              nome
              sigla
            }
          }
        }
      }
    }
  }
`

/**
 * __useSincronizaCidadaoCadsusPollingQuery__
 *
 * To run a query within a React component, call `useSincronizaCidadaoCadsusPollingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSincronizaCidadaoCadsusPollingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSincronizaCidadaoCadsusPollingQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSincronizaCidadaoCadsusPollingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.SincronizaCidadaoCadsusPollingQuery,
    Types.SincronizaCidadaoCadsusPollingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.SincronizaCidadaoCadsusPollingQuery,
    Types.SincronizaCidadaoCadsusPollingQueryVariables
  >(SincronizaCidadaoCadsusPollingDocument, baseOptions)
}
export function useSincronizaCidadaoCadsusPollingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.SincronizaCidadaoCadsusPollingQuery,
    Types.SincronizaCidadaoCadsusPollingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.SincronizaCidadaoCadsusPollingQuery,
    Types.SincronizaCidadaoCadsusPollingQueryVariables
  >(SincronizaCidadaoCadsusPollingDocument, baseOptions)
}
export type SincronizaCidadaoCadsusPollingQueryHookResult = ReturnType<typeof useSincronizaCidadaoCadsusPollingQuery>
export type SincronizaCidadaoCadsusPollingLazyQueryHookResult = ReturnType<
  typeof useSincronizaCidadaoCadsusPollingLazyQuery
>
export type SincronizaCidadaoCadsusPollingQueryResult = ApolloReactCommon.QueryResult<
  Types.SincronizaCidadaoCadsusPollingQuery,
  Types.SincronizaCidadaoCadsusPollingQueryVariables
>
export const UnificacoesCidadaoDocument = gql`
  query UnificacoesCidadao($id: ID!) {
    unificacoesCidadao(cidadaoId: $id) {
      id
      nomeProfissional
      dataUnificacao
      cidadaos {
        id
        cpf
        cns
        nome
        nomeSocial
        dataNascimento
        identidadeGeneroDbEnum
        sexo
        nomeMae
        localidadeNascimento {
          id
          nome
          uf {
            id
            sigla
          }
        }
      }
    }
  }
`

/**
 * __useUnificacoesCidadaoQuery__
 *
 * To run a query within a React component, call `useUnificacoesCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnificacoesCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnificacoesCidadaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnificacoesCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UnificacoesCidadaoQuery, Types.UnificacoesCidadaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.UnificacoesCidadaoQuery, Types.UnificacoesCidadaoQueryVariables>(
    UnificacoesCidadaoDocument,
    baseOptions
  )
}
export function useUnificacoesCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnificacoesCidadaoQuery,
    Types.UnificacoesCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnificacoesCidadaoQuery, Types.UnificacoesCidadaoQueryVariables>(
    UnificacoesCidadaoDocument,
    baseOptions
  )
}
export type UnificacoesCidadaoQueryHookResult = ReturnType<typeof useUnificacoesCidadaoQuery>
export type UnificacoesCidadaoLazyQueryHookResult = ReturnType<typeof useUnificacoesCidadaoLazyQuery>
export type UnificacoesCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.UnificacoesCidadaoQuery,
  Types.UnificacoesCidadaoQueryVariables
>
export const VinculacaoFichaCadastroIndividualDocument = gql`
  query VinculacaoFichaCadastroIndividual($input: CidadaoVinculacaoFichaQueryInput!) {
    vinculacaoFichaCadastroIndividual(input: $input) {
      perguntaSaidaCadastroEnum
      unidadeSaude {
        id
        nome
        cnes
      }
      equipe {
        id
        ine
        nome
        tipoEquipe {
          id
          nome
          sigla
        }
      }
    }
  }
`

/**
 * __useVinculacaoFichaCadastroIndividualQuery__
 *
 * To run a query within a React component, call `useVinculacaoFichaCadastroIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useVinculacaoFichaCadastroIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVinculacaoFichaCadastroIndividualQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVinculacaoFichaCadastroIndividualQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >(VinculacaoFichaCadastroIndividualDocument, baseOptions)
}
export function useVinculacaoFichaCadastroIndividualLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >(VinculacaoFichaCadastroIndividualDocument, baseOptions)
}
export type VinculacaoFichaCadastroIndividualQueryHookResult = ReturnType<
  typeof useVinculacaoFichaCadastroIndividualQuery
>
export type VinculacaoFichaCadastroIndividualLazyQueryHookResult = ReturnType<
  typeof useVinculacaoFichaCadastroIndividualLazyQuery
>
export type VinculacaoFichaCadastroIndividualQueryResult = ApolloReactCommon.QueryResult<
  Types.VinculacaoFichaCadastroIndividualQuery,
  Types.VinculacaoFichaCadastroIndividualQueryVariables
>
export const BuscaCidadaoCadsusDocument = gql`
  query BuscaCidadaoCadsus($filter: BuscaListaCidadaoCadsusInput!) {
    buscaCidadaosCadsus(filtro: $filter)
  }
`

/**
 * __useBuscaCidadaoCadsusQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoCadsusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoCadsusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoCadsusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBuscaCidadaoCadsusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BuscaCidadaoCadsusQuery, Types.BuscaCidadaoCadsusQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BuscaCidadaoCadsusQuery, Types.BuscaCidadaoCadsusQueryVariables>(
    BuscaCidadaoCadsusDocument,
    baseOptions
  )
}
export function useBuscaCidadaoCadsusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoCadsusQuery,
    Types.BuscaCidadaoCadsusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaCidadaoCadsusQuery, Types.BuscaCidadaoCadsusQueryVariables>(
    BuscaCidadaoCadsusDocument,
    baseOptions
  )
}
export type BuscaCidadaoCadsusQueryHookResult = ReturnType<typeof useBuscaCidadaoCadsusQuery>
export type BuscaCidadaoCadsusLazyQueryHookResult = ReturnType<typeof useBuscaCidadaoCadsusLazyQuery>
export type BuscaCidadaoCadsusQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoCadsusQuery,
  Types.BuscaCidadaoCadsusQueryVariables
>
export const ConfiguracaoCadsusDocument = gql`
  query configuracaoCadsus {
    conexao {
      internet {
        habilitado
      }
      cadsus {
        id
        habilitado
      }
    }
    configuracaoCertificadoHabilitado {
      id
      cadsusHabilitado
    }
  }
`

/**
 * __useConfiguracaoCadsusQuery__
 *
 * To run a query within a React component, call `useConfiguracaoCadsusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoCadsusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoCadsusQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoCadsusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ConfiguracaoCadsusQuery, Types.ConfiguracaoCadsusQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoCadsusQuery, Types.ConfiguracaoCadsusQueryVariables>(
    ConfiguracaoCadsusDocument,
    baseOptions
  )
}
export function useConfiguracaoCadsusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoCadsusQuery,
    Types.ConfiguracaoCadsusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracaoCadsusQuery, Types.ConfiguracaoCadsusQueryVariables>(
    ConfiguracaoCadsusDocument,
    baseOptions
  )
}
export type ConfiguracaoCadsusQueryHookResult = ReturnType<typeof useConfiguracaoCadsusQuery>
export type ConfiguracaoCadsusLazyQueryHookResult = ReturnType<typeof useConfiguracaoCadsusLazyQuery>
export type ConfiguracaoCadsusQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoCadsusQuery,
  Types.ConfiguracaoCadsusQueryVariables
>
export const ConfiguracaoCertificadoDocument = gql`
  query configuracaoCertificado {
    configuracaoCertificadoHabilitado {
      id
      cadsusHabilitado
    }
  }
`

/**
 * __useConfiguracaoCertificadoQuery__
 *
 * To run a query within a React component, call `useConfiguracaoCertificadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoCertificadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoCertificadoQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoCertificadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoCertificadoQuery,
    Types.ConfiguracaoCertificadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoCertificadoQuery, Types.ConfiguracaoCertificadoQueryVariables>(
    ConfiguracaoCertificadoDocument,
    baseOptions
  )
}
export function useConfiguracaoCertificadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoCertificadoQuery,
    Types.ConfiguracaoCertificadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracaoCertificadoQuery, Types.ConfiguracaoCertificadoQueryVariables>(
    ConfiguracaoCertificadoDocument,
    baseOptions
  )
}
export type ConfiguracaoCertificadoQueryHookResult = ReturnType<typeof useConfiguracaoCertificadoQuery>
export type ConfiguracaoCertificadoLazyQueryHookResult = ReturnType<typeof useConfiguracaoCertificadoLazyQuery>
export type ConfiguracaoCertificadoQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoCertificadoQuery,
  Types.ConfiguracaoCertificadoQueryVariables
>
export const CreateCidadaoCadsusDocument = gql`
  mutation CreateCidadaoCadsus($input: SincronizaCidadaoCadsusInput!) {
    createCidadaoCadsus(input: $input)
  }
`
export type CreateCidadaoCadsusMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateCidadaoCadsusMutation,
  Types.CreateCidadaoCadsusMutationVariables
>

/**
 * __useCreateCidadaoCadsusMutation__
 *
 * To run a mutation, you first call `useCreateCidadaoCadsusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCidadaoCadsusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCidadaoCadsusMutation, { data, loading, error }] = useCreateCidadaoCadsusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCidadaoCadsusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateCidadaoCadsusMutation,
    Types.CreateCidadaoCadsusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.CreateCidadaoCadsusMutation, Types.CreateCidadaoCadsusMutationVariables>(
    CreateCidadaoCadsusDocument,
    baseOptions
  )
}
export type CreateCidadaoCadsusMutationHookResult = ReturnType<typeof useCreateCidadaoCadsusMutation>
export type CreateCidadaoCadsusMutationResult = ApolloReactCommon.MutationResult<Types.CreateCidadaoCadsusMutation>
export type CreateCidadaoCadsusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateCidadaoCadsusMutation,
  Types.CreateCidadaoCadsusMutationVariables
>
export const ForcaAtualizacaoCidadaoCadsusDocument = gql`
  query forcaAtualizacaoCidadaoCadsus($input: BuscaCidadaoCadsusInput!) {
    buscaCidadaoCadsusAtualizadoForcado(buscaCidadaoCadsusInput: $input)
  }
`

/**
 * __useForcaAtualizacaoCidadaoCadsusQuery__
 *
 * To run a query within a React component, call `useForcaAtualizacaoCidadaoCadsusQuery` and pass it any options that fit your needs.
 * When your component renders, `useForcaAtualizacaoCidadaoCadsusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForcaAtualizacaoCidadaoCadsusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForcaAtualizacaoCidadaoCadsusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ForcaAtualizacaoCidadaoCadsusQuery,
    Types.ForcaAtualizacaoCidadaoCadsusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ForcaAtualizacaoCidadaoCadsusQuery,
    Types.ForcaAtualizacaoCidadaoCadsusQueryVariables
  >(ForcaAtualizacaoCidadaoCadsusDocument, baseOptions)
}
export function useForcaAtualizacaoCidadaoCadsusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ForcaAtualizacaoCidadaoCadsusQuery,
    Types.ForcaAtualizacaoCidadaoCadsusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ForcaAtualizacaoCidadaoCadsusQuery,
    Types.ForcaAtualizacaoCidadaoCadsusQueryVariables
  >(ForcaAtualizacaoCidadaoCadsusDocument, baseOptions)
}
export type ForcaAtualizacaoCidadaoCadsusQueryHookResult = ReturnType<typeof useForcaAtualizacaoCidadaoCadsusQuery>
export type ForcaAtualizacaoCidadaoCadsusLazyQueryHookResult = ReturnType<
  typeof useForcaAtualizacaoCidadaoCadsusLazyQuery
>
export type ForcaAtualizacaoCidadaoCadsusQueryResult = ApolloReactCommon.QueryResult<
  Types.ForcaAtualizacaoCidadaoCadsusQuery,
  Types.ForcaAtualizacaoCidadaoCadsusQueryVariables
>
export const ImportacoesCnesDocument = gql`
  query ImportacoesCnes($input: ImportacoesCnesQueryInput!) {
    importacoesCnes(input: $input) {
      content {
        id
        dataImportacao
        equipesNovas
        equipesAtualizadas
        lotacoesNovas
        lotacoesAtualizadas
        processo {
          id
          status
        }
        profissional {
          id
          nome
        }
        profissionaisNovos
        profissionaisAtualizados
        unidadesSaudeNovas
        unidadesSaudeAtualizadas
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useImportacoesCnesQuery__
 *
 * To run a query within a React component, call `useImportacoesCnesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportacoesCnesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportacoesCnesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportacoesCnesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>(
    ImportacoesCnesDocument,
    baseOptions
  )
}
export function useImportacoesCnesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>(
    ImportacoesCnesDocument,
    baseOptions
  )
}
export type ImportacoesCnesQueryHookResult = ReturnType<typeof useImportacoesCnesQuery>
export type ImportacoesCnesLazyQueryHookResult = ReturnType<typeof useImportacoesCnesLazyQuery>
export type ImportacoesCnesQueryResult = ApolloReactCommon.QueryResult<
  Types.ImportacoesCnesQuery,
  Types.ImportacoesCnesQueryVariables
>
export const DetalhesImportacaoCnesViewDocument = gql`
  query DetalhesImportacaoCnesView($id: ID!) {
    importacaoCnes(id: $id) {
      id
      dataImportacao
      detalhes
      equipesNovas
      equipesAtualizadas
      lotacoesNovas
      lotacoesAtualizadas
      profissionaisNovos
      profissionaisAtualizados
      unidadesSaudeNovas
      unidadesSaudeAtualizadas
      vinculacoesEquipesNovas
      vinculacoesEquipesAtualizadas
      municipio {
        id
        nome
      }
    }
  }
`

/**
 * __useDetalhesImportacaoCnesViewQuery__
 *
 * To run a query within a React component, call `useDetalhesImportacaoCnesViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetalhesImportacaoCnesViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetalhesImportacaoCnesViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetalhesImportacaoCnesViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DetalhesImportacaoCnesViewQuery,
    Types.DetalhesImportacaoCnesViewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DetalhesImportacaoCnesViewQuery,
    Types.DetalhesImportacaoCnesViewQueryVariables
  >(DetalhesImportacaoCnesViewDocument, baseOptions)
}
export function useDetalhesImportacaoCnesViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DetalhesImportacaoCnesViewQuery,
    Types.DetalhesImportacaoCnesViewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DetalhesImportacaoCnesViewQuery,
    Types.DetalhesImportacaoCnesViewQueryVariables
  >(DetalhesImportacaoCnesViewDocument, baseOptions)
}
export type DetalhesImportacaoCnesViewQueryHookResult = ReturnType<typeof useDetalhesImportacaoCnesViewQuery>
export type DetalhesImportacaoCnesViewLazyQueryHookResult = ReturnType<typeof useDetalhesImportacaoCnesViewLazyQuery>
export type DetalhesImportacaoCnesViewQueryResult = ApolloReactCommon.QueryResult<
  Types.DetalhesImportacaoCnesViewQuery,
  Types.DetalhesImportacaoCnesViewQueryVariables
>
export const ConfiguracoesAvancadasDocument = gql`
  query ConfiguracoesAvancadas {
    avancada {
      performance {
        qtdRequisicoesSimultaneas
        buscaCidadaoPorPropriedades {
          id
          habilitado
        }
      }
    }
  }
`

/**
 * __useConfiguracoesAvancadasQuery__
 *
 * To run a query within a React component, call `useConfiguracoesAvancadasQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracoesAvancadasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracoesAvancadasQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracoesAvancadasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracoesAvancadasQuery,
    Types.ConfiguracoesAvancadasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracoesAvancadasQuery, Types.ConfiguracoesAvancadasQueryVariables>(
    ConfiguracoesAvancadasDocument,
    baseOptions
  )
}
export function useConfiguracoesAvancadasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracoesAvancadasQuery,
    Types.ConfiguracoesAvancadasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracoesAvancadasQuery, Types.ConfiguracoesAvancadasQueryVariables>(
    ConfiguracoesAvancadasDocument,
    baseOptions
  )
}
export type ConfiguracoesAvancadasQueryHookResult = ReturnType<typeof useConfiguracoesAvancadasQuery>
export type ConfiguracoesAvancadasLazyQueryHookResult = ReturnType<typeof useConfiguracoesAvancadasLazyQuery>
export type ConfiguracoesAvancadasQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracoesAvancadasQuery,
  Types.ConfiguracoesAvancadasQueryVariables
>
export const AlterarBuscaCidadaoPorPropriedadesDocument = gql`
  mutation AlterarBuscaCidadaoPorPropriedades($input: Boolean!) {
    alterarBuscaCidadaoPorPropriedades(habilitado: $input) {
      id
      habilitado
    }
  }
`
export type AlterarBuscaCidadaoPorPropriedadesMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarBuscaCidadaoPorPropriedadesMutation,
  Types.AlterarBuscaCidadaoPorPropriedadesMutationVariables
>

/**
 * __useAlterarBuscaCidadaoPorPropriedadesMutation__
 *
 * To run a mutation, you first call `useAlterarBuscaCidadaoPorPropriedadesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarBuscaCidadaoPorPropriedadesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarBuscaCidadaoPorPropriedadesMutation, { data, loading, error }] = useAlterarBuscaCidadaoPorPropriedadesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarBuscaCidadaoPorPropriedadesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarBuscaCidadaoPorPropriedadesMutation,
    Types.AlterarBuscaCidadaoPorPropriedadesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarBuscaCidadaoPorPropriedadesMutation,
    Types.AlterarBuscaCidadaoPorPropriedadesMutationVariables
  >(AlterarBuscaCidadaoPorPropriedadesDocument, baseOptions)
}
export type AlterarBuscaCidadaoPorPropriedadesMutationHookResult = ReturnType<
  typeof useAlterarBuscaCidadaoPorPropriedadesMutation
>
export type AlterarBuscaCidadaoPorPropriedadesMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarBuscaCidadaoPorPropriedadesMutation
>
export type AlterarBuscaCidadaoPorPropriedadesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarBuscaCidadaoPorPropriedadesMutation,
  Types.AlterarBuscaCidadaoPorPropriedadesMutationVariables
>
export const AlterarQtdRequisicoesDocument = gql`
  mutation AlterarQtdRequisicoes($qtdRequisicoesSimultaneas: Int) {
    alterarQtdRequisicoes(qtdRequisicoesSimultaneas: $qtdRequisicoesSimultaneas) {
      qtdRequisicoesSimultaneas
    }
  }
`
export type AlterarQtdRequisicoesMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarQtdRequisicoesMutation,
  Types.AlterarQtdRequisicoesMutationVariables
>

/**
 * __useAlterarQtdRequisicoesMutation__
 *
 * To run a mutation, you first call `useAlterarQtdRequisicoesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarQtdRequisicoesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarQtdRequisicoesMutation, { data, loading, error }] = useAlterarQtdRequisicoesMutation({
 *   variables: {
 *      qtdRequisicoesSimultaneas: // value for 'qtdRequisicoesSimultaneas'
 *   },
 * });
 */
export function useAlterarQtdRequisicoesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarQtdRequisicoesMutation,
    Types.AlterarQtdRequisicoesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarQtdRequisicoesMutation,
    Types.AlterarQtdRequisicoesMutationVariables
  >(AlterarQtdRequisicoesDocument, baseOptions)
}
export type AlterarQtdRequisicoesMutationHookResult = ReturnType<typeof useAlterarQtdRequisicoesMutation>
export type AlterarQtdRequisicoesMutationResult = ApolloReactCommon.MutationResult<Types.AlterarQtdRequisicoesMutation>
export type AlterarQtdRequisicoesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarQtdRequisicoesMutation,
  Types.AlterarQtdRequisicoesMutationVariables
>
export const ConexaoDocument = gql`
  query Conexao {
    conexao {
      internet {
        habilitado
        dataUltimoTeste
        resultadoUltimoTeste
      }
      cadsus {
        id
        habilitado
        desabilitadoAteData
        intervalo
      }
      horus {
        id
        habilitado
        desabilitadoAteData
        intervalo
      }
      videochamadas {
        id
        habilitado
      }
      agendaOnline {
        ativa
        detalhesAtivacao {
          profissional {
            nome
          }
          dataEvento
        }
        testeConexao {
          sucesso
          dataEvento
        }
      }
      prescricaoDigital {
        id
        habilitado
        usuario
        senha
      }
    }
    info {
      smtpConfigurado
    }
  }
`

/**
 * __useConexaoQuery__
 *
 * To run a query within a React component, call `useConexaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConexaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConexaoQuery({
 *   variables: {
 *   },
 * });
 */
export function useConexaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ConexaoQuery, Types.ConexaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ConexaoQuery, Types.ConexaoQueryVariables>(ConexaoDocument, baseOptions)
}
export function useConexaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ConexaoQuery, Types.ConexaoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ConexaoQuery, Types.ConexaoQueryVariables>(ConexaoDocument, baseOptions)
}
export type ConexaoQueryHookResult = ReturnType<typeof useConexaoQuery>
export type ConexaoLazyQueryHookResult = ReturnType<typeof useConexaoLazyQuery>
export type ConexaoQueryResult = ApolloReactCommon.QueryResult<Types.ConexaoQuery, Types.ConexaoQueryVariables>
export const AlternarConexaoInternetDocument = gql`
  mutation AlternarConexaoInternet($habilitado: Boolean) {
    alternarInternet(habilitado: $habilitado) {
      habilitado
    }
  }
`
export type AlternarConexaoInternetMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlternarConexaoInternetMutation,
  Types.AlternarConexaoInternetMutationVariables
>

/**
 * __useAlternarConexaoInternetMutation__
 *
 * To run a mutation, you first call `useAlternarConexaoInternetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlternarConexaoInternetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alternarConexaoInternetMutation, { data, loading, error }] = useAlternarConexaoInternetMutation({
 *   variables: {
 *      habilitado: // value for 'habilitado'
 *   },
 * });
 */
export function useAlternarConexaoInternetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlternarConexaoInternetMutation,
    Types.AlternarConexaoInternetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlternarConexaoInternetMutation,
    Types.AlternarConexaoInternetMutationVariables
  >(AlternarConexaoInternetDocument, baseOptions)
}
export type AlternarConexaoInternetMutationHookResult = ReturnType<typeof useAlternarConexaoInternetMutation>
export type AlternarConexaoInternetMutationResult = ApolloReactCommon.MutationResult<
  Types.AlternarConexaoInternetMutation
>
export type AlternarConexaoInternetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlternarConexaoInternetMutation,
  Types.AlternarConexaoInternetMutationVariables
>
export const TestarConexaoInternetDocument = gql`
  mutation TestarConexaoInternet {
    testarConexaoInternet {
      habilitado
      dataUltimoTeste
      resultadoUltimoTeste
    }
  }
`
export type TestarConexaoInternetMutationFn = ApolloReactCommon.MutationFunction<
  Types.TestarConexaoInternetMutation,
  Types.TestarConexaoInternetMutationVariables
>

/**
 * __useTestarConexaoInternetMutation__
 *
 * To run a mutation, you first call `useTestarConexaoInternetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestarConexaoInternetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testarConexaoInternetMutation, { data, loading, error }] = useTestarConexaoInternetMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestarConexaoInternetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TestarConexaoInternetMutation,
    Types.TestarConexaoInternetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.TestarConexaoInternetMutation,
    Types.TestarConexaoInternetMutationVariables
  >(TestarConexaoInternetDocument, baseOptions)
}
export type TestarConexaoInternetMutationHookResult = ReturnType<typeof useTestarConexaoInternetMutation>
export type TestarConexaoInternetMutationResult = ApolloReactCommon.MutationResult<Types.TestarConexaoInternetMutation>
export type TestarConexaoInternetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.TestarConexaoInternetMutation,
  Types.TestarConexaoInternetMutationVariables
>
export const GerarChaveAtivacaoAgendaOnlineDocument = gql`
  mutation GerarChaveAtivacaoAgendaOnline {
    gerarChaveAtivacaoAgendaOnline
  }
`
export type GerarChaveAtivacaoAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.GerarChaveAtivacaoAgendaOnlineMutation,
  Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
>

/**
 * __useGerarChaveAtivacaoAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useGerarChaveAtivacaoAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGerarChaveAtivacaoAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gerarChaveAtivacaoAgendaOnlineMutation, { data, loading, error }] = useGerarChaveAtivacaoAgendaOnlineMutation({
 *   variables: {
 *   },
 * });
 */
export function useGerarChaveAtivacaoAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.GerarChaveAtivacaoAgendaOnlineMutation,
    Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.GerarChaveAtivacaoAgendaOnlineMutation,
    Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
  >(GerarChaveAtivacaoAgendaOnlineDocument, baseOptions)
}
export type GerarChaveAtivacaoAgendaOnlineMutationHookResult = ReturnType<
  typeof useGerarChaveAtivacaoAgendaOnlineMutation
>
export type GerarChaveAtivacaoAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<
  Types.GerarChaveAtivacaoAgendaOnlineMutation
>
export type GerarChaveAtivacaoAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.GerarChaveAtivacaoAgendaOnlineMutation,
  Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
>
export const AtivarAgendaOnlineDocument = gql`
  mutation AtivarAgendaOnline($ativacaoAgendaOnlineInput: AtivacaoAgendaOnlineInput!) {
    ativarAgendaOnline(ativacaoAgendaOnlineInput: $ativacaoAgendaOnlineInput) {
      ativa
      detalhesAtivacao {
        profissional {
          nome
        }
        dataEvento
      }
    }
  }
`
export type AtivarAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtivarAgendaOnlineMutation,
  Types.AtivarAgendaOnlineMutationVariables
>

/**
 * __useAtivarAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useAtivarAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtivarAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ativarAgendaOnlineMutation, { data, loading, error }] = useAtivarAgendaOnlineMutation({
 *   variables: {
 *      ativacaoAgendaOnlineInput: // value for 'ativacaoAgendaOnlineInput'
 *   },
 * });
 */
export function useAtivarAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtivarAgendaOnlineMutation,
    Types.AtivarAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtivarAgendaOnlineMutation, Types.AtivarAgendaOnlineMutationVariables>(
    AtivarAgendaOnlineDocument,
    baseOptions
  )
}
export type AtivarAgendaOnlineMutationHookResult = ReturnType<typeof useAtivarAgendaOnlineMutation>
export type AtivarAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<Types.AtivarAgendaOnlineMutation>
export type AtivarAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtivarAgendaOnlineMutation,
  Types.AtivarAgendaOnlineMutationVariables
>
export const TestarConexaoAgendaOnlineDocument = gql`
  mutation TestarConexaoAgendaOnline {
    testarConexaoAgendaOnline {
      sucesso
      dataEvento
    }
  }
`
export type TestarConexaoAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.TestarConexaoAgendaOnlineMutation,
  Types.TestarConexaoAgendaOnlineMutationVariables
>

/**
 * __useTestarConexaoAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useTestarConexaoAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestarConexaoAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testarConexaoAgendaOnlineMutation, { data, loading, error }] = useTestarConexaoAgendaOnlineMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestarConexaoAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TestarConexaoAgendaOnlineMutation,
    Types.TestarConexaoAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.TestarConexaoAgendaOnlineMutation,
    Types.TestarConexaoAgendaOnlineMutationVariables
  >(TestarConexaoAgendaOnlineDocument, baseOptions)
}
export type TestarConexaoAgendaOnlineMutationHookResult = ReturnType<typeof useTestarConexaoAgendaOnlineMutation>
export type TestarConexaoAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<
  Types.TestarConexaoAgendaOnlineMutation
>
export type TestarConexaoAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.TestarConexaoAgendaOnlineMutation,
  Types.TestarConexaoAgendaOnlineMutationVariables
>
export const CfgLotacaoAgendaOnlineDocument = gql`
  query CfgLotacaoAgendaOnline {
    conexao {
      agendaOnline {
        ativa
      }
      hasLotacoesConfiguradasOnline
      lotacoesAgendaOnlineComDuracaoPadrao
    }
  }
`

/**
 * __useCfgLotacaoAgendaOnlineQuery__
 *
 * To run a query within a React component, call `useCfgLotacaoAgendaOnlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useCfgLotacaoAgendaOnlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCfgLotacaoAgendaOnlineQuery({
 *   variables: {
 *   },
 * });
 */
export function useCfgLotacaoAgendaOnlineQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CfgLotacaoAgendaOnlineQuery,
    Types.CfgLotacaoAgendaOnlineQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CfgLotacaoAgendaOnlineQuery, Types.CfgLotacaoAgendaOnlineQueryVariables>(
    CfgLotacaoAgendaOnlineDocument,
    baseOptions
  )
}
export function useCfgLotacaoAgendaOnlineLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CfgLotacaoAgendaOnlineQuery,
    Types.CfgLotacaoAgendaOnlineQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CfgLotacaoAgendaOnlineQuery, Types.CfgLotacaoAgendaOnlineQueryVariables>(
    CfgLotacaoAgendaOnlineDocument,
    baseOptions
  )
}
export type CfgLotacaoAgendaOnlineQueryHookResult = ReturnType<typeof useCfgLotacaoAgendaOnlineQuery>
export type CfgLotacaoAgendaOnlineLazyQueryHookResult = ReturnType<typeof useCfgLotacaoAgendaOnlineLazyQuery>
export type CfgLotacaoAgendaOnlineQueryResult = ApolloReactCommon.QueryResult<
  Types.CfgLotacaoAgendaOnlineQuery,
  Types.CfgLotacaoAgendaOnlineQueryVariables
>
export const DesativarAgendaOnlineDocument = gql`
  mutation DesativarAgendaOnline {
    desativarAgendaOnline {
      ativa
      detalhesAtivacao {
        profissional {
          nome
        }
        dataEvento
      }
    }
  }
`
export type DesativarAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.DesativarAgendaOnlineMutation,
  Types.DesativarAgendaOnlineMutationVariables
>

/**
 * __useDesativarAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useDesativarAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesativarAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desativarAgendaOnlineMutation, { data, loading, error }] = useDesativarAgendaOnlineMutation({
 *   variables: {
 *   },
 * });
 */
export function useDesativarAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DesativarAgendaOnlineMutation,
    Types.DesativarAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.DesativarAgendaOnlineMutation,
    Types.DesativarAgendaOnlineMutationVariables
  >(DesativarAgendaOnlineDocument, baseOptions)
}
export type DesativarAgendaOnlineMutationHookResult = ReturnType<typeof useDesativarAgendaOnlineMutation>
export type DesativarAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<Types.DesativarAgendaOnlineMutation>
export type DesativarAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DesativarAgendaOnlineMutation,
  Types.DesativarAgendaOnlineMutationVariables
>
export const UnificacaoBaseAtivaDocument = gql`
  query unificacaoBaseAtiva {
    pedidoEnvioBaseAtivo {
      id
    }
    unificacoesBase {
      id
      status
    }
  }
`

/**
 * __useUnificacaoBaseAtivaQuery__
 *
 * To run a query within a React component, call `useUnificacaoBaseAtivaQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnificacaoBaseAtivaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnificacaoBaseAtivaQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnificacaoBaseAtivaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnificacaoBaseAtivaQuery,
    Types.UnificacaoBaseAtivaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UnificacaoBaseAtivaQuery, Types.UnificacaoBaseAtivaQueryVariables>(
    UnificacaoBaseAtivaDocument,
    baseOptions
  )
}
export function useUnificacaoBaseAtivaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnificacaoBaseAtivaQuery,
    Types.UnificacaoBaseAtivaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnificacaoBaseAtivaQuery, Types.UnificacaoBaseAtivaQueryVariables>(
    UnificacaoBaseAtivaDocument,
    baseOptions
  )
}
export type UnificacaoBaseAtivaQueryHookResult = ReturnType<typeof useUnificacaoBaseAtivaQuery>
export type UnificacaoBaseAtivaLazyQueryHookResult = ReturnType<typeof useUnificacaoBaseAtivaLazyQuery>
export type UnificacaoBaseAtivaQueryResult = ApolloReactCommon.QueryResult<
  Types.UnificacaoBaseAtivaQuery,
  Types.UnificacaoBaseAtivaQueryVariables
>
export const AlterarConfiguracaoPrescricaoDigitalDocument = gql`
  mutation AlterarConfiguracaoPrescricaoDigital($input: AlterarConfiguracaoPrescricaoDigitalInput!) {
    alterarConfiguracaoPrescricaoDigital(input: $input) {
      id
      habilitado
      usuario
      senha
    }
  }
`
export type AlterarConfiguracaoPrescricaoDigitalMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarConfiguracaoPrescricaoDigitalMutation,
  Types.AlterarConfiguracaoPrescricaoDigitalMutationVariables
>

/**
 * __useAlterarConfiguracaoPrescricaoDigitalMutation__
 *
 * To run a mutation, you first call `useAlterarConfiguracaoPrescricaoDigitalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarConfiguracaoPrescricaoDigitalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarConfiguracaoPrescricaoDigitalMutation, { data, loading, error }] = useAlterarConfiguracaoPrescricaoDigitalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarConfiguracaoPrescricaoDigitalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarConfiguracaoPrescricaoDigitalMutation,
    Types.AlterarConfiguracaoPrescricaoDigitalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarConfiguracaoPrescricaoDigitalMutation,
    Types.AlterarConfiguracaoPrescricaoDigitalMutationVariables
  >(AlterarConfiguracaoPrescricaoDigitalDocument, baseOptions)
}
export type AlterarConfiguracaoPrescricaoDigitalMutationHookResult = ReturnType<
  typeof useAlterarConfiguracaoPrescricaoDigitalMutation
>
export type AlterarConfiguracaoPrescricaoDigitalMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarConfiguracaoPrescricaoDigitalMutation
>
export type AlterarConfiguracaoPrescricaoDigitalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarConfiguracaoPrescricaoDigitalMutation,
  Types.AlterarConfiguracaoPrescricaoDigitalMutationVariables
>
export const AlterarCadsusDocument = gql`
  mutation AlterarCadsus($alterarCadsusInput: AlterarCadsusInput!) {
    alterarCadsus(alterarCadsusInput: $alterarCadsusInput) {
      id
      habilitado
      intervalo
      desabilitadoAteData
    }
  }
`
export type AlterarCadsusMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarCadsusMutation,
  Types.AlterarCadsusMutationVariables
>

/**
 * __useAlterarCadsusMutation__
 *
 * To run a mutation, you first call `useAlterarCadsusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarCadsusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarCadsusMutation, { data, loading, error }] = useAlterarCadsusMutation({
 *   variables: {
 *      alterarCadsusInput: // value for 'alterarCadsusInput'
 *   },
 * });
 */
export function useAlterarCadsusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlterarCadsusMutation, Types.AlterarCadsusMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlterarCadsusMutation, Types.AlterarCadsusMutationVariables>(
    AlterarCadsusDocument,
    baseOptions
  )
}
export type AlterarCadsusMutationHookResult = ReturnType<typeof useAlterarCadsusMutation>
export type AlterarCadsusMutationResult = ApolloReactCommon.MutationResult<Types.AlterarCadsusMutation>
export type AlterarCadsusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarCadsusMutation,
  Types.AlterarCadsusMutationVariables
>
export const AlterarHorusDocument = gql`
  mutation AlterarHorus($alterarHorusInput: AlterarHorusInput!) {
    alterarHorus(alterarHorusInput: $alterarHorusInput) {
      id
      habilitado
      intervalo
      desabilitadoAteData
    }
  }
`
export type AlterarHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarHorusMutation,
  Types.AlterarHorusMutationVariables
>

/**
 * __useAlterarHorusMutation__
 *
 * To run a mutation, you first call `useAlterarHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarHorusMutation, { data, loading, error }] = useAlterarHorusMutation({
 *   variables: {
 *      alterarHorusInput: // value for 'alterarHorusInput'
 *   },
 * });
 */
export function useAlterarHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlterarHorusMutation, Types.AlterarHorusMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlterarHorusMutation, Types.AlterarHorusMutationVariables>(
    AlterarHorusDocument,
    baseOptions
  )
}
export type AlterarHorusMutationHookResult = ReturnType<typeof useAlterarHorusMutation>
export type AlterarHorusMutationResult = ApolloReactCommon.MutationResult<Types.AlterarHorusMutation>
export type AlterarHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarHorusMutation,
  Types.AlterarHorusMutationVariables
>
export const AlterarVideochamadaDocument = gql`
  mutation AlterarVideochamada($input: AlterarConfiguracaoVideochamadasInput!) {
    alterarConfiguracaoVideochamadas(input: $input) {
      id
      habilitado
    }
  }
`
export type AlterarVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarVideochamadaMutation,
  Types.AlterarVideochamadaMutationVariables
>

/**
 * __useAlterarVideochamadaMutation__
 *
 * To run a mutation, you first call `useAlterarVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarVideochamadaMutation, { data, loading, error }] = useAlterarVideochamadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarVideochamadaMutation,
    Types.AlterarVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarVideochamadaMutation, Types.AlterarVideochamadaMutationVariables>(
    AlterarVideochamadaDocument,
    baseOptions
  )
}
export type AlterarVideochamadaMutationHookResult = ReturnType<typeof useAlterarVideochamadaMutation>
export type AlterarVideochamadaMutationResult = ApolloReactCommon.MutationResult<Types.AlterarVideochamadaMutation>
export type AlterarVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarVideochamadaMutation,
  Types.AlterarVideochamadaMutationVariables
>
export const HasMunicipioAtivoDocument = gql`
  query HasMunicipioAtivo {
    hasMunicipioAtivo
  }
`

/**
 * __useHasMunicipioAtivoQuery__
 *
 * To run a query within a React component, call `useHasMunicipioAtivoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasMunicipioAtivoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasMunicipioAtivoQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasMunicipioAtivoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.HasMunicipioAtivoQuery, Types.HasMunicipioAtivoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.HasMunicipioAtivoQuery, Types.HasMunicipioAtivoQueryVariables>(
    HasMunicipioAtivoDocument,
    baseOptions
  )
}
export function useHasMunicipioAtivoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HasMunicipioAtivoQuery,
    Types.HasMunicipioAtivoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HasMunicipioAtivoQuery, Types.HasMunicipioAtivoQueryVariables>(
    HasMunicipioAtivoDocument,
    baseOptions
  )
}
export type HasMunicipioAtivoQueryHookResult = ReturnType<typeof useHasMunicipioAtivoQuery>
export type HasMunicipioAtivoLazyQueryHookResult = ReturnType<typeof useHasMunicipioAtivoLazyQuery>
export type HasMunicipioAtivoQueryResult = ApolloReactCommon.QueryResult<
  Types.HasMunicipioAtivoQuery,
  Types.HasMunicipioAtivoQueryVariables
>
export const MunicipiosResponsaveisDocument = gql`
  query MunicipiosResponsaveis($input: MunicipioResponsavelFiltroInput!) {
    municipiosResponsaveis(input: $input) {
      content {
        id
        profissional {
          id
          nome
        }
        municipio {
          id
          nome
          uf {
            id
            sigla
          }
        }
        habilitado
        ativo
        dataAdicao
        dataInativacao
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMunicipiosResponsaveisQuery__
 *
 * To run a query within a React component, call `useMunicipiosResponsaveisQuery` and pass it any options that fit your needs.
 * When your component renders, `useMunicipiosResponsaveisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMunicipiosResponsaveisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMunicipiosResponsaveisQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MunicipiosResponsaveisQuery,
    Types.MunicipiosResponsaveisQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.MunicipiosResponsaveisQuery, Types.MunicipiosResponsaveisQueryVariables>(
    MunicipiosResponsaveisDocument,
    baseOptions
  )
}
export function useMunicipiosResponsaveisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MunicipiosResponsaveisQuery,
    Types.MunicipiosResponsaveisQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.MunicipiosResponsaveisQuery, Types.MunicipiosResponsaveisQueryVariables>(
    MunicipiosResponsaveisDocument,
    baseOptions
  )
}
export type MunicipiosResponsaveisQueryHookResult = ReturnType<typeof useMunicipiosResponsaveisQuery>
export type MunicipiosResponsaveisLazyQueryHookResult = ReturnType<typeof useMunicipiosResponsaveisLazyQuery>
export type MunicipiosResponsaveisQueryResult = ApolloReactCommon.QueryResult<
  Types.MunicipiosResponsaveisQuery,
  Types.MunicipiosResponsaveisQueryVariables
>
export const InserirMunicipioResponsavelDocument = gql`
  mutation InserirMunicipioResponsavel($input: MunicipioResponsavelInput!) {
    inserirMunicipioResponsavel(input: $input) {
      id
      profissional {
        nome
      }
      municipio {
        nome
      }
      habilitado
      ativo
      dataAdicao
      dataInativacao
    }
  }
`
export type InserirMunicipioResponsavelMutationFn = ApolloReactCommon.MutationFunction<
  Types.InserirMunicipioResponsavelMutation,
  Types.InserirMunicipioResponsavelMutationVariables
>

/**
 * __useInserirMunicipioResponsavelMutation__
 *
 * To run a mutation, you first call `useInserirMunicipioResponsavelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInserirMunicipioResponsavelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inserirMunicipioResponsavelMutation, { data, loading, error }] = useInserirMunicipioResponsavelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInserirMunicipioResponsavelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InserirMunicipioResponsavelMutation,
    Types.InserirMunicipioResponsavelMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.InserirMunicipioResponsavelMutation,
    Types.InserirMunicipioResponsavelMutationVariables
  >(InserirMunicipioResponsavelDocument, baseOptions)
}
export type InserirMunicipioResponsavelMutationHookResult = ReturnType<typeof useInserirMunicipioResponsavelMutation>
export type InserirMunicipioResponsavelMutationResult = ApolloReactCommon.MutationResult<
  Types.InserirMunicipioResponsavelMutation
>
export type InserirMunicipioResponsavelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InserirMunicipioResponsavelMutation,
  Types.InserirMunicipioResponsavelMutationVariables
>
export const AtualizarMunicipioResponsavelDocument = gql`
  mutation AtualizarMunicipioResponsavel($input: MunicipioResponsavelUpdateInput!) {
    atualizarMunicipioResponsavel(input: $input) {
      id
      profissional {
        id
        nome
      }
      municipio {
        id
        nome
        uf {
          id
          sigla
        }
      }
      habilitado
      ativo
      dataAdicao
      dataInativacao
    }
  }
`
export type AtualizarMunicipioResponsavelMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtualizarMunicipioResponsavelMutation,
  Types.AtualizarMunicipioResponsavelMutationVariables
>

/**
 * __useAtualizarMunicipioResponsavelMutation__
 *
 * To run a mutation, you first call `useAtualizarMunicipioResponsavelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarMunicipioResponsavelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarMunicipioResponsavelMutation, { data, loading, error }] = useAtualizarMunicipioResponsavelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtualizarMunicipioResponsavelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtualizarMunicipioResponsavelMutation,
    Types.AtualizarMunicipioResponsavelMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AtualizarMunicipioResponsavelMutation,
    Types.AtualizarMunicipioResponsavelMutationVariables
  >(AtualizarMunicipioResponsavelDocument, baseOptions)
}
export type AtualizarMunicipioResponsavelMutationHookResult = ReturnType<
  typeof useAtualizarMunicipioResponsavelMutation
>
export type AtualizarMunicipioResponsavelMutationResult = ApolloReactCommon.MutationResult<
  Types.AtualizarMunicipioResponsavelMutation
>
export type AtualizarMunicipioResponsavelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtualizarMunicipioResponsavelMutation,
  Types.AtualizarMunicipioResponsavelMutationVariables
>
export const ExcluirMunicipioResponsavelDocument = gql`
  mutation ExcluirMunicipioResponsavel($id: ID!) {
    excluirMunicipioResponsavel(id: $id)
  }
`
export type ExcluirMunicipioResponsavelMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirMunicipioResponsavelMutation,
  Types.ExcluirMunicipioResponsavelMutationVariables
>

/**
 * __useExcluirMunicipioResponsavelMutation__
 *
 * To run a mutation, you first call `useExcluirMunicipioResponsavelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirMunicipioResponsavelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirMunicipioResponsavelMutation, { data, loading, error }] = useExcluirMunicipioResponsavelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirMunicipioResponsavelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirMunicipioResponsavelMutation,
    Types.ExcluirMunicipioResponsavelMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirMunicipioResponsavelMutation,
    Types.ExcluirMunicipioResponsavelMutationVariables
  >(ExcluirMunicipioResponsavelDocument, baseOptions)
}
export type ExcluirMunicipioResponsavelMutationHookResult = ReturnType<typeof useExcluirMunicipioResponsavelMutation>
export type ExcluirMunicipioResponsavelMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirMunicipioResponsavelMutation
>
export type ExcluirMunicipioResponsavelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirMunicipioResponsavelMutation,
  Types.ExcluirMunicipioResponsavelMutationVariables
>
export const AlterarNumeroTentativasLoginDocument = gql`
  mutation AlterarNumeroTentativasLogin($tentativas: Int) {
    alterarNumeroTentativasLogin(tentativas: $tentativas) {
      tentativasLogin
    }
  }
`
export type AlterarNumeroTentativasLoginMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarNumeroTentativasLoginMutation,
  Types.AlterarNumeroTentativasLoginMutationVariables
>

/**
 * __useAlterarNumeroTentativasLoginMutation__
 *
 * To run a mutation, you first call `useAlterarNumeroTentativasLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarNumeroTentativasLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarNumeroTentativasLoginMutation, { data, loading, error }] = useAlterarNumeroTentativasLoginMutation({
 *   variables: {
 *      tentativas: // value for 'tentativas'
 *   },
 * });
 */
export function useAlterarNumeroTentativasLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarNumeroTentativasLoginMutation,
    Types.AlterarNumeroTentativasLoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarNumeroTentativasLoginMutation,
    Types.AlterarNumeroTentativasLoginMutationVariables
  >(AlterarNumeroTentativasLoginDocument, baseOptions)
}
export type AlterarNumeroTentativasLoginMutationHookResult = ReturnType<typeof useAlterarNumeroTentativasLoginMutation>
export type AlterarNumeroTentativasLoginMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarNumeroTentativasLoginMutation
>
export type AlterarNumeroTentativasLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarNumeroTentativasLoginMutation,
  Types.AlterarNumeroTentativasLoginMutationVariables
>
export const AlterarPeriodoInatividadeDocument = gql`
  mutation AlterarPeriodoInatividade($periodo: Int) {
    alterarPeriodoInatividade(periodo: $periodo) {
      periodoInatividade
    }
  }
`
export type AlterarPeriodoInatividadeMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarPeriodoInatividadeMutation,
  Types.AlterarPeriodoInatividadeMutationVariables
>

/**
 * __useAlterarPeriodoInatividadeMutation__
 *
 * To run a mutation, you first call `useAlterarPeriodoInatividadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarPeriodoInatividadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarPeriodoInatividadeMutation, { data, loading, error }] = useAlterarPeriodoInatividadeMutation({
 *   variables: {
 *      periodo: // value for 'periodo'
 *   },
 * });
 */
export function useAlterarPeriodoInatividadeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarPeriodoInatividadeMutation,
    Types.AlterarPeriodoInatividadeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarPeriodoInatividadeMutation,
    Types.AlterarPeriodoInatividadeMutationVariables
  >(AlterarPeriodoInatividadeDocument, baseOptions)
}
export type AlterarPeriodoInatividadeMutationHookResult = ReturnType<typeof useAlterarPeriodoInatividadeMutation>
export type AlterarPeriodoInatividadeMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarPeriodoInatividadeMutation
>
export type AlterarPeriodoInatividadeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarPeriodoInatividadeMutation,
  Types.AlterarPeriodoInatividadeMutationVariables
>
export const AlterarPeriodoRedefinicaoSenhaDocument = gql`
  mutation AlterarPeriodoRedefinicaoSenha($periodo: Int) {
    alterarPeriodoRedefinicaoSenha(periodo: $periodo) {
      redefinicaoSenha
    }
  }
`
export type AlterarPeriodoRedefinicaoSenhaMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarPeriodoRedefinicaoSenhaMutation,
  Types.AlterarPeriodoRedefinicaoSenhaMutationVariables
>

/**
 * __useAlterarPeriodoRedefinicaoSenhaMutation__
 *
 * To run a mutation, you first call `useAlterarPeriodoRedefinicaoSenhaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarPeriodoRedefinicaoSenhaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarPeriodoRedefinicaoSenhaMutation, { data, loading, error }] = useAlterarPeriodoRedefinicaoSenhaMutation({
 *   variables: {
 *      periodo: // value for 'periodo'
 *   },
 * });
 */
export function useAlterarPeriodoRedefinicaoSenhaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarPeriodoRedefinicaoSenhaMutation,
    Types.AlterarPeriodoRedefinicaoSenhaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarPeriodoRedefinicaoSenhaMutation,
    Types.AlterarPeriodoRedefinicaoSenhaMutationVariables
  >(AlterarPeriodoRedefinicaoSenhaDocument, baseOptions)
}
export type AlterarPeriodoRedefinicaoSenhaMutationHookResult = ReturnType<
  typeof useAlterarPeriodoRedefinicaoSenhaMutation
>
export type AlterarPeriodoRedefinicaoSenhaMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarPeriodoRedefinicaoSenhaMutation
>
export type AlterarPeriodoRedefinicaoSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarPeriodoRedefinicaoSenhaMutation,
  Types.AlterarPeriodoRedefinicaoSenhaMutationVariables
>
export const ForcarRedefinirSenhasDocument = gql`
  mutation ForcarRedefinirSenhas {
    forcarRedefinirSenhas {
      redefinicaoSenha
    }
  }
`
export type ForcarRedefinirSenhasMutationFn = ApolloReactCommon.MutationFunction<
  Types.ForcarRedefinirSenhasMutation,
  Types.ForcarRedefinirSenhasMutationVariables
>

/**
 * __useForcarRedefinirSenhasMutation__
 *
 * To run a mutation, you first call `useForcarRedefinirSenhasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForcarRedefinirSenhasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forcarRedefinirSenhasMutation, { data, loading, error }] = useForcarRedefinirSenhasMutation({
 *   variables: {
 *   },
 * });
 */
export function useForcarRedefinirSenhasMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ForcarRedefinirSenhasMutation,
    Types.ForcarRedefinirSenhasMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ForcarRedefinirSenhasMutation,
    Types.ForcarRedefinirSenhasMutationVariables
  >(ForcarRedefinirSenhasDocument, baseOptions)
}
export type ForcarRedefinirSenhasMutationHookResult = ReturnType<typeof useForcarRedefinirSenhasMutation>
export type ForcarRedefinirSenhasMutationResult = ApolloReactCommon.MutationResult<Types.ForcarRedefinirSenhasMutation>
export type ForcarRedefinirSenhasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ForcarRedefinirSenhasMutation,
  Types.ForcarRedefinirSenhasMutationVariables
>
export const SegurancaDocument = gql`
  query Seguranca {
    seguranca {
      periodoEmMesesParaRedefinir {
        redefinicaoSenha
      }
      periodoEmMinutosInatividade {
        periodoInatividade
      }
      loginNumeroTentativas {
        tentativasLogin
      }
    }
  }
`

/**
 * __useSegurancaQuery__
 *
 * To run a query within a React component, call `useSegurancaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegurancaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegurancaQuery({
 *   variables: {
 *   },
 * });
 */
export function useSegurancaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.SegurancaQuery, Types.SegurancaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.SegurancaQuery, Types.SegurancaQueryVariables>(SegurancaDocument, baseOptions)
}
export function useSegurancaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SegurancaQuery, Types.SegurancaQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.SegurancaQuery, Types.SegurancaQueryVariables>(
    SegurancaDocument,
    baseOptions
  )
}
export type SegurancaQueryHookResult = ReturnType<typeof useSegurancaQuery>
export type SegurancaLazyQueryHookResult = ReturnType<typeof useSegurancaLazyQuery>
export type SegurancaQueryResult = ApolloReactCommon.QueryResult<Types.SegurancaQuery, Types.SegurancaQueryVariables>
export const AlterarDadosServidorInstalacaoDocument = gql`
  mutation AlterarDadosServidorInstalacao($input: ConfiguracaoDadosServidorInput!) {
    alterarDadosServidor(input: $input) {
      nomeInstalacao
      linkInstalacao
    }
  }
`
export type AlterarDadosServidorInstalacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarDadosServidorInstalacaoMutation,
  Types.AlterarDadosServidorInstalacaoMutationVariables
>

/**
 * __useAlterarDadosServidorInstalacaoMutation__
 *
 * To run a mutation, you first call `useAlterarDadosServidorInstalacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarDadosServidorInstalacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarDadosServidorInstalacaoMutation, { data, loading, error }] = useAlterarDadosServidorInstalacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarDadosServidorInstalacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarDadosServidorInstalacaoMutation,
    Types.AlterarDadosServidorInstalacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarDadosServidorInstalacaoMutation,
    Types.AlterarDadosServidorInstalacaoMutationVariables
  >(AlterarDadosServidorInstalacaoDocument, baseOptions)
}
export type AlterarDadosServidorInstalacaoMutationHookResult = ReturnType<
  typeof useAlterarDadosServidorInstalacaoMutation
>
export type AlterarDadosServidorInstalacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarDadosServidorInstalacaoMutation
>
export type AlterarDadosServidorInstalacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarDadosServidorInstalacaoMutation,
  Types.AlterarDadosServidorInstalacaoMutationVariables
>
export const AlterarServidorSmtpDocument = gql`
  mutation AlterarServidorSMTP($input: ConfiguracaoSMTPInput!) {
    alterarServidorSMTP(input: $input) {
      email
      senha
      usuario
      endereco
      porta
    }
  }
`
export type AlterarServidorSmtpMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarServidorSmtpMutation,
  Types.AlterarServidorSmtpMutationVariables
>

/**
 * __useAlterarServidorSmtpMutation__
 *
 * To run a mutation, you first call `useAlterarServidorSmtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarServidorSmtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarServidorSmtpMutation, { data, loading, error }] = useAlterarServidorSmtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarServidorSmtpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarServidorSmtpMutation,
    Types.AlterarServidorSmtpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarServidorSmtpMutation, Types.AlterarServidorSmtpMutationVariables>(
    AlterarServidorSmtpDocument,
    baseOptions
  )
}
export type AlterarServidorSmtpMutationHookResult = ReturnType<typeof useAlterarServidorSmtpMutation>
export type AlterarServidorSmtpMutationResult = ApolloReactCommon.MutationResult<Types.AlterarServidorSmtpMutation>
export type AlterarServidorSmtpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarServidorSmtpMutation,
  Types.AlterarServidorSmtpMutationVariables
>
export const DesabilitarConexaoSmtpDocument = gql`
  mutation DesabilitarConexaoSMTP {
    desabilitarConexaoSMTP
  }
`
export type DesabilitarConexaoSmtpMutationFn = ApolloReactCommon.MutationFunction<
  Types.DesabilitarConexaoSmtpMutation,
  Types.DesabilitarConexaoSmtpMutationVariables
>

/**
 * __useDesabilitarConexaoSmtpMutation__
 *
 * To run a mutation, you first call `useDesabilitarConexaoSmtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesabilitarConexaoSmtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desabilitarConexaoSmtpMutation, { data, loading, error }] = useDesabilitarConexaoSmtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useDesabilitarConexaoSmtpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DesabilitarConexaoSmtpMutation,
    Types.DesabilitarConexaoSmtpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.DesabilitarConexaoSmtpMutation,
    Types.DesabilitarConexaoSmtpMutationVariables
  >(DesabilitarConexaoSmtpDocument, baseOptions)
}
export type DesabilitarConexaoSmtpMutationHookResult = ReturnType<typeof useDesabilitarConexaoSmtpMutation>
export type DesabilitarConexaoSmtpMutationResult = ApolloReactCommon.MutationResult<
  Types.DesabilitarConexaoSmtpMutation
>
export type DesabilitarConexaoSmtpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DesabilitarConexaoSmtpMutation,
  Types.DesabilitarConexaoSmtpMutationVariables
>
export const DadosServidoresDocument = gql`
  query DadosServidores {
    servidores {
      internet {
        habilitado
      }
      dadosServidor {
        nomeInstalacao
        linkInstalacao
      }
      servidorSMTP {
        email
        senha
        usuario
        endereco
        porta
        ativo
        dataRegistro
      }
    }
  }
`

/**
 * __useDadosServidoresQuery__
 *
 * To run a query within a React component, call `useDadosServidoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useDadosServidoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDadosServidoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useDadosServidoresQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>(
    DadosServidoresDocument,
    baseOptions
  )
}
export function useDadosServidoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>(
    DadosServidoresDocument,
    baseOptions
  )
}
export type DadosServidoresQueryHookResult = ReturnType<typeof useDadosServidoresQuery>
export type DadosServidoresLazyQueryHookResult = ReturnType<typeof useDadosServidoresLazyQuery>
export type DadosServidoresQueryResult = ApolloReactCommon.QueryResult<
  Types.DadosServidoresQuery,
  Types.DadosServidoresQueryVariables
>
export const ConfiguracaoInternetDocument = gql`
  query ConfiguracaoInternet {
    conexao {
      internet {
        habilitado
      }
      agendaOnline {
        ativa
      }
    }
  }
`

/**
 * __useConfiguracaoInternetQuery__
 *
 * To run a query within a React component, call `useConfiguracaoInternetQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoInternetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoInternetQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoInternetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoInternetQuery,
    Types.ConfiguracaoInternetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoInternetQuery, Types.ConfiguracaoInternetQueryVariables>(
    ConfiguracaoInternetDocument,
    baseOptions
  )
}
export function useConfiguracaoInternetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoInternetQuery,
    Types.ConfiguracaoInternetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracaoInternetQuery, Types.ConfiguracaoInternetQueryVariables>(
    ConfiguracaoInternetDocument,
    baseOptions
  )
}
export type ConfiguracaoInternetQueryHookResult = ReturnType<typeof useConfiguracaoInternetQuery>
export type ConfiguracaoInternetLazyQueryHookResult = ReturnType<typeof useConfiguracaoInternetLazyQuery>
export type ConfiguracaoInternetQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoInternetQuery,
  Types.ConfiguracaoInternetQueryVariables
>
export const PedidoEnvioBaseAtivoDocument = gql`
  query PedidoEnvioBaseAtivo {
    pedidoEnvioBaseAtivo {
      id
      nomeInstalacao
      chaveAutenticacao
      linkInstalacao
      status
      loginAtivo
    }
  }
`

/**
 * __usePedidoEnvioBaseAtivoQuery__
 *
 * To run a query within a React component, call `usePedidoEnvioBaseAtivoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePedidoEnvioBaseAtivoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePedidoEnvioBaseAtivoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePedidoEnvioBaseAtivoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.PedidoEnvioBaseAtivoQuery,
    Types.PedidoEnvioBaseAtivoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.PedidoEnvioBaseAtivoQuery, Types.PedidoEnvioBaseAtivoQueryVariables>(
    PedidoEnvioBaseAtivoDocument,
    baseOptions
  )
}
export function usePedidoEnvioBaseAtivoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PedidoEnvioBaseAtivoQuery,
    Types.PedidoEnvioBaseAtivoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.PedidoEnvioBaseAtivoQuery, Types.PedidoEnvioBaseAtivoQueryVariables>(
    PedidoEnvioBaseAtivoDocument,
    baseOptions
  )
}
export type PedidoEnvioBaseAtivoQueryHookResult = ReturnType<typeof usePedidoEnvioBaseAtivoQuery>
export type PedidoEnvioBaseAtivoLazyQueryHookResult = ReturnType<typeof usePedidoEnvioBaseAtivoLazyQuery>
export type PedidoEnvioBaseAtivoQueryResult = ApolloReactCommon.QueryResult<
  Types.PedidoEnvioBaseAtivoQuery,
  Types.PedidoEnvioBaseAtivoQueryVariables
>
export const PedidosEnvioBaseDocument = gql`
  query PedidosEnvioBase {
    pedidosEnvioBase {
      id
      linkInstalacao
      status
      dataSolicitacao
      dataFimUnificacao
      loginAtivo
    }
  }
`

/**
 * __usePedidosEnvioBaseQuery__
 *
 * To run a query within a React component, call `usePedidosEnvioBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePedidosEnvioBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePedidosEnvioBaseQuery({
 *   variables: {
 *   },
 * });
 */
export function usePedidosEnvioBaseQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PedidosEnvioBaseQuery, Types.PedidosEnvioBaseQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PedidosEnvioBaseQuery, Types.PedidosEnvioBaseQueryVariables>(
    PedidosEnvioBaseDocument,
    baseOptions
  )
}
export function usePedidosEnvioBaseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PedidosEnvioBaseQuery, Types.PedidosEnvioBaseQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PedidosEnvioBaseQuery, Types.PedidosEnvioBaseQueryVariables>(
    PedidosEnvioBaseDocument,
    baseOptions
  )
}
export type PedidosEnvioBaseQueryHookResult = ReturnType<typeof usePedidosEnvioBaseQuery>
export type PedidosEnvioBaseLazyQueryHookResult = ReturnType<typeof usePedidosEnvioBaseLazyQuery>
export type PedidosEnvioBaseQueryResult = ApolloReactCommon.QueryResult<
  Types.PedidosEnvioBaseQuery,
  Types.PedidosEnvioBaseQueryVariables
>
export const UnificacoesBaseDocument = gql`
  query UnificacoesBase {
    unificacoesBase {
      id
      nomeInstalacao
      status
      dataSolicitacao
      dataFimUnificacao
    }
  }
`

/**
 * __useUnificacoesBaseQuery__
 *
 * To run a query within a React component, call `useUnificacoesBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnificacoesBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnificacoesBaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnificacoesBaseQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UnificacoesBaseQuery, Types.UnificacoesBaseQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.UnificacoesBaseQuery, Types.UnificacoesBaseQueryVariables>(
    UnificacoesBaseDocument,
    baseOptions
  )
}
export function useUnificacoesBaseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.UnificacoesBaseQuery, Types.UnificacoesBaseQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.UnificacoesBaseQuery, Types.UnificacoesBaseQueryVariables>(
    UnificacoesBaseDocument,
    baseOptions
  )
}
export type UnificacoesBaseQueryHookResult = ReturnType<typeof useUnificacoesBaseQuery>
export type UnificacoesBaseLazyQueryHookResult = ReturnType<typeof useUnificacoesBaseLazyQuery>
export type UnificacoesBaseQueryResult = ApolloReactCommon.QueryResult<
  Types.UnificacoesBaseQuery,
  Types.UnificacoesBaseQueryVariables
>
export const AceitarSolicitacaoDocument = gql`
  mutation aceitarSolicitacao($id: ID!) {
    aceitarSolicitacao(id: $id)
  }
`
export type AceitarSolicitacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AceitarSolicitacaoMutation,
  Types.AceitarSolicitacaoMutationVariables
>

/**
 * __useAceitarSolicitacaoMutation__
 *
 * To run a mutation, you first call `useAceitarSolicitacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAceitarSolicitacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aceitarSolicitacaoMutation, { data, loading, error }] = useAceitarSolicitacaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAceitarSolicitacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AceitarSolicitacaoMutation,
    Types.AceitarSolicitacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AceitarSolicitacaoMutation, Types.AceitarSolicitacaoMutationVariables>(
    AceitarSolicitacaoDocument,
    baseOptions
  )
}
export type AceitarSolicitacaoMutationHookResult = ReturnType<typeof useAceitarSolicitacaoMutation>
export type AceitarSolicitacaoMutationResult = ApolloReactCommon.MutationResult<Types.AceitarSolicitacaoMutation>
export type AceitarSolicitacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AceitarSolicitacaoMutation,
  Types.AceitarSolicitacaoMutationVariables
>
export const CadastrarEnderecoEnvioDocument = gql`
  mutation cadastrarEnderecoEnvio($input: InfoCadastroEnvioInput!) {
    cadastrarEnderecoEnvio(input: $input) {
      pedidoEnvioBase {
        id
      }
      errorLogin
      errorImportacaoCnes {
        instalacaoCentral
        instalacaoLocal
      }
    }
  }
`
export type CadastrarEnderecoEnvioMutationFn = ApolloReactCommon.MutationFunction<
  Types.CadastrarEnderecoEnvioMutation,
  Types.CadastrarEnderecoEnvioMutationVariables
>

/**
 * __useCadastrarEnderecoEnvioMutation__
 *
 * To run a mutation, you first call `useCadastrarEnderecoEnvioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastrarEnderecoEnvioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastrarEnderecoEnvioMutation, { data, loading, error }] = useCadastrarEnderecoEnvioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCadastrarEnderecoEnvioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CadastrarEnderecoEnvioMutation,
    Types.CadastrarEnderecoEnvioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.CadastrarEnderecoEnvioMutation,
    Types.CadastrarEnderecoEnvioMutationVariables
  >(CadastrarEnderecoEnvioDocument, baseOptions)
}
export type CadastrarEnderecoEnvioMutationHookResult = ReturnType<typeof useCadastrarEnderecoEnvioMutation>
export type CadastrarEnderecoEnvioMutationResult = ApolloReactCommon.MutationResult<
  Types.CadastrarEnderecoEnvioMutation
>
export type CadastrarEnderecoEnvioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CadastrarEnderecoEnvioMutation,
  Types.CadastrarEnderecoEnvioMutationVariables
>
export const CancelarSolicitacaoEnvioDocument = gql`
  mutation cancelarSolicitacaoEnvio($input: CancelarSolicitacaoEnvioInput!) {
    cancelarSolicitacaoEnvio(input: $input)
  }
`
export type CancelarSolicitacaoEnvioMutationFn = ApolloReactCommon.MutationFunction<
  Types.CancelarSolicitacaoEnvioMutation,
  Types.CancelarSolicitacaoEnvioMutationVariables
>

/**
 * __useCancelarSolicitacaoEnvioMutation__
 *
 * To run a mutation, you first call `useCancelarSolicitacaoEnvioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelarSolicitacaoEnvioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelarSolicitacaoEnvioMutation, { data, loading, error }] = useCancelarSolicitacaoEnvioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelarSolicitacaoEnvioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CancelarSolicitacaoEnvioMutation,
    Types.CancelarSolicitacaoEnvioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.CancelarSolicitacaoEnvioMutation,
    Types.CancelarSolicitacaoEnvioMutationVariables
  >(CancelarSolicitacaoEnvioDocument, baseOptions)
}
export type CancelarSolicitacaoEnvioMutationHookResult = ReturnType<typeof useCancelarSolicitacaoEnvioMutation>
export type CancelarSolicitacaoEnvioMutationResult = ApolloReactCommon.MutationResult<
  Types.CancelarSolicitacaoEnvioMutation
>
export type CancelarSolicitacaoEnvioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CancelarSolicitacaoEnvioMutation,
  Types.CancelarSolicitacaoEnvioMutationVariables
>
export const HasImpressaoDocumentosDuplicadosDocument = gql`
  query hasImpressaoDocumentosDuplicados($id: ID!) {
    hasDocumentosDuplicados(unificacaoBaseId: $id)
  }
`

/**
 * __useHasImpressaoDocumentosDuplicadosQuery__
 *
 * To run a query within a React component, call `useHasImpressaoDocumentosDuplicadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasImpressaoDocumentosDuplicadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasImpressaoDocumentosDuplicadosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHasImpressaoDocumentosDuplicadosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HasImpressaoDocumentosDuplicadosQuery,
    Types.HasImpressaoDocumentosDuplicadosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HasImpressaoDocumentosDuplicadosQuery,
    Types.HasImpressaoDocumentosDuplicadosQueryVariables
  >(HasImpressaoDocumentosDuplicadosDocument, baseOptions)
}
export function useHasImpressaoDocumentosDuplicadosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HasImpressaoDocumentosDuplicadosQuery,
    Types.HasImpressaoDocumentosDuplicadosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HasImpressaoDocumentosDuplicadosQuery,
    Types.HasImpressaoDocumentosDuplicadosQueryVariables
  >(HasImpressaoDocumentosDuplicadosDocument, baseOptions)
}
export type HasImpressaoDocumentosDuplicadosQueryHookResult = ReturnType<
  typeof useHasImpressaoDocumentosDuplicadosQuery
>
export type HasImpressaoDocumentosDuplicadosLazyQueryHookResult = ReturnType<
  typeof useHasImpressaoDocumentosDuplicadosLazyQuery
>
export type HasImpressaoDocumentosDuplicadosQueryResult = ApolloReactCommon.QueryResult<
  Types.HasImpressaoDocumentosDuplicadosQuery,
  Types.HasImpressaoDocumentosDuplicadosQueryVariables
>
export const InicarUnificacaoBaseDocument = gql`
  mutation inicarUnificacaoBase {
    iniciarUnificacaoBase
  }
`
export type InicarUnificacaoBaseMutationFn = ApolloReactCommon.MutationFunction<
  Types.InicarUnificacaoBaseMutation,
  Types.InicarUnificacaoBaseMutationVariables
>

/**
 * __useInicarUnificacaoBaseMutation__
 *
 * To run a mutation, you first call `useInicarUnificacaoBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInicarUnificacaoBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inicarUnificacaoBaseMutation, { data, loading, error }] = useInicarUnificacaoBaseMutation({
 *   variables: {
 *   },
 * });
 */
export function useInicarUnificacaoBaseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InicarUnificacaoBaseMutation,
    Types.InicarUnificacaoBaseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.InicarUnificacaoBaseMutation, Types.InicarUnificacaoBaseMutationVariables>(
    InicarUnificacaoBaseDocument,
    baseOptions
  )
}
export type InicarUnificacaoBaseMutationHookResult = ReturnType<typeof useInicarUnificacaoBaseMutation>
export type InicarUnificacaoBaseMutationResult = ApolloReactCommon.MutationResult<Types.InicarUnificacaoBaseMutation>
export type InicarUnificacaoBaseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InicarUnificacaoBaseMutation,
  Types.InicarUnificacaoBaseMutationVariables
>
export const NegarSolicitacaoDocument = gql`
  mutation negarSolicitacao($id: ID!) {
    negarSolicitacao(id: $id)
  }
`
export type NegarSolicitacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.NegarSolicitacaoMutation,
  Types.NegarSolicitacaoMutationVariables
>

/**
 * __useNegarSolicitacaoMutation__
 *
 * To run a mutation, you first call `useNegarSolicitacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNegarSolicitacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [negarSolicitacaoMutation, { data, loading, error }] = useNegarSolicitacaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNegarSolicitacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.NegarSolicitacaoMutation,
    Types.NegarSolicitacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.NegarSolicitacaoMutation, Types.NegarSolicitacaoMutationVariables>(
    NegarSolicitacaoDocument,
    baseOptions
  )
}
export type NegarSolicitacaoMutationHookResult = ReturnType<typeof useNegarSolicitacaoMutation>
export type NegarSolicitacaoMutationResult = ApolloReactCommon.MutationResult<Types.NegarSolicitacaoMutation>
export type NegarSolicitacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.NegarSolicitacaoMutation,
  Types.NegarSolicitacaoMutationVariables
>
export const RefazerLoginDocument = gql`
  mutation refazerLogin($senha: String!) {
    refazLoginExpirado(senha: $senha)
  }
`
export type RefazerLoginMutationFn = ApolloReactCommon.MutationFunction<
  Types.RefazerLoginMutation,
  Types.RefazerLoginMutationVariables
>

/**
 * __useRefazerLoginMutation__
 *
 * To run a mutation, you first call `useRefazerLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefazerLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refazerLoginMutation, { data, loading, error }] = useRefazerLoginMutation({
 *   variables: {
 *      senha: // value for 'senha'
 *   },
 * });
 */
export function useRefazerLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.RefazerLoginMutation, Types.RefazerLoginMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.RefazerLoginMutation, Types.RefazerLoginMutationVariables>(
    RefazerLoginDocument,
    baseOptions
  )
}
export type RefazerLoginMutationHookResult = ReturnType<typeof useRefazerLoginMutation>
export type RefazerLoginMutationResult = ApolloReactCommon.MutationResult<Types.RefazerLoginMutation>
export type RefazerLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RefazerLoginMutation,
  Types.RefazerLoginMutationVariables
>
export const CadsusHabilitadoInstalacaoDocument = gql`
  query CadsusHabilitadoInstalacao {
    conexao {
      cadsus {
        id
        habilitado
      }
    }
  }
`

/**
 * __useCadsusHabilitadoInstalacaoQuery__
 *
 * To run a query within a React component, call `useCadsusHabilitadoInstalacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCadsusHabilitadoInstalacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCadsusHabilitadoInstalacaoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCadsusHabilitadoInstalacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CadsusHabilitadoInstalacaoQuery,
    Types.CadsusHabilitadoInstalacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CadsusHabilitadoInstalacaoQuery,
    Types.CadsusHabilitadoInstalacaoQueryVariables
  >(CadsusHabilitadoInstalacaoDocument, baseOptions)
}
export function useCadsusHabilitadoInstalacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CadsusHabilitadoInstalacaoQuery,
    Types.CadsusHabilitadoInstalacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CadsusHabilitadoInstalacaoQuery,
    Types.CadsusHabilitadoInstalacaoQueryVariables
  >(CadsusHabilitadoInstalacaoDocument, baseOptions)
}
export type CadsusHabilitadoInstalacaoQueryHookResult = ReturnType<typeof useCadsusHabilitadoInstalacaoQuery>
export type CadsusHabilitadoInstalacaoLazyQueryHookResult = ReturnType<typeof useCadsusHabilitadoInstalacaoLazyQuery>
export type CadsusHabilitadoInstalacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.CadsusHabilitadoInstalacaoQuery,
  Types.CadsusHabilitadoInstalacaoQueryVariables
>
export const CertificadoSelectDocument = gql`
  query CertificadoSelect($input: CertificadoQueryInput!) {
    certificados(input: $input) {
      content {
        path
        nome
      }
    }
  }
`

/**
 * __useCertificadoSelectQuery__
 *
 * To run a query within a React component, call `useCertificadoSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificadoSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificadoSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCertificadoSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CertificadoSelectQuery, Types.CertificadoSelectQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CertificadoSelectQuery, Types.CertificadoSelectQueryVariables>(
    CertificadoSelectDocument,
    baseOptions
  )
}
export function useCertificadoSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CertificadoSelectQuery,
    Types.CertificadoSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CertificadoSelectQuery, Types.CertificadoSelectQueryVariables>(
    CertificadoSelectDocument,
    baseOptions
  )
}
export type CertificadoSelectQueryHookResult = ReturnType<typeof useCertificadoSelectQuery>
export type CertificadoSelectLazyQueryHookResult = ReturnType<typeof useCertificadoSelectLazyQuery>
export type CertificadoSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.CertificadoSelectQuery,
  Types.CertificadoSelectQueryVariables
>
export const SalvarConfiguracaoCertificadoDocument = gql`
  mutation SalvarConfiguracaoCertificado($configuracaoCertificadoInput: ConfiguracaoCertificadoInput!) {
    salvarConfiguracaoCertificado(configuracaoCertificadoInput: $configuracaoCertificadoInput) {
      id
      dataInclusao
      admMunicipal {
        profissional {
          nome
        }
      }
      dataHabilitacao
      dataValidadeCertificado
      rndsHabilitado
      cadsusHabilitado
      error
    }
  }
`
export type SalvarConfiguracaoCertificadoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarConfiguracaoCertificadoMutation,
  Types.SalvarConfiguracaoCertificadoMutationVariables
>

/**
 * __useSalvarConfiguracaoCertificadoMutation__
 *
 * To run a mutation, you first call `useSalvarConfiguracaoCertificadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarConfiguracaoCertificadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarConfiguracaoCertificadoMutation, { data, loading, error }] = useSalvarConfiguracaoCertificadoMutation({
 *   variables: {
 *      configuracaoCertificadoInput: // value for 'configuracaoCertificadoInput'
 *   },
 * });
 */
export function useSalvarConfiguracaoCertificadoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarConfiguracaoCertificadoMutation,
    Types.SalvarConfiguracaoCertificadoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarConfiguracaoCertificadoMutation,
    Types.SalvarConfiguracaoCertificadoMutationVariables
  >(SalvarConfiguracaoCertificadoDocument, baseOptions)
}
export type SalvarConfiguracaoCertificadoMutationHookResult = ReturnType<
  typeof useSalvarConfiguracaoCertificadoMutation
>
export type SalvarConfiguracaoCertificadoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarConfiguracaoCertificadoMutation
>
export type SalvarConfiguracaoCertificadoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarConfiguracaoCertificadoMutation,
  Types.SalvarConfiguracaoCertificadoMutationVariables
>
export const ConfiguracaoCertificadoHabilitadoDocument = gql`
  query ConfiguracaoCertificadoHabilitado {
    configuracaoCertificadoHabilitado {
      id
      dataInclusao
      admMunicipal {
        profissional {
          nome
        }
      }
      dataHabilitacao
      dataValidadeCertificado
      rndsHabilitado
      cadsusHabilitado
      error
    }
    conexao {
      internet {
        habilitado
      }
    }
  }
`

/**
 * __useConfiguracaoCertificadoHabilitadoQuery__
 *
 * To run a query within a React component, call `useConfiguracaoCertificadoHabilitadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoCertificadoHabilitadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoCertificadoHabilitadoQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoCertificadoHabilitadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoCertificadoHabilitadoQuery,
    Types.ConfiguracaoCertificadoHabilitadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ConfiguracaoCertificadoHabilitadoQuery,
    Types.ConfiguracaoCertificadoHabilitadoQueryVariables
  >(ConfiguracaoCertificadoHabilitadoDocument, baseOptions)
}
export function useConfiguracaoCertificadoHabilitadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoCertificadoHabilitadoQuery,
    Types.ConfiguracaoCertificadoHabilitadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoCertificadoHabilitadoQuery,
    Types.ConfiguracaoCertificadoHabilitadoQueryVariables
  >(ConfiguracaoCertificadoHabilitadoDocument, baseOptions)
}
export type ConfiguracaoCertificadoHabilitadoQueryHookResult = ReturnType<
  typeof useConfiguracaoCertificadoHabilitadoQuery
>
export type ConfiguracaoCertificadoHabilitadoLazyQueryHookResult = ReturnType<
  typeof useConfiguracaoCertificadoHabilitadoLazyQuery
>
export type ConfiguracaoCertificadoHabilitadoQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoCertificadoHabilitadoQuery,
  Types.ConfiguracaoCertificadoHabilitadoQueryVariables
>
export const ContraChaveAtivacaoMunicipioDocument = gql`
  query ContraChaveAtivacaoMunicipio {
    contraChaveAtivacaoMunicipio
  }
`

/**
 * __useContraChaveAtivacaoMunicipioQuery__
 *
 * To run a query within a React component, call `useContraChaveAtivacaoMunicipioQuery` and pass it any options that fit your needs.
 * When your component renders, `useContraChaveAtivacaoMunicipioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContraChaveAtivacaoMunicipioQuery({
 *   variables: {
 *   },
 * });
 */
export function useContraChaveAtivacaoMunicipioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ContraChaveAtivacaoMunicipioQuery,
    Types.ContraChaveAtivacaoMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ContraChaveAtivacaoMunicipioQuery,
    Types.ContraChaveAtivacaoMunicipioQueryVariables
  >(ContraChaveAtivacaoMunicipioDocument, baseOptions)
}
export function useContraChaveAtivacaoMunicipioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ContraChaveAtivacaoMunicipioQuery,
    Types.ContraChaveAtivacaoMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ContraChaveAtivacaoMunicipioQuery,
    Types.ContraChaveAtivacaoMunicipioQueryVariables
  >(ContraChaveAtivacaoMunicipioDocument, baseOptions)
}
export type ContraChaveAtivacaoMunicipioQueryHookResult = ReturnType<typeof useContraChaveAtivacaoMunicipioQuery>
export type ContraChaveAtivacaoMunicipioLazyQueryHookResult = ReturnType<
  typeof useContraChaveAtivacaoMunicipioLazyQuery
>
export type ContraChaveAtivacaoMunicipioQueryResult = ApolloReactCommon.QueryResult<
  Types.ContraChaveAtivacaoMunicipioQuery,
  Types.ContraChaveAtivacaoMunicipioQueryVariables
>
export const ExcluirConfiguracaoCertificadoDocument = gql`
  mutation ExcluirConfiguracaoCertificado($configuracaoCertificadoId: ID!) {
    excluirConfiguracaoCertificado(configuracaoCertificadoId: $configuracaoCertificadoId)
  }
`
export type ExcluirConfiguracaoCertificadoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirConfiguracaoCertificadoMutation,
  Types.ExcluirConfiguracaoCertificadoMutationVariables
>

/**
 * __useExcluirConfiguracaoCertificadoMutation__
 *
 * To run a mutation, you first call `useExcluirConfiguracaoCertificadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirConfiguracaoCertificadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirConfiguracaoCertificadoMutation, { data, loading, error }] = useExcluirConfiguracaoCertificadoMutation({
 *   variables: {
 *      configuracaoCertificadoId: // value for 'configuracaoCertificadoId'
 *   },
 * });
 */
export function useExcluirConfiguracaoCertificadoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirConfiguracaoCertificadoMutation,
    Types.ExcluirConfiguracaoCertificadoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirConfiguracaoCertificadoMutation,
    Types.ExcluirConfiguracaoCertificadoMutationVariables
  >(ExcluirConfiguracaoCertificadoDocument, baseOptions)
}
export type ExcluirConfiguracaoCertificadoMutationHookResult = ReturnType<
  typeof useExcluirConfiguracaoCertificadoMutation
>
export type ExcluirConfiguracaoCertificadoMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirConfiguracaoCertificadoMutation
>
export type ExcluirConfiguracaoCertificadoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirConfiguracaoCertificadoMutation,
  Types.ExcluirConfiguracaoCertificadoMutationVariables
>
export const ValidarConfiguracaoCertificadoDocument = gql`
  mutation ValidarConfiguracaoCertificado($configuracaoCertificadoInput: ConfiguracaoCertificadoInput!) {
    validarConfiguracaoCertificado(configuracaoCertificadoInput: $configuracaoCertificadoInput)
  }
`
export type ValidarConfiguracaoCertificadoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ValidarConfiguracaoCertificadoMutation,
  Types.ValidarConfiguracaoCertificadoMutationVariables
>

/**
 * __useValidarConfiguracaoCertificadoMutation__
 *
 * To run a mutation, you first call `useValidarConfiguracaoCertificadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidarConfiguracaoCertificadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validarConfiguracaoCertificadoMutation, { data, loading, error }] = useValidarConfiguracaoCertificadoMutation({
 *   variables: {
 *      configuracaoCertificadoInput: // value for 'configuracaoCertificadoInput'
 *   },
 * });
 */
export function useValidarConfiguracaoCertificadoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ValidarConfiguracaoCertificadoMutation,
    Types.ValidarConfiguracaoCertificadoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ValidarConfiguracaoCertificadoMutation,
    Types.ValidarConfiguracaoCertificadoMutationVariables
  >(ValidarConfiguracaoCertificadoDocument, baseOptions)
}
export type ValidarConfiguracaoCertificadoMutationHookResult = ReturnType<
  typeof useValidarConfiguracaoCertificadoMutation
>
export type ValidarConfiguracaoCertificadoMutationResult = ApolloReactCommon.MutationResult<
  Types.ValidarConfiguracaoCertificadoMutation
>
export type ValidarConfiguracaoCertificadoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ValidarConfiguracaoCertificadoMutation,
  Types.ValidarConfiguracaoCertificadoMutationVariables
>
export const ConfiguracaoAgendaMunicipioDocument = gql`
  query ConfiguracaoAgendaMunicipio {
    duracaoAtendimentoPadraoMunicipio {
      duracaoAtendimentoPadraoMunicipio
    }
    configuracaoHorariosMunicipio {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`

/**
 * __useConfiguracaoAgendaMunicipioQuery__
 *
 * To run a query within a React component, call `useConfiguracaoAgendaMunicipioQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoAgendaMunicipioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoAgendaMunicipioQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoAgendaMunicipioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >(ConfiguracaoAgendaMunicipioDocument, baseOptions)
}
export function useConfiguracaoAgendaMunicipioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >(ConfiguracaoAgendaMunicipioDocument, baseOptions)
}
export type ConfiguracaoAgendaMunicipioQueryHookResult = ReturnType<typeof useConfiguracaoAgendaMunicipioQuery>
export type ConfiguracaoAgendaMunicipioLazyQueryHookResult = ReturnType<typeof useConfiguracaoAgendaMunicipioLazyQuery>
export type ConfiguracaoAgendaMunicipioQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoAgendaMunicipioQuery,
  Types.ConfiguracaoAgendaMunicipioQueryVariables
>
export const SalvarConfiguracaoHorariosMunicipioDocument = gql`
  mutation SalvarConfiguracaoHorariosMunicipio($configuracaoAgendaMunicipalInput: ConfiguracaoAgendaMunicipalInput) {
    salvarConfiguracaoHorariosMunicipio(configuracaoAgendaMunicipalInput: $configuracaoAgendaMunicipalInput) {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`
export type SalvarConfiguracaoHorariosMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarConfiguracaoHorariosMunicipioMutation,
  Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
>

/**
 * __useSalvarConfiguracaoHorariosMunicipioMutation__
 *
 * To run a mutation, you first call `useSalvarConfiguracaoHorariosMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarConfiguracaoHorariosMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarConfiguracaoHorariosMunicipioMutation, { data, loading, error }] = useSalvarConfiguracaoHorariosMunicipioMutation({
 *   variables: {
 *      configuracaoAgendaMunicipalInput: // value for 'configuracaoAgendaMunicipalInput'
 *   },
 * });
 */
export function useSalvarConfiguracaoHorariosMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarConfiguracaoHorariosMunicipioMutation,
    Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarConfiguracaoHorariosMunicipioMutation,
    Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
  >(SalvarConfiguracaoHorariosMunicipioDocument, baseOptions)
}
export type SalvarConfiguracaoHorariosMunicipioMutationHookResult = ReturnType<
  typeof useSalvarConfiguracaoHorariosMunicipioMutation
>
export type SalvarConfiguracaoHorariosMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarConfiguracaoHorariosMunicipioMutation
>
export type SalvarConfiguracaoHorariosMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarConfiguracaoHorariosMunicipioMutation,
  Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
>
export const SalvarConfiguracaoDuracaoAtendimentoMunicipioDocument = gql`
  mutation SalvarConfiguracaoDuracaoAtendimentoMunicipio(
    $duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput!
  ) {
    alterarDuracaoPadraoAtendimento(duracaoAtendimentoPadraoMunicipioInput: $duracaoAtendimentoPadraoMunicipioInput) {
      duracaoAtendimentoPadraoMunicipio
    }
  }
`
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
>

/**
 * __useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation__
 *
 * To run a mutation, you first call `useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarConfiguracaoDuracaoAtendimentoMunicipioMutation, { data, loading, error }] = useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation({
 *   variables: {
 *      duracaoAtendimentoPadraoMunicipioInput: // value for 'duracaoAtendimentoPadraoMunicipioInput'
 *   },
 * });
 */
export function useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
  >(SalvarConfiguracaoDuracaoAtendimentoMunicipioDocument, baseOptions)
}
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationHookResult = ReturnType<
  typeof useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation
>
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation
>
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
>
export const ExcluirGrupoExameDocument = gql`
  mutation ExcluirGrupoExame($id: ID!) {
    excluirGrupoExame(id: $id)
  }
`
export type ExcluirGrupoExameMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirGrupoExameMutation,
  Types.ExcluirGrupoExameMutationVariables
>

/**
 * __useExcluirGrupoExameMutation__
 *
 * To run a mutation, you first call `useExcluirGrupoExameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirGrupoExameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirGrupoExameMutation, { data, loading, error }] = useExcluirGrupoExameMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirGrupoExameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirGrupoExameMutation,
    Types.ExcluirGrupoExameMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ExcluirGrupoExameMutation, Types.ExcluirGrupoExameMutationVariables>(
    ExcluirGrupoExameDocument,
    baseOptions
  )
}
export type ExcluirGrupoExameMutationHookResult = ReturnType<typeof useExcluirGrupoExameMutation>
export type ExcluirGrupoExameMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirGrupoExameMutation>
export type ExcluirGrupoExameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirGrupoExameMutation,
  Types.ExcluirGrupoExameMutationVariables
>
export const GrupoExamesDocument = gql`
  query GrupoExames($id: ID!) {
    grupoExame(id: $id) {
      id
      nome
      procedimentos {
        ...Procedimento
      }
    }
  }
  ${ProcedimentoFragmentDoc}
`

/**
 * __useGrupoExamesQuery__
 *
 * To run a query within a React component, call `useGrupoExamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoExamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoExamesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGrupoExamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>(
    GrupoExamesDocument,
    baseOptions
  )
}
export function useGrupoExamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>(
    GrupoExamesDocument,
    baseOptions
  )
}
export type GrupoExamesQueryHookResult = ReturnType<typeof useGrupoExamesQuery>
export type GrupoExamesLazyQueryHookResult = ReturnType<typeof useGrupoExamesLazyQuery>
export type GrupoExamesQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoExamesQuery,
  Types.GrupoExamesQueryVariables
>
export const SalvarGrupoExameDocument = gql`
  mutation SalvarGrupoExame($input: GrupoExameInput!) {
    salvarGrupoExame(input: $input) {
      id
      nome
      procedimentos {
        ...Procedimento
      }
    }
  }
  ${ProcedimentoFragmentDoc}
`
export type SalvarGrupoExameMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarGrupoExameMutation,
  Types.SalvarGrupoExameMutationVariables
>

/**
 * __useSalvarGrupoExameMutation__
 *
 * To run a mutation, you first call `useSalvarGrupoExameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarGrupoExameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarGrupoExameMutation, { data, loading, error }] = useSalvarGrupoExameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarGrupoExameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarGrupoExameMutation,
    Types.SalvarGrupoExameMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarGrupoExameMutation, Types.SalvarGrupoExameMutationVariables>(
    SalvarGrupoExameDocument,
    baseOptions
  )
}
export type SalvarGrupoExameMutationHookResult = ReturnType<typeof useSalvarGrupoExameMutation>
export type SalvarGrupoExameMutationResult = ApolloReactCommon.MutationResult<Types.SalvarGrupoExameMutation>
export type SalvarGrupoExameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarGrupoExameMutation,
  Types.SalvarGrupoExameMutationVariables
>
export const GrupoExameTableDocument = gql`
  query GrupoExameTable($input: GrupoExameQueryInput!) {
    gruposExame(input: $input) {
      content {
        id
        nome
        quantidadeProcedimentos
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useGrupoExameTableQuery__
 *
 * To run a query within a React component, call `useGrupoExameTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoExameTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoExameTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrupoExameTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>(
    GrupoExameTableDocument,
    baseOptions
  )
}
export function useGrupoExameTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>(
    GrupoExameTableDocument,
    baseOptions
  )
}
export type GrupoExameTableQueryHookResult = ReturnType<typeof useGrupoExameTableQuery>
export type GrupoExameTableLazyQueryHookResult = ReturnType<typeof useGrupoExameTableLazyQuery>
export type GrupoExameTableQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoExameTableQuery,
  Types.GrupoExameTableQueryVariables
>
export const CertificadoRndsDocument = gql`
  query CertificadoRnds {
    configuracaoCertificadoHabilitado {
      id
      rndsHabilitado
    }
    conexao {
      internet {
        habilitado
      }
    }
  }
`

/**
 * __useCertificadoRndsQuery__
 *
 * To run a query within a React component, call `useCertificadoRndsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificadoRndsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificadoRndsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertificadoRndsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CertificadoRndsQuery, Types.CertificadoRndsQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CertificadoRndsQuery, Types.CertificadoRndsQueryVariables>(
    CertificadoRndsDocument,
    baseOptions
  )
}
export function useCertificadoRndsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CertificadoRndsQuery, Types.CertificadoRndsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CertificadoRndsQuery, Types.CertificadoRndsQueryVariables>(
    CertificadoRndsDocument,
    baseOptions
  )
}
export type CertificadoRndsQueryHookResult = ReturnType<typeof useCertificadoRndsQuery>
export type CertificadoRndsLazyQueryHookResult = ReturnType<typeof useCertificadoRndsLazyQuery>
export type CertificadoRndsQueryResult = ApolloReactCommon.QueryResult<
  Types.CertificadoRndsQuery,
  Types.CertificadoRndsQueryVariables
>
export const ResumoEnviosRndsDocument = gql`
  query ResumoEnviosRnds($input: TipoRegistroRndsEnum!) {
    resumoEnviosRnds(input: $input) {
      qtdEnviado
      qtdNaoEnviado
      qtdFalhaConexao
      qtdDadosInvalidos
      qtdErro
    }
  }
`

/**
 * __useResumoEnviosRndsQuery__
 *
 * To run a query within a React component, call `useResumoEnviosRndsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumoEnviosRndsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumoEnviosRndsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumoEnviosRndsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ResumoEnviosRndsQuery, Types.ResumoEnviosRndsQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ResumoEnviosRndsQuery, Types.ResumoEnviosRndsQueryVariables>(
    ResumoEnviosRndsDocument,
    baseOptions
  )
}
export function useResumoEnviosRndsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ResumoEnviosRndsQuery, Types.ResumoEnviosRndsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ResumoEnviosRndsQuery, Types.ResumoEnviosRndsQueryVariables>(
    ResumoEnviosRndsDocument,
    baseOptions
  )
}
export type ResumoEnviosRndsQueryHookResult = ReturnType<typeof useResumoEnviosRndsQuery>
export type ResumoEnviosRndsLazyQueryHookResult = ReturnType<typeof useResumoEnviosRndsLazyQuery>
export type ResumoEnviosRndsQueryResult = ApolloReactCommon.QueryResult<
  Types.ResumoEnviosRndsQuery,
  Types.ResumoEnviosRndsQueryVariables
>
export const StatusConexaoRndsDocument = gql`
  query StatusConexaoRnds {
    statusConexaoRnds
  }
`

/**
 * __useStatusConexaoRndsQuery__
 *
 * To run a query within a React component, call `useStatusConexaoRndsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusConexaoRndsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusConexaoRndsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusConexaoRndsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.StatusConexaoRndsQuery, Types.StatusConexaoRndsQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.StatusConexaoRndsQuery, Types.StatusConexaoRndsQueryVariables>(
    StatusConexaoRndsDocument,
    baseOptions
  )
}
export function useStatusConexaoRndsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.StatusConexaoRndsQuery,
    Types.StatusConexaoRndsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.StatusConexaoRndsQuery, Types.StatusConexaoRndsQueryVariables>(
    StatusConexaoRndsDocument,
    baseOptions
  )
}
export type StatusConexaoRndsQueryHookResult = ReturnType<typeof useStatusConexaoRndsQuery>
export type StatusConexaoRndsLazyQueryHookResult = ReturnType<typeof useStatusConexaoRndsLazyQuery>
export type StatusConexaoRndsQueryResult = ApolloReactCommon.QueryResult<
  Types.StatusConexaoRndsQuery,
  Types.StatusConexaoRndsQueryVariables
>
export const EquipeEMultiVinculacoesTableDocument = gql`
  query EquipeEMultiVinculacoesTable($input: TodasVinculacoesEquipesInput!) {
    todasVinculacoesEquipeEmulti(input: $input) {
      content {
        equipeEmulti {
          id
          nome
          ine
        }
        vinculacoesPorEstabelecimento {
          estabelecimento {
            id
            nome
            cnes
          }
          equipes {
            id
            nome
            ine
          }
        }
        quantidadeVinculacoes
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipeEMultiVinculacoesTableQuery__
 *
 * To run a query within a React component, call `useEquipeEMultiVinculacoesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeEMultiVinculacoesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeEMultiVinculacoesTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeEMultiVinculacoesTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EquipeEMultiVinculacoesTableQuery,
    Types.EquipeEMultiVinculacoesTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EquipeEMultiVinculacoesTableQuery,
    Types.EquipeEMultiVinculacoesTableQueryVariables
  >(EquipeEMultiVinculacoesTableDocument, baseOptions)
}
export function useEquipeEMultiVinculacoesTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeEMultiVinculacoesTableQuery,
    Types.EquipeEMultiVinculacoesTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EquipeEMultiVinculacoesTableQuery,
    Types.EquipeEMultiVinculacoesTableQueryVariables
  >(EquipeEMultiVinculacoesTableDocument, baseOptions)
}
export type EquipeEMultiVinculacoesTableQueryHookResult = ReturnType<typeof useEquipeEMultiVinculacoesTableQuery>
export type EquipeEMultiVinculacoesTableLazyQueryHookResult = ReturnType<
  typeof useEquipeEMultiVinculacoesTableLazyQuery
>
export type EquipeEMultiVinculacoesTableQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeEMultiVinculacoesTableQuery,
  Types.EquipeEMultiVinculacoesTableQueryVariables
>
export const ExcluirVinculacaoServicoDocument = gql`
  mutation ExcluirVinculacaoServico($referenciaId: Long!) {
    excluirVinculacaoServico(referenciaId: $referenciaId)
  }
`
export type ExcluirVinculacaoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirVinculacaoServicoMutation,
  Types.ExcluirVinculacaoServicoMutationVariables
>

/**
 * __useExcluirVinculacaoServicoMutation__
 *
 * To run a mutation, you first call `useExcluirVinculacaoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirVinculacaoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirVinculacaoServicoMutation, { data, loading, error }] = useExcluirVinculacaoServicoMutation({
 *   variables: {
 *      referenciaId: // value for 'referenciaId'
 *   },
 * });
 */
export function useExcluirVinculacaoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirVinculacaoServicoMutation,
    Types.ExcluirVinculacaoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirVinculacaoServicoMutation,
    Types.ExcluirVinculacaoServicoMutationVariables
  >(ExcluirVinculacaoServicoDocument, baseOptions)
}
export type ExcluirVinculacaoServicoMutationHookResult = ReturnType<typeof useExcluirVinculacaoServicoMutation>
export type ExcluirVinculacaoServicoMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirVinculacaoServicoMutation
>
export type ExcluirVinculacaoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirVinculacaoServicoMutation,
  Types.ExcluirVinculacaoServicoMutationVariables
>
export const SalvarVinculacaoServicoDocument = gql`
  mutation SalvarVinculacaoServico($input: SalvarVinculacaoServicoInput!) {
    salvarVinculacaoServico(input: $input)
  }
`
export type SalvarVinculacaoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarVinculacaoServicoMutation,
  Types.SalvarVinculacaoServicoMutationVariables
>

/**
 * __useSalvarVinculacaoServicoMutation__
 *
 * To run a mutation, you first call `useSalvarVinculacaoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarVinculacaoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarVinculacaoServicoMutation, { data, loading, error }] = useSalvarVinculacaoServicoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarVinculacaoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarVinculacaoServicoMutation,
    Types.SalvarVinculacaoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarVinculacaoServicoMutation,
    Types.SalvarVinculacaoServicoMutationVariables
  >(SalvarVinculacaoServicoDocument, baseOptions)
}
export type SalvarVinculacaoServicoMutationHookResult = ReturnType<typeof useSalvarVinculacaoServicoMutation>
export type SalvarVinculacaoServicoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarVinculacaoServicoMutation
>
export type SalvarVinculacaoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarVinculacaoServicoMutation,
  Types.SalvarVinculacaoServicoMutationVariables
>
export const UnidadesSaudeByTipoVinculacaoServicoDocument = gql`
  query UnidadesSaudeByTipoVinculacaoServico($input: UnidadesSaudeByTipoVinculacaoServicoInput!) {
    unidadesSaudeByTipoVinculacaoServico(input: $input) {
      id
      nome
      cnes
      hasVinculacaoServico
    }
  }
`

/**
 * __useUnidadesSaudeByTipoVinculacaoServicoQuery__
 *
 * To run a query within a React component, call `useUnidadesSaudeByTipoVinculacaoServicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadesSaudeByTipoVinculacaoServicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadesSaudeByTipoVinculacaoServicoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadesSaudeByTipoVinculacaoServicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadesSaudeByTipoVinculacaoServicoQuery,
    Types.UnidadesSaudeByTipoVinculacaoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UnidadesSaudeByTipoVinculacaoServicoQuery,
    Types.UnidadesSaudeByTipoVinculacaoServicoQueryVariables
  >(UnidadesSaudeByTipoVinculacaoServicoDocument, baseOptions)
}
export function useUnidadesSaudeByTipoVinculacaoServicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadesSaudeByTipoVinculacaoServicoQuery,
    Types.UnidadesSaudeByTipoVinculacaoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnidadesSaudeByTipoVinculacaoServicoQuery,
    Types.UnidadesSaudeByTipoVinculacaoServicoQueryVariables
  >(UnidadesSaudeByTipoVinculacaoServicoDocument, baseOptions)
}
export type UnidadesSaudeByTipoVinculacaoServicoQueryHookResult = ReturnType<
  typeof useUnidadesSaudeByTipoVinculacaoServicoQuery
>
export type UnidadesSaudeByTipoVinculacaoServicoLazyQueryHookResult = ReturnType<
  typeof useUnidadesSaudeByTipoVinculacaoServicoLazyQuery
>
export type UnidadesSaudeByTipoVinculacaoServicoQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadesSaudeByTipoVinculacaoServicoQuery,
  Types.UnidadesSaudeByTipoVinculacaoServicoQueryVariables
>
export const VinculacoesEstabelecimentosTableDocument = gql`
  query VinculacoesEstabelecimentosTable($input: VinculacaoServicosInput!) {
    vinculacoesServico(input: $input) {
      content {
        referencia {
          id
          nome
          cnes
        }
        vinculacoes {
          id
          nome
          cnes
        }
        quantidadeVinculacoes
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useVinculacoesEstabelecimentosTableQuery__
 *
 * To run a query within a React component, call `useVinculacoesEstabelecimentosTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useVinculacoesEstabelecimentosTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVinculacoesEstabelecimentosTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVinculacoesEstabelecimentosTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VinculacoesEstabelecimentosTableQuery,
    Types.VinculacoesEstabelecimentosTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.VinculacoesEstabelecimentosTableQuery,
    Types.VinculacoesEstabelecimentosTableQueryVariables
  >(VinculacoesEstabelecimentosTableDocument, baseOptions)
}
export function useVinculacoesEstabelecimentosTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VinculacoesEstabelecimentosTableQuery,
    Types.VinculacoesEstabelecimentosTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.VinculacoesEstabelecimentosTableQuery,
    Types.VinculacoesEstabelecimentosTableQueryVariables
  >(VinculacoesEstabelecimentosTableDocument, baseOptions)
}
export type VinculacoesEstabelecimentosTableQueryHookResult = ReturnType<
  typeof useVinculacoesEstabelecimentosTableQuery
>
export type VinculacoesEstabelecimentosTableLazyQueryHookResult = ReturnType<
  typeof useVinculacoesEstabelecimentosTableLazyQuery
>
export type VinculacoesEstabelecimentosTableQueryResult = ApolloReactCommon.QueryResult<
  Types.VinculacoesEstabelecimentosTableQuery,
  Types.VinculacoesEstabelecimentosTableQueryVariables
>
export const VinculacaoServicoDocument = gql`
  query VinculacaoServico($referenciaId: Long!) {
    vinculacaoServico(referenciaId: $referenciaId) {
      referencia {
        id
        nome
        cnes
      }
      vinculacoes {
        id
        nome
        cnes
      }
    }
  }
`

/**
 * __useVinculacaoServicoQuery__
 *
 * To run a query within a React component, call `useVinculacaoServicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVinculacaoServicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVinculacaoServicoQuery({
 *   variables: {
 *      referenciaId: // value for 'referenciaId'
 *   },
 * });
 */
export function useVinculacaoServicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.VinculacaoServicoQuery, Types.VinculacaoServicoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.VinculacaoServicoQuery, Types.VinculacaoServicoQueryVariables>(
    VinculacaoServicoDocument,
    baseOptions
  )
}
export function useVinculacaoServicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VinculacaoServicoQuery,
    Types.VinculacaoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.VinculacaoServicoQuery, Types.VinculacaoServicoQueryVariables>(
    VinculacaoServicoDocument,
    baseOptions
  )
}
export type VinculacaoServicoQueryHookResult = ReturnType<typeof useVinculacaoServicoQuery>
export type VinculacaoServicoLazyQueryHookResult = ReturnType<typeof useVinculacaoServicoLazyQuery>
export type VinculacaoServicoQueryResult = ApolloReactCommon.QueryResult<
  Types.VinculacaoServicoQuery,
  Types.VinculacaoServicoQueryVariables
>
export const TipoServicoCheckDocument = gql`
  query TipoServicoCheck($input: TipoServicoQueryInput!) {
    tiposServico(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTipoServicoCheckQuery__
 *
 * To run a query within a React component, call `useTipoServicoCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoServicoCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoServicoCheckQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipoServicoCheckQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.TipoServicoCheckQuery, Types.TipoServicoCheckQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.TipoServicoCheckQuery, Types.TipoServicoCheckQueryVariables>(
    TipoServicoCheckDocument,
    baseOptions
  )
}
export function useTipoServicoCheckLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TipoServicoCheckQuery, Types.TipoServicoCheckQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.TipoServicoCheckQuery, Types.TipoServicoCheckQueryVariables>(
    TipoServicoCheckDocument,
    baseOptions
  )
}
export type TipoServicoCheckQueryHookResult = ReturnType<typeof useTipoServicoCheckQuery>
export type TipoServicoCheckLazyQueryHookResult = ReturnType<typeof useTipoServicoCheckLazyQuery>
export type TipoServicoCheckQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoServicoCheckQuery,
  Types.TipoServicoCheckQueryVariables
>
export const TipoServicoTableDocument = gql`
  query TipoServicoTable($input: TipoServicoQueryInput!) {
    tiposServico(input: $input) {
      content {
        id
        nome
        municipio {
          id
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTipoServicoTableQuery__
 *
 * To run a query within a React component, call `useTipoServicoTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoServicoTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoServicoTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipoServicoTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>(
    TipoServicoTableDocument,
    baseOptions
  )
}
export function useTipoServicoTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>(
    TipoServicoTableDocument,
    baseOptions
  )
}
export type TipoServicoTableQueryHookResult = ReturnType<typeof useTipoServicoTableQuery>
export type TipoServicoTableLazyQueryHookResult = ReturnType<typeof useTipoServicoTableLazyQuery>
export type TipoServicoTableQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoServicoTableQuery,
  Types.TipoServicoTableQueryVariables
>
export const AlterarTipoServicoDocument = gql`
  mutation AlterarTipoServico($input: TipoServicoUpdateInput!) {
    alterarTipoServico(input: $input) {
      id
      nome
    }
  }
`
export type AlterarTipoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarTipoServicoMutation,
  Types.AlterarTipoServicoMutationVariables
>

/**
 * __useAlterarTipoServicoMutation__
 *
 * To run a mutation, you first call `useAlterarTipoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarTipoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarTipoServicoMutation, { data, loading, error }] = useAlterarTipoServicoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarTipoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarTipoServicoMutation,
    Types.AlterarTipoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarTipoServicoMutation, Types.AlterarTipoServicoMutationVariables>(
    AlterarTipoServicoDocument,
    baseOptions
  )
}
export type AlterarTipoServicoMutationHookResult = ReturnType<typeof useAlterarTipoServicoMutation>
export type AlterarTipoServicoMutationResult = ApolloReactCommon.MutationResult<Types.AlterarTipoServicoMutation>
export type AlterarTipoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarTipoServicoMutation,
  Types.AlterarTipoServicoMutationVariables
>
export const SalvarTipoServicoDocument = gql`
  mutation SalvarTipoServico($input: TipoServicoInput!) {
    salvarTipoServico(input: $input) {
      id
      nome
    }
  }
`
export type SalvarTipoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarTipoServicoMutation,
  Types.SalvarTipoServicoMutationVariables
>

/**
 * __useSalvarTipoServicoMutation__
 *
 * To run a mutation, you first call `useSalvarTipoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarTipoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarTipoServicoMutation, { data, loading, error }] = useSalvarTipoServicoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarTipoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarTipoServicoMutation,
    Types.SalvarTipoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarTipoServicoMutation, Types.SalvarTipoServicoMutationVariables>(
    SalvarTipoServicoDocument,
    baseOptions
  )
}
export type SalvarTipoServicoMutationHookResult = ReturnType<typeof useSalvarTipoServicoMutation>
export type SalvarTipoServicoMutationResult = ApolloReactCommon.MutationResult<Types.SalvarTipoServicoMutation>
export type SalvarTipoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarTipoServicoMutation,
  Types.SalvarTipoServicoMutationVariables
>
export const ExcluirTipoServicoDocument = gql`
  mutation ExcluirTipoServico($id: ID!) {
    excluirTipoServico(id: $id)
  }
`
export type ExcluirTipoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirTipoServicoMutation,
  Types.ExcluirTipoServicoMutationVariables
>

/**
 * __useExcluirTipoServicoMutation__
 *
 * To run a mutation, you first call `useExcluirTipoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirTipoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirTipoServicoMutation, { data, loading, error }] = useExcluirTipoServicoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirTipoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirTipoServicoMutation,
    Types.ExcluirTipoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ExcluirTipoServicoMutation, Types.ExcluirTipoServicoMutationVariables>(
    ExcluirTipoServicoDocument,
    baseOptions
  )
}
export type ExcluirTipoServicoMutationHookResult = ReturnType<typeof useExcluirTipoServicoMutation>
export type ExcluirTipoServicoMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirTipoServicoMutation>
export type ExcluirTipoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirTipoServicoMutation,
  Types.ExcluirTipoServicoMutationVariables
>
export const CuidadoCompartilhadoDocument = gql`
  query CuidadoCompartilhado($id: ID!) {
    cuidadoCompartilhado(id: $id) {
      id
      iniciadoEm
      dataUltimaEvolucao
      classificacaoPrioridadeAtual
      cidadaoAceitaAtendTic
      discussao
      status
      isEmulti
      cid10 {
        ...Cid10
      }
      ciap {
        ...Ciap
      }
      lotacaoExecutanteAtual {
        id
        profissional {
          id
          nome
          telefone
          email
        }
        cbo {
          id
          nome
        }
        equipe {
          id
          nome
          ine
        }
        unidadeSaude {
          id
          nome
        }
      }
      cboExecutanteAtual {
        id
        nome
      }
      unidadeSaudeExecutanteAtual {
        id
        nome
        tipo {
          id
          codigoMs
        }
        subtipo {
          id
          codigoMs
        }
      }
      lotacaoSolicitante {
        id
        profissional {
          id
          nome
          telefone
          email
        }
        cbo {
          id
          nome
        }
        equipe {
          id
          nome
          ine
        }
        unidadeSaude {
          id
          nome
        }
      }
      cidadao {
        id
        cpf
        cns
        nome
        nomeSocial
        dataNascimento
        dataAtualizado
        sexo
        nomeMae
        telefoneResidencial
        telefoneCelular
        telefoneContato
        email
        identidadeGeneroDbEnum
        ativo
        faleceu
        possuiAgendamento
        unificado
        unificacaoBase
        prontuario {
          id
          preNatalAtivo {
            id
            ultimaDum
          }
          gestacoes {
            id
            inicio
            fim
          }
        }
        cidadaoVinculacaoEquipe {
          id
          tpCdsOrigem
          unidadeSaude {
            id
            nome
          }
          equipe {
            id
            nome
            ine
          }
        }
      }
    }
  }
  ${Cid10FragmentDoc}
  ${CiapFragmentDoc}
`

/**
 * __useCuidadoCompartilhadoQuery__
 *
 * To run a query within a React component, call `useCuidadoCompartilhadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCuidadoCompartilhadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCuidadoCompartilhadoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCuidadoCompartilhadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CuidadoCompartilhadoQuery,
    Types.CuidadoCompartilhadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CuidadoCompartilhadoQuery, Types.CuidadoCompartilhadoQueryVariables>(
    CuidadoCompartilhadoDocument,
    baseOptions
  )
}
export function useCuidadoCompartilhadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CuidadoCompartilhadoQuery,
    Types.CuidadoCompartilhadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CuidadoCompartilhadoQuery, Types.CuidadoCompartilhadoQueryVariables>(
    CuidadoCompartilhadoDocument,
    baseOptions
  )
}
export type CuidadoCompartilhadoQueryHookResult = ReturnType<typeof useCuidadoCompartilhadoQuery>
export type CuidadoCompartilhadoLazyQueryHookResult = ReturnType<typeof useCuidadoCompartilhadoLazyQuery>
export type CuidadoCompartilhadoQueryResult = ApolloReactCommon.QueryResult<
  Types.CuidadoCompartilhadoQuery,
  Types.CuidadoCompartilhadoQueryVariables
>
export const SalvarCuidadoCompartilhadoDocument = gql`
  mutation SalvarCuidadoCompartilhado($input: SalvarCuidadoCompartilhadoInput!) {
    salvarCuidadoCompartilhado(input: $input) {
      id
    }
  }
`
export type SalvarCuidadoCompartilhadoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarCuidadoCompartilhadoMutation,
  Types.SalvarCuidadoCompartilhadoMutationVariables
>

/**
 * __useSalvarCuidadoCompartilhadoMutation__
 *
 * To run a mutation, you first call `useSalvarCuidadoCompartilhadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarCuidadoCompartilhadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarCuidadoCompartilhadoMutation, { data, loading, error }] = useSalvarCuidadoCompartilhadoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarCuidadoCompartilhadoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarCuidadoCompartilhadoMutation,
    Types.SalvarCuidadoCompartilhadoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarCuidadoCompartilhadoMutation,
    Types.SalvarCuidadoCompartilhadoMutationVariables
  >(SalvarCuidadoCompartilhadoDocument, baseOptions)
}
export type SalvarCuidadoCompartilhadoMutationHookResult = ReturnType<typeof useSalvarCuidadoCompartilhadoMutation>
export type SalvarCuidadoCompartilhadoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarCuidadoCompartilhadoMutation
>
export type SalvarCuidadoCompartilhadoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarCuidadoCompartilhadoMutation,
  Types.SalvarCuidadoCompartilhadoMutationVariables
>
export const CboTrocaExecutanteSelectFieldDocument = gql`
  query CboTrocaExecutanteSelectField($input: CbosTrocaExecutanteCuidadoCompartilhadoQueryInput!) {
    cbosTrocaExecutante(input: $input) {
      content {
        id
        nome
        cbo2002
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCboTrocaExecutanteSelectFieldQuery__
 *
 * To run a query within a React component, call `useCboTrocaExecutanteSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboTrocaExecutanteSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboTrocaExecutanteSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCboTrocaExecutanteSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CboTrocaExecutanteSelectFieldQuery,
    Types.CboTrocaExecutanteSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CboTrocaExecutanteSelectFieldQuery,
    Types.CboTrocaExecutanteSelectFieldQueryVariables
  >(CboTrocaExecutanteSelectFieldDocument, baseOptions)
}
export function useCboTrocaExecutanteSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CboTrocaExecutanteSelectFieldQuery,
    Types.CboTrocaExecutanteSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CboTrocaExecutanteSelectFieldQuery,
    Types.CboTrocaExecutanteSelectFieldQueryVariables
  >(CboTrocaExecutanteSelectFieldDocument, baseOptions)
}
export type CboTrocaExecutanteSelectFieldQueryHookResult = ReturnType<typeof useCboTrocaExecutanteSelectFieldQuery>
export type CboTrocaExecutanteSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCboTrocaExecutanteSelectFieldLazyQuery
>
export type CboTrocaExecutanteSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CboTrocaExecutanteSelectFieldQuery,
  Types.CboTrocaExecutanteSelectFieldQueryVariables
>
export const CuidadoCompartilhadoEvolucoesDocument = gql`
  query CuidadoCompartilhadoEvolucoes($input: CuidadoCompartilhadoEvolucoesQueryInput!) {
    cuidadoCompartilhadoEvolucoes(input: $input) {
      content {
        id
        dataEvolucao
        conduta
        descricao
        classificacaoPrioridade
        reclassificacaoPrioridade
        enviarGarantiaAcesso
        lotacao {
          id
          profissional {
            id
            nome
          }
        }
        lotacaoExecutante {
          id
        }
        lotacaoAfetada {
          id
          profissional {
            id
            nome
          }
          cbo {
            id
            nome
          }
          equipe {
            id
            nome
          }
          unidadeSaude {
            id
            nome
          }
        }
        agenda {
          id
          horarioInicial
          situacao
          observacao
          justificativaCancelamento {
            id
            justificativa
          }
        }
        cuidadoCompartilhado {
          id
          lotacaoSolicitante {
            id
          }
        }
      }
    }
  }
`

/**
 * __useCuidadoCompartilhadoEvolucoesQuery__
 *
 * To run a query within a React component, call `useCuidadoCompartilhadoEvolucoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCuidadoCompartilhadoEvolucoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCuidadoCompartilhadoEvolucoesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCuidadoCompartilhadoEvolucoesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CuidadoCompartilhadoEvolucoesQuery,
    Types.CuidadoCompartilhadoEvolucoesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CuidadoCompartilhadoEvolucoesQuery,
    Types.CuidadoCompartilhadoEvolucoesQueryVariables
  >(CuidadoCompartilhadoEvolucoesDocument, baseOptions)
}
export function useCuidadoCompartilhadoEvolucoesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CuidadoCompartilhadoEvolucoesQuery,
    Types.CuidadoCompartilhadoEvolucoesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CuidadoCompartilhadoEvolucoesQuery,
    Types.CuidadoCompartilhadoEvolucoesQueryVariables
  >(CuidadoCompartilhadoEvolucoesDocument, baseOptions)
}
export type CuidadoCompartilhadoEvolucoesQueryHookResult = ReturnType<typeof useCuidadoCompartilhadoEvolucoesQuery>
export type CuidadoCompartilhadoEvolucoesLazyQueryHookResult = ReturnType<
  typeof useCuidadoCompartilhadoEvolucoesLazyQuery
>
export type CuidadoCompartilhadoEvolucoesQueryResult = ApolloReactCommon.QueryResult<
  Types.CuidadoCompartilhadoEvolucoesQuery,
  Types.CuidadoCompartilhadoEvolucoesQueryVariables
>
export const VisualizarCuidadoCompartilhadoDocument = gql`
  mutation VisualizarCuidadoCompartilhado($input: VisualizarCuidadoCompartilhadoInput!) {
    visualizarCuidadoCompartilhado(input: $input)
  }
`
export type VisualizarCuidadoCompartilhadoMutationFn = ApolloReactCommon.MutationFunction<
  Types.VisualizarCuidadoCompartilhadoMutation,
  Types.VisualizarCuidadoCompartilhadoMutationVariables
>

/**
 * __useVisualizarCuidadoCompartilhadoMutation__
 *
 * To run a mutation, you first call `useVisualizarCuidadoCompartilhadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisualizarCuidadoCompartilhadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visualizarCuidadoCompartilhadoMutation, { data, loading, error }] = useVisualizarCuidadoCompartilhadoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisualizarCuidadoCompartilhadoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.VisualizarCuidadoCompartilhadoMutation,
    Types.VisualizarCuidadoCompartilhadoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.VisualizarCuidadoCompartilhadoMutation,
    Types.VisualizarCuidadoCompartilhadoMutationVariables
  >(VisualizarCuidadoCompartilhadoDocument, baseOptions)
}
export type VisualizarCuidadoCompartilhadoMutationHookResult = ReturnType<
  typeof useVisualizarCuidadoCompartilhadoMutation
>
export type VisualizarCuidadoCompartilhadoMutationResult = ApolloReactCommon.MutationResult<
  Types.VisualizarCuidadoCompartilhadoMutation
>
export type VisualizarCuidadoCompartilhadoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.VisualizarCuidadoCompartilhadoMutation,
  Types.VisualizarCuidadoCompartilhadoMutationVariables
>
export const IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoDocument = gql`
  query IsEstabelecimentoAllowedAsReferenciaVinculacaoServico($tipoCoMs: Long!, $subtipoCoMs: String) {
    isEstabelecimentoAllowedAsReferenciaVinculacaoServico(tipoCoMs: $tipoCoMs, subtipoCoMs: $subtipoCoMs)
  }
`

/**
 * __useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery__
 *
 * To run a query within a React component, call `useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery({
 *   variables: {
 *      tipoCoMs: // value for 'tipoCoMs'
 *      subtipoCoMs: // value for 'subtipoCoMs'
 *   },
 * });
 */
export function useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery,
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery,
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryVariables
  >(IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoDocument, baseOptions)
}
export function useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery,
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery,
    Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryVariables
  >(IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoDocument, baseOptions)
}
export type IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryHookResult = ReturnType<
  typeof useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery
>
export type IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoLazyQueryHookResult = ReturnType<
  typeof useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoLazyQuery
>
export type IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryResult = ApolloReactCommon.QueryResult<
  Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery,
  Types.IsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQueryVariables
>
export const ListaCuidadoCompartilhadoDocument = gql`
  query ListaCuidadoCompartilhado($input: CuidadosCompartilhadoQueryInput!) {
    cuidadosCompartilhado(input: $input) {
      content {
        id
        iniciadoEm
        cidadao {
          id
          dataNascimento
          nomeSocial
          nome
        }
        classificacaoPrioridadeAtual
        lotacaoExecutanteAtual {
          id
          profissional {
            id
            nome
          }
          equipe {
            id
            nome
          }
        }
        cboExecutanteAtual {
          id
          nome
        }
        unidadeSaudeExecutanteAtual {
          id
          nome
        }
        lotacaoSolicitante {
          id
          profissional {
            id
            nome
          }
          cbo {
            id
            nome
          }
          equipe {
            id
            nome
          }
          unidadeSaude {
            id
            nome
          }
        }
        status
        dataUltimaEvolucao
        discussao
        isEmulti
        cid10 {
          ...Cid10
        }
        ciap {
          ...Ciap
        }
        actions {
          discutirCasoSolicitante {
            enabled
            hint
          }
          discutirCasoExecutante {
            enabled
            hint
          }
          visualizarDiscussao {
            enabled
            hint
          }
          tornarseExecutante {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Cid10FragmentDoc}
  ${CiapFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useListaCuidadoCompartilhadoQuery__
 *
 * To run a query within a React component, call `useListaCuidadoCompartilhadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListaCuidadoCompartilhadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListaCuidadoCompartilhadoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListaCuidadoCompartilhadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ListaCuidadoCompartilhadoQuery,
    Types.ListaCuidadoCompartilhadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ListaCuidadoCompartilhadoQuery, Types.ListaCuidadoCompartilhadoQueryVariables>(
    ListaCuidadoCompartilhadoDocument,
    baseOptions
  )
}
export function useListaCuidadoCompartilhadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ListaCuidadoCompartilhadoQuery,
    Types.ListaCuidadoCompartilhadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ListaCuidadoCompartilhadoQuery,
    Types.ListaCuidadoCompartilhadoQueryVariables
  >(ListaCuidadoCompartilhadoDocument, baseOptions)
}
export type ListaCuidadoCompartilhadoQueryHookResult = ReturnType<typeof useListaCuidadoCompartilhadoQuery>
export type ListaCuidadoCompartilhadoLazyQueryHookResult = ReturnType<typeof useListaCuidadoCompartilhadoLazyQuery>
export type ListaCuidadoCompartilhadoQueryResult = ApolloReactCommon.QueryResult<
  Types.ListaCuidadoCompartilhadoQuery,
  Types.ListaCuidadoCompartilhadoQueryVariables
>
export const CbosCuidadoCompartilhadoSelectFieldDocument = gql`
  query CbosCuidadoCompartilhadoSelectField($input: CbosListaCuidadoCompartilhadoQueryInput!) {
    cbosListaCuidadoCompartilhado(input: $input) {
      id
      nome
      cbo2002
    }
  }
`

/**
 * __useCbosCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useCbosCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCbosCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCbosCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCbosCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CbosCuidadoCompartilhadoSelectFieldQuery,
    Types.CbosCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CbosCuidadoCompartilhadoSelectFieldQuery,
    Types.CbosCuidadoCompartilhadoSelectFieldQueryVariables
  >(CbosCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useCbosCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CbosCuidadoCompartilhadoSelectFieldQuery,
    Types.CbosCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CbosCuidadoCompartilhadoSelectFieldQuery,
    Types.CbosCuidadoCompartilhadoSelectFieldQueryVariables
  >(CbosCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type CbosCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useCbosCuidadoCompartilhadoSelectFieldQuery
>
export type CbosCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useCbosCuidadoCompartilhadoSelectFieldLazyQuery
>
export type CbosCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CbosCuidadoCompartilhadoSelectFieldQuery,
  Types.CbosCuidadoCompartilhadoSelectFieldQueryVariables
>
export const EquipeCuidadoCompartilhadoSelectFieldDocument = gql`
  query EquipeCuidadoCompartilhadoSelectField($input: EquipesListaCuidadoCompartilhadoQueryInput!) {
    equipesListaCuidadoCompartilhado(input: $input) {
      id
      nome
      ine
      tipoEquipe {
        id
        sigla
      }
    }
  }
`

/**
 * __useEquipeCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useEquipeCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EquipeCuidadoCompartilhadoSelectFieldQuery,
    Types.EquipeCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.EquipeCuidadoCompartilhadoSelectFieldQuery,
    Types.EquipeCuidadoCompartilhadoSelectFieldQueryVariables
  >(EquipeCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useEquipeCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeCuidadoCompartilhadoSelectFieldQuery,
    Types.EquipeCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.EquipeCuidadoCompartilhadoSelectFieldQuery,
    Types.EquipeCuidadoCompartilhadoSelectFieldQueryVariables
  >(EquipeCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type EquipeCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useEquipeCuidadoCompartilhadoSelectFieldQuery
>
export type EquipeCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useEquipeCuidadoCompartilhadoSelectFieldLazyQuery
>
export type EquipeCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeCuidadoCompartilhadoSelectFieldQuery,
  Types.EquipeCuidadoCompartilhadoSelectFieldQueryVariables
>
export const UnidadeSaudeCuidadoCompartilhadoSelectFieldDocument = gql`
  query UnidadeSaudeCuidadoCompartilhadoSelectField($input: EstabelecimentosListaCuidadoCompartilhadoQueryInput!) {
    estabelecimentosListaCuidadoCompartilhado(input: $input) {
      id
      nome
      cnes
    }
  }
`

/**
 * __useUnidadeSaudeCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadeSaudeCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables
  >(UnidadeSaudeCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useUnidadeSaudeCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
    Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables
  >(UnidadeSaudeCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useUnidadeSaudeCuidadoCompartilhadoSelectFieldQuery
>
export type UnidadeSaudeCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useUnidadeSaudeCuidadoCompartilhadoSelectFieldLazyQuery
>
export type UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
  Types.UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables
>
export const ProfissionaisCuidadoCompartilhadoSelectFieldDocument = gql`
  query ProfissionaisCuidadoCompartilhadoSelectField($input: ProfissionaisListaCuidadoCompartilhadoQueryInput!) {
    profissionaisListaCuidadoCompartilhado(input: $input) {
      id
      nome
      cpf
    }
  }
`

/**
 * __useProfissionaisCuidadoCompartilhadoSelectFieldQuery__
 *
 * To run a query within a React component, call `useProfissionaisCuidadoCompartilhadoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionaisCuidadoCompartilhadoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionaisCuidadoCompartilhadoSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfissionaisCuidadoCompartilhadoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQuery,
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQuery,
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQueryVariables
  >(ProfissionaisCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export function useProfissionaisCuidadoCompartilhadoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQuery,
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQuery,
    Types.ProfissionaisCuidadoCompartilhadoSelectFieldQueryVariables
  >(ProfissionaisCuidadoCompartilhadoSelectFieldDocument, baseOptions)
}
export type ProfissionaisCuidadoCompartilhadoSelectFieldQueryHookResult = ReturnType<
  typeof useProfissionaisCuidadoCompartilhadoSelectFieldQuery
>
export type ProfissionaisCuidadoCompartilhadoSelectFieldLazyQueryHookResult = ReturnType<
  typeof useProfissionaisCuidadoCompartilhadoSelectFieldLazyQuery
>
export type ProfissionaisCuidadoCompartilhadoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionaisCuidadoCompartilhadoSelectFieldQuery,
  Types.ProfissionaisCuidadoCompartilhadoSelectFieldQueryVariables
>
export const TornarseExecutanteCuidadoCompartilhadoDocument = gql`
  mutation TornarseExecutanteCuidadoCompartilhado($id: ID!) {
    tornarseExecutanteCuidadoCompartilhado(id: $id) {
      id
    }
  }
`
export type TornarseExecutanteCuidadoCompartilhadoMutationFn = ApolloReactCommon.MutationFunction<
  Types.TornarseExecutanteCuidadoCompartilhadoMutation,
  Types.TornarseExecutanteCuidadoCompartilhadoMutationVariables
>

/**
 * __useTornarseExecutanteCuidadoCompartilhadoMutation__
 *
 * To run a mutation, you first call `useTornarseExecutanteCuidadoCompartilhadoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTornarseExecutanteCuidadoCompartilhadoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tornarseExecutanteCuidadoCompartilhadoMutation, { data, loading, error }] = useTornarseExecutanteCuidadoCompartilhadoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTornarseExecutanteCuidadoCompartilhadoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TornarseExecutanteCuidadoCompartilhadoMutation,
    Types.TornarseExecutanteCuidadoCompartilhadoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.TornarseExecutanteCuidadoCompartilhadoMutation,
    Types.TornarseExecutanteCuidadoCompartilhadoMutationVariables
  >(TornarseExecutanteCuidadoCompartilhadoDocument, baseOptions)
}
export type TornarseExecutanteCuidadoCompartilhadoMutationHookResult = ReturnType<
  typeof useTornarseExecutanteCuidadoCompartilhadoMutation
>
export type TornarseExecutanteCuidadoCompartilhadoMutationResult = ApolloReactCommon.MutationResult<
  Types.TornarseExecutanteCuidadoCompartilhadoMutation
>
export type TornarseExecutanteCuidadoCompartilhadoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.TornarseExecutanteCuidadoCompartilhadoMutation,
  Types.TornarseExecutanteCuidadoCompartilhadoMutationVariables
>
export const EquipeDetailDocument = gql`
  query EquipeDetail($id: ID!) {
    equipe(id: $id) {
      id
      nome
      ine
      area
      ativo
      tipoEquipe {
        id
        nome
        sigla
      }
    }
  }
`

/**
 * __useEquipeDetailQuery__
 *
 * To run a query within a React component, call `useEquipeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEquipeDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipeDetailQuery, Types.EquipeDetailQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EquipeDetailQuery, Types.EquipeDetailQueryVariables>(
    EquipeDetailDocument,
    baseOptions
  )
}
export function useEquipeDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EquipeDetailQuery, Types.EquipeDetailQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipeDetailQuery, Types.EquipeDetailQueryVariables>(
    EquipeDetailDocument,
    baseOptions
  )
}
export type EquipeDetailQueryHookResult = ReturnType<typeof useEquipeDetailQuery>
export type EquipeDetailLazyQueryHookResult = ReturnType<typeof useEquipeDetailLazyQuery>
export type EquipeDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeDetailQuery,
  Types.EquipeDetailQueryVariables
>
export const EquipeTableDocument = gql`
  query EquipeTable($input: EquipesQueryInput!) {
    equipes(input: $input) {
      content {
        id
        nome
        ine
        area
        tipoEquipe {
          id
          nome
          sigla
        }
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipeTableQuery__
 *
 * To run a query within a React component, call `useEquipeTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipeTableQuery, Types.EquipeTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EquipeTableQuery, Types.EquipeTableQueryVariables>(
    EquipeTableDocument,
    baseOptions
  )
}
export function useEquipeTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EquipeTableQuery, Types.EquipeTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipeTableQuery, Types.EquipeTableQueryVariables>(
    EquipeTableDocument,
    baseOptions
  )
}
export type EquipeTableQueryHookResult = ReturnType<typeof useEquipeTableQuery>
export type EquipeTableLazyQueryHookResult = ReturnType<typeof useEquipeTableLazyQuery>
export type EquipeTableQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeTableQuery,
  Types.EquipeTableQueryVariables
>
export const CriarEstagioDocument = gql`
  mutation CriarEstagio($input: EstagioCreateInput!) {
    createEstagio(input: $input) {
      id
    }
  }
`
export type CriarEstagioMutationFn = ApolloReactCommon.MutationFunction<
  Types.CriarEstagioMutation,
  Types.CriarEstagioMutationVariables
>

/**
 * __useCriarEstagioMutation__
 *
 * To run a mutation, you first call `useCriarEstagioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCriarEstagioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [criarEstagioMutation, { data, loading, error }] = useCriarEstagioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCriarEstagioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CriarEstagioMutation, Types.CriarEstagioMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.CriarEstagioMutation, Types.CriarEstagioMutationVariables>(
    CriarEstagioDocument,
    baseOptions
  )
}
export type CriarEstagioMutationHookResult = ReturnType<typeof useCriarEstagioMutation>
export type CriarEstagioMutationResult = ApolloReactCommon.MutationResult<Types.CriarEstagioMutation>
export type CriarEstagioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CriarEstagioMutation,
  Types.CriarEstagioMutationVariables
>
export const DeleteEstagioDocument = gql`
  mutation DeleteEstagio($estagioId: ID!) {
    deleteEstagio(estagioId: $estagioId)
  }
`
export type DeleteEstagioMutationFn = ApolloReactCommon.MutationFunction<
  Types.DeleteEstagioMutation,
  Types.DeleteEstagioMutationVariables
>

/**
 * __useDeleteEstagioMutation__
 *
 * To run a mutation, you first call `useDeleteEstagioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEstagioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEstagioMutation, { data, loading, error }] = useDeleteEstagioMutation({
 *   variables: {
 *      estagioId: // value for 'estagioId'
 *   },
 * });
 */
export function useDeleteEstagioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.DeleteEstagioMutation, Types.DeleteEstagioMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.DeleteEstagioMutation, Types.DeleteEstagioMutationVariables>(
    DeleteEstagioDocument,
    baseOptions
  )
}
export type DeleteEstagioMutationHookResult = ReturnType<typeof useDeleteEstagioMutation>
export type DeleteEstagioMutationResult = ApolloReactCommon.MutationResult<Types.DeleteEstagioMutation>
export type DeleteEstagioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DeleteEstagioMutation,
  Types.DeleteEstagioMutationVariables
>
export const EstagioEditViewDocument = gql`
  query EstagioEditView($id: ID!) {
    estagio(id: $id) {
      id
      ativo
      dataInicioVigencia
      dataFimVigencia
      instituicaoEnsino
      nivelTecnico
      cbo {
        id
        nome
        cbo2002
      }
      equipe {
        id
        ine
        nome
      }
      lotacaoSupervisora {
        id
        profissional {
          id
          cpf
          nome
        }
        unidadeSaude {
          id
          nome
          cnes
        }
        cbo {
          id
          nome
          cbo2002
        }
        equipe {
          id
          ine
          nome
        }
      }
      profissional {
        id
        cpf
        nome
      }
      perfis {
        id
        nome
        ativo
      }
      unidadeSaude {
        id
        nome
        cnes
      }
      actions {
        permissaoEdicao {
          enabled
        }
      }
    }
  }
`

/**
 * __useEstagioEditViewQuery__
 *
 * To run a query within a React component, call `useEstagioEditViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstagioEditViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstagioEditViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEstagioEditViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EstagioEditViewQuery, Types.EstagioEditViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EstagioEditViewQuery, Types.EstagioEditViewQueryVariables>(
    EstagioEditViewDocument,
    baseOptions
  )
}
export function useEstagioEditViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EstagioEditViewQuery, Types.EstagioEditViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EstagioEditViewQuery, Types.EstagioEditViewQueryVariables>(
    EstagioEditViewDocument,
    baseOptions
  )
}
export type EstagioEditViewQueryHookResult = ReturnType<typeof useEstagioEditViewQuery>
export type EstagioEditViewLazyQueryHookResult = ReturnType<typeof useEstagioEditViewLazyQuery>
export type EstagioEditViewQueryResult = ApolloReactCommon.QueryResult<
  Types.EstagioEditViewQuery,
  Types.EstagioEditViewQueryVariables
>
export const ProfissionalEstagioDocument = gql`
  query ProfissionalEstagio($id: ID!) {
    profissional(id: $id) {
      id
      nome
    }
  }
`

/**
 * __useProfissionalEstagioQuery__
 *
 * To run a query within a React component, call `useProfissionalEstagioQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalEstagioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalEstagioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfissionalEstagioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionalEstagioQuery,
    Types.ProfissionalEstagioQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalEstagioQuery, Types.ProfissionalEstagioQueryVariables>(
    ProfissionalEstagioDocument,
    baseOptions
  )
}
export function useProfissionalEstagioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalEstagioQuery,
    Types.ProfissionalEstagioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalEstagioQuery, Types.ProfissionalEstagioQueryVariables>(
    ProfissionalEstagioDocument,
    baseOptions
  )
}
export type ProfissionalEstagioQueryHookResult = ReturnType<typeof useProfissionalEstagioQuery>
export type ProfissionalEstagioLazyQueryHookResult = ReturnType<typeof useProfissionalEstagioLazyQuery>
export type ProfissionalEstagioQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalEstagioQuery,
  Types.ProfissionalEstagioQueryVariables
>
export const UpdateEstagioDocument = gql`
  mutation UpdateEstagio($input: EstagioUpdateInput!) {
    updateEstagio(input: $input) {
      id
    }
  }
`
export type UpdateEstagioMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateEstagioMutation,
  Types.UpdateEstagioMutationVariables
>

/**
 * __useUpdateEstagioMutation__
 *
 * To run a mutation, you first call `useUpdateEstagioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstagioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstagioMutation, { data, loading, error }] = useUpdateEstagioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEstagioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateEstagioMutation, Types.UpdateEstagioMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.UpdateEstagioMutation, Types.UpdateEstagioMutationVariables>(
    UpdateEstagioDocument,
    baseOptions
  )
}
export type UpdateEstagioMutationHookResult = ReturnType<typeof useUpdateEstagioMutation>
export type UpdateEstagioMutationResult = ApolloReactCommon.MutationResult<Types.UpdateEstagioMutation>
export type UpdateEstagioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateEstagioMutation,
  Types.UpdateEstagioMutationVariables
>
export const AlterarUnidadePadraoHorusDocument = gql`
  mutation AlterarUnidadePadraoHorus($input: UnidadeConsultaHorusIds!) {
    alterarUnidadePadraoHorus(input: $input) {
      id
      nome
    }
  }
`
export type AlterarUnidadePadraoHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarUnidadePadraoHorusMutation,
  Types.AlterarUnidadePadraoHorusMutationVariables
>

/**
 * __useAlterarUnidadePadraoHorusMutation__
 *
 * To run a mutation, you first call `useAlterarUnidadePadraoHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarUnidadePadraoHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarUnidadePadraoHorusMutation, { data, loading, error }] = useAlterarUnidadePadraoHorusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarUnidadePadraoHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarUnidadePadraoHorusMutation,
    Types.AlterarUnidadePadraoHorusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarUnidadePadraoHorusMutation,
    Types.AlterarUnidadePadraoHorusMutationVariables
  >(AlterarUnidadePadraoHorusDocument, baseOptions)
}
export type AlterarUnidadePadraoHorusMutationHookResult = ReturnType<typeof useAlterarUnidadePadraoHorusMutation>
export type AlterarUnidadePadraoHorusMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarUnidadePadraoHorusMutation
>
export type AlterarUnidadePadraoHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarUnidadePadraoHorusMutation,
  Types.AlterarUnidadePadraoHorusMutationVariables
>
export const CadastroUnidadeConsultaHorusDocument = gql`
  mutation CadastroUnidadeConsultaHorus($input: UnidadeConsultaHorusIds!) {
    adicionarUnidadeConsultaHorus(input: $input) {
      id
      nome
    }
  }
`
export type CadastroUnidadeConsultaHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.CadastroUnidadeConsultaHorusMutation,
  Types.CadastroUnidadeConsultaHorusMutationVariables
>

/**
 * __useCadastroUnidadeConsultaHorusMutation__
 *
 * To run a mutation, you first call `useCadastroUnidadeConsultaHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastroUnidadeConsultaHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastroUnidadeConsultaHorusMutation, { data, loading, error }] = useCadastroUnidadeConsultaHorusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCadastroUnidadeConsultaHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CadastroUnidadeConsultaHorusMutation,
    Types.CadastroUnidadeConsultaHorusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.CadastroUnidadeConsultaHorusMutation,
    Types.CadastroUnidadeConsultaHorusMutationVariables
  >(CadastroUnidadeConsultaHorusDocument, baseOptions)
}
export type CadastroUnidadeConsultaHorusMutationHookResult = ReturnType<typeof useCadastroUnidadeConsultaHorusMutation>
export type CadastroUnidadeConsultaHorusMutationResult = ApolloReactCommon.MutationResult<
  Types.CadastroUnidadeConsultaHorusMutation
>
export type CadastroUnidadeConsultaHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CadastroUnidadeConsultaHorusMutation,
  Types.CadastroUnidadeConsultaHorusMutationVariables
>
export const HorusListViewDocument = gql`
  query HorusListView($unidadeSaudeId: ID!) {
    unidadesConsultaHorus(id: $unidadeSaudeId) {
      unidadeSaude {
        id
        nome
        cnes
      }
      unidadePadrao
    }
    unidadeSaude(id: $unidadeSaudeId) {
      id
      actions {
        permissaoConexaoHorus {
          enabled
          hint
        }
      }
    }
    conexao {
      horus {
        id
        habilitado
      }
    }
  }
`

/**
 * __useHorusListViewQuery__
 *
 * To run a query within a React component, call `useHorusListViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useHorusListViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHorusListViewQuery({
 *   variables: {
 *      unidadeSaudeId: // value for 'unidadeSaudeId'
 *   },
 * });
 */
export function useHorusListViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>(
    HorusListViewDocument,
    baseOptions
  )
}
export function useHorusListViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>(
    HorusListViewDocument,
    baseOptions
  )
}
export type HorusListViewQueryHookResult = ReturnType<typeof useHorusListViewQuery>
export type HorusListViewLazyQueryHookResult = ReturnType<typeof useHorusListViewLazyQuery>
export type HorusListViewQueryResult = ApolloReactCommon.QueryResult<
  Types.HorusListViewQuery,
  Types.HorusListViewQueryVariables
>
export const RemoverUnidadeConsultaHorusDocument = gql`
  mutation RemoverUnidadeConsultaHorus($input: UnidadeConsultaHorusIds!) {
    removerUnidadeConsultaHorus(input: $input) {
      id
      nome
    }
  }
`
export type RemoverUnidadeConsultaHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.RemoverUnidadeConsultaHorusMutation,
  Types.RemoverUnidadeConsultaHorusMutationVariables
>

/**
 * __useRemoverUnidadeConsultaHorusMutation__
 *
 * To run a mutation, you first call `useRemoverUnidadeConsultaHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverUnidadeConsultaHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerUnidadeConsultaHorusMutation, { data, loading, error }] = useRemoverUnidadeConsultaHorusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoverUnidadeConsultaHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RemoverUnidadeConsultaHorusMutation,
    Types.RemoverUnidadeConsultaHorusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RemoverUnidadeConsultaHorusMutation,
    Types.RemoverUnidadeConsultaHorusMutationVariables
  >(RemoverUnidadeConsultaHorusDocument, baseOptions)
}
export type RemoverUnidadeConsultaHorusMutationHookResult = ReturnType<typeof useRemoverUnidadeConsultaHorusMutation>
export type RemoverUnidadeConsultaHorusMutationResult = ApolloReactCommon.MutationResult<
  Types.RemoverUnidadeConsultaHorusMutation
>
export type RemoverUnidadeConsultaHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RemoverUnidadeConsultaHorusMutation,
  Types.RemoverUnidadeConsultaHorusMutationVariables
>
export const InstalarDocument = gql`
  mutation Instalar($input: InstalacaoInput!) {
    instalar(input: $input)
  }
`
export type InstalarMutationFn = ApolloReactCommon.MutationFunction<
  Types.InstalarMutation,
  Types.InstalarMutationVariables
>

/**
 * __useInstalarMutation__
 *
 * To run a mutation, you first call `useInstalarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstalarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [instalarMutation, { data, loading, error }] = useInstalarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstalarMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.InstalarMutation, Types.InstalarMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.InstalarMutation, Types.InstalarMutationVariables>(
    InstalarDocument,
    baseOptions
  )
}
export type InstalarMutationHookResult = ReturnType<typeof useInstalarMutation>
export type InstalarMutationResult = ApolloReactCommon.MutationResult<Types.InstalarMutation>
export type InstalarMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InstalarMutation,
  Types.InstalarMutationVariables
>
export const GarantiaAcessoCidadaoDocument = gql`
  query GarantiaAcessoCidadao($id: ID!) {
    cidadao(id: $id) {
      id
      nome
      nomeSocial
      cpf
      cns
      nomeMae
      dataNascimento
      sexo
      identidadeGeneroDbEnum
      faleceu
      cidadaoVinculacaoEquipe {
        id
        unidadeSaude {
          id
        }
        equipe {
          id
          ine
          nome
          tipoEquipe {
            id
            nome
            sigla
          }
        }
      }
    }
  }
`

/**
 * __useGarantiaAcessoCidadaoQuery__
 *
 * To run a query within a React component, call `useGarantiaAcessoCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGarantiaAcessoCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGarantiaAcessoCidadaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGarantiaAcessoCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GarantiaAcessoCidadaoQuery,
    Types.GarantiaAcessoCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.GarantiaAcessoCidadaoQuery, Types.GarantiaAcessoCidadaoQueryVariables>(
    GarantiaAcessoCidadaoDocument,
    baseOptions
  )
}
export function useGarantiaAcessoCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GarantiaAcessoCidadaoQuery,
    Types.GarantiaAcessoCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.GarantiaAcessoCidadaoQuery, Types.GarantiaAcessoCidadaoQueryVariables>(
    GarantiaAcessoCidadaoDocument,
    baseOptions
  )
}
export type GarantiaAcessoCidadaoQueryHookResult = ReturnType<typeof useGarantiaAcessoCidadaoQuery>
export type GarantiaAcessoCidadaoLazyQueryHookResult = ReturnType<typeof useGarantiaAcessoCidadaoLazyQuery>
export type GarantiaAcessoCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.GarantiaAcessoCidadaoQuery,
  Types.GarantiaAcessoCidadaoQueryVariables
>
export const GarantiaAcessoDocument = gql`
  query GarantiaAcesso($input: ID!) {
    garantiaAcessoItemById(input: $input) {
      id
      cidadao {
        id
        nome
        nomeSocial
        cpf
        cns
        telefoneResidencial
        telefoneCelular
        telefoneContato
      }
      equipe {
        id
        ine
        nome
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      motivoEspera
      tipoAtendimento
      tipoServico {
        id
        nome
      }
      dataEntrada
      cuidadoCompartilhadoEvolucao {
        id
        cuidadoCompartilhado {
          id
          lotacaoSolicitante {
            id
            ativo
            importada
            profissional {
              id
              nome
              cpf
              email
            }
            cbo {
              id
              nome
              cbo2002
            }
          }
        }
        lotacaoExecutante {
          id
          ativo
          importada
          profissional {
            id
            nome
            cpf
            email
          }
          cbo {
            id
            nome
            cbo2002
          }
        }
        condutaAgendamento: enviarGarantiaAcesso
      }
    }
  }
`

/**
 * __useGarantiaAcessoQuery__
 *
 * To run a query within a React component, call `useGarantiaAcessoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGarantiaAcessoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGarantiaAcessoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGarantiaAcessoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GarantiaAcessoQuery, Types.GarantiaAcessoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.GarantiaAcessoQuery, Types.GarantiaAcessoQueryVariables>(
    GarantiaAcessoDocument,
    baseOptions
  )
}
export function useGarantiaAcessoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GarantiaAcessoQuery, Types.GarantiaAcessoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.GarantiaAcessoQuery, Types.GarantiaAcessoQueryVariables>(
    GarantiaAcessoDocument,
    baseOptions
  )
}
export type GarantiaAcessoQueryHookResult = ReturnType<typeof useGarantiaAcessoQuery>
export type GarantiaAcessoLazyQueryHookResult = ReturnType<typeof useGarantiaAcessoLazyQuery>
export type GarantiaAcessoQueryResult = ApolloReactCommon.QueryResult<
  Types.GarantiaAcessoQuery,
  Types.GarantiaAcessoQueryVariables
>
export const GarantiaAcessoTableDocument = gql`
  query GarantiaAcessoTable($input: GarantiaAcessoFilter!) {
    garantiaAcessoByUnidadeSaudeId(input: $input) {
      content {
        id
        cidadao {
          id
          nome
          nomeSocial
          dataNascimento
          cpf
          cns
          telefoneResidencial
          telefoneCelular
          telefoneContato
          prontuario {
            id
          }
        }
        unidadeSaude {
          id
        }
        equipe {
          id
          nome
          area
        }
        motivoEspera
        dataEntrada
        dataSaida
        tipoAtendimento
        tipoServico {
          id
          nome
        }
        qtdCidadaoPresente
        cuidadoCompartilhadoEvolucao {
          ...CuidadoCompartilhadoEvolucaoGarantiaAcessoFragment
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${CuidadoCompartilhadoEvolucaoGarantiaAcessoFragmentFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useGarantiaAcessoTableQuery__
 *
 * To run a query within a React component, call `useGarantiaAcessoTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGarantiaAcessoTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGarantiaAcessoTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGarantiaAcessoTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GarantiaAcessoTableQuery,
    Types.GarantiaAcessoTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.GarantiaAcessoTableQuery, Types.GarantiaAcessoTableQueryVariables>(
    GarantiaAcessoTableDocument,
    baseOptions
  )
}
export function useGarantiaAcessoTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GarantiaAcessoTableQuery,
    Types.GarantiaAcessoTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.GarantiaAcessoTableQuery, Types.GarantiaAcessoTableQueryVariables>(
    GarantiaAcessoTableDocument,
    baseOptions
  )
}
export type GarantiaAcessoTableQueryHookResult = ReturnType<typeof useGarantiaAcessoTableQuery>
export type GarantiaAcessoTableLazyQueryHookResult = ReturnType<typeof useGarantiaAcessoTableLazyQuery>
export type GarantiaAcessoTableQueryResult = ApolloReactCommon.QueryResult<
  Types.GarantiaAcessoTableQuery,
  Types.GarantiaAcessoTableQueryVariables
>
export const UnidadesSaudeByGarantiaAcessoCidadaoDocument = gql`
  query UnidadesSaudeByGarantiaAcessoCidadao($input: UnidadesSaudeByGarantiaAcessoCidadaoInput!) {
    unidadesSaudeByGarantiaAcessoCidadao(input: $input) {
      id
      nome
    }
  }
`

/**
 * __useUnidadesSaudeByGarantiaAcessoCidadaoQuery__
 *
 * To run a query within a React component, call `useUnidadesSaudeByGarantiaAcessoCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadesSaudeByGarantiaAcessoCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadesSaudeByGarantiaAcessoCidadaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadesSaudeByGarantiaAcessoCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQuery,
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQuery,
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQueryVariables
  >(UnidadesSaudeByGarantiaAcessoCidadaoDocument, baseOptions)
}
export function useUnidadesSaudeByGarantiaAcessoCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQuery,
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQuery,
    Types.UnidadesSaudeByGarantiaAcessoCidadaoQueryVariables
  >(UnidadesSaudeByGarantiaAcessoCidadaoDocument, baseOptions)
}
export type UnidadesSaudeByGarantiaAcessoCidadaoQueryHookResult = ReturnType<
  typeof useUnidadesSaudeByGarantiaAcessoCidadaoQuery
>
export type UnidadesSaudeByGarantiaAcessoCidadaoLazyQueryHookResult = ReturnType<
  typeof useUnidadesSaudeByGarantiaAcessoCidadaoLazyQuery
>
export type UnidadesSaudeByGarantiaAcessoCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadesSaudeByGarantiaAcessoCidadaoQuery,
  Types.UnidadesSaudeByGarantiaAcessoCidadaoQueryVariables
>
export const AgendarConsultaGarantiaAcessoDocument = gql`
  mutation AgendarConsultaGarantiaAcesso($input: GarantiaAcessoAgendarConsultaInput!) {
    agendarConsultaGarantiaAcesso(input: $input)
  }
`
export type AgendarConsultaGarantiaAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AgendarConsultaGarantiaAcessoMutation,
  Types.AgendarConsultaGarantiaAcessoMutationVariables
>

/**
 * __useAgendarConsultaGarantiaAcessoMutation__
 *
 * To run a mutation, you first call `useAgendarConsultaGarantiaAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgendarConsultaGarantiaAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agendarConsultaGarantiaAcessoMutation, { data, loading, error }] = useAgendarConsultaGarantiaAcessoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgendarConsultaGarantiaAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AgendarConsultaGarantiaAcessoMutation,
    Types.AgendarConsultaGarantiaAcessoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AgendarConsultaGarantiaAcessoMutation,
    Types.AgendarConsultaGarantiaAcessoMutationVariables
  >(AgendarConsultaGarantiaAcessoDocument, baseOptions)
}
export type AgendarConsultaGarantiaAcessoMutationHookResult = ReturnType<
  typeof useAgendarConsultaGarantiaAcessoMutation
>
export type AgendarConsultaGarantiaAcessoMutationResult = ApolloReactCommon.MutationResult<
  Types.AgendarConsultaGarantiaAcessoMutation
>
export type AgendarConsultaGarantiaAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AgendarConsultaGarantiaAcessoMutation,
  Types.AgendarConsultaGarantiaAcessoMutationVariables
>
export const AdicionarGarantiaAcessoCeoDocument = gql`
  mutation AdicionarGarantiaAcessoCeo($input: GarantiaAcessoItemCreateInput!) {
    adicionarCidadaoGarantiaAcessoCeo(input: $input)
  }
`
export type AdicionarGarantiaAcessoCeoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AdicionarGarantiaAcessoCeoMutation,
  Types.AdicionarGarantiaAcessoCeoMutationVariables
>

/**
 * __useAdicionarGarantiaAcessoCeoMutation__
 *
 * To run a mutation, you first call `useAdicionarGarantiaAcessoCeoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdicionarGarantiaAcessoCeoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adicionarGarantiaAcessoCeoMutation, { data, loading, error }] = useAdicionarGarantiaAcessoCeoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdicionarGarantiaAcessoCeoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AdicionarGarantiaAcessoCeoMutation,
    Types.AdicionarGarantiaAcessoCeoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AdicionarGarantiaAcessoCeoMutation,
    Types.AdicionarGarantiaAcessoCeoMutationVariables
  >(AdicionarGarantiaAcessoCeoDocument, baseOptions)
}
export type AdicionarGarantiaAcessoCeoMutationHookResult = ReturnType<typeof useAdicionarGarantiaAcessoCeoMutation>
export type AdicionarGarantiaAcessoCeoMutationResult = ApolloReactCommon.MutationResult<
  Types.AdicionarGarantiaAcessoCeoMutation
>
export type AdicionarGarantiaAcessoCeoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AdicionarGarantiaAcessoCeoMutation,
  Types.AdicionarGarantiaAcessoCeoMutationVariables
>
export const AdicionarGarantiaAcessoDocument = gql`
  mutation AdicionarGarantiaAcesso($input: GarantiaAcessoItemCreateInput!) {
    adicionarCidadaoGarantiaAcesso(input: $input)
  }
`
export type AdicionarGarantiaAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AdicionarGarantiaAcessoMutation,
  Types.AdicionarGarantiaAcessoMutationVariables
>

/**
 * __useAdicionarGarantiaAcessoMutation__
 *
 * To run a mutation, you first call `useAdicionarGarantiaAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdicionarGarantiaAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adicionarGarantiaAcessoMutation, { data, loading, error }] = useAdicionarGarantiaAcessoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdicionarGarantiaAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AdicionarGarantiaAcessoMutation,
    Types.AdicionarGarantiaAcessoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AdicionarGarantiaAcessoMutation,
    Types.AdicionarGarantiaAcessoMutationVariables
  >(AdicionarGarantiaAcessoDocument, baseOptions)
}
export type AdicionarGarantiaAcessoMutationHookResult = ReturnType<typeof useAdicionarGarantiaAcessoMutation>
export type AdicionarGarantiaAcessoMutationResult = ApolloReactCommon.MutationResult<
  Types.AdicionarGarantiaAcessoMutation
>
export type AdicionarGarantiaAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AdicionarGarantiaAcessoMutation,
  Types.AdicionarGarantiaAcessoMutationVariables
>
export const EditarCidadaoGarantiaAcessoDocument = gql`
  mutation EditarCidadaoGarantiaAcesso($input: GarantiaAcessoItemUpdateInput!) {
    editarCidadaoGarantiaAcesso(input: $input)
  }
`
export type EditarCidadaoGarantiaAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.EditarCidadaoGarantiaAcessoMutation,
  Types.EditarCidadaoGarantiaAcessoMutationVariables
>

/**
 * __useEditarCidadaoGarantiaAcessoMutation__
 *
 * To run a mutation, you first call `useEditarCidadaoGarantiaAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarCidadaoGarantiaAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarCidadaoGarantiaAcessoMutation, { data, loading, error }] = useEditarCidadaoGarantiaAcessoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditarCidadaoGarantiaAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EditarCidadaoGarantiaAcessoMutation,
    Types.EditarCidadaoGarantiaAcessoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.EditarCidadaoGarantiaAcessoMutation,
    Types.EditarCidadaoGarantiaAcessoMutationVariables
  >(EditarCidadaoGarantiaAcessoDocument, baseOptions)
}
export type EditarCidadaoGarantiaAcessoMutationHookResult = ReturnType<typeof useEditarCidadaoGarantiaAcessoMutation>
export type EditarCidadaoGarantiaAcessoMutationResult = ApolloReactCommon.MutationResult<
  Types.EditarCidadaoGarantiaAcessoMutation
>
export type EditarCidadaoGarantiaAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EditarCidadaoGarantiaAcessoMutation,
  Types.EditarCidadaoGarantiaAcessoMutationVariables
>
export const RemoverCidadaoGarantiaAcessoDocument = gql`
  mutation RemoverCidadaoGarantiaAcesso($input: GarantiaAcessoItemDeleteInput!) {
    removerCidadaoGarantiaAcesso(input: $input)
  }
`
export type RemoverCidadaoGarantiaAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.RemoverCidadaoGarantiaAcessoMutation,
  Types.RemoverCidadaoGarantiaAcessoMutationVariables
>

/**
 * __useRemoverCidadaoGarantiaAcessoMutation__
 *
 * To run a mutation, you first call `useRemoverCidadaoGarantiaAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverCidadaoGarantiaAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerCidadaoGarantiaAcessoMutation, { data, loading, error }] = useRemoverCidadaoGarantiaAcessoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoverCidadaoGarantiaAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RemoverCidadaoGarantiaAcessoMutation,
    Types.RemoverCidadaoGarantiaAcessoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RemoverCidadaoGarantiaAcessoMutation,
    Types.RemoverCidadaoGarantiaAcessoMutationVariables
  >(RemoverCidadaoGarantiaAcessoDocument, baseOptions)
}
export type RemoverCidadaoGarantiaAcessoMutationHookResult = ReturnType<typeof useRemoverCidadaoGarantiaAcessoMutation>
export type RemoverCidadaoGarantiaAcessoMutationResult = ApolloReactCommon.MutationResult<
  Types.RemoverCidadaoGarantiaAcessoMutation
>
export type RemoverCidadaoGarantiaAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RemoverCidadaoGarantiaAcessoMutation,
  Types.RemoverCidadaoGarantiaAcessoMutationVariables
>
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      success
    }
  }
`
export type LoginMutationFn = ApolloReactCommon.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, baseOptions)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = ApolloReactCommon.MutationResult<Types.LoginMutation>
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.LoginMutation,
  Types.LoginMutationVariables
>
export const AcessoEstagioDocument = gql`
  query AcessoEstagio($id: ID!) {
    estagio(id: $id) {
      id
      ativo
      lotacaoSupervisora {
        id
        profissional {
          id
          nome
        }
      }
      equipe {
        id
        nome
        ine
        tipoEquipe {
          id
          descricao
        }
      }
      cbo {
        id
        nome
        cbo2002
      }
      unidadeSaude {
        id
        nome
      }
    }
  }
`

/**
 * __useAcessoEstagioQuery__
 *
 * To run a query within a React component, call `useAcessoEstagioQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoEstagioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoEstagioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcessoEstagioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AcessoEstagioQuery, Types.AcessoEstagioQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AcessoEstagioQuery, Types.AcessoEstagioQueryVariables>(
    AcessoEstagioDocument,
    baseOptions
  )
}
export function useAcessoEstagioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AcessoEstagioQuery, Types.AcessoEstagioQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AcessoEstagioQuery, Types.AcessoEstagioQueryVariables>(
    AcessoEstagioDocument,
    baseOptions
  )
}
export type AcessoEstagioQueryHookResult = ReturnType<typeof useAcessoEstagioQuery>
export type AcessoEstagioLazyQueryHookResult = ReturnType<typeof useAcessoEstagioLazyQuery>
export type AcessoEstagioQueryResult = ApolloReactCommon.QueryResult<
  Types.AcessoEstagioQuery,
  Types.AcessoEstagioQueryVariables
>
export const SelecionarAcessoDocument = gql`
  mutation SelecionarAcesso($input: SelecionarAcessoInput!) {
    selecionarAcesso(input: $input) {
      id
      profissional {
        id
        nome
      }
      acesso {
        id
        moduloInicial
      }
      recursos
    }
  }
`
export type SelecionarAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SelecionarAcessoMutation,
  Types.SelecionarAcessoMutationVariables
>

/**
 * __useSelecionarAcessoMutation__
 *
 * To run a mutation, you first call `useSelecionarAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelecionarAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selecionarAcessoMutation, { data, loading, error }] = useSelecionarAcessoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelecionarAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SelecionarAcessoMutation,
    Types.SelecionarAcessoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SelecionarAcessoMutation, Types.SelecionarAcessoMutationVariables>(
    SelecionarAcessoDocument,
    baseOptions
  )
}
export type SelecionarAcessoMutationHookResult = ReturnType<typeof useSelecionarAcessoMutation>
export type SelecionarAcessoMutationResult = ApolloReactCommon.MutationResult<Types.SelecionarAcessoMutation>
export type SelecionarAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SelecionarAcessoMutation,
  Types.SelecionarAcessoMutationVariables
>
export const VersaoDocument = gql`
  query Versao {
    info {
      versao
    }
  }
`

/**
 * __useVersaoQuery__
 *
 * To run a query within a React component, call `useVersaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersaoQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.VersaoQuery, Types.VersaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.VersaoQuery, Types.VersaoQueryVariables>(VersaoDocument, baseOptions)
}
export function useVersaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.VersaoQuery, Types.VersaoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.VersaoQuery, Types.VersaoQueryVariables>(VersaoDocument, baseOptions)
}
export type VersaoQueryHookResult = ReturnType<typeof useVersaoQuery>
export type VersaoLazyQueryHookResult = ReturnType<typeof useVersaoLazyQuery>
export type VersaoQueryResult = ApolloReactCommon.QueryResult<Types.VersaoQuery, Types.VersaoQueryVariables>
export const ImunobiologicoComboDocument = gql`
  query ImunobiologicoCombo($input: ImunobiologicoQueryInput!) {
    imunobiologicos(input: $input) {
      content {
        id
        sigla
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useImunobiologicoComboQuery__
 *
 * To run a query within a React component, call `useImunobiologicoComboQuery` and pass it any options that fit your needs.
 * When your component renders, `useImunobiologicoComboQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImunobiologicoComboQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImunobiologicoComboQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ImunobiologicoComboQuery,
    Types.ImunobiologicoComboQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ImunobiologicoComboQuery, Types.ImunobiologicoComboQueryVariables>(
    ImunobiologicoComboDocument,
    baseOptions
  )
}
export function useImunobiologicoComboLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ImunobiologicoComboQuery,
    Types.ImunobiologicoComboQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ImunobiologicoComboQuery, Types.ImunobiologicoComboQueryVariables>(
    ImunobiologicoComboDocument,
    baseOptions
  )
}
export type ImunobiologicoComboQueryHookResult = ReturnType<typeof useImunobiologicoComboQuery>
export type ImunobiologicoComboLazyQueryHookResult = ReturnType<typeof useImunobiologicoComboLazyQuery>
export type ImunobiologicoComboQueryResult = ApolloReactCommon.QueryResult<
  Types.ImunobiologicoComboQuery,
  Types.ImunobiologicoComboQueryVariables
>
export const LoteHasCadastroDocument = gql`
  query LoteHasCadastro($input: LoteImunobiologicoInput!) {
    loteHasCadastro(input: $input)
  }
`

/**
 * __useLoteHasCadastroQuery__
 *
 * To run a query within a React component, call `useLoteHasCadastroQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteHasCadastroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteHasCadastroQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoteHasCadastroQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoteHasCadastroQuery, Types.LoteHasCadastroQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoteHasCadastroQuery, Types.LoteHasCadastroQueryVariables>(
    LoteHasCadastroDocument,
    baseOptions
  )
}
export function useLoteHasCadastroLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LoteHasCadastroQuery, Types.LoteHasCadastroQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LoteHasCadastroQuery, Types.LoteHasCadastroQueryVariables>(
    LoteHasCadastroDocument,
    baseOptions
  )
}
export type LoteHasCadastroQueryHookResult = ReturnType<typeof useLoteHasCadastroQuery>
export type LoteHasCadastroLazyQueryHookResult = ReturnType<typeof useLoteHasCadastroLazyQuery>
export type LoteHasCadastroQueryResult = ApolloReactCommon.QueryResult<
  Types.LoteHasCadastroQuery,
  Types.LoteHasCadastroQueryVariables
>
export const ExcluirLoteImunobiologicoDocument = gql`
  mutation excluirLoteImunobiologico($idLote: ID!) {
    excluirLoteImunobiologico(idLote: $idLote)
  }
`
export type ExcluirLoteImunobiologicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirLoteImunobiologicoMutation,
  Types.ExcluirLoteImunobiologicoMutationVariables
>

/**
 * __useExcluirLoteImunobiologicoMutation__
 *
 * To run a mutation, you first call `useExcluirLoteImunobiologicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirLoteImunobiologicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirLoteImunobiologicoMutation, { data, loading, error }] = useExcluirLoteImunobiologicoMutation({
 *   variables: {
 *      idLote: // value for 'idLote'
 *   },
 * });
 */
export function useExcluirLoteImunobiologicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirLoteImunobiologicoMutation,
    Types.ExcluirLoteImunobiologicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirLoteImunobiologicoMutation,
    Types.ExcluirLoteImunobiologicoMutationVariables
  >(ExcluirLoteImunobiologicoDocument, baseOptions)
}
export type ExcluirLoteImunobiologicoMutationHookResult = ReturnType<typeof useExcluirLoteImunobiologicoMutation>
export type ExcluirLoteImunobiologicoMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirLoteImunobiologicoMutation
>
export type ExcluirLoteImunobiologicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirLoteImunobiologicoMutation,
  Types.ExcluirLoteImunobiologicoMutationVariables
>
export const LoteImunobiologicoFabricanteDocument = gql`
  query LoteImunobiologicoFabricante($input: ImunobiologicoFabricanteQueryInput!) {
    fabricantesImunobiologico(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLoteImunobiologicoFabricanteQuery__
 *
 * To run a query within a React component, call `useLoteImunobiologicoFabricanteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteImunobiologicoFabricanteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteImunobiologicoFabricanteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoteImunobiologicoFabricanteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >(LoteImunobiologicoFabricanteDocument, baseOptions)
}
export function useLoteImunobiologicoFabricanteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >(LoteImunobiologicoFabricanteDocument, baseOptions)
}
export type LoteImunobiologicoFabricanteQueryHookResult = ReturnType<typeof useLoteImunobiologicoFabricanteQuery>
export type LoteImunobiologicoFabricanteLazyQueryHookResult = ReturnType<
  typeof useLoteImunobiologicoFabricanteLazyQuery
>
export type LoteImunobiologicoFabricanteQueryResult = ApolloReactCommon.QueryResult<
  Types.LoteImunobiologicoFabricanteQuery,
  Types.LoteImunobiologicoFabricanteQueryVariables
>
export const LoteImunobiologicoDocument = gql`
  query LoteImunobiologico($input: LoteImunobiologicoQueryInput!) {
    lotesImunobiologico(input: $input) {
      content {
        id
        lote
        fabricante {
          id
          nome
        }
        imunobiologico {
          id
          nome
          sigla
        }
        validade
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLoteImunobiologicoQuery__
 *
 * To run a query within a React component, call `useLoteImunobiologicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteImunobiologicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteImunobiologicoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoteImunobiologicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoteImunobiologicoQuery, Types.LoteImunobiologicoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoteImunobiologicoQuery, Types.LoteImunobiologicoQueryVariables>(
    LoteImunobiologicoDocument,
    baseOptions
  )
}
export function useLoteImunobiologicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LoteImunobiologicoQuery,
    Types.LoteImunobiologicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.LoteImunobiologicoQuery, Types.LoteImunobiologicoQueryVariables>(
    LoteImunobiologicoDocument,
    baseOptions
  )
}
export type LoteImunobiologicoQueryHookResult = ReturnType<typeof useLoteImunobiologicoQuery>
export type LoteImunobiologicoLazyQueryHookResult = ReturnType<typeof useLoteImunobiologicoLazyQuery>
export type LoteImunobiologicoQueryResult = ApolloReactCommon.QueryResult<
  Types.LoteImunobiologicoQuery,
  Types.LoteImunobiologicoQueryVariables
>
export const SetStatusLoteImunobiologicoDocument = gql`
  mutation setStatusLoteImunobiologico($input: LoteImunobiologicoInput!, $ativado: Boolean) {
    setStatusLoteImunobiologico(input: $input, status: $ativado)
  }
`
export type SetStatusLoteImunobiologicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SetStatusLoteImunobiologicoMutation,
  Types.SetStatusLoteImunobiologicoMutationVariables
>

/**
 * __useSetStatusLoteImunobiologicoMutation__
 *
 * To run a mutation, you first call `useSetStatusLoteImunobiologicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStatusLoteImunobiologicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStatusLoteImunobiologicoMutation, { data, loading, error }] = useSetStatusLoteImunobiologicoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ativado: // value for 'ativado'
 *   },
 * });
 */
export function useSetStatusLoteImunobiologicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SetStatusLoteImunobiologicoMutation,
    Types.SetStatusLoteImunobiologicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SetStatusLoteImunobiologicoMutation,
    Types.SetStatusLoteImunobiologicoMutationVariables
  >(SetStatusLoteImunobiologicoDocument, baseOptions)
}
export type SetStatusLoteImunobiologicoMutationHookResult = ReturnType<typeof useSetStatusLoteImunobiologicoMutation>
export type SetStatusLoteImunobiologicoMutationResult = ApolloReactCommon.MutationResult<
  Types.SetStatusLoteImunobiologicoMutation
>
export type SetStatusLoteImunobiologicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SetStatusLoteImunobiologicoMutation,
  Types.SetStatusLoteImunobiologicoMutationVariables
>
export const SalvarLoteImunobiologicoDocument = gql`
  mutation SalvarLoteImunobiologico($input: LoteImunobiologicoInput!) {
    salvarLoteImunobiologico(input: $input) {
      id
    }
  }
`
export type SalvarLoteImunobiologicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarLoteImunobiologicoMutation,
  Types.SalvarLoteImunobiologicoMutationVariables
>

/**
 * __useSalvarLoteImunobiologicoMutation__
 *
 * To run a mutation, you first call `useSalvarLoteImunobiologicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarLoteImunobiologicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarLoteImunobiologicoMutation, { data, loading, error }] = useSalvarLoteImunobiologicoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarLoteImunobiologicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarLoteImunobiologicoMutation,
    Types.SalvarLoteImunobiologicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarLoteImunobiologicoMutation,
    Types.SalvarLoteImunobiologicoMutationVariables
  >(SalvarLoteImunobiologicoDocument, baseOptions)
}
export type SalvarLoteImunobiologicoMutationHookResult = ReturnType<typeof useSalvarLoteImunobiologicoMutation>
export type SalvarLoteImunobiologicoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarLoteImunobiologicoMutation
>
export type SalvarLoteImunobiologicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarLoteImunobiologicoMutation,
  Types.SalvarLoteImunobiologicoMutationVariables
>
export const VisualizouNovidadesDocument = gql`
  mutation VisualizouNovidades {
    visualizarNovidades
  }
`
export type VisualizouNovidadesMutationFn = ApolloReactCommon.MutationFunction<
  Types.VisualizouNovidadesMutation,
  Types.VisualizouNovidadesMutationVariables
>

/**
 * __useVisualizouNovidadesMutation__
 *
 * To run a mutation, you first call `useVisualizouNovidadesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisualizouNovidadesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visualizouNovidadesMutation, { data, loading, error }] = useVisualizouNovidadesMutation({
 *   variables: {
 *   },
 * });
 */
export function useVisualizouNovidadesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.VisualizouNovidadesMutation,
    Types.VisualizouNovidadesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.VisualizouNovidadesMutation, Types.VisualizouNovidadesMutationVariables>(
    VisualizouNovidadesDocument,
    baseOptions
  )
}
export type VisualizouNovidadesMutationHookResult = ReturnType<typeof useVisualizouNovidadesMutation>
export type VisualizouNovidadesMutationResult = ApolloReactCommon.MutationResult<Types.VisualizouNovidadesMutation>
export type VisualizouNovidadesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.VisualizouNovidadesMutation,
  Types.VisualizouNovidadesMutationVariables
>
export const VisualizarNovidadesDocument = gql`
  query VisualizarNovidades {
    sessao {
      id
      profissional {
        id
        usuario {
          id
          visualizouNovidades
        }
      }
    }
  }
`

/**
 * __useVisualizarNovidadesQuery__
 *
 * To run a query within a React component, call `useVisualizarNovidadesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualizarNovidadesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualizarNovidadesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisualizarNovidadesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VisualizarNovidadesQuery,
    Types.VisualizarNovidadesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.VisualizarNovidadesQuery, Types.VisualizarNovidadesQueryVariables>(
    VisualizarNovidadesDocument,
    baseOptions
  )
}
export function useVisualizarNovidadesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VisualizarNovidadesQuery,
    Types.VisualizarNovidadesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.VisualizarNovidadesQuery, Types.VisualizarNovidadesQueryVariables>(
    VisualizarNovidadesDocument,
    baseOptions
  )
}
export type VisualizarNovidadesQueryHookResult = ReturnType<typeof useVisualizarNovidadesQuery>
export type VisualizarNovidadesLazyQueryHookResult = ReturnType<typeof useVisualizarNovidadesLazyQuery>
export type VisualizarNovidadesQueryResult = ApolloReactCommon.QueryResult<
  Types.VisualizarNovidadesQuery,
  Types.VisualizarNovidadesQueryVariables
>
export const ExibirNotificacaoNovidadesDocument = gql`
  mutation ExibirNotificacaoNovidades {
    exibirNotificacaoNovidades {
      id
      notificaNovidadesVersao
    }
  }
`
export type ExibirNotificacaoNovidadesMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExibirNotificacaoNovidadesMutation,
  Types.ExibirNotificacaoNovidadesMutationVariables
>

/**
 * __useExibirNotificacaoNovidadesMutation__
 *
 * To run a mutation, you first call `useExibirNotificacaoNovidadesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExibirNotificacaoNovidadesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exibirNotificacaoNovidadesMutation, { data, loading, error }] = useExibirNotificacaoNovidadesMutation({
 *   variables: {
 *   },
 * });
 */
export function useExibirNotificacaoNovidadesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExibirNotificacaoNovidadesMutation,
    Types.ExibirNotificacaoNovidadesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExibirNotificacaoNovidadesMutation,
    Types.ExibirNotificacaoNovidadesMutationVariables
  >(ExibirNotificacaoNovidadesDocument, baseOptions)
}
export type ExibirNotificacaoNovidadesMutationHookResult = ReturnType<typeof useExibirNotificacaoNovidadesMutation>
export type ExibirNotificacaoNovidadesMutationResult = ApolloReactCommon.MutationResult<
  Types.ExibirNotificacaoNovidadesMutation
>
export type ExibirNotificacaoNovidadesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExibirNotificacaoNovidadesMutation,
  Types.ExibirNotificacaoNovidadesMutationVariables
>
export const AtivarPerfilDocument = gql`
  mutation AtivarPerfil($id: ID!) {
    ativarPerfil(id: $id) {
      id
      ativo
    }
  }
`
export type AtivarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtivarPerfilMutation,
  Types.AtivarPerfilMutationVariables
>

/**
 * __useAtivarPerfilMutation__
 *
 * To run a mutation, you first call `useAtivarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtivarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ativarPerfilMutation, { data, loading, error }] = useAtivarPerfilMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtivarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AtivarPerfilMutation, Types.AtivarPerfilMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AtivarPerfilMutation, Types.AtivarPerfilMutationVariables>(
    AtivarPerfilDocument,
    baseOptions
  )
}
export type AtivarPerfilMutationHookResult = ReturnType<typeof useAtivarPerfilMutation>
export type AtivarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.AtivarPerfilMutation>
export type AtivarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtivarPerfilMutation,
  Types.AtivarPerfilMutationVariables
>
export const CriarPerfilDocument = gql`
  mutation CriarPerfil($input: PerfilInput!) {
    criarPerfil(input: $input) {
      id
      nome
      ativo
    }
  }
`
export type CriarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.CriarPerfilMutation,
  Types.CriarPerfilMutationVariables
>

/**
 * __useCriarPerfilMutation__
 *
 * To run a mutation, you first call `useCriarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCriarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [criarPerfilMutation, { data, loading, error }] = useCriarPerfilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCriarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CriarPerfilMutation, Types.CriarPerfilMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.CriarPerfilMutation, Types.CriarPerfilMutationVariables>(
    CriarPerfilDocument,
    baseOptions
  )
}
export type CriarPerfilMutationHookResult = ReturnType<typeof useCriarPerfilMutation>
export type CriarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.CriarPerfilMutation>
export type CriarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CriarPerfilMutation,
  Types.CriarPerfilMutationVariables
>
export const ExcluirPerfilDocument = gql`
  mutation ExcluirPerfil($id: ID!) {
    excluirPerfil(id: $id) {
      id
    }
  }
`
export type ExcluirPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirPerfilMutation,
  Types.ExcluirPerfilMutationVariables
>

/**
 * __useExcluirPerfilMutation__
 *
 * To run a mutation, you first call `useExcluirPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirPerfilMutation, { data, loading, error }] = useExcluirPerfilMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ExcluirPerfilMutation, Types.ExcluirPerfilMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.ExcluirPerfilMutation, Types.ExcluirPerfilMutationVariables>(
    ExcluirPerfilDocument,
    baseOptions
  )
}
export type ExcluirPerfilMutationHookResult = ReturnType<typeof useExcluirPerfilMutation>
export type ExcluirPerfilMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirPerfilMutation>
export type ExcluirPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirPerfilMutation,
  Types.ExcluirPerfilMutationVariables
>
export const InativarPerfilDocument = gql`
  mutation InativarPerfil($id: ID!) {
    inativarPerfil(id: $id) {
      id
      ativo
    }
  }
`
export type InativarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.InativarPerfilMutation,
  Types.InativarPerfilMutationVariables
>

/**
 * __useInativarPerfilMutation__
 *
 * To run a mutation, you first call `useInativarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInativarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inativarPerfilMutation, { data, loading, error }] = useInativarPerfilMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInativarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InativarPerfilMutation,
    Types.InativarPerfilMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.InativarPerfilMutation, Types.InativarPerfilMutationVariables>(
    InativarPerfilDocument,
    baseOptions
  )
}
export type InativarPerfilMutationHookResult = ReturnType<typeof useInativarPerfilMutation>
export type InativarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.InativarPerfilMutation>
export type InativarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InativarPerfilMutation,
  Types.InativarPerfilMutationVariables
>
export const RecursosDocument = gql`
  query Recursos($tipoAcesso: TipoAcesso!) {
    recursos(tipoAcesso: $tipoAcesso)
  }
`

/**
 * __useRecursosQuery__
 *
 * To run a query within a React component, call `useRecursosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecursosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecursosQuery({
 *   variables: {
 *      tipoAcesso: // value for 'tipoAcesso'
 *   },
 * });
 */
export function useRecursosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.RecursosQuery, Types.RecursosQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.RecursosQuery, Types.RecursosQueryVariables>(RecursosDocument, baseOptions)
}
export function useRecursosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.RecursosQuery, Types.RecursosQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.RecursosQuery, Types.RecursosQueryVariables>(RecursosDocument, baseOptions)
}
export type RecursosQueryHookResult = ReturnType<typeof useRecursosQuery>
export type RecursosLazyQueryHookResult = ReturnType<typeof useRecursosLazyQuery>
export type RecursosQueryResult = ApolloReactCommon.QueryResult<Types.RecursosQuery, Types.RecursosQueryVariables>
export const PerfilDetailDocument = gql`
  query PerfilDetail($perfilId: ID!) {
    perfil(id: $perfilId) {
      id
      nome
      tipoPerfil
      recursos
      perfilPadrao
    }
  }
`

/**
 * __usePerfilDetailQuery__
 *
 * To run a query within a React component, call `usePerfilDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerfilDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerfilDetailQuery({
 *   variables: {
 *      perfilId: // value for 'perfilId'
 *   },
 * });
 */
export function usePerfilDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PerfilDetailQuery, Types.PerfilDetailQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PerfilDetailQuery, Types.PerfilDetailQueryVariables>(
    PerfilDetailDocument,
    baseOptions
  )
}
export function usePerfilDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PerfilDetailQuery, Types.PerfilDetailQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PerfilDetailQuery, Types.PerfilDetailQueryVariables>(
    PerfilDetailDocument,
    baseOptions
  )
}
export type PerfilDetailQueryHookResult = ReturnType<typeof usePerfilDetailQuery>
export type PerfilDetailLazyQueryHookResult = ReturnType<typeof usePerfilDetailLazyQuery>
export type PerfilDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.PerfilDetailQuery,
  Types.PerfilDetailQueryVariables
>
export const AtualizarPerfilDocument = gql`
  mutation AtualizarPerfil($input: PerfilInput!) {
    atualizarPerfil(input: $input) {
      id
      nome
      ativo
    }
  }
`
export type AtualizarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtualizarPerfilMutation,
  Types.AtualizarPerfilMutationVariables
>

/**
 * __useAtualizarPerfilMutation__
 *
 * To run a mutation, you first call `useAtualizarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarPerfilMutation, { data, loading, error }] = useAtualizarPerfilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtualizarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtualizarPerfilMutation,
    Types.AtualizarPerfilMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtualizarPerfilMutation, Types.AtualizarPerfilMutationVariables>(
    AtualizarPerfilDocument,
    baseOptions
  )
}
export type AtualizarPerfilMutationHookResult = ReturnType<typeof useAtualizarPerfilMutation>
export type AtualizarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.AtualizarPerfilMutation>
export type AtualizarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtualizarPerfilMutation,
  Types.AtualizarPerfilMutationVariables
>
export const PerfilEditDocument = gql`
  query PerfilEdit($perfilId: ID!) {
    perfil(id: $perfilId) {
      id
      nome
      tipoPerfil
      recursos
      perfilPadrao
    }
  }
`

/**
 * __usePerfilEditQuery__
 *
 * To run a query within a React component, call `usePerfilEditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerfilEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerfilEditQuery({
 *   variables: {
 *      perfilId: // value for 'perfilId'
 *   },
 * });
 */
export function usePerfilEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>(
    PerfilEditDocument,
    baseOptions
  )
}
export function usePerfilEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>(
    PerfilEditDocument,
    baseOptions
  )
}
export type PerfilEditQueryHookResult = ReturnType<typeof usePerfilEditQuery>
export type PerfilEditLazyQueryHookResult = ReturnType<typeof usePerfilEditLazyQuery>
export type PerfilEditQueryResult = ApolloReactCommon.QueryResult<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>
export const PerfilTableDocument = gql`
  query PerfilTable($input: PerfisQueryInput!) {
    perfis(input: $input) {
      content {
        id
        nome
        ativo
        tipoPerfil
        perfilPadrao
        actions {
          excluir {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePerfilTableQuery__
 *
 * To run a query within a React component, call `usePerfilTableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerfilTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerfilTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerfilTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>(
    PerfilTableDocument,
    baseOptions
  )
}
export function usePerfilTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>(
    PerfilTableDocument,
    baseOptions
  )
}
export type PerfilTableQueryHookResult = ReturnType<typeof usePerfilTableQuery>
export type PerfilTableLazyQueryHookResult = ReturnType<typeof usePerfilTableLazyQuery>
export type PerfilTableQueryResult = ApolloReactCommon.QueryResult<
  Types.PerfilTableQuery,
  Types.PerfilTableQueryVariables
>
export const ResponderPesquisaSatisfacaoDocument = gql`
  mutation responderPesquisaSatisfacao($input: RespostaPesquisa!) {
    responderPesquisaSatisfacao(input: $input) {
      id
      mostrarPesquisaSatisfacao
    }
  }
`
export type ResponderPesquisaSatisfacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ResponderPesquisaSatisfacaoMutation,
  Types.ResponderPesquisaSatisfacaoMutationVariables
>

/**
 * __useResponderPesquisaSatisfacaoMutation__
 *
 * To run a mutation, you first call `useResponderPesquisaSatisfacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResponderPesquisaSatisfacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [responderPesquisaSatisfacaoMutation, { data, loading, error }] = useResponderPesquisaSatisfacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResponderPesquisaSatisfacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ResponderPesquisaSatisfacaoMutation,
    Types.ResponderPesquisaSatisfacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ResponderPesquisaSatisfacaoMutation,
    Types.ResponderPesquisaSatisfacaoMutationVariables
  >(ResponderPesquisaSatisfacaoDocument, baseOptions)
}
export type ResponderPesquisaSatisfacaoMutationHookResult = ReturnType<typeof useResponderPesquisaSatisfacaoMutation>
export type ResponderPesquisaSatisfacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.ResponderPesquisaSatisfacaoMutation
>
export type ResponderPesquisaSatisfacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ResponderPesquisaSatisfacaoMutation,
  Types.ResponderPesquisaSatisfacaoMutationVariables
>
export const ProfissionalEditDocument = gql`
  query ProfissionalEdit($id: ID!) {
    profissional(id: $id) {
      id
      nome
      numeroConselhoClasse
      sexo
      telefone
      cns
      cpf
      dataNascimento
      email
      conselhoClasse {
        id
        descricao
        sigla
      }
      ufEmissoraConselhoClasse {
        id
        nome
        sigla
      }
      endereco {
        bairro
        cep
        complemento
        logradouro
        numero
        pontoReferencia
        municipio {
          id
          ibge
          nome
          uf {
            id
            nome
            sigla
          }
        }
        semNumero
        tipoLogradouro {
          id
          nome
          numeroDne
        }
        uf {
          id
          nome
          sigla
        }
      }
      lotacoes {
        id
        ativo
      }
      estagios {
        id
        ativo
      }
    }
  }
`

/**
 * __useProfissionalEditQuery__
 *
 * To run a query within a React component, call `useProfissionalEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfissionalEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>(
    ProfissionalEditDocument,
    baseOptions
  )
}
export function useProfissionalEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>(
    ProfissionalEditDocument,
    baseOptions
  )
}
export type ProfissionalEditQueryHookResult = ReturnType<typeof useProfissionalEditQuery>
export type ProfissionalEditLazyQueryHookResult = ReturnType<typeof useProfissionalEditLazyQuery>
export type ProfissionalEditQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalEditQuery,
  Types.ProfissionalEditQueryVariables
>
export const SalvarProfissionalDocument = gql`
  mutation SalvarProfissional($input: ProfissionalInput!) {
    salvarProfissional(input: $input) {
      id
      nome
    }
  }
`
export type SalvarProfissionalMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarProfissionalMutation,
  Types.SalvarProfissionalMutationVariables
>

/**
 * __useSalvarProfissionalMutation__
 *
 * To run a mutation, you first call `useSalvarProfissionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarProfissionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarProfissionalMutation, { data, loading, error }] = useSalvarProfissionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarProfissionalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarProfissionalMutation,
    Types.SalvarProfissionalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarProfissionalMutation, Types.SalvarProfissionalMutationVariables>(
    SalvarProfissionalDocument,
    baseOptions
  )
}
export type SalvarProfissionalMutationHookResult = ReturnType<typeof useSalvarProfissionalMutation>
export type SalvarProfissionalMutationResult = ApolloReactCommon.MutationResult<Types.SalvarProfissionalMutation>
export type SalvarProfissionalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarProfissionalMutation,
  Types.SalvarProfissionalMutationVariables
>
export const ConfiguracaoAgendaOnlineLotacaoDocument = gql`
  query ConfiguracaoAgendaOnlineLotacao($lotacaoId: ID!) {
    lotacao(id: $lotacaoId) {
      id
      hasConfiguracaoAgendaOnline
      cbo {
        id
        nome
        cbo2002
      }
      equipe {
        id
        ine
        nome
      }
      unidadeSaude {
        id
        nome
      }
      profissional {
        id
        nome
      }
      gradeConfiguracaoAgendaOnline {
        horariosConsolidados
        configuracoesSemana {
          diaSemana
          configuracoes {
            horario
            ativo
          }
        }
      }
      actions {
        permissaoConfigurarAgendaOnline {
          enabled
        }
      }
    }
  }
`

/**
 * __useConfiguracaoAgendaOnlineLotacaoQuery__
 *
 * To run a query within a React component, call `useConfiguracaoAgendaOnlineLotacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoAgendaOnlineLotacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoAgendaOnlineLotacaoQuery({
 *   variables: {
 *      lotacaoId: // value for 'lotacaoId'
 *   },
 * });
 */
export function useConfiguracaoAgendaOnlineLotacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoAgendaOnlineLotacaoQuery,
    Types.ConfiguracaoAgendaOnlineLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ConfiguracaoAgendaOnlineLotacaoQuery,
    Types.ConfiguracaoAgendaOnlineLotacaoQueryVariables
  >(ConfiguracaoAgendaOnlineLotacaoDocument, baseOptions)
}
export function useConfiguracaoAgendaOnlineLotacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoAgendaOnlineLotacaoQuery,
    Types.ConfiguracaoAgendaOnlineLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoAgendaOnlineLotacaoQuery,
    Types.ConfiguracaoAgendaOnlineLotacaoQueryVariables
  >(ConfiguracaoAgendaOnlineLotacaoDocument, baseOptions)
}
export type ConfiguracaoAgendaOnlineLotacaoQueryHookResult = ReturnType<typeof useConfiguracaoAgendaOnlineLotacaoQuery>
export type ConfiguracaoAgendaOnlineLotacaoLazyQueryHookResult = ReturnType<
  typeof useConfiguracaoAgendaOnlineLotacaoLazyQuery
>
export type ConfiguracaoAgendaOnlineLotacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoAgendaOnlineLotacaoQuery,
  Types.ConfiguracaoAgendaOnlineLotacaoQueryVariables
>
export const SalvarConfiguracaoAgendaOnlineLotacaoDocument = gql`
  mutation SalvarConfiguracaoAgendaOnlineLotacao(
    $configuracaoAgendaOnlineLotacaoInput: ConfiguracaoAgendaOnlineLotacaoInput
  ) {
    salvarConfiguracaoAgendaOnlineLotacao(configuracaoAgendaOnlineLotacaoInput: $configuracaoAgendaOnlineLotacaoInput)
  }
`
export type SalvarConfiguracaoAgendaOnlineLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarConfiguracaoAgendaOnlineLotacaoMutation,
  Types.SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables
>

/**
 * __useSalvarConfiguracaoAgendaOnlineLotacaoMutation__
 *
 * To run a mutation, you first call `useSalvarConfiguracaoAgendaOnlineLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarConfiguracaoAgendaOnlineLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarConfiguracaoAgendaOnlineLotacaoMutation, { data, loading, error }] = useSalvarConfiguracaoAgendaOnlineLotacaoMutation({
 *   variables: {
 *      configuracaoAgendaOnlineLotacaoInput: // value for 'configuracaoAgendaOnlineLotacaoInput'
 *   },
 * });
 */
export function useSalvarConfiguracaoAgendaOnlineLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarConfiguracaoAgendaOnlineLotacaoMutation,
    Types.SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarConfiguracaoAgendaOnlineLotacaoMutation,
    Types.SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables
  >(SalvarConfiguracaoAgendaOnlineLotacaoDocument, baseOptions)
}
export type SalvarConfiguracaoAgendaOnlineLotacaoMutationHookResult = ReturnType<
  typeof useSalvarConfiguracaoAgendaOnlineLotacaoMutation
>
export type SalvarConfiguracaoAgendaOnlineLotacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarConfiguracaoAgendaOnlineLotacaoMutation
>
export type SalvarConfiguracaoAgendaOnlineLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarConfiguracaoAgendaOnlineLotacaoMutation,
  Types.SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables
>
export const ConfiguracaoAgendaLotacaoDocument = gql`
  query ConfiguracaoAgendaLotacao($lotacaoId: ID!) {
    lotacao(id: $lotacaoId) {
      id
      hasConfiguracaoAgenda
      hasConfiguracaoAgendaOnline
      hasAgendamentoFuturo
      cbo {
        id
        nome
        cbo2002
      }
      equipe {
        id
        ine
        nome
      }
      unidadeSaude {
        id
        nome
        cnes
      }
      profissional {
        id
        nome
      }
      configuracaoAgenda {
        configuracaoHorarioAgenda {
          configuracaoDiasSemana {
            diaSemana
            periodos {
              periodo
              horarioInicial
              horarioFinal
            }
          }
        }
      }
      actions {
        permissaoConfigurarAgenda {
          enabled
        }
        permissaoFecharAgenda {
          enabled
        }
      }
    }
    conexao {
      agendaOnline {
        ativa
      }
    }
    duracaoAtendimentoPadraoMunicipio {
      duracaoAtendimentoPadraoMunicipio
    }
    configuracaoHorariosMunicipio {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`

/**
 * __useConfiguracaoAgendaLotacaoQuery__
 *
 * To run a query within a React component, call `useConfiguracaoAgendaLotacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoAgendaLotacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoAgendaLotacaoQuery({
 *   variables: {
 *      lotacaoId: // value for 'lotacaoId'
 *   },
 * });
 */
export function useConfiguracaoAgendaLotacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoAgendaLotacaoQuery,
    Types.ConfiguracaoAgendaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoAgendaLotacaoQuery, Types.ConfiguracaoAgendaLotacaoQueryVariables>(
    ConfiguracaoAgendaLotacaoDocument,
    baseOptions
  )
}
export function useConfiguracaoAgendaLotacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoAgendaLotacaoQuery,
    Types.ConfiguracaoAgendaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoAgendaLotacaoQuery,
    Types.ConfiguracaoAgendaLotacaoQueryVariables
  >(ConfiguracaoAgendaLotacaoDocument, baseOptions)
}
export type ConfiguracaoAgendaLotacaoQueryHookResult = ReturnType<typeof useConfiguracaoAgendaLotacaoQuery>
export type ConfiguracaoAgendaLotacaoLazyQueryHookResult = ReturnType<typeof useConfiguracaoAgendaLotacaoLazyQuery>
export type ConfiguracaoAgendaLotacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoAgendaLotacaoQuery,
  Types.ConfiguracaoAgendaLotacaoQueryVariables
>
export const DesativarConfiguracaoAgendaOnlineLotacaoDocument = gql`
  mutation DesativarConfiguracaoAgendaOnlineLotacao($id: ID!) {
    desativarConfiguracaoAgendaOnlineLotacao(id: $id)
  }
`
export type DesativarConfiguracaoAgendaOnlineLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.DesativarConfiguracaoAgendaOnlineLotacaoMutation,
  Types.DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables
>

/**
 * __useDesativarConfiguracaoAgendaOnlineLotacaoMutation__
 *
 * To run a mutation, you first call `useDesativarConfiguracaoAgendaOnlineLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesativarConfiguracaoAgendaOnlineLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desativarConfiguracaoAgendaOnlineLotacaoMutation, { data, loading, error }] = useDesativarConfiguracaoAgendaOnlineLotacaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDesativarConfiguracaoAgendaOnlineLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DesativarConfiguracaoAgendaOnlineLotacaoMutation,
    Types.DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.DesativarConfiguracaoAgendaOnlineLotacaoMutation,
    Types.DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables
  >(DesativarConfiguracaoAgendaOnlineLotacaoDocument, baseOptions)
}
export type DesativarConfiguracaoAgendaOnlineLotacaoMutationHookResult = ReturnType<
  typeof useDesativarConfiguracaoAgendaOnlineLotacaoMutation
>
export type DesativarConfiguracaoAgendaOnlineLotacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.DesativarConfiguracaoAgendaOnlineLotacaoMutation
>
export type DesativarConfiguracaoAgendaOnlineLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DesativarConfiguracaoAgendaOnlineLotacaoMutation,
  Types.DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables
>
export const ExcluirConfiguracaoAgendaLotacaoDocument = gql`
  mutation ExcluirConfiguracaoAgendaLotacao($id: ID!) {
    excluirConfiguracaoAgendaLotacao(id: $id)
  }
`
export type ExcluirConfiguracaoAgendaLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirConfiguracaoAgendaLotacaoMutation,
  Types.ExcluirConfiguracaoAgendaLotacaoMutationVariables
>

/**
 * __useExcluirConfiguracaoAgendaLotacaoMutation__
 *
 * To run a mutation, you first call `useExcluirConfiguracaoAgendaLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirConfiguracaoAgendaLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirConfiguracaoAgendaLotacaoMutation, { data, loading, error }] = useExcluirConfiguracaoAgendaLotacaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirConfiguracaoAgendaLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirConfiguracaoAgendaLotacaoMutation,
    Types.ExcluirConfiguracaoAgendaLotacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirConfiguracaoAgendaLotacaoMutation,
    Types.ExcluirConfiguracaoAgendaLotacaoMutationVariables
  >(ExcluirConfiguracaoAgendaLotacaoDocument, baseOptions)
}
export type ExcluirConfiguracaoAgendaLotacaoMutationHookResult = ReturnType<
  typeof useExcluirConfiguracaoAgendaLotacaoMutation
>
export type ExcluirConfiguracaoAgendaLotacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirConfiguracaoAgendaLotacaoMutation
>
export type ExcluirConfiguracaoAgendaLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirConfiguracaoAgendaLotacaoMutation,
  Types.ExcluirConfiguracaoAgendaLotacaoMutationVariables
>
export const SalvarConfiguracaoAgendaLotacaoDocument = gql`
  mutation SalvarConfiguracaoAgendaLotacao($configuracaoAgendaLotacaoInput: ConfiguracaoAgendaLotacaoInput) {
    salvarConfiguracaoAgendaLotacao(configuracaoAgendaLotacaoInput: $configuracaoAgendaLotacaoInput)
  }
`
export type SalvarConfiguracaoAgendaLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarConfiguracaoAgendaLotacaoMutation,
  Types.SalvarConfiguracaoAgendaLotacaoMutationVariables
>

/**
 * __useSalvarConfiguracaoAgendaLotacaoMutation__
 *
 * To run a mutation, you first call `useSalvarConfiguracaoAgendaLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarConfiguracaoAgendaLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarConfiguracaoAgendaLotacaoMutation, { data, loading, error }] = useSalvarConfiguracaoAgendaLotacaoMutation({
 *   variables: {
 *      configuracaoAgendaLotacaoInput: // value for 'configuracaoAgendaLotacaoInput'
 *   },
 * });
 */
export function useSalvarConfiguracaoAgendaLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarConfiguracaoAgendaLotacaoMutation,
    Types.SalvarConfiguracaoAgendaLotacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarConfiguracaoAgendaLotacaoMutation,
    Types.SalvarConfiguracaoAgendaLotacaoMutationVariables
  >(SalvarConfiguracaoAgendaLotacaoDocument, baseOptions)
}
export type SalvarConfiguracaoAgendaLotacaoMutationHookResult = ReturnType<
  typeof useSalvarConfiguracaoAgendaLotacaoMutation
>
export type SalvarConfiguracaoAgendaLotacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarConfiguracaoAgendaLotacaoMutation
>
export type SalvarConfiguracaoAgendaLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarConfiguracaoAgendaLotacaoMutation,
  Types.SalvarConfiguracaoAgendaLotacaoMutationVariables
>
export const ExcluirFechamentoAgendaDocument = gql`
  mutation ExcluirFechamentoAgenda($id: ID!) {
    excluirFechamento(id: $id)
  }
`
export type ExcluirFechamentoAgendaMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirFechamentoAgendaMutation,
  Types.ExcluirFechamentoAgendaMutationVariables
>

/**
 * __useExcluirFechamentoAgendaMutation__
 *
 * To run a mutation, you first call `useExcluirFechamentoAgendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirFechamentoAgendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirFechamentoAgendaMutation, { data, loading, error }] = useExcluirFechamentoAgendaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirFechamentoAgendaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirFechamentoAgendaMutation,
    Types.ExcluirFechamentoAgendaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirFechamentoAgendaMutation,
    Types.ExcluirFechamentoAgendaMutationVariables
  >(ExcluirFechamentoAgendaDocument, baseOptions)
}
export type ExcluirFechamentoAgendaMutationHookResult = ReturnType<typeof useExcluirFechamentoAgendaMutation>
export type ExcluirFechamentoAgendaMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirFechamentoAgendaMutation
>
export type ExcluirFechamentoAgendaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirFechamentoAgendaMutation,
  Types.ExcluirFechamentoAgendaMutationVariables
>
export const FechamentoAgendaTableDocument = gql`
  query FechamentoAgendaTable($input: FechamentoAgendaQueryInput!) {
    fechamentos(input: $input) {
      content {
        id
        dataInicial
        dataFinal
        motivo
        especifique
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useFechamentoAgendaTableQuery__
 *
 * To run a query within a React component, call `useFechamentoAgendaTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFechamentoAgendaTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFechamentoAgendaTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFechamentoAgendaTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.FechamentoAgendaTableQuery,
    Types.FechamentoAgendaTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.FechamentoAgendaTableQuery, Types.FechamentoAgendaTableQueryVariables>(
    FechamentoAgendaTableDocument,
    baseOptions
  )
}
export function useFechamentoAgendaTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.FechamentoAgendaTableQuery,
    Types.FechamentoAgendaTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.FechamentoAgendaTableQuery, Types.FechamentoAgendaTableQueryVariables>(
    FechamentoAgendaTableDocument,
    baseOptions
  )
}
export type FechamentoAgendaTableQueryHookResult = ReturnType<typeof useFechamentoAgendaTableQuery>
export type FechamentoAgendaTableLazyQueryHookResult = ReturnType<typeof useFechamentoAgendaTableLazyQuery>
export type FechamentoAgendaTableQueryResult = ApolloReactCommon.QueryResult<
  Types.FechamentoAgendaTableQuery,
  Types.FechamentoAgendaTableQueryVariables
>
export const SalvarFechamentoAgendaDocument = gql`
  mutation SalvarFechamentoAgenda($input: FechamentoAgendaInput!) {
    salvarFechamento(input: $input) {
      id
    }
  }
`
export type SalvarFechamentoAgendaMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarFechamentoAgendaMutation,
  Types.SalvarFechamentoAgendaMutationVariables
>

/**
 * __useSalvarFechamentoAgendaMutation__
 *
 * To run a mutation, you first call `useSalvarFechamentoAgendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarFechamentoAgendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarFechamentoAgendaMutation, { data, loading, error }] = useSalvarFechamentoAgendaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarFechamentoAgendaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarFechamentoAgendaMutation,
    Types.SalvarFechamentoAgendaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarFechamentoAgendaMutation,
    Types.SalvarFechamentoAgendaMutationVariables
  >(SalvarFechamentoAgendaDocument, baseOptions)
}
export type SalvarFechamentoAgendaMutationHookResult = ReturnType<typeof useSalvarFechamentoAgendaMutation>
export type SalvarFechamentoAgendaMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarFechamentoAgendaMutation
>
export type SalvarFechamentoAgendaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarFechamentoAgendaMutation,
  Types.SalvarFechamentoAgendaMutationVariables
>
export const ProfissionalAcessosDocument = gql`
  query ProfissionalAcessos($profissionalId: ID!) {
    profissional(id: $profissionalId) {
      id
      cns
      acessos(input: { mostrarInativos: true }) {
        id
        tipo
        ativo
        perfis {
          id
          nome
          ativo
        }
        actions {
          permissaoEdicao {
            enabled
            hint
          }
          permissaoExclusao {
            enabled
            hint
          }
          permissaoAtivacaoInativacao {
            enabled
            hint
          }
          ... on LotacaoActions {
            permissaoConfigurarAgenda {
              enabled
              hint
            }
            permissaoConfigurarAgendaOnline {
              enabled
              hint
            }
            permissaoFecharAgenda {
              enabled
              hint
            }
          }
        }
        ... on AdministradorMunicipal {
          municipio {
            id
            nome
          }
        }
        ... on GestorEstadual {
          uf {
            id
            nome
          }
        }
        ... on GestorMunicipal {
          municipio {
            id
            nome
          }
        }
        ... on Lotacao {
          importada
          hasConfiguracaoAgenda
          hasConfiguracaoAgendaOnline
          hasAgendamentoFuturo
          cbo {
            id
            nome
            cbo2002
          }
          equipe {
            id
            nome
            ine
            tipoEquipe {
              id
              descricao
            }
          }
          unidadeSaude {
            id
            nome
          }
        }
        ... on Estagio {
          nivelTecnico
          dataFimVigencia
          instituicaoEnsino
          dataInicioVigencia
          lotacaoSupervisora {
            id
            profissional {
              id
              nome
            }
            cbo {
              id
              nome
              cbo2002
            }
          }
          cbo {
            id
            nome
            cbo2002
          }
          equipe {
            id
            nome
            ine
            tipoEquipe {
              id
              descricao
            }
          }
          unidadeSaude {
            id
            nome
          }
        }
      }
    }
  }
`

/**
 * __useProfissionalAcessosQuery__
 *
 * To run a query within a React component, call `useProfissionalAcessosQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalAcessosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalAcessosQuery({
 *   variables: {
 *      profissionalId: // value for 'profissionalId'
 *   },
 * });
 */
export function useProfissionalAcessosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionalAcessosQuery,
    Types.ProfissionalAcessosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalAcessosQuery, Types.ProfissionalAcessosQueryVariables>(
    ProfissionalAcessosDocument,
    baseOptions
  )
}
export function useProfissionalAcessosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalAcessosQuery,
    Types.ProfissionalAcessosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalAcessosQuery, Types.ProfissionalAcessosQueryVariables>(
    ProfissionalAcessosDocument,
    baseOptions
  )
}
export type ProfissionalAcessosQueryHookResult = ReturnType<typeof useProfissionalAcessosQuery>
export type ProfissionalAcessosLazyQueryHookResult = ReturnType<typeof useProfissionalAcessosLazyQuery>
export type ProfissionalAcessosQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalAcessosQuery,
  Types.ProfissionalAcessosQueryVariables
>
export const ProfissionalDetailDocument = gql`
  query profissionalDetail($id: ID!) {
    profissional(id: $id) {
      lotacoes {
        ativo
        cbo {
          id
          nome
          cbo2002
        }
        equipe {
          id
          ine
          nome
          tipoEquipe {
            id
            nome
            sigla
          }
        }
        id
        unidadeSaude {
          id
          nome
          cnes
        }
      }
      ...ProfissionalDetail
    }
  }
  ${ProfissionalDetailFragmentDoc}
`

/**
 * __useProfissionalDetailQuery__
 *
 * To run a query within a React component, call `useProfissionalDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfissionalDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalDetailQuery, Types.ProfissionalDetailQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalDetailQuery, Types.ProfissionalDetailQueryVariables>(
    ProfissionalDetailDocument,
    baseOptions
  )
}
export function useProfissionalDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalDetailQuery,
    Types.ProfissionalDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalDetailQuery, Types.ProfissionalDetailQueryVariables>(
    ProfissionalDetailDocument,
    baseOptions
  )
}
export type ProfissionalDetailQueryHookResult = ReturnType<typeof useProfissionalDetailQuery>
export type ProfissionalDetailLazyQueryHookResult = ReturnType<typeof useProfissionalDetailLazyQuery>
export type ProfissionalDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalDetailQuery,
  Types.ProfissionalDetailQueryVariables
>
export const AcessoCreateDocument = gql`
  query AcessoCreate($profissionalId: ID!) {
    profissional(id: $profissionalId) {
      id
      nome
    }
  }
`

/**
 * __useAcessoCreateQuery__
 *
 * To run a query within a React component, call `useAcessoCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoCreateQuery({
 *   variables: {
 *      profissionalId: // value for 'profissionalId'
 *   },
 * });
 */
export function useAcessoCreateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AcessoCreateQuery, Types.AcessoCreateQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AcessoCreateQuery, Types.AcessoCreateQueryVariables>(
    AcessoCreateDocument,
    baseOptions
  )
}
export function useAcessoCreateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AcessoCreateQuery, Types.AcessoCreateQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AcessoCreateQuery, Types.AcessoCreateQueryVariables>(
    AcessoCreateDocument,
    baseOptions
  )
}
export type AcessoCreateQueryHookResult = ReturnType<typeof useAcessoCreateQuery>
export type AcessoCreateLazyQueryHookResult = ReturnType<typeof useAcessoCreateLazyQuery>
export type AcessoCreateQueryResult = ApolloReactCommon.QueryResult<
  Types.AcessoCreateQuery,
  Types.AcessoCreateQueryVariables
>
export const AcessoEditDocument = gql`
  query AcessoEdit($acessoId: ID!, $profissionalId: ID!) {
    acesso(id: $acessoId) {
      tipo
      actions {
        permissaoEdicao {
          enabled
        }
      }
      perfis {
        id
        nome
        ativo
      }
      ... on GestorEstadual {
        uf {
          id
          nome
          sigla
        }
      }
      ... on GestorMunicipal {
        municipio {
          id
          nome
          uf {
            id
            nome
            sigla
          }
        }
      }
    }
    profissional(id: $profissionalId) {
      id
      nome
    }
  }
`

/**
 * __useAcessoEditQuery__
 *
 * To run a query within a React component, call `useAcessoEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoEditQuery({
 *   variables: {
 *      acessoId: // value for 'acessoId'
 *      profissionalId: // value for 'profissionalId'
 *   },
 * });
 */
export function useAcessoEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>(
    AcessoEditDocument,
    baseOptions
  )
}
export function useAcessoEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>(
    AcessoEditDocument,
    baseOptions
  )
}
export type AcessoEditQueryHookResult = ReturnType<typeof useAcessoEditQuery>
export type AcessoEditLazyQueryHookResult = ReturnType<typeof useAcessoEditLazyQuery>
export type AcessoEditQueryResult = ApolloReactCommon.QueryResult<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>
export const SalvarAcessoDocument = gql`
  mutation SalvarAcesso($input: OutroAcessoInput!) {
    salvarOutroAcesso(input: $input) {
      id
    }
  }
`
export type SalvarAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarAcessoMutation,
  Types.SalvarAcessoMutationVariables
>

/**
 * __useSalvarAcessoMutation__
 *
 * To run a mutation, you first call `useSalvarAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarAcessoMutation, { data, loading, error }] = useSalvarAcessoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SalvarAcessoMutation, Types.SalvarAcessoMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.SalvarAcessoMutation, Types.SalvarAcessoMutationVariables>(
    SalvarAcessoDocument,
    baseOptions
  )
}
export type SalvarAcessoMutationHookResult = ReturnType<typeof useSalvarAcessoMutation>
export type SalvarAcessoMutationResult = ApolloReactCommon.MutationResult<Types.SalvarAcessoMutation>
export type SalvarAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarAcessoMutation,
  Types.SalvarAcessoMutationVariables
>
export const AgendaEmConflitoDocument = gql`
  query AgendaEmConflito($id: ID!) {
    agendaEmConflito(idLotacao: $id)
  }
`

/**
 * __useAgendaEmConflitoQuery__
 *
 * To run a query within a React component, call `useAgendaEmConflitoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendaEmConflitoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendaEmConflitoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgendaEmConflitoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>(
    AgendaEmConflitoDocument,
    baseOptions
  )
}
export function useAgendaEmConflitoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>(
    AgendaEmConflitoDocument,
    baseOptions
  )
}
export type AgendaEmConflitoQueryHookResult = ReturnType<typeof useAgendaEmConflitoQuery>
export type AgendaEmConflitoLazyQueryHookResult = ReturnType<typeof useAgendaEmConflitoLazyQuery>
export type AgendaEmConflitoQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendaEmConflitoQuery,
  Types.AgendaEmConflitoQueryVariables
>
export const ExcluirLotacaoDocument = gql`
  mutation ExcluirLotacao($lotacaoId: ID!) {
    excluirLotacao(lotacaoId: $lotacaoId)
  }
`
export type ExcluirLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirLotacaoMutation,
  Types.ExcluirLotacaoMutationVariables
>

/**
 * __useExcluirLotacaoMutation__
 *
 * To run a mutation, you first call `useExcluirLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirLotacaoMutation, { data, loading, error }] = useExcluirLotacaoMutation({
 *   variables: {
 *      lotacaoId: // value for 'lotacaoId'
 *   },
 * });
 */
export function useExcluirLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirLotacaoMutation,
    Types.ExcluirLotacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ExcluirLotacaoMutation, Types.ExcluirLotacaoMutationVariables>(
    ExcluirLotacaoDocument,
    baseOptions
  )
}
export type ExcluirLotacaoMutationHookResult = ReturnType<typeof useExcluirLotacaoMutation>
export type ExcluirLotacaoMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirLotacaoMutation>
export type ExcluirLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirLotacaoMutation,
  Types.ExcluirLotacaoMutationVariables
>
export const ProfissionaisLotacoesSectionDocument = gql`
  query ProfissionaisLotacoesSection {
    conexao {
      agendaOnline {
        ativa
      }
    }
  }
`

/**
 * __useProfissionaisLotacoesSectionQuery__
 *
 * To run a query within a React component, call `useProfissionaisLotacoesSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionaisLotacoesSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionaisLotacoesSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfissionaisLotacoesSectionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionaisLotacoesSectionQuery,
    Types.ProfissionaisLotacoesSectionQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProfissionaisLotacoesSectionQuery,
    Types.ProfissionaisLotacoesSectionQueryVariables
  >(ProfissionaisLotacoesSectionDocument, baseOptions)
}
export function useProfissionaisLotacoesSectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionaisLotacoesSectionQuery,
    Types.ProfissionaisLotacoesSectionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProfissionaisLotacoesSectionQuery,
    Types.ProfissionaisLotacoesSectionQueryVariables
  >(ProfissionaisLotacoesSectionDocument, baseOptions)
}
export type ProfissionaisLotacoesSectionQueryHookResult = ReturnType<typeof useProfissionaisLotacoesSectionQuery>
export type ProfissionaisLotacoesSectionLazyQueryHookResult = ReturnType<
  typeof useProfissionaisLotacoesSectionLazyQuery
>
export type ProfissionaisLotacoesSectionQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionaisLotacoesSectionQuery,
  Types.ProfissionaisLotacoesSectionQueryVariables
>
export const AlterarLotacaoDocument = gql`
  mutation AlterarLotacao($input: AlterarLotacaoInput!) {
    alterarLotacao(input: $input) {
      id
    }
  }
`
export type AlterarLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarLotacaoMutation,
  Types.AlterarLotacaoMutationVariables
>

/**
 * __useAlterarLotacaoMutation__
 *
 * To run a mutation, you first call `useAlterarLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarLotacaoMutation, { data, loading, error }] = useAlterarLotacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarLotacaoMutation,
    Types.AlterarLotacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarLotacaoMutation, Types.AlterarLotacaoMutationVariables>(
    AlterarLotacaoDocument,
    baseOptions
  )
}
export type AlterarLotacaoMutationHookResult = ReturnType<typeof useAlterarLotacaoMutation>
export type AlterarLotacaoMutationResult = ApolloReactCommon.MutationResult<Types.AlterarLotacaoMutation>
export type AlterarLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarLotacaoMutation,
  Types.AlterarLotacaoMutationVariables
>
export const CriarLotacaoDocument = gql`
  mutation CriarLotacao($input: LotacaoInput!) {
    criarLotacao(input: $input) {
      id
    }
  }
`
export type CriarLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.CriarLotacaoMutation,
  Types.CriarLotacaoMutationVariables
>

/**
 * __useCriarLotacaoMutation__
 *
 * To run a mutation, you first call `useCriarLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCriarLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [criarLotacaoMutation, { data, loading, error }] = useCriarLotacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCriarLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CriarLotacaoMutation, Types.CriarLotacaoMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.CriarLotacaoMutation, Types.CriarLotacaoMutationVariables>(
    CriarLotacaoDocument,
    baseOptions
  )
}
export type CriarLotacaoMutationHookResult = ReturnType<typeof useCriarLotacaoMutation>
export type CriarLotacaoMutationResult = ApolloReactCommon.MutationResult<Types.CriarLotacaoMutation>
export type CriarLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CriarLotacaoMutation,
  Types.CriarLotacaoMutationVariables
>
export const LotacaoCreateDocument = gql`
  query LotacaoCreate($profissionalId: ID!) {
    profissional(id: $profissionalId) {
      id
      nome
    }
  }
`

/**
 * __useLotacaoCreateQuery__
 *
 * To run a query within a React component, call `useLotacaoCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoCreateQuery({
 *   variables: {
 *      profissionalId: // value for 'profissionalId'
 *   },
 * });
 */
export function useLotacaoCreateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LotacaoCreateQuery, Types.LotacaoCreateQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LotacaoCreateQuery, Types.LotacaoCreateQueryVariables>(
    LotacaoCreateDocument,
    baseOptions
  )
}
export function useLotacaoCreateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LotacaoCreateQuery, Types.LotacaoCreateQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LotacaoCreateQuery, Types.LotacaoCreateQueryVariables>(
    LotacaoCreateDocument,
    baseOptions
  )
}
export type LotacaoCreateQueryHookResult = ReturnType<typeof useLotacaoCreateQuery>
export type LotacaoCreateLazyQueryHookResult = ReturnType<typeof useLotacaoCreateLazyQuery>
export type LotacaoCreateQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoCreateQuery,
  Types.LotacaoCreateQueryVariables
>
export const LotacaoEditViewDocument = gql`
  query LotacaoEditView($id: ID!) {
    lotacao(id: $id) {
      id
      importada
      hasConfiguracaoAgenda
      atualizaPerfil
      ativo
      profissional {
        id
        nome
      }
      cbo {
        id
        nome
        cbo2002
      }
      equipe {
        id
        nome
        ine
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      perfis {
        id
        nome
        ativo
      }
      unidadeSaude {
        id
        nome
        cnes
      }
      actions {
        permissaoEdicao {
          enabled
        }
      }
    }
  }
`

/**
 * __useLotacaoEditViewQuery__
 *
 * To run a query within a React component, call `useLotacaoEditViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoEditViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoEditViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLotacaoEditViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>(
    LotacaoEditViewDocument,
    baseOptions
  )
}
export function useLotacaoEditViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>(
    LotacaoEditViewDocument,
    baseOptions
  )
}
export type LotacaoEditViewQueryHookResult = ReturnType<typeof useLotacaoEditViewQuery>
export type LotacaoEditViewLazyQueryHookResult = ReturnType<typeof useLotacaoEditViewLazyQuery>
export type LotacaoEditViewQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoEditViewQuery,
  Types.LotacaoEditViewQueryVariables
>
export const AlterarAtivacaoAcessoDocument = gql`
  mutation AlterarAtivacaoAcesso($id: ID!) {
    alterarAtivacaoAcesso(id: $id)
  }
`
export type AlterarAtivacaoAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarAtivacaoAcessoMutation,
  Types.AlterarAtivacaoAcessoMutationVariables
>

/**
 * __useAlterarAtivacaoAcessoMutation__
 *
 * To run a mutation, you first call `useAlterarAtivacaoAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarAtivacaoAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarAtivacaoAcessoMutation, { data, loading, error }] = useAlterarAtivacaoAcessoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAlterarAtivacaoAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarAtivacaoAcessoMutation,
    Types.AlterarAtivacaoAcessoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarAtivacaoAcessoMutation,
    Types.AlterarAtivacaoAcessoMutationVariables
  >(AlterarAtivacaoAcessoDocument, baseOptions)
}
export type AlterarAtivacaoAcessoMutationHookResult = ReturnType<typeof useAlterarAtivacaoAcessoMutation>
export type AlterarAtivacaoAcessoMutationResult = ApolloReactCommon.MutationResult<Types.AlterarAtivacaoAcessoMutation>
export type AlterarAtivacaoAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarAtivacaoAcessoMutation,
  Types.AlterarAtivacaoAcessoMutationVariables
>
export const ExcluirAcessoDocument = gql`
  mutation ExcluirAcesso($id: ID!) {
    excluirAcesso(id: $id)
  }
`
export type ExcluirAcessoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirAcessoMutation,
  Types.ExcluirAcessoMutationVariables
>

/**
 * __useExcluirAcessoMutation__
 *
 * To run a mutation, you first call `useExcluirAcessoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirAcessoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirAcessoMutation, { data, loading, error }] = useExcluirAcessoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirAcessoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ExcluirAcessoMutation, Types.ExcluirAcessoMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.ExcluirAcessoMutation, Types.ExcluirAcessoMutationVariables>(
    ExcluirAcessoDocument,
    baseOptions
  )
}
export type ExcluirAcessoMutationHookResult = ReturnType<typeof useExcluirAcessoMutation>
export type ExcluirAcessoMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirAcessoMutation>
export type ExcluirAcessoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirAcessoMutation,
  Types.ExcluirAcessoMutationVariables
>
export const DefinirSenhaProvisoriaDocument = gql`
  mutation DefinirSenhaProvisoria($input: DefinirSenhaProvisoriaInput!) {
    definirSenhaProvisoria(input: $input)
  }
`
export type DefinirSenhaProvisoriaMutationFn = ApolloReactCommon.MutationFunction<
  Types.DefinirSenhaProvisoriaMutation,
  Types.DefinirSenhaProvisoriaMutationVariables
>

/**
 * __useDefinirSenhaProvisoriaMutation__
 *
 * To run a mutation, you first call `useDefinirSenhaProvisoriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDefinirSenhaProvisoriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [definirSenhaProvisoriaMutation, { data, loading, error }] = useDefinirSenhaProvisoriaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDefinirSenhaProvisoriaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DefinirSenhaProvisoriaMutation,
    Types.DefinirSenhaProvisoriaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.DefinirSenhaProvisoriaMutation,
    Types.DefinirSenhaProvisoriaMutationVariables
  >(DefinirSenhaProvisoriaDocument, baseOptions)
}
export type DefinirSenhaProvisoriaMutationHookResult = ReturnType<typeof useDefinirSenhaProvisoriaMutation>
export type DefinirSenhaProvisoriaMutationResult = ApolloReactCommon.MutationResult<
  Types.DefinirSenhaProvisoriaMutation
>
export type DefinirSenhaProvisoriaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DefinirSenhaProvisoriaMutation,
  Types.DefinirSenhaProvisoriaMutationVariables
>
export const ExcluirProfissionalDocument = gql`
  mutation ExcluirProfissional($profissionalId: ID!) {
    excluirProfissional(profissionalId: $profissionalId)
  }
`
export type ExcluirProfissionalMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirProfissionalMutation,
  Types.ExcluirProfissionalMutationVariables
>

/**
 * __useExcluirProfissionalMutation__
 *
 * To run a mutation, you first call `useExcluirProfissionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirProfissionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirProfissionalMutation, { data, loading, error }] = useExcluirProfissionalMutation({
 *   variables: {
 *      profissionalId: // value for 'profissionalId'
 *   },
 * });
 */
export function useExcluirProfissionalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirProfissionalMutation,
    Types.ExcluirProfissionalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ExcluirProfissionalMutation, Types.ExcluirProfissionalMutationVariables>(
    ExcluirProfissionalDocument,
    baseOptions
  )
}
export type ExcluirProfissionalMutationHookResult = ReturnType<typeof useExcluirProfissionalMutation>
export type ExcluirProfissionalMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirProfissionalMutation>
export type ExcluirProfissionalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirProfissionalMutation,
  Types.ExcluirProfissionalMutationVariables
>
export const ProfissionalTableDocument = gql`
  query ProfissionalTable($input: ProfissionaisQueryInput!) {
    profissionais(input: $input) {
      content {
        id
        nome
        cpf
        cns
        lotacoes {
          id
          ativo
          cbo {
            id
            nome
          }
        }
        estagios {
          id
          cbo {
            id
            nome
          }
          ativo
        }
        usuario {
          id
          bloqueado
        }
        actions {
          redefinirSenha {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useProfissionalTableQuery__
 *
 * To run a query within a React component, call `useProfissionalTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfissionalTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalTableQuery, Types.ProfissionalTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalTableQuery, Types.ProfissionalTableQueryVariables>(
    ProfissionalTableDocument,
    baseOptions
  )
}
export function useProfissionalTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalTableQuery,
    Types.ProfissionalTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalTableQuery, Types.ProfissionalTableQueryVariables>(
    ProfissionalTableDocument,
    baseOptions
  )
}
export type ProfissionalTableQueryHookResult = ReturnType<typeof useProfissionalTableQuery>
export type ProfissionalTableLazyQueryHookResult = ReturnType<typeof useProfissionalTableLazyQuery>
export type ProfissionalTableQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalTableQuery,
  Types.ProfissionalTableQueryVariables
>
export const SolicitarRedefinicaoSenhaDocument = gql`
  mutation SolicitarRedefinicaoSenha($input: SolicitarRecuperacaoSenhaInput!) {
    solicitarRedefinicaoSenha(input: $input) {
      value
      base64PngImage
    }
  }
`
export type SolicitarRedefinicaoSenhaMutationFn = ApolloReactCommon.MutationFunction<
  Types.SolicitarRedefinicaoSenhaMutation,
  Types.SolicitarRedefinicaoSenhaMutationVariables
>

/**
 * __useSolicitarRedefinicaoSenhaMutation__
 *
 * To run a mutation, you first call `useSolicitarRedefinicaoSenhaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolicitarRedefinicaoSenhaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solicitarRedefinicaoSenhaMutation, { data, loading, error }] = useSolicitarRedefinicaoSenhaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolicitarRedefinicaoSenhaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SolicitarRedefinicaoSenhaMutation,
    Types.SolicitarRedefinicaoSenhaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SolicitarRedefinicaoSenhaMutation,
    Types.SolicitarRedefinicaoSenhaMutationVariables
  >(SolicitarRedefinicaoSenhaDocument, baseOptions)
}
export type SolicitarRedefinicaoSenhaMutationHookResult = ReturnType<typeof useSolicitarRedefinicaoSenhaMutation>
export type SolicitarRedefinicaoSenhaMutationResult = ApolloReactCommon.MutationResult<
  Types.SolicitarRedefinicaoSenhaMutation
>
export type SolicitarRedefinicaoSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SolicitarRedefinicaoSenhaMutation,
  Types.SolicitarRedefinicaoSenhaMutationVariables
>
export const SalvarJustificativaVisualizarProntuarioDocument = gql`
  mutation SalvarJustificativaVisualizarProntuario($input: JustificativaVisualizarProntuarioInput!) {
    salvarJustificativaVisualizarProntuario(input: $input)
  }
`
export type SalvarJustificativaVisualizarProntuarioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarJustificativaVisualizarProntuarioMutation,
  Types.SalvarJustificativaVisualizarProntuarioMutationVariables
>

/**
 * __useSalvarJustificativaVisualizarProntuarioMutation__
 *
 * To run a mutation, you first call `useSalvarJustificativaVisualizarProntuarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarJustificativaVisualizarProntuarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarJustificativaVisualizarProntuarioMutation, { data, loading, error }] = useSalvarJustificativaVisualizarProntuarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarJustificativaVisualizarProntuarioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarJustificativaVisualizarProntuarioMutation,
    Types.SalvarJustificativaVisualizarProntuarioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarJustificativaVisualizarProntuarioMutation,
    Types.SalvarJustificativaVisualizarProntuarioMutationVariables
  >(SalvarJustificativaVisualizarProntuarioDocument, baseOptions)
}
export type SalvarJustificativaVisualizarProntuarioMutationHookResult = ReturnType<
  typeof useSalvarJustificativaVisualizarProntuarioMutation
>
export type SalvarJustificativaVisualizarProntuarioMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarJustificativaVisualizarProntuarioMutation
>
export type SalvarJustificativaVisualizarProntuarioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarJustificativaVisualizarProntuarioMutation,
  Types.SalvarJustificativaVisualizarProntuarioMutationVariables
>
export const RecuperarSenhaBloqueadoDocument = gql`
  query RecuperarSenhaBloqueado {
    recuperarSenhaBloqueado
  }
`

/**
 * __useRecuperarSenhaBloqueadoQuery__
 *
 * To run a query within a React component, call `useRecuperarSenhaBloqueadoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecuperarSenhaBloqueadoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecuperarSenhaBloqueadoQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecuperarSenhaBloqueadoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.RecuperarSenhaBloqueadoQuery,
    Types.RecuperarSenhaBloqueadoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.RecuperarSenhaBloqueadoQuery, Types.RecuperarSenhaBloqueadoQueryVariables>(
    RecuperarSenhaBloqueadoDocument,
    baseOptions
  )
}
export function useRecuperarSenhaBloqueadoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.RecuperarSenhaBloqueadoQuery,
    Types.RecuperarSenhaBloqueadoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.RecuperarSenhaBloqueadoQuery, Types.RecuperarSenhaBloqueadoQueryVariables>(
    RecuperarSenhaBloqueadoDocument,
    baseOptions
  )
}
export type RecuperarSenhaBloqueadoQueryHookResult = ReturnType<typeof useRecuperarSenhaBloqueadoQuery>
export type RecuperarSenhaBloqueadoLazyQueryHookResult = ReturnType<typeof useRecuperarSenhaBloqueadoLazyQuery>
export type RecuperarSenhaBloqueadoQueryResult = ApolloReactCommon.QueryResult<
  Types.RecuperarSenhaBloqueadoQuery,
  Types.RecuperarSenhaBloqueadoQueryVariables
>
export const RedefinirSenhaDocument = gql`
  mutation RedefinirSenha($input: RedefinirSenhaInput!) {
    redefinirSenha(input: $input)
  }
`
export type RedefinirSenhaMutationFn = ApolloReactCommon.MutationFunction<
  Types.RedefinirSenhaMutation,
  Types.RedefinirSenhaMutationVariables
>

/**
 * __useRedefinirSenhaMutation__
 *
 * To run a mutation, you first call `useRedefinirSenhaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedefinirSenhaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redefinirSenhaMutation, { data, loading, error }] = useRedefinirSenhaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedefinirSenhaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RedefinirSenhaMutation,
    Types.RedefinirSenhaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.RedefinirSenhaMutation, Types.RedefinirSenhaMutationVariables>(
    RedefinirSenhaDocument,
    baseOptions
  )
}
export type RedefinirSenhaMutationHookResult = ReturnType<typeof useRedefinirSenhaMutation>
export type RedefinirSenhaMutationResult = ApolloReactCommon.MutationResult<Types.RedefinirSenhaMutation>
export type RedefinirSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RedefinirSenhaMutation,
  Types.RedefinirSenhaMutationVariables
>
export const SolicitacaoRecuperarSenhaDocument = gql`
  mutation SolicitacaoRecuperarSenha($input: SolicitarRecuperacaoSenhaInput!) {
    solicitarRecuperarSenha(input: $input)
  }
`
export type SolicitacaoRecuperarSenhaMutationFn = ApolloReactCommon.MutationFunction<
  Types.SolicitacaoRecuperarSenhaMutation,
  Types.SolicitacaoRecuperarSenhaMutationVariables
>

/**
 * __useSolicitacaoRecuperarSenhaMutation__
 *
 * To run a mutation, you first call `useSolicitacaoRecuperarSenhaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolicitacaoRecuperarSenhaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solicitacaoRecuperarSenhaMutation, { data, loading, error }] = useSolicitacaoRecuperarSenhaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolicitacaoRecuperarSenhaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SolicitacaoRecuperarSenhaMutation,
    Types.SolicitacaoRecuperarSenhaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SolicitacaoRecuperarSenhaMutation,
    Types.SolicitacaoRecuperarSenhaMutationVariables
  >(SolicitacaoRecuperarSenhaDocument, baseOptions)
}
export type SolicitacaoRecuperarSenhaMutationHookResult = ReturnType<typeof useSolicitacaoRecuperarSenhaMutation>
export type SolicitacaoRecuperarSenhaMutationResult = ApolloReactCommon.MutationResult<
  Types.SolicitacaoRecuperarSenhaMutation
>
export type SolicitacaoRecuperarSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SolicitacaoRecuperarSenhaMutation,
  Types.SolicitacaoRecuperarSenhaMutationVariables
>
export const AtendimentoRegistroTardioDocument = gql`
  query AtendimentoRegistroTardio($id: ID!) {
    atendimento(id: $id) {
      id
      cidadao {
        id
        nome
        nomeSocial
        dataNascimento
      }
      responsavel {
        ... on Acesso {
          id
          profissional {
            id
            nome
          }
        }
        ... on Lotacao {
          cbo {
            id
            nome
            cbo2002
          }
        }
        ... on Estagio {
          cbo {
            id
            nome
            cbo2002
          }
        }
      }
      actions {
        editar {
          enabled
        }
      }
      localAtendimento {
        id
        localAtendimentoExibicao
      }
      justificativa
      iniciadoEm
    }
  }
`

/**
 * __useAtendimentoRegistroTardioQuery__
 *
 * To run a query within a React component, call `useAtendimentoRegistroTardioQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtendimentoRegistroTardioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtendimentoRegistroTardioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtendimentoRegistroTardioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AtendimentoRegistroTardioQuery,
    Types.AtendimentoRegistroTardioQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AtendimentoRegistroTardioQuery, Types.AtendimentoRegistroTardioQueryVariables>(
    AtendimentoRegistroTardioDocument,
    baseOptions
  )
}
export function useAtendimentoRegistroTardioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AtendimentoRegistroTardioQuery,
    Types.AtendimentoRegistroTardioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AtendimentoRegistroTardioQuery,
    Types.AtendimentoRegistroTardioQueryVariables
  >(AtendimentoRegistroTardioDocument, baseOptions)
}
export type AtendimentoRegistroTardioQueryHookResult = ReturnType<typeof useAtendimentoRegistroTardioQuery>
export type AtendimentoRegistroTardioLazyQueryHookResult = ReturnType<typeof useAtendimentoRegistroTardioLazyQuery>
export type AtendimentoRegistroTardioQueryResult = ApolloReactCommon.QueryResult<
  Types.AtendimentoRegistroTardioQuery,
  Types.AtendimentoRegistroTardioQueryVariables
>
export const EditarRegistroTardioDocument = gql`
  mutation EditarRegistroTardio($input: RegistroTardioInput!) {
    editarRegistroTardio(input: $input) {
      id
    }
  }
`
export type EditarRegistroTardioMutationFn = ApolloReactCommon.MutationFunction<
  Types.EditarRegistroTardioMutation,
  Types.EditarRegistroTardioMutationVariables
>

/**
 * __useEditarRegistroTardioMutation__
 *
 * To run a mutation, you first call `useEditarRegistroTardioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarRegistroTardioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarRegistroTardioMutation, { data, loading, error }] = useEditarRegistroTardioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditarRegistroTardioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EditarRegistroTardioMutation,
    Types.EditarRegistroTardioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.EditarRegistroTardioMutation, Types.EditarRegistroTardioMutationVariables>(
    EditarRegistroTardioDocument,
    baseOptions
  )
}
export type EditarRegistroTardioMutationHookResult = ReturnType<typeof useEditarRegistroTardioMutation>
export type EditarRegistroTardioMutationResult = ApolloReactCommon.MutationResult<Types.EditarRegistroTardioMutation>
export type EditarRegistroTardioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EditarRegistroTardioMutation,
  Types.EditarRegistroTardioMutationVariables
>
export const SalvarRegistroTardioDocument = gql`
  mutation SalvarRegistroTardio($input: RegistroTardioInput!) {
    salvarRegistroTardio(input: $input) {
      id
    }
  }
`
export type SalvarRegistroTardioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarRegistroTardioMutation,
  Types.SalvarRegistroTardioMutationVariables
>

/**
 * __useSalvarRegistroTardioMutation__
 *
 * To run a mutation, you first call `useSalvarRegistroTardioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarRegistroTardioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarRegistroTardioMutation, { data, loading, error }] = useSalvarRegistroTardioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarRegistroTardioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarRegistroTardioMutation,
    Types.SalvarRegistroTardioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarRegistroTardioMutation, Types.SalvarRegistroTardioMutationVariables>(
    SalvarRegistroTardioDocument,
    baseOptions
  )
}
export type SalvarRegistroTardioMutationHookResult = ReturnType<typeof useSalvarRegistroTardioMutation>
export type SalvarRegistroTardioMutationResult = ApolloReactCommon.MutationResult<Types.SalvarRegistroTardioMutation>
export type SalvarRegistroTardioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarRegistroTardioMutation,
  Types.SalvarRegistroTardioMutationVariables
>
export const UltimoAtendimentoProfissionalCidadaoDocument = gql`
  query UltimoAtendimentoProfissionalCidadao($input: UltimoAtendimentoProfissionalFinalizadoQueryInput) {
    ultimoAtendimentoProfissionalCidadao(input: $input) {
      id
      finalizadoEm
      lotacao {
        id
        cbo {
          id
          nome
        }
        profissional {
          id
          nome
        }
      }
    }
  }
`

/**
 * __useUltimoAtendimentoProfissionalCidadaoQuery__
 *
 * To run a query within a React component, call `useUltimoAtendimentoProfissionalCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUltimoAtendimentoProfissionalCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUltimoAtendimentoProfissionalCidadaoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUltimoAtendimentoProfissionalCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UltimoAtendimentoProfissionalCidadaoQuery,
    Types.UltimoAtendimentoProfissionalCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UltimoAtendimentoProfissionalCidadaoQuery,
    Types.UltimoAtendimentoProfissionalCidadaoQueryVariables
  >(UltimoAtendimentoProfissionalCidadaoDocument, baseOptions)
}
export function useUltimoAtendimentoProfissionalCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UltimoAtendimentoProfissionalCidadaoQuery,
    Types.UltimoAtendimentoProfissionalCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UltimoAtendimentoProfissionalCidadaoQuery,
    Types.UltimoAtendimentoProfissionalCidadaoQueryVariables
  >(UltimoAtendimentoProfissionalCidadaoDocument, baseOptions)
}
export type UltimoAtendimentoProfissionalCidadaoQueryHookResult = ReturnType<
  typeof useUltimoAtendimentoProfissionalCidadaoQuery
>
export type UltimoAtendimentoProfissionalCidadaoLazyQueryHookResult = ReturnType<
  typeof useUltimoAtendimentoProfissionalCidadaoLazyQuery
>
export type UltimoAtendimentoProfissionalCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.UltimoAtendimentoProfissionalCidadaoQuery,
  Types.UltimoAtendimentoProfissionalCidadaoQueryVariables
>
export const AbsenteismosDocument = gql`
  query Absenteismos($input: DateRangeDto!) {
    absenteismos(input: $input) {
      id
      municipio
      unidadeSaude
      equipe
      nomeProfissional
      nomeSituacaoAgendamento
      categoriaProfissional
      turno
      intervaloAgendamento
      faixaEtaria
      cbo
      producoes
    }
  }
`

/**
 * __useAbsenteismosQuery__
 *
 * To run a query within a React component, call `useAbsenteismosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbsenteismosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbsenteismosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAbsenteismosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AbsenteismosQuery, Types.AbsenteismosQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AbsenteismosQuery, Types.AbsenteismosQueryVariables>(
    AbsenteismosDocument,
    baseOptions
  )
}
export function useAbsenteismosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AbsenteismosQuery, Types.AbsenteismosQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AbsenteismosQuery, Types.AbsenteismosQueryVariables>(
    AbsenteismosDocument,
    baseOptions
  )
}
export type AbsenteismosQueryHookResult = ReturnType<typeof useAbsenteismosQuery>
export type AbsenteismosLazyQueryHookResult = ReturnType<typeof useAbsenteismosLazyQuery>
export type AbsenteismosQueryResult = ApolloReactCommon.QueryResult<
  Types.AbsenteismosQuery,
  Types.AbsenteismosQueryVariables
>
export const RocksdbEnabledDocument = gql`
  query RocksdbEnabled {
    info {
      rocksdbEnabled
    }
  }
`

/**
 * __useRocksdbEnabledQuery__
 *
 * To run a query within a React component, call `useRocksdbEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useRocksdbEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRocksdbEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useRocksdbEnabledQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.RocksdbEnabledQuery, Types.RocksdbEnabledQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.RocksdbEnabledQuery, Types.RocksdbEnabledQueryVariables>(
    RocksdbEnabledDocument,
    baseOptions
  )
}
export function useRocksdbEnabledLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.RocksdbEnabledQuery, Types.RocksdbEnabledQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.RocksdbEnabledQuery, Types.RocksdbEnabledQueryVariables>(
    RocksdbEnabledDocument,
    baseOptions
  )
}
export type RocksdbEnabledQueryHookResult = ReturnType<typeof useRocksdbEnabledQuery>
export type RocksdbEnabledLazyQueryHookResult = ReturnType<typeof useRocksdbEnabledLazyQuery>
export type RocksdbEnabledQueryResult = ApolloReactCommon.QueryResult<
  Types.RocksdbEnabledQuery,
  Types.RocksdbEnabledQueryVariables
>
export const ModeloPersonalizadoRelatorioGerencialDocument = gql`
  query ModeloPersonalizadoRelatorioGerencial($id: ID!) {
    modeloPersonalizadoRelatorioGerencialById(id: $id) {
      id
      nome
      isPublico
      tipoModulo
      atorPapelCriadorId
      modelo {
        unidadePeriodo
        opcaoSelecionada
        ciaps {
          ...Ciap
        }
        cids {
          ...Cid10
        }
        gruposCondicoes {
          id
          grupoCondicao
          ciaps {
            ...Ciap
          }
          cids {
            ...Cid10
          }
        }
        campos {
          key
          posicao
          items
        }
        aggregator
      }
    }
  }
  ${CiapFragmentDoc}
  ${Cid10FragmentDoc}
`

/**
 * __useModeloPersonalizadoRelatorioGerencialQuery__
 *
 * To run a query within a React component, call `useModeloPersonalizadoRelatorioGerencialQuery` and pass it any options that fit your needs.
 * When your component renders, `useModeloPersonalizadoRelatorioGerencialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModeloPersonalizadoRelatorioGerencialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModeloPersonalizadoRelatorioGerencialQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ModeloPersonalizadoRelatorioGerencialQuery,
    Types.ModeloPersonalizadoRelatorioGerencialQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ModeloPersonalizadoRelatorioGerencialQuery,
    Types.ModeloPersonalizadoRelatorioGerencialQueryVariables
  >(ModeloPersonalizadoRelatorioGerencialDocument, baseOptions)
}
export function useModeloPersonalizadoRelatorioGerencialLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ModeloPersonalizadoRelatorioGerencialQuery,
    Types.ModeloPersonalizadoRelatorioGerencialQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ModeloPersonalizadoRelatorioGerencialQuery,
    Types.ModeloPersonalizadoRelatorioGerencialQueryVariables
  >(ModeloPersonalizadoRelatorioGerencialDocument, baseOptions)
}
export type ModeloPersonalizadoRelatorioGerencialQueryHookResult = ReturnType<
  typeof useModeloPersonalizadoRelatorioGerencialQuery
>
export type ModeloPersonalizadoRelatorioGerencialLazyQueryHookResult = ReturnType<
  typeof useModeloPersonalizadoRelatorioGerencialLazyQuery
>
export type ModeloPersonalizadoRelatorioGerencialQueryResult = ApolloReactCommon.QueryResult<
  Types.ModeloPersonalizadoRelatorioGerencialQuery,
  Types.ModeloPersonalizadoRelatorioGerencialQueryVariables
>
export const ModelosByTipoDocument = gql`
  query ModelosByTipo($tipoModulo: TipoModuloEnum!) {
    modelosPersonalizadosByTipoModulo(tipoModulo: $tipoModulo) {
      id
      nome
      isPublico
    }
  }
`

/**
 * __useModelosByTipoQuery__
 *
 * To run a query within a React component, call `useModelosByTipoQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelosByTipoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelosByTipoQuery({
 *   variables: {
 *      tipoModulo: // value for 'tipoModulo'
 *   },
 * });
 */
export function useModelosByTipoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ModelosByTipoQuery, Types.ModelosByTipoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ModelosByTipoQuery, Types.ModelosByTipoQueryVariables>(
    ModelosByTipoDocument,
    baseOptions
  )
}
export function useModelosByTipoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ModelosByTipoQuery, Types.ModelosByTipoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ModelosByTipoQuery, Types.ModelosByTipoQueryVariables>(
    ModelosByTipoDocument,
    baseOptions
  )
}
export type ModelosByTipoQueryHookResult = ReturnType<typeof useModelosByTipoQuery>
export type ModelosByTipoLazyQueryHookResult = ReturnType<typeof useModelosByTipoLazyQuery>
export type ModelosByTipoQueryResult = ApolloReactCommon.QueryResult<
  Types.ModelosByTipoQuery,
  Types.ModelosByTipoQueryVariables
>
export const EditarModeloPersonalizadoRelatorioGerencialDocument = gql`
  mutation EditarModeloPersonalizadoRelatorioGerencial($input: EditarModeloRelatorioGerencialInput!) {
    editarModeloPersonalizadoRelatorioGerencial(input: $input) {
      id
      nome
      isPublico
      tipoModulo
      modelo
    }
  }
`
export type EditarModeloPersonalizadoRelatorioGerencialMutationFn = ApolloReactCommon.MutationFunction<
  Types.EditarModeloPersonalizadoRelatorioGerencialMutation,
  Types.EditarModeloPersonalizadoRelatorioGerencialMutationVariables
>

/**
 * __useEditarModeloPersonalizadoRelatorioGerencialMutation__
 *
 * To run a mutation, you first call `useEditarModeloPersonalizadoRelatorioGerencialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditarModeloPersonalizadoRelatorioGerencialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editarModeloPersonalizadoRelatorioGerencialMutation, { data, loading, error }] = useEditarModeloPersonalizadoRelatorioGerencialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditarModeloPersonalizadoRelatorioGerencialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EditarModeloPersonalizadoRelatorioGerencialMutation,
    Types.EditarModeloPersonalizadoRelatorioGerencialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.EditarModeloPersonalizadoRelatorioGerencialMutation,
    Types.EditarModeloPersonalizadoRelatorioGerencialMutationVariables
  >(EditarModeloPersonalizadoRelatorioGerencialDocument, baseOptions)
}
export type EditarModeloPersonalizadoRelatorioGerencialMutationHookResult = ReturnType<
  typeof useEditarModeloPersonalizadoRelatorioGerencialMutation
>
export type EditarModeloPersonalizadoRelatorioGerencialMutationResult = ApolloReactCommon.MutationResult<
  Types.EditarModeloPersonalizadoRelatorioGerencialMutation
>
export type EditarModeloPersonalizadoRelatorioGerencialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EditarModeloPersonalizadoRelatorioGerencialMutation,
  Types.EditarModeloPersonalizadoRelatorioGerencialMutationVariables
>
export const ExcluirModeloPersonalizadoRelatorioGerencialDocument = gql`
  mutation ExcluirModeloPersonalizadoRelatorioGerencial($input: ExcluirModeloRelatorioGerencialInput!) {
    excluirModeloPersonalizadoRelatorioGerencial(input: $input)
  }
`
export type ExcluirModeloPersonalizadoRelatorioGerencialMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirModeloPersonalizadoRelatorioGerencialMutation,
  Types.ExcluirModeloPersonalizadoRelatorioGerencialMutationVariables
>

/**
 * __useExcluirModeloPersonalizadoRelatorioGerencialMutation__
 *
 * To run a mutation, you first call `useExcluirModeloPersonalizadoRelatorioGerencialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirModeloPersonalizadoRelatorioGerencialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirModeloPersonalizadoRelatorioGerencialMutation, { data, loading, error }] = useExcluirModeloPersonalizadoRelatorioGerencialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExcluirModeloPersonalizadoRelatorioGerencialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirModeloPersonalizadoRelatorioGerencialMutation,
    Types.ExcluirModeloPersonalizadoRelatorioGerencialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirModeloPersonalizadoRelatorioGerencialMutation,
    Types.ExcluirModeloPersonalizadoRelatorioGerencialMutationVariables
  >(ExcluirModeloPersonalizadoRelatorioGerencialDocument, baseOptions)
}
export type ExcluirModeloPersonalizadoRelatorioGerencialMutationHookResult = ReturnType<
  typeof useExcluirModeloPersonalizadoRelatorioGerencialMutation
>
export type ExcluirModeloPersonalizadoRelatorioGerencialMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirModeloPersonalizadoRelatorioGerencialMutation
>
export type ExcluirModeloPersonalizadoRelatorioGerencialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirModeloPersonalizadoRelatorioGerencialMutation,
  Types.ExcluirModeloPersonalizadoRelatorioGerencialMutationVariables
>
export const SalvarModeloPersonalizadoRelatorioGerencialDocument = gql`
  mutation SalvarModeloPersonalizadoRelatorioGerencial($input: SalvarModeloRelatorioGerencialInput!) {
    salvarModeloPersonalizadoRelatorioGerencial(input: $input) {
      id
      nome
      isPublico
      tipoModulo
      modelo
    }
  }
`
export type SalvarModeloPersonalizadoRelatorioGerencialMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarModeloPersonalizadoRelatorioGerencialMutation,
  Types.SalvarModeloPersonalizadoRelatorioGerencialMutationVariables
>

/**
 * __useSalvarModeloPersonalizadoRelatorioGerencialMutation__
 *
 * To run a mutation, you first call `useSalvarModeloPersonalizadoRelatorioGerencialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarModeloPersonalizadoRelatorioGerencialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarModeloPersonalizadoRelatorioGerencialMutation, { data, loading, error }] = useSalvarModeloPersonalizadoRelatorioGerencialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarModeloPersonalizadoRelatorioGerencialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarModeloPersonalizadoRelatorioGerencialMutation,
    Types.SalvarModeloPersonalizadoRelatorioGerencialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarModeloPersonalizadoRelatorioGerencialMutation,
    Types.SalvarModeloPersonalizadoRelatorioGerencialMutationVariables
  >(SalvarModeloPersonalizadoRelatorioGerencialDocument, baseOptions)
}
export type SalvarModeloPersonalizadoRelatorioGerencialMutationHookResult = ReturnType<
  typeof useSalvarModeloPersonalizadoRelatorioGerencialMutation
>
export type SalvarModeloPersonalizadoRelatorioGerencialMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarModeloPersonalizadoRelatorioGerencialMutation
>
export type SalvarModeloPersonalizadoRelatorioGerencialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarModeloPersonalizadoRelatorioGerencialMutation,
  Types.SalvarModeloPersonalizadoRelatorioGerencialMutationVariables
>
export const DataUltimoProcessamentoDocument = gql`
  query DataUltimoProcessamento {
    dataUltimoProcessamento
  }
`

/**
 * __useDataUltimoProcessamentoQuery__
 *
 * To run a query within a React component, call `useDataUltimoProcessamentoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataUltimoProcessamentoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataUltimoProcessamentoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataUltimoProcessamentoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DataUltimoProcessamentoQuery,
    Types.DataUltimoProcessamentoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.DataUltimoProcessamentoQuery, Types.DataUltimoProcessamentoQueryVariables>(
    DataUltimoProcessamentoDocument,
    baseOptions
  )
}
export function useDataUltimoProcessamentoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DataUltimoProcessamentoQuery,
    Types.DataUltimoProcessamentoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.DataUltimoProcessamentoQuery, Types.DataUltimoProcessamentoQueryVariables>(
    DataUltimoProcessamentoDocument,
    baseOptions
  )
}
export type DataUltimoProcessamentoQueryHookResult = ReturnType<typeof useDataUltimoProcessamentoQuery>
export type DataUltimoProcessamentoLazyQueryHookResult = ReturnType<typeof useDataUltimoProcessamentoLazyQuery>
export type DataUltimoProcessamentoQueryResult = ApolloReactCommon.QueryResult<
  Types.DataUltimoProcessamentoQuery,
  Types.DataUltimoProcessamentoQueryVariables
>
export const InfoProfissionalCpfDocument = gql`
  query InfoProfissionalCpf($input: CpfContraChaveInput!) {
    profissionalByCpfContraChave(input: $input) {
      id
    }
  }
`

/**
 * __useInfoProfissionalCpfQuery__
 *
 * To run a query within a React component, call `useInfoProfissionalCpfQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoProfissionalCpfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoProfissionalCpfQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInfoProfissionalCpfQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.InfoProfissionalCpfQuery,
    Types.InfoProfissionalCpfQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.InfoProfissionalCpfQuery, Types.InfoProfissionalCpfQueryVariables>(
    InfoProfissionalCpfDocument,
    baseOptions
  )
}
export function useInfoProfissionalCpfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.InfoProfissionalCpfQuery,
    Types.InfoProfissionalCpfQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.InfoProfissionalCpfQuery, Types.InfoProfissionalCpfQueryVariables>(
    InfoProfissionalCpfDocument,
    baseOptions
  )
}
export type InfoProfissionalCpfQueryHookResult = ReturnType<typeof useInfoProfissionalCpfQuery>
export type InfoProfissionalCpfLazyQueryHookResult = ReturnType<typeof useInfoProfissionalCpfLazyQuery>
export type InfoProfissionalCpfQueryResult = ApolloReactCommon.QueryResult<
  Types.InfoProfissionalCpfQuery,
  Types.InfoProfissionalCpfQueryVariables
>
export const RedefinirSenhaAdminInstalacaoDocument = gql`
  mutation RedefinirSenhaAdminInstalacao($input: RedefinirSenhaAdminInstalacaoInput!) {
    redefinirSenhaAdminInstalacao(input: $input)
  }
`
export type RedefinirSenhaAdminInstalacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.RedefinirSenhaAdminInstalacaoMutation,
  Types.RedefinirSenhaAdminInstalacaoMutationVariables
>

/**
 * __useRedefinirSenhaAdminInstalacaoMutation__
 *
 * To run a mutation, you first call `useRedefinirSenhaAdminInstalacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedefinirSenhaAdminInstalacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redefinirSenhaAdminInstalacaoMutation, { data, loading, error }] = useRedefinirSenhaAdminInstalacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedefinirSenhaAdminInstalacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RedefinirSenhaAdminInstalacaoMutation,
    Types.RedefinirSenhaAdminInstalacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RedefinirSenhaAdminInstalacaoMutation,
    Types.RedefinirSenhaAdminInstalacaoMutationVariables
  >(RedefinirSenhaAdminInstalacaoDocument, baseOptions)
}
export type RedefinirSenhaAdminInstalacaoMutationHookResult = ReturnType<
  typeof useRedefinirSenhaAdminInstalacaoMutation
>
export type RedefinirSenhaAdminInstalacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.RedefinirSenhaAdminInstalacaoMutation
>
export type RedefinirSenhaAdminInstalacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RedefinirSenhaAdminInstalacaoMutation,
  Types.RedefinirSenhaAdminInstalacaoMutationVariables
>
export const SaveAdminProfissionalDocument = gql`
  mutation SaveAdminProfissional($input: ProfissionalContraChaveInput!) {
    salvarProfissionalContraChave(input: $input) {
      id
    }
  }
`
export type SaveAdminProfissionalMutationFn = ApolloReactCommon.MutationFunction<
  Types.SaveAdminProfissionalMutation,
  Types.SaveAdminProfissionalMutationVariables
>

/**
 * __useSaveAdminProfissionalMutation__
 *
 * To run a mutation, you first call `useSaveAdminProfissionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAdminProfissionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAdminProfissionalMutation, { data, loading, error }] = useSaveAdminProfissionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAdminProfissionalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SaveAdminProfissionalMutation,
    Types.SaveAdminProfissionalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SaveAdminProfissionalMutation,
    Types.SaveAdminProfissionalMutationVariables
  >(SaveAdminProfissionalDocument, baseOptions)
}
export type SaveAdminProfissionalMutationHookResult = ReturnType<typeof useSaveAdminProfissionalMutation>
export type SaveAdminProfissionalMutationResult = ApolloReactCommon.MutationResult<Types.SaveAdminProfissionalMutation>
export type SaveAdminProfissionalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SaveAdminProfissionalMutation,
  Types.SaveAdminProfissionalMutationVariables
>
export const ValidateNovoAdminDocument = gql`
  query validateNovoAdmin($input: ValidateNovoAdminInput!) {
    validatePermissaoNovoAdmin(input: $input)
  }
`

/**
 * __useValidateNovoAdminQuery__
 *
 * To run a query within a React component, call `useValidateNovoAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateNovoAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateNovoAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateNovoAdminQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ValidateNovoAdminQuery, Types.ValidateNovoAdminQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ValidateNovoAdminQuery, Types.ValidateNovoAdminQueryVariables>(
    ValidateNovoAdminDocument,
    baseOptions
  )
}
export function useValidateNovoAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ValidateNovoAdminQuery,
    Types.ValidateNovoAdminQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ValidateNovoAdminQuery, Types.ValidateNovoAdminQueryVariables>(
    ValidateNovoAdminDocument,
    baseOptions
  )
}
export type ValidateNovoAdminQueryHookResult = ReturnType<typeof useValidateNovoAdminQuery>
export type ValidateNovoAdminLazyQueryHookResult = ReturnType<typeof useValidateNovoAdminLazyQuery>
export type ValidateNovoAdminQueryResult = ApolloReactCommon.QueryResult<
  Types.ValidateNovoAdminQuery,
  Types.ValidateNovoAdminQueryVariables
>
export const ValidateResetAdminDocument = gql`
  query ValidateResetAdmin($input: CpfContraChaveInput!) {
    validateResetAdmin(input: $input)
  }
`

/**
 * __useValidateResetAdminQuery__
 *
 * To run a query within a React component, call `useValidateResetAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateResetAdminQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ValidateResetAdminQuery, Types.ValidateResetAdminQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ValidateResetAdminQuery, Types.ValidateResetAdminQueryVariables>(
    ValidateResetAdminDocument,
    baseOptions
  )
}
export function useValidateResetAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ValidateResetAdminQuery,
    Types.ValidateResetAdminQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ValidateResetAdminQuery, Types.ValidateResetAdminQueryVariables>(
    ValidateResetAdminDocument,
    baseOptions
  )
}
export type ValidateResetAdminQueryHookResult = ReturnType<typeof useValidateResetAdminQuery>
export type ValidateResetAdminLazyQueryHookResult = ReturnType<typeof useValidateResetAdminLazyQuery>
export type ValidateResetAdminQueryResult = ApolloReactCommon.QueryResult<
  Types.ValidateResetAdminQuery,
  Types.ValidateResetAdminQueryVariables
>
export const AlteraAdminDocument = gql`
  mutation AlteraAdmin($input: ValidateNovoAdminInput!) {
    denifinirNovoAdministrador(input: $input)
  }
`
export type AlteraAdminMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlteraAdminMutation,
  Types.AlteraAdminMutationVariables
>

/**
 * __useAlteraAdminMutation__
 *
 * To run a mutation, you first call `useAlteraAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlteraAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alteraAdminMutation, { data, loading, error }] = useAlteraAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlteraAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlteraAdminMutation, Types.AlteraAdminMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlteraAdminMutation, Types.AlteraAdminMutationVariables>(
    AlteraAdminDocument,
    baseOptions
  )
}
export type AlteraAdminMutationHookResult = ReturnType<typeof useAlteraAdminMutation>
export type AlteraAdminMutationResult = ApolloReactCommon.MutationResult<Types.AlteraAdminMutation>
export type AlteraAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlteraAdminMutation,
  Types.AlteraAdminMutationVariables
>
export const ChaveByCpfDocument = gql`
  query chaveByCpf($cpf: String!) {
    chaveByCpf(cpf: $cpf)
  }
`

/**
 * __useChaveByCpfQuery__
 *
 * To run a query within a React component, call `useChaveByCpfQuery` and pass it any options that fit your needs.
 * When your component renders, `useChaveByCpfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChaveByCpfQuery({
 *   variables: {
 *      cpf: // value for 'cpf'
 *   },
 * });
 */
export function useChaveByCpfQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>(
    ChaveByCpfDocument,
    baseOptions
  )
}
export function useChaveByCpfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>(
    ChaveByCpfDocument,
    baseOptions
  )
}
export type ChaveByCpfQueryHookResult = ReturnType<typeof useChaveByCpfQuery>
export type ChaveByCpfLazyQueryHookResult = ReturnType<typeof useChaveByCpfLazyQuery>
export type ChaveByCpfQueryResult = ApolloReactCommon.QueryResult<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>
export const ProfissionalAdminDetailDocument = gql`
  query profissionalAdminDetail($input: CpfContraChaveInput!) {
    profissionalByCpfContraChave(input: $input) {
      ...ProfissionalDetail
      usuario {
        id
      }
    }
  }
  ${ProfissionalDetailFragmentDoc}
`

/**
 * __useProfissionalAdminDetailQuery__
 *
 * To run a query within a React component, call `useProfissionalAdminDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalAdminDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalAdminDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfissionalAdminDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionalAdminDetailQuery,
    Types.ProfissionalAdminDetailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalAdminDetailQuery, Types.ProfissionalAdminDetailQueryVariables>(
    ProfissionalAdminDetailDocument,
    baseOptions
  )
}
export function useProfissionalAdminDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalAdminDetailQuery,
    Types.ProfissionalAdminDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalAdminDetailQuery, Types.ProfissionalAdminDetailQueryVariables>(
    ProfissionalAdminDetailDocument,
    baseOptions
  )
}
export type ProfissionalAdminDetailQueryHookResult = ReturnType<typeof useProfissionalAdminDetailQuery>
export type ProfissionalAdminDetailLazyQueryHookResult = ReturnType<typeof useProfissionalAdminDetailLazyQuery>
export type ProfissionalAdminDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalAdminDetailQuery,
  Types.ProfissionalAdminDetailQueryVariables
>
export const AtualizarImoveisDocument = gql`
  mutation AtualizarImoveis($input: AtualizarImoveisInput!) {
    atualizarImoveis(input: $input)
  }
`
export type AtualizarImoveisMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtualizarImoveisMutation,
  Types.AtualizarImoveisMutationVariables
>

/**
 * __useAtualizarImoveisMutation__
 *
 * To run a mutation, you first call `useAtualizarImoveisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarImoveisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarImoveisMutation, { data, loading, error }] = useAtualizarImoveisMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtualizarImoveisMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtualizarImoveisMutation,
    Types.AtualizarImoveisMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtualizarImoveisMutation, Types.AtualizarImoveisMutationVariables>(
    AtualizarImoveisDocument,
    baseOptions
  )
}
export type AtualizarImoveisMutationHookResult = ReturnType<typeof useAtualizarImoveisMutation>
export type AtualizarImoveisMutationResult = ApolloReactCommon.MutationResult<Types.AtualizarImoveisMutation>
export type AtualizarImoveisMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtualizarImoveisMutation,
  Types.AtualizarImoveisMutationVariables
>
export const LoadImoveisDocument = gql`
  query LoadImoveis($input: ImoveisQueryInput!) {
    imoveis(input: $input) {
      content {
        id
        tipoLogradouro {
          id
          nome
        }
        logradouro
        bairro
        microarea
        ine
        cnes
        nomeEquipe
        isEquipeAtiva
        nomeUnidadeSaude
        isUnidadeSaudeAtiva
        numero
        cep
        complemento
        familias {
          id
          responsavel {
            id
            nome
            nomeSocial
            permissaoReterritorializar {
              enabled
              hint
            }
            ultimaFichaCadastrada {
              id
              isProcessado
            }
          }
          dependentes {
            id
            permissaoReterritorializar {
              enabled
              hint
            }
            ultimaFichaCadastrada {
              id
              isProcessado
            }
          }
        }
        permissaoReterritorializar {
          enabled
          hint
        }
        responsavelTecnico {
          id
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLoadImoveisQuery__
 *
 * To run a query within a React component, call `useLoadImoveisQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadImoveisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadImoveisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadImoveisQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoadImoveisQuery, Types.LoadImoveisQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoadImoveisQuery, Types.LoadImoveisQueryVariables>(
    LoadImoveisDocument,
    baseOptions
  )
}
export function useLoadImoveisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LoadImoveisQuery, Types.LoadImoveisQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LoadImoveisQuery, Types.LoadImoveisQueryVariables>(
    LoadImoveisDocument,
    baseOptions
  )
}
export type LoadImoveisQueryHookResult = ReturnType<typeof useLoadImoveisQuery>
export type LoadImoveisLazyQueryHookResult = ReturnType<typeof useLoadImoveisLazyQuery>
export type LoadImoveisQueryResult = ApolloReactCommon.QueryResult<
  Types.LoadImoveisQuery,
  Types.LoadImoveisQueryVariables
>
export const IsProcessTerritorioRunningDocument = gql`
  query IsProcessTerritorioRunning($input: ID!) {
    isProcessamentoTerritorioRunning(token: $input)
  }
`

/**
 * __useIsProcessTerritorioRunningQuery__
 *
 * To run a query within a React component, call `useIsProcessTerritorioRunningQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsProcessTerritorioRunningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsProcessTerritorioRunningQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsProcessTerritorioRunningQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.IsProcessTerritorioRunningQuery,
    Types.IsProcessTerritorioRunningQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.IsProcessTerritorioRunningQuery,
    Types.IsProcessTerritorioRunningQueryVariables
  >(IsProcessTerritorioRunningDocument, baseOptions)
}
export function useIsProcessTerritorioRunningLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.IsProcessTerritorioRunningQuery,
    Types.IsProcessTerritorioRunningQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.IsProcessTerritorioRunningQuery,
    Types.IsProcessTerritorioRunningQueryVariables
  >(IsProcessTerritorioRunningDocument, baseOptions)
}
export type IsProcessTerritorioRunningQueryHookResult = ReturnType<typeof useIsProcessTerritorioRunningQuery>
export type IsProcessTerritorioRunningLazyQueryHookResult = ReturnType<typeof useIsProcessTerritorioRunningLazyQuery>
export type IsProcessTerritorioRunningQueryResult = ApolloReactCommon.QueryResult<
  Types.IsProcessTerritorioRunningQuery,
  Types.IsProcessTerritorioRunningQueryVariables
>
export const SobreDocument = gql`
  query Sobre {
    info {
      commit
      competenciaSigtap
      databaseVendor
      databaseSchemaVersion
      recursosDisponiveis
      versao
      internetHabilitada
      smtpConfigurado
      linkInstalacaoConfigurado
      govBREnabled
    }
  }
`

/**
 * __useSobreQuery__
 *
 * To run a query within a React component, call `useSobreQuery` and pass it any options that fit your needs.
 * When your component renders, `useSobreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSobreQuery({
 *   variables: {
 *   },
 * });
 */
export function useSobreQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.SobreQuery, Types.SobreQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.SobreQuery, Types.SobreQueryVariables>(SobreDocument, baseOptions)
}
export function useSobreLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SobreQuery, Types.SobreQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.SobreQuery, Types.SobreQueryVariables>(SobreDocument, baseOptions)
}
export type SobreQueryHookResult = ReturnType<typeof useSobreQuery>
export type SobreLazyQueryHookResult = ReturnType<typeof useSobreLazyQuery>
export type SobreQueryResult = ApolloReactCommon.QueryResult<Types.SobreQuery, Types.SobreQueryVariables>
export const AceitarTermosUsoDocument = gql`
  mutation AceitarTermosUso {
    aceitarTermosUso {
      id
      forcarTrocaSenha
      aceitouTermosUso
    }
  }
`
export type AceitarTermosUsoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AceitarTermosUsoMutation,
  Types.AceitarTermosUsoMutationVariables
>

/**
 * __useAceitarTermosUsoMutation__
 *
 * To run a mutation, you first call `useAceitarTermosUsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAceitarTermosUsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aceitarTermosUsoMutation, { data, loading, error }] = useAceitarTermosUsoMutation({
 *   variables: {
 *   },
 * });
 */
export function useAceitarTermosUsoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AceitarTermosUsoMutation,
    Types.AceitarTermosUsoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AceitarTermosUsoMutation, Types.AceitarTermosUsoMutationVariables>(
    AceitarTermosUsoDocument,
    baseOptions
  )
}
export type AceitarTermosUsoMutationHookResult = ReturnType<typeof useAceitarTermosUsoMutation>
export type AceitarTermosUsoMutationResult = ApolloReactCommon.MutationResult<Types.AceitarTermosUsoMutation>
export type AceitarTermosUsoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AceitarTermosUsoMutation,
  Types.AceitarTermosUsoMutationVariables
>
export const AlternarTransmissaoLinkDocument = gql`
  mutation AlternarTransmissaoLink($id: ID!) {
    alternarTransmissaoLink(id: $id)
  }
`
export type AlternarTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlternarTransmissaoLinkMutation,
  Types.AlternarTransmissaoLinkMutationVariables
>

/**
 * __useAlternarTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useAlternarTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlternarTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alternarTransmissaoLinkMutation, { data, loading, error }] = useAlternarTransmissaoLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAlternarTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlternarTransmissaoLinkMutation,
    Types.AlternarTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlternarTransmissaoLinkMutation,
    Types.AlternarTransmissaoLinkMutationVariables
  >(AlternarTransmissaoLinkDocument, baseOptions)
}
export type AlternarTransmissaoLinkMutationHookResult = ReturnType<typeof useAlternarTransmissaoLinkMutation>
export type AlternarTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<
  Types.AlternarTransmissaoLinkMutation
>
export type AlternarTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlternarTransmissaoLinkMutation,
  Types.AlternarTransmissaoLinkMutationVariables
>
export const ExcluirTransmissaoLinkDocument = gql`
  mutation ExcluirTransmissaoLink($id: ID!) {
    excluirTransmissaoLink(id: $id)
  }
`
export type ExcluirTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirTransmissaoLinkMutation,
  Types.ExcluirTransmissaoLinkMutationVariables
>

/**
 * __useExcluirTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useExcluirTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirTransmissaoLinkMutation, { data, loading, error }] = useExcluirTransmissaoLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirTransmissaoLinkMutation,
    Types.ExcluirTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirTransmissaoLinkMutation,
    Types.ExcluirTransmissaoLinkMutationVariables
  >(ExcluirTransmissaoLinkDocument, baseOptions)
}
export type ExcluirTransmissaoLinkMutationHookResult = ReturnType<typeof useExcluirTransmissaoLinkMutation>
export type ExcluirTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirTransmissaoLinkMutation
>
export type ExcluirTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirTransmissaoLinkMutation,
  Types.ExcluirTransmissaoLinkMutationVariables
>
export const InserirTransmissaoLinkDocument = gql`
  mutation InserirTransmissaoLink($form: TransmissaoLinkInsertDto!) {
    inserirTransmissaoLink(form: $form)
  }
`
export type InserirTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.InserirTransmissaoLinkMutation,
  Types.InserirTransmissaoLinkMutationVariables
>

/**
 * __useInserirTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useInserirTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInserirTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inserirTransmissaoLinkMutation, { data, loading, error }] = useInserirTransmissaoLinkMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useInserirTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InserirTransmissaoLinkMutation,
    Types.InserirTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.InserirTransmissaoLinkMutation,
    Types.InserirTransmissaoLinkMutationVariables
  >(InserirTransmissaoLinkDocument, baseOptions)
}
export type InserirTransmissaoLinkMutationHookResult = ReturnType<typeof useInserirTransmissaoLinkMutation>
export type InserirTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<
  Types.InserirTransmissaoLinkMutation
>
export type InserirTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InserirTransmissaoLinkMutation,
  Types.InserirTransmissaoLinkMutationVariables
>
export const TransmissaoLinkDocument = gql`
  query TransmissaoLink($input: TransmissaoLinkPagingQueryFiltroDto!) {
    transmissaoLink(input: $input) {
      content {
        id
        hostname
        name
        ativo
        statusConexao
        dataTesteConexao
        actions {
          alternarAtivacao {
            enabled
            hint
          }
          excluir {
            enabled
            hint
          }
          editar {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTransmissaoLinkQuery__
 *
 * To run a query within a React component, call `useTransmissaoLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>(
    TransmissaoLinkDocument,
    baseOptions
  )
}
export function useTransmissaoLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>(
    TransmissaoLinkDocument,
    baseOptions
  )
}
export type TransmissaoLinkQueryHookResult = ReturnType<typeof useTransmissaoLinkQuery>
export type TransmissaoLinkLazyQueryHookResult = ReturnType<typeof useTransmissaoLinkLazyQuery>
export type TransmissaoLinkQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoLinkQuery,
  Types.TransmissaoLinkQueryVariables
>
export const TestarConexaoLinkTransmissaoDocument = gql`
  mutation TestarConexaoLinkTransmissao($id: ID!) {
    testarConexaoLinkTransmissao(id: $id) {
      link {
        id
        hostname
        name
        ativo
        statusConexao
        dataTesteConexao
      }
      validationDescription
    }
  }
`
export type TestarConexaoLinkTransmissaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.TestarConexaoLinkTransmissaoMutation,
  Types.TestarConexaoLinkTransmissaoMutationVariables
>

/**
 * __useTestarConexaoLinkTransmissaoMutation__
 *
 * To run a mutation, you first call `useTestarConexaoLinkTransmissaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestarConexaoLinkTransmissaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testarConexaoLinkTransmissaoMutation, { data, loading, error }] = useTestarConexaoLinkTransmissaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestarConexaoLinkTransmissaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TestarConexaoLinkTransmissaoMutation,
    Types.TestarConexaoLinkTransmissaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.TestarConexaoLinkTransmissaoMutation,
    Types.TestarConexaoLinkTransmissaoMutationVariables
  >(TestarConexaoLinkTransmissaoDocument, baseOptions)
}
export type TestarConexaoLinkTransmissaoMutationHookResult = ReturnType<typeof useTestarConexaoLinkTransmissaoMutation>
export type TestarConexaoLinkTransmissaoMutationResult = ApolloReactCommon.MutationResult<
  Types.TestarConexaoLinkTransmissaoMutation
>
export type TestarConexaoLinkTransmissaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.TestarConexaoLinkTransmissaoMutation,
  Types.TestarConexaoLinkTransmissaoMutationVariables
>
export const UpdateTransmissaoLinkDocument = gql`
  mutation UpdateTransmissaoLink($form: TransmissaoLinkUpdateDto!) {
    updateTransmissaoLink(form: $form)
  }
`
export type UpdateTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateTransmissaoLinkMutation,
  Types.UpdateTransmissaoLinkMutationVariables
>

/**
 * __useUpdateTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useUpdateTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransmissaoLinkMutation, { data, loading, error }] = useUpdateTransmissaoLinkMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useUpdateTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateTransmissaoLinkMutation,
    Types.UpdateTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.UpdateTransmissaoLinkMutation,
    Types.UpdateTransmissaoLinkMutationVariables
  >(UpdateTransmissaoLinkDocument, baseOptions)
}
export type UpdateTransmissaoLinkMutationHookResult = ReturnType<typeof useUpdateTransmissaoLinkMutation>
export type UpdateTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<Types.UpdateTransmissaoLinkMutation>
export type UpdateTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateTransmissaoLinkMutation,
  Types.UpdateTransmissaoLinkMutationVariables
>
export const TransmissaoRecebimentoLotesDocument = gql`
  query TransmissaoRecebimentoLotes($input: TransmissaoRecebimentoLoteFiltro!) {
    transmissaoRecebimentoLotes(input: $input) {
      content {
        id
        numeroLote
        responsavel
        processado
        data
        counts {
          fichasValidas
          fichasInvalidas
          totalFichas
          fichasRepetidas
        }
        origemLote {
          id
          descricao
        }
        credencialRecebimento {
          id
          nomeResponsavel
        }
        possuiInconsistencia
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTransmissaoRecebimentoLotesQuery__
 *
 * To run a query within a React component, call `useTransmissaoRecebimentoLotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoRecebimentoLotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoRecebimentoLotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoRecebimentoLotesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >(TransmissaoRecebimentoLotesDocument, baseOptions)
}
export function useTransmissaoRecebimentoLotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >(TransmissaoRecebimentoLotesDocument, baseOptions)
}
export type TransmissaoRecebimentoLotesQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoLotesQuery>
export type TransmissaoRecebimentoLotesLazyQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoLotesLazyQuery>
export type TransmissaoRecebimentoLotesQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoRecebimentoLotesQuery,
  Types.TransmissaoRecebimentoLotesQueryVariables
>
export const CredenciaisRecebimentoExternoDocument = gql`
  query CredenciaisRecebimentoExterno($input: CredencialRecebimentoExternoQueryInput!) {
    credenciaisRecebimentoExterno(input: $input) {
      content {
        id
        nomeResponsavel
        nomeCredencial
        dataCriacao
        email
        ativo
        tipoPessoa
        cpfCnpj
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCredenciaisRecebimentoExternoQuery__
 *
 * To run a query within a React component, call `useCredenciaisRecebimentoExternoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCredenciaisRecebimentoExternoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCredenciaisRecebimentoExternoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCredenciaisRecebimentoExternoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CredenciaisRecebimentoExternoQuery,
    Types.CredenciaisRecebimentoExternoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CredenciaisRecebimentoExternoQuery,
    Types.CredenciaisRecebimentoExternoQueryVariables
  >(CredenciaisRecebimentoExternoDocument, baseOptions)
}
export function useCredenciaisRecebimentoExternoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CredenciaisRecebimentoExternoQuery,
    Types.CredenciaisRecebimentoExternoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CredenciaisRecebimentoExternoQuery,
    Types.CredenciaisRecebimentoExternoQueryVariables
  >(CredenciaisRecebimentoExternoDocument, baseOptions)
}
export type CredenciaisRecebimentoExternoQueryHookResult = ReturnType<typeof useCredenciaisRecebimentoExternoQuery>
export type CredenciaisRecebimentoExternoLazyQueryHookResult = ReturnType<
  typeof useCredenciaisRecebimentoExternoLazyQuery
>
export type CredenciaisRecebimentoExternoQueryResult = ApolloReactCommon.QueryResult<
  Types.CredenciaisRecebimentoExternoQuery,
  Types.CredenciaisRecebimentoExternoQueryVariables
>
export const InativarCredencialRecebimentoExternoDocument = gql`
  mutation InativarCredencialRecebimentoExterno($id: ID!) {
    inativarCredencialRecebimentoExterno(id: $id) {
      id
    }
  }
`
export type InativarCredencialRecebimentoExternoMutationFn = ApolloReactCommon.MutationFunction<
  Types.InativarCredencialRecebimentoExternoMutation,
  Types.InativarCredencialRecebimentoExternoMutationVariables
>

/**
 * __useInativarCredencialRecebimentoExternoMutation__
 *
 * To run a mutation, you first call `useInativarCredencialRecebimentoExternoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInativarCredencialRecebimentoExternoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inativarCredencialRecebimentoExternoMutation, { data, loading, error }] = useInativarCredencialRecebimentoExternoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInativarCredencialRecebimentoExternoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InativarCredencialRecebimentoExternoMutation,
    Types.InativarCredencialRecebimentoExternoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.InativarCredencialRecebimentoExternoMutation,
    Types.InativarCredencialRecebimentoExternoMutationVariables
  >(InativarCredencialRecebimentoExternoDocument, baseOptions)
}
export type InativarCredencialRecebimentoExternoMutationHookResult = ReturnType<
  typeof useInativarCredencialRecebimentoExternoMutation
>
export type InativarCredencialRecebimentoExternoMutationResult = ApolloReactCommon.MutationResult<
  Types.InativarCredencialRecebimentoExternoMutation
>
export type InativarCredencialRecebimentoExternoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InativarCredencialRecebimentoExternoMutation,
  Types.InativarCredencialRecebimentoExternoMutationVariables
>
export const SalvarCredencialRecebimentoExternoDocument = gql`
  mutation SalvarCredencialRecebimentoExterno($input: CredencialRecebimentoExternoInput!) {
    salvarCredencialRecebimentoExterno(input: $input) {
      userPassword {
        user
        password
      }
    }
  }
`
export type SalvarCredencialRecebimentoExternoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarCredencialRecebimentoExternoMutation,
  Types.SalvarCredencialRecebimentoExternoMutationVariables
>

/**
 * __useSalvarCredencialRecebimentoExternoMutation__
 *
 * To run a mutation, you first call `useSalvarCredencialRecebimentoExternoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarCredencialRecebimentoExternoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarCredencialRecebimentoExternoMutation, { data, loading, error }] = useSalvarCredencialRecebimentoExternoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarCredencialRecebimentoExternoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarCredencialRecebimentoExternoMutation,
    Types.SalvarCredencialRecebimentoExternoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarCredencialRecebimentoExternoMutation,
    Types.SalvarCredencialRecebimentoExternoMutationVariables
  >(SalvarCredencialRecebimentoExternoDocument, baseOptions)
}
export type SalvarCredencialRecebimentoExternoMutationHookResult = ReturnType<
  typeof useSalvarCredencialRecebimentoExternoMutation
>
export type SalvarCredencialRecebimentoExternoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarCredencialRecebimentoExternoMutation
>
export type SalvarCredencialRecebimentoExternoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarCredencialRecebimentoExternoMutation,
  Types.SalvarCredencialRecebimentoExternoMutationVariables
>
export const AlterarHorarioGeracaoLotesProcessamentoFichasDocument = gql`
  mutation AlterarHorarioGeracaoLotesProcessamentoFichas($horario: LocalTime!) {
    alterarHorarioGeracaoLotesProcessamentoFichas(horario: $horario)
  }
`
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
>

/**
 * __useAlterarHorarioGeracaoLotesProcessamentoFichasMutation__
 *
 * To run a mutation, you first call `useAlterarHorarioGeracaoLotesProcessamentoFichasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarHorarioGeracaoLotesProcessamentoFichasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarHorarioGeracaoLotesProcessamentoFichasMutation, { data, loading, error }] = useAlterarHorarioGeracaoLotesProcessamentoFichasMutation({
 *   variables: {
 *      horario: // value for 'horario'
 *   },
 * });
 */
export function useAlterarHorarioGeracaoLotesProcessamentoFichasMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
  >(AlterarHorarioGeracaoLotesProcessamentoFichasDocument, baseOptions)
}
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationHookResult = ReturnType<
  typeof useAlterarHorarioGeracaoLotesProcessamentoFichasMutation
>
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation
>
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
>
export const LoadHorarioGeracaoLotesProcessamentoFichasDocument = gql`
  query LoadHorarioGeracaoLotesProcessamentoFichas {
    loadHorarioGeracaoLotesProcessamentoFichas
  }
`

/**
 * __useLoadHorarioGeracaoLotesProcessamentoFichasQuery__
 *
 * To run a query within a React component, call `useLoadHorarioGeracaoLotesProcessamentoFichasQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadHorarioGeracaoLotesProcessamentoFichasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadHorarioGeracaoLotesProcessamentoFichasQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadHorarioGeracaoLotesProcessamentoFichasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >(LoadHorarioGeracaoLotesProcessamentoFichasDocument, baseOptions)
}
export function useLoadHorarioGeracaoLotesProcessamentoFichasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >(LoadHorarioGeracaoLotesProcessamentoFichasDocument, baseOptions)
}
export type LoadHorarioGeracaoLotesProcessamentoFichasQueryHookResult = ReturnType<
  typeof useLoadHorarioGeracaoLotesProcessamentoFichasQuery
>
export type LoadHorarioGeracaoLotesProcessamentoFichasLazyQueryHookResult = ReturnType<
  typeof useLoadHorarioGeracaoLotesProcessamentoFichasLazyQuery
>
export type LoadHorarioGeracaoLotesProcessamentoFichasQueryResult = ApolloReactCommon.QueryResult<
  Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
  Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
>
export const LoteEnvioDocument = gql`
  query LoteEnvio($idLote: ID!, $filtroHistorico: HistoricoExportacaoFiltro!) {
    loteEnvio(idLote: $idLote) {
      id
      dataCriacao
      quantidadeFichas
      nodos {
        id
        dataEnvio
        nodeNome
        statusEnvio
      }
      historicoExportacao(filtro: $filtroHistorico) {
        content {
          id
          nomeProfissional
          dataExportacao
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLoteEnvioQuery__
 *
 * To run a query within a React component, call `useLoteEnvioQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteEnvioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteEnvioQuery({
 *   variables: {
 *      idLote: // value for 'idLote'
 *      filtroHistorico: // value for 'filtroHistorico'
 *   },
 * });
 */
export function useLoteEnvioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>(LoteEnvioDocument, baseOptions)
}
export function useLoteEnvioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>(
    LoteEnvioDocument,
    baseOptions
  )
}
export type LoteEnvioQueryHookResult = ReturnType<typeof useLoteEnvioQuery>
export type LoteEnvioLazyQueryHookResult = ReturnType<typeof useLoteEnvioLazyQuery>
export type LoteEnvioQueryResult = ApolloReactCommon.QueryResult<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>
export const GerarLotesDocument = gql`
  mutation GerarLotes {
    gerarLote
  }
`
export type GerarLotesMutationFn = ApolloReactCommon.MutationFunction<
  Types.GerarLotesMutation,
  Types.GerarLotesMutationVariables
>

/**
 * __useGerarLotesMutation__
 *
 * To run a mutation, you first call `useGerarLotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGerarLotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gerarLotesMutation, { data, loading, error }] = useGerarLotesMutation({
 *   variables: {
 *   },
 * });
 */
export function useGerarLotesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.GerarLotesMutation, Types.GerarLotesMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.GerarLotesMutation, Types.GerarLotesMutationVariables>(
    GerarLotesDocument,
    baseOptions
  )
}
export type GerarLotesMutationHookResult = ReturnType<typeof useGerarLotesMutation>
export type GerarLotesMutationResult = ApolloReactCommon.MutationResult<Types.GerarLotesMutation>
export type GerarLotesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.GerarLotesMutation,
  Types.GerarLotesMutationVariables
>
export const LotesEnvioDocument = gql`
  query LotesEnvio($input: LotesEnvioFiltro!) {
    lotesEnvio(input: $input) {
      content {
        id
        statusEnvio
        dataCriacao
        quantidadeFichas
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLotesEnvioQuery__
 *
 * To run a query within a React component, call `useLotesEnvioQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotesEnvioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotesEnvioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLotesEnvioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>(
    LotesEnvioDocument,
    baseOptions
  )
}
export function useLotesEnvioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>(
    LotesEnvioDocument,
    baseOptions
  )
}
export type LotesEnvioQueryHookResult = ReturnType<typeof useLotesEnvioQuery>
export type LotesEnvioLazyQueryHookResult = ReturnType<typeof useLotesEnvioLazyQuery>
export type LotesEnvioQueryResult = ApolloReactCommon.QueryResult<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>
export const TransmissaoRecebimentoCnesDetailItemsDocument = gql`
  query TransmissaoRecebimentoCnesDetailItems($input: TransmissaoRecebimentoCnesDetailFiltro!) {
    transmissaoRecebimentoCnesDetailItems(input: $input) {
      nomeEstabelecimento
      tipoFicha
      quantidade
    }
  }
`

/**
 * __useTransmissaoRecebimentoCnesDetailItemsQuery__
 *
 * To run a query within a React component, call `useTransmissaoRecebimentoCnesDetailItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoRecebimentoCnesDetailItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoRecebimentoCnesDetailItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoRecebimentoCnesDetailItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >(TransmissaoRecebimentoCnesDetailItemsDocument, baseOptions)
}
export function useTransmissaoRecebimentoCnesDetailItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >(TransmissaoRecebimentoCnesDetailItemsDocument, baseOptions)
}
export type TransmissaoRecebimentoCnesDetailItemsQueryHookResult = ReturnType<
  typeof useTransmissaoRecebimentoCnesDetailItemsQuery
>
export type TransmissaoRecebimentoCnesDetailItemsLazyQueryHookResult = ReturnType<
  typeof useTransmissaoRecebimentoCnesDetailItemsLazyQuery
>
export type TransmissaoRecebimentoCnesDetailItemsQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoRecebimentoCnesDetailItemsQuery,
  Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
>
export const TransmissaoRecebimentoCnesDocument = gql`
  query TransmissaoRecebimentoCnes($input: TransmissaoRecebimentoCnesFiltro!) {
    transmissaoRecebimentoCnes(input: $input) {
      content {
        numCnes
        mesRecebimento
        anoRecebimento
        fichasRecebidas
        estabelecimentoOrigem
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTransmissaoRecebimentoCnesQuery__
 *
 * To run a query within a React component, call `useTransmissaoRecebimentoCnesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoRecebimentoCnesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoRecebimentoCnesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoRecebimentoCnesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >(TransmissaoRecebimentoCnesDocument, baseOptions)
}
export function useTransmissaoRecebimentoCnesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >(TransmissaoRecebimentoCnesDocument, baseOptions)
}
export type TransmissaoRecebimentoCnesQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoCnesQuery>
export type TransmissaoRecebimentoCnesLazyQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoCnesLazyQuery>
export type TransmissaoRecebimentoCnesQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoRecebimentoCnesQuery,
  Types.TransmissaoRecebimentoCnesQueryVariables
>
export const ExistemFichasParaProcessarDocument = gql`
  query ExistemFichasParaProcessar {
    existemFichasParaProcessar
  }
`

/**
 * __useExistemFichasParaProcessarQuery__
 *
 * To run a query within a React component, call `useExistemFichasParaProcessarQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistemFichasParaProcessarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistemFichasParaProcessarQuery({
 *   variables: {
 *   },
 * });
 */
export function useExistemFichasParaProcessarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >(ExistemFichasParaProcessarDocument, baseOptions)
}
export function useExistemFichasParaProcessarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >(ExistemFichasParaProcessarDocument, baseOptions)
}
export type ExistemFichasParaProcessarQueryHookResult = ReturnType<typeof useExistemFichasParaProcessarQuery>
export type ExistemFichasParaProcessarLazyQueryHookResult = ReturnType<typeof useExistemFichasParaProcessarLazyQuery>
export type ExistemFichasParaProcessarQueryResult = ApolloReactCommon.QueryResult<
  Types.ExistemFichasParaProcessarQuery,
  Types.ExistemFichasParaProcessarQueryVariables
>
export const TransmissaoDocument = gql`
  query Transmissao {
    transmissao {
      id
      importacaoArquivo {
        dataEvento
        status
        message
      }
    }
  }
`

/**
 * __useTransmissaoQuery__
 *
 * To run a query within a React component, call `useTransmissaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransmissaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>(
    TransmissaoDocument,
    baseOptions
  )
}
export function useTransmissaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>(
    TransmissaoDocument,
    baseOptions
  )
}
export type TransmissaoQueryHookResult = ReturnType<typeof useTransmissaoQuery>
export type TransmissaoLazyQueryHookResult = ReturnType<typeof useTransmissaoLazyQuery>
export type TransmissaoQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoQuery,
  Types.TransmissaoQueryVariables
>
export const AtivarTipoServicoDocument = gql`
  mutation AtivarTipoServico($unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput!) {
    ativarTipoServico(unidadeSaudeTipoServicoFormInput: $unidadeSaudeTipoServicoFormInput) {
      unidadeSaudeId
      tipoServicoId
    }
  }
`
export type AtivarTipoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtivarTipoServicoMutation,
  Types.AtivarTipoServicoMutationVariables
>

/**
 * __useAtivarTipoServicoMutation__
 *
 * To run a mutation, you first call `useAtivarTipoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtivarTipoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ativarTipoServicoMutation, { data, loading, error }] = useAtivarTipoServicoMutation({
 *   variables: {
 *      unidadeSaudeTipoServicoFormInput: // value for 'unidadeSaudeTipoServicoFormInput'
 *   },
 * });
 */
export function useAtivarTipoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtivarTipoServicoMutation,
    Types.AtivarTipoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtivarTipoServicoMutation, Types.AtivarTipoServicoMutationVariables>(
    AtivarTipoServicoDocument,
    baseOptions
  )
}
export type AtivarTipoServicoMutationHookResult = ReturnType<typeof useAtivarTipoServicoMutation>
export type AtivarTipoServicoMutationResult = ApolloReactCommon.MutationResult<Types.AtivarTipoServicoMutation>
export type AtivarTipoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtivarTipoServicoMutation,
  Types.AtivarTipoServicoMutationVariables
>
export const DesativarTipoServicoDocument = gql`
  mutation DesativarTipoServico($unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput!) {
    desativarTipoServico(unidadeSaudeTipoServicoFormInput: $unidadeSaudeTipoServicoFormInput) {
      unidadeSaudeId
      tipoServicoId
    }
  }
`
export type DesativarTipoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.DesativarTipoServicoMutation,
  Types.DesativarTipoServicoMutationVariables
>

/**
 * __useDesativarTipoServicoMutation__
 *
 * To run a mutation, you first call `useDesativarTipoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesativarTipoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desativarTipoServicoMutation, { data, loading, error }] = useDesativarTipoServicoMutation({
 *   variables: {
 *      unidadeSaudeTipoServicoFormInput: // value for 'unidadeSaudeTipoServicoFormInput'
 *   },
 * });
 */
export function useDesativarTipoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DesativarTipoServicoMutation,
    Types.DesativarTipoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.DesativarTipoServicoMutation, Types.DesativarTipoServicoMutationVariables>(
    DesativarTipoServicoDocument,
    baseOptions
  )
}
export type DesativarTipoServicoMutationHookResult = ReturnType<typeof useDesativarTipoServicoMutation>
export type DesativarTipoServicoMutationResult = ApolloReactCommon.MutationResult<Types.DesativarTipoServicoMutation>
export type DesativarTipoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DesativarTipoServicoMutation,
  Types.DesativarTipoServicoMutationVariables
>
export const UnidadeSaudeDetailDocument = gql`
  query UnidadeSaudeDetail($id: ID!) {
    unidadeSaude(id: $id) {
      id
      nome
      cnes
      cnpj
      email
      telefone1
      telefone2
      fax
      tipo {
        id
        descricao
      }
      subtipo {
        id
        codigoMs
        tipoUnidadeSaude {
          id
        }
        descricao
      }
      tipoEstabelecimento
      complexidades {
        id
        nome
      }
      ativo
      endereco {
        cep
        municipio {
          nome
        }
        uf {
          sigla
        }
        tipoLogradouro {
          nome
        }
        logradouro
        numero
        semNumero
        complemento
        pontoReferencia
        bairro
      }
      actions {
        permissaoAtivarInativarTipoServico {
          enabled
          hint
        }
      }
    }
  }
`

/**
 * __useUnidadeSaudeDetailQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnidadeSaudeDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UnidadeSaudeDetailQuery, Types.UnidadeSaudeDetailQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.UnidadeSaudeDetailQuery, Types.UnidadeSaudeDetailQueryVariables>(
    UnidadeSaudeDetailDocument,
    baseOptions
  )
}
export function useUnidadeSaudeDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeDetailQuery,
    Types.UnidadeSaudeDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnidadeSaudeDetailQuery, Types.UnidadeSaudeDetailQueryVariables>(
    UnidadeSaudeDetailDocument,
    baseOptions
  )
}
export type UnidadeSaudeDetailQueryHookResult = ReturnType<typeof useUnidadeSaudeDetailQuery>
export type UnidadeSaudeDetailLazyQueryHookResult = ReturnType<typeof useUnidadeSaudeDetailLazyQuery>
export type UnidadeSaudeDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeDetailQuery,
  Types.UnidadeSaudeDetailQueryVariables
>
export const UnidadeSaudeTipoServicoDocument = gql`
  query UnidadeSaudeTipoServico($input: UnidadeSaudeTipoServicoQueryInput!) {
    unidadeSaudeTiposServico(input: $input) {
      content {
        tipoServico {
          id
          nome
        }
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUnidadeSaudeTipoServicoQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeTipoServicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeTipoServicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeTipoServicoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadeSaudeTipoServicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadeSaudeTipoServicoQuery,
    Types.UnidadeSaudeTipoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UnidadeSaudeTipoServicoQuery, Types.UnidadeSaudeTipoServicoQueryVariables>(
    UnidadeSaudeTipoServicoDocument,
    baseOptions
  )
}
export function useUnidadeSaudeTipoServicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeTipoServicoQuery,
    Types.UnidadeSaudeTipoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnidadeSaudeTipoServicoQuery, Types.UnidadeSaudeTipoServicoQueryVariables>(
    UnidadeSaudeTipoServicoDocument,
    baseOptions
  )
}
export type UnidadeSaudeTipoServicoQueryHookResult = ReturnType<typeof useUnidadeSaudeTipoServicoQuery>
export type UnidadeSaudeTipoServicoLazyQueryHookResult = ReturnType<typeof useUnidadeSaudeTipoServicoLazyQuery>
export type UnidadeSaudeTipoServicoQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeTipoServicoQuery,
  Types.UnidadeSaudeTipoServicoQueryVariables
>
export const UnidadeSaudeTableDocument = gql`
  query UnidadeSaudeTable($input: UnidadesSaudeQueryInput!) {
    unidadesSaude(input: $input) {
      content {
        id
        cnes
        nome
        telefone1
        telefone2
        endereco {
          bairro
        }
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUnidadeSaudeTableQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadeSaudeTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UnidadeSaudeTableQuery, Types.UnidadeSaudeTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.UnidadeSaudeTableQuery, Types.UnidadeSaudeTableQueryVariables>(
    UnidadeSaudeTableDocument,
    baseOptions
  )
}
export function useUnidadeSaudeTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeTableQuery,
    Types.UnidadeSaudeTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnidadeSaudeTableQuery, Types.UnidadeSaudeTableQueryVariables>(
    UnidadeSaudeTableDocument,
    baseOptions
  )
}
export type UnidadeSaudeTableQueryHookResult = ReturnType<typeof useUnidadeSaudeTableQuery>
export type UnidadeSaudeTableLazyQueryHookResult = ReturnType<typeof useUnidadeSaudeTableLazyQuery>
export type UnidadeSaudeTableQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeTableQuery,
  Types.UnidadeSaudeTableQueryVariables
>
export const NomesInstalacoesUnificacaoBaseDocument = gql`
  query NomesInstalacoesUnificacaoBase {
    nomesInstalacoes {
      nomeInstalacaoCentral
      nomesInstalacoesUnificadas
    }
  }
`

/**
 * __useNomesInstalacoesUnificacaoBaseQuery__
 *
 * To run a query within a React component, call `useNomesInstalacoesUnificacaoBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useNomesInstalacoesUnificacaoBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNomesInstalacoesUnificacaoBaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useNomesInstalacoesUnificacaoBaseQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.NomesInstalacoesUnificacaoBaseQuery,
    Types.NomesInstalacoesUnificacaoBaseQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.NomesInstalacoesUnificacaoBaseQuery,
    Types.NomesInstalacoesUnificacaoBaseQueryVariables
  >(NomesInstalacoesUnificacaoBaseDocument, baseOptions)
}
export function useNomesInstalacoesUnificacaoBaseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.NomesInstalacoesUnificacaoBaseQuery,
    Types.NomesInstalacoesUnificacaoBaseQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.NomesInstalacoesUnificacaoBaseQuery,
    Types.NomesInstalacoesUnificacaoBaseQueryVariables
  >(NomesInstalacoesUnificacaoBaseDocument, baseOptions)
}
export type NomesInstalacoesUnificacaoBaseQueryHookResult = ReturnType<typeof useNomesInstalacoesUnificacaoBaseQuery>
export type NomesInstalacoesUnificacaoBaseLazyQueryHookResult = ReturnType<
  typeof useNomesInstalacoesUnificacaoBaseLazyQuery
>
export type NomesInstalacoesUnificacaoBaseQueryResult = ApolloReactCommon.QueryResult<
  Types.NomesInstalacoesUnificacaoBaseQuery,
  Types.NomesInstalacoesUnificacaoBaseQueryVariables
>
export const UnificacaoBaseCadastrosDuplicadosDocument = gql`
  query UnificacaoBaseCadastrosDuplicados($input: CidadaoUnificacaoBaseInput!) {
    cadastrosDuplicados(input: $input) {
      content {
        id
        cpf
        cns
        cidadao {
          nome
          nomeSocial
          nomeMae
          dataNascimento
          sexo
          identidadeGeneroDbEnum
          ativo
          faleceu
          unificado
          unificacaoBase
          localidadeNascimento {
            id
            nome
            uf {
              id
              sigla
            }
          }
          cidadaoVinculacaoEquipe {
            id
            unidadeSaude {
              id
              nome
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUnificacaoBaseCadastrosDuplicadosQuery__
 *
 * To run a query within a React component, call `useUnificacaoBaseCadastrosDuplicadosQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnificacaoBaseCadastrosDuplicadosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnificacaoBaseCadastrosDuplicadosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnificacaoBaseCadastrosDuplicadosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnificacaoBaseCadastrosDuplicadosQuery,
    Types.UnificacaoBaseCadastrosDuplicadosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.UnificacaoBaseCadastrosDuplicadosQuery,
    Types.UnificacaoBaseCadastrosDuplicadosQueryVariables
  >(UnificacaoBaseCadastrosDuplicadosDocument, baseOptions)
}
export function useUnificacaoBaseCadastrosDuplicadosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnificacaoBaseCadastrosDuplicadosQuery,
    Types.UnificacaoBaseCadastrosDuplicadosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnificacaoBaseCadastrosDuplicadosQuery,
    Types.UnificacaoBaseCadastrosDuplicadosQueryVariables
  >(UnificacaoBaseCadastrosDuplicadosDocument, baseOptions)
}
export type UnificacaoBaseCadastrosDuplicadosQueryHookResult = ReturnType<
  typeof useUnificacaoBaseCadastrosDuplicadosQuery
>
export type UnificacaoBaseCadastrosDuplicadosLazyQueryHookResult = ReturnType<
  typeof useUnificacaoBaseCadastrosDuplicadosLazyQuery
>
export type UnificacaoBaseCadastrosDuplicadosQueryResult = ApolloReactCommon.QueryResult<
  Types.UnificacaoBaseCadastrosDuplicadosQuery,
  Types.UnificacaoBaseCadastrosDuplicadosQueryVariables
>
export const UnificaCadastrosDocument = gql`
  mutation UnificaCadastros($input: UnificacaoCadastroInput!) {
    unificaCadastros(input: $input)
  }
`
export type UnificaCadastrosMutationFn = ApolloReactCommon.MutationFunction<
  Types.UnificaCadastrosMutation,
  Types.UnificaCadastrosMutationVariables
>

/**
 * __useUnificaCadastrosMutation__
 *
 * To run a mutation, you first call `useUnificaCadastrosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnificaCadastrosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unificaCadastrosMutation, { data, loading, error }] = useUnificaCadastrosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnificaCadastrosMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UnificaCadastrosMutation,
    Types.UnificaCadastrosMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.UnificaCadastrosMutation, Types.UnificaCadastrosMutationVariables>(
    UnificaCadastrosDocument,
    baseOptions
  )
}
export type UnificaCadastrosMutationHookResult = ReturnType<typeof useUnificaCadastrosMutation>
export type UnificaCadastrosMutationResult = ApolloReactCommon.MutationResult<Types.UnificaCadastrosMutation>
export type UnificaCadastrosMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UnificaCadastrosMutation,
  Types.UnificaCadastrosMutationVariables
>
export const UnificacaoCidadaosListingDocument = gql`
  query UnificacaoCidadaosListing($input: CidadaosQueryInput!) {
    listagemCidadaos(input: $input) {
      content {
        id
        nome
        nomeSocial
        cpf
        cns
        nomeMae
        dataNascimento
        telefoneCelular
        telefoneResidencial
        sexo
        identidadeGeneroDbEnum
        dataAtualizado
        ativo
        unificado
        unificacaoBase
        faleceu
        prontuario {
          id
        }
        localidadeNascimento {
          id
          nome
          uf {
            id
            nome
            sigla
          }
        }
        cidadaoVinculacaoEquipe {
          id
          dataAtualizacaoCadastro
          unidadeSaude {
            id
            nome
          }
          equipe {
            id
            nome
            ine
          }
        }
        uuidUltimaFicha
      }
    }
  }
`

/**
 * __useUnificacaoCidadaosListingQuery__
 *
 * To run a query within a React component, call `useUnificacaoCidadaosListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnificacaoCidadaosListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnificacaoCidadaosListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnificacaoCidadaosListingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnificacaoCidadaosListingQuery,
    Types.UnificacaoCidadaosListingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UnificacaoCidadaosListingQuery, Types.UnificacaoCidadaosListingQueryVariables>(
    UnificacaoCidadaosListingDocument,
    baseOptions
  )
}
export function useUnificacaoCidadaosListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnificacaoCidadaosListingQuery,
    Types.UnificacaoCidadaosListingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnificacaoCidadaosListingQuery,
    Types.UnificacaoCidadaosListingQueryVariables
  >(UnificacaoCidadaosListingDocument, baseOptions)
}
export type UnificacaoCidadaosListingQueryHookResult = ReturnType<typeof useUnificacaoCidadaosListingQuery>
export type UnificacaoCidadaosListingLazyQueryHookResult = ReturnType<typeof useUnificacaoCidadaosListingLazyQuery>
export type UnificacaoCidadaosListingQueryResult = ApolloReactCommon.QueryResult<
  Types.UnificacaoCidadaosListingQuery,
  Types.UnificacaoCidadaosListingQueryVariables
>
export const CancelarSolicitacaoEntradaVideochamadaDocument = gql`
  mutation CancelarSolicitacaoEntradaVideochamada($input: CancelarSolicitacaoEntradaVideochamadaInput!) {
    cancelarSolicitacaoEntradaVideochamada(input: $input)
  }
`
export type CancelarSolicitacaoEntradaVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.CancelarSolicitacaoEntradaVideochamadaMutation,
  Types.CancelarSolicitacaoEntradaVideochamadaMutationVariables
>

/**
 * __useCancelarSolicitacaoEntradaVideochamadaMutation__
 *
 * To run a mutation, you first call `useCancelarSolicitacaoEntradaVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelarSolicitacaoEntradaVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelarSolicitacaoEntradaVideochamadaMutation, { data, loading, error }] = useCancelarSolicitacaoEntradaVideochamadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelarSolicitacaoEntradaVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CancelarSolicitacaoEntradaVideochamadaMutation,
    Types.CancelarSolicitacaoEntradaVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.CancelarSolicitacaoEntradaVideochamadaMutation,
    Types.CancelarSolicitacaoEntradaVideochamadaMutationVariables
  >(CancelarSolicitacaoEntradaVideochamadaDocument, baseOptions)
}
export type CancelarSolicitacaoEntradaVideochamadaMutationHookResult = ReturnType<
  typeof useCancelarSolicitacaoEntradaVideochamadaMutation
>
export type CancelarSolicitacaoEntradaVideochamadaMutationResult = ApolloReactCommon.MutationResult<
  Types.CancelarSolicitacaoEntradaVideochamadaMutation
>
export type CancelarSolicitacaoEntradaVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CancelarSolicitacaoEntradaVideochamadaMutation,
  Types.CancelarSolicitacaoEntradaVideochamadaMutationVariables
>
export const EncerrarMinhasVideochamadasDocument = gql`
  mutation encerrarMinhasVideochamadas {
    encerrarMinhasVideochamadas
  }
`
export type EncerrarMinhasVideochamadasMutationFn = ApolloReactCommon.MutationFunction<
  Types.EncerrarMinhasVideochamadasMutation,
  Types.EncerrarMinhasVideochamadasMutationVariables
>

/**
 * __useEncerrarMinhasVideochamadasMutation__
 *
 * To run a mutation, you first call `useEncerrarMinhasVideochamadasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEncerrarMinhasVideochamadasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [encerrarMinhasVideochamadasMutation, { data, loading, error }] = useEncerrarMinhasVideochamadasMutation({
 *   variables: {
 *   },
 * });
 */
export function useEncerrarMinhasVideochamadasMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EncerrarMinhasVideochamadasMutation,
    Types.EncerrarMinhasVideochamadasMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.EncerrarMinhasVideochamadasMutation,
    Types.EncerrarMinhasVideochamadasMutationVariables
  >(EncerrarMinhasVideochamadasDocument, baseOptions)
}
export type EncerrarMinhasVideochamadasMutationHookResult = ReturnType<typeof useEncerrarMinhasVideochamadasMutation>
export type EncerrarMinhasVideochamadasMutationResult = ApolloReactCommon.MutationResult<
  Types.EncerrarMinhasVideochamadasMutation
>
export type EncerrarMinhasVideochamadasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EncerrarMinhasVideochamadasMutation,
  Types.EncerrarMinhasVideochamadasMutationVariables
>
export const EncerrarVideochamadaDocument = gql`
  mutation encerrarVideochamada($videochamadaUuid: String!) {
    encerrarVideochamada(videochamadaUuid: $videochamadaUuid)
  }
`
export type EncerrarVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.EncerrarVideochamadaMutation,
  Types.EncerrarVideochamadaMutationVariables
>

/**
 * __useEncerrarVideochamadaMutation__
 *
 * To run a mutation, you first call `useEncerrarVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEncerrarVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [encerrarVideochamadaMutation, { data, loading, error }] = useEncerrarVideochamadaMutation({
 *   variables: {
 *      videochamadaUuid: // value for 'videochamadaUuid'
 *   },
 * });
 */
export function useEncerrarVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.EncerrarVideochamadaMutation,
    Types.EncerrarVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.EncerrarVideochamadaMutation, Types.EncerrarVideochamadaMutationVariables>(
    EncerrarVideochamadaDocument,
    baseOptions
  )
}
export type EncerrarVideochamadaMutationHookResult = ReturnType<typeof useEncerrarVideochamadaMutation>
export type EncerrarVideochamadaMutationResult = ApolloReactCommon.MutationResult<Types.EncerrarVideochamadaMutation>
export type EncerrarVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.EncerrarVideochamadaMutation,
  Types.EncerrarVideochamadaMutationVariables
>
export const IniciarVideochamadaDocument = gql`
  mutation IniciarVideochamada($input: IniciarVideochamadaInput!) {
    iniciarVideochamada(input: $input)
  }
`
export type IniciarVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.IniciarVideochamadaMutation,
  Types.IniciarVideochamadaMutationVariables
>

/**
 * __useIniciarVideochamadaMutation__
 *
 * To run a mutation, you first call `useIniciarVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIniciarVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iniciarVideochamadaMutation, { data, loading, error }] = useIniciarVideochamadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIniciarVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.IniciarVideochamadaMutation,
    Types.IniciarVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.IniciarVideochamadaMutation, Types.IniciarVideochamadaMutationVariables>(
    IniciarVideochamadaDocument,
    baseOptions
  )
}
export type IniciarVideochamadaMutationHookResult = ReturnType<typeof useIniciarVideochamadaMutation>
export type IniciarVideochamadaMutationResult = ApolloReactCommon.MutationResult<Types.IniciarVideochamadaMutation>
export type IniciarVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.IniciarVideochamadaMutation,
  Types.IniciarVideochamadaMutationVariables
>
export const ResponderSolicitacaoEntradaVideochamadaDocument = gql`
  mutation ResponderSolicitacaoEntradaVideochamada($input: ResponderSolicitacaoEntradaVideochamadaInput!) {
    responderSolicitacaoEntradaVideochamada(input: $input)
  }
`
export type ResponderSolicitacaoEntradaVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.ResponderSolicitacaoEntradaVideochamadaMutation,
  Types.ResponderSolicitacaoEntradaVideochamadaMutationVariables
>

/**
 * __useResponderSolicitacaoEntradaVideochamadaMutation__
 *
 * To run a mutation, you first call `useResponderSolicitacaoEntradaVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResponderSolicitacaoEntradaVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [responderSolicitacaoEntradaVideochamadaMutation, { data, loading, error }] = useResponderSolicitacaoEntradaVideochamadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResponderSolicitacaoEntradaVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ResponderSolicitacaoEntradaVideochamadaMutation,
    Types.ResponderSolicitacaoEntradaVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ResponderSolicitacaoEntradaVideochamadaMutation,
    Types.ResponderSolicitacaoEntradaVideochamadaMutationVariables
  >(ResponderSolicitacaoEntradaVideochamadaDocument, baseOptions)
}
export type ResponderSolicitacaoEntradaVideochamadaMutationHookResult = ReturnType<
  typeof useResponderSolicitacaoEntradaVideochamadaMutation
>
export type ResponderSolicitacaoEntradaVideochamadaMutationResult = ApolloReactCommon.MutationResult<
  Types.ResponderSolicitacaoEntradaVideochamadaMutation
>
export type ResponderSolicitacaoEntradaVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ResponderSolicitacaoEntradaVideochamadaMutation,
  Types.ResponderSolicitacaoEntradaVideochamadaMutationVariables
>
export const VideochamadaViewDocument = gql`
  query VideochamadaView($uuid: String!) {
    videochamada(uuid: $uuid) {
      id
      dataInicio
      dataFim
      agendamento {
        id
        horarioInicial
      }
      owner {
        id
        profissional {
          id
          nome
          cpf
        }
        ... on Lotacao {
          cbo {
            id
            nome
          }
        }
        ... on Estagio {
          cbo {
            id
            nome
          }
        }
      }
    }
  }
`

/**
 * __useVideochamadaViewQuery__
 *
 * To run a query within a React component, call `useVideochamadaViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideochamadaViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideochamadaViewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useVideochamadaViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.VideochamadaViewQuery, Types.VideochamadaViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.VideochamadaViewQuery, Types.VideochamadaViewQueryVariables>(
    VideochamadaViewDocument,
    baseOptions
  )
}
export function useVideochamadaViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.VideochamadaViewQuery, Types.VideochamadaViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.VideochamadaViewQuery, Types.VideochamadaViewQueryVariables>(
    VideochamadaViewDocument,
    baseOptions
  )
}
export type VideochamadaViewQueryHookResult = ReturnType<typeof useVideochamadaViewQuery>
export type VideochamadaViewLazyQueryHookResult = ReturnType<typeof useVideochamadaViewLazyQuery>
export type VideochamadaViewQueryResult = ApolloReactCommon.QueryResult<
  Types.VideochamadaViewQuery,
  Types.VideochamadaViewQueryVariables
>
export const SolicitarEntradaVideochamadaDocument = gql`
  mutation SolicitarEntradaVideochamada($input: SolicitarEntradaVideochamadaInput!) {
    solicitarEntradaVideochamada(input: $input) {
      id
      nome
      cpf
    }
  }
`
export type SolicitarEntradaVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.SolicitarEntradaVideochamadaMutation,
  Types.SolicitarEntradaVideochamadaMutationVariables
>

/**
 * __useSolicitarEntradaVideochamadaMutation__
 *
 * To run a mutation, you first call `useSolicitarEntradaVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSolicitarEntradaVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [solicitarEntradaVideochamadaMutation, { data, loading, error }] = useSolicitarEntradaVideochamadaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSolicitarEntradaVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SolicitarEntradaVideochamadaMutation,
    Types.SolicitarEntradaVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SolicitarEntradaVideochamadaMutation,
    Types.SolicitarEntradaVideochamadaMutationVariables
  >(SolicitarEntradaVideochamadaDocument, baseOptions)
}
export type SolicitarEntradaVideochamadaMutationHookResult = ReturnType<typeof useSolicitarEntradaVideochamadaMutation>
export type SolicitarEntradaVideochamadaMutationResult = ApolloReactCommon.MutationResult<
  Types.SolicitarEntradaVideochamadaMutation
>
export type SolicitarEntradaVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SolicitarEntradaVideochamadaMutation,
  Types.SolicitarEntradaVideochamadaMutationVariables
>
export const ConfiguracaoVideochamadasDocument = gql`
  query ConfiguracaoVideochamadas {
    conexao {
      videochamadas {
        id
        habilitado
      }
    }
  }
`

/**
 * __useConfiguracaoVideochamadasQuery__
 *
 * To run a query within a React component, call `useConfiguracaoVideochamadasQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoVideochamadasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoVideochamadasQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoVideochamadasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoVideochamadasQuery,
    Types.ConfiguracaoVideochamadasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoVideochamadasQuery, Types.ConfiguracaoVideochamadasQueryVariables>(
    ConfiguracaoVideochamadasDocument,
    baseOptions
  )
}
export function useConfiguracaoVideochamadasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoVideochamadasQuery,
    Types.ConfiguracaoVideochamadasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoVideochamadasQuery,
    Types.ConfiguracaoVideochamadasQueryVariables
  >(ConfiguracaoVideochamadasDocument, baseOptions)
}
export type ConfiguracaoVideochamadasQueryHookResult = ReturnType<typeof useConfiguracaoVideochamadasQuery>
export type ConfiguracaoVideochamadasLazyQueryHookResult = ReturnType<typeof useConfiguracaoVideochamadasLazyQuery>
export type ConfiguracaoVideochamadasQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoVideochamadasQuery,
  Types.ConfiguracaoVideochamadasQueryVariables
>
export const ConfiguracoesIceServersDocument = gql`
  query ConfiguracoesIceServers($input: IceServersConfigurationInput!) {
    iceServersConfiguration(input: $input) {
      urls
      user
      password
    }
  }
`

/**
 * __useConfiguracoesIceServersQuery__
 *
 * To run a query within a React component, call `useConfiguracoesIceServersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracoesIceServersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracoesIceServersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfiguracoesIceServersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracoesIceServersQuery,
    Types.ConfiguracoesIceServersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracoesIceServersQuery, Types.ConfiguracoesIceServersQueryVariables>(
    ConfiguracoesIceServersDocument,
    baseOptions
  )
}
export function useConfiguracoesIceServersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracoesIceServersQuery,
    Types.ConfiguracoesIceServersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracoesIceServersQuery, Types.ConfiguracoesIceServersQueryVariables>(
    ConfiguracoesIceServersDocument,
    baseOptions
  )
}
export type ConfiguracoesIceServersQueryHookResult = ReturnType<typeof useConfiguracoesIceServersQuery>
export type ConfiguracoesIceServersLazyQueryHookResult = ReturnType<typeof useConfiguracoesIceServersLazyQuery>
export type ConfiguracoesIceServersQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracoesIceServersQuery,
  Types.ConfiguracoesIceServersQueryVariables
>
export const IsParticipantePermitidoVideochamadaDocument = gql`
  query IsParticipantePermitidoVideochamada($input: VerificarParticipantePermitidoVideochamadaInput!) {
    isParticipantePermitidoVideochamada(input: $input)
  }
`

/**
 * __useIsParticipantePermitidoVideochamadaQuery__
 *
 * To run a query within a React component, call `useIsParticipantePermitidoVideochamadaQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsParticipantePermitidoVideochamadaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsParticipantePermitidoVideochamadaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsParticipantePermitidoVideochamadaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.IsParticipantePermitidoVideochamadaQuery,
    Types.IsParticipantePermitidoVideochamadaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.IsParticipantePermitidoVideochamadaQuery,
    Types.IsParticipantePermitidoVideochamadaQueryVariables
  >(IsParticipantePermitidoVideochamadaDocument, baseOptions)
}
export function useIsParticipantePermitidoVideochamadaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.IsParticipantePermitidoVideochamadaQuery,
    Types.IsParticipantePermitidoVideochamadaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.IsParticipantePermitidoVideochamadaQuery,
    Types.IsParticipantePermitidoVideochamadaQueryVariables
  >(IsParticipantePermitidoVideochamadaDocument, baseOptions)
}
export type IsParticipantePermitidoVideochamadaQueryHookResult = ReturnType<
  typeof useIsParticipantePermitidoVideochamadaQuery
>
export type IsParticipantePermitidoVideochamadaLazyQueryHookResult = ReturnType<
  typeof useIsParticipantePermitidoVideochamadaLazyQuery
>
export type IsParticipantePermitidoVideochamadaQueryResult = ApolloReactCommon.QueryResult<
  Types.IsParticipantePermitidoVideochamadaQuery,
  Types.IsParticipantePermitidoVideochamadaQueryVariables
>
export const NotificarConexaoVideochamadaFalhouDocument = gql`
  mutation NotificarConexaoVideochamadaFalhou($videochamadaUuid: String!) {
    notifyConexaoVideochamadaFalhou(videochamadaUuid: $videochamadaUuid)
  }
`
export type NotificarConexaoVideochamadaFalhouMutationFn = ApolloReactCommon.MutationFunction<
  Types.NotificarConexaoVideochamadaFalhouMutation,
  Types.NotificarConexaoVideochamadaFalhouMutationVariables
>

/**
 * __useNotificarConexaoVideochamadaFalhouMutation__
 *
 * To run a mutation, you first call `useNotificarConexaoVideochamadaFalhouMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificarConexaoVideochamadaFalhouMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificarConexaoVideochamadaFalhouMutation, { data, loading, error }] = useNotificarConexaoVideochamadaFalhouMutation({
 *   variables: {
 *      videochamadaUuid: // value for 'videochamadaUuid'
 *   },
 * });
 */
export function useNotificarConexaoVideochamadaFalhouMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.NotificarConexaoVideochamadaFalhouMutation,
    Types.NotificarConexaoVideochamadaFalhouMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.NotificarConexaoVideochamadaFalhouMutation,
    Types.NotificarConexaoVideochamadaFalhouMutationVariables
  >(NotificarConexaoVideochamadaFalhouDocument, baseOptions)
}
export type NotificarConexaoVideochamadaFalhouMutationHookResult = ReturnType<
  typeof useNotificarConexaoVideochamadaFalhouMutation
>
export type NotificarConexaoVideochamadaFalhouMutationResult = ApolloReactCommon.MutationResult<
  Types.NotificarConexaoVideochamadaFalhouMutation
>
export type NotificarConexaoVideochamadaFalhouMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.NotificarConexaoVideochamadaFalhouMutation,
  Types.NotificarConexaoVideochamadaFalhouMutationVariables
>
export const NotificarEntradaParticipanteVideochamadaDocument = gql`
  mutation NotificarEntradaParticipanteVideochamada(
    $videochamadaUuid: String!
    $participanteId: ID!
    $nomeParticipante: String!
  ) {
    notifyEntradaParticipanteVideochamada(
      videochamadaUuid: $videochamadaUuid
      participanteId: $participanteId
      nomeParticipante: $nomeParticipante
    )
  }
`
export type NotificarEntradaParticipanteVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.NotificarEntradaParticipanteVideochamadaMutation,
  Types.NotificarEntradaParticipanteVideochamadaMutationVariables
>

/**
 * __useNotificarEntradaParticipanteVideochamadaMutation__
 *
 * To run a mutation, you first call `useNotificarEntradaParticipanteVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificarEntradaParticipanteVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificarEntradaParticipanteVideochamadaMutation, { data, loading, error }] = useNotificarEntradaParticipanteVideochamadaMutation({
 *   variables: {
 *      videochamadaUuid: // value for 'videochamadaUuid'
 *      participanteId: // value for 'participanteId'
 *      nomeParticipante: // value for 'nomeParticipante'
 *   },
 * });
 */
export function useNotificarEntradaParticipanteVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.NotificarEntradaParticipanteVideochamadaMutation,
    Types.NotificarEntradaParticipanteVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.NotificarEntradaParticipanteVideochamadaMutation,
    Types.NotificarEntradaParticipanteVideochamadaMutationVariables
  >(NotificarEntradaParticipanteVideochamadaDocument, baseOptions)
}
export type NotificarEntradaParticipanteVideochamadaMutationHookResult = ReturnType<
  typeof useNotificarEntradaParticipanteVideochamadaMutation
>
export type NotificarEntradaParticipanteVideochamadaMutationResult = ApolloReactCommon.MutationResult<
  Types.NotificarEntradaParticipanteVideochamadaMutation
>
export type NotificarEntradaParticipanteVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.NotificarEntradaParticipanteVideochamadaMutation,
  Types.NotificarEntradaParticipanteVideochamadaMutationVariables
>
export const NotificarSaidaDonoVideochamadaDocument = gql`
  mutation NotificarSaidaDonoVideochamada($videochamadaUuid: String!) {
    notifySaidaDonoVideochamada(videochamadaUuid: $videochamadaUuid)
  }
`
export type NotificarSaidaDonoVideochamadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.NotificarSaidaDonoVideochamadaMutation,
  Types.NotificarSaidaDonoVideochamadaMutationVariables
>

/**
 * __useNotificarSaidaDonoVideochamadaMutation__
 *
 * To run a mutation, you first call `useNotificarSaidaDonoVideochamadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificarSaidaDonoVideochamadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificarSaidaDonoVideochamadaMutation, { data, loading, error }] = useNotificarSaidaDonoVideochamadaMutation({
 *   variables: {
 *      videochamadaUuid: // value for 'videochamadaUuid'
 *   },
 * });
 */
export function useNotificarSaidaDonoVideochamadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.NotificarSaidaDonoVideochamadaMutation,
    Types.NotificarSaidaDonoVideochamadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.NotificarSaidaDonoVideochamadaMutation,
    Types.NotificarSaidaDonoVideochamadaMutationVariables
  >(NotificarSaidaDonoVideochamadaDocument, baseOptions)
}
export type NotificarSaidaDonoVideochamadaMutationHookResult = ReturnType<
  typeof useNotificarSaidaDonoVideochamadaMutation
>
export type NotificarSaidaDonoVideochamadaMutationResult = ApolloReactCommon.MutationResult<
  Types.NotificarSaidaDonoVideochamadaMutation
>
export type NotificarSaidaDonoVideochamadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.NotificarSaidaDonoVideochamadaMutation,
  Types.NotificarSaidaDonoVideochamadaMutationVariables
>
export const NotificarVideochamadaLotadaDocument = gql`
  mutation NotificarVideochamadaLotada($videochamadaUuid: String!, $lotada: Boolean!) {
    notifyVideochamadaLotada(videochamadaUuid: $videochamadaUuid, lotada: $lotada)
  }
`
export type NotificarVideochamadaLotadaMutationFn = ApolloReactCommon.MutationFunction<
  Types.NotificarVideochamadaLotadaMutation,
  Types.NotificarVideochamadaLotadaMutationVariables
>

/**
 * __useNotificarVideochamadaLotadaMutation__
 *
 * To run a mutation, you first call `useNotificarVideochamadaLotadaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificarVideochamadaLotadaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificarVideochamadaLotadaMutation, { data, loading, error }] = useNotificarVideochamadaLotadaMutation({
 *   variables: {
 *      videochamadaUuid: // value for 'videochamadaUuid'
 *      lotada: // value for 'lotada'
 *   },
 * });
 */
export function useNotificarVideochamadaLotadaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.NotificarVideochamadaLotadaMutation,
    Types.NotificarVideochamadaLotadaMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.NotificarVideochamadaLotadaMutation,
    Types.NotificarVideochamadaLotadaMutationVariables
  >(NotificarVideochamadaLotadaDocument, baseOptions)
}
export type NotificarVideochamadaLotadaMutationHookResult = ReturnType<typeof useNotificarVideochamadaLotadaMutation>
export type NotificarVideochamadaLotadaMutationResult = ApolloReactCommon.MutationResult<
  Types.NotificarVideochamadaLotadaMutation
>
export type NotificarVideochamadaLotadaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.NotificarVideochamadaLotadaMutation,
  Types.NotificarVideochamadaLotadaMutationVariables
>
export const SmtpConfiguradoDocument = gql`
  query SmtpConfigurado {
    info {
      smtpConfigurado
    }
  }
`

/**
 * __useSmtpConfiguradoQuery__
 *
 * To run a query within a React component, call `useSmtpConfiguradoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmtpConfiguradoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmtpConfiguradoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSmtpConfiguradoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.SmtpConfiguradoQuery, Types.SmtpConfiguradoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.SmtpConfiguradoQuery, Types.SmtpConfiguradoQueryVariables>(
    SmtpConfiguradoDocument,
    baseOptions
  )
}
export function useSmtpConfiguradoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SmtpConfiguradoQuery, Types.SmtpConfiguradoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.SmtpConfiguradoQuery, Types.SmtpConfiguradoQueryVariables>(
    SmtpConfiguradoDocument,
    baseOptions
  )
}
export type SmtpConfiguradoQueryHookResult = ReturnType<typeof useSmtpConfiguradoQuery>
export type SmtpConfiguradoLazyQueryHookResult = ReturnType<typeof useSmtpConfiguradoLazyQuery>
export type SmtpConfiguradoQueryResult = ApolloReactCommon.QueryResult<
  Types.SmtpConfiguradoQuery,
  Types.SmtpConfiguradoQueryVariables
>
export const StatusVideochamadaDocument = gql`
  query StatusVideochamada($uuid: String!) {
    videochamada(uuid: $uuid) {
      id
      status
    }
  }
`

/**
 * __useStatusVideochamadaQuery__
 *
 * To run a query within a React component, call `useStatusVideochamadaQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusVideochamadaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusVideochamadaQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useStatusVideochamadaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.StatusVideochamadaQuery, Types.StatusVideochamadaQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.StatusVideochamadaQuery, Types.StatusVideochamadaQueryVariables>(
    StatusVideochamadaDocument,
    baseOptions
  )
}
export function useStatusVideochamadaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.StatusVideochamadaQuery,
    Types.StatusVideochamadaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.StatusVideochamadaQuery, Types.StatusVideochamadaQueryVariables>(
    StatusVideochamadaDocument,
    baseOptions
  )
}
export type StatusVideochamadaQueryHookResult = ReturnType<typeof useStatusVideochamadaQuery>
export type StatusVideochamadaLazyQueryHookResult = ReturnType<typeof useStatusVideochamadaLazyQuery>
export type StatusVideochamadaQueryResult = ApolloReactCommon.QueryResult<
  Types.StatusVideochamadaQuery,
  Types.StatusVideochamadaQueryVariables
>
export const EquipesVinculadasTableDocument = gql`
  query EquipesVinculadasTable($input: EquipesQueryInput!) {
    equipesVinculadas(input: $input) {
      content {
        id
        nome
        ine
        area
        tipoEquipe {
          id
          nome
          sigla
        }
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipesVinculadasTableQuery__
 *
 * To run a query within a React component, call `useEquipesVinculadasTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipesVinculadasTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipesVinculadasTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipesVinculadasTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EquipesVinculadasTableQuery,
    Types.EquipesVinculadasTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EquipesVinculadasTableQuery, Types.EquipesVinculadasTableQueryVariables>(
    EquipesVinculadasTableDocument,
    baseOptions
  )
}
export function useEquipesVinculadasTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipesVinculadasTableQuery,
    Types.EquipesVinculadasTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipesVinculadasTableQuery, Types.EquipesVinculadasTableQueryVariables>(
    EquipesVinculadasTableDocument,
    baseOptions
  )
}
export type EquipesVinculadasTableQueryHookResult = ReturnType<typeof useEquipesVinculadasTableQuery>
export type EquipesVinculadasTableLazyQueryHookResult = ReturnType<typeof useEquipesVinculadasTableLazyQuery>
export type EquipesVinculadasTableQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipesVinculadasTableQuery,
  Types.EquipesVinculadasTableQueryVariables
>
export const HasEquipesVinculadasDocument = gql`
  query HasEquipesVinculadas($equipePrincipalId: ID!) {
    hasEquipesVinculadas(equipePrincipalId: $equipePrincipalId)
  }
`

/**
 * __useHasEquipesVinculadasQuery__
 *
 * To run a query within a React component, call `useHasEquipesVinculadasQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasEquipesVinculadasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasEquipesVinculadasQuery({
 *   variables: {
 *      equipePrincipalId: // value for 'equipePrincipalId'
 *   },
 * });
 */
export function useHasEquipesVinculadasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HasEquipesVinculadasQuery,
    Types.HasEquipesVinculadasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.HasEquipesVinculadasQuery, Types.HasEquipesVinculadasQueryVariables>(
    HasEquipesVinculadasDocument,
    baseOptions
  )
}
export function useHasEquipesVinculadasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HasEquipesVinculadasQuery,
    Types.HasEquipesVinculadasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.HasEquipesVinculadasQuery, Types.HasEquipesVinculadasQueryVariables>(
    HasEquipesVinculadasDocument,
    baseOptions
  )
}
export type HasEquipesVinculadasQueryHookResult = ReturnType<typeof useHasEquipesVinculadasQuery>
export type HasEquipesVinculadasLazyQueryHookResult = ReturnType<typeof useHasEquipesVinculadasLazyQuery>
export type HasEquipesVinculadasQueryResult = ApolloReactCommon.QueryResult<
  Types.HasEquipesVinculadasQuery,
  Types.HasEquipesVinculadasQueryVariables
>
export const ImoveisPermissaoAtualizarDocument = gql`
  query imoveisPermissaoAtualizar($input: TerritorioLogradouroQueryInput!) {
    imoveisPermissaoAtualizar(input: $input) {
      totalImoveisPermitidos
      totalImoveis
    }
  }
`

/**
 * __useImoveisPermissaoAtualizarQuery__
 *
 * To run a query within a React component, call `useImoveisPermissaoAtualizarQuery` and pass it any options that fit your needs.
 * When your component renders, `useImoveisPermissaoAtualizarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImoveisPermissaoAtualizarQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImoveisPermissaoAtualizarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ImoveisPermissaoAtualizarQuery,
    Types.ImoveisPermissaoAtualizarQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ImoveisPermissaoAtualizarQuery, Types.ImoveisPermissaoAtualizarQueryVariables>(
    ImoveisPermissaoAtualizarDocument,
    baseOptions
  )
}
export function useImoveisPermissaoAtualizarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ImoveisPermissaoAtualizarQuery,
    Types.ImoveisPermissaoAtualizarQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ImoveisPermissaoAtualizarQuery,
    Types.ImoveisPermissaoAtualizarQueryVariables
  >(ImoveisPermissaoAtualizarDocument, baseOptions)
}
export type ImoveisPermissaoAtualizarQueryHookResult = ReturnType<typeof useImoveisPermissaoAtualizarQuery>
export type ImoveisPermissaoAtualizarLazyQueryHookResult = ReturnType<typeof useImoveisPermissaoAtualizarLazyQuery>
export type ImoveisPermissaoAtualizarQueryResult = ApolloReactCommon.QueryResult<
  Types.ImoveisPermissaoAtualizarQuery,
  Types.ImoveisPermissaoAtualizarQueryVariables
>
export const AtualizarEnderecoLogradouroDocument = gql`
  mutation AtualizarEnderecoLogradouro($input: AtualizarEnderecoTerritorioInput!) {
    atualizarEnderecoTerritorio(input: $input)
  }
`
export type AtualizarEnderecoLogradouroMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtualizarEnderecoLogradouroMutation,
  Types.AtualizarEnderecoLogradouroMutationVariables
>

/**
 * __useAtualizarEnderecoLogradouroMutation__
 *
 * To run a mutation, you first call `useAtualizarEnderecoLogradouroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarEnderecoLogradouroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarEnderecoLogradouroMutation, { data, loading, error }] = useAtualizarEnderecoLogradouroMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtualizarEnderecoLogradouroMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtualizarEnderecoLogradouroMutation,
    Types.AtualizarEnderecoLogradouroMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AtualizarEnderecoLogradouroMutation,
    Types.AtualizarEnderecoLogradouroMutationVariables
  >(AtualizarEnderecoLogradouroDocument, baseOptions)
}
export type AtualizarEnderecoLogradouroMutationHookResult = ReturnType<typeof useAtualizarEnderecoLogradouroMutation>
export type AtualizarEnderecoLogradouroMutationResult = ApolloReactCommon.MutationResult<
  Types.AtualizarEnderecoLogradouroMutation
>
export type AtualizarEnderecoLogradouroMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtualizarEnderecoLogradouroMutation,
  Types.AtualizarEnderecoLogradouroMutationVariables
>
export const TerritorioMicroareaDocument = gql`
  query TerritorioMicroarea($input: TerritorioMicroareaQueryInput!) {
    territorioMicroarea(input: $input) {
      id
      logradouros {
        content {
          id
          logradouro
          tipoLogradouroId
          tipoLogradouroNome
          tipoLogradouroDne
          bairro
          cep
          numeroImoveis
          numeroCidadaos
        }
        pageInfo {
          ...PageInfo
        }
      }
      indices {
        quantidadeImoveisAtualizados
        quantidadeImoveisCadastroCompleto
        quantidadeImoveisAvaliados
        quantidadeDomiciliosAvaliados
        quantidadeDomiciliosVisitados
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTerritorioMicroareaQuery__
 *
 * To run a query within a React component, call `useTerritorioMicroareaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerritorioMicroareaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerritorioMicroareaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerritorioMicroareaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TerritorioMicroareaQuery,
    Types.TerritorioMicroareaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.TerritorioMicroareaQuery, Types.TerritorioMicroareaQueryVariables>(
    TerritorioMicroareaDocument,
    baseOptions
  )
}
export function useTerritorioMicroareaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TerritorioMicroareaQuery,
    Types.TerritorioMicroareaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.TerritorioMicroareaQuery, Types.TerritorioMicroareaQueryVariables>(
    TerritorioMicroareaDocument,
    baseOptions
  )
}
export type TerritorioMicroareaQueryHookResult = ReturnType<typeof useTerritorioMicroareaQuery>
export type TerritorioMicroareaLazyQueryHookResult = ReturnType<typeof useTerritorioMicroareaLazyQuery>
export type TerritorioMicroareaQueryResult = ApolloReactCommon.QueryResult<
  Types.TerritorioMicroareaQuery,
  Types.TerritorioMicroareaQueryVariables
>
export const VisualizacaoTerritorialDocument = gql`
  query VisualizacaoTerritorial($input: TerritorioEquipeQueryInput!) {
    territorioEquipe(input: $input) {
      microareas
    }
  }
`

/**
 * __useVisualizacaoTerritorialQuery__
 *
 * To run a query within a React component, call `useVisualizacaoTerritorialQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualizacaoTerritorialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualizacaoTerritorialQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisualizacaoTerritorialQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VisualizacaoTerritorialQuery,
    Types.VisualizacaoTerritorialQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.VisualizacaoTerritorialQuery, Types.VisualizacaoTerritorialQueryVariables>(
    VisualizacaoTerritorialDocument,
    baseOptions
  )
}
export function useVisualizacaoTerritorialLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VisualizacaoTerritorialQuery,
    Types.VisualizacaoTerritorialQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.VisualizacaoTerritorialQuery, Types.VisualizacaoTerritorialQueryVariables>(
    VisualizacaoTerritorialDocument,
    baseOptions
  )
}
export type VisualizacaoTerritorialQueryHookResult = ReturnType<typeof useVisualizacaoTerritorialQuery>
export type VisualizacaoTerritorialLazyQueryHookResult = ReturnType<typeof useVisualizacaoTerritorialLazyQuery>
export type VisualizacaoTerritorialQueryResult = ApolloReactCommon.QueryResult<
  Types.VisualizacaoTerritorialQuery,
  Types.VisualizacaoTerritorialQueryVariables
>
export const VisualizarCidadaosImovelDocument = gql`
  query VisualizarCidadaosImovel($input: ID!) {
    imovel(imovelId: $input) {
      id
      uuidDomicilio
      ultimaFichaCadastrada {
        id
        isProcessado
      }
      dataUltimaVisita
      telefoneResidencial
      telefoneContato
      tipoLogradouro {
        id
        nome
      }
      tipoCdsImovelId
      logradouro
      numero
      complemento
      cep
      bairro
      familias(input: { mostrarInativas: true }) {
        id
        cnes
        ine
        numeroProntuarioFamiliar
        cpfCnsResponsavel
        isResponsavelCadastrado
        isResponsavelDeclarado
        isResponsavelVivo
        isResponsavelUnico
        isResponsavelAindaReside
        isFamiliaAindaReside
        isInformacaoSuficiente
        isDomicilioAtivo
        cidadaos(input: { mostrarInconsistentes: true }) {
          id
          uuidCidadao
          nome
          nomeSocial
          sexo
          dataNascimento
          cpf
          cns
          telefoneCelular
          telefoneContato
          isFaleceu
          ultimaFichaCadastrada {
            id
            uuidFichaOrigem
            isProcessado
          }
          isFaleceu
        }
        isResponsavelCadastrado
        isResponsavelDeclarado
        isResponsavelVivo
        isResponsavelUnico
        isResponsavelAindaReside
        isFamiliaAindaReside
        isInformacaoSuficiente
        isDomicilioAtivo
      }
      ultimaFichaProcessada {
        tipoLocalizacaoId
        tipoDomicilioId
        numeroMoradores
      }
      dataUltimaAtualizacao
      responsavelTecnico {
        id
        nome
        contato
        cns
        cargo
      }
    }
  }
`

/**
 * __useVisualizarCidadaosImovelQuery__
 *
 * To run a query within a React component, call `useVisualizarCidadaosImovelQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualizarCidadaosImovelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualizarCidadaosImovelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisualizarCidadaosImovelQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VisualizarCidadaosImovelQuery,
    Types.VisualizarCidadaosImovelQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.VisualizarCidadaosImovelQuery, Types.VisualizarCidadaosImovelQueryVariables>(
    VisualizarCidadaosImovelDocument,
    baseOptions
  )
}
export function useVisualizarCidadaosImovelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VisualizarCidadaosImovelQuery,
    Types.VisualizarCidadaosImovelQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.VisualizarCidadaosImovelQuery,
    Types.VisualizarCidadaosImovelQueryVariables
  >(VisualizarCidadaosImovelDocument, baseOptions)
}
export type VisualizarCidadaosImovelQueryHookResult = ReturnType<typeof useVisualizarCidadaosImovelQuery>
export type VisualizarCidadaosImovelLazyQueryHookResult = ReturnType<typeof useVisualizarCidadaosImovelLazyQuery>
export type VisualizarCidadaosImovelQueryResult = ApolloReactCommon.QueryResult<
  Types.VisualizarCidadaosImovelQuery,
  Types.VisualizarCidadaosImovelQueryVariables
>
export const VisualizarImoveisDocument = gql`
  query VisualizarImoveis($input: TerritorioLogradouroQueryInput!) {
    territorioLogradouro(input: $input) {
      id
      uuidDomicilio
      numero
      complemento
      cep
      familias {
        id
        responsavel {
          id
          nome
          nomeSocial
        }
        dependentes {
          id
        }
      }
      tipoCdsImovelId
      responsavelTecnico {
        id
        nome
      }
      dataUltimaVisita
      ultimaFichaCadastrada {
        id
        isProcessado
      }
      ultimaFichaProcessada {
        tipoDomicilioId
        numeroMoradores
      }
      dataUltimaAtualizacao
      isCadastroCompleto
    }
  }
`

/**
 * __useVisualizarImoveisQuery__
 *
 * To run a query within a React component, call `useVisualizarImoveisQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualizarImoveisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualizarImoveisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisualizarImoveisQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.VisualizarImoveisQuery, Types.VisualizarImoveisQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.VisualizarImoveisQuery, Types.VisualizarImoveisQueryVariables>(
    VisualizarImoveisDocument,
    baseOptions
  )
}
export function useVisualizarImoveisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VisualizarImoveisQuery,
    Types.VisualizarImoveisQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.VisualizarImoveisQuery, Types.VisualizarImoveisQueryVariables>(
    VisualizarImoveisDocument,
    baseOptions
  )
}
export type VisualizarImoveisQueryHookResult = ReturnType<typeof useVisualizarImoveisQuery>
export type VisualizarImoveisLazyQueryHookResult = ReturnType<typeof useVisualizarImoveisLazyQuery>
export type VisualizarImoveisQueryResult = ApolloReactCommon.QueryResult<
  Types.VisualizarImoveisQuery,
  Types.VisualizarImoveisQueryVariables
>
