import { AgeRangeValue } from 'components/date/AgeRange'
import { FaixaEtariaAcompanhamentoInput, FaixaEtariaEnum, FaixaEtariaInput } from 'graphql/types.generated'

export const convertFaixaEtariaAcompanhamentoInput = (
  faixaEtariaFilter?: FaixaEtariaEnum,
  faixaEtaria?: FaixaEtariaInput
): FaixaEtariaAcompanhamentoInput => {
  return {
    tipoFaixaEtaria: faixaEtariaFilter,
    faixaEtariaRange: faixaEtaria,
  }
}

export const convertAgeRangeValueToFaixaEtariaInput = (ageRangeValue?: AgeRangeValue): FaixaEtariaInput => {
  return {
    idadeMinima: ageRangeValue?.firstValue,
    idadeMaxima: ageRangeValue?.secondValue,
    tipoFaixaEtaria: ageRangeValue?.periodo,
  }
}
