import { AgeRangeValue } from 'components/date/AgeRange'
import { EquipeSelectModel } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectFieldModel } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { GrupoAtendimentoSelectFieldModel } from 'components/form/field/select/GrupoAtendimentoSelectField'
import {
  GrupoAlvoBuscaAtivaVacinacaoEnum,
  PageParams,
  StatusVacinacaoEnum,
  TipoVisualizacaoBuscaAtivaVacinacaoEnum,
} from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { ImunobiologicoSelectModel } from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'

import { GrupoAlvoVacinacaoSelectModel } from './component/GrupoAlvoVacinacaoSelectField'
import { items, StatusDoseImunoSelectModel } from './component/StatusDoseImunoSelectField'

export const BUSCA_ATIVA_VACINACAO_PATH = 'busca-ativa-vacinacao'

export const metaBuscaAtivaVacinacao = metaPath<BuscaAtivaVacinacaoFormModel>()

export interface BuscaAtivaVacinacaoFormModel {
  equipe?: EquipeSelectModel
  grupoAlvo?: GrupoAlvoVacinacaoSelectModel
  faixaEtaria?: AgeRangeValue
  faixaEtariaInicio?: number
  faixaEtariaFim?: number
  status?: StatusDoseImunoSelectModel
  microareas?: string[]
  filtrosAvancados?: BuscaAtivaVacinacaoFilterPopperFormModel
  tipoVisualizacao?: TipoVisualizacaoBuscaAtivaVacinacaoEnum
  pageParams?: PageParams
}

export interface BuscaAtivaVacinacao {
  id: ID
  cidadaoId?: ID
  nomeSocialOuNomeCidadao: string
  cpfCidadao?: string
  cnsCidadao?: string
  dataNascimentoCidadao: LocalDate
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  vacinasAtrasadas?: ImunobiologicoBuscaAtiva[]
  vacinasNoPrazo?: ImunobiologicoBuscaAtiva[]
  vacinasAplicadas?: ImunobiologicoBuscaAtiva[]
  endereco?: string
}

export interface ImunobiologicoBuscaAtiva {
  cidadaoId: ID
  dataAplicacaoVacina?: LocalDate
  nome: string
  siglaDose: string
  imunobiologicoId?: ID
  doseId?: ID
}

export const tipoVisualizacaoRecord: Record<TipoVisualizacaoBuscaAtivaVacinacaoEnum, string> = {
  CALENDARIO_VACINAL: 'Calendário vacinal',
  COVID_19: 'Covid-19',
}
export interface BuscaAtivaVacinacaoFilterPopperFormModel {
  imunobiologicos: ImunobiologicoSelectModel[]
  doses: DoseImunobiologicoVacinacaoSelectFieldModel[]
  gruposAtendimento: GrupoAtendimentoSelectFieldModel[]
  bairro: string
}

export const statusByTypeAndGroup = {
  [TipoVisualizacaoBuscaAtivaVacinacaoEnum.CALENDARIO_VACINAL]: {
    [GrupoAlvoBuscaAtivaVacinacaoEnum.GESTANTES]: items[StatusVacinacaoEnum.ATRASADA],
    [GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS]: items[StatusVacinacaoEnum.NO_PRAZO],
    [GrupoAlvoBuscaAtivaVacinacaoEnum.IDOSOS]: items[StatusVacinacaoEnum.NO_PRAZO],
  },
}
